import Vue from 'vue';

import {AccessRoles, settings} from './config'

// import mapboxgl from 'mapbox-gl'
// mapboxgl.accessToken = settings.mapboxApiKey
import { load as mapLoad } from '@2gis/mapgl';

import store from './store/store'
import { router, redirect } from './routes'

import { mapState } from "vuex"

Vue.prototype.$to = function (routeName, params) {
    this.$router.push({ name: routeName, params: params})
}
Vue.prototype.$round = function (v, p = 0) {
    return Math.round(v * 10**p) / 10**p;
}

// import { MqttClient } from './mqttClient'
// Vue.prototype.$mqtt = new MqttClient()

import {DATA_LOADED, USER_LOGGED} from "./store/mutations";

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

import vNotifications from 'vue-notification'
Vue.use(vNotifications)

import {plural} from "./other";
Vue.prototype.$plural = plural;

import axios from 'axios'

import mainPage from './pages/main.vue'

const app = new Vue({
    el: '#app',
    store,
    router,

    components:{
        mainPage
    },

    methods:{

    },

    computed:{
        ...mapState([
            'loggedIn',
        ])
    },

    watch:{
        async loggedIn(newVal, oldVal){
            let r = redirect(this.$route, newVal);
            // console.trace(newVal)
            if(r !== false) await this.$router.push(r);
            if(newVal === true){
                try {
                    await this.$store.dispatch('timezonesLoad')
                    // await this.$store.dispatch('projectsLoad')
                    this.$store.commit(DATA_LOADED, true)
                } catch (e) {
                    console.log(e)
                }
            }
        }
    },

    created(){

        Vue.prototype.$hasAccess = (project, accessType = 'Edit') => {
            if(this.$store.state?.user?.role === 'Administrator'){
                return true;
            }

            let user = this.$store.state.user;
            if(!user || !project){
                return false;
            }

            if(user.email === project.owner){
                return true;
            }

            if(user.projects) for(let el of user.projects){
                if(el.projectId == project.id && el.access === AccessRoles.edit){
                    return true;
                }
            }

            return false;
        }

        axios.interceptors.response.use(function (response) { // http status == 2xx
            return response;
        }, (error) => { // http status != 2xx
            console.log(error)
            if(error.response.status === 401 && this.loggedIn){
                // window.location.reload();
                this.$store.dispatch('logout')
                this.$store.commit(DATA_LOADED, false)
            }
            return Promise.reject(error);
        });

        // переход на нужные маршруты в зависимости от авторизованности
        this.$store.dispatch('getAuthUser').then(()=>{
            let r = redirect(this.$route, this.loggedIn)
            if(r !== false) this.$router.push(r)
        })

        this.$router.beforeEach((to, from, next) => {
            let p = redirect(to, this.loggedIn)
            if(p !== false){
                next(p)
            } else {
                next()
            }
        })
    },

    // https://b.tile.openstreetmap.org/${z}/${x}/${y}.png
    async mounted() {
        window.mapBox_mapMovingListenersNeedAdd = true;
        await mapLoad();
        Vue.prototype.$map = new mapgl.Map('map', {
            key: '11f2b8e9-11f2-47ae-b96c-3d411feca65e',
            // center: [37.23584, 55.31878],
            // zoom: 13,
            center: [37.85, 59.07],
            zoom: 13,
        });
        /*Vue.prototype.$map = new mapboxgl.Map({
            maxZoom: 18,
            container: 'map',
            // style: 'mapbox://styles/mapbox/light-v10',
            style: {
                'version': 8,
                'sources': {
                    'raster-tiles': {
                        'type': 'raster',
                        'tiles': [
                            'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        ],
                        'tileSize': 256,
                    }
                },
                'layers': [
                    {
                        'id': 'simple-tiles',
                        'type': 'raster',
                        'source': 'raster-tiles',
                        'minzoom': 0,
                        'maxzoom': 22
                    }
                ]
            },
            center: [37.215518, 55.984677],
            zoom: 13,
        })*/

    }
})
