<template>
    <div class="pe-input-autocomplete" :class="{'error': error, 'success': success}">
        <div class="pe-input" :class="{'error': error, 'success': success}">
            <input type="text"
                   :value="value"
                   @input="change($event)"
                   :tabindex="tabIndex"
                   @keydown.esc="setVal(value)"
                   @blur="hideVariants(); focused = false"
                   @focus="hideVars = false; focused = true"
                   :disabled="disabled"
            >
            <div class="pe-input-label" :class="{active: focused || value}">{{placeholder}}</div>
            <div class="ico-success"><img src="/images/icons/ok.svg"></div>
            <div class="ico-error"><img src="/images/icons/cross.svg"></div>
            <div class="err-hint" v-if="error">{{error}}</div>
        </div>
        <div class="variants" v-if="!hideVars && variants && variants.length">
            <div class="variant" v-for="v in variants" @click="setVal(v)">{{v}}</div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "src/scss/vars";

    .pe-input-autocomplete{
        position: relative;
    }

    .variants{
        position: absolute;
        width: 100%;
        top: 44px;
        left: 0;
        border: 1px solid #E7EBF8;
        border-top: none;
        z-index: 9999;
        box-sizing: border-box;
    }

    .variant{
        background: #ffffff;
        padding: 8px 10px;
        color: $colorBlack;
        font-size: 16px;
        line-height: 22px;
        border-top: 1px solid #E7EBF8;
        cursor: pointer;
        &:hover{
            color: $colorAcent;
        }
    }
</style>

<script>
    import peInput from "./peInput";
    export default {
        components:{
            peInput
        },

        props: ['value', 'placeholder', 'error', 'success', 'tabIndex', 'variants', 'disabled'],

        data() {
            return {
                hideVars: false,
                focused: false,
            }
        },

        methods: {
            hideVariants(){
                setTimeout(()=>{
                    this.hideVars = true
                }, 200)
            },

            setVal(v){
                this.$emit('input', v)
                this.$emit('change', v)
                this.$emit('selected', v)
            },

            change(e) {
                this.$emit('input', e.target.value)
                this.$emit('change', e.target.value)
            }
        }
    }
</script>
