let str = `59.07 37.85 0.1251069214850862
59.07 37.8510067114094 0.12495048310107441
59.07 37.85201342281879 0.12478964688988341
59.07 37.85302013422819 0.1246242126900577
59.07 37.854026845637584 0.12445397327169591
59.07 37.85503355704698 0.1242787143345626
59.07 37.856040268456375 0.12409821453952108
59.07 37.85704697986577 0.12391224557735804
59.07 37.85805369127517 0.12372057227929936
59.07 37.85906040268456 0.12352295277376223
59.07 37.86006711409396 0.12331913869415968
59.07 37.861073825503354 0.123108875442757
59.07 37.86208053691275 0.12289190251576744
59.07 37.86308724832215 0.12266795389506596
59.07 37.86409395973155 0.1224367585119873
59.07 37.865100671140944 0.12219804078870006
59.07 37.86610738255034 0.12195152126273236
59.07 37.867114093959735 0.12169691730005251
59.07 37.86812080536913 0.12143394390195889
59.07 37.869127516778526 0.12116231461078104
59.07 37.87013422818792 0.12088174251894362
59.07 37.87114093959732 0.1205919413853784
59.07 37.87214765100671 0.12029262686258155
59.07 37.87315436241611 0.11998351783661629
59.07 37.874161073825505 0.11966433788114764
59.07 37.8751677852349 0.11933481682510744
59.07 37.876174496644296 0.1189946924315609
59.07 37.87718120805369 0.11864371218277056
59.07 37.87818791946309 0.11828163516308547
59.07 37.87919463087248 0.11790823402654468
59.07 37.88020134228188 0.11752329702947645
59.07 37.881208053691275 0.11712663009884577
59.07 37.88221476510067 0.11671805889273362
59.07 37.883221476510066 0.11629743078747508
59.07 37.88422818791946 0.1158646166920836
59.07 37.88523489932886 0.11541951253699186
59.07 37.88624161073825 0.11496204019894525
59.07 37.88724832214765 0.1144921474874874
59.07 37.88825503355705 0.11400980659877868
59.07 37.88926174496645 0.11351501008807899
59.07 37.89026845637584 0.11300776283929984
59.07 37.89127516778524 0.1124880675831556
59.07 37.892281879194634 0.11195590001568917
59.07 37.89328859060403 0.11141116714442945
59.07 37.894295302013425 0.11085363857507644
59.07 37.89530201342282 0.11028283415025389
59.07 37.89630872483222 0.10969784125570475
59.07 37.89731543624161 0.10909701906041307
59.07 37.89832214765101 0.10847752178461698
59.07 37.899328859060404 0.10783453450225558
59.07 37.9003355704698 0.10716005821064731
59.07 37.901342281879195 0.10644100373586109
59.07 37.90234899328859 0.1056562665275264
59.07 37.90335570469799 0.10477240478260653
59.07 37.90436241610738 0.10373767872659614
59.07 37.90536912751678 0.1024748916138814
59.07 37.906375838926174 0.10087541869615645
59.07 37.90738255033557 0.09880093477836253
59.07 37.908389261744965 0.09610527448962917
59.07 37.90939597315436 0.09269058532474339
59.07 37.910402684563756 0.08859409947463799
59.07 37.91140939597315 0.08405542397139415
59.07 37.91241610738255 0.07948217538836111
59.07 37.91342281879195 0.07529628622047198
59.07 37.914429530201346 0.07176904630091784
59.07 37.91543624161074 0.06896622420848988
59.07 37.91644295302014 0.06680481909746258
59.07 37.91744966442953 0.06514148705299729
59.07 37.91845637583893 0.0638345423957966
59.07 37.919463087248324 0.0627703214512906
59.07 37.92046979865772 0.06186706526299378
59.07 37.921476510067116 0.06106957579651107
59.07 37.92248322147651 0.06034201053946431
59.07 37.92348993288591 0.05966167811878241
59.07 37.9244966442953 0.059014470619999676
59.07 37.9255033557047 0.05839174692883606
59.07 37.926510067114094 0.05778829253220549
59.07 37.92751677852349 0.05720101557255003
59.07 37.928523489932886 0.05662812529650515
59.07 37.92953020134228 0.05606861899779174
59.07 37.93053691275168 0.05552196348066146
59.07 37.93154362416107 0.05498789816780557
59.07 37.93255033557047 0.05446631391729669
59.07 37.933557046979864 0.053957178836949324
59.07 37.93456375838926 0.05346049323782551
59.07 37.935570469798655 0.05297626264925227
59.07 37.93657718120805 0.052504482032017935
59.07 37.937583892617454 0.05204512693801993
59.07 37.93859060402685 0.0515981489800767
59.07 37.939597315436245 0.05116347397427411
59.07 37.94060402684564 0.05074100173438438
59.07 37.94161073825504 0.05033060687971714
59.07 37.94261744966443 0.04993214025421308
59.07 37.94362416107383 0.04954543070145688
59.07 37.94463087248322 0.049170287031969837
59.07 37.94563758389262 0.048806500076567595
59.07 37.946644295302015 0.048453844755999564
59.07 37.94765100671141 0.04811208212052047
59.07 37.948657718120806 0.047780961328239765
59.07 37.9496644295302 0.04746022154128794
59.07 37.9506711409396 0.04714959372587865
59.07 37.95167785234899 0.04684880234720312
59.07 37.95268456375839 0.046557566953735946
59.07 37.953691275167785 0.046275603648163494
59.07 37.95469798657718 0.04600262644426566
59.07 37.955704697986576 0.04573834851062746
59.07 37.95671140939597 0.04548248330331692
59.07 37.95771812080537 0.04523474559069485
59.07 37.95872483221476 0.04499485237424448
59.07 37.95973154362416 0.044762523709895044
59.07 37.960738255033554 0.044537483434789756
59.07 37.96174496644295 0.04431945980470656
59.07 37.96275167785235 0.044108186047532416
59.07 37.96375838926175 0.04390340083834659
59.07 37.964765100671144 0.043704848701580465
59.07 37.96577181208054 0.0435122803457965
59.07 37.966778523489936 0.04332545293641728
59.07 37.96778523489933 0.04314413031165318
59.07 37.96879194630873 0.042968083146647545
59.07 37.96979865771812 0.04279708907068744
59.07 37.97080536912752 0.04263093274206238
59.07 37.971812080536914 0.04246940588490618
59.07 37.97281879194631 0.042312307292131375
59.07 37.973825503355705 0.04215944279827364
59.07 37.9748322147651 0.04201062522580668
59.07 37.9758389261745 0.04186567430825505
59.07 37.97684563758389 0.04172441659315326
59.07 37.97785234899329 0.04158668532765704
59.07 37.978859060402684 0.04145232032939928
59.07 37.97986577181208 0.04132116784493587
59.07 37.980872483221475 0.04119308039790981
59.07 37.98187919463087 0.04106791662888691
59.07 37.982885906040266 0.04094554112859816
59.07 37.98389261744966 0.040825824266151514
59.07 37.98489932885906 0.04070864201362724
59.07 37.98590604026845 0.04059387576829839
59.07 37.98691275167785 0.04048141217357459
59.07 37.98791946308725 0.015080632675993896
59.07 37.98892617449665 0.015080632675993882
59.07 37.98993288590604 0.015080632675993882
59.07 37.99093959731544 0.015080632675993869
59.07 37.991946308724835 0.015080632675993896
59.07 37.99295302013423 0.015080632675993882
59.07 37.993959731543626 0.015080632675993882
59.07 37.99496644295302 0.015080632675993882
59.07 37.99597315436242 0.015080632675993896
59.07 37.99697986577181 0.015080632675993882
59.07 37.99798657718121 0.015080632675993882
59.07 37.998993288590604 0.015080632675993882
59.071 37.85 0.12515374253070047
59.071 37.8510067114094 0.12499861011480735
59.071 37.85201342281879 0.12483913972330574
59.071 37.85302013422819 0.12467513336150651
59.071 37.854026845637584 0.1245063859737352
59.071 37.85503355704698 0.12433268543205254
59.071 37.856040268456375 0.12415381255710821
59.071 37.85704697986577 0.12396954117511672
59.071 37.85805369127517 0.12377963821517765
59.071 37.85906040268456 0.12358386385140888
59.071 37.86006711409396 0.12338197169463776
59.071 37.861073825503354 0.12317370903858495
59.071 37.86208053691275 0.1229588171656733
59.071 37.86308724832215 0.12273703171779496
59.071 37.86409395973155 0.12250808313747333
59.071 37.865100671140944 0.12227169718489905
59.071 37.86610738255034 0.1220275955364233
59.071 37.867114093959735 0.12177549646994591
59.071 37.86812080536913 0.12151511564251655
59.071 37.869127516778526 0.12124616696524737
59.071 37.87013422818792 0.12096836358023047
59.071 37.87114093959732 0.12068141894363191
59.071 37.87214765100671 0.12038504801850448
59.071 37.87315436241611 0.12007896857993425
59.071 37.874161073825505 0.11976290263400834
59.071 37.8751677852349 0.11943657795071216
59.071 37.876174496644296 0.1190997297089869
59.071 37.87718120805369 0.11875210224980441
59.071 37.87818791946309 0.11839345093004676
59.071 37.87919463087248 0.11802354406567941
59.071 37.88020134228188 0.11764216494677739
59.071 37.881208053691275 0.11724911389849771
59.071 37.88221476510067 0.11684421034940315
59.071 37.883221476510066 0.116427294849386
59.071 37.88422818791946 0.11599823094987775
59.071 37.88523489932886 0.13878432424925652
59.071 37.88624161073825 0.13824158909335063
59.071 37.88724832214765 0.13768658111114399
59.071 37.88825503355705 0.1371192659201304
59.071 37.88926174496645 0.13653963108554226
59.071 37.89026845637584 0.13594767952264195
59.071 37.89127516778524 0.13534341732472804
59.071 37.892281879194634 0.1347268326066084
59.071 37.89328859060403 0.1340978598604297
59.071 37.894295302013425 0.13345632093983562
59.071 37.89530201342282 0.13280182834037074
59.071 37.89630872483222 0.13213362770021891
59.071 37.89731543624161 0.1314503425062763
59.071 37.89832214765101 0.13074956202112706
59.071 37.899328859060404 0.13002717948479153
59.071 37.9003355704698 0.1292763369065765
59.071 37.901342281879195 0.1284857616620689
59.071 37.90234899328859 0.12763719320538852
59.071 37.90335570469799 0.1267015276636689
59.071 37.90436241610738 0.1256333611979635
59.071 37.90536912751678 0.12436407034493349
59.071 37.906375838926174 0.10140904382539882
59.071 37.90738255033557 0.09949606323147042
59.071 37.908389261744965 0.0970049614193259
59.071 37.90939597315436 0.09381482056212617
59.071 37.910402684563756 0.08991185653176942
59.071 37.91140939597315 0.08547114585816047
59.071 37.91241610738255 0.08086067912934762
59.071 37.91342281879195 0.07651801026272755
59.071 37.914429530201346 0.07277282296428889
59.071 37.91543624161074 0.0697516960587177
59.071 37.91644295302014 0.06740768569816466
59.071 37.91744966442953 0.06560760047259749
59.071 37.91845637583893 0.06420498879314869
59.071 37.919463087248324 0.06307645748168045
59.071 37.92046979865772 0.06213080806006138
59.071 37.921476510067116 0.061305466450813834
59.071 37.92248322147651 0.06055938679034398
59.071 37.92348993288591 0.05986640661322962
59.071 37.9244966442953 0.059210177225800756
59.071 37.9255033557047 0.058580640219089486
59.071 37.926510067114094 0.05797169697055223
59.071 37.92751677852349 0.05737971009349564
59.071 37.928523489932886 0.0568025557983492
59.071 37.92953020134228 0.05623903071965282
59.071 37.93053691275168 0.05568848305124352
59.071 37.93154362416107 0.05515058424833565
59.071 37.93255033557047 0.05462518832060893
59.071 37.933557046979864 0.05411224553317086
59.071 37.93456375838926 0.05361174985033827
59.071 37.935570469798655 0.05312370729505933
59.071 37.93657718120805 0.05264811727464761
59.071 37.937583892617454 0.05218496194863341
59.071 37.93859060402685 0.051734200586581215
59.071 37.939597315436245 0.05129576702119752
59.071 37.94060402684564 0.05086956901734572
59.071 37.94161073825504 0.05045548881998356
59.071 37.94261744966443 0.05005338441785658
59.071 37.94362416107383 0.04966309122970109
59.071 37.94463087248322 0.049284424025612415
59.071 37.94563758389262 0.04891717896244228
59.071 37.946644295302015 0.04856113565394605
59.071 37.94765100671141 0.048216059223202684
59.071 37.948657718120806 0.04788170230212081
59.071 37.9496644295302 0.04755780695435126
59.071 37.9506711409396 0.04724410650580414
59.071 37.95167785234899 0.04694032727235921
59.071 37.95268456375839 0.04664619017836234
59.071 37.953691275167785 0.04636141226236847
59.071 37.95469798657718 0.046085708068887156
59.071 37.955704697986576 0.04581879092655488
59.071 37.95671140939597 0.045560374114514746
59.071 37.95771812080537 0.04531017191989288
59.071 37.95872483221476 0.045067900590040316
59.071 37.95973154362416 0.04483327918385318
59.071 37.960738255033554 0.044606030327001694
59.071 37.96174496644295 0.04438588087619345
59.071 37.96275167785235 0.0441725624978246
59.071 37.96375838926175 0.0439658121665509
59.071 37.964765100671144 0.04376537258924821
59.071 37.96577181208054 0.043570992559925054
59.071 37.966778523489936 0.043382427250955355
59.071 37.96778523489933 0.043199438445928995
59.071 37.96879194630873 0.043021794719197384
59.071 37.96979865771812 0.042849271567031604
59.071 37.97080536912752 0.04268165149504503
59.071 37.971812080536914 0.042518724066295796
59.071 37.97281879194631 0.042360285914253566
59.071 37.973825503355705 0.04220614072453077
59.071 37.9748322147651 0.04205609918901605
59.071 37.9758389261745 0.041909978935817285
59.071 37.97684563758389 0.0417676044381375
59.071 37.97785234899329 0.04162880690496497
59.071 37.978859060402684 0.041493424156240064
59.071 37.97986577181208 0.04136130048491192
59.071 37.980872483221475 0.04123228650807892
59.071 37.98187919463087 0.04110623900922491
59.071 37.982885906040266 0.04098302077334503
59.071 37.98389261744966 0.04086250041657666
59.071 37.98489932885906 0.040744552211798596
59.071 37.98590604026845 0.0406290559114868
59.071 37.98691275167785 0.040515896568966946
59.071 37.98791946308725 0.04040496435909054
59.071 37.98892617449665 0.015080632675993882
59.071 37.98993288590604 0.015080632675993882
59.071 37.99093959731544 0.015080632675993882
59.071 37.991946308724835 0.015080632675993882
59.071 37.99295302013423 0.015080632675993882
59.071 37.993959731543626 0.015080632675993882
59.071 37.99496644295302 0.015080632675993882
59.071 37.99597315436242 0.015080632675993882
59.071 37.99697986577181 0.015080632675993882
59.071 37.99798657718121 0.015080632675993882
59.071 37.998993288590604 0.015080632675993882
59.072 37.85 0.12520017857085522
59.072 37.8510067114094 0.12504633431318057
59.072 37.85201342281879 0.12488821127216251
59.072 37.85302013422819 0.12472561361577886
59.072 37.854026845637584 0.12455833846111916
59.072 37.85503355704698 0.12438617585406214
59.072 37.856040268456375 0.12420890877992177
59.072 37.85704697986577 0.12402631320897159
59.072 37.85805369127517 0.1238381581809894
59.072 37.85906040268456 0.12364420593321512
59.072 37.86006711409396 0.12344421207639356
59.072 37.861073825503354 0.12323792582376926
59.072 37.86208053691275 0.12302509027810869
59.072 37.86308724832215 0.12280544278203352
59.072 37.86409395973155 0.12257871533706598
59.072 37.865100671140944 0.12234463509684973
59.072 37.86610738255034 0.12210292494012821
59.072 37.867114093959735 0.12185330412894496
59.072 37.86812080536913 0.12159548905743715
59.072 37.869127516778526 0.12132919409640966
59.072 37.87013422818792 0.12105413253850575
59.072 37.87114093959732 0.12077001764832213
59.072 37.87214765100671 0.12047656382123392
59.072 37.87315436241611 0.1201734878538381
59.072 37.874161073825505 0.1198605103278742
59.072 37.8751677852349 0.14418982906516037
59.072 37.876174496644296 0.14376434864302604
59.072 37.87718120805369 0.1433282990589798
59.072 37.87818791946309 0.14288143226434322
59.072 37.87919463087248 0.14242351260218675
59.072 37.88020134228188 0.14195431880129195
59.072 37.881208053691275 0.14147364602514337
59.072 37.88221476510067 0.1409813079417697
59.072 37.883221476510066 0.14047713876342582
59.072 37.88422818791946 0.1399609951793145
59.072 37.88523489932886 0.139432758064
59.072 37.88624161073825 0.1388923337801278
59.072 37.88724832214765 0.13833965479196214
59.072 37.88825503355705 0.1377746791421222
59.072 37.88926174496645 0.13719738807961454
59.072 37.89026845637584 0.13660778070042687
59.072 37.89127516778524 0.13600586377169643
59.072 37.892281879194634 0.1353916337935237
59.072 37.89328859060403 0.134765046546118
59.072 37.894295302013425 0.13412596645042943
59.072 37.89530201342282 0.1334740833618722
59.072 37.89630872483222 0.13280877684880038
59.072 37.89731543624161 0.13212889591337282
59.072 37.89832214765101 0.13143240297244171
59.072 37.899328859060404 0.1307158011182056
59.072 37.9003355704698 0.12997321859370434
59.072 37.901342281879195 0.1291949596823323
59.072 37.90234899328859 0.12836524761687398
59.072 37.90335570469799 0.12745880302787524
59.072 37.90436241610738 0.12643589750078738
59.072 37.90536912751678 0.12523580929519457
59.072 37.906375838926174 0.12376967351823684
59.072 37.90738255033557 0.1219164125430466
59.072 37.908389261744965 0.11953047535801471
59.072 37.90939597315436 0.11647562759268713
59.072 37.910402684563756 0.11269539046152643
59.072 37.91140939597315 0.10830118210015077
59.072 37.91241610738255 0.10360920319980733
59.072 37.91342281879195 0.09905478855229034
59.072 37.914429530201346 0.0950167503804652
59.072 37.91543624161074 0.09168585899873437
59.072 37.91644295302014 0.08905998573155183
59.072 37.91744966442953 0.06611091314580858
59.072 37.91845637583893 0.06460098211313456
59.072 37.919463087248324 0.06339959709077865
59.072 37.92046979865772 0.06240572196503635
59.072 37.921476510067116 0.06154871440914997
59.072 37.92248322147651 0.06078169430121792
59.072 37.92348993288591 0.06007455237132223
59.072 37.9244966442953 0.05940837055271707
59.072 37.9255033557047 0.058771456551905465
59.072 37.926510067114094 0.05815668863823886
59.072 37.92751677852349 0.05755979609359964
59.072 37.928523489932886 0.05697826716462811
59.072 37.92953020134228 0.05641066367942685
59.072 37.93053691275168 0.055856194088994386
59.072 37.93154362416107 0.055314448856428944
59.072 37.93255033557047 0.05478523715430601
59.072 37.933557046979864 0.05426848653975253
59.072 37.93456375838926 0.05376418170147649
59.072 37.935570469798655 0.0532723274279401
59.072 37.93657718120805 0.05279292658986711
59.072 37.937583892617454 0.05232596743375023
59.072 37.93859060402685 0.051871416652227775
59.072 37.939597315436245 0.0514292160387084
59.072 37.94060402684564 0.05099928136268254
59.072 37.94161073825504 0.050581502614800905
59.072 37.94261744966443 0.05017574508796018
59.072 37.94362416107383 0.04978185095729087
59.072 37.94463087248322 0.04939964114433946
59.072 37.94563758389262 0.049028917327124145
59.072 37.946644295302015 0.048669464005889354
59.072 37.94765100671141 0.04832105056508628
59.072 37.948657718120806 0.04798343329181681
59.072 37.9496644295302 0.04765635732400206
59.072 37.9506711409396 0.0473395585103739
59.072 37.95167785234899 0.04703276517039058
59.072 37.95268456375839 0.04673569974658547
59.072 37.953691275167785 0.0464480803449972
59.072 37.95469798657718 0.04616962216181262
59.072 37.955704697986576 0.04590003879616143
59.072 37.95671140939597 0.04563904345046063
59.072 37.95771812080537 0.04538635002089733
59.072 37.95872483221476 0.04514167408148519
59.072 37.95973154362416 0.04490473376582842
59.072 37.960738255033554 0.044675250551290346
59.072 37.96174496644295 0.044452949950602366
59.072 37.96275167785235 0.04423756211620444
59.072 37.96375838926175 0.044028822362822524
59.072 37.964765100671144 0.043826471613745185
59.072 37.96577181208054 0.04363025677637772
59.072 37.966778523489936 0.04343993105247397
59.072 37.96778523489933 0.04325525418838816
59.072 37.96879194630873 0.043075992670482136
59.072 37.96979865771812 0.04290191987066862
59.072 37.97080536912752 0.04273281614681531
59.072 37.971812080536914 0.042568468902499115
59.072 37.97281879194631 0.04240867261037502
59.072 37.973825503355705 0.042253228803136936
59.072 37.9748322147651 0.04210194603579034
59.072 37.9758389261745 0.04195463982272059
59.072 37.97684563758389 0.0418111325527592
59.072 37.97785234899329 0.041671253385202056
59.072 37.978859060402684 0.04153483812951676
59.072 37.97986577181208 0.04140172911121838
59.072 37.980872483221475 0.041271775026174864
59.072 37.98187919463087 0.04114483078541484
59.072 37.982885906040266 0.041020757352291104
59.072 37.98389261744966 0.04089942157366707
59.072 37.98489932885906 0.04078069600664391
59.072 37.98590604026845 0.040664458742159904
59.072 37.98691275167785 0.04055059322664838
59.072 37.98791946308725 0.040438988082819885
59.072 37.98892617449665 0.0403295369304928
59.072 37.98993288590604 0.015080632675993855
59.072 37.99093959731544 0.015080632675993882
59.072 37.991946308724835 0.015080632675993882
59.072 37.99295302013423 0.015080632675993882
59.072 37.993959731543626 0.015080632675993896
59.072 37.99496644295302 0.015080632675993882
59.072 37.99597315436242 0.015080632675993882
59.072 37.99697986577181 0.015080632675993882
59.072 37.99798657718121 0.015080632675993882
59.072 37.998993288590604 0.015080632675993882
59.073 37.85 0.12524623488003087
59.073 37.8510067114094 0.1250936611698997
59.073 37.85201342281879 0.12493686721110926
59.073 37.85302013422819 0.12477565932946628
59.073 37.854026845637584 0.12460983681252259
59.073 37.85503355704698 0.12443919188054854
59.073 37.856040268456375 0.12426350968733632
59.073 37.85704697986577 0.12408256835466167
59.073 37.85805369127517 0.1238961390444663
59.073 37.85906040268456 0.12370398607307714
59.073 37.86006711409396 0.12350586707205852
59.073 37.861073825503354 0.12330153320049705
59.073 37.86208053691275 0.12309072941373218
59.073 37.86308724832215 0.12287319479376399
59.073 37.86409395973155 0.12264866294670201
59.073 37.865100671140944 0.1224168624726919
59.073 37.86610738255034 0.12217751751389899
59.073 37.867114093959735 0.12193034838603026
59.073 37.86812080536913 0.1475228843419436
59.073 37.869127516778526 0.14716478014877818
59.073 37.87013422818792 0.14679813818280035
59.073 37.87114093959732 0.14642267107036652
59.073 37.87214765100671 0.1460380922647974
59.073 37.87315436241611 0.14564411728027793
59.073 37.874161073825505 0.14524046503885688
59.073 37.8751677852349 0.1448268593315729
59.073 37.876174496644296 0.14440303039310154
59.073 37.87718120805369 0.14396871658729318
59.073 37.87818791946309 0.14352366619841495
59.073 37.87919463087248 0.1430676393193288
59.073 37.88020134228188 0.14260040982302338
59.073 37.881208053691275 0.14212176739719265
59.073 37.88221476510067 0.14163151961158152
59.073 37.883221476510066 0.14112949397301014
59.073 37.88422818791946 0.14061553990043518
59.073 37.88523489932886 0.1400895305170905
59.073 37.88624161073825 0.13955136410116115
59.073 37.88724832214765 0.13900096494803849
59.073 37.88825503355705 0.13843828325526972
59.073 37.88926174496645 0.13786329341302836
59.073 37.89026845637584 0.13727598971444824
59.073 37.89127516778524 0.13667637790447543
59.073 37.892281879194634 0.13606446002201097
59.073 37.89328859060403 0.13544020843115157
59.073 37.894295302013425 0.13480352241667065
59.073 37.89530201342282 0.13415415665074476
59.073 37.89630872483222 0.13349160429122514
59.073 37.89731543624161 0.13281490698787873
59.073 37.89832214765101 0.13212234742307982
59.073 37.899328859060404 0.13141095393678903
59.073 37.9003355704698 0.13067570691365482
59.073 37.901342281879195 0.12990827821912362
59.073 37.90234899328859 0.12909505635748722
59.073 37.90335570469799 0.12821412303784002
59.073 37.90436241610738 0.12723080482544166
59.073 37.90536912751678 0.126091585826796
59.073 37.906375838926174 0.12471692009566318
59.073 37.90738255033557 0.12299557116454023
59.073 37.908389261744965 0.12078742622939859
59.073 37.90939597315436 0.11794765295914632
59.073 37.910402684563756 0.11438589027332394
59.073 37.91140939597315 0.11015401108586727
59.073 37.91241610738255 0.10550817122993583
59.073 37.91342281879195 0.10086383314961836
59.073 37.914429530201346 0.09663589989900326
59.073 37.91543624161074 0.09307929022489371
59.073 37.91644295302014 0.09024476474746129
59.073 37.91744966442953 0.08804138586728448
59.073 37.91845637583893 0.08632421435529553
59.073 37.919463087248324 0.08495327078608002
59.073 37.92046979865772 0.0838175163738846
59.073 37.921476510067116 0.08283758332161087
59.073 37.92248322147651 0.08196009024531788
59.073 37.92348993288591 0.060286505494063974
59.073 37.9244966442953 0.05960928910318021
59.073 37.9255033557047 0.058964339593337606
59.073 37.926510067114094 0.058343352531491144
59.073 37.92751677852349 0.057741322646436825
59.073 37.928523489932886 0.0571552867383997
59.073 37.92953020134228 0.05658353229669537
59.073 37.93053691275168 0.05602510353246353
59.073 37.93154362416107 0.05547949479879067
59.073 37.93255033557047 0.054946461143094476
59.073 37.933557046979864 0.05442590174350548
59.073 37.93456375838926 0.053917788584674484
59.073 37.935570469798655 0.05342212318258971
59.073 37.93657718120805 0.052938910695941074
59.073 37.937583892617454 0.052468144818102545
59.073 37.93859060402685 0.052009799357024315
59.073 37.939597315436245 0.05156382396506957
59.073 37.94060402684564 0.05113014244176678
59.073 37.94161073825504 0.050708652626542235
59.073 37.94261744966443 0.050299227265923376
59.073 37.94362416107383 0.04990171546736985
59.073 37.94463087248322 0.049515944493413455
59.073 37.94563758389262 0.0491417217379785
59.073 37.946644295302015 0.04877883678219426
59.073 37.94765100671141 0.04842706346222164
59.073 37.948657718120806 0.048086161904131675
59.073 37.9496644295302 0.04775588049564433
59.073 37.9506711409396 0.04743595777447429
59.073 37.95167785234899 0.047126124219739565
59.073 37.95268456375839 0.04682610393774877
59.073 37.953691275167785 0.04653561623693106
59.073 37.95469798657718 0.0462543770893654
59.073 37.955704697986576 0.0459821004783231
59.073 37.95671140939597 0.045718499632810425
59.073 37.95771812080537 0.0454632881513766
59.073 37.95872483221476 0.045216181018369765
59.073 37.95973154362416 0.044976895516578325
59.073 37.960738255033554 0.04474515204081147
59.073 37.96174496644295 0.04452067481734782
59.073 37.96275167785235 0.044303192534477565
59.073 37.96375838926175 0.04409243888960285
59.073 37.964765100671144 0.04388815305834769
59.073 37.96577181208054 0.043690080091264516
59.073 37.966778523489936 0.04349797124356429
59.073 37.96778523489933 0.04331158424325434
59.073 37.96879194630873 0.04313068350287319
59.073 37.96979865771812 0.04295504027986452
59.073 37.97080536912752 0.04278443279038635
59.073 37.971812080536914 0.04261864628111699
59.073 37.97281879194631 0.04245747306340123
59.073 37.973825503355705 0.04230071251379343
59.073 37.9748322147651 0.04214817104479517
59.073 37.9758389261745 0.04199966204935289
59.073 37.97684563758389 0.0418550058223937
59.073 37.97785234899329 0.0417140294624294
59.073 37.978859060402684 0.041576566756038894
59.073 37.97986577181208 0.04144245804777841
59.073 37.980872483221475 0.041311550097845706
59.073 37.98187919463087 0.04118369592963695
59.073 37.982885906040266 0.04105875466910492
59.073 37.98389261744966 0.040936591377645576
59.073 37.98489932885906 0.04081707688007843
59.073 37.98590604026845 0.040700087589103984
59.073 37.98691275167785 0.04058550532746668
59.073 37.98791946308725 0.04047321714893162
59.073 37.98892617449665 0.04036311515903607
59.073 37.98993288590604 0.015080632675993882
59.073 37.99093959731544 0.015080632675993882
59.073 37.991946308724835 0.015080632675993882
59.073 37.99295302013423 0.015080632675993882
59.073 37.993959731543626 0.015080632675993882
59.073 37.99496644295302 0.015080632675993882
59.073 37.99597315436242 0.015080632675993882
59.073 37.99697986577181 0.015080632675993882
59.073 37.99798657718121 0.015080632675993882
59.073 37.998993288590604 0.015080632675993882
59.074 37.85 0.1252919166729031
59.074 37.8510067114094 0.12514059609821937
59.074 37.85201342281879 0.12498511315390996
59.074 37.85302013422819 0.1248252763180642
59.074 37.854026845637584 0.1246608870455862
59.074 37.85503355704698 0.12449173973079783
59.074 37.856040268456375 0.12431762169875549
59.074 37.85704697986577 0.12413831322903074
59.074 37.85805369127517 0.12395358761593707
59.074 37.85906040268456 0.12376321126943919
59.074 37.86006711409396 0.12356694386126714
59.074 37.861073825503354 0.12336453852096327
59.074 37.86208053691275 0.1231557420868116
59.074 37.86308724832215 0.1498311560651946
59.074 37.86409395973155 0.1495118364409608
59.074 37.865100671140944 0.14918549168411505
59.074 37.86610738255034 0.14885184568810447
59.074 37.867114093959735 0.14851061841718355
59.074 37.86812080536913 0.14816152659570272
59.074 37.869127516778526 0.14780428449017158
59.074 37.87013422818792 0.1474386047891202
59.074 37.87114093959732 0.14706419958539618
59.074 37.87214765100671 0.14668078146507407
59.074 37.87315436241611 0.14628806470639347
59.074 37.874161073825505 0.14588576659124636
59.074 37.8751677852349 0.14547360883064775
59.074 37.876174496644296 0.14505131910410443
59.074 37.87718120805369 0.14461863271092404
59.074 37.87818791946309 0.14417529432915724
59.074 37.87919463087248 0.1437210598745947
59.074 37.88020134228188 0.14325569844787886
59.074 37.881208053691275 0.14277899435177077
59.074 37.88221476510067 0.14229074915174067
59.074 37.883221476510066 0.14179078373999127
59.074 37.88422818791946 0.14127894034327432
59.074 37.88523489932886 0.14075508438405404
59.074 37.88624161073825 0.1402191060562843
59.074 37.88724832214765 0.1396709214004654
59.074 37.88825503355705 0.13911047253986403
59.074 37.88926174496645 0.13853772654252977
59.074 37.89026845637584 0.13795267205559097
59.074 37.89127516778524 0.13735531234419085
59.074 37.892281879194634 0.13674565253563595
59.074 37.89328859060403 0.13612367752397694
59.074 37.894295302013425 0.13548931481427975
59.074 37.89530201342282 0.13484237307195732
59.074 37.89630872483222 0.13418244148182773
59.074 37.89731543624161 0.13350872594036584
59.074 37.89832214765101 0.1328197836392543
59.074 37.899328859060404 0.13211309482818068
59.074 37.9003355704698 0.13138437542035925
59.074 37.901342281879195 0.13062648182992645
59.074 37.90234899328859 0.1298276867244755
59.074 37.90335570469799 0.1289690170599831
59.074 37.90436241610738 0.12802027978517533
59.074 37.90536912751678 0.12693447275229106
59.074 37.906375838926174 0.1256407891705755
59.074 37.90738255033557 0.12403800206123225
59.074 37.908389261744965 0.1219935873023754
59.074 37.90939597315436 0.11935968496059401
59.074 37.910402684563756 0.11602069267779358
59.074 37.91140939597315 0.11197546959320594
59.074 37.91241610738255 0.10741604654868903
59.074 37.91342281879195 0.10272202300835141
59.074 37.914429530201346 0.09832843612761699
59.074 37.91543624161074 0.09455033139841897
59.074 37.91644295302014 0.09149791560334328
59.074 37.91744966442953 0.08911399411679638
59.074 37.91845637583893 0.08726213826671331
59.074 37.919463087248324 0.08579690103215327
59.074 37.92046979865772 0.08459740483144661
59.074 37.921476510067116 0.0835749753670843
59.074 37.92248322147651 0.08266903765697163
59.074 37.92348993288591 0.08183996528900722
59.074 37.9244966442953 0.08106254585149306
59.074 37.9255033557047 0.08032104502737768
59.074 37.926510067114094 0.07960579339354584
59.074 37.92751677852349 0.07891093518950668
59.074 37.928523489932886 0.07823298307631374
59.074 37.92953020134228 0.05675765425636893
59.074 37.93053691275168 0.056195220184020296
59.074 37.93154362416107 0.05564572588496168
59.074 37.93255033557047 0.05510886148890355
59.074 37.933557046979864 0.05458449119080744
59.074 37.93456375838926 0.05407257026424077
59.074 37.935570469798655 0.053573094555903436
59.074 37.93657718120805 0.053086070114339884
59.074 37.937583892617454 0.052611495301479586
59.074 37.93859060402685 0.052149350647013926
59.074 37.939597315436245 0.051699593507364805
59.074 37.94060402684564 0.051262155704789515
59.074 37.94161073825504 0.05083694301070327
59.074 37.94261744966443 0.05042383576302337
59.074 37.94362416107383 0.05002269017096944
59.074 37.94463087248322 0.04963334002446691
59.074 37.94563758389262 0.04925559862717814
59.074 37.946644295302015 0.04888926083607814
59.074 37.94765100671141 0.04853410513093066
59.074 37.948657718120806 0.048189895662776164
59.074 37.9496644295302 0.047856384247334606
59.074 37.9506711409396 0.047533312280433934
59.074 37.95167785234899 0.04722041256008558
59.074 37.95268456375839 0.04691741100521463
59.074 37.953691275167785 0.046624028264839065
59.074 37.95469798657718 0.046339981214423925
59.074 37.955704697986576 0.04606498433825853
59.074 37.95671140939597 0.04579875099840082
59.074 37.95771812080537 0.0455409945921053
59.074 37.95872483221476 0.045291429600643814
59.074 37.95973154362416 0.04504977253324585
59.074 37.960738255033554 0.04481574277055125
59.074 37.96174496644295 0.04458906331238828
59.074 37.96275167785235 0.04436946143502381
59.074 37.96375838926175 0.04415666926330365
59.074 37.964765100671144 0.043950424263112825
59.074 37.96577181208054 0.04375046965974825
59.074 37.966778523489936 0.043556554787649915
59.074 37.96778523489933 0.043368435376914836
59.074 37.96879194630873 0.04318587378183052
59.074 37.96979865771812 0.04300863915653291
59.074 37.97080536912752 0.042836507582650125
59.074 37.971812080536914 0.04266926215356763
59.074 37.97281879194631 0.04250669301973527
59.074 37.973825503355705 0.04234859739915081
59.074 37.9748322147651 0.04219477955689821
59.074 37.9758389261745 0.04204505075738413
59.074 37.97684563758389 0.041899229192630394
59.074 37.97785234899329 0.0417571398897282
59.074 37.978859060402684 0.041618614600336966
59.074 37.97986577181208 0.04148349167484969
59.074 37.980872483221475 0.0038619864839570944
59.074 37.98187919463087 0.0031943210870690464
59.074 37.982885906040266 0.002515419737859837
59.074 37.98389261744966 0.001823170429979655
59.074 37.98489932885906 0.0011150890793481943
59.074 37.98590604026845 0.0003882512190267924
59.074 37.98691275167785 -0.0003607870240052019
59.074 37.98791946308725 -0.0011360796878149872
59.074 37.98892617449665 -0.001942394262863932
59.074 37.98993288590604 -0.0027853278761439566
59.074 37.99093959731544 -0.0358409171975865
59.074 37.991946308724835 -0.03662173537455302
59.074 37.99295302013423 -0.03746222538135302
59.074 37.993959731543626 -0.03837259892334997
59.074 37.99496644295302 -0.0393646091566656
59.074 37.99597315436242 0.015080632675993882
59.074 37.99697986577181 0.015080632675993882
59.074 37.99798657718121 0.015080632675993882
59.074 37.998993288590604 0.015080632675993882
59.075 37.85 0.125337229104567
59.075 37.8510067114094 0.12518714445111
59.075 37.85201342281879 0.1250329546535386
59.075 37.85302013422819 0.12487447033599766
59.075 37.854026845637584 0.1247114951168577
59.075 37.85503355704698 0.12454382556327057
59.075 37.856040268456375 0.12437125117335057
59.075 37.85704697986577 0.12419355438964752
59.075 37.85805369127517 0.15198101333945116
59.075 37.85906040268456 0.15169249343456898
59.075 37.86006711409396 0.15139835286540762
59.075 37.861073825503354 0.15109834353632384
59.075 37.86208053691275 0.15079221113598437
59.075 37.86308724832215 0.15047969542398687
59.075 37.86409395973155 0.15016053058142054
59.075 37.865100671140944 0.1498344456307217
59.075 37.86610738255034 0.14950116493039398
59.075 37.867114093959735 0.1491604087500695
59.075 37.86812080536913 0.14881189393138514
59.075 37.869127516778526 0.1484553346400645
59.075 37.87013422818792 0.14809044321431947
59.075 37.87114093959732 0.14771693111433806
59.075 37.87214765100671 0.14733450997720815
59.075 37.87315436241611 0.1469428927809276
59.075 37.874161073825505 0.1465417951203146
59.075 37.8751677852349 0.14613093659663257
59.075 37.876174496644296 0.14571004232132617
59.075 37.87718120805369 0.14527884453253084
59.075 37.87818791946309 0.14483708432085743
59.075 37.87919463087248 0.14438451345795322
59.075 37.88020134228188 0.1439208963173837
59.075 37.881208053691275 0.1434460118719712
59.075 37.88221476510067 0.14295965574381628
59.075 37.883221476510066 0.1424616422716831
59.075 37.88422818791946 0.14195180654310613
59.075 37.88523489932886 0.14143000631166852
59.075 37.88624161073825 0.14089612367791152
59.075 37.88724832214765 0.14035006634592517
59.075 37.88825503355705 0.13979176816139732
59.075 37.88926174496645 0.13922118846642528
59.075 37.89026845637584 0.13863830953164513
59.075 37.89127516778524 0.1380431308824768
59.075 37.892281879194634 0.13743565861846233
59.075 37.89328859060403 0.13681588666372627
59.075 37.894295302013425 0.13618376500841278
59.075 37.89530201342282 0.13553914696649177
59.075 37.89630872483222 0.13488170258261292
59.075 37.89731543624161 0.13421077745959403
59.075 37.89832214765101 0.13352516372437503
59.075 37.899328859060404 0.13282273000411765
59.075 37.9003355704698 0.13209982644249488
59.075 37.901342281879195 0.13135033427128912
59.075 37.90234899328859 0.13056416306346996
59.075 37.90335570469799 0.12972491418385673
59.075 37.90436241610738 0.12880634924587403
59.075 37.90536912751678 0.12776731148004933
59.075 37.906375838926174 0.12654507538391097
59.075 37.90738255033557 0.125048260389474
59.075 37.908389261744965 0.12315331029442414
59.075 37.90939597315436 0.12071375330514364
59.075 37.910402684563756 0.11759672578005523
59.075 37.91140939597315 0.1137561342201231
59.075 37.91241610738255 0.10931978910888343
59.075 37.91342281879195 0.10461834128553038
59.075 37.914429530201346 0.10008932424730418
59.075 37.91543624161074 0.09609971411117617
59.075 37.91644295302014 0.09282331459391106
59.075 37.91744966442953 0.09024548625849202
59.075 37.91845637583893 0.0882450152506815
59.075 37.919463087248324 0.08667404610045935
59.075 37.92046979865772 0.08540247492601064
59.075 37.921476510067116 0.08433187615162215
59.075 37.92248322147651 0.08339378572132403
59.075 37.92348993288591 0.08254291137001328
59.075 37.9244966442953 0.0817502223547864
59.075 37.9255033557047 0.08099750418283401
59.075 37.926510067114094 0.08027351245563387
59.075 37.92751677852349 0.07957140611312971
59.075 37.928523489932886 0.07888708833602007
59.075 37.92953020134228 0.07821815406997618
59.075 37.93053691275168 0.07756322992368118
59.075 37.93154362416107 0.07692156313495066
59.075 37.93255033557047 0.07629276690816472
59.075 37.933557046979864 0.05474425516853891
59.075 37.93456375838926 0.05422852652298393
59.075 37.935570469798655 0.05372524143392895
59.075 37.93657718120805 0.05323440518385343
59.075 37.937583892617454 0.05275601986487144
59.075 37.93859060402685 0.05229007223548254
59.075 37.939597315436245 0.05183652713966844
59.075 37.94060402684564 0.051395324377086334
59.075 37.94161073825504 0.050966377711142485
59.075 37.94261744966443 0.05054957519505528
59.075 37.94362416107383 0.05014478030136339
59.075 37.94463087248322 0.04975183352977898
59.075 37.94563758389262 0.04937055428603894
59.075 37.946644295302015 0.04205157432313615
59.075 37.94765100671141 0.041642419585276355
59.075 37.948657718120806 0.0412440985024493
59.075 37.9496644295302 0.04085636795011371
59.075 37.9506711409396 0.040478973783406744
59.075 37.95167785234899 0.04011165268239217
59.075 37.95268456375839 0.039754133895902495
59.075 37.953691275167785 0.039406140876635895
59.075 37.95469798657718 0.03906739280336439
59.075 37.955704697986576 0.03873760598839976
59.075 37.95671140939597 0.03841649517028531
59.075 37.95771812080537 0.03810377469315377
59.075 37.95872483221476 0.037799159575245864
59.075 37.95973154362416 0.037502366469953786
59.075 37.960738255033554 0.037213114523470175
59.075 37.96174496644295 0.03693112613356771
59.075 37.96275167785235 0.03665612761438555
59.075 37.96375838926175 0.03638784977240654
59.075 37.964765100671144 0.03612602839879901
59.075 37.96577181208054 0.03587040468349466
59.075 37.966778523489936 0.035620725556200265
59.075 37.96778523489933 0.010617443013755702
59.075 37.96879194630873 0.01007396729908049
59.075 37.96979865771812 0.009532183672136561
59.075 37.97080536912752 0.008991597526373754
59.075 37.971812080536914 0.008451666148653007
59.075 37.97281879194631 0.007911788017362937
59.075 37.973825503355705 0.007371290020696204
59.075 37.9748322147651 0.0068294122245340615
59.075 37.9758389261745 0.006264556958355285
59.075 37.97684563758389 0.005613227853896424
59.075 37.97785234899329 0.004955751924120819
59.075 37.978859060402684 0.004290812564801603
59.075 37.97986577181208 0.0036168756665187762
59.075 37.980872483221475 0.002932147321486646
59.075 37.98187919463087 0.002234524158951621
59.075 37.982885906040266 0.0015215352498334396
59.075 37.98389261744966 0.0007902744693114028
59.075 37.98489932885906 3.732220569119607e-05
59.075 37.98590604026845 -0.0007413446125132776
59.075 37.98691275167785 -0.001550454985183497
59.075 37.98791946308725 -0.0023955592101807047
59.075 37.98892617449665 -0.0032831568706400763
59.075 37.98993288590604 -0.0042208360759910385
59.075 37.99093959731544 -0.005217420978644943
59.075 37.991946308724835 -0.038312426570908054
59.075 37.99295302013423 -0.039298805482346606
59.075 37.993959731543626 -0.04037932229333688
59.075 37.99496644295302 -0.04156902781343701
59.075 37.99597315436242 -0.042884743133390295
59.075 37.99697986577181 -0.04434499106885427
59.075 37.99798657718121 -0.04596979325760747
59.075 37.998993288590604 -0.04778028630647921
59.076 37.85 0.12538217727078071
59.076 37.8510067114094 0.12523331152144626
59.076 37.85201342281879 0.12508039720230382
59.076 37.85302013422819 0.12492324707667474
59.076 37.854026845637584 0.12476166692176278
59.076 37.85503355704698 0.15348323769953534
59.076 37.856040268456375 0.1532091485172705
59.076 37.85704697986577 0.15293025314261302
59.076 37.85805369127517 0.1526463237877385
59.076 37.85906040268456 0.15235712594878062
59.076 37.86006711409396 0.15206241851576577
59.076 37.861073825503354 0.15176195392983421
59.076 37.86208053691275 0.1514554783925486
59.076 37.86308724832215 0.15114273213235538
59.076 37.86409395973155 0.15082344973341044
59.076 37.865100671140944 0.15049736053208695
59.076 37.86610738255034 0.15016418908671164
59.076 37.867114093959735 0.1498236557260008
59.076 37.86812080536913 0.1494754771816892
59.076 37.869127516778526 0.14911936731078948
59.076 37.87013422818792 0.14875503791267344
59.076 37.87114093959732 0.14838219964585714
59.076 37.87214765100671 0.1480005630490014
59.076 37.87315436241611 0.14760983966998856
59.076 37.874161073825505 0.14720974330616565
59.076 37.8751677852349 0.14679999135791855
59.076 37.876174496644296 0.14638030629642185
59.076 37.87718120805369 0.14595041724479862
59.076 37.87818791946309 0.14551006166993669
59.076 37.87919463087248 0.14505898717944826
59.076 37.88020134228188 0.1557241265081574
59.076 37.881208053691275 0.15523423086304394
59.076 37.88221476510067 0.15473278034868337
59.076 37.883221476510066 0.1542195832151252
59.076 37.88422818791946 0.15369446789447833
59.076 37.88523489932886 0.1531572849625381
59.076 37.88624161073825 0.1526079089069506
59.076 37.88724832214765 0.15204623953768967
59.076 37.88825503355705 0.15147220278359352
59.076 37.88926174496645 0.15088575047131614
59.076 37.89026845637584 0.15028685844574458
59.076 37.89127516778524 0.1496755220078593
59.076 37.892281879194634 0.14905174702652113
59.076 37.89328859060403 0.14841553407887748
59.076 37.894295302013425 0.14776685135309206
59.076 37.89530201342282 0.14710558942715132
59.076 37.89630872483222 0.1464314868099845
59.076 37.89731543624161 0.14574400832998888
59.076 37.89832214765101 0.145042147571377
59.076 37.899328859060404 0.14432410728826978
59.076 37.9003355704698 0.14358678471916306
59.076 37.901342281879195 0.1428249475397752
59.076 37.90234899328859 0.1420299261736285
59.076 37.90335570469799 0.14118756818564587
59.076 37.90436241610738 0.1402751143428434
59.076 37.90536912751678 0.1392566224804022
59.076 37.906375838926174 0.1380767568183117
59.076 37.90738255033557 0.13665358780113104
59.076 37.908389261744965 0.13487328539546303
59.076 37.90939597315436 0.12201295234021514
59.076 37.910402684563756 0.11911280261287972
59.076 37.91140939597315 0.11548850201887112
59.076 37.91241610738255 0.1112069776029735
59.076 37.91342281879195 0.10654060793452351
59.076 37.914429530201346 0.10191157516994317
59.076 37.91543624161074 0.09772667252678995
59.076 37.91644295302014 0.09422421815936798
59.076 37.91744966442953 0.09144045647015599
59.076 37.91845637583893 0.08927712328647266
59.076 37.919463087248324 0.08758806411004366
59.076 37.92046979865772 0.08623514407690114
59.076 37.921476510067116 0.08510995154842091
59.076 37.92248322147651 0.08413546292509942
59.076 37.92348993288591 0.08326006091287445
59.076 37.9244966442953 0.08245038415958351
59.076 37.9255033557047 0.08168537870345552
59.076 37.926510067114094 0.08095198631866221
59.076 37.92751677852349 0.08024222658446292
59.076 37.928523489932886 0.0795512955764078
59.076 37.92953020134228 0.0788763539527689
59.076 37.93053691275168 0.07821576461140212
59.076 37.93154362416107 0.0775686170800604
59.076 37.93255033557047 0.07693443242887445
59.076 37.933557046979864 0.07631298097651588
59.076 37.93456375838926 0.07570417016559615
59.076 37.935570469798655 0.07510797598871241
59.076 37.93657718120805 0.07452440141622535
59.076 37.937583892617454 0.04622621100395462
59.076 37.93859060402685 0.045706612362896096
59.076 37.939597315436245 0.04519923054543149
59.076 37.94060402684564 0.04470401469716158
59.076 37.94161073825504 0.04422088806921752
59.076 37.94261744966443 0.04374974800157701
59.076 37.94362416107383 0.0432904668429472
59.076 37.94463087248322 0.042842893433206734
59.076 37.94563758389262 0.042406854910552294
59.076 37.946644295302015 0.04198215869054281
59.076 37.94765100671141 0.0415685945177561
59.076 37.948657718120806 0.04116593652461031
59.076 37.9496644295302 0.04077394525372141
59.076 37.9506711409396 0.040392369614524845
59.076 37.95167785234899 0.04002094875436994
59.076 37.95268456375839 0.0396594138309527
59.076 37.953691275167785 0.039307489677553896
59.076 37.95469798657718 0.03896489635604331
59.076 37.955704697986576 0.03863135059509626
59.076 37.95671140939597 0.03830656711303801
59.076 37.95771812080537 0.03799025982630992
59.076 37.95872483221476 0.03768214294568445
59.076 37.95973154362416 0.03738193196329311
59.076 37.960738255033554 0.013946299870914853
59.076 37.96174496644295 0.013372690489243728
59.076 37.96275167785235 0.01280341715627066
59.076 37.96375838926175 0.012238107080903091
59.076 37.964765100671144 0.011676370426393683
59.076 37.96577181208054 0.011117796356196207
59.076 37.966778523489936 0.01056194815473318
59.076 37.96778523489933 0.010008357266266216
59.076 37.96879194630873 0.00945651606490628
59.076 37.96979865771812 0.00890586913376927
59.076 37.97080536912752 0.008355802790217817
59.076 37.971812080536914 0.007805632546696969
59.076 37.97281879194631 0.007254588142232343
59.076 37.973825503355705 0.006701795717443334
59.076 37.9748322147651 0.006146256635988601
59.076 37.9758389261745 0.005586822377928756
59.076 37.97684563758389 0.005022164846241801
59.076 37.97785234899329 0.0044507413390571585
59.076 37.978859060402684 0.0038707533509135597
59.076 37.97986577181208 0.003280098282586408
59.076 37.980872483221475 0.0026763130710618643
59.076 37.98187919463087 0.002056508714227162
59.076 37.982885906040266 0.0014172946807703875
59.076 37.98389261744966 0.0007546922968051303
59.076 37.98489932885906 -0.0011452785847738851
59.076 37.98590604026845 -0.001993149301169582
59.076 37.98691275167785 -0.002882809488680787
59.076 37.98791946308725 -0.0038217832527506768
59.076 37.98892617449665 -0.004818820790892669
59.076 37.98993288590604 -0.0058840490254317805
59.076 37.99093959731544 -0.0070291174290276134
59.076 37.991946308724835 -0.040307612500703666
59.076 37.99295302013423 -0.041489828564809546
59.076 37.993959731543626 -0.04279691846969916
59.076 37.99496644295302 -0.044247297970104585
59.076 37.99597315436242 -0.045860899076422185
59.076 37.99697986577181 -0.04765879930532507
59.076 37.99798657718121 -0.04966262353412467
59.076 37.998993288590604 -0.051893672139397565
59.077 37.85 0.12542676620822293
59.077 37.8510067114094 0.15523122740781348
59.077 37.85201342281879 0.15497224787278943
59.077 37.85302013422819 0.1547094308208103
59.077 37.854026845637584 0.154442575257962
59.077 37.85503355704698 0.15417147352101215
59.077 37.856040268456375 0.15389591123026147
59.077 37.85704697986577 0.15361566727183026
59.077 37.85805369127517 0.15333051381310328
59.077 37.85906040268456 0.15304021635532208
59.077 37.86006711409396 0.15274453382761286
59.077 37.861073825503354 0.15244321872694827
59.077 37.86208053691275 0.15213601730877024
59.077 37.86308724832215 0.15182266983327608
59.077 37.86409395973155 0.15150291087252393
59.077 37.865100671140944 0.15117646968363296
59.077 37.86610738255034 0.15084307065359426
59.077 37.867114093959735 0.1505024338211557
59.077 37.86812080536913 0.15015427548128357
59.077 37.869127516778526 0.14979830887767662
59.077 37.87013422818792 0.14943424498859134
59.077 37.87114093959732 0.16048069148945382
59.077 37.87214765100671 0.16008489507473098
59.077 37.87315436241611 0.1596799667065439
59.077 37.874161073825505 0.15926561820405885
59.077 37.8751677852349 0.15884156464219362
59.077 37.876174496644296 0.1584075257822309
59.077 37.87718120805369 0.15796322761633516
59.077 37.87818791946309 0.1575084040239044
59.077 37.87919463087248 0.157042798535143
59.077 37.88020134228188 0.15656616619395333
59.077 37.881208053691275 0.1560782755078534
59.077 37.88221476510067 0.15557891046627367
59.077 37.883221476510066 0.15506787259950594
59.077 37.88422818791946 0.154544983037166
59.077 37.88523489932886 0.15401008450440892
59.077 37.88624161073825 0.1534630431622726
59.077 37.88724832214765 0.15290375014849514
59.077 37.88825503355705 0.15233212259540954
59.077 37.88926174496645 0.15174810377404008
59.077 37.89026845637584 0.1511516618085087
59.077 37.89127516778524 0.1505427860741214
59.077 37.892281879194634 0.1499214798578451
59.077 37.89328859060403 0.14928774699542802
59.077 37.894295302013425 0.1486415688005024
59.077 37.89530201342282 0.1479828653393022
59.077 37.89630872483222 0.14731143145289255
59.077 37.89731543624161 0.1466268320474799
59.077 37.89832214765101 0.1459282317433373
59.077 37.899328859060404 0.14521411896378744
59.077 37.9003355704698 0.14448186095021834
59.077 37.901342281879195 0.14372698987350435
59.077 37.90234899328859 0.1429420663822526
59.077 37.90335570469799 0.1421148926504932
59.077 37.90436241610738 0.14122575947391908
59.077 37.90536912751678 0.1402433512449895
59.077 37.906375838926174 0.13911902563898126
59.077 37.90738255033557 0.13777975300943118
59.077 37.908389261744965 0.1361217029716073
59.077 37.90939597315436 0.13401023037557516
59.077 37.910402684563756 0.131297839495695
59.077 37.91140939597315 0.12787478086983792
59.077 37.91241610738255 0.12375308128571377
59.077 37.91342281879195 0.11914167539829887
59.077 37.914429530201346 0.11443083790523317
59.077 37.91543624161074 0.11005188188259937
59.077 37.91644295302014 0.10630469417408482
59.077 37.91744966442953 0.10328344145599971
59.077 37.91845637583893 0.09036299226798558
59.077 37.919463087248324 0.08854266283256058
59.077 37.92046979865772 0.08709814718160513
59.077 37.921476510067116 0.08591111591257666
59.077 37.92248322147651 0.08489538055888651
59.077 37.92348993288591 0.08399231181843539
59.077 37.9244966442953 0.0831636562056898
59.077 37.9255033557047 0.08238511675465762
59.077 37.926510067114094 0.08164154957551334
59.077 37.92751677852349 0.08092365820737474
59.077 37.928523489932886 0.08022581915078317
59.077 37.92953020134228 0.07954468806505659
59.077 37.93053691275168 0.07887832001834162
59.077 37.93154362416107 0.06954366025811476
59.077 37.93255033557047 0.06883496482137297
59.077 37.933557046979864 0.06813878249896307
59.077 37.93456375838926 0.06745499019682422
59.077 37.935570469798655 0.06678355098145736
59.077 37.93657718120805 0.06612446554351831
59.077 37.937583892617454 0.06547774304465384
59.077 37.93859060402685 0.0648433839821928
59.077 37.939597315436245 0.06422137050869492
59.077 37.94060402684564 0.04465971619096985
59.077 37.94161073825504 0.04417183839280199
59.077 37.94261744966443 0.04369598163257402
59.077 37.94362416107383 0.04323202574151319
59.077 37.94463087248322 0.042779826523922336
59.077 37.94563758389262 0.04233921748131707
59.077 37.946644295302015 0.0419100117482977
59.077 37.94765100671141 0.04149200412696244
59.077 37.948657718120806 0.041084973145534734
59.077 37.9496644295302 0.04068868309181778
59.077 37.9506711409396 0.04030288598839133
59.077 37.95167785234899 0.039927323487162866
59.077 37.95268456375839 0.039561728668320605
59.077 37.953691275167785 0.039205827733833645
59.077 37.95469798657718 0.038859341589468424
59.077 37.955704697986576 0.016361793927099393
59.077 37.95671140939597 0.015754214744777194
59.077 37.95771812080537 0.01515268313399842
59.077 37.95872483221476 0.014556857422591257
59.077 37.95973154362416 0.013966384523644949
59.077 37.960738255033554 0.013380898303048637
59.077 37.96174496644295 0.012800017348778572
59.077 37.96275167785235 0.012223342051036912
59.077 37.96375838926175 0.011650450884750172
59.077 37.964765100671144 0.011080895764533343
59.077 37.96577181208054 0.01051419631754109
59.077 37.966778523489936 0.00994983288987113
59.077 37.96778523489933 0.009387238067809282
59.077 37.96879194630873 0.008825786454792036
59.077 37.96979865771812 0.00826478239840673
59.077 37.97080536912752 0.007703445307962822
59.077 37.971812080536914 0.007140892141999682
59.077 37.97281879194631 0.006576116576318115
59.077 37.973825503355705 0.006007964286673086
59.077 37.9748322147651 0.005435103697372035
59.077 37.9758389261745 0.004855991459762305
59.077 37.97684563758389 0.004268831836423497
59.077 37.97785234899329 0.0036715290843295743
59.077 37.978859060402684 0.003061631863117191
59.077 37.97986577181208 0.0024362686574054965
59.077 37.980872483221475 0.0017920732169155358
59.077 37.98187919463087 0.0011250991160876127
59.077 37.982885906040266 0.0004307227592278487
59.077 37.98389261744966 -0.0002964654323509841
59.077 37.98489932885906 -0.0010627812391874256
59.077 37.98590604026845 -0.0018755768144866972
59.077 37.98691275167785 -0.0027433778180801748
59.077 37.98791946308725 -0.0036760223495555903
59.077 37.98892617449665 -0.004684793542248534
59.077 37.98993288590604 -0.005782533758570903
59.077 37.99093959731544 -0.00919663091383123
59.077 37.991946308724835 -0.010663379077169511
59.077 37.99295302013423 -0.04415053522070314
59.077 37.993959731543626 -0.04575305279272511
59.077 37.99496644295302 -0.04753848417748831
59.077 37.99597315436242 -0.04952843853232688
59.077 37.99697986577181 -0.05174426494212187
59.077 37.99798657718121 -0.054205895945331706
59.077 37.998993288590604 -0.05693039159122175
59.078 37.85 0.15621200159325085
59.078 37.8510067114094 0.15595383499492313
59.078 37.85201342281879 0.15569217549077216
59.078 37.85302013422819 0.15542682733293572
59.078 37.854026845637584 0.15515758831785614
59.078 37.85503355704698 0.15488424970248493
59.078 37.856040268456375 0.1546065961454882
59.078 37.85704697986577 0.15432440567688438
59.078 37.85805369127517 0.1540374496997607
59.078 37.85906040268456 0.1537454930279727
59.078 37.86006711409396 0.15344829396403964
59.078 37.861073825503354 0.15314560442165417
59.078 37.86208053691275 0.15283717009746411
59.078 37.86308724832215 0.15252273069705932
59.078 37.86409395973155 0.1522020202202653
59.078 37.865100671140944 0.16352245260562115
59.078 37.86610738255034 0.16317523557589034
59.078 37.867114093959735 0.16282075865999787
59.078 37.86812080536913 0.1624587367075403
59.078 37.869127516778526 0.1620888814172544
59.078 37.87013422818792 0.1617109020902262
59.078 37.87114093959732 0.16132450648002347
59.078 37.87214765100671 0.16092940174454018
59.078 37.87315436241611 0.16052529550378786
59.078 37.874161073825505 0.16011189700721043
59.078 37.8751677852349 0.15968891841331811
59.078 37.876174496644296 0.15925607618328017
59.078 37.87718120805369 0.15881309258873352
59.078 37.87818791946309 0.1583596973323687
59.078 37.87919463087248 0.15789562927750542
59.078 37.88020134228188 0.15742063827986227
59.078 37.881208053691275 0.156934487110748
59.078 37.88221476510067 0.15643695345518654
59.078 37.883221476510066 0.1559278319604002
59.078 37.88422818791946 0.15540693629824098
59.078 37.88523489932886 0.15487410118705602
59.078 37.88624161073825 0.15432918429066178
59.078 37.88724832214765 0.15377206786862335
59.078 37.88825503355705 0.15320265998288565
59.078 37.88926174496645 0.15262089495550576
59.078 37.89026845637584 0.1520267325950529
59.078 37.89127516778524 0.15142015542365936
59.078 37.892281879194634 0.1508011626752031
59.078 37.89328859060403 0.15016975908915847
59.078 37.894295302013425 0.14952593531744093
59.078 37.89530201342282 0.14886963480937215
59.078 37.89630872483222 0.14820069888627596
59.078 37.89731543624161 0.1475187766333489
59.078 37.89832214765101 0.14682317807218623
59.078 37.899328859060404 0.14611263604834127
59.078 37.9003355704698 0.14538492169351797
59.078 37.901342281879195 0.14463622640900853
59.078 37.90234899328859 0.14386017534660955
59.078 37.90335570469799 0.14304626933021816
59.078 37.90436241610738 0.14217746667185166
59.078 37.90536912751678 0.14122653950609548
59.078 37.906375838926174 0.14015086279291075
59.078 37.90738255033557 0.13888566473980227
59.078 37.908389261744965 0.1373370284680632
59.078 37.90939597315436 0.13537897817283032
59.078 37.910402684563756 0.13286435412084366
59.078 37.91140939597315 0.12966413846841549
59.078 37.91241610738255 0.12574320078617673
59.078 37.91342281879195 0.1212459324189469
59.078 37.914429530201346 0.116516754387641
59.078 37.91543624161074 0.11199416569620087
59.078 37.91644295302014 0.10803250968104601
59.078 37.91744966442953 0.10478873652519066
59.078 37.91845637583893 0.10223630053978372
59.078 37.919463087248324 0.1002493614652288
59.078 37.92046979865772 0.09868038109630703
59.078 37.921476510067116 0.09740159705347654
59.078 37.92248322147651 0.09631717059981931
59.078 37.92348993288591 0.0953607645475987
59.078 37.9244966442953 0.08389078232233389
59.078 37.9255033557047 0.08309725678792798
59.078 37.926510067114094 0.07374108236455963
59.078 37.92751677852349 0.07294486129460608
59.078 37.928523489932886 0.07216983834864998
59.078 37.92953020134228 0.07141208529743406
59.078 37.93053691275168 0.07066930112901255
59.078 37.93154362416107 0.06994017626865127
59.078 37.93255033557047 0.06922399685230898
59.078 37.933557046979864 0.0685203996212023
59.078 37.93456375838926 0.06782922074332706
59.078 37.935570469798655 0.06715040300489543
59.078 37.93657718120805 0.06648393921086668
59.078 37.937583892617454 0.06582983803245594
59.078 37.93859060402685 0.06518810377067226
59.078 37.939597315436245 0.06455872475086256
59.078 37.94060402684564 0.06394166707258556
59.078 37.94161073825504 0.06333687168176702
59.078 37.94261744966443 0.06274425350008997
59.078 37.94362416107383 0.04317087886225447
59.078 37.94463087248322 0.04271402400037033
59.078 37.94563758389262 0.0422688117962543
59.078 37.946644295302015 0.04183506118142196
59.078 37.94765100671141 0.041412572083818676
59.078 37.948657718120806 0.04100112748043026
59.078 37.9496644295302 0.04060049543739838
59.078 37.9506711409396 0.040210431100223015
59.078 37.95167785234899 0.01835169223736757
59.078 37.95268456375839 0.017709332864114136
59.078 37.953691275167785 0.017074328891534988
59.078 37.95469798657718 0.016446366119803667
59.078 37.955704697986576 0.015825117690460723
59.078 37.95671140939597 0.015210243712405206
59.078 37.95771812080537 0.014601390428181413
59.078 37.95872483221476 0.013998188855404346
59.078 37.95973154362416 0.013400252826557227
59.078 37.960738255033554 0.01280717633629503
59.078 37.96174496644295 0.012218530088127073
59.078 37.96275167785235 0.011633857111811804
59.078 37.96375838926175 0.011052667298540743
59.078 37.964765100671144 0.010474430671869064
59.078 37.96577181208054 0.009898569178817564
59.078 37.966778523489936 0.00932444674574106
59.078 37.96778523489933 0.008751357297889582
59.078 37.96879194630873 0.00817851038870428
59.078 37.96979865771812 0.007605014024892848
59.078 37.97080536912752 0.007029854205519541
59.078 37.971812080536914 0.0064518706182827845
59.078 37.97281879194631 0.0058697278547694895
59.078 37.973825503355705 0.0052818814205116554
59.078 37.9748322147651 0.004686537729108448
59.078 37.9758389261745 0.004081607188632628
59.078 37.97684563758389 0.00346464942231001
59.078 37.97785234899329 0.0028328096288335047
59.078 37.978859060402684 0.0021827451019354703
59.078 37.97986577181208 0.001510541024092659
59.078 37.980872483221475 0.0008116148682824308
59.078 37.98187919463087 8.060914251571336e-05
59.078 37.982885906040266 -0.0006887271289158746
59.078 37.98389261744966 -0.001503666776516744
59.078 37.98489932885906 -0.0023726398005075103
59.078 37.98590604026845 -0.0033053705997531414
59.078 37.98691275167785 -0.004313009184272125
59.078 37.98791946308725 -0.005408244616892911
59.078 37.98892617449665 -0.006605384091328832
59.078 37.98993288590604 -0.00792037523276706
59.078 37.99093959731544 -0.009370742662911564
59.078 37.991946308724835 -0.01097540324163018
59.078 37.99295302013423 -0.03518782159738784
59.078 37.993959731543626 -0.03698623931447423
59.078 37.99496644295302 -0.03900030263984461
59.078 37.99597315436242 -0.05404157468712968
59.078 37.99697986577181 -0.056748746725994594
59.078 37.99798657718121 -0.059730649621969036
59.078 37.998993288590604 -0.0629942333680859
59.079 37.85 0.1569722855987514
59.079 37.8510067114094 0.15671031743348932
59.079 37.85201342281879 0.1564450387638896
59.079 37.85302013422819 0.1561762513877158
59.079 37.854026845637584 0.15590375082209176
59.079 37.85503355704698 0.15562732620854147
59.079 37.856040268456375 0.15534676024212976
59.079 37.85704697986577 0.15506182912806013
59.079 37.85805369127517 0.15477230256929728
59.079 37.85906040268456 0.15447794378903912
59.079 37.86006711409396 0.16603060601549333
59.079 37.861073825503354 0.1657139509267923
59.079 37.86208053691275 0.16539156642974404
59.079 37.86308724832215 0.1650631895307952
59.079 37.86409395973155 0.1647285516197955
59.079 37.865100671140944 0.16438737879421736
59.079 37.86610738255034 0.16403939225188868
59.079 37.867114093959735 0.16368430875765877
59.079 37.86812080536913 0.16332184118950685
59.079 37.869127516778526 0.16295169916961968
59.079 37.87013422818792 0.16257358978580397
59.079 37.87114093959732 0.1621872184083935
59.079 37.87214765100671 0.16179228960755998
59.079 37.87315436241611 0.16138850817542766
59.079 37.874161073825505 0.16097558025678538
59.079 37.8751677852349 0.16055321459147134
59.079 37.876174496644296 0.16012112387043076
59.079 37.87718120805369 0.15967902620615684
59.079 37.87818791946309 0.15922664671666104
59.079 37.87919463087248 0.1587637192199456
59.079 37.88020134228188 0.1582899880331877
59.079 37.881208053691275 0.15780520986724084
59.079 37.88221476510067 0.1573091558018944
59.079 37.883221476510066 0.1568016133201162
59.079 37.88422818791946 0.15628238836903047
59.079 37.88523489932886 0.1557513073994613
59.079 37.88624161073825 0.15520821931156323
59.079 37.88724832214765 0.15465299719623282
59.079 37.88825503355705 0.15408553970199013
59.079 37.88926174496645 0.15350577176153235
59.079 37.89026845637584 0.15291364425899448
59.079 37.89127516778524 0.15230913197229726
59.079 37.892281879194634 0.1516922287265746
59.079 37.89328859060403 0.15106293805096432
59.079 37.894295302013425 0.15042125658930325
59.079 37.89530201342282 0.14976714583039274
59.079 37.89630872483222 0.14910048500059783
59.079 37.89731543624161 0.1484209935685268
59.079 37.89832214765101 0.14772810474722806
59.079 37.899328859060404 0.1470207600801418
59.079 37.9003355704698 0.14629707728564487
59.079 37.901342281879195 0.1455538155709405
59.079 37.90234899328859 0.1447855201009868
59.079 37.90335570469799 0.14398316565426275
59.079 37.90436241610738 0.1431320382046909
59.079 37.90536912751678 0.14220850822278225
59.079 37.906375838926174 0.14117532563695415
59.079 37.90738255033557 0.1399752896388578
59.079 37.908389261744965 0.13852405349437852
59.079 37.90939597315436 0.1367052174998056
59.079 37.910402684563756 0.13437556647265633
59.079 37.91140939597315 0.13139409648714961
59.079 37.91241610738255 0.12768716620774137
59.079 37.91342281879195 0.12333713053239623
59.079 37.914429530201346 0.11863219274923016
59.079 37.91543624161074 0.11400009891293772
59.079 37.91644295302014 0.10983850672843899
59.079 37.91744966442953 0.10636898118434394
59.079 37.91845637583893 0.10361377275612314
59.079 37.919463087248324 0.10146788849920839
59.079 37.92046979865772 0.09978431659953621
59.079 37.921476510067116 0.0984268908815559
59.079 37.92248322147651 0.08848718479204506
59.079 37.92348993288591 0.08741544244536238
59.079 37.9244966442953 0.08643900560519004
59.079 37.9255033557047 0.08552569428923426
59.079 37.926510067114094 0.08465523184241364
59.079 37.92751677852349 0.08381505810923069
59.079 37.928523489932886 0.08299750674496635
59.079 37.92953020134228 0.07182744026976381
59.079 37.93053691275168 0.07107653145539697
59.079 37.93154362416107 0.07033961119249671
59.079 37.93255033557047 0.0696158141596937
59.079 37.933557046979864 0.06890468875119665
59.079 37.93456375838926 0.0682060216520382
59.079 37.935570469798655 0.06751972997321554
59.079 37.93657718120805 0.06684579531427437
59.079 37.937583892617454 0.06618422382103305
59.079 37.93859060402685 0.06553502235800986
59.079 37.939597315436245 0.06489818467400633
59.079 37.94060402684564 0.06427368376752937
59.079 37.94161073825504 0.06366146809895638
59.079 37.94261744966443 0.06306146018652459
59.079 37.94362416107383 0.06247355667358272
59.079 37.94463087248322 0.061897629295202816
59.079 37.94563758389262 0.06133352638322494
59.079 37.946644295302015 0.04175721150142006
59.079 37.94765100671141 0.02051686430669205
59.079 37.948657718120806 0.019834560917339414
59.079 37.9496644295302 0.01916078360992645
59.079 37.9506711409396 0.01849526750779807
59.079 37.95167785234899 0.017837730529019512
59.079 37.95268456375839 0.017187874022127358
59.079 37.953691275167785 0.01654538306295731
59.079 37.95469798657718 0.015909926358117766
59.079 37.955704697986576 0.01528115569500322
59.079 37.95671140939597 0.014658704870188895
59.079 37.95771812080537 0.014042188017548248
59.079 37.95872483221476 0.01343119724398073
59.079 37.95973154362416 0.012825299464257055
59.079 37.960738255033554 0.01222403230679223
59.079 37.96174496644295 0.011626898938365045
59.079 37.96275167785235 0.011033361627705369
59.079 37.96375838926175 0.010442833834943866
59.079 37.964765100671144 0.009854670574935859
59.079 37.96577181208054 0.009268156757891971
59.079 37.966778523489936 0.008682493158726465
59.079 37.96778523489933 0.008096779607749224
59.079 37.96879194630873 0.007509994928776378
59.079 37.96979865771812 0.006920973077246376
59.079 37.97080536912752 0.0063283748509219105
59.079 37.971812080536914 0.005730654461598362
59.079 37.97281879194631 0.005126020171548215
59.079 37.973825503355705 0.004512388118916931
59.079 37.9748322147651 0.0038873283916554223
59.079 37.9758389261745 0.003248002374027281
59.079 37.97684563758389 0.0025910904037573686
59.079 37.97785234899329 0.0019127088714975642
59.079 37.978859060402684 0.0012083161089536665
59.079 37.97986577181208 0.00047260680388115997
59.079 37.980872483221475 -0.0003006046755363416
59.079 37.98187919463087 -0.001118510671265123
59.079 37.982885906040266 -0.0019894435045146944
59.079 37.98389261744966 -0.0029230107186317793
59.079 37.98489932885906 -0.003930224564767385
59.079 37.98590604026845 -0.005023614275512244
59.079 37.98691275167785 -0.006217304961169064
59.079 37.98791946308725 -0.0075270412954025305
59.079 37.98892617449665 -0.008970127782119641
59.079 37.98993288590604 -0.010565250924436102
59.079 37.99093959731544 -0.012332143193059582
59.079 37.991946308724835 -0.014291046181395092
59.079 37.99295302013423 -0.0164619335303485
59.079 37.993959731543626 -0.040947438481045095
59.079 37.99496644295302 -0.043417781867460654
59.079 37.99597315436242 -0.046146695620222236
59.079 37.99697986577181 -0.0491403462942895
59.079 37.99798657718121 -0.05239975308443015
59.079 37.998993288590604 -0.05592913926002855
59.08 37.85 0.15777657174683551
59.08 37.8510067114094 0.1575095151424675
59.08 37.85201342281879 0.15723937275353833
59.08 37.85302013422819 0.15696594252705748
59.08 37.854026845637584 0.15668901634437563
59.08 37.85503355704698 0.15640837991472856
59.08 37.856040268456375 0.16816227392044122
59.08 37.85704697986577 0.1678625208205931
59.08 37.85805369127517 0.1675582507207211
59.08 37.85906040268456 0.1672492215925263
59.08 37.86006711409396 0.16693518522841516
59.08 37.861073825503354 0.16661588732547156
59.08 37.86208053691275 0.16629106761605586
59.08 37.86308724832215 0.16596046004983322
59.08 37.86409395973155 0.16562379303221744
59.08 37.865100671140944 0.1958128541533406
59.08 37.86610738255034 0.1953107979501255
59.08 37.867114093959735 0.19480245573399066
59.08 37.86812080536913 0.19428751565889762
59.08 37.869127516778526 0.19376566424088096
59.08 37.87013422818792 0.19323658692869236
59.08 37.87114093959732 0.1926999687767073
59.08 37.87214765100671 0.19215549522457037
59.08 37.87315436241611 0.19160285298760202
59.08 37.874161073825505 0.19104173106146433
59.08 37.8751677852349 0.19047182184398476
59.08 37.876174496644296 0.18989282237603683
59.08 37.87718120805369 0.18930443570220717
59.08 37.87818791946309 0.18870637235058257
59.08 37.87919463087248 0.18809835192897467
59.08 37.88020134228188 0.18748010483238406
59.08 37.881208053691275 0.18685137405327018
59.08 37.88221476510067 0.18621191708151483
59.08 37.883221476510066 0.18556150787452574
59.08 37.88422818791946 0.1848999388686789
59.08 37.88523489932886 0.18422702298925944
59.08 37.88624161073825 0.183542595594795
59.08 37.88724832214765 0.18284651625876172
59.08 37.88825503355705 0.1821386702395216
59.08 37.88926174496645 0.18141896940665445
59.08 37.89026845637584 0.1806873522594071
59.08 37.89127516778524 0.17994378245992307
59.08 37.892281879194634 0.17918824495993596
59.08 37.89328859060403 0.1784207382441567
59.08 37.894295302013425 0.17764126031460648
59.08 37.89530201342282 0.17684978458612366
59.08 37.89630872483222 0.17604621951267185
59.08 37.89731543624161 0.17523034196913204
59.08 37.89832214765101 0.17440168830372954
59.08 37.899328859060404 0.1735593771853213
59.08 37.9003355704698 0.17270182279864962
59.08 37.901342281879195 0.14648126663751843
59.08 37.90234899328859 0.14571968018974418
59.08 37.90335570469799 0.14492731300837453
59.08 37.90436241610738 0.14409147809731357
59.08 37.90536912751678 0.14319170340195386
59.08 37.906375838926174 0.14219551433697167
59.08 37.90738255033557 0.1410525839285829
59.08 37.908389261744965 0.13968761916659944
59.08 37.90939597315436 0.13799418510966813
59.08 37.910402684563756 0.13583562699282206
59.08 37.91140939597315 0.1330651347493329
59.08 37.91241610738255 0.1295793582822189
59.08 37.91342281879195 0.12540398913813372
59.08 37.914429530201346 0.12076474472802227
59.08 37.91543624161074 0.11606158712504233
59.08 37.91644295302014 0.1117209262636601
59.08 37.91744966442953 0.10802704668219033
59.08 37.91845637583893 0.09632587016933755
59.08 37.919463087248324 0.09392571797614847
59.08 37.92046979865772 0.09203305613981916
59.08 37.921476510067116 0.09050503865571076
59.08 37.92248322147651 0.08922591176319868
59.08 37.92348993288591 0.08811193581223833
59.08 37.9244966442953 0.0871063779798347
59.08 37.9255033557047 0.08617231627164179
59.08 37.926510067114094 0.08528633970715285
59.08 37.92751677852349 0.08443387584773539
59.08 37.928523489932886 0.0836059899609785
59.08 37.92953020134228 0.08279728486689367
59.08 37.93053691275168 0.08200455675859653
59.08 37.93154362416107 0.08122594751752905
59.08 37.93255033557047 0.08046041499588617
59.08 37.933557046979864 0.06929172963168334
59.08 37.93456375838926 0.0685854528430812
59.08 37.935570469798655 0.06789157382416061
59.08 37.93657718120805 0.0672100590505918
59.08 37.937583892617454 0.06654090971056177
59.08 37.93859060402685 0.06588413371842439
59.08 37.939597315436245 0.06523972929917765
59.08 37.94060402684564 0.06460767575055107
59.08 37.94161073825504 0.0639879286586205
59.08 37.94261744966443 0.06338041787467853
59.08 37.94362416107383 0.04284435964675026
59.08 37.94463087248322 0.0419881098064255
59.08 37.94563758389262 0.04114116557316811
59.08 37.946644295302015 0.04030334505947766
59.08 37.94765100671141 0.03947444259940043
59.08 37.948657718120806 0.03865422993876242
59.08 37.9496644295302 0.018655322205964473
59.08 37.9506711409396 0.01798212271728462
59.08 37.95167785234899 0.017316906000570464
59.08 37.95268456375839 0.016659357548457293
59.08 37.953691275167785 0.01600914227298178
59.08 37.95469798657718 0.015365903717699227
59.08 37.955704697986576 0.014729262678954923
59.08 37.95671140939597 0.014098815141278902
59.08 37.95771812080537 0.013474129416766553
59.08 37.95872483221476 0.012854742359402642
59.08 37.95973154362416 0.012240154502535454
59.08 37.960738255033554 0.011629823940629697
59.08 37.96174496644295 0.011023158744157751
59.08 37.96275167785235 0.010419507658766144
59.08 37.96375838926175 0.009818148796141235
59.08 37.964765100671144 0.009218275973139591
59.08 37.96577181208054 0.008618982298411001
59.08 37.966778523489936 0.008019240540464712
59.08 37.96778523489933 0.007417879739307409
59.08 37.96879194630873 0.006813557445532598
59.08 37.96979865771812 0.006204726888666554
59.08 37.97080536912752 0.005589598293749359
59.08 37.971812080536914 0.004966093487813589
59.08 37.97281879194631 0.0043317928753576415
59.08 37.973825503355705 0.003683873827694719
59.08 37.9748322147651 0.0030190395458409777
59.08 37.9758389261745 0.0023334375494159336
59.08 37.97684563758389 0.0016225671549507
59.08 37.97785234899329 0.000881175691329561
59.08 37.978859060402684 0.00010314383068036129
59.08 37.97986577181208 -0.0007186386169422687
59.08 37.980872483221475 -0.0015924036714872573
59.08 37.98187919463087 -0.0025276381243266415
59.08 37.982885906040266 -0.003535210913331834
59.08 37.98389261744966 -0.004627483770704342
59.08 37.98489932885906 -0.005818389405786727
59.08 37.98590604026845 -0.0071234559817801996
59.08 37.98691275167785 -0.008559750456294965
59.08 37.98791946308725 -0.010145707068803817
59.08 37.98892617449665 -0.011900801973434929
59.08 37.98993288590604 -0.01384503252101281
59.08 37.99093959731544 -0.015998162659617506
59.08 37.991946308724835 -0.018378708376926633
59.08 37.99295302013423 -0.021002666927781296
59.08 37.993959731543626 -0.04578323365243956
59.08 37.99496644295302 -0.048741898103577284
59.08 37.99597315436242 -0.05196351340565297
59.08 37.99697986577181 -0.05545148661896572
59.08 37.99798657718121 -0.05925469081043172
59.08 37.998993288590604 -0.06368369466816931
59.081 37.85 0.15863698293606354
59.081 37.8510067114094 0.1583631406404994
59.081 37.85201342281879 0.17031045985539905
59.081 37.85302013422819 0.17002023233576755
59.081 37.854026845637584 0.1697266914071991
59.081 37.85503355704698 0.16942961359347417
59.081 37.856040268456375 0.16912876954531084
59.081 37.85704697986577 0.20118076073999663
59.081 37.85805369127517 0.20070870181934225
59.081 37.85906040268456 0.20023328967663145
59.081 37.86006711409396 0.19975421097122464
59.081 37.861073825503354 0.1992711507918411
59.081 37.86208053691275 0.19878379246857247
59.081 37.86308724832215 0.19829181745485394
59.081 37.86409395973155 0.19779490528305338
59.081 37.865100671140944 0.19729273359754118
59.081 37.86610738255034 0.19678497826953334
59.081 37.867114093959735 0.1962713135980016
59.081 37.86812080536913 0.19575141260115028
59.081 37.869127516778526 0.19522494740309787
59.081 37.87013422818792 0.19469158972032316
59.081 37.87114093959732 0.19415101145234376
59.081 37.87214765100671 0.19360288538098872
59.081 37.87315436241611 0.19304688598222652
59.081 37.874161073825505 0.19248269035404092
59.081 37.8751677852349 0.1919099792633186
59.081 37.876174496644296 0.1913284383137871
59.081 37.87718120805369 0.19073775923596842
59.081 37.87818791946309 0.1901376412988342
59.081 37.87919463087248 0.18952779284098395
59.081 37.88020134228188 0.18890793291686098
59.081 37.881208053691275 0.18827779305057946
59.081 37.88221476510067 0.187637119085697
59.081 37.883221476510066 0.18698567311348743
59.081 37.88422818791946 0.1863232354540639
59.081 37.88523489932886 0.18564960665230165
59.081 37.88624161073825 0.184964609431866
59.081 37.88724832214765 0.1842680905219624
59.081 37.88825503355705 0.18355992222611128
59.081 37.88926174496645 0.18284000353058244
59.081 37.89026845637584 0.17597398474836745
59.081 37.89127516778524 0.17526652092808886
59.081 37.892281879194634 0.1745471979171643
59.081 37.89328859060403 0.17381600559722693
59.081 37.894295302013425 0.17307293872882085
59.081 37.89530201342282 0.17231797645235716
59.081 37.89630872483222 0.17155104773721613
59.081 37.89731543624161 0.17077197416463413
59.081 37.89832214765101 0.1699803761484553
59.081 37.899328859060404 0.16917552021979626
59.081 37.9003355704698 0.1683560714811071
59.081 37.901342281879195 0.16751969400949257
59.081 37.90234899328859 0.16666240897679463
59.081 37.90335570469799 0.16577757080378244
59.081 37.90436241610738 0.16485425200396875
59.081 37.90536912751678 0.1638747411576251
59.081 37.906375838926174 0.16281078504857258
59.081 37.90738255033557 0.161618245431904
59.081 37.908389261744965 0.1375932788397978
59.081 37.90939597315436 0.13603787927434546
59.081 37.910402684563756 0.13406182747064327
59.081 37.91140939597315 0.13151752220470836
59.081 37.91241610738255 0.1282802785501661
59.081 37.91342281879195 0.12432649180401653
59.081 37.914429530201346 0.11981726051611079
59.081 37.91543624161074 0.10757689547996051
59.081 37.91644295302014 0.10303485434233478
59.081 37.91744966442953 0.09907351894109011
59.081 37.91845637583893 0.09759020953224548
59.081 37.919463087248324 0.09500039637029309
59.081 37.92046979865772 0.0929638394795926
59.081 37.921476510067116 0.09133357421973264
59.081 37.92248322147651 0.0899842421753983
59.081 37.92348993288591 0.08882256458872495
59.081 37.9244966442953 0.08778428035868367
59.081 37.9255033557047 0.08682712859970063
59.081 37.926510067114094 0.08592412563861003
59.081 37.92751677852349 0.08505838796948201
59.081 37.928523489932886 0.08421951692409743
59.081 37.92953020134228 0.08340120091932231
59.081 37.93053691275168 0.08259967111618272
59.081 37.93154362416107 0.08181272379575139
59.081 37.93255033557047 0.08103910797955054
59.081 37.933557046979864 0.08027814441914244
59.081 37.93456375838926 0.07952948967440907
59.081 37.935570469798655 0.07879299064043664
59.081 37.93657718120805 0.07806859527385467
59.081 37.937583892617454 0.06689993610857507
59.081 37.93859060402685 0.06623545670788714
59.081 37.939597315436245 0.06558335637807436
59.081 37.94060402684564 0.045423089877918446
59.081 37.94161073825504 0.044526591321787
59.081 37.94261744966443 0.04363980121889069
59.081 37.94362416107383 0.042762618905584845
59.081 37.94463087248322 0.04189491528557812
59.081 37.94563758389262 0.04103653334159589
59.081 37.946644295302015 0.040187288947010354
59.081 37.94765100671141 0.058367566400670715
59.081 37.948657718120806 0.05773734057166679
59.081 37.9496644295302 0.05711664798880074
59.081 37.9506711409396 0.056505193898950715
59.081 37.95167785234899 0.041564540965037505
59.081 37.95268456375839 0.04109808097124516
59.081 37.953691275167785 0.040639390949475586
59.081 37.95469798657718 0.04018807068124124
59.081 37.955704697986576 0.03974369055303952
59.081 37.95671140939597 0.039305788284328874
59.081 37.95771812080537 0.03887386460892382
59.081 37.95872483221476 0.038447377732617874
59.081 37.95973154362416 0.03802573635892115
59.081 37.960738255033554 0.037608291038358214
59.081 37.96174496644295 0.03719432355404219
59.081 37.96275167785235 0.036783034006913684
59.081 37.96375838926175 0.03637352520773246
59.081 37.964765100671144 0.03596478391904391
59.081 37.96577181208054 0.03555565841987676
59.081 37.966778523489936 0.035144831788875126
59.081 37.96778523489933 0.03473079022062244
59.081 37.96879194630873 0.034311785607949535
59.081 37.96979865771812 0.03388579154614709
59.081 37.97080536912752 0.03345045185174632
59.081 37.971812080536914 0.03300302065253018
59.081 37.97281879194631 0.03254029311627381
59.081 37.973825503355705 0.032058525971077355
59.081 37.9748322147651 0.03155334716911974
59.081 37.9758389261745 0.031019654411059826
59.081 37.97684563758389 0.030451502849787076
59.081 37.97785234899329 0.029841983219657166
59.081 37.978859060402684 0.029183093001307597
59.081 37.97986577181208 -0.0021178488126406027
59.081 37.980872483221475 -0.003126647697163358
59.081 37.98187919463087 -0.004218609739967447
59.081 37.982885906040266 -0.005407463330801551
59.081 37.98389261744966 -0.006708504616990112
59.081 37.98489932885906 -0.008138543238455542
59.081 37.98590604026845 -0.009715736482066698
59.081 37.98691275167785 -0.011459274051655886
59.081 37.98791946308725 -0.013388873218049456
59.081 37.98892617449665 -0.01552404690487412
59.081 37.98993288590604 -0.017883119081770506
59.081 37.99093959731544 -0.02048198996616749
59.081 37.991946308724835 -0.023332721275073624
59.081 37.99295302013423 -0.026442224274925857
59.081 37.993959731543626 -0.029812212631045963
59.081 37.99496644295302 -0.054961478167398756
59.081 37.99597315436242 -0.05871074721438349
59.081 37.99697986577181 -0.06304466402636806
59.081 37.99798657718121 -0.06936468040434624
59.081 37.998993288590604 -0.08279408749942269
59.082 37.85 0.17196361449233755
59.082 37.8510067114094 0.1716699385463978
59.082 37.85201342281879 0.20520761657663217
59.082 37.85302013422819 0.20473028532422155
59.082 37.854026845637584 0.2042520913526367
59.082 37.85503355704698 0.2037726765203848
59.082 37.856040268456375 0.2032916858990347
59.082 37.85704697986577 0.20280876710471873
59.082 37.85805369127517 0.20232356969379528
59.082 37.85906040268456 0.20183574462427173
59.082 37.86006711409396 0.20134494378500728
59.082 37.861073825503354 0.20085081959496803
59.082 37.86208053691275 0.20035302467513771
59.082 37.86308724832215 0.1998512115960942
59.082 37.86409395973155 0.19934503270450668
59.082 37.865100671140944 0.1988341400320271
59.082 37.86610738255034 0.198318185290498
59.082 37.867114093959735 0.1977968199574243
59.082 37.86812080536913 0.19726969545588202
59.082 37.869127516778526 0.19673646343321483
59.082 37.87013422818792 0.19619677614281922
59.082 37.87114093959732 0.19565028693326653
59.082 37.87214765100671 0.1950966508489541
59.082 37.87315436241611 0.19453552534611776
59.082 37.874161073825505 0.19396657112763718
59.082 37.8751677852349 0.19338945309958902
59.082 37.876174496644296 0.19280384145166418
59.082 37.87718120805369 0.1922094128625721
59.082 37.87818791946309 0.1916058518303929
59.082 37.87919463087248 0.19099285212610928
59.082 37.88020134228188 0.1903701183664345
59.082 37.881208053691275 0.18301383439678634
59.082 37.88221476510067 0.18240709255551163
59.082 37.883221476510066 0.18178987644505448
59.082 37.88422818791946 0.1811619499635881
59.082 37.88523489932886 0.18052309710311795
59.082 37.88624161073825 0.17987312389774338
59.082 37.88724832214765 0.17921186027632321
59.082 37.88825503355705 0.17853916170482317
59.082 37.88926174496645 0.17785491044146812
59.082 37.89026845637584 0.17715901612865434
59.082 37.89127516778524 0.1764514152864503
59.082 37.892281879194634 0.1757320690159822
59.082 37.89328859060403 0.1750009578072464
59.082 37.894295302013425 0.17425807167643956
59.082 37.89530201342282 0.17510251518020428
59.082 37.89630872483222 0.17436557042481732
59.082 37.89731543624161 0.17361669556746814
59.082 37.89832214765101 0.17285558042822413
59.082 37.899328859060404 0.17208161604244426
59.082 37.9003355704698 0.17129368107542045
59.082 37.901342281879195 0.1704897966109348
59.082 37.90234899328859 0.1696665707259052
59.082 37.90335570469799 0.1688183103702529
59.082 37.90436241610738 0.16793561477894192
59.082 37.90536912751678 0.16700318214608156
59.082 37.906375838926174 0.16599647792217412
59.082 37.90738255033557 0.16487689994769755
59.082 37.908389261744965 0.16358533326445676
59.082 37.90939597315436 0.1620349790085885
59.082 37.910402684563756 0.16010689564979705
59.082 37.91140939597315 0.15765657798128646
59.082 37.91241610738255 0.14627704900798646
59.082 37.91342281879195 0.14235483228471693
59.082 37.914429530201346 0.11910804213599585
59.082 37.91543624161074 0.11435860655731746
59.082 37.91644295302014 0.10971679697734571
59.082 37.91744966442953 0.10557016236162563
59.082 37.91845637583893 0.10212342938794733
59.082 37.919463087248324 0.09938539590229209
59.082 37.92046979865772 0.09724491681898038
59.082 37.921476510067116 0.0955544523696286
59.082 37.92248322147651 0.0941799090150609
59.082 37.92348993288591 0.09301810924917049
59.082 37.9244966442953 0.09199657034353502
59.082 37.9255033557047 0.09106704679425107
59.082 37.926510067114094 0.09019848965622851
59.082 37.92751677852349 0.09128418412194347
59.082 37.928523489932886 0.09045717034524273
59.082 37.92953020134228 0.08965217101505613
59.082 37.93053691275168 0.08886475763882465
59.082 37.93154362416107 0.08240385571385597
59.082 37.93255033557047 0.08162192081428464
59.082 37.933557046979864 0.08085280486421814
59.082 37.93456375838926 0.08009607883548395
59.082 37.935570469798655 0.07935154140855596
59.082 37.93657718120805 0.07861911519800001
59.082 37.937583892617454 0.05973674272910944
59.082 37.93859060402685 0.058760293016827655
59.082 37.939597315436245 0.07283385896166764
59.082 37.94060402684564 0.06342783135591193
59.082 37.94161073825504 0.06271706758544543
59.082 37.94261744966443 0.062017207414710694
59.082 37.94362416107383 0.061328140880661824
59.082 37.94463087248322 0.0606497280570029
59.082 37.94563758389262 0.05998179917095753
59.082 37.946644295302015 0.05932415505274414
59.082 37.94765100671141 0.05867656765983685
59.082 37.948657718120806 0.05803878048991152
59.082 37.9496644295302 0.057410508737684246
59.082 37.9506711409396 0.056791439072583064
59.082 37.95167785234899 0.05618122892256712
59.082 37.95268456375839 0.05557950514885664
59.082 37.953691275167785 0.04074239030156658
59.082 37.95469798657718 0.04028556818531365
59.082 37.955704697986576 0.039835432674184205
59.082 37.95671140939597 0.039391453610930105
59.082 37.95771812080537 0.03895304955518718
59.082 37.95872483221476 0.03851957957405984
59.082 37.95973154362416 0.0380903330953896
59.082 37.960738255033554 0.037664517491687935
59.082 37.96174496644295 0.037241243007850616
59.082 37.96275167785235 0.0368195045838563
59.082 37.96375838926175 0.03639816005498363
59.082 37.964765100671144 0.03597590413713832
59.082 37.96577181208054 0.03555123752633109
59.082 37.966778523489936 0.03512243036170043
59.082 37.96778523489933 0.0346874792271174
59.082 37.96879194630873 0.03424405680550059
59.082 37.96979865771812 0.0337894532660148
59.082 37.97080536912752 0.0333205084760732
59.082 37.971812080536914 0.032833534214903516
59.082 37.97281879194631 0.032324225761123634
59.082 37.973825503355705 0.03178756258420472
59.082 37.9748322147651 0.03121769845663126
59.082 37.9758389261745 0.03060784220642069
59.082 37.97684563758389 0.02995013165437827
59.082 37.97785234899329 0.02923550515087884
59.082 37.978859060402684 0.028453577675462506
59.082 37.97986577181208 0.027592531810603633
59.082 37.980872483221475 0.0266390381247885
59.082 37.98187919463087 0.025578224571085956
59.082 37.982885906040266 0.02439372020470312
59.082 37.98389261744966 0.023067804315088185
59.082 37.98489932885906 0.02158169696757338
59.082 37.98590604026845 0.019916029359960948
59.082 37.98691275167785 0.018051529956568873
59.082 37.98791946308725 -0.017375517206567777
59.082 37.98892617449665 -0.019948374110343503
59.082 37.98993288590604 -0.022769086041382926
59.082 37.99093959731544 -0.025844695215714503
59.082 37.991946308724835 -0.029177085187356584
59.082 37.99295302013423 -0.032768779737628845
59.082 37.993959731543626 -0.0366579995491966
59.082 37.99496644295302 -0.062389314261962814
59.082 37.99597315436242 -0.06844962081318716
59.082 37.99697986577181 -0.08113956262760055
59.082 37.99798657718121 -0.10621393872095478
59.082 37.998993288590604 -0.1293911920464686
59.083 37.85 0.20806694352951566
59.083 37.8510067114094 0.2075633946781855
59.083 37.85201342281879 0.20706113258232087
59.083 37.85302013422819 0.2065597163693272
59.083 37.854026845637584 0.20605871419729882
59.083 37.85503355704698 0.20555770223020328
59.083 37.856040268456375 0.20505626368334828
59.083 37.85704697986577 0.20455398794010682
59.083 37.85805369127517 0.20405046974104177
59.083 37.85906040268456 0.20354530844683405
59.083 37.86006711409396 0.20303810737679534
59.083 37.861073825503354 0.20252847322495784
59.083 37.86208053691275 0.2020160155560341
59.083 37.86308724832215 0.20150034638392875
59.083 37.86409395973155 0.20098107983571523
59.083 37.865100671140944 0.20045783190419883
59.083 37.86610738255034 0.1999302202926485
59.083 37.867114093959735 0.19939786435529797
59.083 37.86812080536913 0.19886038513746873
59.083 37.869127516778526 0.19831740551935687
59.083 37.87013422818792 0.19776855046750538
59.083 37.87114093959732 0.1972134473979589
59.083 37.87214765100671 0.19665172665508188
59.083 37.87315436241611 0.19608302210970707
59.083 37.874161073825505 0.19550697187992078
59.083 37.8751677852349 0.18770731802140259
59.083 37.876174496644296 0.1871518809141579
59.083 37.87718120805369 0.18658813083925146
59.083 37.87818791946309 0.18601573077631292
59.083 37.87919463087248 0.18543435345701234
59.083 37.88020134228188 0.18484368284891328
59.083 37.881208053691275 0.1842434157577251
59.083 37.88221476510067 0.18363326353862458
59.083 37.883221476510066 0.18301295390264014
59.083 37.88422818791946 0.18238223279757554
59.083 37.88523489932886 0.18174086633321931
59.083 37.88624161073825 0.18108864270618558
59.083 37.88724832214765 0.18042537405783246
59.083 37.88825503355705 0.18108105868142937
59.083 37.88926174496645 0.18042487892952605
59.083 37.89026845637584 0.17975732357384944
59.083 37.89127516778524 0.17907831164894356
59.083 37.892281879194634 0.1783877881683364
59.083 37.89328859060403 0.17768571994681867
59.083 37.894295302013425 0.17697208738928277
59.083 37.89530201342282 0.17624686977702952
59.083 37.89630872483222 0.17551002007127486
59.083 37.89731543624161 0.17476142281044404
59.083 37.89832214765101 0.17400082473485715
59.083 37.899328859060404 0.17322772142658585
59.083 37.9003355704698 0.17244117308756593
59.083 37.901342281879195 0.17163950642794032
59.083 37.90234899328859 0.17081983431702005
59.083 37.90335570469799 0.16997728606553375
59.083 37.90436241610738 0.16910378421564462
59.083 37.90536912751678 0.16818612642952568
59.083 37.906375838926174 0.1672030438937071
59.083 37.90738255033557 0.16612085979118774
59.083 37.908389261744965 0.16488751204876892
59.083 37.90939597315436 0.15523904367811553
59.083 37.910402684563756 0.1533528154218042
59.083 37.91140939597315 0.15098957497056756
59.083 37.91241610738255 0.1480035321280785
59.083 37.91342281879195 0.14429828604504094
59.083 37.914429530201346 0.13991365562641012
59.083 37.91543624161074 0.13509194492282767
59.083 37.91644295302014 0.1302417637408743
59.083 37.91744966442953 0.12578347174754326
59.083 37.91845637583893 0.10350986731344793
59.083 37.919463087248324 0.10055961947574926
59.083 37.92046979865772 0.09824824852431895
59.083 37.921476510067116 0.09643229438608095
59.083 37.92248322147651 0.09497046135654558
59.083 37.92348993288591 0.09374957671442906
59.083 37.9244966442953 0.09268829631239513
59.083 37.9255033557047 0.0917317206273927
59.083 37.926510067114094 0.09084420419154222
59.083 37.92751677852349 0.09000317872450334
59.083 37.928523489932886 0.08919460994046391
59.083 37.92953020134228 0.08840989878465774
59.083 37.93053691275168 0.08764385320023721
59.083 37.93154362416107 0.08689339181560786
59.083 37.93255033557047 0.08615672713196901
59.083 37.933557046979864 0.09927764499768926
59.083 37.93456375838926 0.0825121181746408
59.083 37.935570469798655 0.08169159546497161
59.083 37.93657718120805 0.08088163626401708
59.083 37.937583892617454 0.08008219011719524
59.083 37.93859060402685 0.07410870204547008
59.083 37.939597315436245 0.07331893737957933
59.083 37.94060402684564 0.07253996282289069
59.083 37.94161073825504 0.0717717301333517
59.083 37.94261744966443 0.07101416329615638
59.083 37.94362416107383 0.06167123676221907
59.083 37.94463087248322 0.06098506582717783
59.083 37.94563758389262 0.0603093812305147
59.083 37.946644295302015 0.059643975524579916
59.083 37.94765100671141 0.05898860871372447
59.083 37.948657718120806 0.058343007994064555
59.083 37.9496644295302 0.05770686713496104
59.083 37.9506711409396 0.05707984534659133
59.083 37.95167785234899 0.05646156548481012
59.083 37.95268456375839 0.05585161144054746
59.083 37.953691275167785 0.0552495245483703
59.083 37.95469798657718 0.054654798829336576
59.083 37.955704697986576 0.05406687485701446
59.083 37.95671140939597 0.03947756020366382
59.083 37.95771812080537 0.03903158573832953
59.083 37.95872483221476 0.03858981446667803
59.083 37.95973154362416 0.03815137031820469
59.083 37.960738255033554 0.03771526233164568
59.083 37.96174496644295 0.0372803635431503
59.083 37.96275167785235 0.032518257311212384
59.083 37.96375838926175 0.03207383001845754
59.083 37.964765100671144 0.0316267250774503
59.083 37.96577181208054 0.03117497497265756
59.083 37.966778523489936 0.03071629432780123
59.083 37.96778523489933 0.03024802284875498
59.083 37.96879194630873 0.029767059364098625
59.083 37.96979865771812 0.02926978619420498
59.083 37.97080536912752 0.02875198328117
59.083 37.971812080536914 0.028208731872968993
59.083 37.97281879194631 0.027634308141132366
59.083 37.973825503355705 0.027022068004506006
59.083 37.9748322147651 0.026364325733374112
59.083 37.9758389261745 0.025652230734422653
59.083 37.97684563758389 0.02487564938938567
59.083 37.97785234899329 0.024023062047629506
59.083 37.978859060402684 0.023081489315804712
59.083 37.97986577181208 0.022036466601240676
59.083 37.980872483221475 0.020872091217891534
59.083 37.98187919463087 0.019571171722982436
59.083 37.982885906040266 0.018115513556559045
59.083 37.98389261744966 0.016486376979722668
59.083 37.98489932885906 0.014665140529971196
59.083 37.98590604026845 0.012634192502508315
59.083 37.98691275167785 0.010378048305275115
59.083 37.98791946308725 0.007884633951626138
59.083 37.98892617449665 0.005146499829637902
59.083 37.98993288590604 0.002161008703536308
59.083 37.99093959731544 -0.001074857815980823
59.083 37.991946308724835 -0.00043236353838547667
59.083 37.99295302013423 -0.004508777671004636
59.083 37.993959731543626 -0.04627297616120334
59.083 37.99496644295302 -0.0795108880987585
59.083 37.99597315436242 -0.103912350977439
59.083 37.99697986577181 -0.1277770883140647
59.083 37.99798657718121 -0.1392933263951633
59.083 37.998993288590604 -0.14502603217430526
59.084 37.85 0.21019191923161354
59.084 37.8510067114094 0.20965144604734204
59.084 37.85201342281879 0.20911417927410014
59.084 37.85302013422819 0.2085795790855961
59.084 37.854026845637584 0.20804712007009996
59.084 37.85503355704698 0.20751628995237423
59.084 37.856040268456375 0.20698658839067102
59.084 37.85704697986577 0.20645752585048696
59.084 37.85805369127517 0.2059286225567506
59.084 37.85906040268456 0.20539940752623095
59.084 37.86006711409396 0.20486941768220535
59.084 37.861073825503354 0.2043381970535215
59.084 37.86208053691275 0.2038052960603989
59.084 37.86308724832215 0.2032702708896291
59.084 37.86409395973155 0.20273268296199734
59.084 37.865100671140944 0.20219209849491246
59.084 37.86610738255034 0.2016480881636527
59.084 37.867114093959735 0.20110022686463122
59.084 37.86812080536913 0.2005480935843128
59.084 37.869127516778526 0.19999127137760825
59.084 37.87013422818792 0.19174767829423864
59.084 37.87114093959732 0.1912165938212609
59.084 37.87214765100671 0.19067969781768496
59.084 37.87315436241611 0.19013658989397741
59.084 37.874161073825505 0.1895868753816623
59.084 37.8751677852349 0.1890301660452966
59.084 37.876174496644296 0.18846608092693928
59.084 37.87718120805369 0.18789424732439544
59.084 37.87818791946309 0.18731430190353499
59.084 37.87919463087248 0.18672589194351172
59.084 37.88020134228188 0.18612867671191358
59.084 37.881208053691275 0.18552232896464466
59.084 37.88221476510067 0.18599102273627943
59.084 37.883221476510066 0.18539511805982642
59.084 37.88422818791946 0.18478928425048108
59.084 37.88523489932886 0.18417326395561734
59.084 37.88624161073825 0.18354682218461368
59.084 37.88724832214765 0.182909748149863
59.084 37.88825503355705 0.1822618569782327
59.084 37.88926174496645 0.18160299115719067
59.084 37.89026845637584 0.18093302150539553
59.084 37.89127516778524 0.18025184733854563
59.084 37.892281879194634 0.17955939530978965
59.084 37.89328859060403 0.1788556160956627
59.084 37.894295302013425 0.17814047759988133
59.084 37.89530201342282 0.17741395254113831
59.084 37.89630872483222 0.17667599698699812
59.084 37.89731543624161 0.17592651428710981
59.084 37.89832214765101 0.17516529545348314
59.084 37.899328859060404 0.1743919215492627
59.084 37.9003355704698 0.17360560484430101
59.084 37.901342281879195 0.17280493146770096
59.084 37.90234899328859 0.17198744618810746
59.084 37.90335570469799 0.17114898581224866
59.084 37.90436241610738 0.1702826167402187
59.084 37.90536912751678 0.16937696094648746
59.084 37.906375838926174 0.1684136078871615
59.084 37.90738255033557 0.15917919219485413
59.084 37.908389261744965 0.15790895981413738
59.084 37.90939597315436 0.1564363283745876
59.084 37.910402684563756 0.15466224610142848
59.084 37.91140939597315 0.15245609945216035
59.084 37.91241610738255 0.14967010505190384
59.084 37.91342281879195 0.14618440829054943
59.084 37.914429530201346 0.14198747405750914
59.084 37.91543624161074 0.13725716963351298
59.084 37.91644295302014 0.1323631386801646
59.084 37.91744966442953 0.1277421162468066
59.084 37.91845637583893 0.12372106916537652
59.084 37.919463087248324 0.12042380914065737
59.084 37.92046979865772 0.11780181061445755
59.084 37.921476510067116 0.11572150280623725
59.084 37.92248322147651 0.09578798100365582
59.084 37.92348993288591 0.09450002197436635
59.084 37.9244966442953 0.09339352406354917
59.084 37.9255033557047 0.0924062388520642
59.084 37.926510067114094 0.09149733914520496
59.084 37.92751677852349 0.09064080002513751
59.084 37.928523489932886 0.10043072530386297
59.084 37.92953020134228 0.09977814520888476
59.084 37.93053691275168 0.0991471280189339
59.084 37.93154362416107 0.09853405904213074
59.084 37.93255033557047 0.08347410413415626
59.084 37.933557046979864 0.08265881530029377
59.084 37.93456375838926 0.0818545127920922
59.084 37.935570469798655 0.08106084473227462
59.084 37.93657718120805 0.0802776393348605
59.084 37.937583892617454 0.07950481865379816
59.084 37.93859060402685 0.07985958885213854
59.084 37.939597315436245 0.07907289569668541
59.084 37.94060402684564 0.07829663704500311
59.084 37.94161073825504 0.07753075816829269
59.084 37.94261744966443 0.07677517602801888
59.084 37.94362416107383 0.07602977551144298
59.084 37.94463087248322 0.06997603539298462
59.084 37.94563758389262 0.06924095818218062
59.084 37.946644295302015 0.059967039463428295
59.084 37.94765100671141 0.05930360278599091
59.084 37.948657718120806 0.058649857273813555
59.084 37.9496644295302 0.058005465124422534
59.084 37.9506711409396 0.057370045835148695
59.084 37.95167785234899 0.05674317290311126
59.084 37.95268456375839 0.056124369424627715
59.084 37.953691275167785 0.05551310237243261
59.084 37.95469798657718 0.04961629068217124
59.084 37.955704697986576 0.049001900426345106
59.084 37.95671140939597 0.048393679556406374
59.084 37.95771812080537 0.0347324830069812
59.084 37.95872483221476 0.03427021160468945
59.084 37.95973154362416 0.03381055381760225
59.084 37.960738255033554 0.0333522533386313
59.084 37.96174496644295 0.032893864947580814
59.084 37.96275167785235 0.032433720001157185
59.084 37.96375838926175 0.0319698857511329
59.084 37.964765100671144 0.03150011766474736
59.084 37.96577181208054 0.031021803897598867
59.084 37.966778523489936 0.030531901090666672
59.084 37.96778523489933 0.030026860756530944
59.084 37.96879194630873 0.029502545720417522
59.084 37.96979865771812 0.02895413643779793
59.084 37.97080536912752 0.02837602758410708
59.084 37.971812080536914 0.027761716183072726
59.084 37.97281879194631 0.02710368380402975
59.084 37.973825503355705 0.02639327712450902
59.084 37.9748322147651 0.02562059353643425
59.084 37.9758389261745 0.024774381571834286
59.084 37.97684563758389 0.02384196978807055
59.084 37.97785234899329 0.02280924233307246
59.084 37.978859060402684 0.02166068447765029
59.084 37.97986577181208 0.02037952644027891
59.084 37.980872483221475 0.0189480179224649
59.084 37.98187919463087 0.01734786747923714
59.084 37.982885906040266 0.015560878091890128
59.084 37.98389261744966 0.013569800104036075
59.084 37.98489932885906 0.01135939954400221
59.084 37.98590604026845 0.00891768664219995
59.084 37.98691275167785 0.006237088757049786
59.084 37.98791946308725 0.0033146995802389063
59.084 37.98892617449665 0.00014766283203913183
59.084 37.98993288590604 -0.0032953541954969037
59.084 37.99093959731544 -0.007219102588845866
59.084 37.991946308724835 -0.012676768286926914
59.084 37.99295302013423 -0.023865754338308655
59.084 37.993959731543626 -0.047418132889236825
59.084 37.99496644295302 -0.08094383554673944
59.084 37.99597315436242 -0.0928199912031977
59.084 37.99697986577181 -0.09848883989778365
59.084 37.99798657718121 -0.1424824868674895
59.084 37.998993288590604 -0.14607422262630038
59.085 37.85 0.21261129640429632
59.085 37.8510067114094 0.21202053667760537
59.085 37.85201342281879 0.2114355364019706
59.085 37.85302013422819 0.21085562817732506
59.085 37.854026845637584 0.21028016531717922
59.085 37.85503355704698 0.2097085203456297
59.085 37.856040268456375 0.20914008356381183
59.085 37.85704697986577 0.20857426168968804
59.085 37.85805369127517 0.20801047657470667
59.085 37.85906040268456 0.20744816400068108
59.085 37.86006711409396 0.20688677256022914
59.085 37.861073825503354 0.20632576262397118
59.085 37.86208053691275 0.2057646053976921
59.085 37.86308724832215 0.20520278207281195
59.085 37.86409395973155 0.20463978307352382
59.085 37.865100671140944 0.20407510740397553
59.085 37.86610738255034 0.19538844199965502
59.085 37.867114093959735 0.19485523530258708
59.085 37.86812080536913 0.19431901433663576
59.085 37.869127516778526 0.1937793062800495
59.085 37.87013422818792 0.1932356452407473
59.085 37.87114093959732 0.19268757228223907
59.085 37.87214765100671 0.19213463557591964
59.085 37.87315436241611 0.19157639068322405
59.085 37.874161073825505 0.19101240097082015
59.085 37.8751677852349 0.19044223816167927
59.085 37.876174496644296 0.18986548302417477
59.085 37.87718120805369 0.1901450569460038
59.085 37.87818791946309 0.18958348976846479
59.085 37.87919463087248 0.18901423521570038
59.085 37.88020134228188 0.1884369188184874
59.085 37.881208053691275 0.1878511806621046
59.085 37.88221476510067 0.18725667688572345
59.085 37.883221476510066 0.18665308129453798
59.085 37.88422818791946 0.18604008706799532
59.085 37.88523489932886 0.18541740853976846
59.085 37.88624161073825 0.18478478301388954
59.085 37.88724832214765 0.18414197256464793
59.085 37.88825503355705 0.18348876574168585
59.085 37.88926174496645 0.18282497906098122
59.085 37.89026845637584 0.18215045809788086
59.085 37.89127516778524 0.18146507789536856
59.085 37.892281879194634 0.18076874223522468
59.085 37.89328859060403 0.18006138105350245
59.085 37.894295302013425 0.17934294485134702
59.085 37.89530201342282 0.17861339425650705
59.085 37.89630872483222 0.17787268176564544
59.085 37.89731543624161 0.1771207208774093
59.085 37.89832214765101 0.17635733488563687
59.085 37.899328859060404 0.17558217286064898
59.085 37.9003355704698 0.1747945727274173
59.085 37.901342281879195 0.173993339178029
59.085 37.90234899328859 0.17317638490165402
59.085 37.90335570469799 0.172340153656695
59.085 37.90436241610738 0.16338980354830118
59.085 37.90536912751678 0.16240609190786753
59.085 37.906375838926174 0.1613707447127709
59.085 37.90738255033557 0.160258285920328
59.085 37.908389261744965 0.159028715628173
59.085 37.90939597315436 0.15762095512910623
59.085 37.910402684563756 0.15594572065999376
59.085 37.91140939597315 0.15388156433462624
59.085 37.91241610738255 0.1512828840161962
59.085 37.91342281879195 0.1480141599328594
59.085 37.914429530201346 0.14402077710200586
59.085 37.91543624161074 0.13941686821419677
59.085 37.91644295302014 0.14511166407841497
59.085 37.91744966442953 0.1404151943467722
59.085 37.91845637583893 0.13623836483514556
59.085 37.919463087248324 0.13276904527064665
59.085 37.92046979865772 0.13000313879783817
59.085 37.921476510067116 0.12782390411825967
59.085 37.92248322147651 0.12608357684326266
59.085 37.92348993288591 0.12465022275252022
59.085 37.9244966442953 0.1302279573317166
59.085 37.9255033557047 0.12924133286752545
59.085 37.926510067114094 0.11432101469936622
59.085 37.92751677852349 0.11360749517778629
59.085 37.928523489932886 0.11293544979595198
59.085 37.92953020134228 0.1122932457383859
59.085 37.93053691275168 0.09763656391850714
59.085 37.93154362416107 0.08487217264593827
59.085 37.93255033557047 0.08403718298915945
59.085 37.933557046979864 0.08321417891224701
59.085 37.93456375838926 0.0824023443796228
59.085 37.935570469798655 0.08160124168434965
59.085 37.93657718120805 0.08081064853587744
59.085 37.937583892617454 0.08003045769282498
59.085 37.93859060402685 0.07926061534844181
59.085 37.939597315436245 0.07850108347431216
59.085 37.94060402684564 0.07775181693496891
59.085 37.94161073825504 0.07701274966456173
59.085 37.94261744966443 0.07731179329791882
59.085 37.94362416107383 0.07655784251364993
59.085 37.94463087248322 0.07581387853979364
59.085 37.94563758389262 0.07507969988102342
59.085 37.946644295302015 0.07435506338576307
59.085 37.94765100671141 0.07363968221171607
59.085 37.948657718120806 0.06024201969939765
59.085 37.9496644295302 0.05297623260057313
59.085 37.9506711409396 0.052312587929480646
59.085 37.95167785234899 0.05165780458404776
59.085 37.95268456375839 0.051011331409457246
59.085 37.953691275167785 0.050372542392576276
59.085 37.95469798657718 0.04974072587653325
59.085 37.955704697986576 0.04911507128577555
59.085 37.95671140939597 0.04849465293306874
59.085 37.95771812080537 0.04787841042031542
59.085 37.95872483221476 0.047265125079881294
59.085 37.95973154362416 0.03375749142789388
59.085 37.960738255033554 0.03328306609304664
59.085 37.96174496644295 0.03280596445594597
59.085 37.96275167785235 0.03232399149771065
59.085 37.96375838926175 0.031834592733215004
59.085 37.964765100671144 0.031334791905696074
59.085 37.96577181208054 0.03082111955429358
59.085 37.966778523489936 0.030289531957953184
59.085 37.96778523489933 0.0297353203109337
59.085 37.96879194630873 0.029153010547606942
59.085 37.96979865771812 0.02853625508302536
59.085 37.97080536912752 0.027877718960835046
59.085 37.971812080536914 0.02716896460406869
59.085 37.97281879194631 0.02640034165213209
59.085 37.973825503355705 0.025560891327697562
59.085 37.9748322147651 0.02463827845163216
59.085 37.9758389261745 0.02361876855489349
59.085 37.97684563758389 0.022487272293385777
59.085 37.97785234899329 0.021227484058492677
59.085 37.978859060402684 0.01982214541950216
59.085 37.97986577181208 0.01825346548023748
59.085 37.980872483221475 0.016503727453078453
59.085 37.98187919463087 0.014556101013669678
59.085 37.982885906040266 0.012395658334677099
59.085 37.98389261744966 0.010010542649688547
59.085 37.98489932885906 0.007393091982464028
59.085 37.98590604026845 0.004540130004275406
59.085 37.98691275167785 0.0014488568365479994
59.085 37.98791946308725 -0.0019088849441067299
59.085 37.98892617449665 -0.005717783272005235
59.085 37.98993288590604 -0.010934515156652024
59.085 37.99093959731544 -0.021463478888715932
59.085 37.991946308724835 -0.04420611793019072
59.085 37.99295302013423 -0.06913581969048131
59.085 37.993959731543626 -0.0817505855495842
59.085 37.99496644295302 -0.08770149641855189
59.085 37.99597315436242 -0.10058629046910265
59.085 37.99697986577181 -0.10411782287380139
59.085 37.99798657718121 -0.10741444867197744
59.085 37.998993288590604 -0.11051266050292195
59.086 37.85 0.21542169086920698
59.086 37.8510067114094 0.2147633736602966
59.086 37.85201342281879 0.21411413117339534
59.086 37.85302013422819 0.21347313974742424
59.086 37.854026845637584 0.21283960297419874
59.086 37.85503355704698 0.21221275010028212
59.086 37.856040268456375 0.21159183447131305
59.086 37.85704697986577 0.21097613202729879
59.086 37.85805369127517 0.21036493985645727
59.086 37.85906040268456 0.2097575748145194
59.086 37.86006711409396 0.20915337221595298
59.086 37.861073825503354 0.20855168460301218
59.086 37.86208053691275 0.19938569949737242
59.086 37.86308724832215 0.1988232872255523
59.086 37.86409395973155 0.19826167460791547
59.086 37.865100671140944 0.19770027092514234
59.086 37.86610738255034 0.19713849786347412
59.086 37.867114093959735 0.19657578886038146
59.086 37.86812080536913 0.19601158856309261
59.086 37.869127516778526 0.19544535240459457
59.086 37.87013422818792 0.19487654630153056
59.086 37.87114093959732 0.1943046464782767
59.086 37.87214765100671 0.19372913942138809
59.086 37.87315436241611 0.1938170463151335
59.086 37.874161073825505 0.19326238902609397
59.086 37.8751677852349 0.19270275706391116
59.086 37.876174496644296 0.1921376792091734
59.086 37.87718120805369 0.19156669633693352
59.086 37.87818791946309 0.19098936221737273
59.086 37.87919463087248 0.1904052444615584
59.086 37.88020134228188 0.1898139256101726
59.086 37.881208053691275 0.1892150043611752
59.086 37.88221476510067 0.18860809692964176
59.086 37.883221476510066 0.18799283852950777
59.086 37.88422818791946 0.1873688849622285
59.086 37.88523489932886 0.18673591429044478
59.086 37.88624161073825 0.18609362856478584
59.086 37.88724832214765 0.1854417555571578
59.086 37.88825503355705 0.18478005043095658
59.086 37.88926174496645 0.1841082972431695
59.086 37.89026845637584 0.18342631011733018
59.086 37.89127516778524 0.18273393383712458
59.086 37.892281879194634 0.1820310434673097
59.086 37.89328859060403 0.18131754237867959
59.086 37.894295302013425 0.1805933576822624
59.086 37.89530201342282 0.17985843147759023
59.086 37.89630872483222 0.17911270534895113
59.086 37.89731543624161 0.17835609397272825
59.086 37.89832214765101 0.17758844116024125
59.086 37.899328859060404 0.17680944756414424
59.086 37.9003355704698 0.17601855268555916
59.086 37.901342281879195 0.17521474326824424
59.086 37.90234899328859 0.16632158929922794
59.086 37.90335570469799 0.16539741967258323
59.086 37.90436241610738 0.17451119981130864
59.086 37.90536912751678 0.17358916855983814
59.086 37.906375838926174 0.17262145812912555
59.086 37.90738255033557 0.1715859622605352
59.086 37.908389261744965 0.1704477519619041
59.086 37.90939597315436 0.1691529735334782
59.086 37.910402684563756 0.16762166988989857
59.086 37.91140939597315 0.16574219555738265
59.086 37.91241610738255 0.1633742418458491
59.086 37.91342281879195 0.16037338798402948
59.086 37.914429530201346 0.15665073581429081
59.086 37.91543624161074 0.15226065764479915
59.086 37.91644295302014 0.14746177341438022
59.086 37.91744966442953 0.14267025577967202
59.086 37.91845637583893 0.13829930524253017
59.086 37.919463087248324 0.1346009646475117
59.086 37.92046979865772 0.13806171969552336
59.086 37.921476510067116 0.13581350365033892
59.086 37.92248322147651 0.1340506728711993
59.086 37.92348993288591 0.13263363775504783
59.086 37.9244966442953 0.1314518217241708
59.086 37.9255033557047 0.13042623894515065
59.086 37.926510067114094 0.1295037792836315
59.086 37.92751677852349 0.12865003953815013
59.086 37.928523489932886 0.11406843635558447
59.086 37.92953020134228 0.09928800317841
59.086 37.93053691275168 0.09843245200303578
59.086 37.93154362416107 0.09759351038614092
59.086 37.93255033557047 0.09676819216986832
59.086 37.933557046979864 0.09595475387571399
59.086 37.93456375838926 0.09515220295818683
59.086 37.935570469798655 0.09435999231287659
59.086 37.93657718120805 0.09357783116991028
59.086 37.937583892617454 0.09280556835187902
59.086 37.93859060402685 0.09204312037617823
59.086 37.939597315436245 0.0912904272698361
59.086 37.94060402684564 0.09054742544949174
59.086 37.94161073825504 0.08981403104575073
59.086 37.94261744966443 0.08909012954184439
59.086 37.94362416107383 0.07097801012428934
59.086 37.94463087248322 0.0702238148437175
59.086 37.94563758389262 0.06947947256808512
59.086 37.946644295302015 0.06921629928899824
59.086 37.94765100671141 0.06845786874802129
59.086 37.948657718120806 0.06770865946414927
59.086 37.9496644295302 0.06696824033563448
59.086 37.9506711409396 0.06623611170628041
59.086 37.95167785234899 0.05847348737417205
59.086 37.95268456375839 0.05780254303395814
59.086 37.953691275167785 0.050503308137058486
59.086 37.95469798657718 0.04985942681306639
59.086 37.955704697986576 0.04922063743128039
59.086 37.95671140939597 0.04858576315317427
59.086 37.95771812080537 0.04795344308248223
59.086 37.95872483221476 0.04732210004177218
59.086 37.95973154362416 0.04668990249347818
59.086 37.960738255033554 0.046054719836225916
59.086 37.96174496644295 0.032690109814366625
59.086 37.96275167785235 0.032179474690275156
59.086 37.96375838926175 0.031656334341413156
59.086 37.964765100671144 0.031116751411361217
59.086 37.96577181208054 0.030556141773926588
59.086 37.966778523489936 0.02996917786428089
59.086 37.96778523489933 0.02934968434617202
59.086 37.96879194630873 0.0286905285720057
59.086 37.96979865771812 0.02798350993224133
59.086 37.97080536912752 0.02721925437918333
59.086 37.971812080536914 0.02638712322615742
59.086 37.97281879194631 0.02547514879612184
59.086 37.973825503355705 0.02447001355746385
59.086 37.9748322147651 0.023357093809471378
59.086 37.9758389261745 0.02212059328561863
59.086 37.97684563758389 0.020743795401541323
59.086 37.97785234899329 0.01920946402336734
59.086 37.978859060402684 0.01750041979655269
59.086 37.97986577181208 0.015600309772863565
59.086 37.980872483221475 0.013494567845278551
59.086 37.98187919463087 0.011171518445536148
59.086 37.982885906040266 0.008623443090063295
59.086 37.98389261744966 0.005846894808765751
59.086 37.98489932885906 0.002839032476636763
59.086 37.98590604026845 -0.0004256057824908663
59.086 37.98691275167785 -0.0041138298506450295
59.086 37.98791946308725 -0.009094606398018866
59.086 37.98892617449665 -0.01899110875032872
59.086 37.98993288590604 -0.04087416593869455
59.086 37.99093959731544 -0.06626168750207785
59.086 37.991946308724835 -0.07950215100491467
59.086 37.99295302013423 -0.08561061470290152
59.086 37.993959731543626 -0.08977086963521898
59.086 37.99496644295302 -0.09341217492701918
59.086 37.99597315436242 -0.10527406205380559
59.086 37.99697986577181 -0.10836550072653228
59.086 37.99798657718121 -0.11126900271446209
59.086 37.998993288590604 -0.1139791642361525
59.087 37.85 0.21874275779404223
59.087 37.8510067114094 0.21799536873113495
59.087 37.85201342281879 0.21726121300093856
59.087 37.85302013422819 0.21653928974690023
59.087 37.854026845637584 0.21582863065024732
59.087 37.85503355704698 0.21512829853341642
59.087 37.856040268456375 0.21443738594380787
59.087 37.85704697986577 0.21375501373396194
59.087 37.85805369127517 0.2130803296526647
59.087 37.85906040268456 0.20342766899879533
59.087 37.86006711409396 0.20280191870058337
59.087 37.861073825503354 0.20218159495038446
59.087 37.86208053691275 0.20156593824561234
59.087 37.86308724832215 0.20095420949664372
59.087 37.86409395973155 0.20034568892552748
59.087 37.865100671140944 0.19973967504833479
59.087 37.86610738255034 0.19913548374894066
59.087 37.867114093959735 0.19853244745134963
59.087 37.86812080536913 0.19792991439737756
59.087 37.869127516778526 0.1973272480362146
59.087 37.87013422818792 0.1972214910171239
59.087 37.87114093959732 0.1966462908993355
59.087 37.87214765100671 0.19606925404758146
59.087 37.87315436241611 0.19548980010749892
59.087 37.874161073825505 0.19490736274195408
59.087 37.8751677852349 0.19432138980631228
59.087 37.876174496644296 0.1937313436694741
59.087 37.87718120805369 0.19313670168306732
59.087 37.87818791946309 0.1925369568002755
59.087 37.87919463087248 0.19193161834445074
59.087 37.88020134228188 0.191320212926121
59.087 37.881208053691275 0.19070228550517587
59.087 37.88221476510067 0.19007740059243944
59.087 37.883221476510066 0.18944514358157952
59.087 37.88422818791946 0.18880512219800327
59.087 37.88523489932886 0.1881569680451267
59.087 37.88624161073825 0.18750033821952902
59.087 37.88724832214765 0.1868349169534488
59.087 37.88825503355705 0.18616041722297336
59.087 37.88926174496645 0.18547658222932195
59.087 37.89026845637584 0.18478318661197243
59.087 37.89127516778524 0.18408003717510615
59.087 37.892281879194634 0.18336697278501013
59.087 37.89328859060403 0.18264386289744597
59.087 37.894295302013425 0.1819106038531857
59.087 37.89530201342282 0.18116711156180346
59.087 37.89630872483222 0.18041330835602476
59.087 37.89731543624161 0.18956563319346517
59.087 37.89832214765101 0.18885462329521577
59.087 37.899328859060404 0.1881330186898673
59.087 37.9003355704698 0.1792109620674302
59.087 37.901342281879195 0.17837211186356816
59.087 37.90234899328859 0.177518912536605
59.087 37.90335570469799 0.1766487606462282
59.087 37.90436241610738 0.17575727844342423
59.087 37.90536912751678 0.17483721147482947
59.087 37.906375838926174 0.17387669383523985
59.087 37.90738255033557 0.1728565717288425
59.087 37.908389261744965 0.171746411262019
59.087 37.90939597315436 0.17049889250648934
59.087 37.910402684563756 0.16904281254175213
59.087 37.91140939597315 0.167276518044388
59.087 37.91241610738255 0.1650671873227491
59.087 37.91342281879195 0.1622671251977858
59.087 37.914429530201346 0.15876181103525788
59.087 37.91543624161074 0.15455229761723915
59.087 37.91644295302014 0.14983321144711412
59.087 37.91744966442953 0.15116442659329282
59.087 37.91845637583893 0.1467191471690194
59.087 37.919463087248324 0.14289292806030424
59.087 37.92046979865772 0.13979341081578175
59.087 37.921476510067116 0.13736200771347987
59.087 37.92248322147651 0.1354620048960954
59.087 37.92348993288591 0.13394825267477511
59.087 37.9244966442953 0.1327003256616988
59.087 37.9255033557047 0.1316299512065375
59.087 37.926510067114094 0.1171908501605802
59.087 37.92751677852349 0.11609106616818558
59.087 37.928523489932886 0.11504098003677272
59.087 37.92953020134228 0.11402489718535168
59.087 37.93053691275168 0.11303315471869754
59.087 37.93154362416107 0.1120598821476223
59.087 37.93255033557047 0.09755665317324436
59.087 37.933557046979864 0.09673463118056821
59.087 37.93456375838926 0.09592378851323259
59.087 37.935570469798655 0.0951234555906071
59.087 37.93657718120805 0.09433326645056489
59.087 37.937583892617454 0.0935530230015548
59.087 37.93859060402685 0.09278261115699529
59.087 37.939597315436245 0.08653990687606328
59.087 37.94060402684564 0.08574118755873356
59.087 37.94161073825504 0.08495222823720357
59.087 37.94261744966443 0.08417291091282572
59.087 37.94362416107383 0.08340307621780037
59.087 37.94463087248322 0.08264251704169366
59.087 37.94563758389262 0.08189097317783071
59.087 37.946644295302015 0.08114812612606641
59.087 37.94765100671141 0.08041359342305374
59.087 37.948657718120806 0.07968692200152748
59.087 37.9496644295302 0.0789675801443837
59.087 37.9506711409396 0.06656695090860137
59.087 37.95167785234899 0.06583033581085398
59.087 37.95268456375839 0.06509997852055237
59.087 37.953691275167785 0.05737056999833925
59.087 37.95469798657718 0.05669710750981988
59.087 37.955704697986576 0.05602672917025421
59.087 37.95671140939597 0.0486638048420508
59.087 37.95771812080537 0.0480118072832649
59.087 37.95872483221476 0.04735806272651141
59.087 37.95973154362416 0.04670016942645863
59.087 37.960738255033554 0.04603532495861752
59.087 37.96174496644295 0.04536025964541278
59.087 37.96275167785235 0.04467116108657647
59.087 37.96375838926175 0.03142072048390418
59.087 37.964765100671144 0.030828740252652777
59.087 37.96577181208054 0.03020632266575615
59.087 37.966778523489936 0.02954655907311892
59.087 37.96778523489933 0.028841509931380953
59.087 37.96879194630873 0.028082104442483914
59.087 37.96979865771812 0.02725805423482408
59.087 37.97080536912752 0.026357793090889654
59.087 37.971812080536914 0.025368458505518214
59.087 37.97281879194631 0.024275934929560254
59.087 37.973825503355705 0.02306498245107765
59.087 37.9748322147651 0.021719477610585774
59.087 37.9758389261745 0.02022279386854919
59.087 37.97684563758389 0.018558346325844313
59.087 37.97785234899329 0.016710316416650828
59.087 37.978859060402684 0.014664553478201267
59.087 37.97986577181208 0.01240960888474587
59.087 37.980872483221475 0.00993773797775228
59.087 37.98187919463087 0.007245221577452384
59.087 37.982885906040266 0.004329086389023521
59.087 37.98389261744966 0.0011661287107446981
59.087 37.98489932885906 -0.0023945655321739694
59.087 37.98590604026845 -0.0071423647081433825
59.087 37.98691275167785 -0.01643236319034748
59.087 37.98791946308725 -0.03741498603995272
59.087 37.98892617449665 -0.06316930143910192
59.087 37.98993288590604 -0.07705597480561824
59.087 37.99093959731544 -0.08333147552322896
59.087 37.991946308724835 -0.08749057279052902
59.087 37.99295302013423 -0.09110418926442687
59.087 37.993959731543626 -0.09449081775389741
59.087 37.99496644295302 -0.09769888704878682
59.087 37.99597315436242 -0.10891400078672571
59.087 37.99697986577181 -0.11163955781776297
59.087 37.99798657718121 -0.11418173364686822
59.087 37.998993288590604 -0.11654157790800239
59.088 37.85 0.22271499763384792
59.088 37.8510067114094 0.22185312529934234
59.088 37.85201342281879 0.22100944994477045
59.088 37.85302013422819 0.22018279362551255
59.088 37.854026845637584 0.21937201240753013
59.088 37.85503355704698 0.21857599568424035
59.088 37.856040268456375 0.20838082986571926
59.088 37.85704697986577 0.20764701731969867
59.088 37.85805369127517 0.2069249865168324
59.088 37.85906040268456 0.20621375004918135
59.088 37.86006711409396 0.20551234897320006
59.088 37.861073825503354 0.2048198517130662
59.088 37.86208053691275 0.20413535296498853
59.088 37.86308724832215 0.2034579726184648
59.088 37.86409395973155 0.2027868547091645
59.088 37.865100671140944 0.20212116641693373
59.088 37.86610738255034 0.20146009712169313
59.088 37.867114093959735 0.20112756957994118
59.088 37.86812080536913 0.20050295121450093
59.088 37.869127516778526 0.19988077324723563
59.088 37.87013422818792 0.19926030506567663
59.088 37.87114093959732 0.19864083476846678
59.088 37.87214765100671 0.19802166878651023
59.088 37.87315436241611 0.19740213162817782
59.088 37.874161073825505 0.1967815657551623
59.088 37.8751677852349 0.19615933159490445
59.088 37.876174496644296 0.1955348076945366
59.088 37.87718120805369 0.19490739102031696
59.088 37.87818791946309 0.1942764974054992
59.088 37.87919463087248 0.1936415621481595
59.088 37.88020134228188 0.19300204075891264
59.088 37.881208053691275 0.1923574098566336
59.088 37.88221476510067 0.19170716820778066
59.088 37.883221476510066 0.1910508379018255
59.088 37.88422818791946 0.19038796565129976
59.088 37.88523489932886 0.1897181241992381
59.088 37.88624161073825 0.1890409138087985
59.088 37.88724832214765 0.18835596379825956
59.088 37.88825503355705 0.18766293406685378
59.088 37.88926174496645 0.18696151652983437
59.088 37.89026845637584 0.18625143633881508
59.088 37.89127516778524 0.1951955773458464
59.088 37.892281879194634 0.19453114916248904
59.088 37.89328859060403 0.1938576730061684
59.088 37.894295302013425 0.1931750046623014
59.088 37.89530201342282 0.19248302390565844
59.088 37.89630872483222 0.19178162336895443
59.088 37.89731543624161 0.19107068929268728
59.088 37.89832214765101 0.1822032019458615
59.088 37.899328859060404 0.1813781515194419
59.088 37.9003355704698 0.18054183985326297
59.088 37.901342281879195 0.179693514095567
59.088 37.90234899328859 0.1788318680395364
59.088 37.90335570469799 0.1779546692178608
59.088 37.90436241610738 0.17705815962826912
59.088 37.90536912751678 0.17613609893029356
59.088 37.906375838926174 0.17517825239135085
59.088 37.90738255033557 0.17416804128989682
59.088 37.908389261744965 0.1730789945658089
59.088 37.90939597315436 0.1718696524358375
59.088 37.910402684563756 0.17047690766006068
59.088 37.91140939597315 0.16880894662568977
59.088 37.91241610738255 0.16674184416788393
59.088 37.91342281879195 0.16412909543467008
59.088 37.914429530201346 0.16676327043310019
59.088 37.91543624161074 0.16284472772549044
59.088 37.91644295302014 0.15833578382064126
59.088 37.91744966442953 0.15356897709500095
59.088 37.91845637583893 0.14898157318680888
59.088 37.919463087248324 0.14493824161424554
59.088 37.92046979865772 0.14161006025062264
59.088 37.921476510067116 0.13898062902771324
59.088 37.92248322147651 0.13692816623430926
59.088 37.92348993288591 0.1353049835376246
59.088 37.9244966442953 0.12078461166666815
59.088 37.9255033557047 0.11944162104184361
59.088 37.926510067114094 0.11822904029539989
59.088 37.92751677852349 0.11710175856267291
59.088 37.928523489932886 0.11603084463623861
59.088 37.92953020134228 0.11499812648534824
59.088 37.93053691275168 0.11399236449518883
59.088 37.93154362416107 0.11300669794907825
59.088 37.93255033557047 0.11203699427701942
59.088 37.933557046979864 0.11108080149260849
59.088 37.93456375838926 0.11013669070878909
59.088 37.935570469798655 0.09049027629508147
59.088 37.93657718120805 0.08964215084817517
59.088 37.937583892617454 0.08880419110776093
59.088 37.93859060402685 0.08797625957924313
59.088 37.939597315436245 0.08715826082079962
59.088 37.94060402684564 0.08635010342362383
59.088 37.94161073825504 0.08555167582849489
59.088 37.94261744966443 0.08476283040579495
59.088 37.94362416107383 0.08398337228335788
59.088 37.94463087248322 0.0832130506680838
59.088 37.94563758389262 0.08245155117421477
59.088 37.946644295302015 0.08169848813093022
59.088 37.94765100671141 0.0809533961100417
59.088 37.948657718120806 0.08021572006199562
59.088 37.9496644295302 0.07948480351960295
59.088 37.9506711409396 0.07875987435127613
59.088 37.95167785234899 0.0780400275359891
59.088 37.95268456375839 0.07732420440282385
59.088 37.953691275167785 0.07771215246661992
59.088 37.95469798657718 0.07696337005592062
59.088 37.955704697986576 0.0707379249060876
59.088 37.95671140939597 0.05552537187137045
59.088 37.95771812080537 0.05483142443592859
59.088 37.95872483221476 0.05413152899355868
59.088 37.95973154362416 0.046676644007656524
59.088 37.960738255033554 0.04597403298354232
59.088 37.96174496644295 0.045254886262415216
59.088 37.96275167785235 0.044514227402129486
59.088 37.96375838926175 0.043746289010917046
59.088 37.964765100671144 0.030450589865168354
59.088 37.96577181208054 0.02974792915095684
59.088 37.966778523489936 0.028994067382835165
59.088 37.96778523489933 0.028179099300725666
59.088 37.96879194630873 0.02729189638194335
59.088 37.96979865771812 0.026320091104716118
59.088 37.97080536912752 0.0252501198999777
59.088 37.971812080536914 0.024067346841837096
59.088 37.97281879194631 0.02275629263449446
59.088 37.973825503355705 0.021300993927520703
59.088 37.9748322147651 0.019685514979738816
59.088 37.9758389261745 0.01789462522768775
59.088 37.97684563758389 0.015914638896793083
59.088 37.97785234899329 0.013734375272611885
59.088 37.978859060402684 0.01134608933871473
59.088 37.97986577181208 0.008745785556428812
59.088 37.980872483221475 0.005930272456227119
59.088 37.98187919463087 0.0028782042197179405
59.088 37.982885906040266 -0.0005472387605548468
59.088 37.98389261744966 -0.005063297325018874
59.088 37.98489932885906 -0.01377104046024685
59.088 37.98590604026845 -0.03382114978592643
59.088 37.98691275167785 -0.05984414134024697
59.088 37.98791946308725 -0.07439409638871815
59.088 37.98892617449665 -0.08084659634310552
59.088 37.98993288590604 -0.08499988785234136
59.088 37.99093959731544 -0.08857815024995075
59.088 37.991946308724835 -0.09193646910267413
59.088 37.99295302013423 -0.0951306232260589
59.088 37.993959731543626 -0.09816012177376197
59.088 37.99496644295302 -0.10101778841986578
59.088 37.99597315436242 -0.11162928385735316
59.088 37.99697986577181 -0.11402641676853781
59.088 37.99798657718121 -0.11625554070794417
59.088 37.998993288590604 -0.11832230592373734
59.089 37.85 0.22749051923299912
59.089 37.8510067114094 0.22648643696757367
59.089 37.85201342281879 0.22550606486615585
59.089 37.85302013422819 0.21469594112279308
59.089 37.854026845637584 0.21379484564389903
59.089 37.85503355704698 0.21291376263926506
59.089 37.856040268456375 0.21205146182683454
59.089 37.85704697986577 0.2112067425410919
59.089 37.85805369127517 0.2103784337293305
59.089 37.85906040268456 0.20956539377416164
59.089 37.86006711409396 0.20876651017115178
59.089 37.861073825503354 0.20798069908864597
59.089 37.86208053691275 0.20720690483519807
59.089 37.86308724832215 0.20644409925855206
59.089 37.86409395973155 0.2058402931501296
59.089 37.865100671140944 0.20512599324803837
59.089 37.86610738255034 0.20441989350941298
59.089 37.867114093959735 0.20372106847999175
59.089 37.86812080536913 0.20302861692698798
59.089 37.869127516778526 0.2023416612462299
59.089 37.87013422818792 0.20165934692875648
59.089 37.87114093959732 0.20098084210032263
59.089 37.87214765100671 0.20030533714632592
59.089 37.87315436241611 0.1996320444334978
59.089 37.874161073825505 0.19896019813864652
59.089 37.8751677852349 0.1982890541937605
59.089 37.876174496644296 0.197617890355525
59.089 37.87718120805369 0.19694600640606938
59.089 37.87818791946309 0.19627272449053434
59.089 37.87919463087248 0.19559738959541884
59.089 37.88020134228188 0.19491937016994293
59.089 37.881208053691275 0.19423805889074777
59.089 37.88221476510067 0.19355287356769899
59.089 37.883221476510066 0.1928632581855184
59.089 37.88422818791946 0.19216868407217141
59.089 37.88523489932886 0.19146865117957623
59.089 37.88624161073825 0.1907626894549094
59.089 37.88724832214765 0.19958001375486048
59.089 37.88825503355705 0.1989248678218309
59.089 37.88926174496645 0.19826282571311896
59.089 37.89026845637584 0.19759355069419773
59.089 37.89127516778524 0.1969167423288582
59.089 37.892281879194634 0.19623213675510814
59.089 37.89328859060403 0.1955395059565551
59.089 37.894295302013425 0.1948386553816491
59.089 37.89530201342282 0.19412941887708526
59.089 37.89630872483222 0.18531261058812942
59.089 37.89731543624161 0.1844914629492891
59.089 37.89832214765101 0.18366054751136462
59.089 37.899328859060404 0.1828196168910072
59.089 37.9003355704698 0.18196827706089821
59.089 37.901342281879195 0.18110586444910057
59.089 37.90234899328859 0.18023124657463102
59.089 37.90335570469799 0.17934249986389528
59.089 37.90436241610738 0.1784363911472461
59.089 37.90536912751678 0.17750754797154902
59.089 37.906375838926174 0.1765471426603898
59.089 37.90738255033557 0.1755408350027869
59.089 37.908389261744965 0.18865544841775334
59.089 37.90939597315436 0.1875461828998082
59.089 37.910402684563756 0.18627607225968537
59.089 37.91140939597315 0.18927343331660196
59.089 37.91241610738255 0.1874727922608994
59.089 37.91342281879195 0.18517369106310233
59.089 37.914429530201346 0.18223344772574038
59.089 37.91543624161074 0.1785712275546642
59.089 37.91644295302014 0.1742563493909087
59.089 37.91744966442953 0.1695634121151085
59.089 37.91845637583893 0.16491503742177543
59.089 37.919463087248324 0.1607162132244184
59.089 37.92046979865772 0.15720249983287268
59.089 37.921476510067116 0.15440803287099336
59.089 37.92248322147651 0.13930197371294661
59.089 37.92348993288591 0.1373815440054882
59.089 37.9244966442953 0.1357941538590336
59.089 37.9255033557047 0.13443176717851493
59.089 37.926510067114094 0.13321765220253826
59.089 37.92751677852349 0.1321003774037601
59.089 37.928523489932886 0.13104667883244195
59.089 37.92953020134228 0.13003553650242644
59.089 37.93053691275168 0.1290538835336465
59.089 37.93154362416107 0.12809370278543877
59.089 37.93255033557047 0.1208850602315303
59.089 37.933557046979864 0.11989137200868381
59.089 37.93456375838926 0.1189096488490839
59.089 37.935570469798655 0.11793890843535533
59.089 37.93657718120805 0.11697859047518717
59.089 37.937583892617454 0.10482468805378876
59.089 37.93859060402685 0.10399568419079498
59.089 37.939597315436245 0.10317586864543057
59.089 37.94060402684564 0.10236510711647472
59.089 37.94161073825504 0.10156324410524385
59.089 37.94261744966443 0.10077008325014793
59.089 37.94362416107383 0.0999853729893885
59.089 37.94463087248322 0.08379149865013095
59.089 37.94563758389262 0.08301917067274656
59.089 37.946644295302015 0.08225493591907243
59.089 37.94765100671141 0.08149821514220101
59.089 37.948657718120806 0.08074831407100654
59.089 37.9496644295302 0.08000440666749176
59.089 37.9506711409396 0.07926551514546469
59.089 37.95167785234899 0.07853048611394703
59.089 37.95268456375839 0.07779796218916599
59.089 37.953691275167785 0.07706634839550466
59.089 37.95469798657718 0.07633377266151109
59.089 37.955704697986576 0.07666647646250316
59.089 37.95671140939597 0.07588754821190763
59.089 37.95771812080537 0.06966770528935495
59.089 37.95872483221476 0.06889945990561872
59.089 37.95973154362416 0.06811540316724482
59.089 37.960738255033554 0.05269148394415535
59.089 37.96174496644295 0.05189589575306035
59.089 37.96275167785235 0.04427890374878399
59.089 37.96375838926175 0.04343411842059213
59.089 37.964765100671144 0.04254252282471007
59.089 37.96577181208054 0.04159462947592838
59.089 37.966778523489936 0.02828358919430946
59.089 37.96778523489933 0.027331375791763736
59.089 37.96879194630873 0.026286476652180132
59.089 37.96979865771812 0.025134906378760948
59.089 37.97080536912752 0.023861878565524575
59.089 37.971812080536914 0.02245214298538447
59.089 37.97281879194631 0.02089046271777217
59.089 37.973825503355705 0.019162242517925674
59.089 37.9748322147651 0.017254303694644233
59.089 37.9758389261745 0.015155766780599746
59.089 37.97684563758389 0.012858905038371778
59.089 37.97785234899329 0.010359437085350424
59.089 37.978859060402684 0.007653867841415234
59.089 37.97986577181208 0.00472233468060718
59.089 37.980872483221475 0.0014404553142471112
59.089 37.98187919463087 -0.002843796161227284
59.089 37.982885906040266 -0.010992074288114096
59.089 37.98389261744966 -0.03008610893321073
59.089 37.98489932885906 -0.056273395622118715
59.089 37.98590604026845 -0.07149945631829668
59.089 37.98691275167785 -0.07813957683193738
59.089 37.98791946308725 -0.08228207099923646
59.089 37.98892617449665 -0.085816455141347
59.089 37.98993288590604 -0.08913818353601663
59.089 37.99093959731544 -0.09231135018072631
59.089 37.991946308724835 -0.09533656311655615
59.089 37.99295302013423 -0.0982058789901241
59.089 37.993959731543626 -0.10091347461613068
59.089 37.99496644295302 -0.10345726528999669
59.089 37.99597315436242 -0.1135243195184683
59.089 37.99697986577181 -0.11564818176296553
59.089 37.99798657718121 -0.1176247467694684
59.089 37.998993288590604 -0.11946134475523713
59.09 37.85 0.23321326604802423
59.09 37.8510067114094 0.2217697539385518
59.09 37.85201342281879 0.22066194694729083
59.09 37.85302013422819 0.21958215830032574
59.09 37.854026845637584 0.21852903914774074
59.09 37.85503355704698 0.21750125563855438
59.09 37.856040268456375 0.21649749123901932
59.09 37.85704697986577 0.21551644867880176
59.09 37.85805369127517 0.21455685155801676
59.09 37.85906040268456 0.21361744564793883
59.09 37.86006711409396 0.21269699991791213
59.09 37.861073825503354 0.21176497228120678
59.09 37.86208053691275 0.21090829087736562
59.09 37.86308724832215 0.2100671689371978
59.09 37.86409395973155 0.2092404733000073
59.09 37.865100671140944 0.2084270960946618
59.09 37.86610738255034 0.20762595475186998
59.09 37.867114093959735 0.2068359919416509
59.09 37.86812080536913 0.20605617545942262
59.09 37.869127516778526 0.20528549808291038
59.09 37.87013422818792 0.20452297742060194
59.09 37.87114093959732 0.20376765577116604
59.09 37.87214765100671 0.20301860001204206
59.09 37.87315436241611 0.20227490153394911
59.09 37.874161073825505 0.20153567623671143
59.09 37.8751677852349 0.2008000646005347
59.09 37.876174496644296 0.20006723184531122
59.09 37.87718120805369 0.19933636818901876
59.09 37.87818791946309 0.198606689214795
59.09 37.87919463087248 0.19787743635439276
59.09 37.88020134228188 0.19714787749378349
59.09 37.881208053691275 0.19641730770460136
59.09 37.88221476510067 0.19568505010243487
59.09 37.883221476510066 0.20434024335812234
59.09 37.88422818791946 0.2036668786120595
59.09 37.88523489932886 0.20299023606759845
59.09 37.88624161073825 0.20230976146751645
59.09 37.88724832214765 0.20162493452665606
59.09 37.88825503355705 0.2009352703159551
59.09 37.88926174496645 0.20024032060621888
59.09 37.89026845637584 0.19953967507701126
59.09 37.89127516778524 0.1988329622452849
59.09 37.892281879194634 0.198119849887688
59.09 37.89328859060403 0.19740004460199287
59.09 37.894295302013425 0.1886272788391602
59.09 37.89530201342282 0.18779852297512084
59.09 37.89630872483222 0.18696146112335552
59.09 37.89731543624161 0.18611589839520423
59.09 37.89832214765101 0.18526162689789955
59.09 37.899328859060404 0.1843983862122916
59.09 37.9003355704698 0.18352579844548078
59.09 37.901342281879195 0.19656963513575212
59.09 37.90234899328859 0.19574863852898922
59.09 37.90335570469799 0.19491505884992139
59.09 37.90436241610738 0.1940660986200191
59.09 37.90536912751678 0.19319711921947016
59.09 37.906375838926174 0.1923004971084844
59.09 37.90738255033557 0.19136382640260666
59.09 37.908389261744965 0.1903671517521799
59.09 37.90939597315436 0.19364317917069726
59.09 37.910402684563756 0.19248263023568832
59.09 37.91140939597315 0.1911089150785724
59.09 37.91241610738255 0.18941775891616025
59.09 37.91342281879195 0.18727421866569346
59.09 37.914429530201346 0.18453097691859574
59.09 37.91543624161074 0.18107936523675913
59.09 37.91644295302014 0.17693352502712917
59.09 37.91744966442953 0.17230477288261184
59.09 37.91845637583893 0.16758445854782336
59.09 37.919463087248324 0.16320270593537528
59.09 37.92046979865772 0.1468250717127369
59.09 37.921476510067116 0.14359063331417543
59.09 37.92248322147651 0.14101265015023107
59.09 37.92348993288591 0.1389516542512353
59.09 37.9244966442953 0.1372636474655985
59.09 37.9255033557047 0.13583066178365252
59.09 37.926510067114094 0.1345668110834836
59.09 37.92751677852349 0.13341366403843213
59.09 37.928523489932886 0.12619292014880099
59.09 37.92953020134228 0.12509574219970168
59.09 37.93053691275168 0.12403160964271859
59.09 37.93154362416107 0.12299119594303243
59.09 37.93255033557047 0.12196882585619534
59.09 37.933557046979864 0.1209610875337035
59.09 37.93456375838926 0.11996595568786848
59.09 37.935570469798655 0.11898224238730501
59.09 37.93657718120805 0.11800925462395288
59.09 37.937583892617454 0.11704658109972711
59.09 37.93859060402685 0.11609395920910798
59.09 37.939597315436245 0.1151511914806003
59.09 37.94060402684564 0.10327635670175483
59.09 37.94161073825504 0.10246316831720807
59.09 37.94261744966443 0.10165860183964089
59.09 37.94362416107383 0.10086233676848066
59.09 37.94463087248322 0.10007397087811212
59.09 37.94563758389262 0.0992930055510551
59.09 37.946644295302015 0.09851883046314676
59.09 37.94765100671141 0.09775070652917695
59.09 37.948657718120806 0.09698774621749244
59.09 37.9496644295302 0.09622889044641411
59.09 37.9506711409396 0.09547288132418981
59.09 37.95167785234899 0.09471823001524868
59.09 37.95268456375839 0.07827015450919891
59.09 37.953691275167785 0.07751643488288981
59.09 37.95469798657718 0.07675872035192464
59.09 37.955704697986576 0.07599416087487629
59.09 37.95671140939597 0.07521941894586534
59.09 37.95771812080537 0.07443059629793711
59.09 37.95872483221476 0.07461901588174234
59.09 37.95973154362416 0.0683639651754856
59.09 37.960738255033554 0.06749708697243398
59.09 37.96174496644295 0.0665938137782168
59.09 37.96275167785235 0.06564617394706923
59.09 37.96375838926175 0.06464513707535204
59.09 37.964765100671144 0.04885581040001258
59.09 37.96577181208054 0.04093114204730805
59.09 37.966778523489936 0.039769344511992086
59.09 37.96778523489933 0.026275325438255313
59.09 37.96879194630873 0.025044314919350854
59.09 37.96979865771812 0.0236847430665404
59.09 37.97080536912752 0.022182113103512032
59.09 37.971812080536914 0.02052250262171474
59.09 37.97281879194631 0.018693271350079914
59.09 37.973825503355705 0.016683862981808666
59.09 37.9748322147651 0.014486575996281042
59.09 37.9758389261745 0.012096821195843908
59.09 37.97684563758389 0.00951069972922644
59.09 37.97785234899329 0.0067094444311964
59.09 37.978859060402684 0.0035794950984467423
59.09 37.97986577181208 -0.00047254434378085283
59.09 37.980872483221475 -0.008083955754918933
59.09 37.98187919463087 -0.026206950049799053
59.09 37.982885906040266 -0.05244712709272218
59.09 37.98389261744966 -0.06835621897117439
59.09 37.98489932885906 -0.07519564190237264
59.09 37.98590604026845 -0.07932242074972824
59.09 37.98691275167785 -0.08280374822726153
59.09 37.98791946308725 -0.08607960923320467
59.09 37.98892617449665 -0.0892235277010618
59.09 37.98993288590604 -0.09223759158648727
59.09 37.99093959731544 -0.09511326874665611
59.09 37.991946308724835 -0.09784338713832388
59.09 37.99295302013423 -0.10042413253325108
59.09 37.993959731543626 -0.10285496778402214
59.09 37.99496644295302 -0.1051381110255484
59.09 37.99597315436242 -0.11473190915216028
59.09 37.99697986577181 -0.11664264331111597
59.09 37.99798657718121 -0.11842687163714988
59.09 37.998993288590604 -0.1200918600136777
59.091 37.85 0.2293144692348972
59.091 37.8510067114094 0.2279888193657127
59.091 37.85201342281879 0.2266971459131571
59.091 37.85302013422819 0.22543819461719095
59.091 37.854026845637584 0.22421069667836177
59.091 37.85503355704698 0.22301337367726884
59.091 37.856040268456375 0.22184494202907118
59.091 37.85704697986577 0.22070411699399173
59.091 37.85805369127517 0.21937896279790786
59.091 37.85906040268456 0.21831889242193261
59.091 37.86006711409396 0.21728275872848435
59.091 37.861073825503354 0.21626930348481166
59.091 37.86208053691275 0.21527728154864983
59.091 37.86308724832215 0.2143054627400518
59.091 37.86409395973155 0.2133526334538994
59.091 37.865100671140944 0.21241759804143606
59.091 37.86610738255034 0.21149917998925483
59.091 37.867114093959735 0.21059622292331082
59.091 37.86812080536913 0.20970759146499915
59.091 37.869127516778526 0.20883217196564885
59.091 37.87013422818792 0.20796887314469248
59.091 37.87114093959732 0.20711662665572664
59.091 37.87214765100671 0.20627438760368488
59.091 37.87315436241611 0.20544113503494738
59.091 37.874161073825505 0.20461587242089624
59.091 37.8751677852349 0.20379762815414715
59.091 37.876174496644296 0.20298545607502913
59.091 37.87718120805369 0.20217843604427502
59.091 37.87818791946309 0.20137567457626854
59.091 37.87919463087248 0.20982030025822404
59.091 37.88020134228188 0.20908783222540683
59.091 37.881208053691275 0.20835738589531916
59.091 37.88221476510067 0.20762818204042222
59.091 37.883221476510066 0.2068994724836556
59.091 37.88422818791946 0.20617054126799772
59.091 37.88523489932886 0.20544070591612698
59.091 37.88624161073825 0.2047093187672387
59.091 37.88724832214765 0.20397576836939396
59.091 37.88825503355705 0.20323948089303562
59.091 37.88926174496645 0.20249992151260382
59.091 37.89026845637584 0.20175659567466947
59.091 37.89127516778524 0.20100905012664644
59.091 37.892281879194634 0.2002568735099469
59.091 37.89328859060403 0.1914168925392088
59.091 37.894295302013425 0.1905585552137583
59.091 37.89530201342282 0.2034115373776812
59.091 37.89630872483222 0.2026134918003629
59.091 37.89731543624161 0.201808413782708
59.091 37.89832214765101 0.20099604567972243
59.091 37.899328859060404 0.20017609383528676
59.091 37.9003355704698 0.19934817268380703
59.091 37.901342281879195 0.19851171342764487
59.091 37.90234899328859 0.19766581561461555
59.091 37.90335570469799 0.19680900683663166
59.091 37.90436241610738 0.1959388550879582
59.091 37.90536912751678 0.19505134626025192
59.091 37.906375838926174 0.19829404977507983
59.091 37.90738255033557 0.19741361002989113
59.091 37.908389261744965 0.1964821028813293
59.091 37.90939597315436 0.19547200369054957
59.091 37.910402684563756 0.19434032734488826
59.091 37.91140939597315 0.1930218725811622
59.091 37.91241610738255 0.1914222547685849
59.091 37.91342281879195 0.1894151117265731
59.091 37.914429530201346 0.18685325020332522
59.091 37.91543624161074 0.1836083835917199
59.091 37.91644295302014 0.17964715574075033
59.091 37.91744966442953 0.17511633656187478
59.091 37.91845637583893 0.17036193235790062
59.091 37.919463087248324 0.153277317554282
59.091 37.92046979865772 0.14908847003968448
59.091 37.921476510067116 0.14561730532276157
59.091 37.92248322147651 0.1428356404142123
59.091 37.92348993288591 0.14061710470365452
59.091 37.9244966442953 0.13881427868806476
59.091 37.9255033557047 0.13729972774417357
59.091 37.926510067114094 0.12983945343556394
59.091 37.92751677852349 0.1285782771775673
59.091 37.928523489932886 0.12739994646993502
59.091 37.92953020134228 0.12627651870550233
59.091 37.93053691275168 0.12519045027884734
59.091 37.93154362416107 0.12413088427397016
59.091 37.93255033557047 0.12309118064616391
59.091 37.933557046979864 0.12206732189572958
59.091 37.93456375838926 0.12105690168998062
59.091 37.935570469798655 0.1200584896365612
59.091 37.93657718120805 0.11907123432507327
59.091 37.937583892617454 0.11809461555885538
59.091 37.93859060402685 0.11712828923702073
59.091 37.939597315436245 0.116171989344705
59.091 37.94060402684564 0.11522546480615008
59.091 37.94161073825504 0.11428843727818037
59.091 37.94261744966443 0.10257253823681843
59.091 37.94362416107383 0.10176354943663468
59.091 37.94463087248322 0.10096219386022613
59.091 37.94563758389262 0.10016783572769611
59.091 37.946644295302015 0.09937969707567335
59.091 37.94765100671141 0.09859683449684825
59.091 37.948657718120806 0.09781811245401359
59.091 37.9496644295302 0.09704217228283062
59.091 37.9506711409396 0.09626739609284654
59.091 37.95167785234899 0.09549186485722719
59.091 37.95268456375839 0.09471331008003243
59.091 37.953691275167785 0.09392905857499456
59.091 37.95469798657718 0.0931359701144046
59.091 37.955704697986576 0.09233036804681245
59.091 37.95671140939597 0.09150796348057033
59.091 37.95771812080537 0.09066377433470271
59.091 37.95872483221476 0.0738635639428783
59.091 37.95973154362416 0.07296757035651526
59.091 37.960738255033554 0.07298949471616079
59.091 37.96174496644295 0.0666278377740823
59.091 37.96275167785235 0.06557760436634605
59.091 37.96375838926175 0.06445839651729258
59.091 37.964765100671144 0.06325855867976023
59.091 37.96577181208054 0.06196553055460867
59.091 37.966778523489936 0.060566036611198026
59.091 37.96778523489933 0.05904637587792834
59.091 37.96879194630873 0.023569569893302424
59.091 37.96979865771812 0.021984822445905706
59.091 37.97080536912752 0.02024121682931232
59.091 37.971812080536914 0.018328523724982716
59.091 37.97281879194631 0.01623905049749943
59.091 37.973825503355705 0.01396784084684154
59.091 37.9748322147651 0.0115104178097452
59.091 37.9758389261745 0.008848561076593934
59.091 37.97684563758389 0.005877592976492114
59.091 37.97785234899329 0.0020556739819944554
59.091 37.978859060402684 -0.005046201134119573
59.091 37.97986577181208 -0.022193891900259173
59.091 37.980872483221475 -0.04836089153545869
59.091 37.98187919463087 -0.06494833775349408
59.091 37.982885906040266 -0.07200086613306905
59.091 37.98389261744966 -0.0761084510747559
59.091 37.98489932885906 -0.07952766110664468
59.091 37.98590604026845 -0.08274774588758038
59.091 37.98691275167785 -0.0858530808035915
59.091 37.98791946308725 -0.0888477789070776
59.091 37.98892617449665 -0.0917230046165398
59.091 37.98993288590604 -0.09447040674443091
59.091 37.99093959731544 -0.09708453303451536
59.091 37.991946308724835 -0.09956294652623103
59.091 37.99295302013423 -0.1019058410522673
59.091 37.993959731543626 -0.10411555367071881
59.091 37.99496644295302 -0.1061960805036104
59.091 37.99597315436242 -0.11538662941789324
59.091 37.99697986577181 -0.11713994678099406
59.091 37.99798657718121 -0.11878490867929756
59.091 37.998993288590604 -0.12032791888242762
59.092 37.85 0.23677151196551388
59.092 37.8510067114094 0.23525038782255664
59.092 37.85201342281879 0.23376584151537108
59.092 37.85302013422819 0.23231691523996967
59.092 37.854026845637584 0.23090260093858087
59.092 37.85503355704698 0.22952184750402388
59.092 37.856040268456375 0.22780711107986515
59.092 37.85704697986577 0.2265196855741273
59.092 37.85805369127517 0.22526264536841054
59.092 37.85906040268456 0.22403482664430063
59.092 37.86006711409396 0.22283505053656366
59.092 37.861073825503354 0.22166212741031682
59.092 37.86208053691275 0.2205148607647818
59.092 37.86308724832215 0.21939205078814827
59.092 37.86409395973155 0.21829249758757877
59.092 37.865100671140944 0.217215004118302
59.092 37.86610738255034 0.2161583788364061
59.092 37.867114093959735 0.21512143809975798
59.092 37.86812080536913 0.2141030083418426
59.092 37.869127516778526 0.21310192804353245
59.092 37.87013422818792 0.21211704952755964
59.092 37.87114093959732 0.2111472406002506
59.092 37.87214765100671 0.21019138606483842
59.092 37.87315436241611 0.20924838912991828
59.092 37.874161073825505 0.20831717273584816
59.092 37.8751677852349 0.20739668082111193
59.092 37.876174496644296 0.21564301138595773
59.092 37.87718120805369 0.21480563588959112
59.092 37.87818791946309 0.2139762464326756
59.092 37.87919463087248 0.21315388309261674
59.092 37.88020134228188 0.21233761302323223
59.092 37.881208053691275 0.2115265317406609
59.092 37.88221476510067 0.21071976445914606
59.092 37.883221476510066 0.20991646748303391
59.092 37.88422818791946 0.2091158296577801
59.092 37.88523489932886 0.20831707387800982
59.092 37.88624161073825 0.20751945864469484
59.092 37.88724832214765 0.20672227965553702
59.092 37.88825503355705 0.20592487140107377
59.092 37.88926174496645 0.20512660872231853
59.092 37.89026845637584 0.21824764595364762
59.092 37.89127516778524 0.20914951416764047
59.092 37.892281879194634 0.20832448831748798
59.092 37.89328859060403 0.20749565731766142
59.092 37.894295302013425 0.206662599656281
59.092 37.89530201342282 0.20582493410752087
59.092 37.89630872483222 0.20498231427486402
59.092 37.89731543624161 0.20413441859826223
59.092 37.89832214765101 0.2032809330556437
59.092 37.899328859060404 0.2024215220929812
59.092 37.9003355704698 0.20155578057615509
59.092 37.901342281879195 0.2006831551373676
59.092 37.90234899328859 0.1998028161823509
59.092 37.90335570469799 0.19891345046100387
59.092 37.90436241610738 0.20202642183639305
59.092 37.90536912751678 0.20117518335768989
59.092 37.906375838926174 0.20030449475713646
59.092 37.90738255033557 0.1994050802160179
59.092 37.908389261744965 0.1984619514285773
59.092 37.90939597315436 0.1974511658152006
59.092 37.910402684563756 0.1963351046935829
59.092 37.91140939597315 0.19505613216581488
59.092 37.91241610738255 0.19352941530757006
59.092 37.91342281879195 0.19163810139909002
59.092 37.914429530201346 0.18923877126790212
59.092 37.91543624161074 0.1861908400894895
59.092 37.91644295302014 0.18242207125266627
59.092 37.91744966442953 0.16577470881527762
59.092 37.91845637583893 0.16081232156468742
59.092 37.919463087248324 0.15592867546673495
59.092 37.92046979865772 0.15151948203234042
59.092 37.921476510067116 0.1478036144284025
59.092 37.92248322147651 0.14480134364605227
59.092 37.92348993288591 0.1363411984235001
59.092 37.9244966442953 0.13434055064716552
59.092 37.9255033557047 0.13266455685943998
59.092 37.926510067114094 0.13120774223659795
59.092 37.92751677852349 0.12989560783242032
59.092 37.928523489932886 0.12867837655051836
59.092 37.92953020134228 0.12752390951234435
59.092 37.93053691275168 0.1264119111691736
59.092 37.93154362416107 0.12532976071057922
59.092 37.93255033557047 0.12426970141943668
59.092 37.933557046979864 0.12322701219837683
59.092 37.93456375838926 0.12219884213079285
59.092 37.935570469798655 0.1211834761695528
59.092 37.93657718120805 0.1201798751047605
59.092 37.937583892617454 0.11918738766231725
59.092 37.93859060402685 0.1182055695870925
59.092 37.939597315436245 0.11723406863505915
59.092 37.94060402684564 0.11627254971188325
59.092 37.94161073825504 0.11532064400703403
59.092 37.94261744966443 0.1143779119518897
59.092 37.94362416107383 0.11344381352589344
59.092 37.94463087248322 0.10188621453661666
59.092 37.94563758389262 0.1010770126412258
59.092 37.946644295302015 0.10027334668113919
59.092 37.94765100671141 0.09947400658600271
59.092 37.948657718120806 0.09867753452796
59.092 37.9496644295302 0.0978821846513747
59.092 37.9506711409396 0.09708587696371762
59.092 37.95167785234899 0.09628614487768947
59.092 37.95268456375839 0.09548007615235851
59.092 37.953691275167785 0.09466424733614756
59.092 37.95469798657718 0.0938346523107838
59.092 37.955704697986576 0.09298662621763802
59.092 37.95671140939597 0.09211476696260798
59.092 37.95771812080537 0.09121285768452038
59.092 37.95872483221476 0.09027379506316825
59.092 37.95973154362416 0.08928953013874358
59.092 37.960738255033554 0.0882510303694504
59.092 37.96174496644295 0.08714827384980796
59.092 37.96275167785235 0.06541370597553453
59.092 37.96375838926175 0.06415784395575518
59.092 37.964765100671144 0.06280405357106361
59.092 37.96577181208054 0.06133943526933203
59.092 37.966778523489936 0.05975104221087186
59.092 37.96778523489933 0.058026350204460106
59.092 37.96879194630873 0.05615382287125459
59.092 37.96979865771812 0.05412353478525956
59.092 37.97080536912752 0.03732472902671123
59.092 37.971812080536914 0.015980787139955835
59.092 37.97281879194631 0.013660067417973449
59.092 37.973825503355705 0.011144105167131706
59.092 37.9748322147651 0.008333437925173926
59.092 37.9758389261745 0.0047271056012049145
59.092 37.97684563758389 -0.001916368880507422
59.092 37.97785234899329 -0.018107101008320935
59.092 37.978859060402684 -0.044025549112292915
59.092 37.97986577181208 -0.06125067457296972
59.092 37.980872483221475 -0.068535338507675
59.092 37.98187919463087 -0.07262680855169874
59.092 37.982885906040266 -0.07597795275197163
59.092 37.98389261744966 -0.07913325460641818
59.092 37.98489932885906 -0.08219032288719193
59.092 37.98590604026845 -0.08515636130544231
59.092 37.98691275167785 -0.0880228048720712
59.092 37.98791946308725 -0.09078047016953618
59.092 37.98892617449665 -0.09342248105459403
59.092 37.98993288590604 -0.09594462155771738
59.092 37.99093959731544 -0.09834510896945634
59.092 37.991946308724835 -0.10062422670235298
59.092 37.99295302013423 -0.1027839331261502
59.092 37.993959731543626 -0.10482748801881064
59.092 37.99496644295302 -0.10675911562371743
59.092 37.99597315436242 -0.11560807703295152
59.092 37.99697986577181 -0.11725102721507598
59.092 37.99798657718121 -0.11880021274583065
59.092 37.998993288590604 -0.12026094683349814
59.093 37.85 0.24516903088476602
59.093 37.8510067114094 0.24346581416039992
59.093 37.85201342281879 0.24179838371342952
59.093 37.85302013422819 0.24016638671068974
59.093 37.854026845637584 0.23804214833932752
59.093 37.85503355704698 0.23650906844792932
59.093 37.856040268456375 0.23500987744758162
59.093 37.85704697986577 0.2335438197433431
59.093 37.85805369127517 0.2321100738537665
59.093 37.85906040268456 0.23070776182841576
59.093 37.86006711409396 0.22933595736950257
59.093 37.861073825503354 0.22799369294385224
59.093 37.86208053691275 0.22667996609233945
59.093 37.86308724832215 0.2253937450877843
59.093 37.86409395973155 0.22413397405221439
59.093 37.865100671140944 0.22289957761619528
59.093 37.86610738255034 0.22168946518387989
59.093 37.867114093959735 0.2205025348533982
59.093 37.86812080536913 0.21933767703313425
59.093 37.869127516778526 0.21819377778828958
59.093 37.87013422818792 0.21706972194768828
59.093 37.87114093959732 0.21596439599791542
59.093 37.87214765100671 0.21487669079011004
59.093 37.87315436241611 0.2228709408454846
59.093 37.874161073825505 0.22188030042905707
59.093 37.8751677852349 0.22090431366073338
59.093 37.876174496644296 0.21994191336283825
59.093 37.87718120805369 0.21899204821752533
59.093 37.87818791946309 0.21805368480945314
59.093 37.87919463087248 0.21712580962571082
59.093 37.88020134228188 0.2162074310287648
59.093 37.881208053691275 0.2152975812165017
59.093 37.88221476510067 0.21439531818105984
59.093 37.883221476510066 0.2134997276754553
59.093 37.88422818791946 0.2126099251938512
59.093 37.88523489932886 0.21172505796698948
59.093 37.88624161073825 0.22464963725492945
59.093 37.88724832214765 0.22385590500719565
59.093 37.88825503355705 0.22306504208326072
59.093 37.88926174496645 0.22227634178972688
59.093 37.89026845637584 0.21308876322601683
59.093 37.89127516778524 0.21219735287374833
59.093 37.892281879194634 0.2113050760204564
59.093 37.89328859060403 0.21041138754730646
59.093 37.894295302013425 0.2095157866461154
59.093 37.89530201342282 0.20861781497129273
59.093 37.89630872483222 0.2077170523351042
59.093 37.89731543624161 0.2068131084598291
59.093 37.89832214765101 0.20590560839498867
59.093 37.899328859060404 0.2049941677439475
59.093 37.9003355704698 0.20407835147791387
59.093 37.901342281879195 0.20315760629679191
59.093 37.90234899328859 0.20610616736124163
59.093 37.90335570469799 0.2052349889276821
59.093 37.90436241610738 0.2043559190100122
59.093 37.90536912751678 0.20346592513820377
59.093 37.906375838926174 0.20256006676606453
59.093 37.90738255033557 0.2016303088780508
59.093 37.908389261744965 0.20066366013555234
59.093 37.90939597315436 0.1996393129667116
59.093 37.910402684563756 0.19852440972394247
59.093 37.91140939597315 0.19726817906153613
59.093 37.91241610738255 0.1957948263745982
59.093 37.91342281879195 0.19399741417374416
59.093 37.914429530201346 0.1917389501824373
59.093 37.91543624161074 0.18887277572578196
59.093 37.91644295302014 0.1731480315370976
59.093 37.91744966442953 0.16867920827591323
59.093 37.91845637583893 0.16375282309148514
59.093 37.919463087248324 0.15876956679250806
59.093 37.92046979865772 0.15415444430808106
59.093 37.921476510067116 0.14413465581437424
59.093 37.92248322147651 0.14082752215370783
59.093 37.92348993288591 0.13816923308593998
59.093 37.9244966442953 0.13601801863588933
59.093 37.9255033557047 0.13423123516357402
59.093 37.926510067114094 0.13269342021187516
59.093 37.92751677852349 0.13132106061058182
59.093 37.928523489932886 0.13005754955533846
59.093 37.92953020134228 0.12886600226086012
59.093 37.93053691275168 0.12772297834484747
59.093 37.93154362416107 0.12661382844856037
59.093 37.93255033557047 0.12552950626974468
59.093 37.933557046979864 0.12446447556504402
59.093 37.93456375838926 0.123415368688861
59.093 37.935570469798655 0.12238013828568298
59.093 37.93657718120805 0.1213575243315494
59.093 37.937583892617454 0.12034671961865244
59.093 37.93859060402685 0.11934715870664259
59.093 37.939597315436245 0.11835838290318607
59.093 37.94060402684564 0.1173799514534213
59.093 37.94161073825504 0.11641138021372725
59.093 37.94261744966443 0.11545209600545067
59.093 37.94362416107383 0.11450139913308685
59.093 37.94463087248322 0.11355842919973284
59.093 37.94563758389262 0.1126221309878159
59.093 37.946644295302015 0.1012189900097993
59.093 37.94765100671141 0.10040131173004702
59.093 37.948657718120806 0.09958496277188213
59.093 37.9496644295302 0.09876770814824963
59.093 37.9506711409396 0.09794688985438796
59.093 37.95167785234899 0.09711936479493757
59.093 37.95268456375839 0.09628143737123442
59.093 37.953691275167785 0.09542878795508104
59.093 37.95469798657718 0.09455639935186388
59.093 37.955704697986576 0.09365848446885454
59.093 37.95671140939597 0.09272841977390224
59.093 37.95771812080537 0.09175869074642845
59.093 37.95872483221476 0.09074085733078666
59.093 37.95973154362416 0.08966554927756731
59.093 37.960738255033554 0.08852250298104195
59.093 37.96174496644295 0.08730065264550882
59.093 37.96275167785235 0.08598828884165666
59.093 37.96375838926175 0.08457329605388487
59.093 37.964765100671144 0.07937163125445817
59.093 37.96577181208054 0.07939604107806846
59.093 37.966778523489936 0.05882916367644748
59.093 37.96778523489933 0.056903712535087636
59.093 37.96879194630873 0.05482432125710021
59.093 37.96979865771812 0.05258423854197687
59.093 37.97080536912752 0.05017518397752785
59.093 37.971812080536914 0.03936327557981385
59.093 37.97281879194631 0.030232202713954763
59.093 37.973825503355705 0.026656096311389686
59.093 37.9748322147651 0.0011280622807009859
59.093 37.9758389261745 -0.014200357070125638
59.093 37.97684563758389 -0.03951162050938244
59.093 37.97785234899329 -0.05719149785648513
59.093 37.978859060402684 -0.06474088297345945
59.093 37.97986577181208 -0.06884606370380458
59.093 37.980872483221475 -0.07213845596620887
59.093 37.98187919463087 -0.07522717555007251
59.093 37.982885906040266 -0.07822910385146992
59.093 37.98389261744966 -0.08115763549156263
59.093 37.98489932885906 -0.08400610874756209
59.093 37.98590604026845 -0.08676541767142235
59.093 37.98691275167785 -0.08942779439598804
59.093 37.98791946308725 -0.09198756116637818
59.093 37.98892617449665 -0.09444114011580866
59.093 37.98993288590604 -0.09678684754425936
59.093 37.99093959731544 -0.09902461959221101
59.093 37.991946308724835 -0.10115572405308597
59.093 37.99295302013423 -0.10318248432443346
59.093 37.993959731543626 -0.10510802888887981
59.093 37.99496644295302 -0.10693607258449726
59.093 37.99597315436242 -0.1154943421314633
59.093 37.99697986577181 -0.11706445248710254
59.093 37.99798657718121 -0.11855190352843152
59.093 37.998993288590604 -0.11996097199132999
59.094 37.85 0.2541704759551732
59.094 37.8510067114094 0.25234631348856756
59.094 37.85201342281879 0.24985202240280585
59.094 37.85302013422819 0.24810746381492185
59.094 37.854026845637584 0.24639119429628864
59.094 37.85503355704698 0.24470435979631985
59.094 37.856040268456375 0.24304764555711078
59.094 37.85704697986577 0.24142138363066817
59.094 37.85805369127517 0.2398256331320694
59.094 37.85906040268456 0.23826024018186404
59.094 37.86006711409396 0.2367248828455713
59.094 37.861073825503354 0.23521910504991722
59.094 37.86208053691275 0.2337423424239498
59.094 37.86308724832215 0.23229394223116012
59.094 37.86409395973155 0.23087317897471088
59.094 37.865100671140944 0.22947926682674966
59.094 37.86610738255034 0.22811136971776738
59.094 37.867114093959735 0.22676860969164037
59.094 37.86812080536913 0.22545007396571054
59.094 37.869127516778526 0.2241548210152117
59.094 37.87013422818792 0.22288188591461314
59.094 37.87114093959732 0.23066467869318702
59.094 37.87214765100671 0.22949921248734503
59.094 37.87315436241611 0.22835340616283428
59.094 37.874161073825505 0.22722624583617274
59.094 37.8751677852349 0.2261167144212428
59.094 37.876174496644296 0.22502379490743585
59.094 37.87718120805369 0.2239464734908744
59.094 37.87818791946309 0.22288374258774835
59.094 37.87919463087248 0.2218346037538286
59.094 37.88020134228188 0.2207980705303874
59.094 37.881208053691275 0.21977317123363235
59.094 37.88221476510067 0.21875895170151227
59.094 37.883221476510066 0.2315208795362078
59.094 37.88422818791946 0.23060971637883998
59.094 37.88523489932886 0.22970680191544987
59.094 37.88624161073825 0.22881127852652572
59.094 37.88724832214765 0.2279223194173694
59.094 37.88825503355705 0.21872688601895685
59.094 37.88926174496645 0.21774405071660516
59.094 37.89026845637584 0.21676433731280637
59.094 37.89127516778524 0.2157870540856772
59.094 37.892281879194634 0.21481155107859892
59.094 37.89328859060403 0.21383722133395747
59.094 37.894295302013425 0.21286350127311207
59.094 37.89530201342282 0.21188986972083146
59.094 37.89630872483222 0.2109158447730057
59.094 37.89731543624161 0.20994097722280308
59.094 37.89832214765101 0.20896483847910213
59.094 37.899328859060404 0.20798699964780556
59.094 37.9003355704698 0.2107458412104179
59.094 37.901342281879195 0.20982354201024847
59.094 37.90234899328859 0.20889865819100212
59.094 37.90335570469799 0.2079701186097138
59.094 37.90436241610738 0.20703626118664203
59.094 37.90536912751678 0.20609443036882544
59.094 37.906375838926174 0.20514033092962328
59.094 37.90738255033557 0.2041669978047836
59.094 37.908389261744965 0.20316317187645688
59.094 37.90939597315436 0.20211078552485018
59.094 37.910402684563756 0.20098118929809985
59.094 37.91140939597315 0.19972979355184386
59.094 37.91241610738255 0.1982892237209933
59.094 37.91342281879195 0.1965624712246092
59.094 37.914429530201346 0.19442077038610342
59.094 37.91543624161074 0.17966532426491552
59.094 37.91644295302014 0.17606516929377827
59.094 37.91744966442953 0.171753410906197
59.094 37.91845637583893 0.16689326670921886
59.094 37.919463087248324 0.15580431096884964
59.094 37.92046979865772 0.15093344538317768
59.094 37.921476510067116 0.14664865392192028
59.094 37.92248322147651 0.14308772823969873
59.094 37.92348993288591 0.14021133157236607
59.094 37.9244966442953 0.13788903824629808
59.094 37.9255033557047 0.13597397507083625
59.094 37.926510067114094 0.13434104334880492
59.094 37.92751677852349 0.13289718931154973
59.094 37.928523489932886 0.1315782807430631
59.094 37.92953020134228 0.1303420918754628
59.094 37.93053691275168 0.12916160070216579
59.094 37.93154362416107 0.12801983147819668
59.094 37.93255033557047 0.12690625292213553
59.094 37.933557046979864 0.1258143878058117
59.094 37.93456375838926 0.12474027089936783
59.094 37.935570469798655 0.12368146968796084
59.094 37.93657718120805 0.12263646714061899
59.094 37.937583892617454 0.12160427304856956
59.094 37.93859060402685 0.12058417776264883
59.094 37.939597315436245 0.11957559359501
59.094 37.94060402684564 0.11857794939531899
59.094 37.94161073825504 0.1175906166178497
59.094 37.94261744966443 0.11661285321163103
59.094 37.94362416107383 0.11564375665602324
59.094 37.94463087248322 0.11468222057037472
59.094 37.94563758389262 0.11372689127627433
59.094 37.946644295302015 0.11277612194802247
59.094 37.94765100671141 0.11182792284837749
59.094 37.948657718120806 0.10056942473351721
59.094 37.9496644295302 0.09972774941676102
59.094 37.9506711409396 0.09887940357350096
59.094 37.95167785234899 0.09802043867572985
59.094 37.95268456375839 0.09714624723946375
59.094 37.953691275167785 0.09625149496307532
59.094 37.95469798657718 0.09533005853801553
59.094 37.955704697986576 0.0943749748509085
59.094 37.95671140939597 0.09337840886426536
59.094 37.95771812080537 0.092331649016215
59.094 37.95872483221476 0.0912251402813627
59.094 37.95973154362416 0.09004856570581389
59.094 37.960738255033554 0.08879098666622244
59.094 37.96174496644295 0.08744104929965205
59.094 37.96275167785235 0.08598725764473333
59.094 37.96375838926175 0.08441829905869366
59.094 37.964765100671144 0.08272337481487597
59.094 37.96577181208054 0.07725317169593433
59.094 37.966778523489936 0.07529176924767342
59.094 37.96778523489933 0.07480588388327612
59.094 37.96879194630873 0.07246824614088887
59.094 37.96979865771812 0.06991256188066133
59.094 37.97080536912752 0.048118293875003285
59.094 37.971812080536914 0.044326557282909476
59.094 37.97281879194631 0.02943983833395695
59.094 37.973825503355705 0.014464012102401044
59.094 37.9748322147651 -0.015716021020144154
59.094 37.9758389261745 -0.03323890573900895
59.094 37.97684563758389 -0.06039174018547758
59.094 37.97785234899329 -0.06464195570763417
59.094 37.978859060402684 -0.06794884319338931
59.094 37.97986577181208 -0.07100232051916297
59.094 37.980872483221475 -0.07395823783699153
59.094 37.98187919463087 -0.07684746369142453
59.094 37.982885906040266 -0.07967116229015957
59.094 37.98389261744966 -0.08242341055752683
59.094 37.98489932885906 -0.0850972152639798
59.094 37.98590604026845 -0.08768635940299974
59.094 37.98691275167785 -0.09018599331309324
59.094 37.98791946308725 -0.09259276146546645
59.094 37.98892617449665 -0.0949047317061179
59.094 37.98993288590604 -0.09712123873305345
59.094 37.99093959731544 -0.09924269604273794
59.094 37.991946308724835 -0.1012704045141282
59.094 37.99295302013423 -0.10320637231493761
59.094 37.993959731543626 -0.10505315328480105
59.094 37.99496644295302 -0.11351770126509575
59.094 37.99597315436242 -0.11512169605805149
59.094 37.99697986577181 -0.11664782858023544
59.094 37.99798657718121 -0.11809942630162933
59.094 37.998993288590604 -0.11947989201014535
59.095 37.85 0.26125936181551807
59.095 37.8510067114094 0.2597548129740896
59.095 37.85201342281879 0.2581714202295275
59.095 37.85302013422819 0.25653335699719915
59.095 37.854026845637584 0.2548606910702817
59.095 37.85503355704698 0.2531693806180854
59.095 37.856040268456375 0.25147173533569755
59.095 37.85704697986577 0.24977703544652316
59.095 37.85805369127517 0.24809214661786666
59.095 37.85906040268456 0.24642205948596857
59.095 37.86006711409396 0.24477033247639332
59.095 37.861073825503354 0.2431394409557137
59.095 37.86208053691275 0.24153104561168975
59.095 37.86308724832215 0.2399461953910732
59.095 37.86409395973155 0.23838547936834317
59.095 37.865100671140944 0.23684913970995083
59.095 37.86610738255034 0.23533715547252718
59.095 37.867114093959735 0.23384930477164576
59.095 37.86812080536913 0.24131847670887
59.095 37.869127516778526 0.23994376344177676
59.095 37.87013422818792 0.2385920774015767
59.095 37.87114093959732 0.23726275565362812
59.095 37.87214765100671 0.23595507646914224
59.095 37.87315436241611 0.23466827140009805
59.095 37.874161073825505 0.23340153496654387
59.095 37.8751677852349 0.2321540326061624
59.095 37.876174496644296 0.23092490735794102
59.095 37.87718120805369 0.2297132856228539
59.095 37.87818791946309 0.22851828225128046
59.095 37.87919463087248 0.2273390051389866
59.095 37.88020134228188 0.23989570676625124
59.095 37.881208053691275 0.23883044696403888
59.095 37.88221476510067 0.23777855658101726
59.095 37.883221476510066 0.23673915590662545
59.095 37.88422818791946 0.23571137743183995
59.095 37.88523489932886 0.2346943693012624
59.095 37.88624161073825 0.23368729869598126
59.095 37.88724832214765 0.22436548361839034
59.095 37.88825503355705 0.22327054114718928
59.095 37.88926174496645 0.2221819915817468
59.095 37.89026845637584 0.22109909656569587
59.095 37.89127516778524 0.22002115173667847
59.095 37.892281879194634 0.21894748914970572
59.095 37.89328859060403 0.21787747920519526
59.095 37.894295302013425 0.2168105318071354
59.095 37.89530201342282 0.215746096315673
59.095 37.89630872483222 0.2146836595996735
59.095 37.89731543624161 0.2136227410771958
59.095 37.89832214765101 0.21616796819024645
59.095 37.899328859060404 0.2151673974578833
59.095 37.9003355704698 0.2141678296248319
59.095 37.901342281879195 0.21316873244781626
59.095 37.90234899328859 0.21216941983630538
59.095 37.90335570469799 0.21116891929965123
59.095 37.90436241610738 0.21016575684188332
59.095 37.90536912751678 0.2091576093982278
59.095 37.906375838926174 0.2081407458329987
59.095 37.90738255033557 0.2071091334676348
59.095 37.908389261744965 0.20605302366839473
59.095 37.90939597315436 0.20495674753856016
59.095 37.910402684563756 0.20379536998059128
59.095 37.91140939597315 0.2025298394202912
59.095 37.91241610738255 0.20110053396231756
59.095 37.91342281879195 0.18767498014847472
59.095 37.914429530201346 0.18541890834262087
59.095 37.91543624161074 0.18264451321175457
59.095 37.91644295302014 0.17921367354940715
59.095 37.91744966442953 0.16904366324709952
59.095 37.91845637583893 0.16420423694786349
59.095 37.919463087248324 0.15905027538604272
59.095 37.92046979865772 0.15401375774865103
59.095 37.921476510067116 0.14948020974906373
59.095 37.92248322147651 0.14565178218886626
59.095 37.92348993288591 0.1425354552360286
59.095 37.9244966442953 0.1400193390809641
59.095 37.9255033557047 0.1379558527435606
59.095 37.926510067114094 0.13621112501714722
59.095 37.92751677852349 0.13468220758573538
59.095 37.928523489932886 0.13329676036391044
59.095 37.92953020134228 0.1320065832502696
59.095 37.93053691275168 0.13078059227785593
59.095 37.93154362416107 0.12959915318560056
59.095 37.93255033557047 0.12845003104850655
59.095 37.933557046979864 0.12732566739754564
59.095 37.93456375838926 0.12622141025648567
59.095 37.935570469798655 0.12513438459795087
59.095 37.93657718120805 0.12406277777430041
59.095 37.937583892617454 0.12300538794101556
59.095 37.93859060402685 0.12196133660429365
59.095 37.939597315436245 0.12092988221365415
59.095 37.94060402684564 0.11991029495756042
59.095 37.94161073825504 0.1189017677001931
59.095 37.94261744966443 0.11790334729534108
59.095 37.94362416107383 0.11691387634128096
59.095 37.94463087248322 0.1159319391121372
59.095 37.94563758389262 0.11495580775936354
59.095 37.946644295302015 0.11398338646150905
59.095 37.94765100671141 0.11301215235822507
59.095 37.948657718120806 0.11203909306128257
59.095 37.9496644295302 0.10080504855507502
59.095 37.9506711409396 0.0999262579309556
59.095 37.95167785234899 0.09903233931818439
59.095 37.95268456375839 0.09811768221478388
59.095 37.953691275167785 0.09717588200428784
59.095 37.95469798657718 0.09619970896641666
59.095 37.955704697986576 0.09518110225105154
59.095 37.95671140939597 0.09411119720839281
59.095 37.95771812080537 0.09298039407584448
59.095 37.95872483221476 0.09177847334512752
59.095 37.95973154362416 0.09049475564594446
59.095 37.960738255033554 0.08911828596651597
59.095 37.96174496644295 0.08763797986436946
59.095 37.96275167785235 0.08604256947150918
59.095 37.96375838926175 0.08431994498980674
59.095 37.964765100671144 0.08245488403001881
59.095 37.96577181208054 0.08042260854924466
59.095 37.966778523489936 0.07456563482563602
59.095 37.96778523489933 0.07198558108215833
59.095 37.96879194630873 0.07037751316479873
59.095 37.96979865771812 0.06553983725791458
59.095 37.97080536912752 0.05704060464362825
59.095 37.971812080536914 0.041754490013911134
59.095 37.97281879194631 0.02184624520091425
59.095 37.973825503355705 -0.020356483189006458
59.095 37.9748322147651 -0.028611442148170366
59.095 37.9758389261745 -0.033582758318895234
59.095 37.97684563758389 -0.04366198027401763
59.095 37.97785234899329 -0.04697546677547507
59.095 37.978859060402684 -0.05009525128488275
59.095 37.97986577181208 -0.0722035185350557
59.095 37.980872483221475 -0.07501186017019248
59.095 37.98187919463087 -0.07775514526836916
59.095 37.982885906040266 -0.08043336810120123
59.095 37.98389261744966 -0.08304302015910701
59.095 37.98489932885906 -0.0855797060646444
59.095 37.98590604026845 -0.08803933492470348
59.095 37.98691275167785 -0.0904186198419042
59.095 37.98791946308725 -0.0927152372213224
59.095 37.98892617449665 -0.09492782109221443
59.095 37.98993288590604 -0.09705588140203118
59.095 37.99093959731544 -0.09909969184312384
59.095 37.991946308724835 -0.10106017050656345
59.095 37.99295302013423 -0.1029387650244336
59.095 37.993959731543626 -0.10473734768187602
59.095 37.99496644295302 -0.11297200860771468
59.095 37.99597315436242 -0.11454724264456798
59.095 37.99697986577181 -0.11605109430664359
59.095 37.99798657718121 -0.11748617092487795
59.095 37.998993288590604 -0.11885518233754423
59.096000000000004 37.85 0.2608779735029412
59.096000000000004 37.8510067114094 0.2605274922979746
59.096000000000004 37.85201342281879 0.2601849113652267
59.096000000000004 37.85302013422819 0.2597647275949878
59.096000000000004 37.854026845637584 0.25920346513711107
59.096000000000004 37.85503355704698 0.25846657866884254
59.096000000000004 37.856040268456375 0.2575468721789349
59.096000000000004 37.85704697986577 0.2564573796366878
59.096000000000004 37.85805369127517 0.25522272284900865
59.096000000000004 37.85906040268456 0.25387178891031414
59.096000000000004 37.86006711409396 0.25243282151382457
59.096000000000004 37.861073825503354 0.25093079324732603
59.096000000000004 37.86208053691275 0.2493864218078165
59.096000000000004 37.86308724832215 0.24781616516664465
59.096000000000004 37.86409395973155 0.24623269564214448
59.096000000000004 37.865100671140944 0.24464554044681083
59.096000000000004 37.86610738255034 0.2519551348390281
59.096000000000004 37.867114093959735 0.2504464705069384
59.096000000000004 37.86812080536913 0.24895020752529967
59.096000000000004 37.869127516778526 0.2474688320871709
59.096000000000004 37.87013422818792 0.24600399659811406
59.096000000000004 37.87114093959732 0.24455673229219707
59.096000000000004 37.87214765100671 0.24312760828741548
59.096000000000004 37.87315436241611 0.24171684978963714
59.096000000000004 37.874161073825505 0.24032442565067075
59.096000000000004 37.8751677852349 0.23895011319749057
59.096000000000004 37.876174496644296 0.2375935463389244
59.096000000000004 37.87718120805369 0.24992377884451772
59.096000000000004 37.87818791946309 0.24868719351252952
59.096000000000004 37.87919463087248 0.2474670565654259
59.096000000000004 37.88020134228188 0.24626271049271622
59.096000000000004 37.881208053691275 0.24507346593278612
59.096000000000004 37.88221476510067 0.24389861164751234
59.096000000000004 37.883221476510066 0.2427374227373133
59.096000000000004 37.88422818791946 0.24158916758946905
59.096000000000004 37.88523489932886 0.2404531139129531
59.096000000000004 37.88624161073825 0.23099718359361804
59.096000000000004 37.88724832214765 0.22977738686511665
59.096000000000004 37.88825503355705 0.22856642685087789
59.096000000000004 37.88926174496645 0.22736360249965915
59.096000000000004 37.89026845637584 0.22616823426874041
59.096000000000004 37.89127516778524 0.2249796676797234
59.096000000000004 37.892281879194634 0.22379727650710673
59.096000000000004 37.89328859060403 0.22262046546942732
59.096000000000004 37.894295302013425 0.22144867219820638
59.096000000000004 37.89530201342282 0.2202813681156324
59.096000000000004 37.89630872483222 0.21911805762491068
59.096000000000004 37.89731543624161 0.2214890406197712
59.096000000000004 37.89832214765101 0.22039013566655954
59.096000000000004 37.899328859060404 0.21929475554147732
59.096000000000004 37.9003355704698 0.218202467388832
59.096000000000004 37.901342281879195 0.2171127807295148
59.096000000000004 37.90234899328859 0.21602507801200566
59.096000000000004 37.90335570469799 0.2149385008096403
59.096000000000004 37.90436241610738 0.21385176463730032
59.096000000000004 37.90536912751678 0.2127628591308018
59.096000000000004 37.906375838926174 0.2116685649002732
59.096000000000004 37.90738255033557 0.21056367943932927
59.096000000000004 37.908389261744965 0.20943978732586782
59.096000000000004 37.90939597315436 0.20828333260567639
59.096000000000004 37.910402684563756 0.20707266441733535
59.096000000000004 37.91140939597315 0.2057736805574532
59.096000000000004 37.91241610738255 0.19269181984484535
59.096000000000004 37.91342281879195 0.1908290724238215
59.096000000000004 37.914429530201346 0.18863474773320493
59.096000000000004 37.91543624161074 0.17997388417125007
59.096000000000004 37.91644295302014 0.1766225033205474
59.096000000000004 37.91744966442953 0.1725592312097196
59.096000000000004 37.91845637583893 0.16782573526962224
59.096000000000004 37.919463087248324 0.16266618239666214
59.096000000000004 37.92046979865772 0.1574896497059034
59.096000000000004 37.921476510067116 0.15271497555574703
59.096000000000004 37.92248322147651 0.14860794235467403
59.096000000000004 37.92348993288591 0.14522971630745052
59.096000000000004 37.9244966442953 0.1424950759766555
59.096000000000004 37.9255033557047 0.14026036231085898
59.096000000000004 37.926510067114094 0.13838445370031224
59.096000000000004 37.92751677852349 0.1367544129154112
59.096000000000004 37.928523489932886 0.13528906075807023
59.096000000000004 37.92953020134228 0.13393356389155986
59.096000000000004 37.93053691275168 0.13265225543088655
59.096000000000004 37.93154362416107 0.13142247638891708
59.096000000000004 37.93255033557047 0.13023004631031881
59.096000000000004 37.933557046979864 0.12906617130233836
59.096000000000004 37.93456375838926 0.12792541602289093
59.096000000000004 37.935570469798655 0.12680440201797627
59.096000000000004 37.93657718120805 0.12570098075415487
59.096000000000004 37.937583892617454 0.12461370888646452
59.096000000000004 37.93859060402685 0.12354151256109658
59.096000000000004 37.939597315436245 0.12248346817361491
59.096000000000004 37.94060402684564 0.12143865364248344
59.096000000000004 37.94161073825504 0.12040604132059948
59.096000000000004 37.94261744966443 0.11938441447456606
59.096000000000004 37.94362416107383 0.11837229609913244
59.096000000000004 37.94463087248322 0.11736788320997454
59.096000000000004 37.94563758389262 0.11636898264386696
59.096000000000004 37.946644295302015 0.11537294641336915
59.096000000000004 37.94765100671141 0.11437660620806732
59.096000000000004 37.948657718120806 0.11337620795217533
59.096000000000004 37.9496644295302 0.1123673485632992
59.096000000000004 37.9506711409396 0.11134491827151702
59.096000000000004 37.95167785234899 0.1002143601956394
59.096000000000004 37.95268456375839 0.09925520542917862
59.096000000000004 37.953691275167785 0.09826181787045107
59.096000000000004 37.95469798657718 0.0972259807473686
59.096000000000004 37.955704697986576 0.09613874127735338
59.096000000000004 37.95671140939597 0.09499047612997107
59.096000000000004 37.95771812080537 0.09377094322781569
59.096000000000004 37.95872483221476 0.09246922423480836
59.096000000000004 37.95973154362416 0.09107331220513568
59.096000000000004 37.960738255033554 0.0895687345587726
59.096000000000004 37.96174496644295 0.08793471582875154
59.096000000000004 37.96275167785235 0.08613425119719909
59.096000000000004 37.96375838926175 0.08408949001131924
59.096000000000004 37.964765100671144 0.08162334411589356
59.096000000000004 37.96577181208054 0.07833286512005913
59.096000000000004 37.966778523489936 0.07337635316900995
59.096000000000004 37.96778523489933 0.06539437922133907
59.096000000000004 37.96879194630873 0.0499223495443622
59.096000000000004 37.96979865771812 0.035861530602348146
59.096000000000004 37.97080536912752 0.024918024400323514
59.096000000000004 37.971812080536914 0.015281047027298836
59.096000000000004 37.97281879194631 0.007837586606348215
59.096000000000004 37.973825503355705 0.001821719863161722
59.096000000000004 37.9748322147651 -0.009142733950755141
59.096000000000004 37.9758389261745 -0.013262322463268514
59.096000000000004 37.97684563758389 -0.037891178302623896
59.096000000000004 37.97785234899329 -0.04110899818661033
59.096000000000004 37.978859060402684 -0.050429288972086456
59.096000000000004 37.97986577181208 -0.05333325665245382
59.096000000000004 37.980872483221475 -0.05616076300650116
59.096000000000004 37.98187919463087 -0.07806281704981409
59.096000000000004 37.982885906040266 -0.08062907312760235
59.096000000000004 37.98389261744966 -0.08313226275706634
59.096000000000004 37.98489932885906 -0.08556952460818669
59.096000000000004 37.98590604026845 -0.08793782422430005
59.096000000000004 37.98691275167785 -0.09023452026847786
59.096000000000004 37.98791946308725 -0.09245758906584128
59.096000000000004 37.98892617449665 -0.09460568129760515
59.096000000000004 37.98993288590604 -0.09667809881820032
59.096000000000004 37.99093959731544 -0.09867473598177057
59.096000000000004 37.991946308724835 -0.10059600779150725
59.096000000000004 37.99295302013423 -0.10244277596636767
59.096000000000004 37.993959731543626 -0.10421627828162554
59.096000000000004 37.99496644295302 -0.11224800708897867
59.096000000000004 37.99597315436242 -0.11381252366417165
59.096000000000004 37.99697986577181 -0.11531019236536681
59.096000000000004 37.99798657718121 -0.1167430566038207
59.096000000000004 37.998993288590604 -0.11811329381138037
59.097 37.85 0.262027528183084
59.097 37.8510067114094 0.2603022438577542
59.097 37.85201342281879 0.25875385288860087
59.097 37.85302013422819 0.2574019935529784
59.097 37.854026845637584 0.25626782006225685
59.097 37.85503355704698 0.25536717386716795
59.097 37.856040268456375 0.25470196540877427
59.097 37.85704697986577 0.25425163375365073
59.097 37.85805369127517 0.2539682148678292
59.097 37.85906040268456 0.2537788259408664
59.097 37.86006711409396 0.25359688779404155
59.097 37.861073825503354 0.2533388542959726
59.097 37.86208053691275 0.2529398398251533
59.097 37.86308724832215 0.2523622683929077
59.097 37.86409395973155 0.2604450534848857
59.097 37.865100671140944 0.2595678577152671
59.097 37.86610738255034 0.2585364320870531
59.097 37.867114093959735 0.25737932514877365
59.097 37.86812080536913 0.25612498836956055
59.097 37.869127516778526 0.2547988653521146
59.097 37.87013422818792 0.25342221536870985
59.097 37.87114093959732 0.25201200185281125
59.097 37.87214765100671 0.25058132084139356
59.097 37.87315436241611 0.24914003205952107
59.097 37.874161073825505 0.2613068476638618
59.097 37.8751677852349 0.2599508277274875
59.097 37.876174496644296 0.2586008368750732
59.097 37.87718120805369 0.2572594592511819
59.097 37.87818791946309 0.2559284236707354
59.097 37.87919463087248 0.25460882651205796
59.097 37.88020134228188 0.25330129874312535
59.097 37.881208053691275 0.2520061302027636
59.097 37.88221476510067 0.2507233617500528
59.097 37.883221476510066 0.24945285355201516
59.097 37.88422818791946 0.24819433580152125
59.097 37.88523489932886 0.2386127413733075
59.097 37.88624161073825 0.23727050118697895
59.097 37.88724832214765 0.23593775802413927
59.097 37.88825503355705 0.23461400190971132
59.097 37.88926174496645 0.23329870977521033
59.097 37.89026845637584 0.23199135579215222
59.097 37.89127516778524 0.23069141969684243
59.097 37.892281879194634 0.22939839353686603
59.097 37.89328859060403 0.22811178707744675
59.097 37.894295302013425 0.22683113192502294
59.097 37.89530201342282 0.22895633975626783
59.097 37.89630872483222 0.22774231892481692
59.097 37.89731543624161 0.22653385632031472
59.097 37.89832214765101 0.22533059783276946
59.097 37.899328859060404 0.22413219847375315
59.097 37.9003355704698 0.22293830195916262
59.097 37.901342281879195 0.22174850542784652
59.097 37.90234899328859 0.2205623003079844
59.097 37.90335570469799 0.21937897482145968
59.097 37.90436241610738 0.21819745476428412
59.097 37.90536912751678 0.2170160451024793
59.097 37.906375838926174 0.21583201272290053
59.097 37.90738255033557 0.21464091640475869
59.097 37.908389261744965 0.21343553897287598
59.097 37.90939597315436 0.21220420522157954
59.097 37.910402684563756 0.21092818259714474
59.097 37.91140939597315 0.1980420728444793
59.097 37.91241610738255 0.19636851599955046
59.097 37.91342281879195 0.18853351643225252
59.097 37.914429530201346 0.1863032894715707
59.097 37.91543624161074 0.18364914737959329
59.097 37.91644295302014 0.1804232816908459
59.097 37.91744966442953 0.17650655479980124
59.097 37.91845637583893 0.17188905932947726
59.097 37.919463087248324 0.16675065023534152
59.097 37.92046979865772 0.16146199783984233
59.097 37.921476510067116 0.1564589285597832
59.097 37.92248322147651 0.15206609851243147
59.097 37.92348993288591 0.14840506190824923
59.097 37.9244966442953 0.14542600211380832
59.097 37.9255033557047 0.14299498819172568
59.097 37.926510067114094 0.14096598487558487
59.097 37.92751677852349 0.13921633893342122
59.097 37.928523489932886 0.1376555037176674
59.097 37.92953020134228 0.13622134779808412
59.097 37.93053691275168 0.1348730657726496
59.097 37.93154362416107 0.1335845751920879
59.097 37.93255033557047 0.1323394856196945
59.097 37.933557046979864 0.13112760034671073
59.097 37.93456375838926 0.1299425973505053
59.097 37.935570469798655 0.1287805307805185
59.097 37.93657718120805 0.1276388742006902
59.097 37.937583892617454 0.1265159107024707
59.097 37.93859060402685 0.12541034061844455
59.097 37.939597315436245 0.12432102351059986
59.097 37.94060402684564 0.1232468016077006
59.097 37.94161073825504 0.12218637157475794
59.097 37.94261744966443 0.12113818408819327
59.097 37.94362416107383 0.12010035874633754
59.097 37.94463087248322 0.11907060708460279
59.097 37.94563758389262 0.11804615999416956
59.097 37.946644295302015 0.11702369835533816
59.097 37.94765100671141 0.11599928759927407
59.097 37.948657718120806 0.114968318394013
59.097 37.9496644295302 0.11392545668631103
59.097 37.9506711409396 0.11286460658022626
59.097 37.95167785234899 0.11177888804413315
59.097 37.95268456375839 0.1006321670514373
59.097 37.953691275167785 0.0995819090642265
59.097 37.95469798657718 0.09848048617633558
59.097 37.955704697986576 0.09731732867623498
59.097 37.95671140939597 0.09607976396706722
59.097 37.95771812080537 0.09475055760080317
59.097 37.95872483221476 0.0933015735326549
59.097 37.95973154362416 0.09167799994152641
59.097 37.960738255033554 0.08976052757314365
59.097 37.96174496644295 0.08728063165566619
59.097 37.96275167785235 0.0836610673894546
59.097 37.96375838926175 0.07785809245985652
59.097 37.964765100671144 0.06874279975373065
59.097 37.96577181208054 0.05681431901859686
59.097 37.966778523489936 0.045160816956692405
59.097 37.96778523489933 0.03632869907592469
59.097 37.96879194630873 0.03010093184726237
59.097 37.96979865771812 0.021573775122304115
59.097 37.97080536912752 0.016690112770746612
59.097 37.971812080536914 0.011395419970859272
59.097 37.97281879194631 0.007368783543647228
59.097 37.973825503355705 0.0019572745341995545
59.097 37.9748322147651 -0.0028172047934933997
59.097 37.9758389261745 -0.012838491647531605
59.097 37.97684563758389 -0.016393171185373534
59.097 37.97785234899329 -0.01963863378490993
59.097 37.978859060402684 -0.02270290720919349
59.097 37.97986577181208 -0.05300737801827804
59.097 37.980872483221475 -0.0557525938179545
59.097 37.98187919463087 -0.05843590731144445
59.097 37.982885906040266 -0.06106173827620272
59.097 37.98389261744966 -0.08279820014072391
59.097 37.98489932885906 -0.08516913228843012
59.097 37.98590604026845 -0.08747833598264251
59.097 37.98691275167785 -0.08972333700668532
59.097 37.98791946308725 -0.0919020654905934
59.097 37.98892617449665 -0.09401296010777171
59.097 37.98993288590604 -0.0960549857898613
59.097 37.99093959731544 -0.09802761028867048
59.097 37.991946308724835 -0.09993076230838156
59.097 37.99295302013423 -0.10176478259518741
59.097 37.993959731543626 -0.1097476526066621
59.097 37.99496644295302 -0.1113799305820157
59.097 37.99597315436242 -0.11294704527766816
59.097 37.99697986577181 -0.1144504061731021
59.097 37.99798657718121 -0.11589162331296751
59.097 37.998993288590604 -0.11727246976960519
59.098 37.85 0.26986011060020243
59.098 37.8510067114094 0.267645589183532
59.098 37.85201342281879 0.26553307515282515
59.098 37.85302013422819 0.26350500200132915
59.098 37.854026845637584 0.2615516769604287
59.098 37.85503355704698 0.2596700269170178
59.098 37.856040268456375 0.25786290016359936
59.098 37.85704697986577 0.256138778517268
59.098 37.85805369127517 0.2545117122407069
59.098 37.85906040268456 0.2530012033123943
59.098 37.86006711409396 0.25163162117941346
59.098 37.861073825503354 0.2504305611866004
59.098 37.86208053691275 0.25822609491273324
59.098 37.86308724832215 0.25750617804174364
59.098 37.86409395973155 0.25701202033244697
59.098 37.865100671140944 0.2567293882640622
59.098 37.86610738255034 0.25661645641683484
59.098 37.867114093959735 0.2566050390391052
59.098 37.86812080536913 0.25661053811169326
59.098 37.869127516778526 0.2565481982114051
59.098 37.87013422818792 0.25634942817490414
59.098 37.87114093959732 0.25597191099256955
59.098 37.87214765100671 0.26903514295143177
59.098 37.87315436241611 0.26836619000988576
59.098 37.874161073825505 0.26753358217563483
59.098 37.8751677852349 0.2665652450117842
59.098 37.876174496644296 0.26548973259454545
59.098 37.87718120805369 0.26433296645509746
59.098 37.87818791946309 0.2631168172333601
59.098 37.87919463087248 0.26185885478389187
59.098 37.88020134228188 0.26057271270396015
59.098 37.881208053691275 0.2592687005858247
59.098 37.88221476510067 0.2579544565261015
59.098 37.883221476510066 0.25663554016782614
59.098 37.88422818791946 0.24698197780461517
59.098 37.88523489932886 0.24555738674256214
59.098 37.88624161073825 0.24413555066758538
59.098 37.88724832214765 0.24271772587757742
59.098 37.88825503355705 0.2413047013771064
59.098 37.88926174496645 0.23989692986441438
59.098 37.89026845637584 0.23849462486635734
59.098 37.89127516778524 0.2370978326034857
59.098 37.892281879194634 0.23570648509998401
59.098 37.89328859060403 0.23759305332583422
59.098 37.894295302013425 0.23626639860279766
59.098 37.89530201342282 0.23494548790328137
59.098 37.89630872483222 0.2336301179349478
59.098 37.89731543624161 0.2323200849150662
59.098 37.89832214765101 0.23101518745620692
59.098 37.899328859060404 0.22971522249499504
59.098 37.9003355704698 0.22841997182356702
59.098 37.901342281879195 0.22712917480017417
59.098 37.90234899328859 0.22584247974811195
59.098 37.90335570469799 0.22455936170515864
59.098 37.90436241610738 0.2232789864701798
59.098 37.90536912751678 0.2219999886222472
59.098 37.906375838926174 0.22072011181739012
59.098 37.90738255033557 0.21943562956981938
59.098 37.908389261744965 0.21814041927755964
59.098 37.90939597315436 0.21682449718644897
59.098 37.910402684563756 0.20404538120744242
59.098 37.91140939597315 0.1964917367655342
59.098 37.91241610738255 0.19469733885726148
59.098 37.91342281879195 0.19273522189216716
59.098 37.914429530201346 0.19051568716075398
59.098 37.91543624161074 0.18791692936448437
59.098 37.91644295302014 0.18479326224888512
59.098 37.91744966442953 0.1810096990843303
59.098 37.91845637583893 0.17651304788717553
59.098 37.919463087248324 0.17141947510978192
59.098 37.92046979865772 0.16604885199361236
59.098 37.921476510067116 0.1608362652156591
59.098 37.92248322147651 0.1561563436929441
59.098 37.92348993288591 0.15219468189276872
59.098 37.9244966442953 0.1489456916270966
59.098 37.9255033557047 0.14629213402920746
59.098 37.926510067114094 0.14408636403024383
59.098 37.92751677852349 0.14219677905961184
59.098 37.928523489932886 0.14052310583422467
59.098 37.92953020134228 0.13899527070737858
59.098 37.93053691275168 0.13756674796421792
59.098 37.93154362416107 0.13620760227712161
59.098 37.93255033557047 0.13489894624125548
59.098 37.933557046979864 0.13362899937507408
59.098 37.93456375838926 0.13239044233613234
59.098 37.935570469798655 0.13117869364541784
59.098 37.93657718120805 0.1299908030641964
59.098 37.937583892617454 0.12882474252689455
59.098 37.93859060402685 0.1276789475100699
59.098 37.939597315436245 0.12655201346644934
59.098 37.94060402684564 0.12544248682312714
59.098 37.94161073825504 0.12434871281013056
59.098 37.94261744966443 0.12326871706085946
59.098 37.94362416107383 0.12220010738856052
59.098 37.94463087248322 0.12113998829602124
59.098 37.94563758389262 0.12008488477024369
59.098 37.946644295302015 0.11903067428596734
59.098 37.94765100671141 0.11797252656182644
59.098 37.948657718120806 0.11690484828652702
59.098 37.9496644295302 0.1158212213334998
59.098 37.9506711409396 0.11471429924966053
59.098 37.95167785234899 0.11357556667939259
59.098 37.95268456375839 0.11239471748762381
59.098 37.953691275167785 0.1111580418389351
59.098 37.95469798657718 0.09998807485559971
59.098 37.955704697986576 0.09866682650066554
59.098 37.95671140939597 0.09714728744934731
59.098 37.95771812080537 0.0952476824413851
59.098 37.95872483221476 0.09256542651520436
59.098 37.95973154362416 0.08829376299789811
59.098 37.960738255033554 0.08123238383890379
59.098 37.96174496644295 0.0708273147594837
59.098 37.96275167785235 0.05905062076899312
59.098 37.96375838926175 0.049254082462419954
59.098 37.964765100671144 0.04259697912492562
59.098 37.96577181208054 0.038138502680324086
59.098 37.966778523489936 0.034725636176208136
59.098 37.96778523489933 0.031632581182409976
59.098 37.96879194630873 0.02843747302634838
59.098 37.96979865771812 0.024834173486145625
59.098 37.97080536912752 0.017083431641797958
59.098 37.971812080536914 0.012082262340949515
59.098 37.97281879194631 0.0066181455896062585
59.098 37.973825503355705 0.002860889277986417
59.098 37.9748322147651 -0.001907054989464918
59.098 37.9758389261745 -0.005992491329390434
59.098 37.97684563758389 -0.015391184862242252
59.098 37.97785234899329 -0.018593785837590193
59.098 37.978859060402684 -0.021608235744311227
59.098 37.97986577181208 -0.024510170042355714
59.098 37.980872483221475 -0.027337133106378697
59.098 37.98187919463087 -0.05755897658431641
59.098 37.982885906040266 -0.060138356723131794
59.098 37.98389261744966 -0.06266535951949088
59.098 37.98489932885906 -0.06513833691685461
59.098 37.98590604026845 -0.08673757640005313
59.098 37.98691275167785 -0.08895482053982402
59.098 37.98791946308725 -0.09111150334707589
59.098 37.98892617449665 -0.09320578762453068
59.098 37.98993288590604 -0.09523629250420183
59.098 37.99093959731544 -0.09720209310016413
59.098 37.991946308724835 -0.09910269767573197
59.098 37.99295302013423 -0.10093801426720084
59.098 37.993959731543626 -0.10874953104127992
59.098 37.99496644295302 -0.11039179018530434
59.098 37.99597315436242 -0.11197129978998456
59.098 37.99697986577181 -0.11348911449873529
59.098 37.99798657718121 -0.11494651134973174
59.098 37.998993288590604 -0.11634495492784985
59.099000000000004 37.85 0.2784892858104084
59.099000000000004 37.8510067114094 0.2759501958591101
59.099000000000004 37.85201342281879 0.2735949924593757
59.099000000000004 37.85302013422819 0.2713757708661487
59.099000000000004 37.854026845637584 0.26925786857843104
59.099000000000004 37.85503355704698 0.2672165770925491
59.099000000000004 37.856040268456375 0.26523460504570034
59.099000000000004 37.85704697986577 0.2633001982628491
59.099000000000004 37.85805369127517 0.26140580035544375
59.099000000000004 37.85906040268456 0.2595471475921654
59.099000000000004 37.86006711409396 0.26647073822107614
59.099000000000004 37.861073825503354 0.26474948380164626
59.099000000000004 37.86208053691275 0.2630672614200285
59.099000000000004 37.86308724832215 0.26143010314283094
59.099000000000004 37.86409395973155 0.2598470449918593
59.099000000000004 37.865100671140944 0.2583306253908169
59.099000000000004 37.86610738255034 0.256897410997819
59.099000000000004 37.867114093959735 0.25556835497703745
59.099000000000004 37.86812080536913 0.25436864664666836
59.099000000000004 37.869127516778526 0.2668902050807643
59.099000000000004 37.87013422818792 0.26612226499321323
59.099000000000004 37.87114093959732 0.2655641730558011
59.099000000000004 37.87214765100671 0.26522691668178894
59.099000000000004 37.87315436241611 0.2651006815456483
59.099000000000004 37.874161073825505 0.26514860693688014
59.099000000000004 37.8751677852349 0.26530664806486814
59.099000000000004 37.876174496644296 0.26549205050172275
59.099000000000004 37.87718120805369 0.2656188600191323
59.099000000000004 37.87818791946309 0.2656147089241982
59.099000000000004 37.87919463087248 0.2654323030794618
59.099000000000004 37.88020134228188 0.2650523178913011
59.099000000000004 37.881208053691275 0.26447894562113344
59.099000000000004 37.88221476510067 0.2637318774097878
59.099000000000004 37.883221476510066 0.25450919480454476
59.099000000000004 37.88422818791946 0.2533902072687448
59.099000000000004 37.88523489932886 0.2521785375987845
59.099000000000004 37.88624161073825 0.25089676134204236
59.099000000000004 37.88724832214765 0.24956317161089125
59.099000000000004 37.88825503355705 0.24819206590320933
59.099000000000004 37.88926174496645 0.24679433218771105
59.099000000000004 37.89026845637584 0.2453781027546068
59.099000000000004 37.89127516778524 0.2439493607704591
59.099000000000004 37.892281879194634 0.24571260394514316
59.099000000000004 37.89328859060403 0.24432400164348322
59.099000000000004 37.894295302013425 0.24293343520961125
59.099000000000004 37.89530201342282 0.24154250050402362
59.099000000000004 37.89630872483222 0.24015232290472857
59.099000000000004 37.89731543624161 0.23876369141964943
59.099000000000004 37.89832214765101 0.23737715456551275
59.099000000000004 37.899328859060404 0.23599308542479158
59.099000000000004 37.9003355704698 0.2346117202125388
59.099000000000004 37.901342281879195 0.23323317139694877
59.099000000000004 37.90234899328859 0.23185741253060196
59.099000000000004 37.90335570469799 0.23048422681409997
59.099000000000004 37.90436241610738 0.22911310402753404
59.099000000000004 37.90536912751678 0.22774305929913566
59.099000000000004 37.906375838926174 0.22637232994012424
59.099000000000004 37.90738255033557 0.22499787994609677
59.099000000000004 37.908389261744965 0.22361460135003938
59.099000000000004 37.90939597315436 0.2049984520010845
59.099000000000004 37.910402684563756 0.2032943677447628
59.099000000000004 37.91140939597315 0.20153359751562647
59.099000000000004 37.91241610738255 0.19968105984890944
59.099000000000004 37.91342281879195 0.1976826936376578
59.099000000000004 37.914429530201346 0.19545829022448707
59.099000000000004 37.91543624161074 0.19289597258456798
59.099000000000004 37.91644295302014 0.18985539017024347
59.099000000000004 37.91744966442953 0.1861925759546922
59.099000000000004 37.91845637583893 0.18181990718687158
59.099000000000004 37.919463087248324 0.17679379905960244
59.099000000000004 37.92046979865772 0.17137481247037106
59.099000000000004 37.921476510067116 0.16597965098251247
59.099000000000004 37.92248322147651 0.1610202138499493
59.099000000000004 37.92348993288591 0.15674643289302667
59.099000000000004 37.9244966442953 0.1532051619773139
59.099000000000004 37.9255033557047 0.15030382879649612
59.099000000000004 37.926510067114094 0.1478975740663876
59.099000000000004 37.92751677852349 0.14584724384171094
59.099000000000004 37.928523489932886 0.1440427263007819
59.099000000000004 37.92953020134228 0.13787933677243247
59.099000000000004 37.93053691275168 0.13635670149171256
59.099000000000004 37.93154362416107 0.13491468253690225
59.099000000000004 37.93255033557047 0.1335315861032726
59.099000000000004 37.933557046979864 0.13219385298316128
59.099000000000004 37.93456375838926 0.13089304800473917
59.099000000000004 37.935570469798655 0.1296238807700105
59.099000000000004 37.93657718120805 0.12838292623082656
59.099000000000004 37.937583892617454 0.12716780038011516
59.099000000000004 37.93859060402685 0.12597662434666745
59.099000000000004 37.939597315436245 0.12480766814849564
59.099000000000004 37.94060402684564 0.12365910507844494
59.099000000000004 37.94161073825504 0.12252883381398601
59.099000000000004 37.94261744966443 0.12141434202798983
59.099000000000004 37.94362416107383 0.1203125953599508
59.099000000000004 37.94463087248322 0.11921994033509101
59.099000000000004 37.94563758389262 0.11813200831328198
59.099000000000004 37.946644295302015 0.11704359508388953
59.099000000000004 37.94765100671141 0.11594845407034127
59.099000000000004 37.948657718120806 0.11483884681526021
59.099000000000004 37.9496644295302 0.11370445999197294
59.099000000000004 37.9506711409396 0.11252972299475339
59.099000000000004 37.95167785234899 0.11128716771321867
59.099000000000004 37.95268456375839 0.10992119822952928
59.099000000000004 37.953691275167785 0.10830951391001045
59.099000000000004 37.95469798657718 0.10617728658203998
59.099000000000004 37.955704697986576 0.09340261756507653
59.099000000000004 37.95671140939597 0.09219085946381156
59.099000000000004 37.95771812080537 0.08363013756929949
59.099000000000004 37.95872483221476 0.07238883062676808
59.099000000000004 37.95973154362416 0.06159247178715744
59.099000000000004 37.960738255033554 0.053760999431123016
59.099000000000004 37.96174496644295 0.048739292073844115
59.099000000000004 37.96275167785235 0.045327954176335006
59.099000000000004 37.96375838926175 0.04262390338879371
59.099000000000004 37.964765100671144 0.04015859894806635
59.099000000000004 37.96577181208054 0.03771329605520477
59.099000000000004 37.966778523489936 0.03517433520024422
59.099000000000004 37.96778523489933 0.03245123193700438
59.099000000000004 37.96879194630873 0.029426741524565148
59.099000000000004 37.96979865771812 0.02592828924887798
59.099000000000004 37.97080536912752 0.021752982623985645
59.099000000000004 37.971812080536914 0.013367565041506116
59.099000000000004 37.97281879194631 0.007948432147065615
59.099000000000004 37.973825503355705 0.002625199225204544
59.099000000000004 37.9748322147651 -0.002108785318416531
59.099000000000004 37.9758389261745 -0.004626705489756661
59.099000000000004 37.97684563758389 -0.008187709299780563
59.099000000000004 37.97785234899329 -0.017149528350757615
59.099000000000004 37.978859060402684 -0.020137576087549014
59.099000000000004 37.97986577181208 -0.023011372023950052
59.099000000000004 37.980872483221475 -0.025811295121189723
59.099000000000004 37.98187919463087 -0.028555469742453266
59.099000000000004 37.982885906040266 -0.031250565454096324
59.099000000000004 37.98389261744966 -0.06143849126461348
59.099000000000004 37.98489932885906 -0.06389650063166362
59.099000000000004 37.98590604026845 -0.06630262908106219
59.099000000000004 37.98691275167785 -0.06865430133309405
59.099000000000004 37.98791946308725 -0.09013231040310594
59.099000000000004 37.98892617449665 -0.09222513388506703
59.099000000000004 37.98993288590604 -0.09425794352270689
59.099000000000004 37.99093959731544 -0.09622947968276264
59.099000000000004 37.991946308724835 -0.09813890288112562
59.099000000000004 37.99295302013423 -0.09998577053038467
59.099000000000004 37.993959731543626 -0.10763979021788954
59.099000000000004 37.99496644295302 -0.10930005393606995
59.099000000000004 37.99597315436242 -0.11089918038267471
59.099000000000004 37.99697986577181 -0.11243794587786657
59.099000000000004 37.99798657718121 -0.1139173675370326
59.099000000000004 37.998993288590604 -0.11533867012238824
59.1 37.85 0.28780751120488224
59.1 37.8510067114094 0.28458325959547076
59.1 37.85201342281879 0.28173725819891426
59.1 37.85302013422819 0.27917201204164405
59.1 37.854026845637584 0.2768136870511276
59.1 37.85503355704698 0.27460748441260546
59.1 37.856040268456375 0.272513315238985
59.1 37.85704697986577 0.2705021628740743
59.1 37.85805369127517 0.27724492715285665
59.1 37.85906040268456 0.275411511615638
59.1 37.86006711409396 0.27361547546751575
59.1 37.861073825503354 0.27184951984291506
59.1 37.86208053691275 0.270108661441598
59.1 37.86308724832215 0.2683896394298254
59.1 37.86409395973155 0.26669051879022365
59.1 37.865100671140944 0.26501044664265694
59.1 37.86610738255034 0.2633495329339958
59.1 37.867114093959735 0.2752844920618882
59.1 37.86812080536913 0.27375511135772845
59.1 37.869127516778526 0.2722518524903017
59.1 37.87013422818792 0.2707795953311215
59.1 37.87114093959732 0.2693451690214839
59.1 37.87214765100671 0.267957878770404
59.1 37.87315436241611 0.2666301192869846
59.1 37.874161073825505 0.26537800009550183
59.1 37.8751677852349 0.2642218190978819
59.1 37.876174496644296 0.2631860811704749
59.1 37.87718120805369 0.2622985769542873
59.1 37.87818791946309 0.2615878764308759
59.1 37.87919463087248 0.26107861637445795
59.1 37.88020134228188 0.26078444499847075
59.1 37.881208053691275 0.26069967452741455
59.1 37.88221476510067 0.25247221917218704
59.1 37.883221476510066 0.252574906494866
59.1 37.88422818791946 0.2527138061275092
59.1 37.88523489932886 0.2528022823857828
59.1 37.88624161073825 0.2527645023899705
59.1 37.88724832214765 0.2525482170031507
59.1 37.88825503355705 0.2521292170108051
59.1 37.88926174496645 0.25150796863872166
59.1 37.89026845637584 0.25070196066295564
59.1 37.89127516778524 0.25286535386367204
59.1 37.892281879194634 0.2518235884407504
59.1 37.89328859060403 0.2506800378535196
59.1 37.894295302013425 0.2494581600756125
59.1 37.89530201342282 0.24817717610296688
59.1 37.89630872483222 0.24685226220231954
59.1 37.89731543624161 0.2454950983112299
59.1 37.89832214765101 0.24411451399256706
59.1 37.899328859060404 0.24271709835705757
59.1 37.9003355704698 0.2413077167555191
59.1 37.901342281879195 0.2398899175833194
59.1 37.90234899328859 0.23846623019726532
59.1 37.90335570469799 0.23703835892984634
59.1 37.90436241610738 0.23560727378289958
59.1 37.90536912751678 0.234173187773163
59.1 37.906375838926174 0.2327353939172915
59.1 37.90738255033557 0.2312919094107689
59.1 37.908389261744965 0.21270460334789767
59.1 37.90939597315436 0.21096304942667912
59.1 37.910402684563756 0.2091899861195611
59.1 37.91140939597315 0.20736643768234256
59.1 37.91241610738255 0.20546207654327014
59.1 37.91342281879195 0.20342961714233543
59.1 37.914429530201346 0.20119782884710893
59.1 37.91543624161074 0.19866501624866337
59.1 37.91644295302014 0.19569843405148662
59.1 37.91744966442953 0.1921508451566885
59.1 37.91845637583893 0.18790891063716558
59.1 37.919463087248324 0.18297570531994084
59.1 37.92046979865772 0.17289097192815897
59.1 37.921476510067116 0.16734708602313295
59.1 37.92248322147651 0.16212499804042527
59.1 37.92348993288591 0.1575353255819154
59.1 37.9244966442953 0.15368412366007966
59.1 37.9255033557047 0.15051215722102523
59.1 37.926510067114094 0.14788290534750728
59.1 37.92751677852349 0.14565182847644084
59.1 37.928523489932886 0.14369919765089792
59.1 37.92953020134228 0.14193736396532908
59.1 37.93053691275168 0.14030654734549033
59.1 37.93154362416107 0.13876768563366818
59.1 37.93255033557047 0.1372959240813704
59.1 37.933557046979864 0.13587569509596187
59.1 37.93456375838926 0.13449730369012924
59.1 37.935570469798655 0.13315465809573132
59.1 37.93657718120805 0.1318437920271361
59.1 37.937583892617454 0.13056190732225326
59.1 37.93859060402685 0.12930674867431108
59.1 37.939597315436245 0.12807618603906526
59.1 37.94060402684564 0.12686792384659248
59.1 37.94161073825504 0.12567928224790256
59.1 37.94261744966443 0.12450700524156494
59.1 37.94362416107383 0.12334703833674372
59.1 37.94463087248322 0.12219416510720943
59.1 37.94563758389262 0.12104124579221953
59.1 37.946644295302015 0.11987743270979587
59.1 37.94765100671141 0.1186838336813247
59.1 37.948657718120806 0.11742292926871115
59.1 37.9496644295302 0.11601310614344429
59.1 37.9506711409396 0.11426980786502908
59.1 37.95167785234899 0.1117833199568439
59.1 37.95268456375839 0.10773549667624123
59.1 37.953691275167785 0.10092264447493204
59.1 37.95469798657718 0.09082655668037655
59.1 37.955704697986576 0.0794752763401774
59.1 37.95671140939597 0.06073857453746776
59.1 37.95771812080537 0.05474395611641495
59.1 37.95872483221476 0.050970691485634705
59.1 37.95973154362416 0.04830012598399615
59.1 37.960738255033554 0.046071711660924004
59.1 37.96174496644295 0.043974128130879045
59.1 37.96275167785235 0.041872814780215886
59.1 37.96375838926175 0.03971050259429876
59.1 37.964765100671144 0.037459908091503506
59.1 37.96577181208054 0.039163024935693666
59.1 37.966778523489936 0.036646506048300796
59.1 37.96778523489933 0.03393753423719902
59.1 37.96879194630873 0.030936247024848977
59.1 37.96979865771812 0.027479306980330243
59.1 37.97080536912752 0.02336421315711447
59.1 37.971812080536914 0.01848959991568859
59.1 37.97281879194631 0.009677950744549968
59.1 37.973825503355705 0.004346063176664791
59.1 37.9748322147651 -0.0004174462248577593
59.1 37.9758389261745 -0.00448541470500588
59.1 37.97684563758389 -0.006478956168324668
59.1 37.97785234899329 -0.015389376570813948
59.1 37.978859060402684 -0.01837051768486267
59.1 37.97986577181208 -0.021235338790905542
59.1 37.980872483221475 -0.02402686738835466
59.1 37.98187919463087 -0.02676472955082329
59.1 37.982885906040266 -0.02945639844130067
59.1 37.98389261744966 -0.03210338795692545
59.1 37.98489932885906 -0.0388215353248364
59.1 37.98590604026845 -0.06487075534150145
59.1 37.98691275167785 -0.06722950199951114
59.1 37.98791946308725 -0.06953459877670984
59.1 37.98892617449665 -0.07178376334569131
59.1 37.98993288590604 -0.0931453477139941
59.1 37.99093959731544 -0.09513170167447613
59.1 37.991946308724835 -0.09705818097305666
59.1 37.99295302013423 -0.1046870573371054
59.1 37.993959731543626 -0.10643156522647322
59.1 37.99496644295302 -0.10811573610254506
59.1 37.99597315436242 -0.10973983361371852
59.1 37.99697986577181 -0.11130441000520434
59.1 37.99798657718121 -0.1128102735303515
59.1 37.998993288590604 -0.11425845653030937
59.101 37.85 0.2993043509999029
59.101 37.8510067114094 0.29466299148258124
59.101 37.85201342281879 0.29074924575976585
59.101 37.85302013422819 0.28739842179792424
59.101 37.854026845637584 0.2844735192203323
59.101 37.85503355704698 0.2818660780217103
59.101 37.856040268456375 0.2794930544859917
59.101 37.85704697986577 0.2859927587512622
59.101 37.85805369127517 0.2839873155692653
59.101 37.85906040268456 0.28207562050001705
59.101 37.86006711409396 0.2802336320945715
59.101 37.861073825503354 0.27844408801933646
59.101 37.86208053691275 0.27669464326763543
59.101 37.86308724832215 0.2749764948333093
59.101 37.86409395973155 0.273283379510416
59.101 37.865100671140944 0.28519379901427355
59.101 37.86610738255034 0.28362842225348167
59.101 37.867114093959735 0.2820787681146364
59.101 37.86812080536913 0.280543327930965
59.101 37.869127516778526 0.2790211114014523
59.101 37.87013422818792 0.27751154181956394
59.101 37.87114093959732 0.2760144023184304
59.101 37.87214765100671 0.2745298260204734
59.101 37.87315436241611 0.2730583276386072
59.101 37.874161073825505 0.27160087821037887
59.101 37.8751677852349 0.27015902850504586
59.101 37.876174496644296 0.2687350902859564
59.101 37.87718120805369 0.2673323877775971
59.101 37.87818791946309 0.2659555935422756
59.101 37.87919463087248 0.2646111616491359
59.101 37.88020134228188 0.2633078628977003
59.101 37.881208053691275 0.25375017644275905
59.101 37.88221476510067 0.25245959752697966
59.101 37.883221476510066 0.2512552240813285
59.101 37.88422818791946 0.2501606781788168
59.101 37.88523489932886 0.24920312529803723
59.101 37.88624161073825 0.24841111232625523
59.101 37.88724832214765 0.24781029811204353
59.101 37.88825503355705 0.2474168066805339
59.101 37.88926174496645 0.2502341518217773
59.101 37.89026845637584 0.2502757389086087
59.101 37.89127516778524 0.25044221925398186
59.101 37.892281879194634 0.25065623109531454
59.101 37.89328859060403 0.2508311760231915
59.101 37.894295302013425 0.2508882752781898
59.101 37.89530201342282 0.25077052945825445
59.101 37.89630872483222 0.2504487867107613
59.101 37.89731543624161 0.2499196123571376
59.101 37.89832214765101 0.24919813482483055
59.101 37.899328859060404 0.24830974890754925
59.101 37.9003355704698 0.24728326489486677
59.101 37.901342281879195 0.24614640970628654
59.101 37.90234899328859 0.24492346891596523
59.101 37.90335570469799 0.24363442756912995
59.101 37.90436241610738 0.24229496495996755
59.101 37.90536912751678 0.24091681732841547
59.101 37.906375838926174 0.232877929950032
59.101 37.90738255033557 0.22102612715707912
59.101 37.908389261744965 0.21929648493431392
59.101 37.90939597315436 0.21753711979929607
59.101 37.910402684563756 0.2157407084358423
59.101 37.91140939597315 0.21389281785201997
59.101 37.91241610738255 0.2119685473483724
59.101 37.91342281879195 0.20992757012558388
59.101 37.914429530201346 0.20293235531564918
59.101 37.91543624161074 0.20043495151259472
59.101 37.91644295302014 0.19754433899375826
59.101 37.91744966442953 0.19411470183332916
59.101 37.91845637583893 0.19001514006156084
59.101 37.919463087248324 0.18520342514498608
59.101 37.92046979865772 0.17981246660645028
59.101 37.921476510067116 0.1741767549367928
59.101 37.92248322147651 0.16873385777328528
59.101 37.92348993288591 0.16384725188040758
59.101 37.9244966442953 0.15968658202178337
59.101 37.9255033557047 0.1562347924843225
59.101 37.926510067114094 0.15337035158579398
59.101 37.92751677852349 0.15094635528473355
59.101 37.928523489932886 0.1488340697913612
59.101 37.92953020134228 0.14693638729073485
59.101 37.93053691275168 0.1451859387418229
59.101 37.93154362416107 0.14353827481702447
59.101 37.93255033557047 0.14196496619626917
59.101 37.933557046979864 0.14044816491202755
59.101 37.93456375838926 0.13897674739212573
59.101 37.935570469798655 0.13754371782500516
59.101 37.93657718120805 0.1361445012125933
59.101 37.937583892617454 0.13477582438551428
59.101 37.93859060402685 0.13343496365440197
59.101 37.939597315436245 0.1321191928187197
59.101 37.94060402684564 0.13082527663296292
59.101 37.94161073825504 0.12954879167416897
59.101 37.94261744966443 0.12828283920686812
59.101 37.94362416107383 0.12701514219463644
59.101 37.94463087248322 0.12572110818509075
59.101 37.94563758389262 0.12434711163978254
59.101 37.946644295302015 0.12277102059428996
59.101 37.94765100671141 0.12071480475437402
59.101 37.948657718120806 0.11758324725112654
59.101 37.9496644295302 0.11231822131278206
59.101 37.9506711409396 0.10383387906687194
59.101 37.95167785234899 0.09276765637460677
59.101 37.95268456375839 0.08227301813351211
59.101 37.953691275167785 0.07480969919662914
59.101 37.95469798657718 0.07017227989434568
59.101 37.955704697986576 0.06715670042970073
59.101 37.95671140939597 0.06486892029095849
59.101 37.95771812080537 0.05346522548592867
59.101 37.95872483221476 0.05161609168280705
59.101 37.95973154362416 0.049741024961832705
59.101 37.960738255033554 0.04780360444890446
59.101 37.96174496644295 0.04578880673846003
59.101 37.96275167785235 0.043690196844976964
59.101 37.96375838926175 0.04150388783783572
59.101 37.964765100671144 0.03922455390823189
59.101 37.96577181208054 0.03684086068110362
59.101 37.966778523489936 0.0343280696131616
59.101 37.96778523489933 0.03163535921528476
59.101 37.96879194630873 0.028666626705598372
59.101 37.96979865771812 0.025262419720895296
59.101 37.97080536912752 0.021219235135479533
59.101 37.971812080536914 0.016419721387091024
59.101 37.97281879194631 0.015113493827737912
59.101 37.973825503355705 0.006374228446035218
59.101 37.9748322147651 0.0015678771052159146
59.101 37.9758389261745 -0.0025394451035252036
59.101 37.97684563758389 -0.006087133449891632
59.101 37.97785234899329 -0.007746122364715807
59.101 37.978859060402684 -0.01636955697392803
59.101 37.97986577181208 -0.019239922766458403
59.101 37.980872483221475 -0.022036947419059677
59.101 37.98187919463087 -0.024781701603527065
59.101 37.982885906040266 -0.027482411249745653
59.101 37.98389261744966 -0.030140946673038183
59.101 37.98489932885906 -0.0327562130802109
59.101 37.98590604026845 -0.039427747340996494
59.101 37.98691275167785 -0.04194561236655302
59.101 37.98791946308725 -0.04441214707321242
59.101 37.98892617449665 -0.07025947509635073
59.101 37.98993288590604 -0.07247395214249991
59.101 37.99093959731544 -0.09392386555829021
59.101 37.991946308724835 -0.09587334294265912
59.101 37.99295302013423 -0.10336165735780334
59.101 37.993959731543626 -0.1051335354776377
59.101 37.99496644295302 -0.10684597665271381
59.101 37.99597315436242 -0.1084990463223983
59.101 37.99697986577181 -0.11009310960114412
59.101 37.99798657718121 -0.11162880023177475
59.101 37.998993288590604 -0.11310698967827777
59.102000000000004 37.85 0.31535981351971293
59.102000000000004 37.8510067114094 0.30827966175588906
59.102000000000004 37.85201342281879 0.30235486429006164
59.102000000000004 37.85302013422819 0.29741727670342544
59.102000000000004 37.854026845637584 0.29328125733063365
59.102000000000004 37.85503355704698 0.2984107827509935
59.102000000000004 37.856040268456375 0.29544976041396603
59.102000000000004 37.85704697986577 0.2928480934253895
59.102000000000004 37.85805369127517 0.29051284956947143
59.102000000000004 37.85906040268456 0.2883738908050253
59.102000000000004 37.86006711409396 0.2863792518359225
59.102000000000004 37.861073825503354 0.28449092398537773
59.102000000000004 37.86208053691275 0.2826813537739539
59.102000000000004 37.86308724832215 0.2945164755595239
59.102000000000004 37.86409395973155 0.29290079858087276
59.102000000000004 37.865100671140944 0.2913200689219173
59.102000000000004 37.86610738255034 0.28976698825831193
59.102000000000004 37.867114093959735 0.288236325455716
59.102000000000004 37.86812080536913 0.28672431804262494
59.102000000000004 37.869127516778526 0.2852282436409718
59.102000000000004 37.87013422818792 0.28374611477488165
59.102000000000004 37.87114093959732 0.2822764629078485
59.102000000000004 37.87214765100671 0.28081818701684214
59.102000000000004 37.87315436241611 0.27937044908479064
59.102000000000004 37.874161073825505 0.27793260414675
59.102000000000004 37.8751677852349 0.2765041564423259
59.102000000000004 37.876174496644296 0.2750847361971021
59.102000000000004 37.87718120805369 0.27367409388939284
59.102000000000004 37.87818791946309 0.2722721108023231
59.102000000000004 37.87919463087248 0.2708788264153113
59.102000000000004 37.88020134228188 0.26120416078741754
59.102000000000004 37.881208053691275 0.25972079443183416
59.102000000000004 37.88221476510067 0.2582464398358771
59.102000000000004 37.883221476510066 0.2567824722751548
59.102000000000004 37.88422818791946 0.2553309619961401
59.102000000000004 37.88523489932886 0.2538949234574973
59.102000000000004 37.88624161073825 0.25247864284197397
59.102000000000004 37.88724832214765 0.25108809822727723
59.102000000000004 37.88825503355705 0.25266588672539586
59.102000000000004 37.88926174496645 0.25140378132277363
59.102000000000004 37.89026845637584 0.2502019013024145
59.102000000000004 37.89127516778524 0.24907879354411344
59.102000000000004 37.892281879194634 0.24805728220269904
59.102000000000004 37.89328859060403 0.24716396577145092
59.102000000000004 37.894295302013425 0.24642738099326866
59.102000000000004 37.89530201342282 0.24587417199318518
59.102000000000004 37.89630872483222 0.24552288187079618
59.102000000000004 37.89731543624161 0.245375894668515
59.102000000000004 37.89832214765101 0.24541163000963914
59.102000000000004 37.899328859060404 0.24558063386906362
59.102000000000004 37.9003355704698 0.24580920298692865
59.102000000000004 37.901342281879195 0.24601142010451796
59.102000000000004 37.90234899328859 0.24610595259659054
59.102000000000004 37.90335570469799 0.24603099355905372
59.102000000000004 37.90436241610738 0.2457518942971089
59.102000000000004 37.90536912751678 0.23861221597133678
59.102000000000004 37.906375838926174 0.22761083483586686
59.102000000000004 37.90738255033557 0.22646804606325285
59.102000000000004 37.908389261744965 0.22516811869755313
59.102000000000004 37.90939597315436 0.21884622518865202
59.102000000000004 37.910402684563756 0.21728433802448965
59.102000000000004 37.91140939597315 0.21561158786412948
59.102000000000004 37.91241610738255 0.21382178115804773
59.102000000000004 37.91342281879195 0.21189170002762325
59.102000000000004 37.914429530201346 0.2097754836317797
59.102000000000004 37.91543624161074 0.20739806243856504
59.102000000000004 37.91644295302014 0.2046505263052423
59.102000000000004 37.91744966442953 0.20139492610228293
59.102000000000004 37.91845637583893 0.19749188291584968
59.102000000000004 37.919463087248324 0.19286388473309046
59.102000000000004 37.92046979865772 0.18758394521423563
59.102000000000004 37.921476510067116 0.1819302360461421
59.102000000000004 37.92248322147651 0.17632769168687962
59.102000000000004 37.92348993288591 0.17118180634476451
59.102000000000004 37.9244966442953 0.16672760363193084
59.102000000000004 37.9255033557047 0.16299882566262977
59.102000000000004 37.926510067114094 0.1598966033838104
59.102000000000004 37.92751677852349 0.15727562060253752
59.102000000000004 37.928523489932886 0.1549991973185251
59.102000000000004 37.92953020134228 0.1529604384452797
59.102000000000004 37.93053691275168 0.1510836586332006
59.102000000000004 37.93154362416107 0.14931833325252766
59.102000000000004 37.93255033557047 0.14763193823730966
59.102000000000004 37.933557046979864 0.14600399077662854
59.102000000000004 37.93456375838926 0.14442169987968703
59.102000000000004 37.935570469798655 0.14287696355300764
59.102000000000004 37.93657718120805 0.14136429574289472
59.102000000000004 37.937583892617454 0.1398792549623728
59.102000000000004 37.93859060402685 0.13841687975781064
59.102000000000004 37.939597315436245 0.13696933392566302
59.102000000000004 37.94060402684564 0.13552109692499464
59.102000000000004 37.94161073825504 0.13403786371636076
59.102000000000004 37.94261744966443 0.13244031027669415
59.102000000000004 37.94362416107383 0.13054391733274087
59.102000000000004 37.94463087248322 0.12793479701398275
59.102000000000004 37.94563758389262 0.12378635833914658
59.102000000000004 37.946644295302015 0.11689549648363386
59.102000000000004 37.94765100671141 0.10678742789152115
59.102000000000004 37.948657718120806 0.09555465106200878
59.102000000000004 37.9496644295302 0.08650690877736408
59.102000000000004 37.9506711409396 0.08067305230566708
59.102000000000004 37.95167785234899 0.07707840970064206
59.102000000000004 37.95268456375839 0.07461030218467601
59.102000000000004 37.953691275167785 0.07261497324140455
59.102000000000004 37.95469798657718 0.0707829822834184
59.102000000000004 37.955704697986576 0.06897884428448721
59.102000000000004 37.95671140939597 0.0671443107690457
59.102000000000004 37.95771812080537 0.06525400179507813
59.102000000000004 37.95872483221476 0.06329650893482026
59.102000000000004 37.95973154362416 0.052068515157758906
59.102000000000004 37.960738255033554 0.05006989911706838
59.102000000000004 37.96174496644295 0.047994170901411656
59.102000000000004 37.96275167785235 0.04584040728799267
59.102000000000004 37.96375838926175 0.04360680126656352
59.102000000000004 37.964765100671144 0.041288762417619085
59.102000000000004 37.96577181208054 0.03887534987394686
59.102000000000004 37.966778523489936 0.03634253994892089
59.102000000000004 37.96778523489933 0.033641177259655465
59.102000000000004 37.96879194630873 0.030678265114407438
59.102000000000004 37.96979865771812 0.027298156074478525
59.102000000000004 37.97080536912752 0.02329703836537468
59.102000000000004 37.971812080536914 0.018543058980282787
59.102000000000004 37.97281879194631 0.013209851631700312
59.102000000000004 37.973825503355705 0.007843438465610295
59.102000000000004 37.9748322147651 -0.00037949115132895894
59.102000000000004 37.9758389261745 -0.004509744454800485
59.102000000000004 37.97684563758389 -0.00806602051121677
59.102000000000004 37.97785234899329 -0.007172842128084306
59.102000000000004 37.978859060402684 -0.010172896559775907
59.102000000000004 37.97986577181208 -0.01706823353917608
59.102000000000004 37.980872483221475 -0.01988070715858825
59.102000000000004 37.98187919463087 -0.022641732283746843
59.102000000000004 37.982885906040266 -0.02536028386761019
59.102000000000004 37.98389261744966 -0.028038586537805513
59.102000000000004 37.98489932885906 -0.030675677962544973
59.102000000000004 37.98590604026845 -0.03326922593285643
59.102000000000004 37.98691275167785 -0.03581644253780519
59.102000000000004 37.98791946308725 -0.04239734551853486
59.102000000000004 37.98892617449665 -0.0448402084243143
59.102000000000004 37.98993288590604 -0.07086913107057517
59.102000000000004 37.99093959731544 -0.07305573707373697
59.102000000000004 37.991946308724835 -0.07834700447949156
59.102000000000004 37.99295302013423 -0.1019485208030097
59.102000000000004 37.993959731543626 -0.1037512621170667
59.102000000000004 37.99496644295302 -0.10549521405123377
59.102000000000004 37.99597315436242 -0.10718026982973097
59.102000000000004 37.99697986577181 -0.10880663161457
59.102000000000004 37.99798657718121 -0.11037478081220145
59.102000000000004 37.998993288590604 -0.11188544809438389
59.103 37.85 0.3378229956528144
59.103 37.8510067114094 0.32782405821929206
59.103 37.85201342281879 0.3190099457302007
59.103 37.85302013422819 0.31146602596420253
59.103 37.854026845637584 0.31377304292128644
59.103 37.85503355704698 0.30856820345952524
59.103 37.856040268456375 0.30424151796400123
59.103 37.85704697986577 0.30061182239760376
59.103 37.85805369127517 0.2975191909703516
59.103 37.85906040268456 0.2948319910368195
59.103 37.86006711409396 0.29244673131674487
59.103 37.861073825503354 0.3038689479038366
59.103 37.86208053691275 0.3019618461681223
59.103 37.86308724832215 0.3001762129671041
59.103 37.86409395973155 0.2984805902846183
59.103 37.865100671140944 0.2968522547105536
59.103 37.86610738255034 0.29527486709298595
59.103 37.867114093959735 0.29373670878967606
59.103 37.86812080536913 0.29222938085417927
59.103 37.869127516778526 0.2907468559529408
59.103 37.87013422818792 0.28928479306924104
59.103 37.87114093959732 0.28784004509871597
59.103 37.87214765100671 0.2864103066291741
59.103 37.87315436241611 0.28499386292502754
59.103 37.874161073825505 0.2835894116666518
59.103 37.8751677852349 0.2821959368747549
59.103 37.876174496644296 0.280812620255151
59.103 37.87718120805369 0.2794387794374397
59.103 37.87818791946309 0.27807382566222855
59.103 37.87919463087248 0.2684476639469984
59.103 37.88020134228188 0.2669903247605375
59.103 37.881208053691275 0.26553908742019255
59.103 37.88221476510067 0.2640935238984444
59.103 37.883221476510066 0.26265324446073685
59.103 37.88422818791946 0.26121790798413264
59.103 37.88523489932886 0.2597872394789456
59.103 37.88624161073825 0.2583610563471993
59.103 37.88724832214765 0.25980339221472143
59.103 37.88825503355705 0.25843475876976896
59.103 37.88926174496645 0.25707185667756455
59.103 37.89026845637584 0.2557154453550618
59.103 37.89127516778524 0.25436677138970276
59.103 37.892281879194634 0.2530277474605737
59.103 37.89328859060403 0.25170119066323715
59.103 37.894295302013425 0.2503911355276333
59.103 37.89530201342282 0.24910323667732887
59.103 37.89630872483222 0.2478452706961721
59.103 37.89731543624161 0.24662773110863578
59.103 37.89832214765101 0.24546447584911552
59.103 37.899328859060404 0.2443733208388317
59.103 37.9003355704698 0.24337636256462658
59.103 37.901342281879195 0.2424996514578403
59.103 37.90234899328859 0.24177165330589814
59.103 37.90335570469799 0.24121982863808375
59.103 37.90436241610738 0.234201584667407
59.103 37.90536912751678 0.22832320053670904
59.103 37.906375838926174 0.21860231767264063
59.103 37.90738255033557 0.2184917220641908
59.103 37.908389261744965 0.21844688715634095
59.103 37.90939597315436 0.21837894468533386
59.103 37.910402684563756 0.21819716067699932
59.103 37.91140939597315 0.21782291404756665
59.103 37.91241610738255 0.21719624353098607
59.103 37.91342281879195 0.21627246551141552
59.103 37.914429530201346 0.21501063889101274
59.103 37.91543624161074 0.21335802669556123
59.103 37.91644295302014 0.21123578060690754
59.103 37.91744966442953 0.20853333697335377
59.103 37.91845637583893 0.20512375553618573
59.103 37.919463087248324 0.2009147887329332
59.103 37.92046979865772 0.19593572916014693
59.103 37.921476510067116 0.19041499497016393
59.103 37.92248322147651 0.18476314418359846
59.103 37.92348993288591 0.17942771889867154
59.103 37.9244966442953 0.17471769286606925
59.103 37.9255033557047 0.17073065438621185
59.103 37.926510067114094 0.16740085713250277
59.103 37.92751677852349 0.16458990320926234
59.103 37.928523489932886 0.1621546352072855
59.103 37.92953020134228 0.15997796536663125
59.103 37.93053691275168 0.15797491163306976
59.103 37.93154362416107 0.15608788581101016
59.103 37.93255033557047 0.15427939342454977
59.103 37.933557046979864 0.15252540039164705
59.103 37.93456375838926 0.15081002665573134
59.103 37.935570469798655 0.14912102784237277
59.103 37.93657718120805 0.14744468587778545
59.103 37.937583892617454 0.14575729896267142
59.103 37.93859060402685 0.14400714531821998
59.103 37.939597315436245 0.1420735256748995
59.103 37.94060402684564 0.13967731582921655
59.103 37.94161073825504 0.13621772427187775
59.103 37.94261744966443 0.13063123538012086
59.103 37.94362416107383 0.12185378757940757
59.103 37.94463087248322 0.11059409298666388
59.103 37.94563758389262 0.10003998660748659
59.103 37.946644295302015 0.09259007433084472
59.103 37.94765100671141 0.08799022414645302
59.103 37.948657718120806 0.08503584147963136
59.103 37.9496644295302 0.08284433109579262
59.103 37.9506711409396 0.08096311729331172
59.103 37.95167785234899 0.07918795620461797
59.103 37.95268456375839 0.07742991586622182
59.103 37.953691275167785 0.0756496232411488
59.103 37.95469798657718 0.07382853214052315
59.103 37.955704697986576 0.07195694622238184
59.103 37.95671140939597 0.07002912296463491
59.103 37.95771812080537 0.06804128507206406
59.103 37.95872483221476 0.06599080153822498
59.103 37.95973154362416 0.06387581781344503
59.103 37.960738255033554 0.052577751385946475
59.103 37.96174496644295 0.05043600614723051
59.103 37.96275167785235 0.04822530681616679
59.103 37.96375838926175 0.04594344585581985
59.103 37.964765100671144 0.043585460708324995
59.103 37.96577181208054 0.04114028866473611
59.103 37.966778523489936 0.038584365049569545
59.103 37.96778523489933 0.03587005599360851
59.103 37.96879194630873 0.03290739753473276
59.103 37.96979865771812 0.029544669107461147
59.103 37.97080536912752 0.025578450420801035
59.103 37.971812080536914 0.020863836593849494
59.103 37.97281879194631 0.015545110875029827
59.103 37.973825503355705 0.010148717993557418
59.103 37.9748322147651 0.005241819076159934
59.103 37.9758389261745 -0.0023022990168533736
59.103 37.97684563758389 -0.005903792556556514
59.103 37.97785234899329 -0.009121468045345943
59.103 37.978859060402684 -0.012116282563280178
59.103 37.97986577181208 -0.02012340068455215
59.103 37.980872483221475 -0.021960169437130056
59.103 37.98187919463087 -0.020370297029168427
59.103 37.982885906040266 -0.02311272286340446
59.103 37.98389261744966 -0.025816415657928693
59.103 37.98489932885906 -0.028480559428255947
59.103 37.98590604026845 -0.03110284278755436
59.103 37.98691275167785 -0.03368042483184101
59.103 37.98791946308725 -0.03621041937155586
59.103 37.98892617449665 -0.04275667922113985
59.103 37.98993288590604 -0.04518043526365796
59.103 37.99093959731544 -0.04833345848465502
59.103 37.991946308724835 -0.07659282884649077
59.103 37.99295302013423 -0.07869117657826016
59.103 37.993959731543626 -0.10228817559592851
59.103 37.99496644295302 -0.10406604837842974
59.103 37.99597315436242 -0.10578537199939529
59.103 37.99697986577181 -0.10744620077949708
59.103 37.99798657718121 -0.10904887735551276
59.103 37.998993288590604 -0.11059400361358036
59.104 37.85 0.3646839562674572
59.104 37.8510067114094 0.3532941422189507
59.104 37.85201342281879 0.35073294698521723
59.104 37.85302013422819 0.3404191992356951
59.104 37.854026845637584 0.3312051152759001
59.104 37.85503355704698 0.32324860498293156
59.104 37.856040268456375 0.3165412785176637
59.104 37.85704697986577 0.3109629669056788
59.104 37.85805369127517 0.3063392308427625
59.104 37.85906040268456 0.3024849693486325
59.104 37.86006711409396 0.3129006696228167
59.104 37.861073825503354 0.31019398811048887
59.104 37.86208053691275 0.3078293954105522
59.104 37.86308724832215 0.30571856841723904
59.104 37.86409395973155 0.30379509955593814
59.104 37.865100671140944 0.30200991222726836
59.104 37.86610738255034 0.30032717008026083
59.104 37.867114093959735 0.2987209205910618
59.104 37.86812080536913 0.29717247290398924
59.104 37.869127516778526 0.29566841166229263
59.104 37.87013422818792 0.29419912288864336
59.104 37.87114093959732 0.2927577148848694
59.104 37.87214765100671 0.2913392357957909
59.104 37.87315436241611 0.2899401100882789
59.104 37.874161073825505 0.28855773467810913
59.104 37.8751677852349 0.2871901905527014
59.104 37.876174496644296 0.28583603749259123
59.104 37.87718120805369 0.2844941683627373
59.104 37.87818791946309 0.2831637060072614
59.104 37.87919463087248 0.27349012049562327
59.104 37.88020134228188 0.2720703492333451
59.104 37.881208053691275 0.27065872494065385
59.104 37.88221476510067 0.26925471948465546
59.104 37.883221476510066 0.2678578314942353
59.104 37.88422818791946 0.2664675754158589
59.104 37.88523489932886 0.26508347487082673
59.104 37.88624161073825 0.26649935771033856
59.104 37.88724832214765 0.26517369037157246
59.104 37.88825503355705 0.26385355403442295
59.104 37.88926174496645 0.262538526031446
59.104 37.89026845637584 0.26122820488725573
59.104 37.89127516778524 0.25992222078122323
59.104 37.892281879194634 0.2586202500832722
59.104 37.89328859060403 0.25732203524991526
59.104 37.894295302013425 0.25602741191256045
59.104 37.89530201342282 0.2547363457072533
59.104 37.89630872483222 0.25344898234059243
59.104 37.89731543624161 0.2521657156221549
59.104 37.89832214765101 0.2508872797787678
59.104 37.899328859060404 0.2496148743318641
59.104 37.9003355704698 0.24835033211795896
59.104 37.901342281879195 0.24087740006787167
59.104 37.90234899328859 0.2395990347730401
59.104 37.90335570469799 0.2322078355496579
59.104 37.90436241610738 0.23088816113882282
59.104 37.90536912751678 0.22009743770595352
59.104 37.906375838926174 0.21870705989702832
59.104 37.90738255033557 0.21738075400462892
59.104 37.908389261744965 0.21613952475506631
59.104 37.90939597315436 0.21500815443860058
59.104 37.910402684563756 0.21401359267605127
59.104 37.91140939597315 0.21318116967065667
59.104 37.91241610738255 0.21252761624467514
59.104 37.91342281879195 0.21205012826059205
59.104 37.914429530201346 0.2117115933432166
59.104 37.91543624161074 0.2114236294474538
59.104 37.91644295302014 0.2110310562112648
59.104 37.91744966442953 0.21030418584186344
59.104 37.91845637583893 0.20895089285467644
59.104 37.919463087248324 0.20666950351751023
59.104 37.92046979865772 0.2032627582624772
59.104 37.921476510067116 0.19878759828730896
59.104 37.92248322147651 0.19362543986303887
59.104 37.92348993288591 0.18835234534330358
59.104 37.9244966442953 0.18347475698312032
59.104 37.9255033557047 0.17925055216788124
59.104 37.926510067114094 0.17569735207000894
59.104 37.92751677852349 0.17270109921516355
59.104 37.928523489932886 0.17011439482964028
59.104 37.92953020134228 0.16780734145113485
59.104 37.93053691275168 0.16568208387775843
59.104 37.93154362416107 0.1636698883448198
59.104 37.93255033557047 0.1617220957988302
59.104 37.933557046979864 0.15979848142246098
59.104 37.93456375838926 0.15785064269075216
59.104 37.935570469798655 0.15579146208429698
59.104 37.93657718120805 0.1534311744339645
59.104 37.937583892617454 0.15034955689765478
59.104 37.93859060402685 0.1457116749247396
59.104 37.939597315436245 0.13831848851685222
59.104 37.94060402684564 0.12774828651874243
59.104 37.94161073825504 0.11616883248683187
59.104 37.94261744966443 0.1068711549428272
59.104 37.94362416107383 0.10081619814609333
59.104 37.94463087248322 0.09701156584644663
59.104 37.94563758389262 0.09435834885580133
59.104 37.946644295302015 0.09221681435410614
59.104 37.94765100671141 0.09028541747746993
59.104 37.948657718120806 0.08843208700469052
59.104 37.9496644295302 0.08659931335407059
59.104 37.9506711409396 0.08476078643714743
59.104 37.95167785234899 0.08290302478531884
59.104 37.95268456375839 0.08101784641520032
59.104 37.953691275167785 0.0790993495463472
59.104 37.95469798657718 0.0771427341928993
59.104 37.955704697986576 0.07514386473795406
59.104 37.95671140939597 0.07309912368756338
59.104 37.95771812080537 0.07100537184574575
59.104 37.95872483221476 0.06885993572373145
59.104 37.95973154362416 0.0666605809255768
59.104 37.960738255033554 0.06440543551452672
59.104 37.96174496644295 0.05305810244655074
59.104 37.96275167785235 0.05079151598380243
59.104 37.96375838926175 0.04846177800026122
59.104 37.964765100671144 0.04606342092448303
59.104 37.96577181208054 0.04358518848524219
59.104 37.966778523489936 0.041003929555733856
59.104 37.96778523489933 0.0382734859726984
59.104 37.96879194630873 0.035306887192976616
59.104 37.96979865771812 0.03195643908683722
59.104 37.97080536912752 0.028019730448169036
59.104 37.971812080536914 0.023340180456350876
59.104 37.97281879194631 0.018033493928891794
59.104 37.973825503355705 0.012604710861706461
59.104 37.9748322147651 0.007634501529611093
59.104 37.9758389261745 0.0033639378889549626
59.104 37.97684563758389 -0.0036074710319591724
59.104 37.97785234899329 -0.006863364838924538
59.104 37.978859060402684 -0.009887835230710212
59.104 37.97986577181208 -0.012782317523124781
59.104 37.980872483221475 -0.02065123043779231
59.104 37.98187919463087 -0.022465260556711913
59.104 37.982885906040266 -0.02519176882513488
59.104 37.98389261744966 -0.02788063282827133
59.104 37.98489932885906 -0.030531208659169876
59.104 37.98590604026845 -0.028837650746851115
59.104 37.98691275167785 -0.03144858148022861
59.104 37.98791946308725 -0.034013249552830194
59.104 37.98892617449665 -0.036528855925166734
59.104 37.98993288590604 -0.04304255055287334
59.104 37.99093959731544 -0.04615593184222354
59.104 37.991946308724835 -0.04854290175841133
59.104 37.99295302013423 -0.05087151916432733
59.104 37.993959731543626 -0.07897044607489398
59.104 37.99496644295302 -0.0809882618968197
59.104 37.99597315436242 -0.10431519010868055
59.104 37.99697986577181 -0.10601216073396107
59.104 37.99798657718121 -0.1076509988996329
59.104 37.998993288590604 -0.10923218415260742
59.105000000000004 37.85 0.3895659579353642
59.105000000000004 37.8510067114094 0.3882694947367631
59.105000000000004 37.85201342281879 0.377529832900918
59.105000000000004 37.85302013422819 0.36628296903953916
59.105000000000004 37.854026845637584 0.35506931410266307
59.105000000000004 37.85503355704698 0.3444231468461636
59.105000000000004 37.856040268456375 0.3347601082497176
59.105000000000004 37.85704697986577 0.32631418144647206
59.105000000000004 37.85805369127517 0.33280018965516606
59.105000000000004 37.85906040268456 0.3269046450395126
59.105000000000004 37.86006711409396 0.32203874123333853
59.105000000000004 37.861073825503354 0.3180176634108782
59.105000000000004 37.86208053691275 0.31466334550731795
59.105000000000004 37.86308724832215 0.31182106531930115
59.105000000000004 37.86409395973155 0.3093647575195688
59.105000000000004 37.865100671140944 0.30719597791891906
59.105000000000004 37.86610738255034 0.30524004305511304
59.105000000000004 37.867114093959735 0.3034414177615797
59.105000000000004 37.86812080536913 0.3017593780698259
59.105000000000004 37.869127516778526 0.3001643505547911
59.105000000000004 37.87013422818792 0.2986350031634539
59.105000000000004 37.87114093959732 0.29715601728378377
59.105000000000004 37.87214765100671 0.2957164220252299
59.105000000000004 37.87315436241611 0.2943083687428088
59.105000000000004 37.874161073825505 0.2929262393692874
59.105000000000004 37.8751677852349 0.2915660026413632
59.105000000000004 37.876174496644296 0.29022475188467395
59.105000000000004 37.87718120805369 0.2889003745291368
59.105000000000004 37.87818791946309 0.2792655679865093
59.105000000000004 37.87919463087248 0.2778605885954552
59.105000000000004 37.88020134228188 0.2764674924152394
59.105000000000004 37.881208053691275 0.2750855466929483
59.105000000000004 37.88221476510067 0.27371414490078677
59.105000000000004 37.883221476510066 0.27235276485436843
59.105000000000004 37.88422818791946 0.27100093933932823
59.105000000000004 37.88523489932886 0.27238335950225767
59.105000000000004 37.88624161073825 0.27109586392411933
59.105000000000004 37.88724832214765 0.2698174266928375
59.105000000000004 37.88825503355705 0.2685476729428988
59.105000000000004 37.88926174496645 0.2672862304626928
59.105000000000004 37.89026845637584 0.26603272745606865
59.105000000000004 37.89127516778524 0.2647867915262793
59.105000000000004 37.892281879194634 0.26354804960183975
59.105000000000004 37.89328859060403 0.2623161286462468
59.105000000000004 37.894295302013425 0.26109065709803125
59.105000000000004 37.89530201342282 0.25987126708735325
59.105000000000004 37.89630872483222 0.2586575975816044
59.105000000000004 37.89731543624161 0.2574492987363597
59.105000000000004 37.89832214765101 0.24994470420444626
59.105000000000004 37.899328859060404 0.24870614824823503
59.105000000000004 37.9003355704698 0.24747201076886097
59.105000000000004 37.901342281879195 0.24019779134611274
59.105000000000004 37.90234899328859 0.23888452967956164
59.105000000000004 37.90335570469799 0.23757426699731113
59.105000000000004 37.90436241610738 0.22688260095434226
59.105000000000004 37.90536912751678 0.22542447420559047
59.105000000000004 37.906375838926174 0.22396824299367007
59.105000000000004 37.90738255033557 0.2225148356858723
59.105000000000004 37.908389261744965 0.2210657134628254
59.105000000000004 37.90939597315436 0.21962306435808082
59.105000000000004 37.910402684563756 0.21819005355776316
59.105000000000004 37.91140939597315 0.2167711312097278
59.105000000000004 37.91241610738255 0.21537237804740392
59.105000000000004 37.91342281879195 0.21400181748712344
59.105000000000004 37.914429530201346 0.21266950462760875
59.105000000000004 37.91543624161074 0.21138694370601077
59.105000000000004 37.91644295302014 0.21016484118704984
59.105000000000004 37.91744966442953 0.20900712607122698
59.105000000000004 37.91845637583893 0.20789732139469674
59.105000000000004 37.919463087248324 0.2067713478311001
59.105000000000004 37.92046979865772 0.20547360532310052
59.105000000000004 37.921476510067116 0.20371736898840603
59.105000000000004 37.92248322147651 0.20113579599442652
59.105000000000004 37.92348993288591 0.19753676883058174
59.105000000000004 37.9244966442953 0.1932097006019448
59.105000000000004 37.9255033557047 0.1888084092756178
59.105000000000004 37.926510067114094 0.1848559071810549
59.105000000000004 37.92751677852349 0.18150295396305946
59.105000000000004 37.928523489932886 0.17865493591034298
59.105000000000004 37.92953020134228 0.1761484983941667
59.105000000000004 37.93053691275168 0.17383006851312438
59.105000000000004 37.93154362416107 0.17155631172583505
59.105000000000004 37.93255033557047 0.1691465040406106
59.105000000000004 37.933557046979864 0.16628147221741751
59.105000000000004 37.93456375838926 0.1623305580815855
59.105000000000004 37.935570469798655 0.1562106322230986
59.105000000000004 37.93657718120805 0.14687519242577843
59.105000000000004 37.937583892617454 0.13511003547394154
59.105000000000004 37.93859060402685 0.12414220664377487
59.105000000000004 37.939597315436245 0.11630790389915441
59.105000000000004 37.94060402684564 0.11130635198446619
59.105000000000004 37.94161073825504 0.10793963764182071
59.105000000000004 37.94261744966443 0.10534575980003533
59.105000000000004 37.94362416107383 0.10308830830069511
59.105000000000004 37.94463087248322 0.10097376132302006
59.105000000000004 37.94563758389262 0.09892032934789201
59.105000000000004 37.946644295302015 0.09689343700389258
59.105000000000004 37.94765100671141 0.09487758351126269
59.105000000000004 37.948657718120806 0.09286465124068757
59.105000000000004 37.9496644295302 0.09084918426911862
59.105000000000004 37.9506711409396 0.08882654922443929
59.105000000000004 37.95167785234899 0.086792275369448
59.105000000000004 37.95268456375839 0.08474187208705923
59.105000000000004 37.953691275167785 0.08267083456523601
59.105000000000004 37.95469798657718 0.08057471775378611
59.105000000000004 37.955704697986576 0.07844922819940796
59.105000000000004 37.95671140939597 0.07629031182259916
59.105000000000004 37.95771812080537 0.0740942266567773
59.105000000000004 37.95872483221476 0.07185759201883551
59.105000000000004 37.95973154362416 0.06957740199732765
59.105000000000004 37.960738255033554 0.06725098013981426
59.105000000000004 37.96174496644295 0.06487582839418045
59.105000000000004 37.96275167785235 0.05349876782041161
59.105000000000004 37.96375838926175 0.051122131983691144
59.105000000000004 37.964765100671144 0.048683627607588015
59.105000000000004 37.96577181208054 0.04617180779308073
59.105000000000004 37.966778523489936 0.043563932979124184
59.105000000000004 37.96778523489933 0.04081529440961979
59.105000000000004 37.96879194630873 0.037841871255216854
59.105000000000004 37.96979865771812 0.03450005553757131
59.105000000000004 37.97080536912752 0.030588972204294088
59.105000000000004 37.971812080536914 0.025941654915883725
59.105000000000004 37.97281879194631 0.020646090855020907
59.105000000000004 37.973825503355705 0.01518425290739471
59.105000000000004 37.9748322147651 0.010148063412811367
59.105000000000004 37.9758389261745 0.005808228166315654
59.105000000000004 37.97684563758389 -0.0011987677112336725
59.105000000000004 37.97785234899329 -0.004497103785110604
59.105000000000004 37.978859060402684 -0.0075549116750319445
59.105000000000004 37.97986577181208 -0.010478080475155319
59.105000000000004 37.980872483221475 -0.01832676491768026
59.105000000000004 37.98187919463087 -0.021081351727950615
59.105000000000004 37.982885906040266 -0.022887544073073418
59.105000000000004 37.98389261744966 -0.02560619317035215
59.105000000000004 37.98489932885906 -0.02828779753389586
59.105000000000004 37.98590604026845 -0.030930146518347718
59.105000000000004 37.98691275167785 -0.03353035253533741
59.105000000000004 37.98791946308725 -0.03608539343640163
59.105000000000004 37.98892617449665 -0.03428190815959428
59.105000000000004 37.98993288590604 -0.03703612919532444
59.105000000000004 37.99093959731544 -0.04389830942375747
59.105000000000004 37.991946308724835 -0.04632628683152569
59.105000000000004 37.99295302013423 -0.04869649551621394
59.105000000000004 37.993959731543626 -0.05100776601134502
59.105000000000004 37.99496644295302 -0.07919171614080461
59.105000000000004 37.99597315436242 -0.0811897607997387
59.105000000000004 37.99697986577181 -0.1045043319044476
59.105000000000004 37.99798657718121 -0.10618061395280126
59.105000000000004 37.998993288590604 -0.1077991464834674
59.106 37.85 0.4162439847854861
59.106 37.8510067114094 0.409392449102564
59.106 37.85201342281879 0.4012951216031582
59.106 37.85302013422819 0.39195909385211297
59.106 37.854026845637584 0.3815618121531524
59.106 37.85503355704698 0.37046820266048275
59.106 37.856040268456375 0.35918709839583207
59.106 37.85704697986577 0.3620119269183084
59.106 37.85805369127517 0.35202566939271446
59.106 37.85906040268456 0.343187447152817
59.106 37.86006711409396 0.33561900953472773
59.106 37.861073825503354 0.32928523548721744
59.106 37.86208053691275 0.3240511614898131
59.106 37.86308724832215 0.3197375273007467
59.106 37.86409395973155 0.3161608214303169
59.106 37.865100671140944 0.31315586973013315
59.106 37.86610738255034 0.31058502238669683
59.106 37.867114093959735 0.3083391554981744
59.106 37.86812080536913 0.30633462607123424
59.106 37.869127516778526 0.30450879460664115
59.106 37.87013422818792 0.30281550617001207
59.106 37.87114093959732 0.30122113873987766
59.106 37.87214765100671 0.29970139723656614
59.106 37.87315436241611 0.2982388261312272
59.106 37.874161073825505 0.2968209337329495
59.106 37.8751677852349 0.29543880419339685
59.106 37.876174496644296 0.2940860835461143
59.106 37.87718120805369 0.28446408512224997
59.106 37.87818791946309 0.2830478795745235
59.106 37.87919463087248 0.2816496454656268
59.106 37.88020134228188 0.2802676996338212
59.106 37.881208053691275 0.2789008154115011
59.106 37.88221476510067 0.27754808596408825
59.106 37.883221476510066 0.27620882738869207
59.106 37.88422818791946 0.27753914345775743
59.106 37.88523489932886 0.2762708144225129
59.106 37.88624161073825 0.2750153827470758
59.106 37.88724832214765 0.27377256545175044
59.106 37.88825503355705 0.2725421127857976
59.106 37.88926174496645 0.2713237965796668
59.106 37.89026845637584 0.27011740206842116
59.106 37.89127516778524 0.2689227220880835
59.106 37.892281879194634 0.2677395528514682
59.106 37.89328859060403 0.2665676907268242
59.106 37.894295302013425 0.2654069295960193
59.106 37.89530201342282 0.25787692230916354
59.106 37.89630872483222 0.2566964632761813
59.106 37.89731543624161 0.2555263927232994
59.106 37.89832214765101 0.25436647165025017
59.106 37.899328859060404 0.2532164452711768
59.106 37.9003355704698 0.24603528787874426
59.106 37.901342281879195 0.2448166475822441
59.106 37.90234899328859 0.24360597132062842
59.106 37.90335570469799 0.24240288317379002
59.106 37.90436241610738 0.23179385645158349
59.106 37.90536912751678 0.2304499498089055
59.106 37.906375838926174 0.2291101420485731
59.106 37.90738255033557 0.22777381040535394
59.106 37.908389261744965 0.2264402640707807
59.106 37.90939597315436 0.22510874228642003
59.106 37.910402684563756 0.22377841613067156
59.106 37.91140939597315 0.22244839543056397
59.106 37.91241610738255 0.2211177420084961
59.106 37.91342281879195 0.2197854891899009
59.106 37.914429530201346 0.21845066356872778
59.106 37.91543624161074 0.21711229483163472
59.106 37.91644295302014 0.21576937479576958
59.106 37.91744966442953 0.2144206694153618
59.106 37.91845637583893 0.21306415708622922
59.106 37.919463087248324 0.21169557747745338
59.106 37.92046979865772 0.21030495988985187
59.106 37.921476510067116 0.20886880502862726
59.106 37.92248322147651 0.20733381050718241
59.106 37.92348993288591 0.20558788950671608
59.106 37.9244966442953 0.20342714980203677
59.106 37.9255033557047 0.2005801652495518
59.106 37.926510067114094 0.1969128760212262
59.106 37.92751677852349 0.19272310490864386
59.106 37.928523489932886 0.1885744697442138
59.106 37.92953020134228 0.1846699785661222
59.106 37.93053691275168 0.18055803378885327
59.106 37.93154362416107 0.17519467394403376
59.106 37.93255033557047 0.1671999763358747
59.106 37.933557046979864 0.15609640085037335
59.106 37.93456375838926 0.14409494388044092
59.106 37.935570469798655 0.134454473630927
59.106 37.93657718120805 0.12803854009753374
59.106 37.937583892617454 0.12381611521712196
59.106 37.93859060402685 0.12069817270786325
59.106 37.939597315436245 0.11806425085074354
59.106 37.94060402684564 0.11562961095280255
59.106 37.94161073825504 0.11327606899594896
59.106 37.94261744966443 0.11095801842043707
59.106 37.94362416107383 0.10865953050311468
59.106 37.94463087248322 0.10637606622179162
59.106 37.94563758389262 0.10410688605643104
59.106 37.946644295302015 0.101851958914565
59.106 37.94765100671141 0.09961075662682634
59.106 37.948657718120806 0.09738184923604828
59.106 37.9496644295302 0.09516284809170827
59.106 37.9506711409396 0.09295050246613315
59.106 37.95167785234899 0.09074086200836906
59.106 37.95268456375839 0.08852946248088597
59.106 37.953691275167785 0.08631151247841215
59.106 37.95469798657718 0.0840820687772757
59.106 37.955704697986576 0.08183619328563918
59.106 37.95671140939597 0.07956908735783891
59.106 37.95771812080537 0.07727620007093901
59.106 37.95872483221476 0.07495330564515877
59.106 37.95973154362416 0.072596540319251
59.106 37.960738255033554 0.07020237792873402
59.106 37.96174496644295 0.06776750048377905
59.106 37.96275167785235 0.05631841777347608
59.106 37.96375838926175 0.053896239436321214
59.106 37.964765100671144 0.05141829017711595
59.106 37.96577181208054 0.04887296583236809
59.106 37.966778523489936 0.04623795132689513
59.106 37.96778523489933 0.04346996197732916
59.106 37.96879194630873 0.04048786668785108
59.106 37.96979865771812 0.03715215549649818
59.106 37.97080536912752 0.03326391723356713
59.106 37.971812080536914 0.028646985686841986
59.106 37.97281879194631 0.023362558160316127
59.106 37.973825503355705 0.017868071506360397
59.106 37.9748322147651 0.012764411125320063
59.106 37.9758389261745 0.008352088550065084
59.106 37.97684563758389 0.004567077749281945
59.106 37.97785234899329 -0.0020372699784740045
59.106 37.978859060402684 -0.005130875052345982
59.106 37.97986577181208 -0.00808476204086972
59.106 37.980872483221475 -0.010958510978841124
59.106 37.98187919463087 -0.01869774434766941
59.106 37.982885906040266 -0.02144229505535361
59.106 37.98389261744966 -0.0232479569595046
59.106 37.98489932885906 -0.025961735201926003
59.106 37.98590604026845 -0.02863747403565764
59.106 37.98691275167785 -0.0312722818082941
59.106 37.98791946308725 -0.03386308608537404
59.106 37.98892617449665 -0.036854687120418436
59.106 37.98993288590604 -0.039378402239804156
59.106 37.99093959731544 -0.04184936333451966
59.106 37.991946308724835 -0.04403251004837566
59.106 37.99295302013423 -0.04644514570323674
59.106 37.993959731543626 -0.04879931102165587
59.106 37.99496644295302 -0.05109406816603111
59.106 37.99597315436242 -0.05332882069992595
59.106 37.99697986577181 -0.08133853236484477
59.106 37.99798657718121 -0.10463698887747017
59.106 37.998993288590604 -0.10629388618715188
59.107 37.85 0.4277887080228493
59.107 37.8510067114094 0.42336474030225607
59.107 37.85201342281879 0.41804923960097295
59.107 37.85302013422819 0.4116618809135418
59.107 37.854026845637584 0.4040622565601115
59.107 37.85503355704698 0.40893083472818953
59.107 37.856040268456375 0.399013964334647
59.107 37.85704697986577 0.38823788364042056
59.107 37.85805369127517 0.3770640403458375
59.107 37.85906040268456 0.36604126797371805
59.107 37.86006711409396 0.3556869417758687
59.107 37.861073825503354 0.34638191416905145
59.107 37.86208053691275 0.3383208987378617
59.107 37.86308724832215 0.33152470235691
59.107 37.86409395973155 0.32589074221913233
59.107 37.865100671140944 0.3212515940044557
59.107 37.86610738255034 0.31742190209756693
59.107 37.867114093959735 0.3142275718044831
59.107 37.86812080536913 0.31151962724038024
59.107 37.869127516778526 0.30917788471207536
59.107 37.87013422818792 0.30710912561151155
59.107 37.87114093959732 0.30524297012077917
59.107 37.87214765100671 0.30352727306256994
59.107 37.87315436241611 0.3019239116992979
59.107 37.874161073825505 0.3004052814128199
59.107 37.8751677852349 0.2989515344981506
59.107 37.876174496644296 0.29754847696574205
59.107 37.87718120805369 0.28781691371074464
59.107 37.87818791946309 0.2863765629282896
59.107 37.87919463087248 0.2849631529917873
59.107 37.88020134228188 0.28357308371821244
59.107 37.881208053691275 0.2822038189062236
59.107 37.88221476510067 0.2808535772324008
59.107 37.883221476510066 0.2821099996280365
59.107 37.88422818791946 0.28083888864514955
59.107 37.88523489932886 0.27958479546536075
59.107 37.88624161073825 0.2783473253696655
59.107 37.88724832214765 0.2771262178215341
59.107 37.88825503355705 0.2759213071581855
59.107 37.88926174496645 0.2747324947610755
59.107 37.89026845637584 0.2735597292626589
59.107 37.89127516778524 0.2724029923180246
59.107 37.892281879194634 0.2648070223528615
59.107 37.89328859060403 0.26363989604585014
59.107 37.894295302013425 0.26248878055812297
59.107 37.89530201342282 0.2613537066733755
59.107 37.89630872483222 0.26023470226266204
59.107 37.89731543624161 0.25913178685341326
59.107 37.89832214765101 0.2580449657367272
59.107 37.899328859060404 0.2509393669131514
59.107 37.9003355704698 0.2497969765302147
59.107 37.901342281879195 0.24866950178162753
59.107 37.90234899328859 0.247556795153086
59.107 37.90335570469799 0.23717255468245763
59.107 37.90436241610738 0.2359357466167076
59.107 37.90536912751678 0.23471076756285225
59.107 37.906375838926174 0.23349707413319665
59.107 37.90738255033557 0.23229397878125577
59.107 37.908389261744965 0.23110062319127156
59.107 37.90939597315436 0.22991594823490452
59.107 37.910402684563756 0.22873866017508362
59.107 37.91140939597315 0.22756719270349668
59.107 37.91241610738255 0.2263996641995612
59.107 37.91342281879195 0.22523382919402815
59.107 37.914429530201346 0.2240670222148256
59.107 37.91543624161074 0.22289609060758447
59.107 37.91644295302014 0.22171730981563761
59.107 37.91744966442953 0.22052626848316198
59.107 37.91845637583893 0.2193176985768663
59.107 37.919463087248324 0.21808520123298866
59.107 37.92046979865772 0.21682076906199008
59.107 37.921476510067116 0.21551390197439874
59.107 37.92248322147651 0.21414989306352977
59.107 37.92348993288591 0.2127063667422857
59.107 37.9244966442953 0.21114590874482392
59.107 37.9255033557047 0.20939882756289555
59.107 37.926510067114094 0.2073158715502725
59.107 37.92751677852349 0.20451458543818546
59.107 37.928523489932886 0.19987946881363733
59.107 37.92953020134228 0.19064762515447486
59.107 37.93053691275168 0.17462502772685767
59.107 37.93154362416107 0.15789277512812838
59.107 37.93255033557047 0.1471843538115416
59.107 37.933557046979864 0.14128532188449144
59.107 37.93456375838926 0.13756419136067238
59.107 37.935570469798655 0.13467272061501895
59.107 37.93657718120805 0.13206923631347897
59.107 37.937583892617454 0.12954805754880433
59.107 37.93859060402685 0.12703523373150727
59.107 37.939597315436245 0.12450868976634333
59.107 37.94060402684564 0.12196679457397772
59.107 37.94161073825504 0.11941561244532363
59.107 37.94261744966443 0.11686352183282554
59.107 37.94362416107383 0.11431883112448242
59.107 37.94463087248322 0.11178867607066312
59.107 37.94563758389262 0.1092785145828187
59.107 37.946644295302015 0.10679193656503455
59.107 37.94765100671141 0.1043306620067255
59.107 37.948657718120806 0.10189466034333258
59.107 37.9496644295302 0.09948234780748248
59.107 37.9506711409396 0.09709083052177242
59.107 37.95167785234899 0.09471616812032918
59.107 37.95268456375839 0.09235363856260322
59.107 37.953691275167785 0.08999799012578755
59.107 37.95469798657718 0.08764367121320774
59.107 37.955704697986576 0.08528503231583345
59.107 37.95671140939597 0.08291649693651515
59.107 37.95771812080537 0.08053269920163966
59.107 37.95872483221476 0.07812858465831402
59.107 37.95973154362416 0.07569946616107734
59.107 37.960738255033554 0.07324101618309826
59.107 37.96174496644295 0.07074915494006835
59.107 37.96275167785235 0.0682197494905056
59.107 37.96375838926175 0.05676554778956191
59.107 37.964765100671144 0.05424911580942228
59.107 37.96577181208054 0.05167076864403202
59.107 37.966778523489936 0.049008628131493016
59.107 37.96778523489933 0.04622079793098143
59.107 37.96879194630873 0.043228950721410996
59.107 37.96979865771812 0.039897627361794655
59.107 37.97080536912752 0.036030190786922364
59.107 37.971812080536914 0.03144232154749242
59.107 37.97281879194631 0.026169383353986303
59.107 37.973825503355705 0.020643026329667344
59.107 37.9748322147651 0.01547087098446559
59.107 37.9758389261745 0.010983359169444706
59.107 37.97684563758389 0.0071358887759968015
59.107 37.97785234899329 0.003728882465306324
59.107 37.978859060402684 -0.002625831190186459
59.107 37.97986577181208 -0.0056116404699232125
59.107 37.980872483221475 -0.008514926545102913
59.107 37.98187919463087 -0.01623538936531059
59.107 37.982885906040266 -0.019009827278241714
59.107 37.98389261744966 -0.02175014206720803
59.107 37.98489932885906 -0.02355843372895064
59.107 37.98590604026845 -0.02626793778372885
59.107 37.98691275167785 -0.028937696138582727
59.107 37.98791946308725 -0.031933977623169116
59.107 37.98892617449665 -0.034544990553544846
59.107 37.98993288590604 -0.03710662028709229
59.107 37.99093959731544 -0.039616383398634075
59.107 37.991946308724835 -0.042072146640472166
59.107 37.99295302013423 -0.04879303290737837
59.107 37.993959731543626 -0.051125123943700085
59.107 37.99496644295302 -0.048854363684499816
59.107 37.99597315436242 -0.05113314577419956
59.107 37.99697986577181 -0.053351796025664325
59.107 37.99798657718121 -0.08143689195215273
59.107 37.998993288590604 -0.08333902023423685
59.108000000000004 37.85 0.4350507701453932
59.108000000000004 37.8510067114094 0.4320504681706155
59.108000000000004 37.85201342281879 0.4285577884419264
59.108000000000004 37.85302013422819 0.42442071085796745
59.108000000000004 37.854026845637584 0.4332649322569523
59.108000000000004 37.85503355704698 0.4273974191158528
59.108000000000004 37.856040268456375 0.4203648018197755
59.108000000000004 37.85704697986577 0.4120779604802743
59.108000000000004 37.85805369127517 0.4025785001492084
59.108000000000004 37.85906040268456 0.39208593902400896
59.108000000000004 37.86006711409396 0.38100282311023664
59.108000000000004 37.861073825503354 0.36985694393589696
59.108000000000004 37.86208053691275 0.35919187051889184
59.108000000000004 37.86308724832215 0.349450493104135
59.108000000000004 37.86409395973155 0.3409011700496796
59.108000000000004 37.865100671140944 0.33362759239669987
59.108000000000004 37.86610738255034 0.32756810842388323
59.108000000000004 37.867114093959735 0.3225741653380392
59.108000000000004 37.86812080536913 0.318463060930469
59.108000000000004 37.869127516778526 0.31505402656285797
59.108000000000004 37.87013422818792 0.31218748577646305
59.108000000000004 37.87114093959732 0.3097320792784022
59.108000000000004 37.87214765100671 0.3075845269944864
59.108000000000004 37.87315436241611 0.3056661256125156
59.108000000000004 37.874161073825505 0.30391819333394654
59.108000000000004 37.8751677852349 0.30229764948254817
59.108000000000004 37.876174496644296 0.29244255560012733
59.108000000000004 37.87718120805369 0.2908806175495069
59.108000000000004 37.87818791946309 0.28937480466374715
59.108000000000004 37.87919463087248 0.28791365698126825
59.108000000000004 37.88020134228188 0.2864890692498791
59.108000000000004 37.881208053691275 0.28509534288621413
59.108000000000004 37.88221476510067 0.28625044080653256
59.108000000000004 37.883221476510066 0.28495116231133955
59.108000000000004 37.88422818791946 0.283674838103904
59.108000000000004 37.88523489932886 0.28242023581791864
59.108000000000004 37.88624161073825 0.2811865692301548
59.108000000000004 37.88724832214765 0.2799733710028818
59.108000000000004 37.88825503355705 0.27878040233349066
59.108000000000004 37.88926174496645 0.2710809370546614
59.108000000000004 37.89026845637584 0.2698846543672289
59.108000000000004 37.89127516778524 0.2687086217635905
59.108000000000004 37.892281879194634 0.26755303280280346
59.108000000000004 37.89328859060403 0.2664181254670586
59.108000000000004 37.894295302013425 0.2653041686247505
59.108000000000004 37.89530201342282 0.26421145103144733
59.108000000000004 37.89630872483222 0.26314027173373
59.108000000000004 37.89731543624161 0.2620909310110972
59.108000000000004 37.89832214765101 0.2550370679792423
59.108000000000004 37.899328859060404 0.2539444966342432
59.108000000000004 37.9003355704698 0.25287353728907275
59.108000000000004 37.901342281879195 0.2518243758747057
59.108000000000004 37.90234899328859 0.25079713905256257
59.108000000000004 37.90335570469799 0.2404827852523747
59.108000000000004 37.90436241610738 0.23934629083321973
59.108000000000004 37.90536912751678 0.23822948596697197
59.108000000000004 37.906375838926174 0.237131997675881
59.108000000000004 37.90738255033557 0.2360532591270845
59.108000000000004 37.908389261744965 0.23499246895019957
59.108000000000004 37.90939597315436 0.23394854375651486
59.108000000000004 37.910402684563756 0.2329200625158861
59.108000000000004 37.91140939597315 0.23190520093583056
59.108000000000004 37.91241610738255 0.23090165316744385
59.108000000000004 37.91342281879195 0.22990653684056747
59.108000000000004 37.914429530201346 0.2289162752929242
59.108000000000004 37.91543624161074 0.2279264474049465
59.108000000000004 37.91644295302014 0.22693158989928397
59.108000000000004 37.91744966442953 0.2259249280938365
59.108000000000004 37.91845637583893 0.22489799706371014
59.108000000000004 37.919463087248324 0.22384009323240844
59.108000000000004 37.92046979865772 0.22273746249821824
59.108000000000004 37.921476510067116 0.2215720780359427
59.108000000000004 37.92248322147651 0.22031976912473358
59.108000000000004 37.92348993288591 0.2189472408088761
59.108000000000004 37.9244966442953 0.21740667703183622
59.108000000000004 37.9255033557047 0.21562243711508522
59.108000000000004 37.926510067114094 0.2134426812958394
59.108000000000004 37.92751677852349 0.2104228004112074
59.108000000000004 37.928523489932886 0.20490895246213692
59.108000000000004 37.92953020134228 0.1920651488165764
59.108000000000004 37.93053691275168 0.17073504740814216
59.108000000000004 37.93154362416107 0.1557489229695077
59.108000000000004 37.93255033557047 0.1496403709027077
59.108000000000004 37.933557046979864 0.14649343430964237
59.108000000000004 37.93456375838926 0.1439572432313961
59.108000000000004 37.935570469798655 0.1414800729336675
59.108000000000004 37.93657718120805 0.13894756566865235
59.108000000000004 37.937583892617454 0.13634288108583942
59.108000000000004 37.93859060402685 0.13367296673139759
59.108000000000004 37.939597315436245 0.13095152110746427
59.108000000000004 37.94060402684564 0.12819418685426823
59.108000000000004 37.94161073825504 0.12541664781754458
59.108000000000004 37.94261744966443 0.12263357035836661
59.108000000000004 37.94362416107383 0.11985790468766352
59.108000000000004 37.94463087248322 0.11710042556763807
59.108000000000004 37.94563758389262 0.1143694748541854
59.108000000000004 37.946644295302015 0.11167088124859531
59.108000000000004 37.94765100671141 0.10900802892541543
59.108000000000004 37.948657718120806 0.10638204181666674
59.108000000000004 37.9496644295302 0.10379204858283518
59.108000000000004 37.9506711409396 0.10123549517547423
59.108000000000004 37.95167785234899 0.09870847648496275
59.108000000000004 37.95268456375839 0.09620606459842575
59.108000000000004 37.953691275167785 0.09372261750323332
59.108000000000004 37.95469798657718 0.09125205779883681
59.108000000000004 37.955704697986576 0.08878811556780496
59.108000000000004 37.95671140939597 0.08632453266828427
59.108000000000004 37.95771812080537 0.08385522705229068
59.108000000000004 37.95872483221476 0.08137441478871994
59.108000000000004 37.95973154362416 0.07887668317315577
59.108000000000004 37.960738255033554 0.07635699821005387
59.108000000000004 37.96174496644295 0.07381060874862012
59.108000000000004 37.96275167785235 0.07123276726763703
59.108000000000004 37.96375838926175 0.06861810331912048
59.108000000000004 37.964765100671144 0.057165877200261034
59.108000000000004 37.96577181208054 0.05455527596345222
59.108000000000004 37.966778523489936 0.051866444407606065
59.108000000000004 37.96778523489933 0.049058819567583546
59.108000000000004 37.96879194630873 0.04605675457026481
59.108000000000004 37.96979865771812 0.042728715857944494
59.108000000000004 37.97080536912752 0.03888049871664043
59.108000000000004 37.971812080536914 0.03432047857234781
59.108000000000004 37.97281879194631 0.029059104801599595
59.108000000000004 37.973825503355705 0.023501228928693677
59.108000000000004 37.9748322147651 0.018259177362294002
59.108000000000004 37.9758389261745 0.013693531735123138
59.108000000000004 37.97684563758389 0.009780675350376869
59.108000000000004 37.97785234899329 0.006324094786396742
59.108000000000004 37.978859060402684 -4.772086024393274e-05
59.108000000000004 37.97986577181208 -0.003066147871825131
59.108000000000004 37.980872483221475 -0.0059993064745125085
59.108000000000004 37.98187919463087 -0.013700621567883667
59.108000000000004 37.982885906040266 -0.01650493708664849
59.108000000000004 37.98389261744966 -0.0192759128795022
59.108000000000004 37.98489932885906 -0.02108253365212101
59.108000000000004 37.98590604026845 -0.023825639268577545
59.108000000000004 37.98691275167785 -0.02653018087782054
59.108000000000004 37.98791946308725 -0.029517258728371312
59.108000000000004 37.98892617449665 -0.03216500923728802
59.108000000000004 37.98993288590604 -0.0347644057079111
59.108000000000004 37.99093959731544 -0.03731287393498174
59.108000000000004 37.991946308724835 -0.03980817809628835
59.108000000000004 37.99295302013423 -0.04224843758533428
59.108000000000004 37.993959731543626 -0.048919286732700934
59.108000000000004 37.99496644295302 -0.05123464803555536
59.108000000000004 37.99597315436242 -0.05349098997081503
59.108000000000004 37.99697986577181 -0.051126641964638495
59.108000000000004 37.99798657718121 -0.053329707538878
59.108000000000004 37.998993288590604 -0.05547272296448123
59.109 37.85 0.44002280809897576
59.109 37.8510067114094 0.4377349934427921
59.109 37.85201342281879 0.4352033689819519
59.109 37.85302013422819 0.4462155667266129
59.109 37.854026845637584 0.4430048530078267
59.109 37.85503355704698 0.439209618639548
59.109 37.856040268456375 0.4346618740570785
59.109 37.85704697986577 0.4291780459862582
59.109 37.85805369127517 0.42258400840200633
59.109 37.85906040268456 0.4147576364173446
59.109 37.86006711409396 0.40568543413970426
59.109 37.861073825503354 0.3955171726256354
59.109 37.86208053691275 0.38459008696615554
59.109 37.86308724832215 0.373395434367683
59.109 37.86409395973155 0.36248485362659544
59.109 37.865100671140944 0.3523504699697583
59.109 37.86610738255034 0.34333076012745634
59.109 37.867114093959735 0.33557649162772674
59.109 37.86812080536913 0.3290745508984298
59.109 37.869127516778526 0.3237025611100117
59.109 37.87013422818792 0.3192857065812169
59.109 37.87114093959732 0.3156395253421222
59.109 37.87214765100671 0.31259511922754785
59.109 37.87315436241611 0.3100101963170749
59.109 37.874161073825505 0.3077711393643409
59.109 37.8751677852349 0.30579045190197746
59.109 37.876174496644296 0.2956024812050997
59.109 37.87718120805369 0.2938463563716689
59.109 37.87818791946309 0.292197721081219
59.109 37.87919463087248 0.290631114154702
59.109 37.88020134228188 0.28912832764569246
59.109 37.881208053691275 0.29013227589545
59.109 37.88221476510067 0.28876459655597614
59.109 37.883221476510066 0.2874330292323244
59.109 37.88422818791946 0.286133207562811
59.109 37.88523489932886 0.2848621788018506
59.109 37.88624161073825 0.2836180023165697
59.109 37.88724832214765 0.27576038753822185
59.109 37.88825503355705 0.2745218544430672
59.109 37.88926174496645 0.2733078529699456
59.109 37.89026845637584 0.27211833427448917
59.109 37.89127516778524 0.2709534456339894
59.109 37.892281879194634 0.26981347701990027
59.109 37.89328859060403 0.2686988222146816
59.109 37.894295302013425 0.26760994990608
59.109 37.89530201342282 0.26654738144459905
59.109 37.89630872483222 0.26551167283586297
59.109 37.89731543624161 0.2645033991610974
59.109 37.89832214765101 0.2574191291698852
59.109 37.899328859060404 0.25637860209618496
59.109 37.9003355704698 0.25536614757190934
59.109 37.901342281879195 0.2543822470221049
59.109 37.90234899328859 0.24424914309913245
59.109 37.90335570469799 0.2431721987358683
59.109 37.90436241610738 0.24212273650661437
59.109 37.90536912751678 0.24110077080390746
59.109 37.906375838926174 0.24010611832339643
59.109 37.90738255033557 0.23913834957990682
59.109 37.908389261744965 0.23819673066746097
59.109 37.90939597315436 0.23728015267899197
59.109 37.910402684563756 0.23638704513103204
59.109 37.91140939597315 0.2355152680730911
59.109 37.91241610738255 0.2346619749565047
59.109 37.91342281879195 0.2338234342565071
59.109 37.914429530201346 0.23299479145752106
59.109 37.91543624161074 0.23216974308182203
59.109 37.91644295302014 0.23134007916920343
59.109 37.91744966442953 0.23049502757351498
59.109 37.91845637583893 0.22962029979933643
59.109 37.919463087248324 0.22869669171828513
59.109 37.92046979865772 0.2276980351180753
59.109 37.921476510067116 0.22658824010096673
59.109 37.92248322147651 0.2253171439173127
59.109 37.92348993288591 0.2238148986861535
59.109 37.9244966442953 0.2219843202222881
59.109 37.9255033557047 0.2196871814326793
59.109 37.926510067114094 0.21669903975990393
59.109 37.92751677852349 0.21250691192494842
59.109 37.928523489932886 0.2055332694592497
59.109 37.92953020134228 0.19214060078348996
59.109 37.93053691275168 0.17359602540989738
59.109 37.93154362416107 0.16148114368919894
59.109 37.93255033557047 0.15626646781568634
59.109 37.933557046979864 0.15329170251852772
59.109 37.93456375838926 0.1507499259930997
59.109 37.935570469798655 0.14820596151871857
59.109 37.93657718120805 0.14557076035049174
59.109 37.937583892617454 0.14283596511043636
59.109 37.93859060402685 0.14001375697241464
59.109 37.939597315436245 0.1371223560484636
59.109 37.94060402684564 0.1341817091272519
59.109 37.94161073825504 0.13121163277044903
59.109 37.94261744966443 0.1282306819588509
59.109 37.94362416107383 0.12525535643434516
59.109 37.94463087248322 0.12229956173450977
59.109 37.94563758389262 0.11937430175722619
59.109 37.946644295302015 0.11648758060817638
59.109 37.94765100671141 0.11364448150656425
59.109 37.948657718120806 0.11084738284508541
59.109 37.9496644295302 0.10809626913286305
59.109 37.9506711409396 0.10538909707513278
59.109 37.95167785234899 0.10272218290424293
59.109 37.95268456375839 0.10009058456178495
59.109 37.953691275167785 0.09748846000825323
59.109 37.95469798657718 0.09490938980671149
59.109 37.955704697986576 0.09234665759288155
59.109 37.95671140939597 0.08979348578769863
59.109 37.95771812080537 0.08724322569274892
59.109 37.95872483221476 0.0846895005154151
59.109 37.95973154362416 0.08212629590356602
59.109 37.960738255033554 0.0795479829612776
59.109 37.96174496644295 0.07694923862812499
59.109 37.96275167785235 0.07432478784449922
59.109 37.96375838926175 0.07166881154716978
59.109 37.964765100671144 0.06897370737026057
59.109 37.96577181208054 0.057524330549583426
59.109 37.966778523489936 0.05480972863980236
59.109 37.96778523489933 0.0519829437621905
59.109 37.96879194630873 0.04897083198896086
59.109 37.96979865771812 0.04564554893573354
59.109 37.97080536912752 0.04181526622646212
59.109 37.971812080536914 0.03728160045463298
59.109 37.97281879194631 0.03203085531767633
59.109 37.973825503355705 0.02644032162485062
59.109 37.9748322147651 0.0211254134655854
59.109 37.9758389261745 0.016477391528503263
59.109 37.97684563758389 0.012495388481605058
59.109 37.97785234899329 0.008986769428250704
59.109 37.978859060402684 0.005761951179378902
59.109 37.97986577181208 -0.0004545433905121521
59.109 37.980872483221475 -0.0034175838563949984
59.109 37.98187919463087 -0.006326923123496503
59.109 37.982885906040266 -0.013932791652591842
59.109 37.98389261744966 -0.01673390342346026
59.109 37.98489932885906 -0.019502926110782803
59.109 37.98590604026845 -0.02131446489006668
59.109 37.98691275167785 -0.024305072959222268
59.109 37.98791946308725 -0.027033319916489357
59.109 37.98892617449665 -0.0297172306113571
59.109 37.98993288590604 -0.03235387050422811
59.109 37.99093959731544 -0.03494058442228527
59.109 37.991946308724835 -0.03747504223842686
59.109 37.99295302013423 -0.039955259220199366
59.109 37.993959731543626 -0.04237960148053048
59.109 37.99496644295302 -0.049000332455554935
59.109 37.99597315436242 -0.05129892476181473
59.109 37.99697986577181 -0.05353831900193272
59.109 37.99798657718121 -0.05571819051261996
59.109 37.998993288590604 -0.05783849905039311
59.11 37.85 0.45535062304660434
59.11 37.8510067114094 0.44189794551750716
59.11 37.85201342281879 0.45376906807993317
59.11 37.85302013422819 0.451630403586091
59.11 37.854026845637584 0.44927314077894026
59.11 37.85503355704698 0.44661848943732874
59.11 37.856040268456375 0.44356311679165206
59.11 37.85704697986577 0.43997576647902287
59.11 37.85805369127517 0.43569730217847824
59.11 37.85906040268456 0.43054796140620755
59.11 37.86006711409396 0.4243468313009532
59.11 37.861073825503354 0.4169480132022735
59.11 37.86208053691275 0.40829315391546755
59.11 37.86308724832215 0.3984692654969139
59.11 37.86409395973155 0.3877471667249589
59.11 37.865100671140944 0.3765706440485996
59.11 37.86610738255034 0.3654825518568659
59.11 37.867114093959735 0.3550090249724033
59.11 37.86812080536913 0.34555091474199295
59.11 37.869127516778526 0.33732657175021463
59.11 37.87013422818792 0.33037700576646273
59.11 37.87114093959732 0.324612816513262
59.11 37.87214765100671 0.3198726578382718
59.11 37.87315436241611 0.31597198071144744
59.11 37.874161073825505 0.3127344335413562
59.11 37.8751677852349 0.30165250792989673
59.11 37.876174496644296 0.2992002077154158
59.11 37.87718120805369 0.29703660871332266
59.11 37.87818791946309 0.29508858020812184
59.11 37.87919463087248 0.2933018748054743
59.11 37.88020134228188 0.2916368533433505
59.11 37.881208053691275 0.29249693134539506
59.11 37.88221476510067 0.2910396175836359
59.11 37.883221476510066 0.28964104034669863
59.11 37.88422818791946 0.28829103172468706
59.11 37.88523489932886 0.2802325000295937
59.11 37.88624161073825 0.27891454822392536
59.11 37.88724832214765 0.27763005343889974
59.11 37.88825503355705 0.27637699963911755
59.11 37.88926174496645 0.27515420429782916
59.11 37.89026845637584 0.2739610848663825
59.11 37.89127516778524 0.2727974916969404
59.11 37.892281879194634 0.27166358821964554
59.11 37.89328859060403 0.2705597644946911
59.11 37.894295302013425 0.26948657411799537
59.11 37.89530201342282 0.2684446872347741
59.11 37.89630872483222 0.2674348543972904
59.11 37.89731543624161 0.26036650794077454
59.11 37.89832214765101 0.2593343520685866
59.11 37.899328859060404 0.2583356575896392
59.11 37.9003355704698 0.25737121686471426
59.11 37.901342281879195 0.2564417746890965
59.11 37.90234899328859 0.24635214377823644
59.11 37.90335570469799 0.2453431654606218
59.11 37.90436241610738 0.24436881339500988
59.11 37.90536912751678 0.24342926842707358
59.11 37.906375838926174 0.24252445627805416
59.11 37.90738255033557 0.24165397697624985
59.11 37.908389261744965 0.2408170163453882
59.11 37.90939597315436 0.24001223305446373
59.11 37.910402684563756 0.2392376116438438
59.11 37.91140939597315 0.23849026716331173
59.11 37.91241610738255 0.23776617968368546
59.11 37.91342281879195 0.23705982563728697
59.11 37.914429530201346 0.23636365581559052
59.11 37.91543624161074 0.23566734446382587
59.11 37.91644295302014 0.23495669769922087
59.11 37.91744966442953 0.2342120614236442
59.11 37.91845637583893 0.23340601436083142
59.11 37.919463087248324 0.2325000946935231
59.11 37.92046979865772 0.23144035845863786
59.11 37.921476510067116 0.2301518703344105
59.11 37.92248322147651 0.22853310801124488
59.11 37.92348993288591 0.2264531469966754
59.11 37.9244966442953 0.22375716127943734
59.11 37.9255033557047 0.22028520969159746
59.11 37.926510067114094 0.2158890128750053
59.11 37.92751677852349 0.21035020874490348
59.11 37.928523489932886 0.20295104128930044
59.11 37.92953020134228 0.19199384030647196
59.11 37.93053691275168 0.1781980990136065
59.11 37.93154362416107 0.16805402154939228
59.11 37.93255033557047 0.16288897705137365
59.11 37.933557046979864 0.15976784989824178
59.11 37.93456375838926 0.15713302867322923
59.11 37.935570469798655 0.15451782464939037
59.11 37.93657718120805 0.15180546152772603
59.11 37.937583892617454 0.1489771607337882
59.11 37.93859060402685 0.14604307402350308
59.11 37.939597315436245 0.14302279111808547
59.11 37.94060402684564 0.1399389631258659
59.11 37.94161073825504 0.13681459916574812
59.11 37.94261744966443 0.13367154393061825
59.11 37.94362416107383 0.1305294699561504
59.11 37.94463087248322 0.1274052069192464
59.11 37.94563758389262 0.12431235254137465
59.11 37.946644295302015 0.12126112991098462
59.11 37.94765100671141 0.11825845124973028
59.11 37.948657718120806 0.11530814159168781
59.11 37.9496644295302 0.11241127384150448
59.11 37.9506711409396 0.1095665697362025
59.11 37.95167785234899 0.10677082792160042
59.11 37.95268456375839 0.10401934884047828
59.11 37.953691275167785 0.10130633483440915
59.11 37.95469798657718 0.0986252516756669
59.11 37.955704697986576 0.09596914401175678
59.11 37.95671140939597 0.09333090158956717
59.11 37.95771812080537 0.09070347541173712
59.11 37.95872483221476 0.08808004279503265
59.11 37.95973154362416 0.08545411673653523
59.11 37.960738255033554 0.08281958591690755
59.11 37.96174496644295 0.08017065248010025
59.11 37.96275167785235 0.07750159596759346
59.11 37.96375838926175 0.07480621468208812
59.11 37.964765100671144 0.07207664470921418
59.11 37.96577181208054 0.060585241657783694
59.11 37.966778523489936 0.057846597962766394
59.11 37.96778523489933 0.05500218654885995
59.11 37.96879194630873 0.051981107225289175
59.11 37.96979865771812 0.048658799721183206
59.11 37.97080536912752 0.044845432271281904
59.11 37.971812080536914 0.04033592780744946
59.11 37.97281879194631 0.03509286175822261
59.11 37.973825503355705 0.029465436815385285
59.11 37.9748322147651 0.024071279291877495
59.11 37.9758389261745 0.019333644500872327
59.11 37.97684563758389 0.015276619057810553
59.11 37.97785234899329 0.011712261004231103
59.11 37.978859060402684 0.008445833800840893
59.11 37.97986577181208 0.0022177377272721427
59.11 37.980872483221475 -0.0007751283043839902
59.11 37.98187919463087 -0.003712795886774367
59.11 37.982885906040266 -0.011298329875652185
59.11 37.98389261744966 -0.014128733336162158
59.11 37.98489932885906 -0.0169279824249461
59.11 37.98590604026845 -0.019921192882628447
59.11 37.98691275167785 -0.021722952743644164
59.11 37.98791946308725 -0.024485248721598143
59.11 37.98892617449665 -0.02720439864630246
59.11 37.98993288590604 -0.029877418840695336
59.11 37.99093959731544 -0.03250158477126454
59.11 37.991946308724835 -0.03507448173253487
59.11 37.99295302013423 -0.037594028877840066
59.11 37.993959731543626 -0.04005848763811701
59.11 37.99496644295302 -0.04669644807999966
59.11 37.99597315436242 -0.04903695667092445
59.11 37.99697986577181 -0.05131870382325808
59.11 37.99798657718121 -0.053541248480331205
59.11 37.998993288590604 -0.05570443564412962
59.111000000000004 37.85 0.47240159842700974
59.111000000000004 37.8510067114094 0.4707547349934179
59.111000000000004 37.85201342281879 0.4575536142239338
59.111000000000004 37.85302013422819 0.45570503769834225
59.111000000000004 37.854026845637584 0.45375039492413766
59.111000000000004 37.85503355704698 0.451650737237132
59.111000000000004 37.856040268456375 0.4493527534564463
59.111000000000004 37.85704697986577 0.4467845696512384
59.111000000000004 37.85805369127517 0.4438512408199674
59.111000000000004 37.85906040268456 0.4404308681006515
59.111000000000004 37.86006711409396 0.43637324339239036
59.111000000000004 37.861073825503354 0.431504291382003
59.111000000000004 37.86208053691275 0.4256409761390836
59.111000000000004 37.86308724832215 0.4186215931542667
59.111000000000004 37.86409395973155 0.41035326913086845
59.111000000000004 37.865100671140944 0.4008697689589881
59.111000000000004 37.86610738255034 0.39037936851076593
59.111000000000004 37.867114093959735 0.3792730570738368
59.111000000000004 37.86812080536913 0.3680714411328387
59.111000000000004 37.869127516778526 0.35731895733250263
59.111000000000004 37.87013422818792 0.3474675724199889
59.111000000000004 37.87114093959732 0.3387994704015388
59.111000000000004 37.87214765100671 0.33141219667345984
59.111000000000004 37.87315436241611 0.32525456424127785
59.111000000000004 37.874161073825505 0.3201839520499081
59.111000000000004 37.8751677852349 0.3076009467091949
59.111000000000004 37.876174496644296 0.3040484361211062
59.111000000000004 37.87718120805369 0.30105781962320743
59.111000000000004 37.87818791946309 0.29849527050192054
59.111000000000004 37.87919463087248 0.29625514902665834
59.111000000000004 37.88020134228188 0.2966235299574281
59.111000000000004 37.881208053691275 0.29484772880622956
59.111000000000004 37.88221476510067 0.2932099273420913
59.111000000000004 37.883221476510066 0.28481867094113145
59.111000000000004 37.88422818791946 0.2833226184916061
59.111000000000004 37.88523489932886 0.2818934575266419
59.111000000000004 37.88624161073825 0.28051988106166137
59.111000000000004 37.88724832214765 0.2791941083005587
59.111000000000004 37.88825503355705 0.27791090186952433
59.111000000000004 37.88926174496645 0.27666685500078336
59.111000000000004 37.89026845637584 0.27545987727566906
59.111000000000004 37.89127516778524 0.2742888247271818
59.111000000000004 37.892281879194634 0.27315323396562463
59.111000000000004 37.89328859060403 0.27205313068658654
59.111000000000004 37.894295302013425 0.2709888909454536
59.111000000000004 37.89530201342282 0.2699611394926261
59.111000000000004 37.89630872483222 0.2628941209741955
59.111000000000004 37.89731543624161 0.2618530124612314
59.111000000000004 37.89832214765101 0.2608500281871641
59.111000000000004 37.899328859060404 0.25988615406451576
59.111000000000004 37.9003355704698 0.25896236026495106
59.111000000000004 37.901342281879195 0.24901806151887304
59.111000000000004 37.90234899328859 0.24802754405031655
59.111000000000004 37.90335570469799 0.2470775359416402
59.111000000000004 37.90436241610738 0.24616847060341224
59.111000000000004 37.90536912751678 0.24530050145907661
59.111000000000004 37.906375838926174 0.24447341119297858
59.111000000000004 37.90738255033557 0.243686494437361
59.111000000000004 37.908389261744965 0.24293840271795467
59.111000000000004 37.90939597315436 0.2422269350732987
59.111000000000004 37.910402684563756 0.24154874947726235
59.111000000000004 37.91140939597315 0.24089895761685684
59.111000000000004 37.91241610738255 0.24027054677997334
59.111000000000004 37.91342281879195 0.23965354524911983
59.111000000000004 37.914429530201346 0.2390338096174752
59.111000000000004 37.91543624161074 0.2383912643566445
59.111000000000004 37.91644295302014 0.23769737506911318
59.111000000000004 37.91744966442953 0.23691162019922232
59.111000000000004 37.91845637583893 0.23597683065113398
59.111000000000004 37.919463087248324 0.23481369710646094
59.111000000000004 37.92046979865772 0.23331589488054172
59.111000000000004 37.921476510067116 0.2313497029596673
59.111000000000004 37.92248322147651 0.22876587093747536
59.111000000000004 37.92348993288591 0.22543469329094518
59.111000000000004 37.9244966442953 0.22131003952229564
59.111000000000004 37.9255033557047 0.21650184600070235
59.111000000000004 37.926510067114094 0.2112891560231916
59.111000000000004 37.92751677852349 0.205971467113334
59.111000000000004 37.928523489932886 0.20046650507309416
59.111000000000004 37.92953020134228 0.19373830056100524
59.111000000000004 37.93053691275168 0.18461726258266903
59.111000000000004 37.93154362416107 0.17555666884742543
59.111000000000004 37.93255033557047 0.16965588411373586
59.111000000000004 37.933557046979864 0.1660218412780243
59.111000000000004 37.93456375838926 0.16317855848927396
59.111000000000004 37.935570469798655 0.16047627679666687
59.111000000000004 37.93657718120805 0.15770697069834988
59.111000000000004 37.937583892617454 0.15481835684327533
59.111000000000004 37.93859060402685 0.151809997286815
59.111000000000004 37.939597315436245 0.14869932366586638
59.111000000000004 37.94060402684564 0.14550997432437707
59.111000000000004 37.94161073825504 0.1422671630379636
59.111000000000004 37.94261744966443 0.13899540064545296
59.111000000000004 37.94362416107383 0.13571714489548026
59.111000000000004 37.94463087248322 0.13245194404951893
59.111000000000004 37.94563758389262 0.12921593275129972
59.111000000000004 37.946644295302015 0.12602161571530318
59.111000000000004 37.94765100671141 0.12287788804894645
59.111000000000004 37.948657718120806 0.1197902396174535
59.111000000000004 37.9496644295302 0.11676109002701643
59.111000000000004 37.9506711409396 0.11379020408992058
59.111000000000004 37.95167785234899 0.11087514456472014
59.111000000000004 37.95268456375839 0.10801172791894614
59.111000000000004 37.953691275167785 0.10519445822070944
59.111000000000004 37.95469798657718 0.10241692281915313
59.111000000000004 37.955704697986576 0.09967214047226058
59.111000000000004 37.95671140939597 0.09695285763174184
59.111000000000004 37.95771812080537 0.09425179145946261
59.111000000000004 37.95872483221476 0.09156181847293657
59.111000000000004 37.95973154362416 0.08887610464010637
59.111000000000004 37.960738255033554 0.08618816424332441
59.111000000000004 37.96174496644295 0.08349181650874694
59.111000000000004 37.96275167785235 0.08078097175729754
59.111000000000004 37.96375838926175 0.07804910455226888
59.111000000000004 37.964765100671144 0.07528812542273647
59.111000000000004 37.96577181208054 0.07248608466699225
59.111000000000004 37.966778523489936 0.06099990636625571
59.111000000000004 37.96778523489933 0.058141002063706065
59.111000000000004 37.96879194630873 0.05511359022316856
59.111000000000004 37.96979865771812 0.05179572310918888
59.111000000000004 37.97080536912752 0.04799867405827074
59.111000000000004 37.971812080536914 0.043509946981377806
59.111000000000004 37.97281879194631 0.03826809736467145
59.111000000000004 37.973825503355705 0.032593866509955785
59.111000000000004 37.9748322147651 0.027107475052300142
59.111000000000004 37.9758389261745 0.022267065521291138
59.111000000000004 37.97684563758389 0.01812480511560098
59.111000000000004 37.97785234899329 0.014498286532808657
59.111000000000004 37.978859060402684 0.011186852727053123
59.111000000000004 37.97986577181208 0.008051793631125598
59.111000000000004 37.980872483221475 0.0019231667329882435
59.111000000000004 37.98187919463087 -0.001042398753266116
59.111000000000004 37.982885906040266 -0.008606431308573734
59.111000000000004 37.98389261744966 -0.011465089319098953
59.111000000000004 37.98489932885906 -0.014460123254933621
59.111000000000004 37.98590604026845 -0.017282561412744535
59.111000000000004 37.98691275167785 -0.020070204310681863
59.111000000000004 37.98791946308725 -0.021876496801358766
59.111000000000004 37.98892617449665 -0.024629657658337828
59.111000000000004 37.98993288590604 -0.027337883405795585
59.111000000000004 37.99093959731544 -0.029998394574811627
59.111000000000004 37.991946308724835 -0.03260870387355187
59.111000000000004 37.99295302013423 -0.03516664425109302
59.111000000000004 37.993959731543626 -0.037670380485155655
59.111000000000004 37.99496644295302 -0.04011841044025252
59.111000000000004 37.99597315436242 -0.04670611555059358
59.111000000000004 37.99697986577181 -0.04902970065693439
59.111000000000004 37.99798657718121 -0.051294517299728754
59.111000000000004 37.998993288590604 -0.05350029608347902
59.112 37.85 0.47522448519733307
59.112 37.8510067114094 0.4736414110557338
59.112 37.85201342281879 0.47202697358397505
59.112 37.85302013422819 0.47037211229665155
59.112 37.854026845637584 0.45734550347927294
59.112 37.85503355704698 0.45550001916996086
59.112 37.856040268456375 0.45355671712049483
59.112 37.85704697986577 0.4514799121332424
59.112 37.85805369127517 0.449220640909086
59.112 37.85906040268456 0.44671265988080316
59.112 37.86006711409396 0.44386800435749424
59.112 37.861073825503354 0.4405728434837291
59.112 37.86208053691275 0.43668520400862215
59.112 37.86308724832215 0.4320373863283565
59.112 37.86409395973155 0.4264473342908347
59.112 37.865100671140944 0.41974395983559054
59.112 37.86610738255034 0.41180956908353494
59.112 37.867114093959735 0.4026356619900613
59.112 37.86812080536913 0.39237608527684187
59.112 37.869127516778526 0.3813697456391876
59.112 37.87013422818792 0.3701067423198535
59.112 37.87114093959732 0.35913590860351596
59.112 37.87214765100671 0.3489469913164252
59.112 37.87315436241611 0.3398780164470645
59.112 37.874161073825505 0.3320813017734109
59.112 37.8751677852349 0.31706607703302153
59.112 37.876174496644296 0.3115586649220985
59.112 37.87718120805369 0.3070183639038427
59.112 37.87818791946309 0.3032613669593326
59.112 37.87919463087248 0.3024211662578926
59.112 37.88020134228188 0.299790127674177
59.112 37.881208053691275 0.2905496790821406
59.112 37.88221476510067 0.2884976859963631
59.112 37.883221476510066 0.28664927353991676
59.112 37.88422818791946 0.28495571431077693
59.112 37.88523489932886 0.28338165636531487
59.112 37.88624161073825 0.2819017837333904
59.112 37.88724832214765 0.2804981954974053
59.112 37.88825503355705 0.2791584122320132
59.112 37.88926174496645 0.2778738903565148
59.112 37.89026845637584 0.27663892989991234
59.112 37.89127516778524 0.2754498785293906
59.112 37.892281879194634 0.27430455446795127
59.112 37.89328859060403 0.27320182891043254
59.112 37.894295302013425 0.27214132336769503
59.112 37.89530201342282 0.2650635716740529
59.112 37.89630872483222 0.26399954067858883
59.112 37.89731543624161 0.26297811156422685
59.112 37.89832214765101 0.26200017607831433
59.112 37.899328859060404 0.2610666900829161
59.112 37.9003355704698 0.26017860179362456
59.112 37.901342281879195 0.2502626191087596
59.112 37.90234899328859 0.2493182170228317
59.112 37.90335570469799 0.2484193174358939
59.112 37.90436241610738 0.2475660334597291
59.112 37.90536912751678 0.24675800724120542
59.112 37.906375838926174 0.24599423977466944
59.112 37.90738255033557 0.24527286116514987
59.112 37.908389261744965 0.24459081336378605
59.112 37.90939597315436 0.24394340389563504
59.112 37.910402684563756 0.2433236692105503
59.112 37.91140939597315 0.24272145801305703
59.112 37.91241610738255 0.24212210717550664
59.112 37.91342281879195 0.24150453851517387
59.112 37.914429530201346 0.2408385685252448
59.112 37.91543624161074 0.2400812393952278
59.112 37.91644295302014 0.239172158305992
59.112 37.91744966442953 0.23802840829437333
59.112 37.91845637583893 0.23654098256480105
59.112 37.919463087248324 0.2345773745008456
59.112 37.92046979865772 0.23199871031706532
59.112 37.921476510067116 0.2287016210899044
59.112 37.92248322147651 0.22468664480758932
59.112 37.92348993288591 0.2201275272623916
59.112 37.9244966442953 0.21538231483972325
59.112 37.9255033557047 0.21089785920629264
59.112 37.926510067114094 0.20703934208446184
59.112 37.92751677852349 0.2039329581324403
59.112 37.928523489932886 0.20131997268936103
59.112 37.92953020134228 0.19824563383188132
59.112 37.93053691275168 0.19283897966389668
59.112 37.93154362416107 0.1846706194814651
59.112 37.93255033557047 0.17726960796628854
59.112 37.933557046979864 0.1724433366708819
59.112 37.93456375838926 0.16909245032395973
59.112 37.935570469798655 0.16620875253749268
59.112 37.93657718120805 0.1633696982323828
59.112 37.937583892617454 0.16043831088429736
59.112 37.93859060402685 0.15738421194749191
59.112 37.939597315436245 0.1542151992629303
59.112 37.94060402684564 0.1509527536466313
59.112 37.94161073825504 0.14762278986301486
59.112 37.94261744966443 0.1442516724385744
59.112 37.94362416107383 0.14086418315637889
59.112 37.94463087248322 0.13748234918486013
59.112 37.94563758389262 0.13412476408528126
59.112 37.946644295302015 0.13080626010407828
59.112 37.94765100671141 0.12753785430597084
59.112 37.948657718120806 0.12432690622618695
59.112 37.9496644295302 0.12117742859908562
59.112 37.9506711409396 0.1180904969565065
59.112 37.95167785234899 0.11506471085434593
59.112 37.95268456375839 0.11209666850075198
59.112 37.953691275167785 0.10918142622681529
59.112 37.95469798657718 0.10631292332171483
59.112 37.955704697986576 0.10348436040118897
59.112 37.95671140939597 0.10068852520593555
59.112 37.95771812080537 0.09791806323511527
59.112 37.95872483221476 0.09516569153325166
59.112 37.95973154362416 0.09242435142992195
59.112 37.960738255033554 0.0896872881317336
59.112 37.96174496644295 0.08694802753289146
59.112 37.96275167785235 0.08420018461891347
59.112 37.96375838926175 0.08143696571515906
59.112 37.964765100671144 0.07865008465321678
59.112 37.96577181208054 0.0758275405458828
59.112 37.966778523489936 0.07294921552424972
59.112 37.96778523489933 0.06144974994841635
59.112 37.96879194630873 0.05842145838420065
59.112 37.96979865771812 0.05511176829528234
59.112 37.97080536912752 0.05133133944189165
59.112 37.971812080536914 0.046858214602629
59.112 37.97281879194631 0.04160529235351253
59.112 37.973825503355705 0.03586439869846397
59.112 37.9748322147651 0.030260761669040973
59.112 37.9758389261745 0.0252932885462665
59.112 37.97684563758389 0.02104722926224943
59.112 37.97785234899329 0.01734670858972341
59.112 37.978859060402684 0.013983660248432547
59.112 37.97986577181208 0.010810478151921932
59.112 37.980872483221475 0.004673195389818016
59.112 37.98187919463087 0.0016797140550177947
59.112 37.982885906040266 -0.005944799431614731
59.112 37.98389261744966 -0.008857047153247768
59.112 37.98489932885906 -0.01173904545893903
59.112 37.98590604026845 -0.014589790792019275
59.112 37.98691275167785 -0.01740687043843958
59.112 37.98791946308725 -0.019210952248535668
59.112 37.98892617449665 -0.021996630058671682
59.112 37.98993288590604 -0.024738606330110754
59.112 37.99093959731544 -0.027434065718643943
59.112 37.991946308724835 -0.030080463929410994
59.112 37.99295302013423 -0.03267556053120077
59.112 37.993959731543626 -0.03521743412627186
59.112 37.99496644295302 -0.03770448654285645
59.112 37.99597315436242 -0.04013543965480393
59.112 37.99697986577181 -0.046672602478371475
59.112 37.99798657718121 -0.04897900297314793
59.112 37.998993288590604 -0.05122680665893889
59.113 37.85 0.4778767948239856
59.113 37.8510067114094 0.4763204142128711
59.113 37.85201342281879 0.47474358116750276
59.113 37.85302013422819 0.4731423840987471
59.113 37.854026845637584 0.47151102774873976
59.113 37.85503355704698 0.45876466950336636
59.113 37.856040268456375 0.456980101651542
59.113 37.85704697986577 0.455127442487769
59.113 37.85805369127517 0.4531827119955216
59.113 37.85906040268456 0.45111260623347427
59.113 37.86006711409396 0.4488713734350724
59.113 37.861073825503354 0.446396994103895
59.113 37.86208053691275 0.4436068512814126
59.113 37.86308724832215 0.4403934883193787
59.113 37.86409395973155 0.4366217878365015
59.113 37.865100671140944 0.4321300443403164
59.113 37.86610738255034 0.4267388113513708
59.113 37.867114093959735 0.4202724012104171
59.113 37.86812080536913 0.4125968897373767
59.113 37.869127516778526 0.40367306262302954
59.113 37.87013422818792 0.3936117397417803
59.113 37.87114093959732 0.38270631088256607
59.113 37.87214765100671 0.3714144942485738
59.113 37.87315436241611 0.36027959445920754
59.113 37.874161073825505 0.34138990301717664
59.113 37.8751677852349 0.3318655600625445
59.113 37.876174496644296 0.3235936652167478
59.113 37.87718120805369 0.3166026324466395
59.113 37.87818791946309 0.3107965747892431
59.113 37.87919463087248 0.3012170908850906
59.113 37.88020134228188 0.29726082722373964
59.113 37.881208053691275 0.2939690322451089
59.113 37.88221476510067 0.29119144178736434
59.113 37.883221476510066 0.2888061649380793
59.113 37.88422818791946 0.2867183251056877
59.113 37.88523489932886 0.2848561923641782
59.113 37.88624161073825 0.2831666879621878
59.113 37.88724832214765 0.28161118918530426
59.113 37.88825503355705 0.28016199370017647
59.113 37.88926174496645 0.27879950601097514
59.113 37.89026845637584 0.27751007631078395
59.113 37.89127516778524 0.27628437758236246
59.113 37.892281879194634 0.275116204366066
59.113 37.89328859060403 0.2740015912040361
59.113 37.894295302013425 0.2668975485599048
59.113 37.89530201342282 0.265795368316849
59.113 37.89630872483222 0.2647416138369629
59.113 37.89731543624161 0.2637363109663895
59.113 37.89832214765101 0.2627797929996176
59.113 37.899328859060404 0.26187253493628065
59.113 37.9003355704698 0.2610150085331187
59.113 37.901342281879195 0.25112312173041734
59.113 37.90234899328859 0.2502161946859114
59.113 37.90335570469799 0.24935699456329358
59.113 37.90436241610738 0.24854441192449875
59.113 37.90536912751678 0.24777640140070142
59.113 37.906375838926174 0.24704961835875727
59.113 37.90738255033557 0.2463589162773143
59.113 37.908389261744965 0.2456966396627418
59.113 37.90939597315436 0.24505162017248253
59.113 37.910402684563756 0.24440774966018974
59.113 37.91140939597315 0.24374196961622416
59.113 37.91241610738255 0.2430215038610099
59.113 37.91342281879195 0.24220022789426415
59.113 37.914429530201346 0.241214344576752
59.113 37.91543624161074 0.23997827724476103
59.113 37.91644295302014 0.23838329087757507
59.113 37.91744966442953 0.23630410138891195
59.113 37.91845637583893 0.23362183572478173
59.113 37.919463087248324 0.22598814195787686
59.113 37.92046979865772 0.2220361731389012
59.113 37.921476510067116 0.21769877762924855
59.113 37.92248322147651 0.21335941955127657
59.113 37.92348993288591 0.20944454864227163
59.113 37.9244966442953 0.20627483156096035
59.113 37.9255033557047 0.2039812254625079
59.113 37.926510067114094 0.20252135445813566
59.113 37.92751677852349 0.2017369549472569
59.113 37.928523489932886 0.20134494172865994
59.113 37.92953020134228 0.20070275970457338
59.113 37.93053691275168 0.19822074655811983
59.113 37.93154362416107 0.19175416473398768
59.113 37.93255033557047 0.18280309435913888
59.113 37.933557046979864 0.17574609812996073
59.113 37.93456375838926 0.1712125263247663
59.113 37.935570469798655 0.16788143749959367
59.113 37.93657718120805 0.1648996175645175
59.113 37.937583892617454 0.16192848516904568
59.113 37.93859060402685 0.1628451121380986
59.113 37.939597315436245 0.15964080013759266
59.113 37.94060402684564 0.15633151958280822
59.113 37.94161073825504 0.15294094492189625
59.113 37.94261744966443 0.14949595363164622
59.113 37.94362416107383 0.14602298788324197
59.113 37.94463087248322 0.14254622447480741
59.113 37.94563758389262 0.13908658537777552
59.113 37.946644295302015 0.13566123556055773
59.113 37.94765100671141 0.13228341698890753
59.113 37.948657718120806 0.12896253100213045
59.113 37.9496644295302 0.12570440104212058
59.113 37.9506711409396 0.12251165652819634
59.113 37.95167785234899 0.11938418657182204
59.113 37.95268456375839 0.11631962129543119
59.113 37.953691275167785 0.11331380827240142
59.113 37.95469798657718 0.11036126101462554
59.113 37.955704697986576 0.10745556461921407
59.113 37.95671140939597 0.10458973006504395
59.113 37.95771812080537 0.10175649283477096
59.113 37.95872483221476 0.09894855309760756
59.113 37.95973154362416 0.09615875277155733
59.113 37.960738255033554 0.09338017746897714
59.113 37.96174496644295 0.09060615443561229
59.113 37.96275167785235 0.08783008241579329
59.113 37.96375838926175 0.08504495839600634
59.113 37.964765100671144 0.08224232552131022
59.113 37.96577181208054 0.07941009528682677
59.113 37.966778523489936 0.07652820447804612
59.113 37.96778523489933 0.06502368948057219
59.113 37.96879194630873 0.062005078255111395
59.113 37.96979865771812 0.058711505747257725
59.113 37.97080536912752 0.05494996083256114
59.113 37.971812080536914 0.05048479642251201
59.113 37.97281879194631 0.045199162309694355
59.113 37.973825503355705 0.03935485932843151
59.113 37.9748322147651 0.03358764438639028
59.113 37.9758389261745 0.028448452665185434
59.113 37.97684563758389 0.024064346554614882
59.113 37.97785234899329 0.020267538093278234
59.113 37.978859060402684 0.016839875178597544
59.113 37.97986577181208 0.013621608338289196
59.113 37.980872483221475 0.007472657427143578
59.113 37.98187919463087 0.0046593025786503955
59.113 37.982885906040266 0.0016499484831938999
59.113 37.98389261744966 -0.006061582664553297
59.113 37.98489932885906 -0.008969772565143252
59.113 37.98590604026845 -0.011847400291527727
59.113 37.98691275167785 -0.01469234517134596
59.113 37.98791946308725 -0.017501785046799334
59.113 37.98892617449665 -0.019309406536857115
59.113 37.98993288590604 -0.022083448945602407
59.113 37.99093959731544 -0.024812206841359943
59.113 37.991946308724835 -0.027493100753761587
59.113 37.99295302013423 -0.030123834559600438
59.113 37.993959731543626 -0.032702415090869213
59.113 37.99496644295302 -0.03522715979135527
59.113 37.99597315436242 -0.03769669642809907
59.113 37.99697986577181 -0.04424931318665186
59.113 37.99798657718121 -0.046596313595961114
59.113 37.998993288590604 -0.04525075956151531
59.114000000000004 37.85 0.4804143462031435
59.114000000000004 37.8510067114094 0.4788685711273771
59.114000000000004 37.85201342281879 0.4773063340601662
59.114000000000004 37.85302013422819 0.47572612198364894
59.114000000000004 37.854026845637584 0.47412550128659464
59.114000000000004 37.85503355704698 0.47250074461701697
59.114000000000004 37.856040268456375 0.46000870041686504
59.114000000000004 37.85704697986577 0.458254024765749
59.114000000000004 37.85805369127517 0.4564497403990055
59.114000000000004 37.85906040268456 0.4545794818619391
59.114000000000004 37.86006711409396 0.45262030163303846
59.114000000000004 37.861073825503354 0.4505403381273114
59.114000000000004 37.86208053691275 0.4482958256251369
59.114000000000004 37.86308724832215 0.4458274189631489
59.114000000000004 37.86409395973155 0.44305598196146095
59.114000000000004 37.865100671140944 0.4398783552387159
59.114000000000004 37.86610738255034 0.4361642848268087
59.114000000000004 37.867114093959735 0.4317567432277809
59.114000000000004 37.86812080536913 0.4264792254891414
59.114000000000004 37.869127516778526 0.42015470223790086
59.114000000000004 37.87013422818792 0.41264035794606124
59.114000000000004 37.87114093959732 0.4038778092205786
59.114000000000004 37.87214765100671 0.3939486298114798
59.114000000000004 37.87315436241611 0.3831124316612482
59.114000000000004 37.874161073825505 0.36331750065064483
59.114000000000004 37.8751677852349 0.35194620653863623
59.114000000000004 37.876174496644296 0.34115661137959574
59.114000000000004 37.87718120805369 0.3239521262974377
59.114000000000004 37.87818791946309 0.3177840356366937
59.114000000000004 37.87919463087248 0.3105009176572038
59.114000000000004 37.88020134228188 0.3044370244734434
59.114000000000004 37.881208053691275 0.2994399311307408
59.114000000000004 37.88221476510067 0.2953281282984661
59.114000000000004 37.883221476510066 0.2919242111986571
59.114000000000004 37.88422818791946 0.2890723772062207
59.114000000000004 37.88523489932886 0.28664464952974306
59.114000000000004 37.88624161073825 0.28454052119478507
59.114000000000004 37.88724832214765 0.28268350818217336
59.114000000000004 37.88825503355705 0.28101673786477915
59.114000000000004 37.88926174496645 0.27949867152475466
59.114000000000004 37.89026845637584 0.2780994215172418
59.114000000000004 37.89127516778524 0.27679777871937095
59.114000000000004 37.892281879194634 0.275578904241821
59.114000000000004 37.89328859060403 0.27443257895425527
59.114000000000004 37.894295302013425 0.2672436926315527
59.114000000000004 37.89530201342282 0.2661344477336779
59.114000000000004 37.89630872483222 0.26508225072895775
59.114000000000004 37.89731543624161 0.2640851817456303
59.114000000000004 37.89832214765101 0.26314195023945397
59.114000000000004 37.899328859060404 0.2622515458470578
59.114000000000004 37.9003355704698 0.2614129325802976
59.114000000000004 37.901342281879195 0.2515324465448124
59.114000000000004 37.90234899328859 0.2506431998487127
59.114000000000004 37.90335570469799 0.24979746939564046
59.114000000000004 37.90436241610738 0.24899079956130174
59.114000000000004 37.90536912751678 0.2482166937645425
59.114000000000004 37.906375838926174 0.24746581341978957
59.114000000000004 37.90738255033557 0.24672487846414143
59.114000000000004 37.908389261744965 0.2414042643515662
59.114000000000004 37.90939597315436 0.24062799001299962
59.114000000000004 37.910402684563756 0.23978063283690507
59.114000000000004 37.91140939597315 0.23881223141216737
59.114000000000004 37.91241610738255 0.2376558182123435
59.114000000000004 37.91342281879195 0.2362250755602103
59.114000000000004 37.914429530201346 0.2344161806370669
59.114000000000004 37.91543624161074 0.23211938182679515
59.114000000000004 37.91644295302014 0.2292476635541639
59.114000000000004 37.91744966442953 0.22578634025630312
59.114000000000004 37.91845637583893 0.22185167790154967
59.114000000000004 37.919463087248324 0.2177205048845534
59.114000000000004 37.92046979865772 0.2137840788999829
59.114000000000004 37.921476510067116 0.2104249227922574
59.114000000000004 37.92248322147651 0.20788940772802664
59.114000000000004 37.92348993288591 0.20624016819183405
59.114000000000004 37.9244966442953 0.20539966250272151
59.114000000000004 37.9255033557047 0.20523037425604823
59.114000000000004 37.926510067114094 0.20559720607673881
59.114000000000004 37.92751677852349 0.20638744538734505
59.114000000000004 37.928523489932886 0.20746804231860208
59.114000000000004 37.92953020134228 0.20850038181861427
59.114000000000004 37.93053691275168 0.20840348322920504
59.114000000000004 37.93154362416107 0.20465990814133586
59.114000000000004 37.93255033557047 0.19560078882274756
59.114000000000004 37.933557046979864 0.18541384922077223
59.114000000000004 37.93456375838926 0.17832813214274068
59.114000000000004 37.935570469798655 0.17381158106167047
59.114000000000004 37.93657718120805 0.1703845558363215
59.114000000000004 37.937583892617454 0.16726178169524275
59.114000000000004 37.93859060402685 0.1641421154565792
59.114000000000004 37.939597315436245 0.16093147691312554
59.114000000000004 37.94060402684564 0.15761501314337234
59.114000000000004 37.94161073825504 0.15420717320630706
59.114000000000004 37.94261744966443 0.1507324946323338
59.114000000000004 37.94362416107383 0.14721783930977533
59.114000000000004 37.94463087248322 0.14368896782809418
59.114000000000004 37.94563758389262 0.14016888534419017
59.114000000000004 37.946644295302015 0.1366770235536909
59.114000000000004 37.94765100671141 0.1332288980835393
59.114000000000004 37.948657718120806 0.12983607903098376
59.114000000000004 37.9496644295302 0.13040961182758884
59.114000000000004 37.9506711409396 0.12712357335992988
59.114000000000004 37.95167785234899 0.12390616513730766
59.114000000000004 37.95268456375839 0.1207562865870139
59.114000000000004 37.953691275167785 0.11767080844183345
59.114000000000004 37.95469798657718 0.11464503864437209
59.114000000000004 37.955704697986576 0.1116731515572626
59.114000000000004 37.95671140939597 0.10874856905610067
59.114000000000004 37.95771812080537 0.10586428693681581
59.114000000000004 37.95872483221476 0.10301314229413355
59.114000000000004 37.95973154362416 0.10018801621579178
59.114000000000004 37.960738255033554 0.09738195932924214
59.114000000000004 37.96174496644295 0.09458821121004898
59.114000000000004 37.96275167785235 0.09180004960239038
59.114000000000004 37.96375838926175 0.08901033397901259
59.114000000000004 37.964765100671144 0.08621046540717449
59.114000000000004 37.96577181208054 0.08338820758160015
59.114000000000004 37.966778523489936 0.08052330402209006
59.114000000000004 37.96778523489933 0.07757900623770321
59.114000000000004 37.96879194630873 0.06604656989896321
59.114000000000004 37.96979865771812 0.06278467983922956
59.114000000000004 37.97080536912752 0.05904835066933387
59.114000000000004 37.971812080536914 0.054580469979112464
59.114000000000004 37.97281879194631 0.04922604493338219
59.114000000000004 37.973825503355705 0.04321381152154542
59.114000000000004 37.9748322147651 0.03719993387089281
59.114000000000004 37.9758389261745 0.031807887868563325
59.114000000000004 37.97684563758389 0.027222506741330352
59.114000000000004 37.97785234899329 0.02328729836050039
59.114000000000004 37.978859060402684 0.019769550244747316
59.114000000000004 37.97986577181208 0.016491684402925783
59.114000000000004 37.980872483221475 0.013731548311220296
59.114000000000004 37.98187919463087 0.007491183102565038
59.114000000000004 37.982885906040266 0.004454144276897373
59.114000000000004 37.98389261744966 -0.0032224374449885823
59.114000000000004 37.98489932885906 -0.006156240406036559
59.114000000000004 37.98590604026845 -0.009059654420113847
59.114000000000004 37.98691275167785 -0.01193104415381123
59.114000000000004 37.98791946308725 -0.014767871024030571
59.114000000000004 37.98892617449665 -0.01657238423119055
59.114000000000004 37.98993288590604 -0.019376681518052538
59.114000000000004 37.99093959731544 -0.02213691247565719
59.114000000000004 37.991946308724835 -0.024850496299616817
59.114000000000004 37.99295302013423 -0.027515107932311197
59.114000000000004 37.993959731543626 -0.030128703896678127
59.114000000000004 37.99496644295302 -0.032689534536547035
59.114000000000004 37.99597315436242 -0.035196147357640845
59.114000000000004 37.99697986577181 -0.03393151960090868
59.114000000000004 37.99798657718121 -0.040535935323055494
59.114000000000004 37.998993288590604 -0.04294077257239273
59.115 37.85 0.48286249042919466
59.115 37.8510067114094 0.4813206627737308
59.115 37.85201342281879 0.4797632116043963
59.115 37.85302013422819 0.4781897522105329
59.115 37.854026845637584 0.4765994436459607
59.115 37.85503355704698 0.47499079553317336
59.115 37.856040268456375 0.47336140050932385
59.115 37.85704697986577 0.47170756433857913
59.115 37.85805369127517 0.4593607903213761
59.115 37.85906040268456 0.45757769407988613
59.115 37.86006711409396 0.45574491793039185
59.115 37.861073825503354 0.4538462645785081
59.115 37.86208053691275 0.4518590524485927
59.115 37.86308724832215 0.4497518357482746
59.115 37.86409395973155 0.44748148571370683
59.115 37.865100671140944 0.44498960567070334
59.115 37.86610738255034 0.4421984193272772
59.115 37.867114093959735 0.4390066182201231
59.115 37.86812080536913 0.43528628314544066
59.115 37.869127516778526 0.43088298915634793
59.115 37.87013422818792 0.42562249606986635
59.115 37.87114093959732 0.41932852189339503
59.115 37.87214765100671 0.4118557137649238
59.115 37.87315436241611 0.403137978249117
59.115 37.874161073825505 0.38470740228994305
59.115 37.8751677852349 0.3662483155427808
59.115 37.876174496644296 0.3547224118163875
59.115 37.87718120805369 0.34319756478334085
59.115 37.87818791946309 0.33464103488791264
59.115 37.87919463087248 0.32465021245484765
59.115 37.88020134228188 0.31587863746686107
59.115 37.881208053691275 0.30840786569085826
59.115 37.88221476510067 0.3021791932522449
59.115 37.883221476510066 0.29704872691381967
59.115 37.88422818791946 0.2928376893904466
59.115 37.88523489932886 0.2893675215422042
59.115 37.88624161073825 0.28647920915073216
59.115 37.88724832214765 0.2840408293141704
59.115 37.88825503355705 0.28194797975690555
59.115 37.88926174496645 0.2801207083694012
59.115 37.89026845637584 0.27849921833489294
59.115 37.89127516778524 0.2770395637242086
59.115 37.892281879194634 0.2757098708836288
59.115 37.89328859060403 0.2684021365524915
59.115 37.894295302013425 0.26718073563393085
59.115 37.89530201342282 0.2660372909599245
59.115 37.89630872483222 0.26496330836651105
59.115 37.89731543624161 0.2639523992409576
59.115 37.89832214765101 0.2629994019500892
59.115 37.899328859060404 0.2620996548563007
59.115 37.9003355704698 0.2522980095273526
59.115 37.901342281879195 0.24653705068636855
59.115 37.90234899328859 0.24562105394793968
59.115 37.90335570469799 0.24473013570999191
59.115 37.90436241610738 0.24385187105354192
59.115 37.90536912751678 0.2429694475671576
59.115 37.906375838926174 0.24206009671818032
59.115 37.90738255033557 0.2410931037227035
59.115 37.908389261744965 0.2400274070619114
59.115 37.90939597315436 0.23880902415625402
59.115 37.910402684563756 0.23736905333771324
59.115 37.91140939597315 0.23562400719509072
59.115 37.91241610738255 0.23348185650173467
59.115 37.91342281879195 0.23085896776290632
59.115 37.914429530201346 0.22771303593627448
59.115 37.91543624161074 0.22409061114607004
59.115 37.91644295302014 0.22017092667705462
59.115 37.91744966442953 0.2162683880412501
59.115 37.91845637583893 0.2127625319856364
59.115 37.919463087248324 0.20997599706158
59.115 37.92046979865772 0.20807551147750528
59.115 37.921476510067116 0.2070566748060662
59.115 37.92248322147651 0.20680241441275565
59.115 37.92348993288591 0.20716068667118034
59.115 37.9244966442953 0.20800008559896466
59.115 37.9255033557047 0.20923418158489418
59.115 37.926510067114094 0.21082257932283507
59.115 37.92751677852349 0.21275527060272334
59.115 37.928523489932886 0.21501067279990865
59.115 37.92953020134228 0.21743285805323867
59.115 37.93053691275168 0.21935769002766756
59.115 37.93154362416107 0.21874894443268794
59.115 37.93255033557047 0.21209787838464
59.115 37.933557046979864 0.19969264579366913
59.115 37.93456375838926 0.18827783174773582
59.115 37.935570469798655 0.18099956557875696
59.115 37.93657718120805 0.17637243520968596
59.115 37.937583892617454 0.17280491399291642
59.115 37.93859060402685 0.1695357057542093
59.115 37.939597315436245 0.16627764529748817
59.115 37.94060402684564 0.1629416343189355
59.115 37.94161073825504 0.15951469850909672
59.115 37.94261744966443 0.1560119551856095
59.115 37.94362416107383 0.1524578552048867
59.115 37.94463087248322 0.1488785717063916
59.115 37.94563758389262 0.14529870716824017
59.115 37.946644295302015 0.14173978833491155
59.115 37.94765100671141 0.13821959902751518
59.115 37.948657718120806 0.13475197303174474
59.115 37.9496644295302 0.13134687430253184
59.115 37.9506711409396 0.12801066603630087
59.115 37.95167785234899 0.12474649988775821
59.115 37.95268456375839 0.12155477225516405
59.115 37.953691275167785 0.1184336061360402
59.115 37.95469798657718 0.11537932723924321
59.115 37.955704697986576 0.11238691207966986
59.115 37.95671140939597 0.11332809068261784
59.115 37.95771812080537 0.11041405136758659
59.115 37.95872483221476 0.10754223641074481
59.115 37.95973154362416 0.10470573108772191
59.115 37.960738255033554 0.10189770393401504
59.115 37.96174496644295 0.09911144700385821
59.115 37.96275167785235 0.0963402402139989
59.115 37.96375838926175 0.09357689910145614
59.115 37.964765100671144 0.09081271554507316
59.115 37.96577181208054 0.08803520693894597
59.115 37.966778523489936 0.08522354031675994
59.115 37.96778523489933 0.08233958837460155
59.115 37.96879194630873 0.07086623465815227
59.115 37.96979865771812 0.06766475339588175
59.115 37.97080536912752 0.0639677134362655
59.115 37.971812080536914 0.059483327117858587
59.115 37.97281879194631 0.05400300440450202
59.115 37.973825503355705 0.04771491465554632
59.115 37.9748322147651 0.041310562387715785
59.115 37.9758389261745 0.03552119617901481
59.115 37.97684563758389 0.030618883827401744
59.115 37.97785234899329 0.026466053488597
59.115 37.978859060402684 0.022808693597429652
59.115 37.97986577181208 0.01986143721536253
59.115 37.980872483221475 0.016627749007607273
59.115 37.98187919463087 0.010374870844791217
59.115 37.982885906040266 0.007304784757981006
59.115 37.98389261744966 -0.00033985263868265003
59.115 37.98489932885906 -0.0033002822534863446
59.115 37.98590604026845 -0.006229434959053884
59.115 37.98691275167785 -0.009126528586031565
59.115 37.98791946308725 -0.011989542097058603
59.115 37.98892617449665 -0.014815917494161251
59.115 37.98993288590604 -0.016622631741083055
59.115 37.99093959731544 -0.019412504166592237
59.115 37.991946308724835 -0.02215688798362664
59.115 37.99295302013423 -0.02485347447197929
59.115 37.993959731543626 -0.027500205378479317
59.115 37.99496644295302 -0.030095292139470944
59.115 37.99597315436242 -0.028957781506926475
59.115 37.99697986577181 -0.03151509144225255
59.115 37.99798657718121 -0.03812466367936061
59.115 37.998993288590604 -0.040565658290416196
59.116 37.85 0.4852326456949094
59.116 37.8510067114094 0.4836923228646217
59.116 37.85201342281879 0.48213577038062294
59.116 37.85302013422819 0.48056314046374965
59.116 37.854026845637584 0.4789743603577196
59.116 37.85503355704698 0.47736902793259084
59.116 37.856040268456375 0.47574626716014146
59.116 37.85704697986577 0.47410452822339433
59.116 37.85805369127517 0.4724413114181294
59.116 37.85906040268456 0.46032377953789927
59.116 37.86006711409396 0.4585442551862527
59.116 37.861073825503354 0.45672518558457353
59.116 37.86208053691275 0.4548547682235294
59.116 37.86308724832215 0.45291634035714434
59.116 37.86409395973155 0.4508866264235759
59.116 37.865100671140944 0.4487334451052064
59.116 37.86610738255034 0.4464127908787421
59.116 37.867114093959735 0.44386527534523756
59.116 37.86812080536913 0.44101208621521937
59.116 37.869127516778526 0.4377509731741471
59.116 37.87013422818792 0.43395339740715083
59.116 37.87114093959732 0.4294649584094628
59.116 37.87214765100671 0.4241124547350428
59.116 37.87315436241611 0.4177219404432148
59.116 37.874161073825505 0.3938904811995678
59.116 37.8751677852349 0.3849034376328748
59.116 37.876174496644296 0.37474437049682663
59.116 37.87718120805369 0.36603722402586847
59.116 37.87818791946309 0.35447427277377536
59.116 37.87919463087248 0.34291604114145186
59.116 37.88020134228188 0.3318899598618928
59.116 37.881208053691275 0.32182378871160394
59.116 37.88221476510067 0.31297915651290237
59.116 37.883221476510066 0.30544221894133167
59.116 37.88422818791946 0.2991590536175825
59.116 37.88523489932886 0.29398938729163027
59.116 37.88624161073825 0.28975637375382435
59.116 37.88724832214765 0.2862818162303334
59.116 37.88825503355705 0.28340591200126203
59.116 37.88926174496645 0.28099524142121646
59.116 37.89026845637584 0.27894353182247805
59.116 37.89127516778524 0.27716878292567576
59.116 37.892281879194634 0.2756090469952122
59.116 37.89328859060403 0.2680687092222135
59.116 37.894295302013425 0.26672187384258206
59.116 37.89530201342282 0.2654827430991434
59.116 37.89630872483222 0.25883417636745043
59.116 37.89731543624161 0.2577507434620122
59.116 37.89832214765101 0.25672646126232096
59.116 37.899328859060404 0.25574865787570367
59.116 37.9003355704698 0.24636229329617237
59.116 37.901342281879195 0.2452981626717218
59.116 37.90234899328859 0.24423266285007392
59.116 37.90335570469799 0.2431433042438525
59.116 37.90436241610738 0.24200146934140687
59.116 37.90536912751678 0.24077060637515932
59.116 37.906375838926174 0.23940441060988396
59.116 37.90738255033557 0.23784547289446636
59.116 37.908389261744965 0.23602547250832467
59.116 37.90939597315436 0.23386897257860584
59.116 37.910402684563756 0.23130412434018444
59.116 37.91140939597315 0.22828425487126913
59.116 37.91241610738255 0.22482210214347764
59.116 37.91342281879195 0.22102991597117536
59.116 37.914429530201346 0.21714352823972866
59.116 37.91543624161074 0.2134986525886879
59.116 37.91644295302014 0.21044595040928166
59.116 37.91744966442953 0.2082401546139587
59.116 37.91845637583893 0.20697097455438138
59.116 37.919463087248324 0.20657376167801256
59.116 37.92046979865772 0.2068964524949303
59.116 37.921476510067116 0.20777240214107015
59.116 37.92248322147651 0.20906749665221355
59.116 37.92348993288591 0.2106982966684488
59.116 37.9244966442953 0.21263146924479057
59.116 37.9255033557047 0.21487457089939158
59.116 37.926510067114094 0.21746306849777847
59.116 37.92751677852349 0.22044265651029885
59.116 37.928523489932886 0.22383725730062737
59.116 37.92953020134228 0.22756949237502658
59.116 37.93053691275168 0.23122872670720718
59.116 37.93154362416107 0.23343681122217408
59.116 37.93255033557047 0.23084157484947276
59.116 37.933557046979864 0.219797982207112
59.116 37.93456375838926 0.20392880830111504
59.116 37.935570469798655 0.19139210129985013
59.116 37.93657718120805 0.1838181689273938
59.116 37.937583892617454 0.17901421446969984
59.116 37.93859060402685 0.17529232357284114
59.116 37.939597315436245 0.17188698433225671
59.116 37.94060402684564 0.1685112045713669
59.116 37.94161073825504 0.1650756941220386
59.116 37.94261744966443 0.16156725900621338
59.116 37.94362416107383 0.15800061796453635
59.116 37.94463087248322 0.15439947450962638
59.116 37.94563758389262 0.1507888783282312
59.116 37.946644295302015 0.14719200874723337
59.116 37.94765100671141 0.14362879104363627
59.116 37.948657718120806 0.14011535592860067
59.116 37.9496644295302 0.13666394295679932
59.116 37.9506711409396 0.1332830657904713
59.116 37.95167785234899 0.12997783894359322
59.116 37.95268456375839 0.12675039956925876
59.116 37.953691275167785 0.12360037558006817
59.116 37.95469798657718 0.12052536361465396
59.116 37.955704697986576 0.1175213902392792
59.116 37.95671140939597 0.11458333787848134
59.116 37.95771812080537 0.1117053230490948
59.116 37.95872483221476 0.10888101790612778
59.116 37.95973154362416 0.10610390575224096
59.116 37.960738255033554 0.10336745468282085
59.116 37.96174496644295 0.10455251465152861
59.116 37.96275167785235 0.10184859551480094
59.116 37.96375838926175 0.09916491019815146
59.116 37.964765100671144 0.09649265222588943
59.116 37.96577181208054 0.09381889895959797
59.116 37.966778523489936 0.09112160816669579
59.116 37.96778523489933 0.08835972124208141
59.116 37.96879194630873 0.077005609002118
59.116 37.96979865771812 0.0739136835280593
59.116 37.97080536912752 0.07028257410434774
59.116 37.971812080536914 0.06576527758016243
59.116 37.97281879194631 0.06007405460251261
59.116 37.973825503355705 0.05334040369513328
59.116 37.9748322147651 0.046309132557513163
59.116 37.9758389261745 0.03987445093100407
59.116 37.97684563758389 0.034448527508079696
59.116 37.97785234899329 0.03041272991042665
59.116 37.978859060402684 0.026491363929313436
59.116 37.97986577181208 0.022947364614054305
59.116 37.980872483221475 0.019618993757556977
59.116 37.98187919463087 0.013331545088061392
59.116 37.982885906040266 0.0102149421172857
59.116 37.98389261744966 0.002593740822658702
59.116 37.98489932885906 -0.0003981054669545542
59.116 37.98590604026845 -0.0033555871039284285
59.116 37.98691275167785 -0.006279497357558492
59.116 37.98791946308725 -0.009168795720777972
59.116 37.98892617449665 -0.012021550986206844
59.116 37.98993288590604 -0.013824806363341505
59.116 37.99093959731544 -0.016642864855492823
59.116 37.991946308724835 -0.019416366245583197
59.116 37.99295302013423 -0.022143103393024555
59.116 37.993959731543626 -0.02111083787820052
59.116 37.99496644295302 -0.023807173244177096
59.116 37.99597315436242 -0.026450697456387798
59.116 37.99697986577181 -0.02904013820097162
59.116 37.99798657718121 -0.031574480509850095
59.116 37.998993288590604 -0.038128623416494936
59.117000000000004 37.85 0.4875298185713399
59.117000000000004 37.8510067114094 0.4859904274725434
59.117000000000004 37.85201342281879 0.4844335349639892
59.117000000000004 37.85302013422819 0.48285954842366796
59.117000000000004 37.854026845637584 0.4812687714730939
59.117000000000004 37.85503355704698 0.4796613449617354
59.117000000000004 37.856040268456375 0.47803716525896334
59.117000000000004 37.85704697986577 0.47639577125757315
59.117000000000004 37.85805369127517 0.47473618828142267
59.117000000000004 37.85906040268456 0.47305671273837435
59.117000000000004 37.86006711409396 0.4713546155211761
59.117000000000004 37.861073825503354 0.4593689616756414
59.117000000000004 37.86208053691275 0.45754821574993365
59.117000000000004 37.86308724832215 0.4556853386768513
59.117000000000004 37.86409395973155 0.4537676377009938
59.117000000000004 37.865100671140944 0.45177725266950036
59.117000000000004 37.86610738255034 0.4496893242543226
59.117000000000004 37.867114093959735 0.4474696167853075
59.117000000000004 37.86812080536913 0.44507152460426563
59.117000000000004 37.869127516778526 0.44243247576038724
59.117000000000004 37.87013422818792 0.43946994247725935
59.117000000000004 37.87114093959732 0.43607765147564587
59.117000000000004 37.87214765100671 0.42351598937875423
59.117000000000004 37.87315436241611 0.41109399056964124
59.117000000000004 37.874161073825505 0.40535198045968995
59.117000000000004 37.8751677852349 0.3985463966944014
59.117000000000004 37.876174496644296 0.39054619575906335
59.117000000000004 37.87718120805369 0.38367425851182907
59.117000000000004 37.87818791946309 0.37333196058328044
59.117000000000004 37.87919463087248 0.36211050856682203
59.117000000000004 37.88020134228188 0.3504368431155217
59.117000000000004 37.881208053691275 0.3388347052763274
59.117000000000004 37.88221476510067 0.3278214375936178
59.117000000000004 37.883221476510066 0.3178069918090427
59.117000000000004 37.88422818791946 0.30903442233109835
59.117000000000004 37.88523489932886 0.301575664110569
59.117000000000004 37.88624161073825 0.295368608277606
59.117000000000004 37.88724832214765 0.29026985477424594
59.117000000000004 37.88825503355705 0.2861024868575583
59.117000000000004 37.88926174496645 0.28268944809158325
59.117000000000004 37.89026845637584 0.27987204473225574
59.117000000000004 37.89127516778524 0.2713800131797157
59.117000000000004 37.892281879194634 0.26370437154101667
59.117000000000004 37.89328859060403 0.26188117166494046
59.117000000000004 37.894295302013425 0.26026423779177366
59.117000000000004 37.89530201342282 0.25880297707391503
59.117000000000004 37.89630872483222 0.25745710990747656
59.117000000000004 37.89731543624161 0.2561931621498128
59.117000000000004 37.89832214765101 0.2549814725112771
59.117000000000004 37.899328859060404 0.2537936099424336
59.117000000000004 37.9003355704698 0.24417551078605967
59.117000000000004 37.901342281879195 0.2428061076426983
59.117000000000004 37.90234899328859 0.24135888636161504
59.117000000000004 37.90335570469799 0.23978937117080598
59.117000000000004 37.90436241610738 0.2380452836979793
59.117000000000004 37.90536912751678 0.23606675429933782
59.117000000000004 37.906375838926174 0.23378911257951074
59.117000000000004 37.90738255033557 0.23115033560786247
59.117000000000004 37.908389261744965 0.22810583973466356
59.117000000000004 37.90939597315436 0.224652665663356
59.117000000000004 37.910402684563756 0.2208612279307546
59.117000000000004 37.91140939597315 0.21690396120828373
59.117000000000004 37.91241610738255 0.21305924172940477
59.117000000000004 37.91342281879195 0.2096680722803977
59.117000000000004 37.914429530201346 0.2070446461833943
59.117000000000004 37.91543624161074 0.20538142810493218
59.117000000000004 37.91644295302014 0.20470441538232634
59.117000000000004 37.91744966442953 0.2048999950153535
59.117000000000004 37.91845637583893 0.20578510399610966
59.117000000000004 37.919463087248324 0.20717603248477665
59.117000000000004 37.92046979865772 0.2089304482685885
59.117000000000004 37.921476510067116 0.21096184074989327
59.117000000000004 37.92248322147651 0.21323683337003785
59.117000000000004 37.92348993288591 0.21576531008640307
59.117000000000004 37.9244966442953 0.21858874144350043
59.117000000000004 37.9255033557047 0.22176806601634927
59.117000000000004 37.926510067114094 0.22537001989612715
59.117000000000004 37.92751677852349 0.22944921313869532
59.117000000000004 37.928523489932886 0.2340204022708217
59.117000000000004 37.92953020134228 0.2390049553504808
59.117000000000004 37.93053691275168 0.24409892713788617
59.117000000000004 37.93154362416107 0.24841121814382042
59.117000000000004 37.93255033557047 0.24962625290809096
59.117000000000004 37.933557046979864 0.24337877177831646
59.117000000000004 37.93456375838926 0.22730658272277843
59.117000000000004 37.935570469798655 0.20839570766630156
59.117000000000004 37.93657718120805 0.1948715790832578
59.117000000000004 37.937583892617454 0.18695388830413662
59.117000000000004 37.93859060402685 0.18195396967419883
59.117000000000004 37.939597315436245 0.17809163072929188
59.117000000000004 37.94060402684564 0.17457982115340925
59.117000000000004 37.94161073825504 0.17112357855211033
59.117000000000004 37.94261744966443 0.16763027544848932
59.117000000000004 37.94362416107383 0.16408514979947317
59.117000000000004 37.94463087248322 0.16050181850605036
59.117000000000004 37.94563758389262 0.1569028247449883
59.117000000000004 37.946644295302015 0.1533118636346125
59.117000000000004 37.94765100671141 0.14975060082296132
59.117000000000004 37.948657718120806 0.14623738394361935
59.117000000000004 37.9496644295302 0.14278680481896489
59.117000000000004 37.9506711409396 0.13940969023276
59.117000000000004 37.95167785234899 0.1361133310425809
59.117000000000004 37.95268456375839 0.13290184853533976
59.117000000000004 37.953691275167785 0.1297766344724723
59.117000000000004 37.95469798657718 0.1267368204468543
59.117000000000004 37.955704697986576 0.12377974453976856
59.117000000000004 37.95671140939597 0.12090139243986407
59.117000000000004 37.95771812080537 0.11809679691415936
59.117000000000004 37.95872483221476 0.11536038339705754
59.117000000000004 37.95973154362416 0.11268624920848413
59.117000000000004 37.960738255033554 0.1100683567867426
59.117000000000004 37.96174496644295 0.10750060156915778
59.117000000000004 37.96275167785235 0.10497667078342068
59.117000000000004 37.96375838926175 0.10248951584261773
59.117000000000004 37.964765100671144 0.10003006919588417
59.117000000000004 37.96577181208054 0.09758445318121023
59.117000000000004 37.966778523489936 0.09901221340487738
59.117000000000004 37.96778523489933 0.09646716525961158
59.117000000000004 37.96879194630873 0.0937742031039698
59.117000000000004 37.96979865771812 0.08241326400141757
59.117000000000004 37.97080536912752 0.0788877806081491
59.117000000000004 37.971812080536914 0.07431626595752747
59.117000000000004 37.97281879194631 0.06829650813496461
59.117000000000004 37.973825503355705 0.06087503319437259
59.117000000000004 37.9748322147651 0.052861842353179936
59.117000000000004 37.9758389261745 0.04538574190802748
59.117000000000004 37.97684563758389 0.03959702476853713
59.117000000000004 37.97785234899329 0.03442358280904567
59.117000000000004 37.978859060402684 0.03008986171744589
59.117000000000004 37.97986577181208 0.026282961721727782
59.117000000000004 37.980872483221475 0.022786095198123052
59.117000000000004 37.98187919463087 0.016416648181374657
59.117000000000004 37.982885906040266 0.013222734850519252
59.117000000000004 37.98389261744966 0.005604369115089519
59.117000000000004 37.98489932885906 0.0025677503719799682
59.117000000000004 37.98590604026845 -0.00042681826201584894
59.117000000000004 37.98691275167785 -0.0033831433083737206
59.117000000000004 37.98791946308725 -0.006302102699650466
59.117000000000004 37.98892617449665 -0.009183024600732587
59.117000000000004 37.98993288590604 -0.012024444096606163
59.117000000000004 37.99093959731544 -0.013829858418564128
59.117000000000004 37.991946308724835 -0.012894757935074247
59.117000000000004 37.99295302013423 -0.01571842909462169
59.117000000000004 37.993959731543626 -0.018493411627310052
59.117000000000004 37.99496644295302 -0.021218042424225397
59.117000000000004 37.99597315436242 -0.023890854112141244
59.117000000000004 37.99697986577181 -0.026510594906499138
59.117000000000004 37.99798657718121 -0.02907623881262475
59.117000000000004 37.998993288590604 -0.03563335397698998
59.118 37.85 0.48975606374387326
59.118 37.8510067114094 0.48821783743767433
59.118 37.85201342281879 0.4866605341739883
59.118 37.85302013422819 0.48508467715912756
59.118 37.854026845637584 0.48349075377864825
59.118 37.85503355704698 0.4818791809908464
59.118 37.856040268456375 0.480250257244248
59.118 37.85704697986577 0.4786040958497165
59.118 37.85805369127517 0.47694053283816484
59.118 37.85906040268456 0.47525899974378677
59.118 37.86006711409396 0.4735583482376772
59.118 37.861073825503354 0.47183660880449796
59.118 37.86208053691275 0.47009065935755656
59.118 37.86308724832215 0.45823050390361253
59.118 37.86409395973155 0.45636186939016876
59.118 37.865100671140944 0.4544470462644125
59.118 37.86610738255034 0.45247181590566127
59.118 37.867114093959735 0.45041625531942125
59.118 37.86812080536913 0.44825275933098263
59.118 37.869127516778526 0.44594350430096164
59.118 37.87013422818792 0.44343730658929004
59.118 37.87114093959732 0.43189405554103244
59.118 37.87214765100671 0.42868795601909526
59.118 37.87315436241611 0.4173211115914371
59.118 37.874161073825505 0.41294162859022004
59.118 37.8751677852349 0.40779136881177613
59.118 37.876174496644296 0.4016971550195205
59.118 37.87718120805369 0.39685169579740665
59.118 37.87818791946309 0.3884791325397604
59.118 37.87919463087248 0.37888135981497284
59.118 37.88020134228188 0.36819544982644115
59.118 37.881208053691275 0.3567294850702159
59.118 37.88221476510067 0.3449391217275014
59.118 37.883221476510066 0.3333521903956462
59.118 37.88422818791946 0.3224644291867276
59.118 37.88523489932886 0.31264766923230186
59.118 37.88624161073825 0.30410391428704725
59.118 37.88724832214765 0.2968718119917397
59.118 37.88825503355705 0.2845339735838106
59.118 37.88926174496645 0.27958928574689623
59.118 37.89026845637584 0.2755393819147711
59.118 37.89127516778524 0.27220752837343015
59.118 37.892281879194634 0.2637335289613181
59.118 37.89328859060403 0.2613060627202512
59.118 37.894295302013425 0.2591944124968075
59.118 37.89530201342282 0.25730884628013706
59.118 37.89630872483222 0.2555750362504213
59.118 37.89731543624161 0.25392927380707864
59.118 37.89832214765101 0.25231409026670804
59.118 37.899328859060404 0.25067448843668994
59.118 37.9003355704698 0.24055022860386666
59.118 37.901342281879195 0.23855545915762644
59.118 37.90234899328859 0.23635928999044645
59.118 37.90335570469799 0.23389844802875903
59.118 37.90436241610738 0.23111292394743846
59.118 37.90536912751678 0.22795624444748297
59.118 37.906375838926174 0.22441259716589626
59.118 37.90738255033557 0.2205207584894966
59.118 37.908389261744965 0.21640027072386064
59.118 37.90939597315436 0.21226788910682165
59.118 37.910402684563756 0.2084261216167968
59.118 37.91140939597315 0.20521034267258803
59.118 37.91241610738255 0.20290350485298728
59.118 37.91342281879195 0.20165626492075467
59.118 37.914429530201346 0.20145606970103846
59.118 37.91543624161074 0.2021586032614144
59.118 37.91644295302014 0.2035560153241221
59.118 37.91744966442953 0.205443488186473
59.118 37.91845637583893 0.20766105437905247
59.118 37.919463087248324 0.21010875761207476
59.118 37.92046979865772 0.2127443105487256
59.118 37.921476510067116 0.21557302274996407
59.118 37.92248322147651 0.21863594668250733
59.118 37.92348993288591 0.22199829987556347
59.118 37.9244966442953 0.2257377310296901
59.118 37.9255033557047 0.22993089840134298
59.118 37.926510067114094 0.23463682395205998
59.118 37.92751677852349 0.23987624990285808
59.118 37.928523489932886 0.24560674988933862
59.118 37.92953020134228 0.2516901113289664
59.118 37.93053691275168 0.25783159818219764
59.118 37.93154362416107 0.2634181545142329
59.118 37.93255033557047 0.2670754579763369
59.118 37.933557046979864 0.2658066722239406
59.118 37.93456375838926 0.25509687986309215
59.118 37.935570469798655 0.23456120416467388
59.118 37.93657718120805 0.21333255306618581
59.118 37.937583892617454 0.1989938910171263
59.118 37.93859060402685 0.19075137054843938
59.118 37.939597315436245 0.18558626826339197
59.118 37.94060402684564 0.18163242353546463
59.118 37.94161073825504 0.17807308547249506
59.118 37.94261744966443 0.1746013675905054
59.118 37.94362416107383 0.17111972531782715
59.118 37.94463087248322 0.16761111023638486
59.118 37.94563758389262 0.16408765362367583
59.118 37.946644295302015 0.1605705339884674
59.118 37.94765100671141 0.1570820179868741
59.118 37.948657718120806 0.15364228927829368
59.118 37.9496644295302 0.15026823355979388
59.118 37.9506711409396 0.14697308187595037
59.118 37.95167785234899 0.14376646800438456
59.118 37.95268456375839 0.1406547028625582
59.118 37.953691275167785 0.13764116489873127
59.118 37.95469798657718 0.1347267458984612
59.118 37.955704697986576 0.13191031164914307
59.118 37.95671140939597 0.1291891488403538
59.118 37.95771812080537 0.12655937731632264
59.118 37.95872483221476 0.12401631087660582
59.118 37.95973154362416 0.121554748965884
59.118 37.960738255033554 0.11916917246018091
59.118 37.96174496644295 0.11685379222825203
59.118 37.96275167785235 0.11460234451990749
59.118 37.96375838926175 0.1124074126286913
59.118 37.964765100671144 0.11025882053960213
59.118 37.96577181208054 0.10814018000358279
59.118 37.966778523489936 0.1060217901989278
59.118 37.96778523489933 0.10384656448575366
59.118 37.96879194630873 0.101503620326691
59.118 37.96979865771812 0.09436798290323517
59.118 37.97080536912752 0.09097496435726363
59.118 37.971812080536914 0.08630040580559101
59.118 37.97281879194631 0.07979699843687282
59.118 37.973825503355705 0.07139418148027925
59.118 37.9748322147651 0.06252260858445094
59.118 37.9758389261745 0.05343399743059929
59.118 37.97684563758389 0.04570123017824275
59.118 37.97785234899329 0.03946854939852419
59.118 37.978859060402684 0.03440727401532817
59.118 37.97986577181208 0.030120955190248835
59.118 37.980872483221475 0.026309910762173372
59.118 37.98187919463087 0.022784148208539523
59.118 37.982885906040266 0.016422213886506706
59.118 37.98389261744966 0.008760122841788232
59.118 37.98489932885906 0.005646471766985781
59.118 37.98590604026845 0.00259212862418369
59.118 37.98691275167785 -0.0004125582156656732
59.118 37.98791946308725 -0.003372283962468553
59.118 37.98892617449665 -0.00628894553864065
59.118 37.98993288590604 -0.009162853855143926
59.118 37.99093959731544 -0.007277223539956343
59.118 37.991946308724835 -0.010175315639561339
59.118 37.99295302013423 -0.01302611242100278
59.118 37.993959731543626 -0.01582825352566014
59.118 37.99496644295302 -0.018580405213101128
59.118 37.99597315436242 -0.021281327110861364
59.118 37.99697986577181 -0.023929916111432165
59.118 37.99798657718121 -0.026525234529983843
59.118 37.998993288590604 -0.029066526978577895
59.119 37.85 0.49191210790893664
59.119 37.8510067114094 0.4903755970549146
59.119 37.85201342281879 0.48881830290824424
59.119 37.85302013422819 0.4872407921147259
59.119 37.854026845637584 0.4856436366236978
59.119 37.85503355704698 0.48402739305181697
59.119 37.856040268456375 0.48239257368533384
59.119 37.85704697986577 0.48073960599815724
59.119 37.85805369127517 0.47906877639529627
59.119 37.85906040268456 0.47738015229440145
59.119 37.86006711409396 0.4756734744814189
59.119 37.861073825503354 0.4739480087219858
59.119 37.86208053691275 0.472202341628029
59.119 37.86308724832215 0.470434100462233
59.119 37.86409395973155 0.4686395695615655
59.119 37.865100671140944 0.45689861626543526
59.119 37.86610738255034 0.4549753958262711
59.119 37.867114093959735 0.4530001832319459
59.119 37.86812080536913 0.4509564634054379
59.119 37.869127516778526 0.4488212092661349
59.119 37.87013422818792 0.43762601241020876
59.119 37.87114093959732 0.4351188981167198
59.119 37.87214765100671 0.4323866003942326
59.119 37.87315436241611 0.4216402179111558
59.119 37.874161073825505 0.4181080690006913
59.119 37.8751677852349 0.4140500921856738
59.119 37.876174496644296 0.4116165885840844
59.119 37.87718120805369 0.4060828928751974
59.119 37.87818791946309 0.3995372386795966
59.119 37.87919463087248 0.39183508666085265
59.119 37.88020134228188 0.38290056399991335
59.119 37.881208053691275 0.3727762646348495
59.119 37.88221476510067 0.36166337897603507
59.119 37.883221476510066 0.3499287281631077
59.119 37.88422818791946 0.33154502709976785
59.119 37.88523489932886 0.32005163923193014
59.119 37.88624161073825 0.30940380928924727
59.119 37.88724832214765 0.299911535684237
59.119 37.88825503355705 0.29171379161884975
59.119 37.88926174496645 0.28479901471481744
59.119 37.89026845637584 0.27905115024701954
59.119 37.89127516778524 0.26864007936444884
59.119 37.892281879194634 0.26461280080700017
59.119 37.89328859060403 0.2612101082644927
59.119 37.894295302013425 0.25826882399874923
59.119 37.89530201342282 0.25564689632240195
59.119 37.89630872483222 0.253221414386687
59.119 37.89731543624161 0.2508845736306227
59.119 37.89832214765101 0.24853940375033115
59.119 37.899328859060404 0.2460964772984305
59.119 37.9003355704698 0.23509032486150935
59.119 37.901342281879195 0.23207366003593924
59.119 37.90234899328859 0.22873738448692696
59.119 37.90335570469799 0.22504483813664616
59.119 37.90436241610738 0.22100124747148375
59.119 37.90536912751678 0.21667473123988623
59.119 37.906375838926174 0.21221554403692905
59.119 37.90738255033557 0.20786256922706045
59.119 37.908389261744965 0.20392361527080033
59.119 37.90939597315436 0.20072246236464752
59.119 37.910402684563756 0.1985233116743278
59.119 37.91140939597315 0.19746280856329412
59.119 37.91241610738255 0.19752267299698884
59.119 37.91342281879195 0.198554598585124
59.119 37.914429530201346 0.2003399391349539
59.119 37.91543624161074 0.20265349030815907
59.119 37.91644295302014 0.20530885477846672
59.119 37.91744966442953 0.20817940866606435
59.119 37.91845637583893 0.21120043484323311
59.119 37.919463087248324 0.21436112275743915
59.119 37.92046979865772 0.21769308631009837
59.119 37.921476510067116 0.22125866737609207
59.119 37.92248322147651 0.2251395607813842
59.119 37.92348993288591 0.22942470245812674
59.119 37.9244966442953 0.2341958513797504
59.119 37.9255033557047 0.23950983518041455
59.119 37.926510067114094 0.24537804943913497
59.119 37.92751677852349 0.25174619781405905
59.119 37.928523489932886 0.2584791018088911
59.119 37.92953020134228 0.26535363777826604
59.119 37.93053691275168 0.2720521588963488
59.119 37.93154362416107 0.27812027469411477
59.119 37.93255033557047 0.2827923642555348
59.119 37.933557046979864 0.2845044552381043
59.119 37.93456375838926 0.2801008886728747
59.119 37.935570469798655 0.2655129223121562
59.119 37.93657718120805 0.24188872917740578
59.119 37.937583892617454 0.21918697932260492
59.119 37.93859060402685 0.2042979153862929
59.119 37.939597315436245 0.19584441903031996
59.119 37.94060402684564 0.1906120610503902
59.119 37.94161073825504 0.1866668531728225
59.119 37.94261744966443 0.1831642019756343
59.119 37.94362416107383 0.17978570354363851
59.119 37.94463087248322 0.17642900986456006
59.119 37.94563758389262 0.17307473752521477
59.119 37.946644295302015 0.16973342991061957
59.119 37.94765100671141 0.16642481649290466
59.119 37.948657718120806 0.16316969868315961
59.119 37.9496644295302 0.15998679110337474
59.119 37.9506711409396 0.15689156800730344
59.119 37.95167785234899 0.15389596954486237
59.119 37.95268456375839 0.1510085062605164
59.119 37.953691275167785 0.1482345600977754
59.119 37.95469798657718 0.14557678146006264
59.119 37.955704697986576 0.14303552404335504
59.119 37.95671140939597 0.140609278817156
59.119 37.95771812080537 0.13829507847255895
59.119 37.95872483221476 0.13608884740037108
59.119 37.95973154362416 0.13398566906564635
59.119 37.960738255033554 0.13197992838709494
59.119 37.96174496644295 0.1300652523211074
59.119 37.96275167785235 0.1282340989379851
59.119 37.96375838926175 0.126476697749896
59.119 37.964765100671144 0.12477875302270186
59.119 37.96577181208054 0.12311676365586878
59.119 37.966778523489936 0.12144879541251956
59.119 37.96778523489933 0.11969687882105576
59.119 37.96879194630873 0.11771521326665366
59.119 37.96979865771812 0.10706902078184492
59.119 37.97080536912752 0.10375031989560574
59.119 37.971812080536914 0.09883901644519957
59.119 37.97281879194631 0.09209963138774846
59.119 37.973825503355705 0.08640638370025994
59.119 37.9748322147651 0.07515042117428784
59.119 37.9758389261745 0.06397319905202098
59.119 37.97684563758389 0.054235631902131834
59.119 37.97785234899329 0.04636201814525924
59.119 37.978859060402684 0.04009057744992639
59.119 37.97986577181208 0.034958646040376906
59.119 37.980872483221475 0.030567075331833615
59.119 37.98187919463087 0.02664081896736545
59.119 37.982885906040266 0.015615401243623733
59.119 37.98389261744966 0.012221206115216904
59.119 37.98489932885906 0.00895818204079106
59.119 37.98590604026845 0.005791018873837775
59.119 37.98691275167785 0.002698967353263873
59.119 37.98791946308725 -0.00033017156689633953
59.119 37.98892617449665 0.00011337981495726512
59.119 37.98993288590604 -0.002863441107685216
59.119 37.99093959731544 -0.004462562992830557
59.119 37.991946308724835 -0.00739570891157687
59.119 37.99295302013423 -0.010278625466892427
59.119 37.993959731543626 -0.013111031444004329
59.119 37.99496644295302 -0.01589239288661981
59.119 37.99597315436242 -0.018622057970891365
59.119 37.99697986577181 -0.02129935089837265
59.119 37.99798657718121 -0.023923637439049752
59.119 37.998993288590604 -0.02649437017638613
59.12 37.85 0.4939981327393878
59.12 37.8510067114094 0.49246397718087986
59.12 37.85201342281879 0.49090728737095995
59.12 37.85302013422819 0.48932863356984446
59.12 37.854026845637584 0.48772861830317027
59.12 37.85503355704698 0.48610786428121
59.12 37.856040268456375 0.4844669969475488
59.12 37.85704697986577 0.48280661966016397
59.12 37.85805369127517 0.4811272787640516
59.12 37.85906040268456 0.4794294147965575
59.12 37.86006711409396 0.47771329467600854
59.12 37.861073825503354 0.47597891782916657
59.12 37.86208053691275 0.47422588664217047
59.12 37.86308724832215 0.4724532281518409
59.12 37.86409395973155 0.4706591492538985
59.12 37.865100671140944 0.46884070156974655
59.12 37.86610738255034 0.46699332414524775
59.12 37.867114093959735 0.4553655407752013
59.12 37.86812080536913 0.44436268452316274
59.12 37.869127516778526 0.4422351823792903
59.12 37.87013422818792 0.44002731989722355
59.12 37.87114093959732 0.437711687102522
59.12 37.87214765100671 0.43525074657159496
59.12 37.87315436241611 0.4248638009975758
59.12 37.874161073825505 0.42184585733777935
59.12 37.8751677852349 0.4184752567391884
59.12 37.876174496644296 0.4169258731844051
59.12 37.87718120805369 0.41252975270577913
59.12 37.87818791946309 0.40737294512592703
59.12 37.87919463087248 0.40128397176111363
59.12 37.88020134228188 0.39410137585005994
59.12 37.881208053691275 0.3789925323046388
59.12 37.88221476510067 0.3693503510616306
59.12 37.883221476510066 0.35859249029688894
59.12 37.88422818791946 0.3470007247519061
59.12 37.88523489932886 0.33500551301648396
59.12 37.88624161073825 0.32311922820937794
59.12 37.88724832214765 0.3118374590478188
59.12 37.88825503355705 0.3015459188755409
59.12 37.88926174496645 0.2924670182743827
59.12 37.89026845637584 0.28465687531105704
59.12 37.89127516778524 0.27234501418774304
59.12 37.892281879194634 0.2666787082156493
59.12 37.89328859060403 0.261845900548213
59.12 37.894295302013425 0.2576398131140111
59.12 37.89530201342282 0.2538637288189856
59.12 37.89630872483222 0.250339023627408
59.12 37.89731543624161 0.24690762150455978
59.12 37.89832214765101 0.2434327245309406
59.12 37.899328859060404 0.2398007898095077
59.12 37.9003355704698 0.2275695470436575
59.12 37.901342281879195 0.22327135706093204
59.12 37.90234899328859 0.218689449020865
59.12 37.90335570469799 0.21389916061951925
59.12 37.90436241610738 0.20905695787797884
59.12 37.90536912751678 0.20439983671809353
59.12 37.906375838926174 0.20022294387078046
59.12 37.90738255033557 0.19683230611858638
59.12 37.908389261744965 0.19448169838315918
59.12 37.90939597315436 0.1933149375433067
59.12 37.910402684563756 0.19333717850168392
59.12 37.91140939597315 0.19442651771250175
59.12 37.91241610738255 0.19637796591407014
59.12 37.91342281879195 0.1989592168549219
59.12 37.914429530201346 0.20195834270528026
59.12 37.91543624161074 0.20521322460042546
59.12 37.91644295302014 0.2086226434952585
59.12 37.91744966442953 0.21214445749510769
59.12 37.91845637583893 0.21578705071659787
59.12 37.919463087248324 0.2195984182528045
59.12 37.92046979865772 0.2236548459113154
59.12 37.921476510067116 0.228049191712855
59.12 37.92248322147651 0.23287762997343123
59.12 37.92348993288591 0.2382235064123595
59.12 37.9244966442953 0.24413782729221656
59.12 37.9255033557047 0.25061795321009517
59.12 37.926510067114094 0.2575888949814286
59.12 37.92751677852349 0.2648938656728001
59.12 37.928523489932886 0.2723001969690589
59.12 37.92953020134228 0.27952139930857534
59.12 37.93053691275168 0.2862457278091389
59.12 37.93154362416107 0.2921466039754135
59.12 37.93255033557047 0.29682668116147776
59.12 37.933557046979864 0.29960234846276035
59.12 37.93456375838926 0.29898408395076953
59.12 37.935570469798655 0.29196444289473616
59.12 37.93657718120805 0.274977900412173
59.12 37.937583892617454 0.24990953827866796
59.12 37.93859060402685 0.22670209513936745
59.12 37.939597315436245 0.21171113046889398
59.12 37.94060402684564 0.20330412123506247
59.12 37.94161073825504 0.19819795402335977
59.12 37.94261744966443 0.19443266976914242
59.12 37.94362416107383 0.19115197846486962
59.12 37.94463087248322 0.1880324598448194
59.12 37.94563758389262 0.18496939022400086
59.12 37.946644295302015 0.18194177049792232
59.12 37.94765100671141 0.1789585885626342
59.12 37.948657718120806 0.17603789416492244
59.12 37.9496644295302 0.17319869261743176
59.12 37.9506711409396 0.17045785026364
59.12 37.95167785234899 0.16782900778620288
59.12 37.95268456375839 0.16532233615147518
59.12 37.953691275167785 0.16294466720368925
59.12 37.95469798657718 0.1606997958682451
59.12 37.955704697986576 0.15858885320803476
59.12 37.95671140939597 0.15661069011481557
59.12 37.95771812080537 0.15476222688064406
59.12 37.95872483221476 0.1530387261752573
59.12 37.95973154362416 0.1514339375120968
59.12 37.960738255033554 0.1499400354242968
59.12 37.96174496644295 0.14854722000924747
59.12 37.96275167785235 0.1472427456003363
59.12 37.96375838926175 0.14600895183007034
59.12 37.964765100671144 0.14481952388763816
59.12 37.96577181208054 0.14363260225810662
59.12 37.966778523489936 0.1423783833129398
59.12 37.96778523489933 0.1409375455334492
59.12 37.96879194630873 0.13910604281676642
59.12 37.96979865771812 0.1283817820329235
59.12 37.97080536912752 0.12466837457003088
59.12 37.971812080536914 0.11946234597344543
59.12 37.97281879194631 0.11116663601492592
59.12 37.973825503355705 0.10011271016803504
59.12 37.9748322147651 0.08714676475302471
59.12 37.9758389261745 0.07392904989183768
59.12 37.97684563758389 0.0659916063928879
59.12 37.97785234899329 0.056057264116240235
59.12 37.978859060402684 0.04807585478989439
59.12 37.97986577181208 0.04162342454919503
59.12 37.980872483221475 0.03624825024203196
59.12 37.98187919463087 0.031598021806768906
59.12 37.982885906040266 0.020126495281124618
59.12 37.98389261744966 0.016334425277495568
59.12 37.98489932885906 0.012772204237282064
59.12 37.98590604026845 0.009377533554511028
59.12 37.98691275167785 0.009530726823460539
59.12 37.98791946308725 0.0063114608523787
59.12 37.98892617449665 0.0031772557483956687
59.12 37.98993288590604 0.00011671848214798997
59.12 37.99093959731544 -0.0028777728515986672
59.12 37.991946308724835 -0.004518546247170324
59.12 37.99295302013423 -0.007448999065878856
59.12 37.993959731543626 -0.010322734793555569
59.12 37.99496644295302 -0.013141045733855591
59.12 37.99597315436242 -0.015904663196893
59.12 37.99697986577181 -0.01861395352931082
59.12 37.99798657718121 -0.02126906152968032
59.12 37.998993288590604 -0.023870015483543547
59.121 37.85 0.4960141634389145
59.121 37.8510067114094 0.4944829837157444
59.121 37.85201342281879 0.49292751931625106
59.121 37.85302013422819 0.4913483209413445
59.121 37.854026845637584 0.4897459906217503
59.121 37.85503355704698 0.48812117524711907
59.121 37.856040268456375 0.48647455656393485
59.121 37.85704697986577 0.484806836326059
59.121 37.85805369127517 0.4831187147939907
59.121 37.85906040268456 0.481410860111364
59.121 37.86006711409396 0.47968386517450945
59.121 37.861073825503354 0.4779381873642878
59.121 37.86208053691275 0.4761740648122853
59.121 37.86308724832215 0.47439140057113977
59.121 37.86409395973155 0.4725896029501476
59.121 37.865100671140944 0.4707673661110302
59.121 37.86610738255034 0.46892236949354515
59.121 37.867114093959735 0.45680868139513076
59.121 37.86812080536913 0.45480405361021625
59.121 37.869127516778526 0.444274775177705
59.121 37.87013422818792 0.44213645901278964
59.121 37.87114093959732 0.43992693105107056
59.121 37.87214765100671 0.43762198345417835
59.121 37.87315436241611 0.4274429119399626
59.121 37.874161073825505 0.42473745140032726
59.121 37.8751677852349 0.42179599668323636
59.121 37.876174496644296 0.42081119965529296
59.121 37.87718120805369 0.41717565346959645
59.121 37.87818791946309 0.40607437106905275
59.121 37.87919463087248 0.40117494696180106
59.121 37.88020134228188 0.3954071004008322
59.121 37.881208053691275 0.3885999227614578
59.121 37.88221476510067 0.380611397089788
59.121 37.883221476510066 0.37137113827831986
59.121 37.88422818791946 0.36092874362558075
59.121 37.88523489932886 0.3494907874109907
59.121 37.88624161073825 0.3374239260412511
59.121 37.88724832214765 0.32520881402071433
59.121 37.88825503355705 0.31335153520475406
59.121 37.88926174496645 0.3022835058337431
59.121 37.89026845637584 0.2922880249566926
59.121 37.89127516778524 0.27774700792583057
59.121 37.892281879194634 0.2699925279493776
59.121 37.89328859060403 0.2632278694517031
59.121 37.894295302013425 0.25724427052242427
59.121 37.89530201342282 0.25181411101695883
59.121 37.89630872483222 0.2467189470610329
59.121 37.89731543624161 0.24176815744674118
59.121 37.89832214765101 0.23681259960845796
59.121 37.899328859060404 0.23175691800575168
59.121 37.9003355704698 0.21824204291810195
59.121 37.901342281879195 0.21284090151034746
59.121 37.90234899328859 0.20748844993752769
59.121 37.90335570469799 0.20239310079236428
59.121 37.90436241610738 0.19781737798131072
59.121 37.90536912751678 0.1940374572486995
59.121 37.906375838926174 0.19129223565002856
59.121 37.90738255033557 0.18973580963688547
59.121 37.908389261744965 0.1894085786346278
59.121 37.90939597315436 0.19023605346624878
59.121 37.910402684563756 0.19205387021083803
59.121 37.91140939597315 0.19464849477062934
59.121 37.91241610738255 0.19780003854298575
59.121 37.91342281879195 0.20131655592614828
59.121 37.914429530201346 0.20505501669967108
59.121 37.91543624161074 0.20892940705330282
59.121 37.91644295302014 0.2129093217832178
59.121 37.91744966442953 0.21701293502829339
59.121 37.91845637583893 0.22129724641145196
59.121 37.919463087248324 0.2258469478341354
59.121 37.92046979865772 0.23076182681966917
59.121 37.921476510067116 0.23614174785287761
59.121 37.92248322147651 0.2420682666869667
59.121 37.92348993288591 0.24858311992787954
59.121 37.9244966442953 0.2556662256551112
59.121 37.9255033557047 0.26321875486804736
59.121 37.926510067114094 0.271058583539582
59.121 37.92751677852349 0.2789338265022288
59.121 37.928523489932886 0.28655427227688
59.121 37.92953020134228 0.2936324235807585
59.121 37.93053691275168 0.29991965982881086
59.121 37.93154362416107 0.30522100359702076
59.121 37.93255033557047 0.30937004791295186
59.121 37.933557046979864 0.31213207962392026
59.121 37.93456375838926 0.3129632663454683
59.121 37.935570469798655 0.31051022940286804
59.121 37.93657718120805 0.30199855818041926
59.121 37.937583892617454 0.28428371091173843
59.121 37.93859060402685 0.2594673750023423
59.121 37.939597315436245 0.23697531110061876
59.121 37.94060402684564 0.22262528220815592
59.121 37.94161073825504 0.2147154198029049
59.121 37.94261744966443 0.2100411213015078
59.121 37.94362416107383 0.2066979711682979
59.121 37.94463087248322 0.2038555813518275
59.121 37.94563758389262 0.20120091904332485
59.121 37.946644295302015 0.19863246993462894
59.121 37.94765100671141 0.19612911721501924
59.121 37.948657718120806 0.19369812994361332
59.121 37.9496644295302 0.19135503367557893
59.121 37.9506711409396 0.18911589220328437
59.121 37.95167785234899 0.18699441320657806
59.121 37.95268456375839 0.18500096478595052
59.121 37.953691275167785 0.18314237417342683
59.121 37.95469798657718 0.18142205346367404
59.121 37.955704697986576 0.17984024998916417
59.121 37.95671140939597 0.17839431194746944
59.121 37.95771812080537 0.17707888952064538
59.121 37.95872483221476 0.1758859914413904
59.121 37.95973154362416 0.1748047951180553
59.121 37.960738255033554 0.1738210616895028
59.121 37.96174496644295 0.1729159244133624
59.121 37.96275167785235 0.17206368024830818
59.121 37.96375838926175 0.17122799288722468
59.121 37.964765100671144 0.17035558145790572
59.121 37.96577181208054 0.16936602143439083
59.121 37.966778523489936 0.168135849747165
59.121 37.96778523489933 0.16647527228930045
59.121 37.96879194630873 0.16409795771976954
59.121 37.96979865771812 0.16138041392382022
59.121 37.97080536912752 0.14783592602055576
59.121 37.971812080536914 0.1404901864985914
59.121 37.97281879194631 0.13049541215251012
59.121 37.973825503355705 0.11798089403295818
59.121 37.9748322147651 0.10382406967673027
59.121 37.9758389261745 0.08942459810888428
59.121 37.97684563758389 0.07609396555258692
59.121 37.97785234899329 0.06456508800609717
59.121 37.978859060402684 0.05495068787407549
59.121 37.97986577181208 0.05099580464440501
59.121 37.980872483221475 0.044289870522328634
59.121 37.98187919463087 0.038538439949605596
59.121 37.982885906040266 0.02626318246480433
59.121 37.98389261744966 0.02175829916124901
59.121 37.98489932885906 0.021050183164492614
59.121 37.98590604026845 0.017155763572148097
59.121 37.98691275167785 0.013484120585727191
59.121 37.98791946308725 0.009989602636467629
59.121 37.98892617449665 0.00663906828852516
59.121 37.98993288590604 0.0034081903360370647
59.121 37.99093959731544 0.0002790019999861504
59.121 37.991946308724835 -0.001448579976780176
59.121 37.99295302013423 -0.004465523561098014
59.121 37.993959731543626 -0.007409905477865464
59.121 37.99496644295302 -0.010286989288130183
59.121 37.99597315436242 -0.013100587233384503
59.121 37.99697986577181 -0.015853442557221306
59.121 37.99798657718121 -0.018547523060995816
59.121 37.998993288590604 -0.02118424611896569
59.122 37.85 0.4979602657260134
59.122 37.8510067114094 0.49643261149756357
59.122 37.85201342281879 0.4948789477271887
59.122 37.85302013422819 0.49329979147375314
59.122 37.854026845637584 0.4916957255462613
59.122 37.85503355704698 0.4900673959622546
59.122 37.856040268456375 0.4884155070580165
59.122 37.85704697986577 0.4867408133623062
59.122 37.85805369127517 0.485044107018192
59.122 37.85906040268456 0.483326199090039
59.122 37.86006711409396 0.4815878924807523
59.122 37.861073825503354 0.4798299433473895
59.122 37.86208053691275 0.47805300676150536
59.122 37.86308724832215 0.4762575608062705
59.122 37.86409395973155 0.47444380119192286
59.122 37.865100671140944 0.47261149561878796
59.122 37.86610738255034 0.46037870372684
59.122 37.867114093959735 0.45840356791926
59.122 37.86812080536913 0.45640329172863275
59.122 37.869127516778526 0.45437302729732404
59.122 37.87013422818792 0.45230544052129196
59.122 37.87114093959732 0.441898379644144
59.122 37.87214765100671 0.43968230360013316
59.122 37.87315436241611 0.43738096350154526
59.122 37.874161073825505 0.42710806607324586
59.122 37.8751677852349 0.4244357186392469
59.122 37.876174496644296 0.41666445994960377
59.122 37.87718120805369 0.41351097486183874
59.122 37.87818791946309 0.4099743998296477
59.122 37.87919463087248 0.40593323949236537
59.122 37.88020134228188 0.4012398278614249
59.122 37.881208053691275 0.39572437577018027
59.122 37.88221476510067 0.3892074747371699
59.122 37.883221476510066 0.38152475549079723
59.122 37.88422818791946 0.372565314922185
59.122 37.88523489932886 0.36231971358862564
59.122 37.88624161073825 0.3509240577787167
59.122 37.88724832214765 0.3386784647579593
59.122 37.88825503355705 0.32601977352729233
59.122 37.88926174496645 0.3134456348571238
59.122 37.89026845637584 0.30141315553350845
59.122 37.89127516778524 0.2844905880024045
59.122 37.892281879194634 0.27427317463112827
59.122 37.89328859060403 0.2650758860003315
59.122 37.894295302013425 0.25677182747505506
59.122 37.89530201342282 0.24917039031371047
59.122 37.89630872483222 0.24207056547515238
59.122 37.89731543624161 0.23530347637998345
59.122 37.89832214765101 0.22876278184618334
59.122 37.899328859060404 0.22242331796888776
59.122 37.9003355704698 0.20804727534585052
59.122 37.901342281879195 0.20224253717946947
59.122 37.90234899328859 0.19704142305794795
59.122 37.90335570469799 0.19266584757002636
59.122 37.90436241610738 0.1893187967405967
59.122 37.90536912751678 0.18714616304388484
59.122 37.906375838926174 0.1862107094639558
59.122 37.90738255033557 0.1864836022671661
59.122 37.908389261744965 0.187853799298207
59.122 37.90939597315436 0.19015108325060837
59.122 37.910402684563756 0.1931760835353185
59.122 37.91140939597315 0.19673044953593727
59.122 37.91241610738255 0.20064186047701127
59.122 37.91342281879195 0.20478093797178212
59.122 37.914429530201346 0.2090695234394427
59.122 37.91543624161074 0.21348152909913504
59.122 37.91644295302014 0.21803832782102722
59.122 37.91744966442953 0.22280044989220646
59.122 37.91845637583893 0.22785653214744886
59.122 37.919463087248324 0.23330949253825423
59.122 37.92046979865772 0.23925930120419792
59.122 37.921476510067116 0.24578199778476817
59.122 37.92248322147651 0.2529061385639971
59.122 37.92348993288591 0.26059054547287447
59.122 37.9244966442953 0.2687100195202474
59.122 37.9255033557047 0.27705648918417686
59.122 37.926510067114094 0.2853598478468128
59.122 37.92751677852349 0.29332560391093776
59.122 37.928523489932886 0.30067887959755724
59.122 37.92953020134228 0.30720108407354163
59.122 37.93053691275168 0.3127487708485851
59.122 37.93154362416107 0.31725076213548203
59.122 37.93255033557047 0.32068354256866166
59.122 37.933557046979864 0.3230201549721116
59.122 37.93456375838926 0.3241281451388096
59.122 37.935570469798655 0.32355087822832085
59.122 37.93657718120805 0.32007191177091165
59.122 37.937583892617454 0.31121973047804846
59.122 37.93859060402685 0.294322389193907
59.122 37.939597315436245 0.27152763023951837
59.122 37.94060402684564 0.2513175816044548
59.122 37.94161073825504 0.23866710442224928
59.122 37.94261744966443 0.23186603411696105
59.122 37.94362416107383 0.2279805772080532
59.122 37.94463087248322 0.2252951105455653
59.122 37.94563758389262 0.22306890071334137
59.122 37.946644295302015 0.22102514201767923
59.122 37.94765100671141 0.21907466105301954
59.122 37.948657718120806 0.2171985628188392
59.122 37.9496644295302 0.21540207637674577
59.122 37.9506711409396 0.2136968640268594
59.122 37.95167785234899 0.21209432338325068
59.122 37.95268456375839 0.2106031193827148
59.122 37.953691275167785 0.20922835959100858
59.122 37.95469798657718 0.2079714054766026
59.122 37.955704697986576 0.20682989886009043
59.122 37.95671140939597 0.2057977928560942
59.122 37.95771812080537 0.20486524028805156
59.122 37.95872483221476 0.20401819229934484
59.122 37.95973154362416 0.2032375218942651
59.122 37.960738255033554 0.20249741580330885
59.122 37.96174496644295 0.20176267037034262
59.122 37.96275167785235 0.20098438299114998
59.122 37.96375838926175 0.20009336985281528
59.122 37.964765100671144 0.1989905410491165
59.122 37.96577181208054 0.19753364158333264
59.122 37.966778523489936 0.1955207211000197
59.122 37.96778523489933 0.19349778495310133
59.122 37.96879194630873 0.18942458887912025
59.122 37.96979865771812 0.18372575934324625
59.122 37.97080536912752 0.16765676408967012
59.122 37.971812080536914 0.15770120838697552
59.122 37.97281879194631 0.14550936230819428
59.122 37.973825503355705 0.13163310501106731
59.122 37.9748322147651 0.11701836301461231
59.122 37.9758389261745 0.1027052144677571
59.122 37.97684563758389 0.08949006723675387
59.122 37.97785234899329 0.07776246247240408
59.122 37.978859060402684 0.06755636520544768
59.122 37.97986577181208 0.058703353846047815
59.122 37.980872483221475 0.05097108359473676
59.122 37.98187919463087 0.05168136007363289
59.122 37.982885906040266 0.03834413548852475
59.122 37.98389261744966 0.03278602924172301
59.122 37.98489932885906 0.027719135243724477
59.122 37.98590604026845 0.02306252910066725
59.122 37.98691275167785 0.018750131094623035
59.122 37.98791946308725 0.014726715163559445
59.122 37.98892617449665 0.01094578680723346
59.122 37.98993288590604 0.007368394183341384
59.122 37.99093959731544 0.0039622874615371645
59.122 37.991946308724835 0.002034525925045702
59.122 37.99295302013423 -0.001157688022126721
59.122 37.993959731543626 -0.0042416856032087
59.122 37.99496644295302 -0.007230608137092628
59.122 37.99597315436242 -0.010134637937581834
59.122 37.99697986577181 -0.012961597516569195
59.122 37.99798657718121 -0.01571745967840031
59.122 37.998993288590604 -0.01840677063560872
59.123 37.85 0.5084381058454062
59.123 37.8510067114094 0.5069266451581945
59.123 37.85201342281879 0.4967616043698546
59.123 37.85302013422819 0.4951830172000165
59.123 37.854026845637584 0.4935777625048845
59.123 37.85503355704698 0.4919464699990482
59.123 37.856040268456375 0.4902898459303589
59.123 37.85704697986577 0.48860866974237077
59.123 37.85805369127517 0.486903787733364
59.123 37.85906040268456 0.4851761025717935
59.123 37.86006711409396 0.4834265571108322
59.123 37.861073825503354 0.4816561103736263
59.123 37.86208053691275 0.4798657028010207
59.123 37.86308724832215 0.4780562067894548
59.123 37.86409395973155 0.476228357096237
59.123 37.865100671140944 0.46386243454474824
59.123 37.86610738255034 0.4618953918253835
59.123 37.867114093959735 0.45990926028049356
59.123 37.86812080536913 0.4579027905723229
59.123 37.869127516778526 0.45587357092288044
59.123 37.87013422818792 0.4538175489004308
59.123 37.87114093959732 0.45172838162078316
59.123 37.87214765100671 0.44959655756307426
59.123 37.87315436241611 0.4309796671402517
59.123 37.874161073825505 0.42153737543955105
59.123 37.8751677852349 0.41900980116493514
59.123 37.876174496644296 0.41882029017562317
59.123 37.87718120805369 0.41599747598422604
59.123 37.87818791946309 0.41290981149643363
59.123 37.87919463087248 0.40946522346565534
59.123 37.88020134228188 0.40554552367713875
59.123 37.881208053691275 0.40100390038265693
59.123 37.88221476510067 0.3956662050685007
59.123 37.883221476510066 0.3893394232217198
59.123 37.88422818791946 0.38183143035307
59.123 37.88523489932886 0.3729851848879802
59.123 37.88624161073825 0.3627261543967357
59.123 37.88724832214765 0.35111312046560583
59.123 37.88825503355705 0.3383722527389113
59.123 37.88926174496645 0.32489052276769415
59.123 37.89026845637584 0.3054480178635588
59.123 37.89127516778524 0.2918704729767068
59.123 37.892281879194634 0.27892712406066594
59.123 37.89328859060403 0.26685927057262643
59.123 37.894295302013425 0.2557505929296647
59.123 37.89530201342282 0.24557020562365703
59.123 37.89630872483222 0.23623586014941472
59.123 37.89731543624161 0.22767284667240056
59.123 37.89832214765101 0.219853635549407
59.123 37.899328859060404 0.2128130862626517
59.123 37.9003355704698 0.1983731919906481
59.123 37.901342281879195 0.1930577296780479
59.123 37.90234899328859 0.18885383191420568
59.123 37.90335570469799 0.18585653058742557
59.123 37.90436241610738 0.1841117859362218
59.123 37.90536912751678 0.18360609156605576
59.123 37.906375838926174 0.18426667620980208
59.123 37.90738255033557 0.18597030303047724
59.123 37.908389261744965 0.18855812584827958
59.123 37.90939597315436 0.19185409625460598
59.123 37.910402684563756 0.19568446381013238
59.123 37.91140939597315 0.19989591128098866
59.123 37.91241610738255 0.2043700992566567
59.123 37.91342281879195 0.20903306448059858
59.123 37.914429530201346 0.21385888472149356
59.123 37.91543624161074 0.21886788561222761
59.123 37.91644295302014 0.22412004936473223
59.123 37.91744966442953 0.22970410676904662
59.123 37.91845637583893 0.23572232432303603
59.123 37.919463087248324 0.24227077945372694
59.123 37.92046979865772 0.2494155774683827
59.123 37.921476510067116 0.257167406586168
59.123 37.92248322147651 0.2654597309940758
59.123 37.92348993288591 0.2741382878857707
59.123 37.9244966442953 0.2829690006768984
59.123 37.9255033557047 0.2916663482504544
59.123 37.926510067114094 0.29993604845221944
59.123 37.92751677852349 0.3075192574734781
59.123 37.928523489932886 0.31422491533643876
59.123 37.92953020134228 0.31994275239841014
59.123 37.93053691275168 0.3246376177493823
59.123 37.93154362416107 0.3283311764159603
59.123 37.93255033557047 0.33107729743873754
59.123 37.933557046979864 0.33293284624817693
59.123 37.93456375838926 0.33391601520395287
59.123 37.935570469798655 0.3339262322496234
59.123 37.93657718120805 0.33256738536416885
59.123 37.937583892617454 0.3287964384960451
59.123 37.93859060402685 0.3205648673872473
59.123 37.939597315436245 0.30585302995505614
59.123 37.94060402684564 0.2868570629532861
59.123 37.94161073825504 0.27061674133112157
59.123 37.94261744966443 0.2607548606155716
59.123 37.94362416107383 0.2555819888319005
59.123 37.94463087248322 0.25267681463374575
59.123 37.94563758389262 0.2506799309722031
59.123 37.946644295302015 0.24901827362625648
59.123 37.94765100671141 0.24748235416612724
59.123 37.948657718120806 0.24600667399380272
59.123 37.9496644295302 0.2445783029820176
59.123 37.9506711409396 0.2432014421781656
59.123 37.95167785234899 0.24188401004819118
59.123 37.95268456375839 0.24063261108623504
59.123 37.953691275167785 0.2394506613529373
59.123 37.95469798657718 0.23833767166816502
59.123 37.955704697986576 0.23728888396710823
59.123 37.95671140939597 0.2362948808653311
59.123 37.95771812080537 0.2353409188066378
59.123 37.95872483221476 0.2344057454302784
59.123 37.95973154362416 0.23345961400258058
59.123 37.960738255033554 0.232461126333403
59.123 37.96174496644295 0.2313524424355378
59.123 37.96275167785235 0.23005233872658853
59.123 37.96375838926175 0.22844669425389125
59.123 37.964765100671144 0.22726246356553947
59.123 37.96577181208054 0.22448241996178325
59.123 37.966778523489936 0.22073683744527028
59.123 37.96778523489933 0.21567758027984085
59.123 37.96879194630873 0.2089255206518985
59.123 37.96979865771812 0.2001506416019212
59.123 37.97080536912752 0.18090058909105405
59.123 37.971812080536914 0.16805985183218136
59.123 37.97281879194631 0.15378942491340583
59.123 37.973825503355705 0.1389560421683817
59.123 37.9748322147651 0.12447515408305038
59.123 37.9758389261745 0.11104365869897566
59.123 37.97684563758389 0.09900319602665228
59.123 37.97785234899329 0.08836988432914125
59.123 37.978859060402684 0.07895896571892155
59.123 37.97986577181208 0.07360600636079953
59.123 37.980872483221475 0.06585597827258985
59.123 37.98187919463087 0.055736910313773286
59.123 37.982885906040266 0.04478461102122618
59.123 37.98389261744966 0.03849170038311358
59.123 37.98489932885906 0.037062218011674794
59.123 37.98590604026845 0.031473675276208604
59.123 37.98691275167785 0.02625827913802027
59.123 37.98791946308725 0.02140287093093063
59.123 37.98892617449665 0.016884877601473447
59.123 37.98993288590604 0.01267432020549282
59.123 37.99093959731544 0.0087372149896459
59.123 37.991946308724835 0.006392133871131342
59.123 37.99295302013423 0.00284472902712872
59.123 37.993959731543626 -0.0005250143083069536
59.123 37.99496644295302 -0.0037424877860077616
59.123 37.99597315436242 -0.006828913610679166
59.123 37.99697986577181 -0.009801623952565744
59.123 37.99798657718121 -0.012674529737776025
59.123 37.998993288590604 -0.01545865429502137
59.124 37.85 0.5099828263140381
59.124 37.8510067114094 0.5084752348664318
59.124 37.85201342281879 0.5069383065591637
59.124 37.85302013422819 0.5053724682675496
59.124 37.854026845637584 0.49539215032670914
59.124 37.85503355704698 0.4937584037820478
59.124 37.856040268456375 0.4920975677936933
59.124 37.85704697986577 0.49041042944763713
59.124 37.85805369127517 0.4886978657901856
59.124 37.85906040268456 0.48696083924635025
59.124 37.86006711409396 0.4852003891372739
59.124 37.861073825503354 0.4834176178180051
59.124 37.86208053691275 0.48161366941743755
59.124 37.86308724832215 0.4797896984250241
59.124 37.86409395973155 0.467287216263462
59.124 37.865100671140944 0.4653214763692095
59.124 37.86610738255034 0.4633377273385751
59.124 37.867114093959735 0.4613364694147135
59.124 37.86812080536913 0.4593177110432169
59.124 37.869127516778526 0.45728072755863336
59.124 37.87013422818792 0.45522372945897116
59.124 37.87114093959732 0.44375045057734885
59.124 37.87214765100671 0.44159430976004593
59.124 37.87315436241611 0.43940088655362436
59.124 37.874161073825505 0.429392359102223
59.124 37.8751677852349 0.4269846202142443
59.124 37.876174496644296 0.42058652606179786
59.124 37.87718120805369 0.4179816131855417
59.124 37.87818791946309 0.415196215199016
59.124 37.87919463087248 0.4121622238901301
59.124 37.88020134228188 0.4087884773968769
59.124 37.881208053691275 0.4049553709618172
59.124 37.88221476510067 0.40051005179540616
59.124 37.883221476510066 0.3952641348152713
59.124 37.88422818791946 0.3889972595795916
59.124 37.88523489932886 0.381471294446729
59.124 37.88624161073825 0.37246056048479836
59.124 37.88724832214765 0.3618010775663437
59.124 37.88825503355705 0.3494541566565521
59.124 37.88926174496645 0.3355666548669991
59.124 37.89026845637584 0.31476230629926677
59.124 37.89127516778524 0.29897070372954826
59.124 37.892281879194634 0.2831632500039328
59.124 37.89328859060403 0.2679297035419229
59.124 37.894295302013425 0.2537271639919816
59.124 37.89530201342282 0.24084375685839216
59.124 37.89630872483222 0.2294203003377997
59.124 37.89731543624161 0.21950110324874478
59.124 37.89832214765101 0.2110831353077387
59.124 37.899328859060404 0.20414694860761923
59.124 37.9003355704698 0.19866799272366414
59.124 37.901342281879195 0.18624862897592875
59.124 37.90234899328859 0.18344342211931508
59.124 37.90335570469799 0.18195660634696267
59.124 37.90436241610738 0.18170409126043507
59.124 37.90536912751678 0.1825794821534356
59.124 37.906375838926174 0.18445611114736307
59.124 37.90738255033557 0.18719169487551351
59.124 37.908389261744965 0.19063587233273957
59.124 37.90939597315436 0.1946405223008429
59.124 37.910402684563756 0.19907193883596808
59.124 37.91140939597315 0.20382309670829502
59.124 37.91241610738255 0.20882384905688706
59.124 37.91342281879195 0.21404715532184432
59.124 37.914429530201346 0.2195101204360742
59.124 37.91543624161074 0.2252693008089486
59.124 37.91644295302014 0.23141008860035472
59.124 37.91744966442953 0.2380300948682292
59.124 37.91845637583893 0.24521679380873457
59.124 37.919463087248324 0.2530209157022901
59.124 37.92046979865772 0.2614295111316563
59.124 37.921476510067116 0.2703455950376534
59.124 37.92248322147651 0.2795828285388804
59.124 37.92348993288591 0.2888813196546518
59.124 37.9244966442953 0.29794358755288675
59.124 37.9255033557047 0.30648101645034137
59.124 37.926510067114094 0.3142561980677396
59.124 37.92751677852349 0.32110893411031594
59.124 37.928523489932886 0.32696159106256334
59.124 37.92953020134228 0.3318076501058332
59.124 37.93053691275168 0.33569142690014014
59.124 37.93154362416107 0.33868644924397245
59.124 37.93255033557047 0.34087681560859473
59.124 37.933557046979864 0.3423420078624531
59.124 37.93456375838926 0.3431415444139986
59.124 37.935570469798655 0.34328985798201944
59.124 37.93657718120805 0.34269975607214864
59.124 37.937583892617454 0.3410509557552123
59.124 37.93859060402685 0.33753567938020035
59.124 37.939597315436245 0.3306585711228306
59.124 37.94060402684564 0.3192163275652184
59.124 37.94161073825504 0.30536327401235797
59.124 37.94261744966443 0.2941526994854456
59.124 37.94362416107383 0.2875248938240316
59.124 37.94463087248322 0.2839686392676103
59.124 37.94563758389262 0.2818021321302149
59.124 37.946644295302015 0.28014596976008255
59.124 37.94765100671141 0.27864593885199035
59.124 37.948657718120806 0.2771795718134879
59.124 37.9496644295302 0.2757131819350074
59.124 37.9506711409396 0.2742440264545538
59.124 37.95167785234899 0.27277823304058463
59.124 37.95268456375839 0.27132246056260617
59.124 37.953691275167785 0.2698806530867142
59.124 37.95469798657718 0.26845259853052506
59.124 37.955704697986576 0.26703299746279685
59.124 37.95671140939597 0.2656104534675584
59.124 37.95771812080537 0.26416601684898194
59.124 37.95872483221476 0.26267094624534937
59.124 37.95973154362416 0.26108331141508523
59.124 37.960738255033554 0.259343003572305
59.124 37.96174496644295 0.25736471464015753
59.124 37.96275167785235 0.25594444570997643
59.124 37.96375838926175 0.2530570745629552
59.124 37.964765100671144 0.2494238216520226
59.124 37.96577181208054 0.24475811451896662
59.124 37.966778523489936 0.2387170823224904
59.124 37.96778523489933 0.23093815247802493
59.124 37.96879194630873 0.22112011308616208
59.124 37.96979865771812 0.20914949366086344
59.124 37.97080536912752 0.18695434180761356
59.124 37.971812080536914 0.17179513173596495
59.124 37.97281879194631 0.156174819747802
59.124 37.973825503355705 0.14104046957900493
59.124 37.9748322147651 0.12713372913478665
59.124 37.9758389261745 0.11484002363278017
59.124 37.97684563758389 0.10728647765650844
59.124 37.97785234899329 0.09805251119798544
59.124 37.978859060402684 0.0899520887778667
59.124 37.97986577181208 0.08265752297922299
59.124 37.980872483221475 0.07588360730940999
59.124 37.98187919463087 0.06231264737022901
59.124 37.982885906040266 0.05604647546497997
59.124 37.98389261744966 0.04986710793287088
59.124 37.98489932885906 0.04376084960880749
59.124 37.98590604026845 0.03775806089482611
59.124 37.98691275167785 0.036428848967374
59.124 37.98791946308725 0.03074974125186239
59.124 37.98892617449665 0.025347093657333092
59.124 37.98993288590604 0.020256998771618365
59.124 37.99093959731544 0.015494296252030054
59.124 37.991946308724835 0.01242730280540863
59.124 37.99295302013423 0.008236304317676968
59.124 37.993959731543626 0.004322641423253629
59.124 37.99496644295302 0.0006546915194620279
59.124 37.99597315436242 -0.0027992708575961117
59.124 37.99697986577181 -0.00606871713793479
59.124 37.99798657718121 -0.00917964269531113
59.124 37.998993288590604 -0.012154154135619002
59.125 37.85 0.5114664340618461
59.125 37.8510067114094 0.5099629945102324
59.125 37.85201342281879 0.5084286349093592
59.125 37.85302013422819 0.5068637316001192
59.125 37.854026845637584 0.5052687565566242
59.125 37.85503355704698 0.5036442820217323
59.125 37.856040268456375 0.49383878972397866
59.125 37.85704697986577 0.49214619157284933
59.125 37.85805369127517 0.49042645801047957
59.125 37.85906040268456 0.48868059145555803
59.125 37.86006711409396 0.4869097005391707
59.125 37.861073825503354 0.48511499356792326
59.125 37.86208053691275 0.48329776684726866
59.125 37.86308724832215 0.47066013571255083
59.125 37.86409395973155 0.46869565416904513
59.125 37.865100671140944 0.46671185704343965
59.125 37.86610738255034 0.4647100731748336
59.125 37.867114093959735 0.4626914837693807
59.125 37.86812080536913 0.4606570026655824
59.125 37.869127516778526 0.44899019460784784
59.125 37.87013422818792 0.4468765067458885
59.125 37.87114093959732 0.44474596542016
59.125 37.87214765100671 0.44259597107956505
59.125 37.87315436241611 0.4404218881461326
59.125 37.874161073825505 0.4304991106696134
59.125 37.8751677852349 0.4281548215025611
59.125 37.876174496644296 0.4280517516635719
59.125 37.87718120805369 0.4256015481975738
59.125 37.87818791946309 0.42303044969336645
59.125 37.87919463087248 0.41428101260238537
59.125 37.88020134228188 0.4112877694681272
59.125 37.881208053691275 0.4079589723526914
59.125 37.88221476510067 0.40416528765663245
59.125 37.883221476510066 0.3997348151435096
59.125 37.88422818791946 0.3944451472966233
59.125 37.88523489932886 0.3880198204443852
59.125 37.88624161073825 0.3801359047674033
59.125 37.88724832214765 0.3704531052910579
59.125 37.88825503355705 0.3586761689850556
59.125 37.88926174496645 0.3446556610773228
59.125 37.89026845637584 0.3227484713823098
59.125 37.89127516778524 0.30487545134553884
59.125 37.892281879194634 0.2861888513053311
59.125 37.89328859060403 0.26772364053231174
59.125 37.894295302013425 0.2504748107034689
59.125 37.89530201342282 0.2351807465519658
59.125 37.89630872483222 0.22223048711917992
59.125 37.89731543624161 0.21170229918390404
59.125 37.89832214765101 0.203470965536714
59.125 37.899328859060404 0.19731612702576945
59.125 37.9003355704698 0.19299720273040044
59.125 37.901342281879195 0.18196257947569283
59.125 37.90234899328859 0.18054375437605935
59.125 37.90335570469799 0.18037950716469675
59.125 37.90436241610738 0.18132534416714213
59.125 37.90536912751678 0.18324809442959783
59.125 37.906375838926174 0.1860192902132592
59.125 37.90738255033557 0.1895123856942161
59.125 37.908389261744965 0.1936046231015766
59.125 37.90939597315436 0.19818330385550398
59.125 37.910402684563756 0.20315506532586058
59.125 37.91140939597315 0.2084558305157617
59.125 37.91241610738255 0.21405872466979056
59.125 37.91342281879195 0.21997750839806043
59.125 37.914429530201346 0.2262637196704257
59.125 37.91543624161074 0.23299643153638577
59.125 37.91644295302014 0.24026426374504592
59.125 37.91744966442953 0.2481403936157521
59.125 37.91845637583893 0.2566533360884233
59.125 37.919463087248324 0.2657592810220436
59.125 37.92046979865772 0.27532462864609225
59.125 37.921476510067116 0.2851275763292564
59.125 37.92248322147651 0.2948829229877367
59.125 37.92348993288591 0.3042853363197771
59.125 37.9244966442953 0.3130578720931364
59.125 37.9255033557047 0.3209902515804966
59.125 37.926510067114094 0.32795692321768627
59.125 37.92751677852349 0.3339142476949981
59.125 37.928523489932886 0.3388834097806461
59.125 37.92953020134228 0.34292789565745047
59.125 37.93053691275168 0.3461324868495865
59.125 37.93154362416107 0.3485873071811977
59.125 37.93255033557047 0.3503775133922069
59.125 37.933557046979864 0.3515773879546844
59.125 37.93456375838926 0.35224650575734334
59.125 37.935570469798655 0.3524243539679268
59.125 37.93657718120805 0.3521167331898519
59.125 37.937583892617454 0.3512600020869594
59.125 37.93859060402685 0.3496368849622872
59.125 37.939597315436245 0.3467254336650021
59.125 37.94060402684564 0.34164705084502667
59.125 37.94161073825504 0.3339693530326881
59.125 37.94261744966443 0.3254395997461942
59.125 37.94362416107383 0.318853340259468
59.125 37.94463087248322 0.314768746932833
59.125 37.94563758389262 0.31214729920561024
59.125 37.946644295302015 0.31010419542075796
59.125 37.94765100671141 0.30822027407115876
59.125 37.948657718120806 0.3063408787147176
59.125 37.9496644295302 0.3044213206096996
59.125 37.9506711409396 0.30245673305795656
59.125 37.95167785234899 0.3004543734075621
59.125 37.95268456375839 0.298422986801832
59.125 37.953691275167785 0.29636851104716166
59.125 37.95469798657718 0.2942919753135131
59.125 37.955704697986576 0.292187961048696
59.125 37.95671140939597 0.29004288434535036
59.125 37.95771812080537 0.2878326371597199
59.125 37.95872483221476 0.2855191767247572
59.125 37.95973154362416 0.284016047571716
59.125 37.960738255033554 0.281272348634683
59.125 37.96174496644295 0.27816934577914476
59.125 37.96275167785235 0.27454483222799864
59.125 37.96375838926175 0.27018057519935706
59.125 37.964765100671144 0.2647957795639603
59.125 37.96577181208054 0.2580535165150015
59.125 37.966778523489936 0.2495938127165504
59.125 37.96778523489933 0.23910841502902996
59.125 37.96879194630873 0.226460767346778
59.125 37.96979865771812 0.21182197648079426
59.125 37.97080536912752 0.18749095989588377
59.125 37.971812080536914 0.17097302057719446
59.125 37.97281879194631 0.1548994496516811
59.125 37.973825503355705 0.14010507482031662
59.125 37.9748322147651 0.1301296698110871
59.125 37.9758389261745 0.11896151328285749
59.125 37.97684563758389 0.10952912898910896
59.125 37.97785234899329 0.10152872461164077
59.125 37.978859060402684 0.0946140168035311
59.125 37.97986577181208 0.08845928578367994
59.125 37.980872483221475 0.08278715523142291
59.125 37.98187919463087 0.0703598004898995
59.125 37.982885906040266 0.0650835214787916
59.125 37.98389261744966 0.05977809057819964
59.125 37.98489932885906 0.05436863513140777
59.125 37.98590604026845 0.048824126087191604
59.125 37.98691275167785 0.04315508020159056
59.125 37.98791946308725 0.03740852918877056
59.125 37.98892617449665 0.03620549232051831
59.125 37.98993288590604 0.030481738762997895
59.125 37.99093959731544 0.02493006199950075
59.125 37.991946308724835 0.02101525660556519
59.125 37.99295302013423 0.015948131179281733
59.125 37.993959731543626 0.01120227791223738
59.125 37.99496644295302 0.006780197738115626
59.125 37.99597315436242 0.0026672331867018118
59.125 37.99697986577181 -0.0011621616331304874
59.125 37.99798657718121 -0.004738810368549021
59.125 37.998993288590604 -0.008094580170023236
59.126 37.85 0.5128894306927557
59.126 37.8510067114094 0.511390318490236
59.126 37.85201342281879 0.5098587616637492
59.126 37.85302013422819 0.5082950848754973
59.126 37.854026845637584 0.5066997162930877
59.126 37.85503355704698 0.5050731939193671
59.126 37.856040268456375 0.5034161715993538
59.126 37.85704697986577 0.5017294244781727
59.126 37.85805369127517 0.5000138536048526
59.126 37.85906040268456 0.49033561422409694
59.126 37.86006711409396 0.4885548040760313
59.126 37.861073825503354 0.4867486652030872
59.126 37.86208053691275 0.48491861378485707
59.126 37.86308724832215 0.47201930569110645
59.126 37.86409395973155 0.4700374073538397
59.126 37.865100671140944 0.46803545585230677
59.126 37.86610738255034 0.46601517079236643
59.126 37.867114093959735 0.4541335489093524
59.126 37.86812080536913 0.4520322026069334
59.126 37.869127516778526 0.4499170843815927
59.126 37.87013422818792 0.4477891759012132
59.126 37.87114093959732 0.4456488460711529
59.126 37.87214765100671 0.44349553265534936
59.126 37.87315436241611 0.44132729747960275
59.126 37.874161073825505 0.43147322222160445
59.126 37.8751677852349 0.42916594337407915
59.126 37.876174496644296 0.42912837181529867
59.126 37.87718120805369 0.4267707422821096
59.126 37.87818791946309 0.42433448889544617
59.126 37.87919463087248 0.4217829189356303
59.126 37.88020134228188 0.419062762868306
59.126 37.881208053691275 0.4160973483241687
59.126 37.88221476510067 0.4069549640772781
59.126 37.883221476510066 0.40311527909977707
59.126 37.88422818791946 0.3985500022419189
59.126 37.88523489932886 0.3929643484064793
59.126 37.88624161073825 0.3859713324295083
59.126 37.88724832214765 0.3770951775608594
59.126 37.88825503355705 0.3658158649450709
59.126 37.88926174496645 0.35168715844536813
59.126 37.89026845637584 0.328759928179846
59.126 37.89127516778524 0.30889442701957465
59.126 37.892281879194634 0.2874365604325911
59.126 37.89328859060403 0.26596177069201055
59.126 37.894295302013425 0.2461125455573112
59.126 37.89530201342282 0.2290942176393661
59.126 37.89630872483222 0.21543550421638322
59.126 37.89731543624161 0.20507911409095642
59.126 37.89832214765101 0.19763499802756945
59.126 37.899328859060404 0.1926132688772044
59.126 37.9003355704698 0.18956141268641757
59.126 37.901342281879195 0.17982658162128734
59.126 37.90234899328859 0.17958073148748085
59.126 37.90335570469799 0.18046455460288946
59.126 37.90436241610738 0.18232505748131472
59.126 37.90536912751678 0.18503830901811347
59.126 37.906375838926174 0.18849595317881848
59.126 37.90738255033557 0.19259859598524412
59.126 37.908389261744965 0.1972557676401638
59.126 37.90939597315436 0.20239118807485396
59.126 37.910402684563756 0.20795105257005486
59.126 37.91140939597315 0.21391226687612278
59.126 37.91241610738255 0.22028729461802188
59.126 37.91342281879195 0.22712261841221132
59.126 37.914429530201346 0.23448866345424638
59.126 37.91543624161074 0.24246036737163162
59.126 37.91644295302014 0.2510896476587536
59.126 37.91744966442953 0.2603740975003976
59.126 37.91845637583893 0.2702299014070201
59.126 37.919463087248324 0.2804793276271602
59.126 37.92046979865772 0.2908613504963033
59.126 37.921476510067116 0.30106650192270956
59.126 37.92248322147651 0.3107866295680199
59.126 37.92348993288591 0.31976317937512844
59.126 37.9244966442953 0.32781891366007465
59.126 37.9255033557047 0.3348665561961965
59.126 37.926510067114094 0.34089767555490946
59.126 37.92751677852349 0.34596067786719825
59.126 37.928523489932886 0.3501368732683871
59.126 37.92953020134228 0.3535204427095357
59.126 37.93053691275168 0.35620455003896784
59.126 37.93154362416107 0.3582733638959086
59.126 37.93255033557047 0.3597986053663032
59.126 37.933557046979864 0.3608389995426371
59.126 37.93456375838926 0.36144115848080904
59.126 37.935570469798655 0.36164053150560543
59.126 37.93657718120805 0.36146072658234896
59.126 37.937583892617454 0.36090814191881593
59.126 37.93859060402685 0.3599555374921966
59.126 37.939597315436245 0.3585035505848827
59.126 37.94060402684564 0.35631995579991194
59.126 37.94161073825504 0.3530636798655994
59.126 37.94261744966443 0.3487290960395271
59.126 37.94362416107383 0.34422483344515953
59.126 37.94463087248322 0.3405184844114743
59.126 37.94563758389262 0.33760326916604744
59.126 37.946644295302015 0.33505547695588667
59.126 37.94765100671141 0.3325898981236899
59.126 37.948657718120806 0.3300903565367071
59.126 37.9496644295302 0.3275253901896077
59.126 37.9506711409396 0.3248959783904066
59.126 37.95167785234899 0.3222126947762168
59.126 37.95268456375839 0.3194863914898275
59.126 37.953691275167785 0.31672409460989515
59.126 37.95469798657718 0.31392665248991813
59.126 37.955704697986576 0.31108670362908664
59.126 37.95671140939597 0.30818625774440295
59.126 37.95771812080537 0.30618742326999177
59.126 37.95872483221476 0.3030246780874247
59.126 37.95973154362416 0.29964400842894845
59.126 37.960738255033554 0.2959368999007361
59.126 37.96174496644295 0.29175145023009386
59.126 37.96275167785235 0.28688168428654015
59.126 37.96375838926175 0.28105994769394604
59.126 37.964765100671144 0.2739598009378562
59.126 37.96577181208054 0.265221713126565
59.126 37.966778523489936 0.25451658395853194
59.126 37.96778523489933 0.24165502549061946
59.126 37.96879194630873 0.2267239836476957
59.126 37.96979865771812 0.2101896777548855
59.126 37.97080536912752 0.18771820262129255
59.126 37.971812080536914 0.17072030102415878
59.126 37.97281879194631 0.15489314783850966
59.126 37.973825503355705 0.1408583491669853
59.126 37.9748322147651 0.1288535687699898
59.126 37.9758389261745 0.11879800435604591
59.126 37.97684563758389 0.1104206783222357
59.126 37.97785234899329 0.10337928885776032
59.126 37.978859060402684 0.09733790003714676
59.126 37.97986577181208 0.0920041420068326
59.126 37.980872483221475 0.0833618388044429
59.126 37.98187919463087 0.07562187889360053
59.126 37.982885906040266 0.07121170338014399
59.126 37.98389261744966 0.06681622337203524
59.126 37.98489932885906 0.06234368589361057
59.126 37.98590604026845 0.05772397825105305
59.126 37.98691275167785 0.052908466333031895
59.126 37.98791946308725 0.04787239282410387
59.126 37.98892617449665 0.042618381181131676
59.126 37.98993288590604 0.03717889254699374
59.126 37.99093959731544 0.036193508122641084
59.126 37.991946308724835 0.031941107724375986
59.126 37.99295302013423 0.026278776918985988
59.126 37.993959731543626 0.020768377737899003
59.126 37.99496644295302 0.015489740772177296
59.126 37.99597315436242 0.010499575965217223
59.126 37.99697986577181 0.005828495272457837
59.126 37.99798657718121 0.0014831364409955694
59.126 37.998993288590604 -0.002548494699830095
59.127 37.85 0.5142525596834792
59.127 37.8510067114094 0.5127578518806027
59.127 37.85201342281879 0.5112292354374176
59.127 37.85302013422819 0.5096669835775184
59.127 37.854026845637584 0.5080714804727968
59.127 37.85503355704698 0.5064432291214944
59.127 37.856040268456375 0.5047828591298711
59.127 37.85704697986577 0.5030911342253765
59.127 37.85805369127517 0.501368959270271
59.127 37.85906040268456 0.49961738646605935
59.127 37.86006711409396 0.49783762033355966
59.127 37.861073825503354 0.4960310209098605
59.127 37.86208053691275 0.4752883895337067
59.127 37.86308724832215 0.4733123351159399
59.127 37.86409395973155 0.47131343803809234
59.127 37.865100671140944 0.45921725866936647
59.127 37.86610738255034 0.45712798360006957
59.127 37.867114093959735 0.4550218285441467
59.127 37.86812080536913 0.45290090437043345
59.127 37.869127516778526 0.45076725337969453
59.127 37.87013422818792 0.44862273381206375
59.127 37.87114093959732 0.4464688552776135
59.127 37.87214765100671 0.4443065448040621
59.127 37.87315436241611 0.44213581394954204
59.127 37.874161073825505 0.4399552834826299
59.127 37.8751677852349 0.43005300949068176
59.127 37.876174496644296 0.43005742033463684
59.127 37.87718120805369 0.42775917153224186
59.127 37.87818791946309 0.42541217560323114
59.127 37.87919463087248 0.42298903885141736
59.127 37.88020134228188 0.4204479200794592
59.127 37.881208053691275 0.4177255199669895
59.127 37.88221476510067 0.4147266446115497
59.127 37.883221476510066 0.41130891809417036
59.127 37.88422818791946 0.4072611849696417
59.127 37.88523489932886 0.402275185175015
59.127 37.88624161073825 0.39026753669374065
59.127 37.88724832214765 0.381935956098691
59.127 37.88825503355705 0.3709289308587814
59.127 37.88926174496645 0.3565320283254281
59.127 37.89026845637584 0.33251441102548707
59.127 37.89127516778524 0.3107045549649276
59.127 37.892281879194634 0.28671191968137877
59.127 37.89328859060403 0.26273008232456135
59.127 37.894295302013425 0.24104541293538725
59.127 37.89530201342282 0.22319281640907934
59.127 37.89630872483222 0.2096379592061958
59.127 37.89731543624161 0.20003531718931689
59.127 37.89832214765101 0.19368118599256134
59.127 37.899328859060404 0.18985118218522912
59.127 37.9003355704698 0.1879538705230378
59.127 37.901342281879195 0.18755983733538376
59.127 37.90234899328859 0.17999631340082606
59.127 37.90335570469799 0.18169140732750055
59.127 37.90436241610738 0.18426254257697705
59.127 37.90536912751678 0.18760969876303238
59.127 37.906375838926174 0.1916523095675756
59.127 37.90738255033557 0.19632215375332657
59.127 37.908389261744965 0.20156351345028473
59.127 37.90939597315436 0.20733827641153865
59.127 37.910402684563756 0.21363278527107893
59.127 37.91140939597315 0.22046255337703596
59.127 37.91241610738255 0.22787087547892984
59.127 37.91342281879195 0.2359181473273263
59.127 37.914429530201346 0.2446605927850547
59.127 37.91543624161074 0.2541202894931525
59.127 37.91644295302014 0.264252742559196
59.127 37.91744966442953 0.2749224960390638
59.127 37.91845637583893 0.28589855070693954
59.127 37.919463087248324 0.2968766663161568
59.127 37.92046979865772 0.3075250985275329
59.127 37.921476510067116 0.31753927611804283
59.127 37.92248322147651 0.32668678442836635
59.127 37.92348993288591 0.33482971434207576
59.127 37.9244966442953 0.3419225525485756
59.127 37.9255033557047 0.3479929446623651
59.127 37.926510067114094 0.3531157518625169
59.127 37.92751677852349 0.3573887389617419
59.127 37.928523489932886 0.3609141943948957
59.127 37.92953020134228 0.3637873448072928
59.127 37.93053691275168 0.366090501303595
59.127 37.93154362416107 0.3678912486536254
59.127 37.93255033557047 0.36924310324641546
59.127 37.933557046979864 0.3701874551644536
59.127 37.93456375838926 0.37075601275692144
59.127 37.935570469798655 0.3709732807189108
59.127 37.93657718120805 0.3708588036793898
59.127 37.937583892617454 0.37042899525227846
59.127 37.93859060402685 0.36969830434134854
59.127 37.939597315436245 0.36867906166764747
59.127 37.94060402684564 0.3673782003446482
59.127 37.94161073825504 0.365787763306676
59.127 37.94261744966443 0.36387445281746666
59.127 37.94362416107383 0.3616101423912804
59.127 37.94463087248322 0.35905265430703037
59.127 37.94563758389262 0.35631318694616204
59.127 37.946644295302015 0.3534506054080737
59.127 37.94765100671141 0.35047870462710984
59.127 37.948657718120806 0.3474057565927369
59.127 37.9496644295302 0.34424531865875124
59.127 37.9506711409396 0.34101386722308447
59.127 37.95167785234899 0.3377270908754928
59.127 37.95268456375839 0.334397092200908
59.127 37.953691275167785 0.3310302281585405
59.127 37.95469798657718 0.3286665725371892
59.127 37.955704697986576 0.32518484657344193
59.127 37.95671140939597 0.321628559434622
59.127 37.95771812080537 0.31795531263900634
59.127 37.95872483221476 0.31409935926252325
59.127 37.95973154362416 0.30996389591151885
59.127 37.960738255033554 0.30541147081300235
59.127 37.96174496644295 0.30025338371342114
59.127 37.96275167785235 0.29424079125479374
59.127 37.96375838926175 0.28706350093766764
59.127 37.964765100671144 0.27836705658552824
59.127 37.96577181208054 0.26780264513485647
59.127 37.966778523489936 0.2551215903529292
59.127 37.96778523489933 0.24357014221382312
59.127 37.96879194630873 0.2269265957078765
59.127 37.96979865771812 0.2092160233546587
59.127 37.97080536912752 0.18301845147439472
59.127 37.971812080536914 0.1662709096245436
59.127 37.97281879194631 0.15121375036216353
59.127 37.973825503355705 0.1382264187502128
59.127 37.9748322147651 0.12733027554986923
59.127 37.9758389261745 0.11830552417107806
59.127 37.97684563758389 0.11082139014865874
59.127 37.97785234899329 0.1045317891041974
59.127 37.978859060402684 0.09912724787633648
59.127 37.97986577181208 0.09435366647408452
59.127 37.980872483221475 0.08628512421618062
59.127 37.98187919463087 0.07910024698593339
59.127 37.982885906040266 0.07524403015794902
59.127 37.98389261744966 0.0714593252796075
59.127 37.98489932885906 0.06767220154247028
59.127 37.98590604026845 0.06382026285391053
59.127 37.98691275167785 0.05984906815819468
59.127 37.98791946308725 0.05571087241100939
59.127 37.98892617449665 0.051365704930701375
59.127 37.98993288590604 0.04678467017242356
59.127 37.99093959731544 0.041954975232470965
59.127 37.991946308724835 0.037956573382479285
59.127 37.99295302013423 0.037593963863251395
59.127 37.993959731543626 0.032061686102218635
59.127 37.99496644295302 0.026472320321339816
59.127 37.99597315436242 0.02092298932718112
59.127 37.99697986577181 0.0155105370490961
59.127 37.99798657718121 0.010318631264988248
59.127 37.998993288590604 0.005408476317410274
59.128 37.85 0.5155567774399082
59.128 37.8510067114094 0.5140664625839607
59.128 37.85201342281879 0.5125408367560134
59.128 37.85302013422819 0.5109801231173605
59.128 37.854026845637584 0.509384663013514
59.128 37.85503355704698 0.5077549253163867
59.128 37.856040268456375 0.5060915158533369
59.128 37.85704697986577 0.5043951867861638
59.128 37.85805369127517 0.5026668457572182
59.128 37.85906040268456 0.5009075645595149
59.128 37.86006711409396 0.49911858700777734
59.128 37.861073825503354 0.4847644950284824
59.128 37.86208053691275 0.4827962263153026
59.128 37.86308724832215 0.46950182592936973
59.128 37.86409395973155 0.4674266248176662
59.128 37.865100671140944 0.46007178741171734
59.128 37.86610738255034 0.45796311433508885
59.128 37.867114093959735 0.45583724312290264
59.128 37.86812080536913 0.453696706414493
59.128 37.869127516778526 0.4515440819907914
59.128 37.87013422818792 0.44938190955774104
59.128 37.87114093959732 0.4472125691159645
59.128 37.87214765100671 0.44503810394896526
59.128 37.87315436241611 0.44285996271750927
59.128 37.874161073825505 0.4406786217125373
59.128 37.8751677852349 0.4308394353997522
59.128 37.876174496644296 0.4308707648921908
59.128 37.87718120805369 0.4286099938893026
59.128 37.87818791946309 0.42632161294763304
59.128 37.87919463087248 0.42398451986984725
59.128 37.88020134228188 0.421564456056105
59.128 37.881208053691275 0.41900673520189236
59.128 37.88221476510067 0.4162248623487437
59.128 37.883221476510066 0.4130829291656767
59.128 37.88422818791946 0.4093691533386546
59.128 37.88523489932886 0.4047584074079534
59.128 37.88624161073825 0.3987654263012915
59.128 37.88724832214765 0.39070273014621526
59.128 37.88825503355705 0.37968579281339343
59.128 37.88926174496645 0.3593110080549127
59.128 37.89026845637584 0.334052114823567
59.128 37.89127516778524 0.3103485434859559
59.128 37.892281879194634 0.28418345316930704
59.128 37.89328859060403 0.2583944103400635
59.128 37.894295302013425 0.23577437348733996
59.128 37.89530201342282 0.2179436005794544
59.128 37.89630872483222 0.20510841869253846
59.128 37.89731543624161 0.19657452453237823
59.128 37.89832214765101 0.19137245078122767
59.128 37.899328859060404 0.18863176831079254
59.128 37.9003355704698 0.18770422942067005
59.128 37.901342281879195 0.1881537646053268
59.128 37.90234899328859 0.18136928774937797
59.128 37.90335570469799 0.1837151166014871
59.128 37.90436241610738 0.18688151564465338
59.128 37.90536912751678 0.19079870361314352
59.128 37.906375838926174 0.195418534609568
59.128 37.90738255033557 0.20070841019562832
59.128 37.908389261744965 0.20665174789840282
59.128 37.90939597315436 0.21325160006866062
59.128 37.910402684563756 0.22053319062151608
59.128 37.91140939597315 0.22854070819626832
59.128 37.91241610738255 0.2373243841109484
59.128 37.91342281879195 0.24691633455778422
59.128 37.914429530201346 0.25729812918541156
59.128 37.91543624161074 0.2683688051919069
59.128 37.91644295302014 0.27992650391189483
59.128 37.91744966442953 0.29167605999166674
59.128 37.91845637583893 0.303266212953241
59.128 37.919463087248324 0.314346592890617
59.128 37.92046979865772 0.3246246216025079
59.128 37.921476510067116 0.3339031855851557
59.128 37.92248322147651 0.34209051819962527
59.128 37.92348993288591 0.3491862444253071
59.128 37.9244966442953 0.3552545897618763
59.128 37.9255033557047 0.3603957398681332
59.128 37.926510067114094 0.3647222778381297
59.128 37.92751677852349 0.3683431614051431
59.128 37.928523489932886 0.3713547427816319
59.128 37.92953020134228 0.3738370760650732
59.128 37.93053691275168 0.3758536259267896
59.128 37.93154362416107 0.37745286553033736
59.128 37.93255033557047 0.3786707336643579
59.128 37.933557046979864 0.3795333325265801
59.128 37.93456375838926 0.38005954572917766
59.128 37.935570469798655 0.38026346368233355
59.128 37.93657718120805 0.3801566739878307
59.128 37.937583892617454 0.3797506881582765
59.128 37.93859060402685 0.37906015374743673
59.128 37.939597315436245 0.3781081703549273
59.128 37.94060402684564 0.3769356478983501
59.128 37.94161073825504 0.3756136258015727
59.128 37.94261744966443 0.37423512450080165
59.128 37.94362416107383 0.3728092128730919
59.128 37.94463087248322 0.3710848409237762
59.128 37.94563758389262 0.3687482778858763
59.128 37.946644295302015 0.3658300554698042
59.128 37.94765100671141 0.36255348608022625
59.128 37.948657718120806 0.35908055797534544
59.128 37.9496644295302 0.3554911913324921
59.128 37.9506711409396 0.3529363764894848
59.128 37.95167785234899 0.34918860194605644
59.128 37.95268456375839 0.34539879034071536
59.128 37.953691275167785 0.34157132557582387
59.128 37.95469798657718 0.3377009406684919
59.128 37.955704697986576 0.33377007440085504
59.128 37.95671140939597 0.32974504023103274
59.128 37.95771812080537 0.32557075326029017
59.128 37.95872483221476 0.3211636906081333
59.128 37.95973154362416 0.3164029483843571
59.128 37.960738255033554 0.31111987979230504
59.128 37.96174496644295 0.3050882126123957
59.128 37.96275167785235 0.29801922220007604
59.128 37.96375838926175 0.29284108226056993
59.128 37.964765100671144 0.2826205015055565
59.128 37.96577181208054 0.2703608653188964
59.128 37.966778523489936 0.255942851481083
59.128 37.96778523489933 0.2395624600099869
59.128 37.96879194630873 0.2218090781259035
59.128 37.96979865771812 0.19512008881372997
59.128 37.97080536912752 0.1776154739242507
59.128 37.971812080536914 0.1615902814151573
59.128 37.97281879194631 0.1475822240701338
59.128 37.973825503355705 0.13574323302063576
59.128 37.9748322147651 0.12592996226573266
59.128 37.9758389261745 0.11783806406054903
59.128 37.97684563758389 0.11111567308897355
59.128 37.97785234899329 0.10543300701068373
59.128 37.978859060402684 0.10051314537421248
59.128 37.97986577181208 0.09243731040426628
59.128 37.980872483221475 0.08533189702269645
59.128 37.98187919463087 0.08163603686798422
59.128 37.982885906040266 0.07810631069864574
59.128 37.98389261744966 0.07467413957274789
59.128 37.98489932885906 0.07128601639773657
59.128 37.98590604026845 0.06789781824693494
59.128 37.98691275167785 0.06447059116112312
59.128 37.98791946308725 0.06096759827409286
59.128 37.98892617449665 0.057352542384473154
59.128 37.98993288590604 0.05358901828671677
59.128 37.99093959731544 0.04964137362180875
59.128 37.991946308724835 0.04655472723120678
59.128 37.99295302013423 0.04210474802603599
59.128 37.993959731543626 0.03740240816403025
59.128 37.99496644295302 0.03743037167762617
59.128 37.99597315436242 0.03219839593534054
59.128 37.99697986577181 0.026813003643431928
59.128 37.99798657718121 0.02135589453267303
59.128 37.998993288590604 0.015923143604619584
59.129 37.85 0.5168032248335863
59.129 37.8510067114094 0.5153172134160104
59.129 37.85201342281879 0.5137945509973171
59.129 37.85302013422819 0.5122354130208963
59.129 37.854026845637584 0.5106401003154152
59.129 37.85503355704698 0.5090090498593252
59.129 37.856040268456375 0.5073428457872327
59.129 37.85704697986577 0.5056422305233103
59.129 37.85805369127517 0.5039081158890372
59.129 37.85906040268456 0.502141593983771
59.129 37.86006711409396 0.48769428259099384
59.129 37.861073825503354 0.4742170003903082
59.129 37.86208053691275 0.4721771220307678
59.129 37.86308724832215 0.4701099153726934
59.129 37.86409395973155 0.46801747851936837
59.129 37.865100671140944 0.46590212488252214
59.129 37.86610738255034 0.46376637711787216
59.129 37.867114093959735 0.4616129534098944
59.129 37.86812080536913 0.45944474301303034
59.129 37.869127516778526 0.4522499913195024
59.129 37.87013422818792 0.4500700354955846
59.129 37.87114093959732 0.4478845695701327
59.129 37.87214765100671 0.44569649827193975
59.129 37.87315436241611 0.4435083541203612
59.129 37.874161073825505 0.44132198128585487
59.129 37.8751677852349 0.4315412051545529
59.129 37.876174496644296 0.43159014410833324
59.129 37.87718120805369 0.4293526731337318
59.129 37.87818791946309 0.42710257033090504
59.129 37.87919463087248 0.4248227530649875
59.129 37.88020134228188 0.42248352977233555
59.129 37.881208053691275 0.42003489019102536
59.129 37.88221476510067 0.41739402403591047
59.129 37.883221476510066 0.41442534732065894
59.129 37.88422818791946 0.4109094014105055
59.129 37.88523489932886 0.4064970645456323
59.129 37.88624161073825 0.40064957523467726
59.129 37.88724832214765 0.3925799961758791
59.129 37.88825503355705 0.3812496752440194
59.129 37.88926174496645 0.3655373766589281
59.129 37.89026845637584 0.33845848326274175
59.129 37.89127516778524 0.3129587866183982
59.129 37.892281879194634 0.28508349307762915
59.129 37.89328859060403 0.2582604319815753
59.129 37.894295302013425 0.2307188311889615
59.129 37.89530201342282 0.2135767139356949
59.129 37.89630872483222 0.20183068190579637
59.129 37.89731543624161 0.19446917979637438
59.129 37.89832214765101 0.19035093813984846
59.129 37.899328859060404 0.18854876990415387
59.129 37.9003355704698 0.18841900794544442
59.129 37.901342281879195 0.18955446782119795
59.129 37.90234899328859 0.1834265881755613
59.129 37.90335570469799 0.1863415240713514
59.129 37.90436241610738 0.19007098212243384
59.129 37.90536912751678 0.1945815279614414
59.129 37.906375838926174 0.1998643692628338
59.129 37.90738255033557 0.20592985155031832
59.129 37.908389261744965 0.21280603767332462
59.129 37.90939597315436 0.22053665367615477
59.129 37.910402684563756 0.22917323064605832
59.129 37.91140939597315 0.2387571288456242
59.129 37.91241610738255 0.24929043329577788
59.129 37.91342281879195 0.26070077179829754
59.129 37.914429530201346 0.2728121935937102
59.129 37.91543624161074 0.2853379471894442
59.129 37.91644295302014 0.2979062211714818
59.129 37.91744966442953 0.3101160214706888
59.129 37.91845637583893 0.3216047818827268
59.129 37.919463087248324 0.332103157345707
59.129 37.92046979865772 0.341460258648922
59.129 37.921476510067116 0.349637819874221
59.129 37.92248322147651 0.3566837618145937
59.129 37.92348993288591 0.3626989245070836
59.129 37.9244966442953 0.3678072625659434
59.129 37.9255033557047 0.37213424095760056
59.129 37.926510067114094 0.3757938511045507
59.129 37.92751677852349 0.37888250012391955
59.129 37.928523489932886 0.38147750706864164
59.129 37.92953020134228 0.3836382598273393
59.129 37.93053691275168 0.38540865545106345
59.129 37.93154362416107 0.38681997066145846
59.129 37.93255033557047 0.38789369370110516
59.129 37.933557046979864 0.3886440961627237
59.129 37.93456375838926 0.3890804700208822
59.129 37.935570469798655 0.38920904224313024
59.129 37.93657718120805 0.3890346482942272
59.129 37.937583892617454 0.3885623450818041
59.129 37.93859060402685 0.38779934512093717
59.129 37.939597315436245 0.38675806003506097
59.129 37.94060402684564 0.38546167653403657
59.129 37.94161073825504 0.38395364784658087
59.129 37.94261744966443 0.38230597582282505
59.129 37.94362416107383 0.38059018571790953
59.129 37.94463087248322 0.3787405212082561
59.129 37.94563758389262 0.37645935800384966
59.129 37.946644295302015 0.3735489933810278
59.129 37.94765100671141 0.37129766712670204
59.129 37.948657718120806 0.36759127359223986
59.129 37.9496644295302 0.36373436741748766
59.129 37.9506711409396 0.35978689969535604
59.129 37.95167785234899 0.3557776510926819
59.129 37.95268456375839 0.35172016605469236
59.129 37.953691275167785 0.34761679245049654
59.129 37.95469798657718 0.34345793388793966
59.129 37.955704697986576 0.3392191059137013
59.129 37.95671140939597 0.33485636628092064
59.129 37.95771812080537 0.33030001964119604
59.129 37.95872483221476 0.32544642017655867
59.129 37.95973154362416 0.32341703446351333
59.129 37.960738255033554 0.31744113365688775
59.129 37.96174496644295 0.31055509932938313
59.129 37.96275167785235 0.30243584968982795
59.129 37.96375838926175 0.29272424043740386
59.129 37.964765100671144 0.28108445910606755
59.129 37.96577181208054 0.26730843452388947
59.129 37.966778523489936 0.2514512127718772
59.129 37.96778523489933 0.23394266177560716
59.129 37.96879194630873 0.2155914838758277
59.129 37.96979865771812 0.1889773944347676
59.129 37.97080536912752 0.17209601322819246
59.129 37.971812080536914 0.157087822028824
59.129 37.97281879194631 0.14425933969115456
59.129 37.973825503355705 0.13357238316030703
59.129 37.9748322147651 0.12477079685918188
59.129 37.9758389261745 0.11750842550243745
59.129 37.97684563758389 0.11143809157212606
59.129 37.97785234899329 0.10625738462092216
59.129 37.978859060402684 0.10172351009783787
59.129 37.97986577181208 0.0939997555051392
59.129 37.980872483221475 0.08717314246435798
59.129 37.98187919463087 0.08369209058598973
59.129 37.982885906040266 0.08036409511006185
59.129 37.98389261744966 0.07713677959951287
59.129 37.98489932885906 0.07397114884515238
59.129 37.98590604026845 0.0708368554656579
59.129 37.98691275167785 0.06770862954849377
59.129 37.98791946308725 0.0645635705478355
59.129 37.98892617449665 0.0613790813371177
59.129 37.98993288590604 0.05813131836127586
59.129 37.99093959731544 0.05479413323872872
59.129 37.991946308724835 0.05242199660223829
59.129 37.99295302013423 0.04877253776635528
59.129 37.993959731543626 0.04494155960819851
59.129 37.99496644295302 0.040898017837266466
59.129 37.99597315436242 0.03661672437537069
59.129 37.99697986577181 0.037077001614829574
59.129 37.99798657718121 0.032224309976266075
59.129 37.998993288590604 0.027153304295151066
59.13 37.85 0.5179931999648698
59.13 37.8510067114094 0.5165113350583067
59.13 37.85201342281879 0.5149915417562877
59.13 37.85302013422819 0.5134339509798311
59.13 37.854026845637584 0.5118388263409785
59.13 37.85503355704698 0.5102065763172443
59.13 37.856040268456375 0.5085377668046769
59.13 37.85704697986577 0.506833133947477
59.13 37.85805369127517 0.5050935971086666
59.13 37.85906040268456 0.5033202718041998
59.13 37.86006711409396 0.4768121837580611
59.13 37.861073825503354 0.47478837585552514
59.13 37.86208053691275 0.47273410639822805
59.13 37.86308724832215 0.4706513642842858
59.13 37.86409395973155 0.46854238445953567
59.13 37.865100671140944 0.46640965191480216
59.13 37.86610738255034 0.46425590122057275
59.13 37.867114093959735 0.4620841097651504
59.13 37.86812080536913 0.4598974820620702
59.13 37.869127516778526 0.4576994212555441
59.13 37.87013422818792 0.455493482001899
59.13 37.87114093959732 0.45328329577520154
59.13 37.87214765100671 0.45107245454486294
59.13 37.87315436241611 0.4440870022754758
59.13 37.874161073825505 0.4418935282030787
59.13 37.8751677852349 0.43970766357299007
59.13 37.876174496644296 0.43223053485873963
59.13 37.87718120805369 0.4300074914363876
59.13 37.87818791946309 0.427782441079889
59.13 37.87919463087248 0.42554059947591627
59.13 37.88020134228188 0.4232544863707959
59.13 37.881208053691275 0.4208755646062087
59.13 37.88221476510067 0.4183204020064124
59.13 37.883221476510066 0.41544806309706644
59.13 37.88422818791946 0.4120242128871651
59.13 37.88523489932886 0.4076673075439274
59.13 37.88624161073825 0.40177705531007935
59.13 37.88724832214765 0.39346436829820586
59.13 37.88825503355705 0.38155101832672833
59.13 37.88926174496645 0.36478969517458426
59.13 37.89026845637584 0.33621362809440225
59.13 37.89127516778524 0.30908589410155246
59.13 37.892281879194634 0.28002078157544036
59.13 37.89328859060403 0.2529017315400153
59.13 37.894295302013425 0.230791553838687
59.13 37.89530201342282 0.21477356786441076
59.13 37.89630872483222 0.20428252535426655
59.13 37.89731543624161 0.19807162315262877
59.13 37.89832214765101 0.19492233617845584
59.13 37.899328859060404 0.18927780087794968
59.13 37.9003355704698 0.1898194242196481
59.13 37.901342281879195 0.19154553763832152
59.13 37.90234899328859 0.19426010732351867
59.13 37.90335570469799 0.18950168068968878
59.13 37.90436241610738 0.19384660224024278
59.13 37.90536912751678 0.19907012591200146
59.13 37.906375838926174 0.20521360328556443
59.13 37.90738255033557 0.21233870854670972
59.13 37.908389261744965 0.2205181714291171
59.13 37.90939597315436 0.22981956586678254
59.13 37.910402684563756 0.24027801418941377
59.13 37.91140939597315 0.25185864884385933
59.13 37.91241610738255 0.26441797270809164
59.13 37.91342281879195 0.27768132710569904
59.13 37.914429530201346 0.2912545115164346
59.13 37.91543624161074 0.3046758458396166
59.13 37.91644295302014 0.31749462397832895
59.13 37.91744966442953 0.32934685715574075
59.13 37.91845637583893 0.34000135973064155
59.13 37.919463087248324 0.3493662770814542
59.13 37.92046979865772 0.3574644803600622
59.13 37.921476510067116 0.36439479319392265
59.13 37.92248322147651 0.3702939929030367
59.13 37.92348993288591 0.3753076926804702
59.13 37.9244966442953 0.3795719604536256
59.13 37.9255033557047 0.38320399870592275
59.13 37.926510067114094 0.38629909444467214
59.13 37.92751677852349 0.38893127896406055
59.13 37.928523489932886 0.3911558331164152
59.13 37.92953020134228 0.39301246539793755
59.13 37.93053691275168 0.3945285108044687
59.13 37.93154362416107 0.39572183520865367
59.13 37.93255033557047 0.3966033239477074
59.13 37.933557046979864 0.3971789317538027
59.13 37.93456375838926 0.3974513116805672
59.13 37.935570469798655 0.39742104932396055
59.13 37.93657718120805 0.3970875235005571
59.13 37.937583892617454 0.39644941123429045
59.13 37.93859060402685 0.39550487369328335
59.13 37.939597315436245 0.3942515370731951
59.13 37.94060402684564 0.39268659522609006
59.13 37.94161073825504 0.39080788133605093
59.13 37.94261744966443 0.38861788833804956
59.13 37.94362416107383 0.38734603773255455
59.13 37.94463087248322 0.38458892005609274
59.13 37.94563758389262 0.3816426700914367
59.13 37.946644295302015 0.37850776620608034
59.13 37.94765100671141 0.3751336224835669
59.13 37.948657718120806 0.37151461170838534
59.13 37.9496644295302 0.367695074358886
59.13 37.9506711409396 0.36372671231280407
59.13 37.95167785234899 0.3596492859550859
59.13 37.95268456375839 0.3554870436173335
59.13 37.953691275167785 0.35456505980876796
59.13 37.95469798657718 0.3502118861417514
59.13 37.955704697986576 0.34574408110820387
59.13 37.95671140939597 0.3411079720842284
59.13 37.95771812080537 0.3362183495318296
59.13 37.95872483221476 0.3309495814891268
59.13 37.95973154362416 0.32512542712451076
59.13 37.960738255033554 0.3185098947347917
59.13 37.96174496644295 0.310804397746888
59.13 37.96275167785235 0.30166078335484775
59.13 37.96375838926175 0.29072405687743164
59.13 37.964765100671144 0.27771782946259704
59.13 37.96577181208054 0.2625707570696937
59.13 37.966778523489936 0.24554758129874066
59.13 37.96778523489933 0.2273087033028666
59.13 37.96879194630873 0.20882270381944992
59.13 37.96979865771812 0.18271510835874497
59.13 37.97080536912752 0.16677094836389258
59.13 37.971812080536914 0.15294277043719903
59.13 37.97281879194631 0.14132557339353183
59.13 37.973825503355705 0.1317374703577467
59.13 37.9748322147651 0.12385505254378561
59.13 37.9758389261745 0.1173216052933421
59.13 37.97684563758389 0.11181062921547855
59.13 37.97785234899329 0.10705223425221447
59.13 37.978859060402684 0.09921120905496959
59.13 37.97986577181208 0.09540542942465866
59.13 37.980872483221475 0.08880194047672116
59.13 37.98187919463087 0.08547939171377825
59.13 37.982885906040266 0.08229098282283727
59.13 37.98389261744966 0.07919573157816617
59.13 37.98489932885906 0.07616423276920403
59.13 37.98590604026845 0.07317482218424468
59.13 37.98691275167785 0.07021073877936115
59.13 37.98791946308725 0.06725799706661231
59.13 37.98892617449665 0.06430374660746108
59.13 37.98993288590604 0.06133495657575553
59.13 37.99093959731544 0.05833731826921943
59.13 37.991946308724835 0.05638297486177836
59.13 37.99295302013423 0.05323120916690547
59.13 37.993959731543626 0.049992400220513995
59.13 37.99496644295302 0.04663980849288126
59.13 37.99597315436242 0.04314342655980888
59.13 37.99697986577181 0.03947132625978608
59.13 37.99798657718121 0.04067656242269965
59.13 37.998993288590604 0.03648959203571404
59.131 37.85 0.5191281326192723
59.131 37.8510067114094 0.517650200462305
59.131 37.85201342281879 0.5161331253649442
59.131 37.85302013422819 0.5145769976901914
59.131 37.854026845637584 0.5129820480205917
59.131 37.85503355704698 0.5113486607511166
59.131 37.856040268456375 0.5096773881857537
59.131 37.85704697986577 0.5079689650417004
59.131 37.85805369127517 0.4924716464260389
59.131 37.85906040268456 0.47933192214685755
59.131 37.86006711409396 0.477328859782856
59.131 37.861073825503354 0.4752923433098813
59.131 37.86208053691275 0.47322420537054843
59.131 37.86308724832215 0.4711265424689215
59.131 37.86409395973155 0.46900172522378575
59.131 37.865100671140944 0.46685240591210525
59.131 37.86610738255034 0.4646815221416005
59.131 37.867114093959735 0.4624922950094482
59.131 37.86812080536913 0.4602882193584048
59.131 37.869127516778526 0.45807304255783066
59.131 37.87013422818792 0.45585072631913204
59.131 37.87114093959732 0.4536253828974292
59.131 37.87214765100671 0.4514011717611245
59.131 37.87315436241611 0.449182133918946
59.131 37.874161073825505 0.4469719259698506
59.131 37.8751677852349 0.4447733900643864
59.131 37.876174496644296 0.4366698448764585
59.131 37.87718120805369 0.43444014162770805
59.131 37.87818791946309 0.42837999499133433
59.131 37.87919463087248 0.4261633483900321
59.131 37.88020134228188 0.42391126484448977
59.131 37.881208053691275 0.42157404695234585
59.131 37.88221476510067 0.4190639438655013
59.131 37.883221476510066 0.41622954017953606
59.131 37.88422818791946 0.4128147237253298
59.131 37.88523489932886 0.4083969079881785
59.131 37.88624161073825 0.40230545400074813
59.131 37.88724832214765 0.3935457081917505
59.131 37.88825503355705 0.3808160597860335
59.131 37.88926174496645 0.36280370908229737
59.131 37.89026845637584 0.33268284992616903
59.131 37.89127516778524 0.30416379842981905
59.131 37.892281879194634 0.27443897292788155
59.131 37.89328859060403 0.24765515075017897
59.131 37.894295302013425 0.2266307503749001
59.131 37.89530201342282 0.21197887608952062
59.131 37.89630872483222 0.20278155636894235
59.131 37.89731543624161 0.19764988272074074
59.131 37.89832214765101 0.19536274023724284
59.131 37.899328859060404 0.19505653770571302
59.131 37.9003355704698 0.1961941454305452
59.131 37.901342281879195 0.1984762862971105
59.131 37.90234899328859 0.20176181868411022
59.131 37.90335570469799 0.19725134490415322
59.131 37.90436241610738 0.2023556709476245
59.131 37.90536912751678 0.20852574968467097
59.131 37.906375838926174 0.2118963740162268
59.131 37.90738255033557 0.22053040555693312
59.131 37.908389261744965 0.2305412686834294
59.131 37.90939597315436 0.24196128516596624
59.131 37.910402684563756 0.25470975397458634
59.131 37.91140939597315 0.2685497459197763
59.131 37.91241610738255 0.28307793975003437
59.131 37.91342281879195 0.29776639968828755
59.131 37.914429530201346 0.3120515543061547
59.131 37.91543624161074 0.3254383096752136
59.131 37.91644295302014 0.3375782295937858
59.131 37.91744966442953 0.3482974656267368
59.131 37.91845637583893 0.35757750104256697
59.131 37.919463087248324 0.3655095408518736
59.131 37.92046979865772 0.37224475190026035
59.131 37.921476510067116 0.37795407314555707
59.131 37.92248322147651 0.382801920619327
59.131 37.92348993288591 0.3869322778757189
59.131 37.9244966442953 0.39046354972190056
59.131 37.9255033557047 0.39348865371062064
59.131 37.926510067114094 0.3960777401510934
59.131 37.92751677852349 0.3982818970184988
59.131 37.928523489932886 0.4001369305461325
59.131 37.92953020134228 0.40166678558645474
59.131 37.93053691275168 0.40288643988100603
59.131 37.93154362416107 0.403804241503272
59.131 37.93255033557047 0.4044237117225339
59.131 37.933557046979864 0.40474484019212165
59.131 37.93456375838926 0.40476487421673113
59.131 37.935570469798655 0.4044785566082445
59.131 37.93657718120805 0.40387769759792436
59.131 37.937583892617454 0.40294987111788305
59.131 37.93859060402685 0.40296907043248287
59.131 37.939597315436245 0.40129373730574885
59.131 37.94060402684564 0.39919489484862075
59.131 37.94161073825504 0.3966000173354165
59.131 37.94261744966443 0.3934074963657861
59.131 37.94362416107383 0.3895122706334132
59.131 37.94463087248322 0.38493724829978093
59.131 37.94563758389262 0.3801391450981212
59.131 37.946644295302015 0.37942499958515885
59.131 37.94765100671141 0.37642579425122424
59.131 37.948657718120806 0.37387898548090504
59.131 37.9496644295302 0.37108572994482486
59.131 37.9506711409396 0.3678410413387736
59.131 37.95167785234899 0.3642010315529928
59.131 37.95268456375839 0.36026669579678705
59.131 37.953691275167785 0.35611099419721814
59.131 37.95469798657718 0.3517657563114374
59.131 37.955704697986576 0.3472221601600836
59.131 37.95671140939597 0.34243069609382815
59.131 37.95771812080537 0.3372975998935287
59.131 37.95872483221476 0.3316780221669404
59.131 37.95973154362416 0.3253678746641664
59.131 37.960738255033554 0.3180985604087768
59.131 37.96174496644295 0.3095424445131654
59.131 37.96275167785235 0.29934145281386837
59.131 37.96375838926175 0.28717337595683584
59.131 37.964765100671144 0.2728628540315497
59.131 37.96577181208054 0.25651780777835004
59.131 37.966778523489936 0.23863066720235543
59.131 37.96778523489933 0.22006034273575925
59.131 37.96879194630873 0.20185398594832615
59.131 37.96979865771812 0.17659044736108656
59.131 37.97080536912752 0.1617866243328103
59.131 37.971812080536914 0.14920605610412932
59.131 37.97281879194631 0.13876924277749145
59.131 37.973825503355705 0.1301970782253374
59.131 37.9748322147651 0.12313544816616154
59.131 37.9758389261745 0.11723835896040988
59.131 37.97684563758389 0.11220884691182297
59.131 37.97785234899329 0.10421096955841536
59.131 37.978859060402684 0.10028837813504156
59.131 37.97986577181208 0.09362960279365903
59.131 37.980872483221475 0.09027478540726223
59.131 37.98187919463087 0.08708134443995427
59.131 37.982885906040266 0.08400258872551486
59.131 37.98389261744966 0.08100589979540056
59.131 37.98489932885906 0.07806844768426853
59.131 37.98590604026845 0.07517407637592703
59.131 37.98691275167785 0.07231104675395245
59.131 37.98791946308725 0.06947038599041919
59.131 37.98892617449665 0.06664465163684061
59.131 37.98993288590604 0.06382696796502554
59.131 37.99093959731544 0.06101023054438444
59.131 37.991946308724835 0.05927969645620341
59.131 37.99295302013423 0.05639552628246982
59.131 37.993959731543626 0.05348365220335144
59.131 37.99496644295302 0.050529373844175725
59.131 37.99597315436242 0.0475145825018052
59.131 37.99697986577181 0.04441733761531838
59.131 37.99798657718121 0.041211734825535654
59.131 37.998993288590604 0.03786829754317893
59.132 37.85 0.5202095607055222
59.132 37.8510067114094 0.5187353010708385
59.132 37.85201342281879 0.517220747028555
59.132 37.85302013422819 0.515665953059179
59.132 37.854026845637584 0.5140711217178571
59.132 37.85503355704698 0.5124366186692779
59.132 37.856040268456375 0.5107629883439809
59.132 37.85704697986577 0.4949417817285418
59.132 37.85805369127517 0.4931027551775992
59.132 37.85906040268456 0.47979244548074934
59.132 37.86006711409396 0.4777781999837979
59.132 37.861073825503354 0.4757293531816582
59.132 37.86208053691275 0.47364782485916457
59.132 37.86308724832215 0.4715358228977964
59.132 37.86409395973155 0.4693958560958257
59.132 37.865100671140944 0.46723074450314434
59.132 37.86610738255034 0.46504362616938255
59.132 37.867114093959735 0.46283795873873834
59.132 37.86812080536913 0.46061751358959063
59.132 37.869127516778526 0.45838635902317604
59.132 37.87013422818792 0.4561488270291495
59.132 37.87114093959732 0.4539094548364912
59.132 37.87214765100671 0.451672886820004
59.132 37.87315436241611 0.4494437126743257
59.132 37.874161073825505 0.44722620112716766
59.132 37.8751677852349 0.4450238596822552
59.132 37.876174496644296 0.4428387010023889
59.132 37.87718120805369 0.4348011935726425
59.132 37.87818791946309 0.4325889856081438
59.132 37.87919463087248 0.4303735623293564
59.132 37.88020134228188 0.4281265961581696
59.132 37.881208053691275 0.4257949975626351
59.132 37.88221476510067 0.4232834445425061
59.132 37.883221476510066 0.42042534981842344
59.132 37.88422818791946 0.4133499788919742
59.132 37.88523489932886 0.40877402737005025
59.132 37.88624161073825 0.4023456087904177
59.132 37.88724832214765 0.39296238257656624
59.132 37.88825503355705 0.37921988829902115
59.132 37.88926174496645 0.3598081801549056
59.132 37.89026845637584 0.3281656631833048
59.132 37.89127516778524 0.2985464234029399
59.132 37.892281879194634 0.26866538178972144
59.132 37.89328859060403 0.2427161869761529
59.132 37.894295302013425 0.22308950911292252
59.132 37.89530201342282 0.209904318753225
59.132 37.89630872483222 0.20196702212710732
59.132 37.89731543624161 0.19782952756503094
59.132 37.89832214765101 0.19632060617276936
59.132 37.899328859060404 0.19665685167589492
59.132 37.9003355704698 0.19837962179114965
59.132 37.901342281879195 0.20126111680194717
59.132 37.90234899328859 0.20522912543716199
59.132 37.90335570469799 0.21031683681390234
59.132 37.90436241610738 0.20780624375451373
59.132 37.90536912751678 0.21535736635476374
59.132 37.906375838926174 0.2244496172447393
59.132 37.90738255033557 0.2352149511729659
59.132 37.908389261744965 0.24768596304472223
59.132 37.90939597315436 0.26172362593652376
59.132 37.910402684563756 0.2769683799332863
59.132 37.91140939597315 0.2928514147155968
59.132 37.91241610738255 0.3086826895672427
59.132 37.91342281879195 0.3237894257491494
59.132 37.914429530201346 0.33764618630938287
59.132 37.91543624161074 0.3499449034369828
59.132 37.91644295302014 0.3605922141807335
59.132 37.91744966442953 0.36965749161000727
59.132 37.91845637583893 0.373653574760655
59.132 37.919463087248324 0.38010353198352176
59.132 37.92046979865772 0.38554149390397474
59.132 37.921476510067116 0.39014689137757524
59.132 37.92248322147651 0.39406847661607625
59.132 37.92348993288591 0.3974236651993404
59.132 37.9244966442953 0.4003016971498053
59.132 37.9255033557047 0.40276821564879256
59.132 37.926510067114094 0.40486996382952783
59.132 37.92751677852349 0.4066389957358788
59.132 37.928523489932886 0.4080961839518153
59.132 37.92953020134228 0.4092539940182254
59.132 37.93053691275168 0.41011856688693404
59.132 37.93154362416107 0.41069115621158575
59.132 37.93255033557047 0.4123617985728644
59.132 37.933557046979864 0.41231329540671113
59.132 37.93456375838926 0.4119518545756957
59.132 37.935570469798655 0.41476240192158614
59.132 37.93657718120805 0.4136891502335627
59.132 37.937583892617454 0.4122146837542122
59.132 37.93859060402685 0.4102725750757396
59.132 37.939597315436245 0.4077597197421233
59.132 37.94060402684564 0.4045182226664382
59.132 37.94161073825504 0.40031379174262266
59.132 37.94261744966443 0.39482319588940423
59.132 37.94362416107383 0.3876805374925524
59.132 37.94463087248322 0.3787306830488679
59.132 37.94563758389262 0.3687449878340407
59.132 37.946644295302015 0.360298622084197
59.132 37.94765100671141 0.3565258232381049
59.132 37.948657718120806 0.35729597846299077
59.132 37.9496644295302 0.3593986025907036
59.132 37.9506711409396 0.3604276836004273
59.132 37.95167785234899 0.3598103006525512
59.132 37.95268456375839 0.3578133603555991
59.132 37.953691275167785 0.35482893905441804
59.132 37.95469798657718 0.35114787089021265
59.132 37.955704697986576 0.34693109776127246
59.132 37.95671140939597 0.34222719404139823
59.132 37.95771812080537 0.33699003678388595
59.132 37.95872483221476 0.33108824567827355
59.132 37.95973154362416 0.3243093821289864
59.132 37.960738255033554 0.3163666556966282
59.132 37.96174496644295 0.3069200678342259
59.132 37.96275167785235 0.29562732472059783
59.132 37.96375838926175 0.2822372943837008
59.132 37.964765100671144 0.26672117736444156
59.132 37.96577181208054 0.24939956808066277
59.132 37.966778523489936 0.2309861625626241
59.132 37.96778523489933 0.2124784339436702
59.132 37.96879194630873 0.18645800380470098
59.132 37.96979865771812 0.17073989279470972
59.132 37.97080536912752 0.1571892459724479
59.132 37.971812080536914 0.14585658216987948
59.132 37.97281879194631 0.13653226462546464
59.132 37.973825503355705 0.12888111717102385
59.132 37.9748322147651 0.12254543263814932
59.132 37.9758389261745 0.11720319770483223
59.132 37.97684563758389 0.11259085807314073
59.132 37.97785234899329 0.10495318753409717
59.132 37.978859060402684 0.10126544287023508
59.132 37.97986577181208 0.09481685462028894
59.132 37.980872483221475 0.09160261823412985
59.132 37.98187919463087 0.08852301116956732
59.132 37.982885906040266 0.08554013069884957
59.132 37.98389261744966 0.08262777547611425
59.132 37.98489932885906 0.07976790296191495
59.132 37.98590604026845 0.07694808895263278
59.132 37.98691275167785 0.07415971351566417
59.132 37.98791946308725 0.07139665990652083
59.132 37.98892617449665 0.06865436728206573
59.132 37.98993288590604 0.06592912057072092
59.132 37.99093959731544 0.06435899828287493
59.132 37.991946308724835 0.06161315699012523
59.132 37.99295302013423 0.05887396305689752
59.132 37.993959731543626 0.05613616913029286
59.132 37.99496644295302 0.05339306990580509
59.132 37.99597315436242 0.05063603407195555
59.132 37.99697986577181 0.04785399886008817
59.132 37.99798657718121 0.04503294109788765
59.132 37.998993288590604 0.04215536664129582
59.133 37.85 0.5212391088418687
59.133 37.8510067114094 0.5197682250792359
59.133 37.85201342281879 0.5182559588666367
59.133 37.85302013422819 0.5167023341494771
59.133 37.854026845637584 0.5151075312237181
59.133 37.85503355704698 0.5134719032475112
59.133 37.856040268456375 0.49732129107063094
59.133 37.85704697986577 0.4955138432155996
59.133 37.85805369127517 0.4821737566060146
59.133 37.85906040268456 0.48018590526354876
59.133 37.86006711409396 0.4781606860550385
59.133 37.861073825503354 0.4760998165674064
59.133 37.86208053691275 0.4740053097577045
59.133 37.86308724832215 0.47187949057496803
59.133 37.86409395973155 0.4697250109533222
59.133 37.865100671140944 0.4675448623728887
59.133 37.86610738255034 0.46534238488360324
59.133 37.867114093959735 0.4631212710144527
59.133 37.86812080536913 0.46088556223085075
59.133 37.869127516778526 0.4586396343481021
59.133 37.87013422818792 0.4563881661979977
59.133 37.87114093959732 0.454136082249497
59.133 37.87214765100671 0.45188845370105046
59.133 37.87315436241611 0.44965033186073833
59.133 37.874161073825505 0.4474264690357189
59.133 37.8751677852349 0.4452208497647735
59.133 37.876174496644296 0.44303589877358296
59.133 37.87718120805369 0.43510814638303286
59.133 37.87818791946309 0.43290505671126056
59.133 37.87919463087248 0.43070136090550376
59.133 37.88020134228188 0.4284662234636005
59.133 37.881208053691275 0.4261411797549004
59.133 37.88221476510067 0.42362035361047673
59.133 37.883221476510066 0.42071755565800334
59.133 37.88422818791946 0.41711369620181127
59.133 37.88523489932886 0.412279157392968
59.133 37.88624161073825 0.40537777352124105
59.133 37.88724832214765 0.39520000901773816
59.133 37.88825503355705 0.3802653352621652
59.133 37.88926174496645 0.35934281395935846
59.133 37.89026845637584 0.3325173642383475
59.133 37.89127516778524 0.29544437756493064
59.133 37.892281879194634 0.262931430388051
59.133 37.89328859060403 0.23818763117417913
59.133 37.894295302013425 0.2201339869410241
59.133 37.89530201342282 0.20842878833946965
59.133 37.89630872483222 0.2016887135027208
59.133 37.89731543624161 0.1984728293252832
59.133 37.89832214765101 0.19769571162188157
59.133 37.899328859060404 0.19867299112869083
59.133 37.9003355704698 0.20103915995127794
59.133 37.901342281879195 0.20465558058728878
59.133 37.90234899328859 0.2095421461728188
59.133 37.90335570469799 0.21583051074544896
59.133 37.90436241610738 0.21497839475522762
59.133 37.90536912751678 0.22456995705978122
59.133 37.906375838926174 0.23617533803277568
59.133 37.90738255033557 0.2498281358375468
59.133 37.908389261744965 0.26531240995827565
59.133 37.90939597315436 0.2821079149579517
59.133 37.910402684563756 0.29943709537406293
59.133 37.91140939597315 0.31642109416659375
59.133 37.91241610738255 0.3322805708382839
59.133 37.91342281879195 0.34648613873873435
59.133 37.914429530201346 0.35880322478901633
59.133 37.91543624161074 0.3692442924636815
59.133 37.91644295302014 0.37797913146197526
59.133 37.91744966442953 0.3852487187320057
59.133 37.91845637583893 0.39130422570645707
59.133 37.919463087248324 0.3963730345362772
59.133 37.92046979865772 0.4006448003053669
59.133 37.921476510067116 0.4042694671969963
59.133 37.92248322147651 0.4073611308349882
59.133 37.92348993288591 0.4117319949648539
59.133 37.9244966442953 0.41396026115873596
59.133 37.9255033557047 0.4158423985740855
59.133 37.926510067114094 0.41740666381485897
59.133 37.92751677852349 0.41867084878190797
59.133 37.928523489932886 0.4196447891102119
59.133 37.92953020134228 0.4203320355423981
59.133 37.93053691275168 0.42073072602572725
59.133 37.93154362416107 0.42083361722339613
59.133 37.93255033557047 0.4206271234051577
59.133 37.933557046979864 0.4200890597926966
59.133 37.93456375838926 0.41918458267611364
59.133 37.935570469798655 0.4178595486005863
59.133 37.93657718120805 0.416030189686336
59.133 37.937583892617454 0.4135677029400212
59.133 37.93859060402685 0.41027635301314136
59.133 37.939597315436245 0.40586473986231947
59.133 37.94060402684564 0.39991380615466926
59.133 37.94161073825504 0.3918559509550594
59.133 37.94261744966443 0.38100486852934246
59.133 37.94362416107383 0.36672552724968965
59.133 37.94463087248322 0.34890256314449336
59.133 37.94563758389262 0.3288513784250687
59.133 37.946644295302015 0.3103503760455664
59.133 37.94765100671141 0.29919443847444294
59.133 37.948657718120806 0.29943356626456324
59.133 37.9496644295302 0.30919302449438557
59.133 37.9506711409396 0.3222650596628613
59.133 37.95167785234899 0.33339723961130885
59.133 37.95268456375839 0.34055037369560776
59.133 37.953691275167785 0.34386690055469055
59.133 37.95469798657718 0.3442161935057011
59.133 37.955704697986576 0.3424357003851891
59.133 37.95671140939597 0.3391011038366354
59.133 37.95771812080537 0.3345157170919033
59.133 37.95872483221476 0.32875734553350205
59.133 37.95973154362416 0.32172856480024037
59.133 37.960738255033554 0.3132049431748743
59.133 37.96174496644295 0.30289464235995234
59.133 37.96275167785235 0.2905273781002474
59.133 37.96375838926175 0.2759808928310594
59.133 37.964765100671144 0.2594233725320656
59.133 37.96577181208054 0.24140780945397403
59.133 37.966778523489936 0.22283636131685192
59.133 37.96778523489933 0.20476543877205722
59.133 37.96879194630873 0.17971336692248618
59.133 37.96979865771812 0.1652179817695562
59.133 37.97080536912752 0.15296190064671125
59.133 37.971812080536914 0.1428324669430546
59.133 37.97281879194631 0.13453394366213536
59.133 37.973825503355705 0.12770824390506302
59.133 37.9748322147651 0.1220125936684539
59.133 37.9758389261745 0.11715614468956075
59.133 37.97684563758389 0.10938270082831963
59.133 37.97785234899329 0.10559450311977782
59.133 37.978859060402684 0.0991016696375697
59.133 37.97986577181208 0.09586115275839818
59.133 37.980872483221475 0.09277668951970482
59.133 37.98187919463087 0.08980375251106662
59.133 37.982885906040266 0.08691155673132298
59.133 37.98389261744966 0.08407898680269354
59.133 37.98489932885906 0.08129167223769113
59.133 37.98590604026845 0.07853990943421421
59.133 37.98691275167785 0.07581719171896315
59.133 37.98791946308725 0.0731191678676904
59.133 37.98892617449665 0.07044289779301356
59.133 37.98993288590604 0.06778631074783592
59.133 37.99093959731544 0.06629236500225745
59.133 37.991946308724835 0.06362652202517854
59.133 37.99295302013423 0.060976524538357314
59.133 37.993959731543626 0.05834037749469287
59.133 37.99496644295302 0.055715449251044664
59.133 37.99597315436242 0.05309820018277322
59.133 37.99697986577181 0.0504838770314773
59.133 37.99798657718121 0.04786616069379576
59.133 37.998993288590604 0.04523675982762267
59.134 37.85 0.5222184691683405
59.134 37.8510067114094 0.5207506378557629
59.134 37.85201342281879 0.5192404000272826
59.134 37.85302013422819 0.517687755087393
59.134 37.854026845637584 0.5013372744643808
59.134 37.85503355704698 0.4996065092915462
59.134 37.856040268456375 0.49783378826177704
59.134 37.85704697986577 0.49601986346917026
59.134 37.85805369127517 0.4825104307974509
59.134 37.85906040268456 0.4805127500220804
59.134 37.86006711409396 0.4784766712745942
59.134 37.861073825503354 0.4764039903240522
59.134 37.86208053691275 0.47429682019538844
59.134 37.86308724832215 0.4721576096995673
59.134 37.86409395973155 0.46998916029070004
59.134 37.865100671140944 0.4677946404070745
59.134 37.86610738255034 0.46557759613785366
59.134 37.867114093959735 0.4633419565577465
59.134 37.86812080536913 0.46109203125898707
59.134 37.869127516778526 0.45883249625088907
59.134 37.87013422818792 0.45656836208515067
59.134 37.87114093959732 0.4543049140876832
59.134 37.87214765100671 0.45204760768232555
59.134 37.87315436241611 0.44980188977757757
59.134 37.874161073825505 0.4475728961968125
59.134 37.8751677852349 0.4453649384098799
59.134 37.876174496644296 0.4431806285898473
59.134 37.87718120805369 0.44306505688916453
59.134 37.87818791946309 0.4331673024194619
59.134 37.87919463087248 0.43097144474552285
59.134 37.88020134228188 0.4287409187445036
59.134 37.881208053691275 0.4264100315555842
59.134 37.88221476510067 0.42385949140232015
59.134 37.883221476510066 0.4208791714286544
59.134 37.88422818791946 0.417109103362843
59.134 37.88523489932886 0.41195424729151425
59.134 37.88624161073825 0.4044852315441471
59.134 37.88724832214765 0.3933894764549629
59.134 37.88825503355705 0.37714484635558954
59.134 37.88926174496645 0.3546826185036213
59.134 37.89026845637584 0.3265660046433688
59.134 37.89127516778524 0.28908804287672385
59.134 37.892281879194634 0.26017533283636807
59.134 37.89328859060403 0.23687646218524827
59.134 37.894295302013425 0.2204550806147513
59.134 37.89530201342282 0.2101764729503391
59.134 37.89630872483222 0.2045495639610412
59.134 37.89731543624161 0.20219231975461363
59.134 37.89832214765101 0.20213248439469148
59.134 37.899328859060404 0.20380401374776358
59.134 37.9003355704698 0.2069566776216265
59.134 37.901342281879195 0.21157024581000705
59.134 37.90234899328859 0.2177907375794464
59.134 37.90335570469799 0.22587582227058306
59.134 37.90436241610738 0.23612581829468415
59.134 37.90536912751678 0.2395622269546455
59.134 37.906375838926174 0.2545046734517344
59.134 37.90738255033557 0.2715571456880362
59.134 37.908389261744965 0.28999021046901463
59.134 37.90939597315436 0.3087704446846984
59.134 37.910402684563756 0.3268116282892872
59.134 37.91140939597315 0.3432469449906534
59.134 37.91241610738255 0.35758424845176257
59.134 37.91342281879195 0.3697020323153919
59.134 37.914429530201346 0.3797425035448548
59.134 37.91543624161074 0.3879833484243922
59.134 37.91644295302014 0.3947385539696405
59.134 37.91744966442953 0.4003005193163408
59.134 37.91845637583893 0.4049150802796829
59.134 37.919463087248324 0.40877647816653967
59.134 37.92046979865772 0.4120320039005704
59.134 37.921476510067116 0.4147900706437621
59.134 37.92248322147651 0.4171285470133648
59.134 37.92348993288591 0.419102027383604
59.134 37.9244966442953 0.4207476574532672
59.134 37.9255033557047 0.42208954186812925
59.134 37.926510067114094 0.42314189075942155
59.134 37.92751677852349 0.42391105741371593
59.134 37.928523489932886 0.4243965500127449
59.134 37.92953020134228 0.4245909937956713
59.134 37.93053691275168 0.42447887986061283
59.134 37.93154362416107 0.4240337542648137
59.134 37.93255033557047 0.42321326259533154
59.134 37.933557046979864 0.4219511646030496
59.134 37.93456375838926 0.42014509978868175
59.134 37.935570469798655 0.41763864852595134
59.134 37.93657718120805 0.414196472728541
59.134 37.937583892617454 0.40947294125834277
59.134 37.93859060402685 0.4029794860534217
59.134 37.939597315436245 0.39406691036007907
59.134 37.94060402684564 0.38195831396474
59.134 37.94161073825504 0.36589098771139766
59.134 37.94261744966443 0.34542442225543485
59.134 37.94362416107383 0.3208925388253013
59.134 37.94463087248322 0.2938000638092356
59.134 37.94563758389262 0.2668302129783824
59.134 37.946644295302015 0.2433129616846373
59.134 37.94765100671141 0.22644272189102002
59.134 37.948657718120806 0.2186689403373408
59.134 37.9496644295302 0.22126815387560445
59.134 37.9506711409396 0.2337843258655987
59.134 37.95167785234899 0.25349017971516685
59.134 37.95268456375839 0.2757919625157873
59.134 37.953691275167785 0.29599668484620845
59.134 37.95469798657718 0.31116108012015176
59.134 37.955704697986576 0.32053261105811404
59.134 37.95671140939597 0.3247482706921237
59.134 37.95771812080537 0.32485552399786244
59.134 37.95872483221476 0.3217382942811503
59.134 37.95973154362416 0.3159344410628897
59.134 37.960738255033554 0.30766505958165175
59.134 37.96174496644295 0.2969552869536175
59.134 37.96275167785235 0.283798977167503
59.134 37.96375838926175 0.26834208917574753
59.134 37.964765100671144 0.25103737502445483
59.134 37.96577181208054 0.23269285890733088
59.134 37.966778523489936 0.2143552705355514
59.134 37.96778523489933 0.19706076155454655
59.134 37.96879194630873 0.17320116714111988
59.134 37.96979865771812 0.16002070844389754
59.134 37.97080536912752 0.1490472700463478
59.134 37.971812080536914 0.14004929621382256
59.134 37.97281879194631 0.13268378322876206
59.134 37.973825503355705 0.12659431367992702
59.134 37.9748322147651 0.12146447348967698
59.134 37.9758389261745 0.1135360251965512
59.134 37.97684563758389 0.10963636928677467
59.134 37.97785234899329 0.10609667189959529
59.134 37.978859060402684 0.09982673088344482
59.134 37.97986577181208 0.09673929430112907
59.134 37.980872483221475 0.09377967612738908
59.134 37.98187919463087 0.09091157377947032
59.134 37.982885906040266 0.08811003088067323
59.134 37.98389261744966 0.08535805225314959
59.134 37.98489932885906 0.08264419344917412
59.134 37.98590604026845 0.07996085875854614
59.134 37.98691275167785 0.07730310401560607
59.134 37.98791946308725 0.07466779415375027
59.134 37.98892617449665 0.0720530073991463
59.134 37.98993288590604 0.06945760915165736
59.134 37.99093959731544 0.06802790687167183
59.134 37.991946308724835 0.06542591746295981
59.134 37.99295302013423 0.06284271023580978
59.134 37.993959731543626 0.060277811126012174
59.134 37.99496644295302 0.057730495647829776
59.134 37.99597315436242 0.05519963992167744
59.134 37.99697986577181 0.05268355845718436
59.134 37.99798657718121 0.050179817817830034
59.134 37.998993288590604 0.04768501560763933
59.135 37.85 0.523149384396787
59.135 37.8510067114094 0.5216842645657742
59.135 37.85201342281879 0.5201757789565032
59.135 37.85302013422819 0.5034863669763975
59.135 37.854026845637584 0.5017945870117562
59.135 37.85503355704698 0.5000592479988188
59.135 37.856040268456375 0.4982808888534296
59.135 37.85704697986577 0.48474750043142767
59.135 37.85805369127517 0.4827807980090884
59.135 37.85906040268456 0.48077318852972223
59.135 37.86006711409396 0.4787262348983319
59.135 37.861073825503354 0.47664181797352906
59.135 37.86208053691275 0.4745221579455031
59.135 37.86308724832215 0.47236983459938003
59.135 37.86409395973155 0.4701878057757018
59.135 37.865100671140944 0.46797942311526886
59.135 37.86610738255034 0.46574844383549197
59.135 37.867114093959735 0.46349903674260207
59.135 37.86812080536913 0.4612357797928698
59.135 37.869127516778526 0.45896364501358927
59.135 37.87013422818792 0.45668796401923817
59.135 37.87114093959732 0.45441436289892934
59.135 37.87214765100671 0.4521486474784944
59.135 37.87315436241611 0.4498966063583904
59.135 37.874161073825505 0.4476636752805573
59.135 37.8751677852349 0.4454543645423987
59.135 37.876174496644296 0.4432712778516502
59.135 37.87718120805369 0.4411134228543696
59.135 37.87818791946309 0.44107378567804745
59.135 37.87919463087248 0.43118363608035665
59.135 37.88020134228188 0.42895147811320977
59.135 37.881208053691275 0.426603628232291
59.135 37.88221476510067 0.4240045110245659
59.135 37.883221476510066 0.4209156719104751
59.135 37.88422818791946 0.41692998778347484
59.135 37.88523489932886 0.4113794255480214
59.135 37.88624161073825 0.4032363804405421
59.135 37.88724832214765 0.39109406785174783
59.135 37.88825503355705 0.37343199817974754
59.135 37.88926174496645 0.3494322465233951
59.135 37.89026845637584 0.3202190839751208
59.135 37.89127516778524 0.282686041030495
59.135 37.892281879194634 0.2547937500505247
59.135 37.89328859060403 0.23313086469128586
59.135 37.894295302013425 0.2183809830166411
59.135 37.89530201342282 0.20950114081897472
59.135 37.89630872483222 0.20496657142116073
59.135 37.89731543624161 0.20349731684338696
59.135 37.89832214765101 0.20426194277660165
59.135 37.899328859060404 0.2068409840730239
59.135 37.9003355704698 0.21113599679180003
59.135 37.901342281879195 0.21728797757181448
59.135 37.90234899328859 0.22560336159132932
59.135 37.90335570469799 0.23645849733033647
59.135 37.90436241610738 0.25015058933623757
59.135 37.90536912751678 0.26669247755751424
59.135 37.906375838926174 0.2763617028476738
59.135 37.90738255033557 0.2965296901674685
59.135 37.908389261744965 0.3167469104491399
59.135 37.90939597315436 0.3357185671329914
59.135 37.910402684563756 0.352531837678562
59.135 37.91140939597315 0.3667870454321856
59.135 37.91241610738255 0.3785167252898989
59.135 37.91342281879195 0.3880102394696454
59.135 37.914429530201346 0.3956526337209996
59.135 37.91543624161074 0.4018219516836733
59.135 37.91644295302014 0.40684139144403786
59.135 37.91744966442953 0.4109665991037478
59.135 37.91845637583893 0.4143903376894507
59.135 37.919463087248324 0.41725342313641567
59.135 37.92046979865772 0.41965635414606833
59.135 37.921476510067116 0.42166938946177046
59.135 37.92248322147651 0.4233404778152727
59.135 37.92348993288591 0.4247011279428902
59.135 37.9244966442953 0.4257705055234623
59.135 37.9255033557047 0.4265580277386644
59.135 37.926510067114094 0.4270646197982051
59.135 37.92751677852349 0.42728264925821396
59.135 37.928523489932886 0.42719437250188674
59.135 37.92953020134228 0.42676850470890393
59.135 37.93053691275168 0.425954247708487
59.135 37.93154362416107 0.42467178229234487
59.135 37.93255033557047 0.42279791197415906
59.135 37.933557046979864 0.4201454415772894
59.135 37.93456375838926 0.4164355343119976
59.135 37.935570469798655 0.41126493250413415
59.135 37.93657718120805 0.40407678375688977
59.135 37.937583892617454 0.39415773357030576
59.135 37.93859060402685 0.3807048835741406
59.135 37.939597315436245 0.3630209332418346
59.135 37.94060402684564 0.3408642678410285
59.135 37.94161073825504 0.3148530066853662
59.135 37.94261744966443 0.2866405554591723
59.135 37.94362416107383 0.25857893427948325
59.135 37.94463087248322 0.23295341504540223
59.135 37.94563758389262 0.21128993755082914
59.135 37.946644295302015 0.19416631650001157
59.135 37.94765100671141 0.18150025698660668
59.135 37.948657718120806 0.1730062199185174
59.135 37.9496644295302 0.1685773994771222
59.135 37.9506711409396 0.16850623407782908
59.135 37.95167785234899 0.17350642681489467
59.135 37.95268456375839 0.18443902994036412
59.135 37.953691275167785 0.20162027061108068
59.135 37.95469798657718 0.22386512195515498
59.135 37.955704697986576 0.2480396624909914
59.135 37.95671140939597 0.26998287287427825
59.135 37.95771812080537 0.28633630654245407
59.135 37.95872483221476 0.2956587884173783
59.135 37.95973154362416 0.29814184517685505
59.135 37.960738255033554 0.2947120842137733
59.135 37.96174496644295 0.286386206120478
59.135 37.96275167785235 0.27408659934607726
59.135 37.96375838926175 0.2587366176078465
59.135 37.964765100671144 0.24139928960775558
59.135 37.96577181208054 0.2232932713746765
59.135 37.966778523489936 0.20564077561913463
59.135 37.96778523489933 0.18098704350280545
59.135 37.96879194630873 0.16693100823547422
59.135 37.96979865771812 0.15510050302281836
59.135 37.97080536912752 0.14536247632413463
59.135 37.971812080536914 0.13741139197580673
59.135 37.97281879194631 0.13088871932698895
59.135 37.973825503355705 0.1254566086316721
59.135 37.9748322147651 0.117354767195195
59.135 37.9758389261745 0.11333433304697187
59.135 37.97684563758389 0.10973043079711593
59.135 37.97785234899329 0.10344839325226654
59.135 37.978859060402684 0.1003637614659807
59.135 37.97986577181208 0.0974253080538543
59.135 37.980872483221475 0.09459028116290771
59.135 37.98187919463087 0.09182918834894398
59.135 37.982885906040266 0.08912188161996326
59.135 37.98389261744966 0.0864547546658007
59.135 37.98489932885906 0.0838187589550629
59.135 37.98590604026845 0.08120800852745239
59.135 37.98691275167785 0.07861880159614179
59.135 37.98791946308725 0.07604893429088994
59.135 37.98892617449665 0.07349721769468447
59.135 37.98993288590604 0.07215598086800945
59.135 37.99093959731544 0.06959529576818368
59.135 37.991946308724835 0.06705314170488591
59.135 37.99295302013423 0.06452977689605913
59.135 37.993959731543626 0.062025461585469406
59.135 37.99496644295302 0.05954036535234412
59.135 37.99597315436242 0.05707448129237359
59.135 37.99697986577181 0.05462753934747116
59.135 37.99798657718121 0.0521989117657079
59.135 37.998993288590604 0.04612517288770064
59.136 37.85 0.5240336330607238
59.136 37.8510067114094 0.5225708749871292
59.136 37.85201342281879 0.5055343096382696
59.136 37.85302013422819 0.5038834173804285
59.136 37.854026845637584 0.5021876211396042
59.136 37.85503355704698 0.5004472474563149
59.136 37.856040268456375 0.49866284813508965
59.136 37.85704697986577 0.4849607690484602
59.136 37.85805369127517 0.4829848141966344
59.136 37.85906040268456 0.4809670067564955
59.136 37.86006711409396 0.47890898052661757
59.136 37.861073825503354 0.47681270781970514
59.136 37.86208053691275 0.4746805225456281
59.136 37.86308724832215 0.47251514204678446
59.136 37.86409395973155 0.47031968692354015
59.136 37.865100671140944 0.46809769784102084
59.136 37.86610738255034 0.46585314793376836
59.136 37.867114093959735 0.46359044882384853
59.136 37.86812080536913 0.46131444727540327
59.136 37.869127516778526 0.4590304078257179
59.136 37.87013422818792 0.4567439738332963
59.136 37.87114093959732 0.4544610943406294
59.136 37.87214765100671 0.452187895327976
59.136 37.87315436241611 0.4499304584534272
59.136 37.874161073825505 0.44769444317234636
59.136 37.8751677852349 0.44548444033275525
59.136 37.876174496644296 0.4433028614821029
59.136 37.87718120805369 0.44114802148713295
59.136 37.87818791946309 0.4411300247239047
59.136 37.87919463087248 0.4390257075963032
59.136 37.88020134228188 0.42909219586529557
59.136 37.881208053691275 0.4267161618360054
59.136 37.88221476510067 0.42404938746599063
59.136 37.883221476510066 0.42082052551378774
59.136 37.88422818791946 0.41656888373962897
59.136 37.88523489932886 0.4105462048625216
59.136 37.88624161073825 0.40162365890922785
59.136 37.88724832214765 0.38831488629673655
59.136 37.88825503355705 0.36915384186178957
59.136 37.88926174496645 0.34366458446980513
59.136 37.89026845637584 0.31358993362547544
59.136 37.89127516778524 0.2830252202399434
59.136 37.892281879194634 0.24973925795865365
59.136 37.89328859060403 0.22981695452840073
59.136 37.894295302013425 0.21672727664456068
59.136 37.89530201342282 0.20921085059973799
59.136 37.89630872483222 0.2057774711399104
59.136 37.89731543624161 0.20529252301976342
59.136 37.89832214765101 0.20710332312763646
59.136 37.899328859060404 0.21098266836264823
59.136 37.9003355704698 0.21703688206487787
59.136 37.901342281879195 0.22560921929113514
59.136 37.90234899328859 0.23714988190903843
59.136 37.90335570469799 0.2520097179099761
59.136 37.90436241610738 0.270156632959932
59.136 37.90536912751678 0.29092047943825283
59.136 37.906375838926174 0.31296802789842265
59.136 37.90738255033557 0.3253371512570887
59.136 37.908389261744965 0.3449954360070563
59.136 37.90939597315436 0.3618976841043998
59.136 37.910402684563756 0.37580034483021596
59.136 37.91140939597315 0.3869246154694498
59.136 37.91241610738255 0.39571283612551744
59.136 37.91342281879195 0.40264717799745464
59.136 37.914429530201346 0.4081550995015715
59.136 37.91543624161074 0.41257772904689394
59.136 37.91644295302014 0.4161712905878431
59.136 37.91744966442953 0.41912120270730097
59.136 37.91845637583893 0.4215583742892148
59.136 37.919463087248324 0.4235734653068445
59.136 37.92046979865772 0.42522798497752123
59.136 37.921476510067116 0.4265623557691578
59.136 37.92248322147651 0.4276014298320701
59.136 37.92348993288591 0.4283579234244603
59.136 37.9244966442953 0.42883407334513546
59.136 37.9255033557047 0.42902160789298527
59.136 37.926510067114094 0.428899884692623
59.136 37.92751677852349 0.42843177199109195
59.136 37.928523489932886 0.427556529173765
59.136 37.92953020134228 0.42617859366785327
59.136 37.93053691275168 0.42415090821689116
59.136 37.93154362416107 0.42125153974856006
59.136 37.93255033557047 0.41715361955576846
59.136 37.933557046979864 0.4113926655968934
59.136 37.93456375838926 0.4033447296684267
59.136 37.935570469798655 0.3922458730720905
59.136 37.93657718120805 0.3773044177079019
59.136 37.937583892617454 0.3579593284503313
59.136 37.93859060402685 0.33427147685452246
59.136 37.939597315436245 0.30727054734991754
59.136 37.94060402684564 0.27893755657523883
59.136 37.94161073825504 0.2516494782232885
59.136 37.94261744966443 0.22736605773453294
59.136 37.94362416107383 0.20709816526423622
59.136 37.94463087248322 0.19091190005864886
59.136 37.94563758389262 0.1782797073929746
59.136 37.946644295302015 0.16846590743940262
59.136 37.94765100671141 0.16078238834602115
59.136 37.948657718120806 0.15470803046717224
59.136 37.9496644295302 0.14992735977937172
59.136 37.9506711409396 0.14634174579629813
59.136 37.95167785234899 0.14408651819580895
59.136 37.95268456375839 0.1435679111209825
59.136 37.953691275167785 0.14551069191468935
59.136 37.95469798657718 0.1509642486730469
59.136 37.955704697986576 0.16113692981793618
59.136 37.95671140939597 0.17685532468693074
59.136 37.95771812080537 0.19757831667594994
59.136 37.95872483221476 0.2205086800789231
59.136 37.95973154362416 0.2409915852956855
59.136 37.960738255033554 0.25456880049318403
59.136 37.96174496644295 0.2590415474384025
59.136 37.96275167785235 0.2548375525040151
59.136 37.96375838926175 0.2440224778974363
59.136 37.964765100671144 0.22918552782648471
59.136 37.96577181208054 0.2127371179505946
59.136 37.966778523489936 0.1965521192084418
59.136 37.96778523489933 0.17342262189629132
59.136 37.96879194630873 0.16083989702295617
59.136 37.96979865771812 0.15037039132958419
59.136 37.97080536912752 0.1418069450683367
59.136 37.971812080536914 0.13481823441794966
59.136 37.97281879194631 0.12905713226595406
59.136 37.973825503355705 0.12076468219246356
59.136 37.9748322147651 0.11661568868894898
59.136 37.9758389261745 0.11294736874163958
59.136 37.97684563758389 0.10665859077878925
59.136 37.97785234899329 0.10358605077217976
59.136 37.978859060402684 0.10068054122179956
59.136 37.97986577181208 0.09789181846007224
59.136 37.980872483221475 0.09518509236409806
59.136 37.98187919463087 0.0925365146170258
59.136 37.982885906040266 0.08992992408025893
59.136 37.98389261744966 0.08735453235177082
59.136 37.98489932885906 0.08480329200816786
59.136 37.98590604026845 0.08227175152158861
59.136 37.98691275167785 0.07975725311197357
59.136 37.98791946308725 0.07725837048313437
59.136 37.98892617449665 0.07477451358358182
59.136 37.98993288590604 0.07349921371978461
59.136 37.99093959731544 0.07100185994170656
59.136 37.991946308724835 0.0685211801856323
59.136 37.99295302013423 0.06605781269838125
59.136 37.993959731543626 0.06361242408993718
59.136 37.99496644295302 0.06118564531511978
59.136 37.99597315436242 0.05877801745882696
59.136 37.99697986577181 0.05638994202193549
59.136 37.99798657718121 0.054021631206130356
59.136 37.998993288590604 0.04803244515371267
59.137 37.85 0.5248730168912117
59.137 37.8510067114094 0.5074801255601723
59.137 37.85201342281879 0.5058715469618931
59.137 37.85302013422819 0.504216963888369
59.137 37.854026845637584 0.5025164934055583
59.137 37.85503355704698 0.5007704670020153
59.137 37.856040268456375 0.4989794544565044
59.137 37.85704697986577 0.4851075927312469
59.137 37.85805369127517 0.48312182874225057
59.137 37.85906040268456 0.48109331259782995
59.137 37.86006711409396 0.4790237537691763
59.137 37.861073825503354 0.476915221019661
59.137 37.86208053691275 0.4747701670876108
59.137 37.86308724832215 0.47259145193298746
59.137 37.86409395973155 0.4703823636936283
59.137 37.865100671140944 0.4681466362221893
59.137 37.86610738255034 0.4658884616565027
59.137 37.867114093959735 0.4636124958029475
59.137 37.86812080536913 0.461323852995873
59.137 37.869127516778526 0.4590280851953672
59.137 37.87013422818792 0.4567311367991262
59.137 37.87114093959732 0.4544392609110687
59.137 37.87214765100671 0.45215887275598504
59.137 37.87315436241611 0.4498962982532761
59.137 37.874161073825505 0.44765734466000134
59.137 37.8751677852349 0.4454465654927676
59.137 37.876174496644296 0.4432659959247648
59.137 37.87718120805369 0.44111296702012054
59.137 37.87818791946309 0.43897631603268084
59.137 37.87919463087248 0.43900583316222846
59.137 37.88020134228188 0.4291498961801593
59.137 37.881208053691275 0.4267330930549078
59.137 37.88221476510067 0.42397776399698106
59.137 37.883221476510066 0.4205748382909719
59.137 37.88422818791946 0.41600346072443306
59.137 37.88523489932886 0.4094284237189982
59.137 37.88624161073825 0.399619367097178
59.137 37.88724832214765 0.38503196948223206
59.137 37.88825503355705 0.3643172378875928
59.137 37.88926174496645 0.33743108388585363
59.137 37.89026845637584 0.30676182053302925
59.137 37.89127516778524 0.27678114783445773
59.137 37.892281879194634 0.24502443967937532
59.137 37.89328859060403 0.2269067731743326
59.137 37.894295302013425 0.21546843328095258
59.137 37.89530201342282 0.2093293793707798
59.137 37.89630872483222 0.20710339483344764
59.137 37.89731543624161 0.20785572676446393
59.137 37.89832214765101 0.2111707570427157
59.137 37.899328859060404 0.21707828764646203
59.137 37.9003355704698 0.22593616340356149
59.137 37.901342281879195 0.23825624359139497
59.137 37.90234899328859 0.25442324546989914
59.137 37.90335570469799 0.27431318346895006
59.137 37.90436241610738 0.29697685893715325
59.137 37.90536912751678 0.3206775819241307
59.137 37.906375838926174 0.34340396761102543
59.137 37.90738255033557 0.3543709601741903
59.137 37.908389261744965 0.37103016496864
59.137 37.90939597315436 0.38430618569407243
59.137 37.910402684563756 0.394628714809684
59.137 37.91140939597315 0.4025841480086072
59.137 37.91241610738255 0.4087323441026378
59.137 37.91342281879195 0.41353157325726464
59.137 37.914429530201346 0.4173271853891167
59.137 37.91543624161074 0.42036723791453057
59.137 37.91644295302014 0.42282473756789735
59.137 37.91744966442953 0.4248179150506152
59.137 37.91845637583893 0.42642603668831747
59.137 37.919463087248324 0.427700758826677
59.137 37.92046979865772 0.42867377381352223
59.137 37.921476510067116 0.42936151368429765
59.137 37.92248322147651 0.42976744396082744
59.137 37.92348993288591 0.4298821766828953
59.137 37.9244966442953 0.42968130781791714
59.137 37.9255033557047 0.42912053859391097
59.137 37.926510067114094 0.4281272689048312
59.137 37.92751677852349 0.4265874927683637
59.137 37.928523489932886 0.42432664624096544
59.137 37.92953020134228 0.4210835166083241
59.137 37.93053691275168 0.41647846864917437
59.137 37.93154362416107 0.4099830766105331
59.137 37.93255033557047 0.40091054176262386
59.137 37.933557046979864 0.3884659226093683
59.137 37.93456375838926 0.3719125376561256
59.137 37.935570469798655 0.3508918430805352
59.137 37.93657718120805 0.32582714557259684
59.137 37.937583892617454 0.29816113027504754
59.137 37.93859060402685 0.27012081156210654
59.137 37.939597315436245 0.2440014635931249
59.137 37.94060402684564 0.22140874566372826
59.137 37.94161073825504 0.20293920932797477
59.137 37.94261744966443 0.18835888128569395
59.137 37.94362416107383 0.17699587418297552
59.137 37.94463087248322 0.16807721764272313
59.137 37.94563758389262 0.16091665934073113
59.137 37.946644295302015 0.15498031910435997
59.137 37.94765100671141 0.14988596469690763
59.137 37.948657718120806 0.14537765612514553
59.137 37.9496644295302 0.1412979783952923
59.137 37.9506711409396 0.1375673136548165
59.137 37.95167785234899 0.13417368603907387
59.137 37.95268456375839 0.13117507434609438
59.137 37.953691275167785 0.1287166203355478
59.137 37.95469798657718 0.12706624778440104
59.137 37.955704697986576 0.12667136492071407
59.137 37.95671140939597 0.12823020333996576
59.137 37.95771812080537 0.13273833473942057
59.137 37.95872483221476 0.14138667157401835
59.137 37.95973154362416 0.15505084024031068
59.137 37.960738255033554 0.17312521708296374
59.137 37.96174496644295 0.19221818514314365
59.137 37.96275167785235 0.20668399912601215
59.137 37.96375838926175 0.2120850113795564
59.137 37.964765100671144 0.20823048990738366
59.137 37.96577181208054 0.1982940342843194
59.137 37.966778523489936 0.17748786622738041
59.137 37.96778523489933 0.16540544270749225
59.137 37.96879194630873 0.15468511054498266
59.137 37.96979865771812 0.14566921114092782
59.137 37.97080536912752 0.13825315426556534
59.137 37.971812080536914 0.13216301126417065
59.137 37.97281879194631 0.1236727276592075
59.137 37.973825503355705 0.11939179229026681
59.137 37.9748322147651 0.11566179366513055
59.137 37.9758389261745 0.10937455670601667
59.137 37.97684563758389 0.10632676236420228
59.137 37.97785234899329 0.10346977683248862
59.137 37.978859060402684 0.10074472955424918
59.137 37.97986577181208 0.09811066146143943
59.137 37.980872483221475 0.09553938505843904
59.137 37.98187919463087 0.0930117290772392
59.137 37.982885906040266 0.09051484696515631
59.137 37.98389261744966 0.08804030998805942
59.137 37.98489932885906 0.08558276507326767
59.137 37.98590604026845 0.08313899310516232
59.137 37.98691275167785 0.08070724860926612
59.137 37.98791946308725 0.07828679612510618
59.137 37.98892617449665 0.07711544447505513
59.137 37.98993288590604 0.07467360536875214
59.137 37.99093959731544 0.07224491103892455
59.137 37.991946308724835 0.06983021067669101
59.137 37.99295302013423 0.06743043823138303
59.137 37.993959731543626 0.06504654889463482
59.137 37.99496644295302 0.06267947096090323
59.137 37.99597315436242 0.06033006792078461
59.137 37.99697986577181 0.057999106900273524
59.137 37.99798657718121 0.05206332378719467
59.137 37.998993288590604 0.04977575627963893
59.138 37.85 0.5093233789950576
59.138 37.8510067114094 0.5077580752478074
59.138 37.85201342281879 0.5061458918379248
59.138 37.85302013422819 0.5044867406202
59.138 37.854026845637584 0.502780735175448
59.138 37.85503355704698 0.5010282151827942
59.138 37.856040268456375 0.4871380856235501
59.138 37.85704697986577 0.48518670832611266
59.138 37.85805369127517 0.4831902603208297
59.138 37.85906040268456 0.4811501761961171
59.138 37.86006711409396 0.4790682435835279
59.138 37.861073825503354 0.47694663025632056
59.138 37.86208053691275 0.4747879102989901
59.138 37.86308724832215 0.4725950885970911
59.138 37.86409395973155 0.4703716226833636
59.138 37.865100671140944 0.4681214406678703
59.138 37.86610738255034 0.4658489535089611
59.138 37.867114093959735 0.463559059122614
59.138 37.86812080536913 0.4612571345641197
59.138 37.869127516778526 0.45894901035859403
59.138 37.87013422818792 0.456640917316531
59.138 37.87114093959732 0.4543393896316048
59.138 37.87214765100671 0.4520510965714847
59.138 37.87315436241611 0.4497825548505411
59.138 37.874161073825505 0.4475396381596447
59.138 37.8751677852349 0.4453267376654707
59.138 37.876174496644296 0.4431453173088843
59.138 37.87718120805369 0.4409914156308599
59.138 37.87818791946309 0.4388513132966993
59.138 37.87919463087248 0.4388896041987986
59.138 37.88020134228188 0.4366925649452996
59.138 37.881208053691275 0.42662932506312967
59.138 37.88221476510067 0.42376118187348855
59.138 37.883221476510066 0.4201457190114966
59.138 37.88422818791946 0.41519516099261483
59.138 37.88523489932886 0.40798141224809203
59.138 37.88624161073825 0.3971758003540264
59.138 37.88724832214765 0.38120587068403844
59.138 37.88825503355705 0.35891169840895976
59.138 37.88926174496645 0.33076467918446123
59.138 37.89026845637584 0.2997914729054367
59.138 37.89127516778524 0.27069650014662483
59.138 37.892281879194634 0.2406457417711148
59.138 37.89328859060403 0.22438806856996257
59.138 37.894295302013425 0.21463778908120756
59.138 37.89530201342282 0.20999738416794742
59.138 37.89630872483222 0.20926664075544654
59.138 37.89731543624161 0.21178796217594942
59.138 37.89832214765101 0.217461641973797
59.138 37.899328859060404 0.22662453430220722
59.138 37.9003355704698 0.23982338210329282
59.138 37.901342281879195 0.2574374671877796
59.138 37.90234899328859 0.27917962372494043
59.138 37.90335570469799 0.30372959133861216
59.138 37.90436241610738 0.32889057315877623
59.138 37.90536912751678 0.35233948430970946
59.138 37.906375838926174 0.37245908995304244
59.138 37.90738255033557 0.3886692866416796
59.138 37.908389261744965 0.39198676326610604
59.138 37.90939597315436 0.40135162380991446
59.138 37.910402684563756 0.4083880095494668
59.138 37.91140939597315 0.4137075705064616
59.138 37.91241610738255 0.4177794706863618
59.138 37.91342281879195 0.42094048081757346
59.138 37.914429530201346 0.423423161418392
59.138 37.91543624161074 0.42538422198100456
59.138 37.91644295302014 0.4269271692306742
59.138 37.91744966442953 0.428118558419254
59.138 37.91845637583893 0.42899883771601877
59.138 37.919463087248324 0.4295889770248501
59.138 37.92046979865772 0.42989376897864684
59.138 37.921476510067116 0.42990226011282434
59.138 37.92248322147651 0.429585327605707
59.138 37.92348993288591 0.4288899773167465
59.138 37.9244966442953 0.4277295085125532
59.138 37.9255033557047 0.42596834035290254
59.138 37.926510067114094 0.4234002761887286
59.138 37.92751677852349 0.4197199422853755
59.138 37.928523489932886 0.4144904634857172
59.138 37.92953020134228 0.40711851174824587
59.138 37.93053691275168 0.39686323171246307
59.138 37.93154362416107 0.38292630830557944
59.138 37.93255033557047 0.3646787015562871
59.138 37.933557046979864 0.3420307641719737
59.138 37.93456375838926 0.31580663965750577
59.138 37.935570469798655 0.2878204597284989
59.138 37.93657718120805 0.26042366584757853
59.138 37.937583892617454 0.23570630118472716
59.138 37.93859060402685 0.21487613594769955
59.138 37.939597315436245 0.19815684334215827
59.138 37.94060402684564 0.18509073872763218
59.138 37.94161073825504 0.17493101205103911
59.138 37.94261744966443 0.16692097985800897
59.138 37.94362416107383 0.1604266853312139
59.138 37.94463087248322 0.1549693029067053
59.138 37.94563758389262 0.15020953692066338
59.138 37.946644295302015 0.14591711608052887
59.138 37.94765100671141 0.1419409255426442
59.138 37.948657718120806 0.13818508682764716
59.138 37.9496644295302 0.13459167631018415
59.138 37.9506711409396 0.13112920813813864
59.138 37.95167785234899 0.12778582686365844
59.138 37.95268456375839 0.12456653602966641
59.138 37.953691275167785 0.12149442076596625
59.138 37.95469798657718 0.11861666500930729
59.138 37.955704697986576 0.11601731730188578
59.138 37.95671140939597 0.11384035666647502
59.138 37.95771812080537 0.11232852339467878
59.138 37.95872483221476 0.11188408075458305
59.138 37.95973154362416 0.11315025860645896
59.138 37.960738255033554 0.11707307426537245
59.138 37.96174496644295 0.12477586573283125
59.138 37.96275167785235 0.1368354661254612
59.138 37.96375838926175 0.1516603715554439
59.138 37.964765100671144 0.16452270675422248
59.138 37.96577181208054 0.1619820552475552
59.138 37.966778523489936 0.1606129910343068
59.138 37.96778523489933 0.15476024067875507
59.138 37.96879194630873 0.14750980894574275
59.138 37.96979865771812 0.1405500029442023
59.138 37.97080536912752 0.1344651006778158
59.138 37.971812080536914 0.12589987773338382
59.138 37.97281879194631 0.12153417249379456
59.138 37.973825503355705 0.11776542968348025
59.138 37.9748322147651 0.11443335267113258
59.138 37.9758389261745 0.10849182941588525
59.138 37.97684563758389 0.10570311598205874
59.138 37.97785234899329 0.10306318795148184
59.138 37.978859060402684 0.10052431773687859
59.138 37.97986577181208 0.0980533631356082
59.138 37.980872483221475 0.09562763205881736
59.138 37.98187919463087 0.09323183213497363
59.138 37.982885906040266 0.0908558738144227
59.138 37.98389261744966 0.08849330887545435
59.138 37.98489932885906 0.08614022808910476
59.138 37.98590604026845 0.08379448515163895
59.138 37.98691275167785 0.08145515029207463
59.138 37.98791946308725 0.07912212480213768
59.138 37.98892617449665 0.07803268330364621
59.138 37.98993288590604 0.07567013308406453
59.138 37.99093959731544 0.07331702769743895
59.138 37.991946308724835 0.07097453252144734
59.138 37.99295302013423 0.0686438668373008
59.138 37.993959731543626 0.06632625103159462
59.138 37.99496644295302 0.06402286768761925
59.138 37.99597315436242 0.06173483246820272
59.138 37.99697986577181 0.05946317176142474
59.138 37.99798657718121 0.053606068991964066
59.138 37.998993288590604 0.05137450297108205
59.139 37.85 0.5095425451153754
59.139 37.8510067114094 0.5079734700432172
59.139 37.85201342281879 0.5063565527819135
59.139 37.85302013422819 0.5046916922112963
59.139 37.854026845637584 0.5029789991556879
59.139 37.85503355704698 0.5012188224594011
59.139 37.856040268456375 0.48715802998998753
59.139 37.85704697986577 0.48519560513926197
59.139 37.85805369127517 0.4831871375597955
59.139 37.85906040268456 0.4811341197251532
59.139 37.86006711409396 0.4790384162893315
59.139 37.861073825503354 0.4769022927500212
59.139 37.86208053691275 0.4747284429832648
59.139 37.86308724832215 0.4725200147988872
59.139 37.86409395973155 0.47028063242546203
59.139 37.865100671140944 0.46801441448569864
59.139 37.86610738255034 0.4657259854908671
59.139 37.867114093959735 0.4634204780229855
59.139 37.86812080536913 0.46110352133855065
59.139 37.869127516778526 0.45878120967151964
59.139 37.87013422818792 0.4564600392464235
59.139 37.87114093959732 0.4541467955399341
59.139 37.87214765100671 0.4518483591856225
59.139 37.87315436241611 0.4495713757558828
59.139 37.874161073825505 0.4473216938426976
59.139 37.8751677852349 0.44510340403842696
59.139 37.876174496644296 0.4429171854111987
59.139 37.87718120805369 0.440757446248141
59.139 37.87818791946309 0.4386073662959266
59.139 37.87919463087248 0.43643030476935635
59.139 37.88020134228188 0.43640938142961877
59.139 37.881208053691275 0.4339442718496152
59.139 37.88221476510067 0.423356097025278
59.139 37.883221476510066 0.41948330396250955
59.139 37.88422818791946 0.4140863633682097
59.139 37.88523489932886 0.40613956361457526
59.139 37.88624161073825 0.39422368934592444
59.139 37.88724832214765 0.3767773896394562
59.139 37.88825503355705 0.35291004398323217
59.139 37.88926174496645 0.32368052251434104
59.139 37.89026845637584 0.2927120550716472
59.139 37.89127516778524 0.2647870254597855
59.139 37.892281879194634 0.24329687694213087
59.139 37.89328859060403 0.2223037302003386
59.139 37.894295302013425 0.21439432384123044
59.139 37.89530201342282 0.21158368002845457
59.139 37.89630872483222 0.21296416571571708
59.139 37.89731543624161 0.21825229686521358
59.139 37.89832214765101 0.2277135023177795
59.139 37.899328859060404 0.2418825950378178
59.139 37.9003355704698 0.2610677783819973
59.139 37.901342281879195 0.284719360608279
59.139 37.90234899328859 0.31104424877982545
59.139 37.90335570469799 0.33735648550085884
59.139 37.90436241610738 0.3611009800713032
59.139 37.90536912751678 0.380767897079884
59.139 37.906375838926174 0.3960699691409577
59.139 37.90738255033557 0.40752982848193786
59.139 37.908389261744965 0.4159694214466836
59.139 37.90939597315436 0.41293002550183683
59.139 37.910402684563756 0.41741176334531227
59.139 37.91140939597315 0.4207620710929489
59.139 37.91241610738255 0.4232985965825905
59.139 37.91342281879195 0.4252329748020324
59.139 37.914429530201346 0.42670293416422966
59.139 37.91543624161074 0.4277957015638945
59.139 37.91644295302014 0.42856362524533387
59.139 37.91744966442953 0.4290337246308139
59.139 37.91845637583893 0.42921257470949664
59.139 37.919463087248324 0.4290873472300943
59.139 37.92046979865772 0.42862322479820125
59.139 37.921476510067116 0.4277568368550458
59.139 37.92248322147651 0.42638486615149207
59.139 37.92348993288591 0.4243466574413162
59.139 37.9244966442953 0.42139989386335186
59.139 37.9255033557047 0.4171900785520036
59.139 37.926510067114094 0.4112194320975623
59.139 37.92751677852349 0.40283153984533326
59.139 37.928523489932886 0.3912462797823727
59.139 37.92953020134228 0.37569868678062085
59.139 37.93053691275168 0.35572737014238487
59.139 37.93154362416107 0.33157234177493006
59.139 37.93255033557047 0.3044696133067074
59.139 37.933557046979864 0.27652710324005797
59.139 37.93456375838926 0.2500868729705957
59.139 37.935570469798655 0.22693424927267367
59.139 37.93657718120805 0.20786885482145978
59.139 37.937583892617454 0.19279613828119954
59.139 37.93859060402685 0.18110021241417967
59.139 37.939597315436245 0.1720043537272254
59.139 37.94060402684564 0.16478959826409423
59.139 37.94161073825504 0.15888103650722157
59.139 37.94261744966443 0.15385612219828682
59.139 37.94362416107383 0.14942042436515574
59.139 37.94463087248322 0.14537617040755169
59.139 37.94563758389262 0.14159422474636976
59.139 37.946644295302015 0.1379924263844084
59.139 37.94765100671141 0.13452000087041976
59.139 37.948657718120806 0.13114679780175842
59.139 37.9496644295302 0.12785606297409396
59.139 37.9506711409396 0.12463970973784884
59.139 37.95167785234899 0.12149535682948545
59.139 37.95268456375839 0.11842467291079445
59.139 37.953691275167785 0.11543280789758593
59.139 37.95469798657718 0.11252892773087124
59.139 37.955704697986576 0.10972815709176137
59.139 37.95671140939597 0.1070556653826993
59.139 37.95771812080537 0.10455436703089753
59.139 37.95872483221476 0.10229903391532646
59.139 37.95973154362416 0.10042197438854378
59.139 37.960738255033554 0.09915909838305272
59.139 37.96174496644295 0.09892808954155363
59.139 37.96275167785235 0.10043817819500121
59.139 37.96375838926175 0.10474566103650901
59.139 37.964765100671144 0.11288610693384932
59.139 37.96577181208054 0.11593143471740486
59.139 37.966778523489936 0.12747178429617234
59.139 37.96778523489933 0.13437253599432852
59.139 37.96879194630873 0.13561374217440408
59.139 37.96979865771812 0.13332009525139168
59.139 37.97080536912752 0.12631230126665088
59.139 37.971812080536914 0.12252930433354234
59.139 37.97281879194631 0.11899340124249688
59.139 37.973825503355705 0.11578457034412701
59.139 37.9748322147651 0.10995542932975702
59.139 37.9758389261745 0.10727181399251104
59.139 37.97684563758389 0.10474690725443568
59.139 37.97785234899329 0.10233120680437072
59.139 37.978859060402684 0.0999881304811476
59.139 37.97986577181208 0.09769171550630465
59.139 37.980872483221475 0.09542406294948727
59.139 37.98187919463087 0.09317316937990269
59.139 37.982885906040266 0.09093125642129696
59.139 37.98389261744966 0.08869353908845284
59.139 37.98489932885906 0.08645733665517233
59.139 37.98590604026845 0.08422143690309736
59.139 37.98691275167785 0.0819856429161814
59.139 37.98791946308725 0.08102979969128021
59.139 37.98892617449665 0.07875187194782296
59.139 37.98993288590604 0.07647755081505198
59.139 37.99093959731544 0.07420825046335633
59.139 37.991946308724835 0.0719454725268486
59.139 37.99295302013423 0.06969074257920943
59.139 37.993959731543626 0.06744556385771498
59.139 37.99496644295302 0.06521138357920792
59.139 37.99597315436242 0.06298956848420964
59.139 37.99697986577181 0.05719524111705258
59.139 37.99798657718121 0.055006126662033576
59.139 37.998993288590604 0.052833262665084244
59.14 37.85 0.5096990921674562
59.14 37.8510067114094 0.5081248194486422
59.14 37.85201342281879 0.5065016941740426
59.14 37.85302013422819 0.5048295996321932
59.14 37.854026845637584 0.5031086412739804
59.14 37.85503355704698 0.5013391745239442
59.14 37.856040268456375 0.4871053226805552
59.14 37.85704697986577 0.48512993705718355
59.14 37.85805369127517 0.4831074450962409
59.14 37.85906040268456 0.48103939097046655
59.14 37.86006711409396 0.4789277098152762
59.14 37.861073825503354 0.47677475787480306
59.14 37.86208053691275 0.47458334127776536
59.14 37.86308724832215 0.4723567424785182
59.14 37.86409395973155 0.4700987431266331
59.14 37.865100671140944 0.46781364173490503
59.14 37.86610738255034 0.4655062639126838
59.14 37.867114093959735 0.4631819619554426
59.14 37.86812080536913 0.4608465989488476
59.14 37.869127516778526 0.4585065097450255
59.14 37.87013422818792 0.4561684262945094
59.14 37.87114093959732 0.4538393462684765
59.14 37.87214765100671 0.4515263088528434
59.14 37.87315436241611 0.4492360150514999
59.14 37.874161073825505 0.44697418304511244
59.14 37.8751677852349 0.4447444468169772
59.14 37.876174496644296 0.44254646190006186
59.14 37.87718120805369 0.44037263066887666
59.14 37.87818791946309 0.438202426891928
59.14 37.87919463087248 0.4359925709053112
59.14 37.88020134228188 0.4359349097942024
59.14 37.881208053691275 0.43336854793906837
59.14 37.88221476510067 0.4302624912062793
59.14 37.883221476510066 0.4185163366084908
59.14 37.88422818791946 0.41259602394837264
59.14 37.88523489932886 0.40381233691422824
59.14 37.88624161073825 0.3906690660887948
59.14 37.88724832214765 0.3716656957843731
59.14 37.88825503355705 0.34626746104464523
59.14 37.88926174496645 0.31617624019172424
59.14 37.89026845637584 0.28553953962745154
59.14 37.89127516778524 0.2590699206813119
59.14 37.892281879194634 0.239620304975946
59.14 37.89328859060403 0.22083084440642092
59.14 37.894295302013425 0.21515492620819876
59.14 37.89530201342282 0.21488948672518338
59.14 37.89630872483222 0.21954115484957076
59.14 37.89731543624161 0.22924315557215957
59.14 37.89832214765101 0.244446402606666
59.14 37.899328859060404 0.2652889244341762
59.14 37.9003355704698 0.29083003746742425
59.14 37.901342281879195 0.3187043102242834
59.14 37.90234899328859 0.34575176962439735
59.14 37.90335570469799 0.36931388328330933
59.14 37.90436241610738 0.38812562895568026
59.14 37.90536912751678 0.4022546404917121
59.14 37.906375838926174 0.41249737064500824
59.14 37.90738255033557 0.4198200072505483
59.14 37.908389261744965 0.42506405146516124
59.14 37.90939597315436 0.42886157334229047
59.14 37.910402684563756 0.4223702568413098
59.14 37.91140939597315 0.4243033029349671
59.14 37.91241610738255 0.42570491834125673
59.14 37.91342281879195 0.42669197606636544
59.14 37.914429530201346 0.42733376717331617
59.14 37.91543624161074 0.4276659588898767
59.14 37.91644295302014 0.42769795087281215
59.14 37.91744966442953 0.42741500097067825
59.14 37.91845637583893 0.4267756735190079
59.14 37.919463087248324 0.42570442481691717
59.14 37.92046979865772 0.4240785554035428
59.14 37.921476510067116 0.42170851118826613
59.14 37.92248322147651 0.418311125522867
59.14 37.92348993288591 0.4134780413913724
59.14 37.9244966442953 0.4066483831554405
59.14 37.9255033557047 0.39710839993992963
59.14 37.926510067114094 0.38406088504470753
59.14 37.92751677852349 0.3668203039770904
59.14 37.928523489932886 0.345156856134447
59.14 37.92953020134228 0.3196875006961309
59.14 37.93053691275168 0.29203808971089773
59.14 37.93154362416107 0.26450317698974735
59.14 37.93255033557047 0.23928556835875664
59.14 37.933557046979864 0.2178000258995399
59.14 37.93456375838926 0.2004586259329062
59.14 37.935570469798655 0.18691196866426174
59.14 37.93657718120805 0.17644390684579525
59.14 37.937583892617454 0.16828147378920427
59.14 37.93859060402685 0.1617560527813321
59.14 37.939597315436245 0.15635260205113316
59.14 37.94060402684564 0.1517010522531113
59.14 37.94161073825504 0.14754728244349663
59.14 37.94261744966443 0.14372234373021184
59.14 37.94362416107383 0.14011684469896352
59.14 37.94463087248322 0.13666178138913815
59.14 37.94563758389262 0.1333149745362297
59.14 37.946644295302015 0.1300517754803428
59.14 37.94765100671141 0.12685881367233826
59.14 37.948657718120806 0.12372983438275754
59.14 37.9496644295302 0.12066294511125432
59.14 37.9506711409396 0.11765880464265638
59.14 37.95167785234899 0.11471944723479163
59.14 37.95268456375839 0.11184754802201527
59.14 37.953691275167785 0.10904601910069518
59.14 37.95469798657718 0.10631789356857162
59.14 37.955704697986576 0.1036665229625454
59.14 37.95671140939597 0.10109620415829867
59.14 37.95771812080537 0.0986135034937013
59.14 37.95872483221476 0.09622983624764304
59.14 37.95973154362416 0.09396645344849214
59.14 37.960738255033554 0.09186423589085956
59.14 37.96174496644295 0.09000329929939743
59.14 37.96275167785235 0.08854250264066313
59.14 37.96375838926175 0.08779677473640671
59.14 37.964765100671144 0.07980160089366391
59.14 37.96577181208054 0.08283795372780536
59.14 37.966778523489936 0.0894928658776141
59.14 37.96778523489933 0.09985654781170938
59.14 37.96879194630873 0.11090033116209361
59.14 37.96979865771812 0.11498720111952301
59.14 37.97080536912752 0.11770240999723305
59.14 37.971812080536914 0.11729221345587398
59.14 37.97281879194631 0.11547372595430738
59.14 37.973825503355705 0.11029240954463085
59.14 37.9748322147651 0.1079447573095417
59.14 37.9758389261745 0.10564329846196988
59.14 37.97684563758389 0.10341111788137883
59.14 37.97785234899329 0.10123778720691702
59.14 37.978859060402684 0.09910556739669983
59.14 37.97986577181208 0.09699823309779489
59.14 37.980872483221475 0.09490331693943224
59.14 37.98187919463087 0.09281208276480563
59.14 37.982885906040266 0.09071885907972055
59.14 37.98389261744966 0.08862030739491049
59.14 37.98489932885906 0.08651479867332955
59.14 37.98590604026845 0.08440192717680947
59.14 37.98691275167785 0.08228214549139784
59.14 37.98791946308725 0.08143295322229654
59.14 37.98892617449665 0.07925899718621274
59.14 37.98993288590604 0.07708306716760116
59.14 37.99093959731544 0.07490696715042962
59.14 37.991946308724835 0.07273255942781212
59.14 37.99295302013423 0.07056170595448437
59.14 37.993959731543626 0.06839622520569155
59.14 37.99496644295302 0.06623786070820563
59.14 37.99597315436242 0.06408825842881197
59.14 37.99697986577181 0.0583833553345912
59.14 37.99798657718121 0.056260032403381566
59.14 37.998993288590604 0.054150104420041256
59.141 37.85 0.5097906176728645
59.141 37.8510067114094 0.5082092688365449
59.141 37.85201342281879 0.5065779577520142
59.141 37.85302013422819 0.5048965447659566
59.141 37.854026845637584 0.5031651222891439
59.141 37.85503355704698 0.5013840443729504
59.141 37.856040268456375 0.48697382398897426
59.141 37.85704697986577 0.48498268368630837
59.141 37.85805369127517 0.48294319091047333
59.141 37.85906040268456 0.4808569279668699
59.141 37.86006711409396 0.47872588619653217
59.141 37.861073825503354 0.47655249753666473
59.141 37.86208053691275 0.47433966443030795
59.141 37.86308724832215 0.47209078699176127
59.141 37.86409395973155 0.46980978602659806
59.141 37.865100671140944 0.46750112005966854
59.141 37.86610738255034 0.4651697938405894
59.141 37.867114093959735 0.4628213546873184
59.141 37.86812080536913 0.46046187116896825
59.141 37.869127516778526 0.45809788543287844
59.141 37.87013422818792 0.45573632490871174
59.141 37.87114093959732 0.45338434933605387
59.141 37.87214765100671 0.45104909181355096
59.141 37.87315436241611 0.4487372221344184
59.141 37.874161073825505 0.44645420701619387
59.141 37.8751677852349 0.4442030474400429
59.141 37.876174496644296 0.4419821079687151
59.141 37.87718120805369 0.439781365498552
59.141 37.87818791946309 0.4375759122039513
59.141 37.87919463087248 0.4353147244132238
59.141 37.88020134228188 0.43290140221610435
59.141 37.881208053691275 0.43249729361307926
59.141 37.88221476510067 0.4291672558858464
59.141 37.883221476510066 0.4246915351765984
59.141 37.88422818791946 0.41061380413954024
59.141 37.88523489932886 0.40087881486095817
59.141 37.88624161073825 0.3863888458150085
59.141 37.88724832214765 0.3657654528201718
59.141 37.88825503355705 0.3389204741700634
59.141 37.88926174496645 0.3082355522507442
59.141 37.89026845637584 0.278290558778679
59.141 37.89127516778524 0.25361003287202377
59.141 37.892281879194634 0.23653169824194553
59.141 37.89328859060403 0.2271539951944974
59.141 37.894295302013425 0.21783189093039668
59.141 37.89530201342282 0.22145605067178709
59.141 37.89630872483222 0.2312572789565514
59.141 37.89731543624161 0.24750413157979934
59.141 37.89832214765101 0.27002598551462115
59.141 37.899328859060404 0.2973363436028112
59.141 37.9003355704698 0.32641614324709867
59.141 37.901342281879195 0.3537019888441849
59.141 37.90234899328859 0.3765965084706362
59.141 37.90335570469799 0.3942021075691182
59.141 37.90436241610738 0.40696482658278443
59.141 37.90536912751678 0.41591612977252546
59.141 37.906375838926174 0.4221161295810729
59.141 37.90738255033557 0.42641643555282993
59.141 37.908389261744965 0.4294239061529864
59.141 37.90939597315436 0.43154326420952327
59.141 37.910402684563756 0.4330335800807169
59.141 37.91140939597315 0.4247479779565463
59.141 37.91241610738255 0.42525555454583097
59.141 37.91342281879195 0.42543552521567113
59.141 37.914429530201346 0.42530207304226963
59.141 37.91543624161074 0.42483895283141815
59.141 37.91644295302014 0.42399732974160653
59.141 37.91744966442953 0.422687615442296
59.141 37.91845637583893 0.4207647369210793
59.141 37.919463087248324 0.4180061435953307
59.141 37.92046979865772 0.41408300541235693
59.141 37.921476510067116 0.4085289950139427
59.141 37.92248322147651 0.4007202471881055
59.141 37.92348993288591 0.3898966601211959
59.141 37.9244966442953 0.3752746909354468
59.141 37.9255033557047 0.35630280558954175
59.141 37.926510067114094 0.3330453230727194
59.141 37.92751677852349 0.3065216863569932
59.141 37.928523489932886 0.2786903979234881
59.141 37.92953020134228 0.25190690931946835
59.141 37.93053691275168 0.22812294578977527
59.141 37.93154362416107 0.20835291287738178
59.141 37.93255033557047 0.1926647898128651
59.141 37.933557046979864 0.18051902768349382
59.141 37.93456375838926 0.17114770662304468
59.141 37.935570469798655 0.16380573381699748
59.141 37.93657718120805 0.1578815972361361
59.141 37.937583892617454 0.15291829718050753
59.141 37.93859060402685 0.14859356783089925
59.141 37.939597315436245 0.1446888215736563
59.141 37.94060402684564 0.14106001696710935
59.141 37.94161073825504 0.13761459727172126
59.141 37.94261744966443 0.13429467230525688
59.141 37.94362416107383 0.13106529419811028
59.141 37.94463087248322 0.12790649350411198
59.141 37.94563758389262 0.1248079486659335
59.141 37.946644295302015 0.12176544463821021
59.141 37.94765100671141 0.11877852550015826
59.141 37.948657718120806 0.1158489357072538
59.141 37.9496644295302 0.11297957984003706
59.141 37.9506711409396 0.11017382364058266
59.141 37.95167785234899 0.10743502186466203
59.141 37.95268456375839 0.104766200687315
59.141 37.953691275167785 0.10216985130319832
59.141 37.95469798657718 0.09964781243114824
59.141 37.955704697986576 0.0972012373439767
59.141 37.95671140939597 0.09483066105763656
59.141 37.95771812080537 0.09253621363901102
59.141 37.95872483221476 0.09031808234652848
59.141 37.95973154362416 0.08817744373553894
59.141 37.960738255033554 0.08611834728459464
59.141 37.96174496644295 0.08415161991051624
59.141 37.96275167785235 0.08230319849285724
59.141 37.96375838926175 0.07199841784224799
59.141 37.964765100671144 0.0707429082456134
59.141 37.96577181208054 0.07008567816439468
59.141 37.966778523489936 0.07063021121394022
59.141 37.96778523489933 0.07348076050914602
59.141 37.96879194630873 0.07670492150183492
59.141 37.96979865771812 0.08707943028932023
59.141 37.97080536912752 0.0980591979012386
59.141 37.971812080536914 0.10553486680622468
59.141 37.97281879194631 0.10577316213464487
59.141 37.973825503355705 0.10607458476218956
59.141 37.9748322147651 0.10501618556054099
59.141 37.9758389261745 0.10340710428479528
59.141 37.97684563758389 0.10160001864332154
59.141 37.97785234899329 0.09972971866816532
59.141 37.978859060402684 0.09784096727270687
59.141 37.97986577181208 0.09594467695415862
59.141 37.980872483221475 0.09404052919066551
59.141 37.98187919463087 0.09212551370812042
59.141 37.982885906040266 0.09019686644271287
59.141 37.98389261744966 0.08825287715107256
59.141 37.98489932885906 0.08629294020967651
59.141 37.98590604026845 0.08431737438131953
59.141 37.98691275167785 0.08364425469136898
59.141 37.98791946308725 0.08159679103929982
59.141 37.98892617449665 0.07953890379518358
59.141 37.98993288590604 0.07747267057985403
59.141 37.99093959731544 0.07540028685896386
59.141 37.991946308724835 0.07332399057959005
59.141 37.99295302013423 0.07124600491459787
59.141 37.993959731543626 0.06916849515541434
59.141 37.99496644295302 0.0670935367111643
59.141 37.99597315436242 0.061473830387865275
59.141 37.99697986577181 0.05941362517429094
59.141 37.99798657718121 0.0573618642241742
59.141 37.998993288590604 0.0553200952365022
59.142 37.85 0.5098129506273845
59.142 37.8510067114094 0.5082219865689424
59.142 37.85201342281879 0.506579776194193
59.142 37.85302013422819 0.504886142584402
59.142 37.854026845637584 0.5031411500211335
59.142 37.85503355704698 0.5013451353602332
59.142 37.856040268456375 0.4867539177709441
59.142 37.85704697986577 0.4847429533172265
59.142 37.85805369127517 0.4826820769171749
59.142 37.85906040268456 0.4805728851244278
59.142 37.86006711409396 0.47841740050968923
59.142 37.861073825503354 0.4762181046164281
59.142 37.86208053691275 0.4739779689540802
59.142 37.86308724832215 0.47170048279115284
59.142 37.86409395973155 0.46938967616213945
59.142 37.865100671140944 0.4670501359975563
59.142 37.86610738255034 0.464687012510372
59.142 37.867114093959735 0.4623060117114891
59.142 37.86812080536913 0.4599133678082163
59.142 37.869127516778526 0.45751578559047645
59.142 37.87013422818792 0.4551203365367769
59.142 37.87114093959732 0.4527342811653961
59.142 37.87214765100671 0.4503647703869513
59.142 37.87315436241611 0.4480183437161743
59.142 37.874161073825505 0.445700080665432
59.142 37.8751677852349 0.44341215343531154
59.142 37.876174496644296 0.44115133969243325
59.142 37.87718120805369 0.4389047259202124
59.142 37.87818791946309 0.43664227165617053
59.142 37.87919463087248 0.4343039776779346
59.142 37.88020134228188 0.43177795615548265
59.142 37.881208053691275 0.42886373407594963
59.142 37.88221476510067 0.427611179682222
59.142 37.883221476510066 0.42268422199360994
59.142 37.88422818791946 0.41551795042403217
59.142 37.88523489932886 0.3971812616588199
59.142 37.88624161073825 0.38122590081355323
59.142 37.88724832214765 0.35894448599749856
59.142 37.88825503355705 0.33078944949874123
59.142 37.88926174496645 0.2998435502118703
59.142 37.89026845637584 0.2710280454705579
59.142 37.89127516778524 0.2486191114205108
59.142 37.892281879194634 0.2345455261717625
59.142 37.89328859060403 0.22882940461653284
59.142 37.894295302013425 0.23094838369168225
59.142 37.89530201342282 0.23380638608747045
59.142 37.89630872483222 0.25101771042981275
59.142 37.89731543624161 0.2751474434749351
59.142 37.89832214765101 0.3039891304542601
59.142 37.899328859060404 0.33382299284190575
59.142 37.9003355704698 0.36080841702829114
59.142 37.901342281879195 0.38258565220990914
59.142 37.90234899328859 0.3987101531860674
59.142 37.90335570469799 0.4099886678399207
59.142 37.90436241610738 0.4176294172988173
59.142 37.90536912751678 0.4227346703754955
59.142 37.906375838926174 0.42613390923812683
59.142 37.90738255033557 0.42839238798803675
59.142 37.908389261744965 0.42987363200182027
59.142 37.90939597315436 0.4308017384652227
59.142 37.910402684563756 0.43130784473643313
59.142 37.91140939597315 0.4314602020198869
59.142 37.91241610738255 0.4312811481921364
59.142 37.91342281879195 0.42128436678462966
59.142 37.914429530201346 0.42021211633329847
59.142 37.91543624161074 0.41862810890635976
59.142 37.91644295302014 0.41636175517686924
59.142 37.91744966442953 0.41315327111791444
59.142 37.91845637583893 0.40862357016141904
59.142 37.919463087248324 0.40224551499826044
59.142 37.92046979865772 0.39333580238625176
59.142 37.921476510067116 0.381105706828047
59.142 37.92248322147651 0.36482542675805074
59.142 37.92348993288591 0.34413804921600244
59.142 37.9244966442953 0.3194558233804823
59.142 37.9255033557047 0.2921967984152948
59.142 37.926510067114094 0.2645620760206322
59.142 37.92751677852349 0.23883732664872712
59.142 37.928523489932886 0.21663997377454025
59.142 37.92953020134228 0.1985878254387226
59.142 37.93053691275168 0.18446235895477567
59.142 37.93154362416107 0.17359449605454014
59.142 37.93255033557047 0.16520301673682042
59.142 37.933557046979864 0.15858682452059164
59.142 37.93456375838926 0.15319398992617772
59.142 37.935570469798655 0.14862211923327734
59.142 37.93657718120805 0.14459182315704944
59.142 37.937583892617454 0.1409155306030795
59.142 37.93859060402685 0.13747055311447431
59.142 37.939597315436245 0.134178558116118
59.142 37.94060402684564 0.1309909056782363
59.142 37.94161073825504 0.12787855053093602
59.142 37.94261744966443 0.12482523495524345
59.142 37.94362416107383 0.1218229554070831
59.142 37.94463087248322 0.1188689612892722
59.142 37.94563758389262 0.11596377045987749
59.142 37.946644295302015 0.11310985306552544
59.142 37.94765100671141 0.1103107521854061
59.142 37.948657718120806 0.10757048919956229
59.142 37.9496644295302 0.10489315484865247
59.142 37.9506711409396 0.10228262205502991
59.142 37.95167785234899 0.09974233970768585
59.142 37.95268456375839 0.09727518188689761
59.142 37.953691275167785 0.09488333720432406
59.142 37.95469798657718 0.0925682299879648
59.142 37.955704697986576 0.09033047039120766
59.142 37.95671140939597 0.08816983550157297
59.142 37.95771812080537 0.08608528996709348
59.142 37.95872483221476 0.08407506583987544
59.142 37.95973154362416 0.0821368443713366
59.142 37.960738255033554 0.08026813377797645
59.142 37.96174496644295 0.07846705528616817
59.142 37.96275167785235 0.06803597982642227
59.142 37.96375838926175 0.0664834859794789
59.142 37.964765100671144 0.06502556447472714
59.142 37.96577181208054 0.06371715132948332
59.142 37.966778523489936 0.059366842087100025
59.142 37.96778523489933 0.0589522528162154
59.142 37.96879194630873 0.05979470851057185
59.142 37.96979865771812 0.06311454639694349
59.142 37.97080536912752 0.07034600640642873
59.142 37.971812080536914 0.07834379621636726
59.142 37.97281879194631 0.08920930086636585
59.142 37.973825503355705 0.09629050434634143
59.142 37.9748322147651 0.09928214435145044
59.142 37.9758389261745 0.0997335493110039
59.142 37.97684563758389 0.09895782603540568
59.142 37.97785234899329 0.09765071797507818
59.142 37.978859060402684 0.09611971704190203
59.142 37.97986577181208 0.09448934057370238
59.142 37.980872483221475 0.09280710337263923
59.142 37.98187919463087 0.09109008662942214
59.142 37.982885906040266 0.0893440802763016
59.142 37.98389261744966 0.08757113694697034
59.142 37.98489932885906 0.08577241761969576
59.142 37.98590604026845 0.08530593670642347
59.142 37.98691275167785 0.08341525034379295
59.142 37.98791946308725 0.08150439753223503
59.142 37.98892617449665 0.07957563080951899
59.142 37.98993288590604 0.07763140141926342
59.142 37.99093959731544 0.07567427463961793
59.142 37.991946308724835 0.07370685819412817
59.142 37.99295302013423 0.07173174428156283
59.142 37.993959731543626 0.06975146388309517
59.142 37.99496644295302 0.0642355218595596
59.142 37.99597315436242 0.062255623875988396
59.142 37.99697986577181 0.060277870318699196
59.142 37.99798657718121 0.05830427951514265
59.142 37.998993288590604 0.05633670628004286
59.143 37.85 0.5097593661676764
59.143 37.8510067114094 0.50815528391125
59.143 37.85201342281879 0.5064983881109809
59.143 37.85302013422819 0.5047884401960144
59.143 37.854026845637584 0.5030254505602513
59.143 37.85503355704698 0.5012097117851846
59.143 37.856040268456375 0.4864309768979399
59.143 37.85704697986577 0.48439427856057965
59.143 37.85805369127517 0.48230560948650536
59.143 37.85906040268456 0.48016654263320396
59.143 37.86006711409396 0.4779790925620257
59.143 37.861073825503354 0.4757457498885471
59.143 37.86208053691275 0.4734695134456093
59.143 37.86308724832215 0.47115391876053025
59.143 37.86409395973155 0.4688030610529891
59.143 37.865100671140944 0.4664216103879594
59.143 37.86610738255034 0.46401481573694314
59.143 37.867114093959735 0.46158849326704926
59.143 37.86812080536913 0.4591489917625511
59.143 37.869127516778526 0.4567031239160429
59.143 37.87013422818792 0.4542580449366012
59.143 37.87114093959732 0.45182104708707027
59.143 37.87214765100671 0.44939921610090894
59.143 37.87315436241611 0.44699885540725437
59.143 37.874161073825505 0.4446245135235164
59.143 37.8751677852349 0.44227732595195407
59.143 37.876174496644296 0.43995216622753375
59.143 37.87718120805369 0.4376327260832889
59.143 37.87818791946309 0.4352830089206515
59.143 37.87919463087248 0.4328326797311597
59.143 37.88020134228188 0.43015212903166156
59.143 37.881208053691275 0.42701107290556345
59.143 37.88221476510067 0.4230131472620244
59.143 37.883221476510066 0.419964152145762
59.143 37.88422818791946 0.41196500190810525
59.143 37.88523489932886 0.40002089689933357
59.143 37.88624161073825 0.37498550502186473
59.143 37.88724832214765 0.35104564625202517
59.143 37.88825503355705 0.3217928076425597
59.143 37.88926174496645 0.29103100473428084
59.143 37.89026845637584 0.26396618841843933
59.143 37.89127516778524 0.24465496290321453
59.143 37.892281879194634 0.23480540363261637
59.143 37.89328859060403 0.23456732448595652
59.143 37.894295302013425 0.2436907316242819
59.143 37.89530201342282 0.2549193243569632
59.143 37.89630872483222 0.2804614798336301
59.143 37.89731543624161 0.3104673774406778
59.143 37.89832214765101 0.3405140133489081
59.143 37.899328859060404 0.36665249998655824
59.143 37.9003355704698 0.38692129506715905
59.143 37.901342281879195 0.40136543826892973
59.143 37.90234899328859 0.4111005913755604
59.143 37.90335570469799 0.4174450678253744
59.143 37.90436241610738 0.42149641143900823
59.143 37.90536912751678 0.42403691293626544
59.143 37.906375838926174 0.4255789593161956
59.143 37.90738255033557 0.42644010764978335
59.143 37.908389261744965 0.42680658674542477
59.143 37.90939597315436 0.42677669615273583
59.143 37.910402684563756 0.4263863784888237
59.143 37.91140939597315 0.4256209611225069
59.143 37.91241610738255 0.4244159857017512
59.143 37.91342281879195 0.4226485776089942
59.143 37.914429530201346 0.41062941115441337
59.143 37.91543624161074 0.40689884360752443
59.143 37.91644295302014 0.4016725550805348
59.143 37.91744966442953 0.39436312717431643
59.143 37.91845637583893 0.3842376564983961
59.143 37.919463087248324 0.3705060450856972
59.143 37.92046979865772 0.3525419455085459
59.143 37.921476510067116 0.3302440026107155
59.143 37.92248322147651 0.304405412049402
59.143 37.92348993288591 0.27679975352228814
59.143 37.9244966442953 0.24975151067207985
59.143 37.9255033557047 0.22535429455362516
59.143 37.926510067114094 0.20484372303239023
59.143 37.92751677852349 0.18847456740820653
59.143 37.928523489932886 0.1758075651428379
59.143 37.92953020134228 0.16609859515103037
59.143 37.93053691275168 0.15858273902855774
59.143 37.93154362416107 0.15261286209553654
59.143 37.93255033557047 0.14769610825079163
59.143 37.933557046979864 0.1434802751466484
59.143 37.93456375838926 0.13972388605926872
59.143 37.935570469798655 0.1362661587244952
59.143 37.93657718120805 0.1330025419038861
59.143 37.937583892617454 0.12986660851026527
59.143 37.93859060402685 0.12681733156407288
59.143 37.939597315436245 0.12383040779877151
59.143 37.94060402684564 0.12089244559782707
59.143 37.94161073825504 0.11799711110943964
59.143 37.94261744966443 0.11514258554156541
59.143 37.94362416107383 0.11232988910684855
59.143 37.94463087248322 0.10956177303380778
59.143 37.94563758389262 0.10684198186503181
59.143 37.946644295302015 0.10417475620636388
59.143 37.94765100671141 0.10156449123019304
59.143 37.948657718120806 0.09901549597510546
59.143 37.9496644295302 0.0965318179958672
59.143 37.9506711409396 0.09411711070733064
59.143 37.95167785234899 0.0917745291669325
59.143 37.95268456375839 0.08950664559458528
59.143 37.953691275167785 0.0873153796453686
59.143 37.95469798657718 0.08520194100137368
59.143 37.955704697986576 0.08316678369691656
59.143 37.95671140939597 0.08120957311065041
59.143 37.95771812080537 0.07932916816347572
59.143 37.95872483221476 0.07752362363301425
59.143 37.95973154362416 0.0757902220621967
59.143 37.960738255033554 0.07412555479109383
59.143 37.96174496644295 0.07252569501299574
59.143 37.96275167785235 0.06233128940759988
59.143 37.96375838926175 0.06095501072866842
59.143 37.964765100671144 0.05963409106115265
59.143 37.96577181208054 0.05507287563926115
59.143 37.966778523489936 0.05391419768620259
59.143 37.96778523489933 0.05289790662136831
59.143 37.96879194630873 0.05217493624528781
59.143 37.96979865771812 0.05210075652872395
59.143 37.97080536912752 0.05057763456655706
59.143 37.971812080536914 0.05469716011393007
59.143 37.97281879194631 0.06292783828792517
59.143 37.973825503355705 0.07436360223239198
59.143 37.9748322147651 0.084883924177419
59.143 37.9758389261745 0.09131273581621124
59.143 37.97684563758389 0.09393636075101496
59.143 37.97785234899329 0.09433012432114098
59.143 37.978859060402684 0.09365674526019993
59.143 37.97986577181208 0.0925077383139405
59.143 37.980872483221475 0.0911435923967095
59.143 37.98187919463087 0.0896721174622542
59.143 37.982885906040266 0.08813676269788129
59.143 37.98389261744966 0.08655508444915971
59.143 37.98489932885906 0.08633015477383622
59.143 37.98590604026845 0.0846300964218509
59.143 37.98691275167785 0.08289880148359345
59.143 37.98791946308725 0.08113885009250424
59.143 37.98892617449665 0.07935289689570454
59.143 37.98993288590604 0.07754372853678232
59.143 37.99093959731544 0.07571423638686745
59.143 37.991946308724835 0.07386736438453759
59.143 37.99295302013423 0.0720060547618295
59.143 37.993959731543626 0.07013319971671936
59.143 37.99496644295302 0.06473814548163923
59.143 37.99597315436242 0.06285407258657338
59.143 37.99697986577181 0.06096685708671731
59.143 37.99798657718121 0.059078873083564194
59.143 37.998993288590604 0.05719231909953978
59.144 37.85 0.5096194580115643
59.144 37.8510067114094 0.5079973527170926
59.144 37.85201342281879 0.5063204268659852
59.144 37.85302013422819 0.5045883416152184
59.144 37.854026845637584 0.5028010127168415
59.144 37.85503355704698 0.5009586456975978
59.144 37.856040268456375 0.4859831833735224
59.144 37.85704697986577 0.4839122008076816
59.144 37.85805369127517 0.48178642849220116
59.144 37.85906040268456 0.4796073598322417
59.144 37.86006711409396 0.47737694405182574
59.144 37.861073825503354 0.47509762248856496
59.144 37.86208053691275 0.4727723622962341
59.144 37.86308724832215 0.47040468597680263
59.144 37.86409395973155 0.46799869471446703
59.144 37.865100671140944 0.4655590828341377
59.144 37.86610738255034 0.4630911397049866
59.144 37.867114093959735 0.46060073377768157
59.144 37.86812080536913 0.4580942706900935
59.144 37.869127516778526 0.45557861261550764
59.144 37.87013422818792 0.45306093768724953
59.144 37.87114093959732 0.4505485036312299
59.144 37.87214765100671 0.4480482537587776
59.144 37.87315436241611 0.4455661575740064
59.144 37.874161073825505 0.44310609732657663
59.144 37.8751677852349 0.44066796972476263
59.144 37.876174496644296 0.43824442414664716
59.144 37.87718120805369 0.43581523152914636
59.144 37.87818791946309 0.43333755801561835
59.144 37.87919463087248 0.4307292535568643
59.144 37.88020134228188 0.4278405424817314
59.144 37.881208053691275 0.42440745817376013
59.144 37.88221476510067 0.4199796320291441
59.144 37.883221476510066 0.41382146444290957
59.144 37.88422818791946 0.4073415041942561
59.144 37.88523489932886 0.394041671390094
59.144 37.88624161073825 0.37491348290765253
59.144 37.88724832214765 0.3494565905302159
59.144 37.88825503355705 0.31189023418822676
59.144 37.88926174496645 0.281982213942345
59.144 37.89026845637584 0.2576897951474319
59.144 37.89127516778524 0.24297564375788314
59.144 37.892281879194634 0.23949314331246138
59.144 37.89328859060403 0.2473885702727593
59.144 37.894295302013425 0.2658229146964267
59.144 37.89530201342282 0.2924923164751013
59.144 37.89630872483222 0.3163057350317098
59.144 37.89731543624161 0.34585862330893125
59.144 37.89832214765101 0.3705049132053286
59.144 37.899328859060404 0.3888208328075395
59.144 37.9003355704698 0.4013389882165422
59.144 37.901342281879195 0.40941288968105494
59.144 37.90234899328859 0.41440313261085315
59.144 37.90335570469799 0.41736019972685284
59.144 37.90436241610738 0.4189976211784394
59.144 37.90536912751678 0.41976468850834125
59.144 37.906375838926174 0.41992722177240277
59.144 37.90738255033557 0.4196281784775343
59.144 37.908389261744965 0.4189254553872885
59.144 37.90939597315436 0.41781100549587663
59.144 37.910402684563756 0.41621542958746527
59.144 37.91140939597315 0.4140006969270522
59.144 37.91241610738255 0.41094261224752443
59.144 37.91342281879195 0.4067052722041188
59.144 37.914429530201346 0.4008136380100905
59.144 37.91543624161074 0.38313118687957093
59.144 37.91644295302014 0.37178694298144893
59.144 37.91744966442953 0.3566685774900035
59.144 37.91845637583893 0.3373090766059772
59.144 37.919463087248324 0.3138927865050146
59.144 37.92046979865772 0.2875513106569171
59.144 37.921476510067116 0.26028062776149585
59.144 37.92248322147651 0.23436222280439425
59.144 37.92348993288591 0.2115971407603685
59.144 37.9244966442953 0.19284937546171402
59.144 37.9255033557047 0.17809141478817547
59.144 37.926510067114094 0.16674989412108532
59.144 37.92751677852349 0.15806340507456879
59.144 37.928523489932886 0.1513100776802404
59.144 37.92953020134228 0.1459034765491885
59.144 37.93053691275168 0.14140715856927635
59.144 37.93154362416107 0.137513487158106
59.144 37.93255033557047 0.13401328066160528
59.144 37.933557046979864 0.13076795380019235
59.144 37.93456375838926 0.12768766068985604
59.144 37.935570469798655 0.12471542717787061
59.144 37.93657718120805 0.12181612075315958
59.144 37.937583892617454 0.11896897711228532
59.144 37.93859060402685 0.11616261357783712
59.144 37.939597315436245 0.11339173135693768
59.144 37.94060402684564 0.11065494450513595
59.144 37.94161073825504 0.10795335220439013
59.144 37.94261744966443 0.10528959785195419
59.144 37.94362416107383 0.10266724536196178
59.144 37.94463087248322 0.10009036136655182
59.144 37.94563758389262 0.0975632306018482
59.144 37.946644295302015 0.09509015713414534
59.144 37.94765100671141 0.09267532068840093
59.144 37.948657718120806 0.09032266819480642
59.144 37.9496644295302 0.0880358277720291
59.144 37.9506711409396 0.08581803703457484
59.144 37.95167785234899 0.08367208070683371
59.144 37.95268456375839 0.0816002346106643
59.144 37.953691275167785 0.07960421452918376
59.144 37.95469798657718 0.07768512947452205
59.144 37.955704697986576 0.07584343965519519
59.144 37.95671140939597 0.07407892006071998
59.144 37.95771812080537 0.07239063115652268
59.144 37.95872483221476 0.07077689884976293
59.144 37.95973154362416 0.0692353069274953
59.144 37.960738255033554 0.06776270723176052
59.144 37.96174496644295 0.05763722976349203
59.144 37.96275167785235 0.056396460854087324
59.144 37.96375838926175 0.051914054876683546
59.144 37.964765100671144 0.05079725176791795
59.144 37.96577181208054 0.049726530039738356
59.144 37.966778523489936 0.04870068347301969
59.144 37.96778523489933 0.04772611712498634
59.144 37.96879194630873 0.04682820620401271
59.144 37.96979865771812 0.04322586081021003
59.144 37.97080536912752 0.04281960439830025
59.144 37.971812080536914 0.04316346507625635
59.144 37.97281879194631 0.045146634911670466
59.144 37.973825503355705 0.0502325800281965
59.144 37.9748322147651 0.05953324068320749
59.144 37.9758389261745 0.07135063363528887
59.144 37.97684563758389 0.08130187212217732
59.144 37.97785234899329 0.08697843482574395
59.144 37.978859060402684 0.08918089271995161
59.144 37.97986577181208 0.08945605045080599
59.144 37.980872483221475 0.08882043303212667
59.144 37.98187919463087 0.08777165391217252
59.144 37.982885906040266 0.08652693163070005
59.144 37.98389261744966 0.08661019923467923
59.144 37.98489932885906 0.08514763540651524
59.144 37.98590604026845 0.08363441977050409
59.144 37.98691275167785 0.08207830633961533
59.144 37.98791946308725 0.08048390621445684
59.144 37.98892617449665 0.07885475804166225
59.144 37.98993288590604 0.0771941054762779
59.144 37.99093959731544 0.07550515815401801
59.144 37.991946308724835 0.07379115121044136
59.144 37.99295302013423 0.07205533049963166
59.144 37.993959731543626 0.0668033041170446
59.144 37.99496644295302 0.06503672133470606
59.144 37.99597315436242 0.06325810930448039
59.144 37.99697986577181 0.06147037770476629
59.144 37.99798657718121 0.059676273859457986
59.144 37.998993288590604 0.057878365613780035
59.145 37.85 0.5093775236102553
59.145 37.8510067114094 0.5077304603517796
59.145 37.85201342281879 0.5060259060961156
59.145 37.85302013422819 0.5042633634434719
59.145 37.854026845637584 0.5024425922332078
59.145 37.85503355704698 0.5005636469244841
59.145 37.856040268456375 0.48537845199014673
59.145 37.85704697986577 0.4832608725947676
59.145 37.85805369127517 0.4810845643978433
59.145 37.85906040268456 0.4788508632542858
59.145 37.86006711409396 0.47656157455038417
59.145 37.861073825503354 0.4742190121799635
59.145 37.86208053691275 0.4718260346992207
59.145 37.86308724832215 0.4693860768769066
59.145 37.86409395973155 0.4669031743424522
59.145 37.865100671140944 0.4643819782921693
59.145 37.86610738255034 0.4618277560707864
59.145 37.867114093959735 0.4592463715842793
59.145 37.86812080536913 0.4566442363564275
59.145 37.869127516778526 0.45402821660036685
59.145 37.87013422818792 0.4514054721323762
59.145 37.87114093959732 0.4487831861123974
59.145 37.87214765100671 0.44616811481287943
59.145 37.87315436241611 0.4435658339824063
59.145 37.874161073825505 0.44097946558702533
59.145 37.8751677852349 0.43840750591532096
59.145 37.876174496644296 0.43584009266121776
59.145 37.87718120805369 0.43325256222200625
59.145 37.87818791946309 0.4305943348465146
59.145 37.87919463087248 0.42776987066027916
59.145 37.88020134228188 0.4246065897146464
59.145 37.881208053691275 0.42080268438646107
59.145 37.88221476510067 0.4158479915222888
59.145 37.883221476510066 0.4089212097122371
59.145 37.88422818791946 0.40135523976622944
59.145 37.88523489932886 0.38655843211356083
59.145 37.88624161073825 0.36569613780871807
59.145 37.88724832214765 0.3388146572518092
59.145 37.88825503355705 0.30871043290754807
59.145 37.88926174496645 0.2732751355933308
59.145 37.89026845637584 0.25359776607743845
59.145 37.89127516778524 0.2461003974379386
59.145 37.892281879194634 0.252016042486276
59.145 37.89328859060403 0.2701304217051196
59.145 37.894295302013425 0.29679490531269054
59.145 37.89530201342282 0.32642106194719633
59.145 37.89630872483222 0.353461675919454
59.145 37.89731543624161 0.3676790494752916
59.145 37.89832214765101 0.38227295408060347
59.145 37.899328859060404 0.3914682675909603
59.145 37.9003355704698 0.39677115170003674
59.145 37.901342281879195 0.3994797114728439
59.145 37.90234899328859 0.40051656884527737
59.145 37.90335570469799 0.4004734157649175
59.145 37.90436241610738 0.39970374250340546
59.145 37.90536912751678 0.3984019919450771
59.145 37.906375838926174 0.3966564190706625
59.145 37.90738255033557 0.3944787820621831
59.145 37.908389261744965 0.3918165019586507
59.145 37.90939597315436 0.3885517060467834
59.145 37.910402684563756 0.3844902931776235
59.145 37.91140939597315 0.3793443852992896
59.145 37.91241610738255 0.37271456073850034
59.145 37.91342281879195 0.36408561649452265
59.145 37.914429530201346 0.3528616740122569
59.145 37.91543624161074 0.33847775740276176
59.145 37.91644295302014 0.3110921049220073
59.145 37.91744966442953 0.28983335233151447
59.145 37.91845637583893 0.26628568381005874
59.145 37.919463087248324 0.24209290685043725
59.145 37.92046979865772 0.21911898117932177
59.145 37.921476510067116 0.19885028400927973
59.145 37.92248322147651 0.1820251340059166
59.145 37.92348993288591 0.1686501781944313
59.145 37.9244966442953 0.15826487805697062
59.145 37.9255033557047 0.1502321291496584
59.145 37.926510067114094 0.14393308791114434
59.145 37.92751677852349 0.13885554847912007
59.145 37.928523489932886 0.13461239904464983
59.145 37.92953020134228 0.13092715254422055
59.145 37.93053691275168 0.12760958160530123
59.145 37.93154362416107 0.12453225294072995
59.145 37.93255033557047 0.12161161876058034
59.145 37.933557046979864 0.11879403815086811
59.145 37.93456375838926 0.11604592713237702
59.145 37.935570469798655 0.11334701247834593
59.145 37.93657718120805 0.11068579024860148
59.145 37.937583892617454 0.10805649997603244
59.145 37.93859060402685 0.10545712028459436
59.145 37.939597315436245 0.1028880443492593
59.145 37.94060402684564 0.1003512042300826
59.145 37.94161073825504 0.0978494899926721
59.145 37.94261744966443 0.09538636166320173
59.145 37.94362416107383 0.09296558689944474
59.145 37.94463087248322 0.09059106031994685
59.145 37.94563758389262 0.08826667561443702
59.145 37.946644295302015 0.0859962315228678
59.145 37.94765100671141 0.08378335930500258
59.145 37.948657718120806 0.08163146361299203
59.145 37.9496644295302 0.07954367151040037
59.145 37.9506711409396 0.0775227862674261
59.145 37.95167785234899 0.07557124383992286
59.145 37.95268456375839 0.07369107083183778
59.145 37.953691275167785 0.07188384338914637
59.145 37.95469798657718 0.07015064697302273
59.145 37.955704697986576 0.06849203736903613
59.145 37.95671140939597 0.0669080036472923
59.145 37.95771812080537 0.06539793412276229
59.145 37.95872483221476 0.06396058670286076
59.145 37.95973154362416 0.06259406540212673
59.145 37.960738255033554 0.0525155489472397
59.145 37.96174496644295 0.05138868877313871
59.145 37.96275167785235 0.047048804258527976
59.145 37.96375838926175 0.046056731495895176
59.145 37.964765100671144 0.045115479112872045
59.145 37.96577181208054 0.04421971334613871
59.145 37.966778523489936 0.04336423437026579
59.145 37.96778523489933 0.03968745541887189
59.145 37.96879194630873 0.038919456807327335
59.145 37.96979865771812 0.038196092005181204
59.145 37.97080536912752 0.037549174805543664
59.145 37.971812080536914 0.037066289639119676
59.145 37.97281879194631 0.0369659622218967
59.145 37.973825503355705 0.03775347217569219
59.145 37.9748322147651 0.04045659795831086
59.145 37.9758389261745 0.04661181364586858
59.145 37.97684563758389 0.05693137308025706
59.145 37.97785234899329 0.0688757441861314
59.145 37.978859060402684 0.07808347779649748
59.145 37.97986577181208 0.08299568304861105
59.145 37.980872483221475 0.08479520048304198
59.145 37.98187919463087 0.08494740485673763
59.145 37.982885906040266 0.08432930617296745
59.145 37.98389261744966 0.08478077056467134
59.145 37.98489932885906 0.08358785580428889
59.145 37.98590604026845 0.0822957343764234
59.145 37.98691275167785 0.08093633102527945
59.145 37.98791946308725 0.07952399507040697
59.145 37.98892617449665 0.07806615952034412
59.145 37.98993288590604 0.07656763929675717
59.145 37.99093959731544 0.0750323233932497
59.145 37.991946308724835 0.07346381062089247
59.145 37.99295302013423 0.06838370373228775
59.145 37.993959731543626 0.06676233176270033
59.145 37.99496644295302 0.06511858056110563
59.145 37.99597315436242 0.06345582332729903
59.145 37.99697986577181 0.06177731871266211
59.145 37.99798657718121 0.06008617974173963
59.145 37.998993288590604 0.05838534890547631
59.146 37.85 0.5090102616956013
59.146 37.8510067114094 0.5073283816990163
59.146 37.85201342281879 0.5055853609395831
59.146 37.85302013422819 0.5037804588521116
59.146 37.854026845637584 0.5019131910479375
59.146 37.85503355704698 0.4999833694393524
59.146 37.856040268456375 0.4845701328584706
59.146 37.85704697986577 0.4823883339240264
59.146 37.85805369127517 0.48014226253213665
59.146 37.85906040268456 0.4778329951179217
59.146 37.86006711409396 0.47546209360429986
59.146 37.861073825503354 0.4730316487929333
59.146 37.86208053691275 0.4705443208614198
59.146 37.86308724832215 0.46800337493668187
59.146 37.86409395973155 0.46541270911754173
59.146 37.865100671140944 0.4627768714625878
59.146 37.86610738255034 0.46010106114692517
59.146 37.867114093959735 0.4573911068604837
59.146 37.86812080536913 0.4546534119254675
59.146 37.869127516778526 0.45189484938695484
59.146 37.87013422818792 0.44912257940640293
59.146 37.87114093959732 0.4463437419932813
59.146 37.87214765100671 0.4435649439694587
59.146 37.87315436241611 0.4407913987075855
59.146 37.874161073825505 0.4380254708216601
59.146 37.8751677852349 0.43526419155458007
59.146 37.876174496644296 0.4324949868245811
59.146 37.87718120805369 0.42968830661935503
59.146 37.87818791946309 0.4267849269053572
59.146 37.87919463087248 0.4236742610633988
59.146 37.88020134228188 0.42015805393617717
59.146 37.881208053691275 0.415892048783955
59.146 37.88221476510067 0.4102998140314289
59.146 37.883221476510066 0.4024678681155487
59.146 37.88422818791946 0.391082937514845
59.146 37.88523489932886 0.3772084883367743
59.146 37.88624161073825 0.35457567007391994
59.146 37.88724832214765 0.32659922326431234
59.146 37.88825503355705 0.2974478248792873
59.146 37.88926174496645 0.27404833349035523
59.146 37.89026845637584 0.2552878968862896
59.146 37.89127516778524 0.2586767162430029
59.146 37.892281879194634 0.27384825518954514
59.146 37.89328859060403 0.29423465613523564
59.146 37.894295302013425 0.31323914974935974
59.146 37.89530201342282 0.32721231943840795
59.146 37.89630872483222 0.33565478247518676
59.146 37.89731543624161 0.3396965887220053
59.146 37.89832214765101 0.33376899434812207
59.146 37.899328859060404 0.33287624449669917
59.146 37.9003355704698 0.3308739692151689
59.146 37.901342281879195 0.3282172430879513
59.146 37.90234899328859 0.3251679270333069
59.146 37.90335570469799 0.3218697739884896
59.146 37.90436241610738 0.3183957728599285
59.146 37.90536912751678 0.3147752729372214
59.146 37.906375838926174 0.31100802721052534
59.146 37.90738255033557 0.3070699738348325
59.146 37.908389261744965 0.30291361400050426
59.146 37.90939597315436 0.2984646406684027
59.146 37.910402684563756 0.29361602644870627
59.146 37.91140939597315 0.2882211582430236
59.146 37.91241610738255 0.2820890476354376
59.146 37.91342281879195 0.27498740268700106
59.146 37.914429530201346 0.26666306490068986
59.146 37.91543624161074 0.25689162241507174
59.146 37.91644295302014 0.24556285912958725
59.146 37.91744966442953 0.2327879850382383
59.146 37.91845637583893 0.2093038340420456
59.146 37.919463087248324 0.1950163509473104
59.146 37.92046979865772 0.18121255204514164
59.146 37.921476510067116 0.1686175891108983
59.146 37.92248322147651 0.1576773501869509
59.146 37.92348993288591 0.1485117607883627
59.146 37.9244966442953 0.14098742061450337
59.146 37.9255033557047 0.1348358603907281
59.146 37.926510067114094 0.1297546150669964
59.146 37.92751677852349 0.12546775424678858
59.146 37.928523489932886 0.12175070475070585
59.146 37.92953020134228 0.11843314527909037
59.146 37.93053691275168 0.11539181942293675
59.146 37.93154362416107 0.11254045841146414
59.146 37.93255033557047 0.10982024360490104
59.146 37.933557046979864 0.1071920192641363
59.146 37.93456375838926 0.10463039807560018
59.146 37.935570469798655 0.10211949015177814
59.146 37.93657718120805 0.09964988946304117
59.146 37.937583892617454 0.09721658201918551
59.146 37.93859060402685 0.09481750808307275
59.146 37.939597315436245 0.09245257916284572
59.146 37.94060402684564 0.09012300715795371
59.146 37.94161073825504 0.08783084593290043
59.146 37.94261744966443 0.08557867658711074
59.146 37.94362416107383 0.08336938946881046
59.146 37.94463087248322 0.08120603102611353
59.146 37.94563758389262 0.07909169387083002
59.146 37.946644295302015 0.0770294354133265
59.146 37.94765100671141 0.07502221515307836
59.146 37.948657718120806 0.07307284390570778
59.146 37.9496644295302 0.07118394041495603
59.146 37.9506711409396 0.06935789227874689
59.146 37.95167785234899 0.06759681914330429
59.146 37.95268456375839 0.06590253684715647
59.146 37.953691275167785 0.06427652173284296
59.146 37.95469798657718 0.06271987476143168
59.146 37.955704697986576 0.06123328541012238
59.146 37.95671140939597 0.059816995638878864
59.146 37.95771812080537 0.058470764498303796
59.146 37.95872483221476 0.05719383423050885
59.146 37.95973154362416 0.04714296016584986
59.146 37.960738255033554 0.042838087313664744
59.146 37.96174496644295 0.04188331122970547
59.146 37.96275167785235 0.040988296549491265
59.146 37.96375838926175 0.04014935402549924
59.146 37.964765100671144 0.039362200502237904
59.146 37.96577181208054 0.03862200781089738
59.146 37.966778523489936 0.03507618503146771
59.146 37.96778523489933 0.03442931011768843
59.146 37.96879194630873 0.033813629982749574
59.146 37.96979865771812 0.03322556343660539
59.146 37.97080536912752 0.03266598185738623
59.146 37.971812080536914 0.03214717984851949
59.146 37.97281879194631 0.03170965375399934
59.146 37.973825503355705 0.0314619064353773
59.146 37.9748322147651 0.03167057642542573
59.146 37.9758389261745 0.03293960065831046
59.146 37.97684563758389 0.03644179191842051
59.146 37.97785234899329 0.0437187482974503
59.146 37.978859060402684 0.054916298386164616
59.146 37.97986577181208 0.06671198021311381
59.146 37.980872483221475 0.07506460534030727
59.146 37.98187919463087 0.07924955754091319
59.146 37.982885906040266 0.08215040299659526
59.146 37.98389261744966 0.0821498582858473
59.146 37.98489932885906 0.08150109381069412
59.146 37.98590604026845 0.08054875948152788
59.146 37.98691275167785 0.07944076513734802
59.146 37.98791946308725 0.07823946319210065
59.146 37.98892617449665 0.0769717550959884
59.146 37.98993288590604 0.07565024007226234
59.146 37.99093959731544 0.07428188746135023
59.146 37.991946308724835 0.06940508804795116
59.146 37.99295302013423 0.06795952870135828
59.146 37.993959731543626 0.0664801542621461
59.146 37.99496644295302 0.0649706671592464
59.146 37.99597315436242 0.063434703110957
59.146 37.99697986577181 0.061875814554399305
59.146 37.99798657718121 0.060297439872993235
59.146 37.998993288590604 0.05870287207479983
59.147 37.85 0.5084835120378595
59.147 37.8510067114094 0.5067527745111856
59.147 37.85201342281879 0.5049558274907127
59.147 37.85302013422819 0.5030915690422219
59.147 37.854026845637584 0.5011591486045768
59.147 37.85503355704698 0.49915801101614526
59.147 37.856040268456375 0.48349110015707364
59.147 37.85704697986577 0.4812200601656695
59.147 37.85805369127517 0.4788769696588811
59.147 37.85906040268456 0.4764625243255647
59.147 37.86006711409396 0.4739779300876735
59.147 37.861073825503354 0.47142495419352315
59.147 37.86208053691275 0.46880597359018594
59.147 37.86308724832215 0.4661240182118258
59.147 37.86409395973155 0.46338280610927773
59.147 37.865100671140944 0.4605867663387119
59.147 37.86610738255034 0.4577410440025901
59.147 37.867114093959735 0.4548514793820345
59.147 37.86812080536913 0.4519245489782713
59.147 37.869127516778526 0.44896724915824004
59.147 37.87013422818792 0.44598689059745444
59.147 37.87114093959732 0.44299074961568796
59.147 37.87214765100671 0.439985483364155
59.147 37.87315436241611 0.4369761465767
59.147 37.874161073825505 0.4339645254686086
59.147 37.8751677852349 0.43094629013252406
59.147 37.876174496644296 0.4279060943095451
59.147 37.87718120805369 0.42480911363752194
59.147 37.87818791946309 0.4215864511741554
59.147 37.87919463087248 0.4181101618876646
59.147 37.88020134228188 0.41415129312144777
59.147 37.881208053691275 0.4093119995941794
59.147 37.88221476510067 0.40292398522546136
59.147 37.883221476510066 0.3939225634637966
59.147 37.88422818791946 0.38077405085797056
59.147 37.88523489932886 0.3617316100589871
59.147 37.88624161073825 0.33606205525904287
59.147 37.88724832214765 0.3094936079412036
59.147 37.88825503355705 0.2849801581868234
59.147 37.88926174496645 0.27306262545009874
59.147 37.89026845637584 0.2725010882861302
59.147 37.89127516778524 0.27631958517014726
59.147 37.892281879194634 0.27210074647377575
59.147 37.89328859060403 0.2736595391005801
59.147 37.894295302013425 0.27369915045123144
59.147 37.89530201342282 0.27275669322850704
59.147 37.89630872483222 0.27125833697008955
59.147 37.89731543624161 0.2694723336969222
59.147 37.89832214765101 0.26755097602172667
59.147 37.899328859060404 0.25854285261006055
59.147 37.9003355704698 0.2564598423830936
59.147 37.901342281879195 0.25437933815241004
59.147 37.90234899328859 0.25230681831619345
59.147 37.90335570469799 0.2502410519947506
59.147 37.90436241610738 0.24817690073718124
59.147 37.90536912751678 0.24610656019432417
59.147 37.906375838926174 0.2440198427361141
59.147 37.90738255033557 0.24190378101808976
59.147 37.908389261744965 0.23974164134519355
59.147 37.90939597315436 0.23751131654051974
59.147 37.910402684563756 0.23518299383002508
59.147 37.91140939597315 0.23271596412709367
59.147 37.91241610738255 0.2300544874889669
59.147 37.91342281879195 0.227122835027259
59.147 37.914429530201346 0.22382013664313294
59.147 37.91543624161074 0.22001668990345868
59.147 37.91644295302014 0.21555511394567187
59.147 37.91744966442953 0.21026197681298356
59.147 37.91845637583893 0.20397696789307745
59.147 37.919463087248324 0.1869187033503022
59.147 37.92046979865772 0.1783529145212771
59.147 37.921476510067116 0.16895160254105052
59.147 37.92248322147651 0.15912126627555997
59.147 37.92348993288591 0.1493785104949569
59.147 37.9244966442953 0.1402174988415855
59.147 37.9255033557047 0.13198859044219516
59.147 37.926510067114094 0.12484609560384673
59.147 37.92751677852349 0.11877154853887922
59.147 37.928523489932886 0.11363721678354534
59.147 37.92953020134228 0.10927052528295662
59.147 37.93053691275168 0.1054989302110078
59.147 37.93154362416107 0.1021729912522923
59.147 37.93255033557047 0.09917379698822545
59.147 37.933557046979864 0.09641180598061694
59.147 37.93456375838926 0.09382214297855014
59.147 37.935570469798655 0.09135916333894754
59.147 37.93657718120805 0.08899155332263871
59.147 37.937583892617454 0.08669837367421245
59.147 37.93859060402685 0.08446605111684487
59.147 37.939597315436245 0.08228617122259653
59.147 37.94060402684564 0.08015389483512492
59.147 37.94161073825504 0.07806683751626224
59.147 37.94261744966443 0.0760242831214428
59.147 37.94362416107383 0.07402663408435713
59.147 37.94463087248322 0.07207502731287589
59.147 37.94563758389262 0.07017106490574399
59.147 37.946644295302015 0.06831662388891327
59.147 37.94765100671141 0.06651371994584013
59.147 37.948657718120806 0.06476440773592028
59.147 37.9496644295302 0.06307070573071981
59.147 37.9506711409396 0.061434537213581344
59.147 37.95167785234899 0.0598576816717728
59.147 37.95268456375839 0.05834173261322703
59.147 37.953691275167785 0.05688805910846621
59.147 37.95469798657718 0.05549776926738301
59.147 37.955704697986576 0.05417167452978218
59.147 37.95671140939597 0.052910254162659584
59.147 37.95771812080537 0.051713619773371036
59.147 37.95872483221476 0.038414134892735124
59.147 37.95973154362416 0.03747119018501639
59.147 37.960738255033554 0.03659000978177342
59.147 37.96174496644295 0.03576890183699655
59.147 37.96275167785235 0.035005645261437554
59.147 37.96375838926175 0.03429746922190942
59.147 37.964765100671144 0.03364104112651303
59.147 37.96577181208054 0.03019504375729394
59.147 37.966778523489936 0.02964518888080403
59.147 37.96778523489933 0.02913392556059298
59.147 37.96879194630873 0.028655974101343143
59.147 37.96979865771812 0.02820597241033304
59.147 37.97080536912752 0.027779108590369347
59.147 37.971812080536914 0.027372601696351223
59.147 37.97281879194631 0.026989238493635492
59.147 37.973825503355705 0.026645901159959268
59.147 37.9748322147651 0.026394082416669476
59.147 37.9758389261745 0.026368253674648373
59.147 37.97684563758389 0.026893336378496932
59.147 37.97785234899329 0.028686770093382413
59.147 37.978859060402684 0.03306256598314539
59.147 37.97986577181208 0.0414708680950132
59.147 37.980872483221475 0.053341375698531604
59.147 37.98187919463087 0.06622343451152032
59.147 37.982885906040266 0.07362372635937955
59.147 37.98389261744966 0.0770998659410846
59.147 37.98489932885906 0.07819027065710411
59.147 37.98590604026845 0.078103099183723
59.147 37.98691275167785 0.07747047224030679
59.147 37.98791946308725 0.07657722849259425
59.147 37.98892617449665 0.07554489247629964
59.147 37.98993288590604 0.07442496169828228
59.147 37.99093959731544 0.06978887087678681
59.147 37.991946308724835 0.06855265539441231
59.147 37.99295302013423 0.06726937828169248
59.147 37.993959731543626 0.0659438056387189
59.147 37.99496644295302 0.06458006572087573
59.147 37.99597315436242 0.06318205245328781
59.147 37.99697986577181 0.06175355108114367
59.147 37.99798657718121 0.06029825659525717
59.147 37.998993288590604 0.058819754262379495
59.148 37.85 0.5077476865803866
59.148 37.8510067114094 0.5059481234938159
59.148 37.85201342281879 0.5040752642665354
59.148 37.85302013422819 0.5021274878806584
59.148 37.854026845637584 0.5001034180238996
59.148 37.85503355704698 0.49800197338518615
59.148 37.856040268456375 0.48204581075924874
59.148 37.85704697986577 0.4796503855285313
59.148 37.85805369127517 0.47717212418289323
59.148 37.85906040268456 0.47461121981735005
59.148 37.86006711409396 0.4719684229643566
59.148 37.861073825503354 0.4692451061646995
59.148 37.86208053691275 0.4664433262463703
59.148 37.86308724832215 0.4635658814177652
59.148 37.86409395973155 0.460616359399176
59.148 37.865100671140944 0.45759917159993063
59.148 37.86610738255034 0.4545195665511469
59.148 37.867114093959735 0.451383612960716
59.148 37.86812080536913 0.4481981380262501
59.148 37.869127516778526 0.4449705984902382
59.148 37.87013422818792 0.44170884759305923
59.148 37.87114093959732 0.43842073563272244
59.148 37.87214765100671 0.4351134363911593
59.148 37.87315436241611 0.4317923101171446
59.148 37.874161073825505 0.4284589662441066
59.148 37.8751677852349 0.4251079183702412
59.148 37.876174496644296 0.4217207141087578
59.148 37.87718120805369 0.4182554184214361
59.148 37.87818791946309 0.4146272224474262
59.148 37.87919463087248 0.4106711917547939
59.148 37.88020134228188 0.40606680831715203
59.148 37.881208053691275 0.4001773409222549
59.148 37.88221476510067 0.39170901107189227
59.148 37.883221476510066 0.37812434411725127
59.148 37.88422818791946 0.3556009854875446
59.148 37.88523489932886 0.3234029213638661
59.148 37.88624161073825 0.2921489964504894
59.148 37.88724832214765 0.2737810977271429
59.148 37.88825503355705 0.26910783901512114
59.148 37.88926174496645 0.26615100564006183
59.148 37.89026845637584 0.2643633813051315
59.148 37.89127516778524 0.26272937091231735
59.148 37.892281879194634 0.2610462830091068
59.148 37.89328859060403 0.2517451587843265
59.148 37.894295302013425 0.24993053816435237
59.148 37.89530201342282 0.24813133215847383
59.148 37.89630872483222 0.24636097717954977
59.148 37.89731543624161 0.24462418321930535
59.148 37.89832214765101 0.2429204845857797
59.148 37.899328859060404 0.24124662441284603
59.148 37.9003355704698 0.2325211690595494
59.148 37.901342281879195 0.23079960455993703
59.148 37.90234899328859 0.2290917685584151
59.148 37.90335570469799 0.22739236561187853
59.148 37.90436241610738 0.22569622650909205
59.148 37.90536912751678 0.22399827715808518
59.148 37.906375838926174 0.2222934229243639
59.148 37.90738255033557 0.22057634352148447
59.148 37.908389261744965 0.21884117798833036
59.148 37.90939597315436 0.21708106321189505
59.148 37.910402684563756 0.21528747048278085
59.148 37.91140939597315 0.21344926116253
59.148 37.91241610738255 0.21155135449377954
59.148 37.91342281879195 0.20957287067410044
59.148 37.914429530201346 0.20748459003634545
59.148 37.91543624161074 0.2052455781841639
59.148 37.91644295302014 0.202798918260274
59.148 37.91744966442953 0.20006676329557133
59.148 37.91845637583893 0.1969455376709322
59.148 37.919463087248324 0.1933032907242278
59.148 37.92046979865772 0.1889830576626001
59.148 37.921476510067116 0.17398716646373158
59.148 37.92248322147651 0.1676952658032155
59.148 37.92348993288591 0.1603513289382915
59.148 37.9244966442953 0.15203408066518462
59.148 37.9255033557047 0.14300632595972856
59.148 37.926510067114094 0.13369620548935623
59.148 37.92751677852349 0.1246044691950129
59.148 37.928523489932886 0.1161746715509873
59.148 37.92953020134228 0.10869280491710055
59.148 37.93053691275168 0.10225920854854084
59.148 37.93154362416107 0.09682482037575794
59.148 37.93255033557047 0.09225416855499867
59.148 37.933557046979864 0.0883820826433927
59.148 37.93456375838926 0.08505027606345342
59.148 37.935570469798655 0.08212466717963515
59.148 37.93657718120805 0.07949985816123879
59.148 37.937583892617454 0.07709697270363387
59.148 37.93859060402685 0.0748589808101066
59.148 37.939597315436245 0.07274570893536997
59.148 37.94060402684564 0.07072947508288607
59.148 37.94161073825504 0.06879161422108247
59.148 37.94261744966443 0.06691985504923523
59.148 37.94362416107383 0.0651064019843284
59.148 37.94463087248322 0.06334656055394883
59.148 37.94563758389262 0.06163776436093736
59.148 37.946644295302015 0.05997889116940597
59.148 37.94765100671141 0.05836978360340675
59.148 37.948657718120806 0.056810912915423784
59.148 37.9496644295302 0.05530314186989965
59.148 37.9506711409396 0.05384755573281147
59.148 37.95167785234899 0.052445339662570825
59.148 37.95268456375839 0.051097687389580745
59.148 37.953691275167785 0.049805730703218906
59.148 37.95469798657718 0.048570482509221705
59.148 37.955704697986576 0.04739278849583996
59.148 37.95671140939597 0.0339415280383097
59.148 37.95771812080537 0.03300706966188621
59.148 37.95872483221476 0.03213046670562092
59.148 37.95973154362416 0.03131150008471302
59.148 37.960738255033554 0.030549586824680328
59.148 37.96174496644295 0.02984374481734306
59.148 37.96275167785235 0.029192559259486342
59.148 37.96375838926175 0.025751167699696167
59.148 37.964765100671144 0.025218303866256286
59.148 37.96577181208054 0.024733057287021146
59.148 37.966778523489936 0.024292026183927534
59.148 37.96778523489933 0.023891286822002297
59.148 37.96879194630873 0.023526416742135445
59.148 37.96979865771812 0.023192558181036813
59.148 37.97080536912752 0.022884564063843565
59.148 37.971812080536914 0.022597328312286294
59.148 37.97281879194631 0.022326548490649203
59.148 37.973825503355705 0.0220705281954358
59.148 37.9748322147651 0.021834504160140197
59.148 37.9758389261745 0.021641096264534573
59.148 37.97684563758389 0.021555393308111584
59.148 37.97785234899329 0.021743588038418693
59.148 37.978859060402684 0.02260011679499005
59.148 37.97986577181208 0.02654150911089634
59.148 37.980872483221475 0.03181592444622276
59.148 37.98187919463087 0.04127539110985548
59.148 37.982885906040266 0.053525157552697454
59.148 37.98389261744966 0.06424407800001175
59.148 37.98489932885906 0.0707355693928532
59.148 37.98590604026845 0.0736224287939487
59.148 37.98691275167785 0.07445685518865747
59.148 37.98791946308725 0.0743017845653383
59.148 37.98892617449665 0.07368739832102206
59.148 37.98993288590604 0.06941208755704069
59.148 37.99093959731544 0.06845057464936599
59.148 37.991946308724835 0.06740706102609673
59.148 37.99295302013423 0.06630067853092028
59.148 37.993959731543626 0.06514116513754306
59.148 37.99496644295302 0.06393457564688632
59.148 37.99597315436242 0.0626855606807715
59.148 37.99697986577181 0.06139824738700687
59.148 37.99798657718121 0.06007655584104504
59.148 37.998993288590604 0.05872429154426822
59.149 37.85 0.5067314653380096
59.149 37.8510067114094 0.5048348124049817
59.149 37.85201342281879 0.5028549685972797
59.149 37.85302013422819 0.500789596811739
59.149 37.854026845637584 0.49863660238682794
59.149 37.85503355704698 0.4963941942926859
59.149 37.856040268456375 0.4801000230219284
59.149 37.85704697986577 0.4775315843964806
59.149 37.85805369127517 0.47486565946874415
59.149 37.85906040268456 0.4721018648504438
59.149 37.86006711409396 0.4692404701881779
59.149 37.861073825503354 0.4662824854580415
59.149 37.86208053691275 0.46322974632587505
59.149 37.86308724832215 0.46008499370172545
59.149 37.86409395973155 0.4568519424728816
59.149 37.865100671140944 0.45353533289095926
59.149 37.86610738255034 0.450140955942829
59.149 37.867114093959735 0.4466756407147795
59.149 37.86812080536913 0.4431471862384989
59.149 37.869127516778526 0.43956421058233897
59.149 37.87013422818792 0.43593587200422246
59.149 37.87114093959732 0.43227138226901207
59.149 37.87214765100671 0.42857916096982707
59.149 37.87315436241611 0.42486532144412154
59.149 37.874161073825505 0.42113079316995217
59.149 37.8751677852349 0.41736535443852085
59.149 37.876174496644296 0.41353388029378535
59.149 37.87718120805369 0.409541168099452
59.149 37.87818791946309 0.405134573927456
59.149 37.87919463087248 0.3996263154170476
59.149 37.88020134228188 0.39115225008139504
59.149 37.881208053691275 0.3752987684746004
59.149 37.88221476510067 0.34613706685326273
59.149 37.883221476510066 0.30825174085679236
59.149 37.88422818791946 0.2797336730714093
59.149 37.88523489932886 0.2656455537272279
59.149 37.88624161073825 0.25923533986201736
59.149 37.88724832214765 0.2555848839013357
59.149 37.88825503355705 0.25281306340777254
59.149 37.88926174496645 0.25321440567136594
59.149 37.89026845637584 0.25092615453836215
59.149 37.89127516778524 0.2487332336218979
59.149 37.892281879194634 0.24662454431036884
59.149 37.89328859060403 0.24459527155851785
59.149 37.894295302013425 0.24264087883522253
59.149 37.89530201342282 0.233149916034025
59.149 37.89630872483222 0.23125229372665207
59.149 37.89731543624161 0.22941158543672965
59.149 37.89832214765101 0.2276215035964205
59.149 37.899328859060404 0.22587587826060493
59.149 37.9003355704698 0.22416877630022228
59.149 37.901342281879195 0.2153757669691512
59.149 37.90234899328859 0.2136363975881494
59.149 37.90335570469799 0.21191886719402075
59.149 37.90436241610738 0.21021865734933107
59.149 37.90536912751678 0.20853160504079357
59.149 37.906375838926174 0.2068538626362195
59.149 37.90738255033557 0.2051818349220596
59.149 37.908389261744965 0.2035120874736116
59.149 37.90939597315436 0.20184121692039805
59.149 37.910402684563756 0.20016566858372725
59.149 37.91140939597315 0.19848147986940426
59.149 37.91241610738255 0.19678391791277172
59.149 37.91342281879195 0.19506696635228854
59.149 37.914429530201346 0.19332259776620747
59.149 37.91543624161074 0.19153974460439735
59.149 37.91644295302014 0.1897028530719203
59.149 37.91744966442953 0.18778987558643245
59.149 37.91845637583893 0.18576954045999425
59.149 37.919463087248324 0.18359776153279853
59.149 37.92046979865772 0.1812131766167037
59.149 37.921476510067116 0.17853214530307857
59.149 37.92248322147651 0.1754442744705379
59.149 37.92348993288591 0.16183671288031773
59.149 37.9244966442953 0.15735590135206395
59.149 37.9255033557047 0.15200290702644859
59.149 37.926510067114094 0.14564997650626488
59.149 37.92751677852349 0.13826423615621286
59.149 37.928523489932886 0.12996905054484423
59.149 37.92953020134228 0.12107335635413297
59.149 37.93053691275168 0.11203337801915243
59.149 37.93154362416107 0.1033452622384324
59.149 37.93255033557047 0.0954170938504955
59.149 37.933557046979864 0.08848544913280877
59.149 37.93456375838926 0.08260650950365309
59.149 37.935570469798655 0.07770286314815533
59.149 37.93657718120805 0.07362700067626725
59.149 37.937583892617454 0.07021317285688825
59.149 37.93859060402685 0.06730844147506376
59.149 37.939597315436245 0.06478599475531967
59.149 37.94060402684564 0.06254741496683484
59.149 37.94161073825504 0.060519596926078154
59.149 37.94261744966443 0.058649879358877596
59.149 37.94362416107383 0.056901190831685794
59.149 37.94463087248322 0.05524792767845205
59.149 37.94563758389262 0.05367272458504033
59.149 37.946644295302015 0.05216403916062072
59.149 37.94765100671141 0.05071439582742346
59.149 37.948657718120806 0.04931913140407819
59.149 37.9496644295302 0.0479755085700347
59.149 37.9506711409396 0.04668209281935405
59.149 37.95167785234899 0.04543831516650848
59.149 37.95268456375839 0.04424416430628075
59.149 37.953691275167785 0.04309996816192248
59.149 37.95469798657718 0.03932340885444417
59.149 37.955704697986576 0.02859382439703377
59.149 37.95671140939597 0.027729433178864907
59.149 37.95771812080537 0.02691628222458066
59.149 37.95872483221476 0.02615469615658747
59.149 37.95973154362416 0.02544479972656631
59.149 37.960738255033554 0.024786472978570923
59.149 37.96174496644295 0.02417930950325882
59.149 37.96275167785235 0.0207888803385919
59.149 37.96375838926175 0.02029655395870543
59.149 37.964765100671144 0.019852151894054135
59.149 37.96577181208054 0.019453749383174776
59.149 37.966778523489936 0.019098962646354564
59.149 37.96778523489933 0.01878492744399443
59.149 37.96879194630873 0.01850828690708292
59.149 37.96979865771812 0.018265193747219285
59.149 37.97080536912752 0.018051336930241718
59.149 37.971812080536914 0.01786201496803093
59.149 37.97281879194631 0.017692307667792367
59.149 37.973825503355705 0.017537471370198995
59.149 37.9748322147651 0.017393862950383435
59.149 37.9758389261745 0.01726114020510061
59.149 37.97684563758389 0.017147563985449865
59.149 37.97785234899329 0.017082808636801117
59.149 37.978859060402684 0.018751742768453927
59.149 37.97986577181208 0.019107164251740166
59.149 37.980872483221475 0.020274791796039873
59.149 37.98187919463087 0.023237716488396495
59.149 37.982885906040266 0.029520423718626967
59.149 37.98389261744966 0.03999283456295756
59.149 37.98489932885906 0.05239381296336979
59.149 37.98590604026845 0.06232278327631664
59.149 37.98691275167785 0.06793959116362018
59.149 37.98791946308725 0.07031302322206928
59.149 37.98892617449665 0.0675143098955389
59.149 37.98993288590604 0.06730996296141459
59.149 37.99093959731544 0.06671838311866651
59.149 37.991946308724835 0.06593254410114302
59.149 37.99295302013423 0.0650343013533832
59.149 37.993959731543626 0.06405887448592842
59.149 37.99496644295302 0.0630224710832244
59.149 37.99597315436242 0.06193370352435401
59.149 37.99697986577181 0.060798215374431835
59.149 37.99798657718121 0.05962051809990492
59.149 37.998993288590604 0.05840469336426835
59.15 37.85 0.505333301083205
59.15 37.8510067114094 0.5032998867544545
59.15 37.85201342281879 0.5011695885251487
59.15 37.85302013422819 0.4989391334552341
59.15 37.854026845637584 0.49660550846778984
59.15 37.85503355704698 0.4941660410082326
59.15 37.856040268456375 0.49161848902626015
59.15 37.85704697986577 0.4746609485258574
59.15 37.85805369127517 0.471736862798959
59.15 37.85906040268456 0.46869513307010213
59.15 37.86006711409396 0.4655357171762165
59.15 37.861073825503354 0.4622595373023265
59.15 37.86208053691275 0.4588685998167804
59.15 37.86308724832215 0.4553661063677812
59.15 37.86409395973155 0.45175654890828365
59.15 37.865100671140944 0.44804577935683054
59.15 37.86610738255034 0.44424104175388274
59.15 37.867114093959735 0.4403509498089321
59.15 37.86812080536913 0.4363853823450258
59.15 37.869127516778526 0.432355243836708
59.15 37.87013422818792 0.4282719682985473
59.15 37.87114093959732 0.42414644193968515
59.15 37.87214765100671 0.41998639217888567
59.15 37.87315436241611 0.41578928742870824
59.15 37.874161073825505 0.4115213083456541
59.15 37.8751677852349 0.4070520374700829
59.15 37.876174496644296 0.4019496965134016
59.15 37.87718120805369 0.3948709484075717
59.15 37.87818791946309 0.3821122072456732
59.15 37.87919463087248 0.3567927921463731
59.15 37.88020134228188 0.3181775397406323
59.15 37.881208053691275 0.2838177260307299
59.15 37.88221476510067 0.2649297870940444
59.15 37.883221476510066 0.2560177568405597
59.15 37.88422818791946 0.2510348136756044
59.15 37.88523489932886 0.24739687332626792
59.15 37.88624161073825 0.24425002963525314
59.15 37.88724832214765 0.24133442093621993
59.15 37.88825503355705 0.23857231425455178
59.15 37.88926174496645 0.2359389557054851
59.15 37.89026845637584 0.23637271419358152
59.15 37.89127516778524 0.23401932451166035
59.15 37.892281879194634 0.23177192723220327
59.15 37.89328859060403 0.22962385330474117
59.15 37.894295302013425 0.2275681575312988
59.15 37.89530201342282 0.22559774168747776
59.15 37.89630872483222 0.22370549482898883
59.15 37.89731543624161 0.2142501941126489
59.15 37.89832214765101 0.21241770239399216
59.15 37.899328859060404 0.21064256969785924
59.15 37.9003355704698 0.20891863680723521
59.15 37.901342281879195 0.20724012808907025
59.15 37.90234899328859 0.1984433208738123
59.15 37.90335570469799 0.19674731881303012
59.15 37.90436241610738 0.19508099977306811
59.15 37.90536912751678 0.1934402337276682
59.15 37.906375838926174 0.19182127878298502
59.15 37.90738255033557 0.19022075328612373
59.15 37.908389261744965 0.18863559876301494
59.15 37.90939597315436 0.18706303210888528
59.15 37.910402684563756 0.1855004840361429
59.15 37.91140939597315 0.18394551883807406
59.15 37.91241610738255 0.18239572781248908
59.15 37.91342281879195 0.1808485848691972
59.15 37.914429530201346 0.17930124744807552
59.15 37.91543624161074 0.1777502782637112
59.15 37.91644295302014 0.1761912527506067
59.15 37.91744966442953 0.1746182024392096
59.15 37.91845637583893 0.1730228249009849
59.15 37.919463087248324 0.17139336593644613
59.15 37.92046979865772 0.16971305065924178
59.15 37.921476510067116 0.16795791278329328
59.15 37.92248322147651 0.16609386134811693
59.15 37.92348993288591 0.16407286695468087
59.15 37.9244966442953 0.15185373865782775
59.15 37.9255033557047 0.14915542469904008
59.15 37.926510067114094 0.1460241949529038
59.15 37.92751677852349 0.1423145128833579
59.15 37.928523489932886 0.1378617706240056
59.15 37.92953020134228 0.13250642315534614
59.15 37.93053691275168 0.12613872635778492
59.15 37.93154362416107 0.11876030551586464
59.15 37.93255033557047 0.11054111328788235
59.15 37.933557046979864 0.1018340700982282
59.15 37.93456375838926 0.0931171892431848
59.15 37.935570469798655 0.08487480744613275
59.15 37.93657718120805 0.07747555462175666
59.15 37.937583892617454 0.07110734871353239
59.15 37.93859060402685 0.06578602297265959
59.15 37.939597315436245 0.061410012003378145
59.15 37.94060402684564 0.05782271604915083
59.15 37.94161073825504 0.05485921335295045
59.15 37.94261744966443 0.05237220149988467
59.15 37.94362416107383 0.05024184536708458
59.15 37.94463087248322 0.04837622440250304
59.15 37.94563758389262 0.046707544421838054
59.15 37.946644295302015 0.04518716196289223
59.15 37.94765100671141 0.04378088324550902
59.15 37.948657718120806 0.04246507157328683
59.15 37.9496644295302 0.041223640114951374
59.15 37.9506711409396 0.04004582129007743
59.15 37.95167785234899 0.038924553046232296
59.15 37.95268456375839 0.03519038234695812
59.15 37.953691275167785 0.03417548114827712
59.15 37.95469798657718 0.023455653971438256
59.15 37.955704697986576 0.022657225121200247
59.15 37.95671140939597 0.021904522655477904
59.15 37.95771812080537 0.02119750944381929
59.15 37.95872483221476 0.020536282348210824
59.15 37.95973154362416 0.019920961418598226
59.15 37.960738255033554 0.016511872424561022
59.15 37.96174496644295 0.01600334488101536
59.15 37.96275167785235 0.015540521601924692
59.15 37.96375838926175 0.015122898043283955
59.15 37.964765100671144 0.014749682282709647
59.15 37.96577181208054 0.014419752977897299
59.15 37.966778523489936 0.01413162089968066
59.15 37.96778523489933 0.013883394419933637
59.15 37.96879194630873 0.013672750000204376
59.15 37.96979865771812 0.013496909527676917
59.15 37.97080536912752 0.013352627642041995
59.15 37.971812080536914 0.013236194857168412
59.15 37.97281879194631 0.01314346843721527
59.15 37.973825503355705 0.01306995789941895
59.15 37.9748322147651 0.013011028652644904
59.15 37.9758389261745 0.012962377535632189
59.15 37.97684563758389 0.012921156084756816
59.15 37.97785234899329 0.014521597142110793
59.15 37.978859060402684 0.014464248853627501
59.15 37.97986577181208 0.014466538206363885
59.15 37.980872483221475 0.014630474091285567
59.15 37.98187919463087 0.01520693148728787
59.15 37.982885906040266 0.016767568899564378
59.15 37.98389261744966 0.020440396731240554
59.15 37.98489932885906 0.027777221562874266
59.15 37.98590604026845 0.03912479418994792
59.15 37.98691275167785 0.05139959057995651
59.15 37.98791946308725 0.057022389184553504
59.15 37.98892617449665 0.061826521041202044
59.15 37.98993288590604 0.06376206831455355
59.15 37.99093959731544 0.06421524773936443
59.15 37.991946308724835 0.06397402502692022
59.15 37.99295302013423 0.06340519675401451
59.15 37.993959731543626 0.06266716387177945
59.15 37.99496644295302 0.06182710395408184
59.15 37.99597315436242 0.0609142202662394
59.15 37.99697986577181 0.059942304759541584
59.15 37.99798657718121 0.0589190155483183
59.15 37.998993288590604 0.05784962154104904
59.151 37.85 0.5034103872349959
59.151 37.8510067114094 0.5011852602806824
59.151 37.85201342281879 0.4988446238389296
59.151 37.85302013422819 0.4963840789015576
59.151 37.854026845637584 0.4937995554778146
59.151 37.85503355704698 0.4910874275358077
59.151 37.856040268456375 0.4882446415709017
59.151 37.85704697986577 0.47076747890627907
59.151 37.85805369127517 0.4674938204215342
59.151 37.85906040268456 0.46407827874921115
59.151 37.86006711409396 0.4605210721841413
59.151 37.861073825503354 0.45682377964471277
59.151 37.86208053691275 0.452989502635222
59.151 37.86308724832215 0.44902300851978727
59.151 37.86409395973155 0.4449308424976618
59.151 37.865100671140944 0.44072139026981094
59.151 37.86610738255034 0.43640485953866626
59.151 37.867114093959735 0.43199310671804036
59.151 37.86812080536913 0.42749910127567625
59.151 37.869127516778526 0.42293538103560757
59.151 37.87013422818792 0.41830940082532053
59.151 37.87114093959732 0.4136088750986588
59.151 37.87214765100671 0.40875443422197655
59.151 37.87315436241611 0.4034465851983774
59.151 37.874161073825505 0.3966904015062529
59.151 37.8751677852349 0.38553959225951967
59.151 37.876174496644296 0.3635251735490355
59.151 37.87718120805369 0.32640274522667334
59.151 37.87818791946309 0.2881614934180724
59.151 37.87919463087248 0.264792520494489
59.151 37.88020134228188 0.2533943119301032
59.151 37.881208053691275 0.24711568708193177
59.151 37.88221476510067 0.2426410308187018
59.151 37.883221476510066 0.23882758405915724
59.151 37.88422818791946 0.23532085668569266
59.151 37.88523489932886 0.2320140738915938
59.151 37.88624161073825 0.2288741743571377
59.151 37.88724832214765 0.22588893220309597
59.151 37.88825503355705 0.22305131093145636
59.151 37.88926174496645 0.22035500638185854
59.151 37.89026845637584 0.21779331537263172
59.151 37.89127516778524 0.2183823014564611
59.151 37.892281879194634 0.21611842526190284
59.151 37.89328859060403 0.2139670187655009
59.151 37.894295302013425 0.2119200045994027
59.151 37.89530201342282 0.20996934294661168
59.151 37.89630872483222 0.20810715409628927
59.151 37.89731543624161 0.20632581650118284
59.151 37.89832214765101 0.20461804210099502
59.151 37.899328859060404 0.19531919079826865
59.151 37.9003355704698 0.19366321773978612
59.151 37.901342281879195 0.19206091435488182
59.151 37.90234899328859 0.1905067118481647
59.151 37.90335570469799 0.18180004962379642
59.151 37.90436241610738 0.1802346865199782
59.151 37.90536912751678 0.178702547320836
59.151 37.906375838926174 0.17719980996446488
59.151 37.90738255033557 0.17572304065896252
59.151 37.908389261744965 0.174269166251369
59.151 37.90939597315436 0.17283544352305022
59.151 37.910402684563756 0.1714194251704094
59.151 37.91140939597315 0.17001892162818338
59.151 37.91241610738255 0.16863195711698747
59.151 37.91342281879195 0.16725671725667257
59.151 37.914429530201346 0.16589148415125213
59.151 37.91543624161074 0.16453455283121357
59.151 37.91644295302014 0.16318412007101202
59.151 37.91744966442953 0.1618381325106515
59.151 37.91845637583893 0.1604940751898061
59.151 37.919463087248324 0.1591486733689625
59.151 37.92046979865772 0.15779746900157213
59.151 37.921476510067116 0.1564342174464988
59.151 37.92248322147651 0.15505002909401244
59.151 37.92348993288591 0.15363215438867955
59.151 37.9244966442953 0.15216228138095797
59.151 37.9255033557047 0.15061419001949655
59.151 37.926510067114094 0.1388390469736171
59.151 37.92751677852349 0.13686698581793544
59.151 37.928523489932886 0.13465345480027782
59.151 37.92953020134228 0.1321013900359211
59.151 37.93053691275168 0.12908555770566132
59.151 37.93154362416107 0.12545410782343272
59.151 37.93255033557047 0.12104054619387054
59.151 37.933557046979864 0.11569283495783764
59.151 37.93456375838926 0.10932334545631651
59.151 37.935570469798655 0.10197206926672633
59.151 37.93657718120805 0.09385660901921386
59.151 37.937583892617454 0.08537001065571932
59.151 37.93859060402685 0.07700409592842351
59.151 37.939597315436245 0.0692233381213154
59.151 37.94060402684564 0.06235294033848833
59.151 37.94161073825504 0.05653324119632461
59.151 37.94261744966443 0.05174377647849433
59.151 37.94362416107383 0.04786319101260174
59.151 37.94463087248322 0.04472897199324624
59.151 37.94563758389262 0.042178770289435286
59.151 37.946644295302015 0.040071605400183105
59.151 37.94765100671141 0.0382947125670592
59.151 37.948657718120806 0.0367625121119378
59.151 37.9496644295302 0.03276062488837653
59.151 37.9506711409396 0.0315520471696671
59.151 37.95167785234899 0.03044949977020349
59.151 37.95268456375839 0.019495753880604025
59.151 37.953691275167785 0.018669771455983503
59.151 37.95469798657718 0.017902219786844573
59.151 37.955704697986576 0.017186521602553184
59.151 37.95671140939597 0.016518414404612936
59.151 37.95771812080537 0.015895205981057786
59.151 37.95872483221476 0.01246922384978677
59.151 37.95973154362416 0.011946378861972959
59.151 37.960738255033554 0.011465329486629955
59.151 37.96174496644295 0.011025736778929804
59.151 37.96275167785235 0.010627340782059971
59.151 37.96375838926175 0.010269853440531496
59.151 37.964765100671144 0.009952874304565512
59.151 37.96577181208054 0.009675821637766188
59.151 37.966778523489936 0.009437874124548662
59.151 37.96778523489933 0.009237920246080097
59.151 37.96879194630873 0.009074513787634408
59.151 37.96979865771812 0.008945835036875807
59.151 37.97080536912752 0.00884965820218142
59.151 37.971812080536914 0.008783326660469132
59.151 37.97281879194631 0.008743739303727918
59.151 37.973825503355705 0.008727354578574431
59.151 37.9748322147651 0.008730226445140474
59.151 37.9758389261745 0.008748104914139035
59.151 37.97684563758389 0.010437911758992613
59.151 37.97785234899329 0.010427909617198527
59.151 37.978859060402684 0.01042355139677277
59.151 37.97986577181208 0.010428097942217593
59.151 37.980872483221475 0.010457961446105765
59.151 37.98187919463087 0.010561933616063182
59.151 37.982885906040266 0.010866661545424802
59.151 37.98389261744966 0.011678395193180008
59.151 37.98489932885906 0.013678055927773797
59.151 37.98590604026845 0.014741892097759335
59.151 37.98691275167785 0.023154370764464444
59.151 37.98791946308725 0.035190382946026666
59.151 37.98892617449665 0.04708181375941889
59.151 37.98993288590604 0.055162334774150494
59.151 37.99093959731544 0.059228027939533606
59.151 37.991946308724835 0.06079181968998038
59.151 37.99295302013423 0.0611065878612663
59.151 37.993959731543626 0.060839957778691924
59.151 37.99496644295302 0.06029513746289885
59.151 37.99597315436242 0.059602000823879626
59.151 37.99697986577181 0.058815708771527926
59.151 37.99798657718121 0.05796053638899132
59.151 37.998993288590604 0.05704827769516464
59.152 37.85 0.5007651807366965
59.152 37.8510067114094 0.4982737241904173
59.152 37.85201342281879 0.4956421215327825
59.152 37.85302013422819 0.49286480771547253
59.152 37.854026845637584 0.4899367272755505
59.152 37.85503355704698 0.486853506502305
59.152 37.856040268456375 0.48361164387255773
59.152 37.85704697986577 0.46550229798364395
59.152 37.85805369127517 0.4617666062823799
59.152 37.85906040268456 0.457861968403921
59.152 37.86006711409396 0.453790139408144
59.152 37.861073825503354 0.44955486595074023
59.152 37.86208053691275 0.44516207169106214
59.152 37.86308724832215 0.4406199839455357
59.152 37.86409395973155 0.4359391440132734
59.152 37.865100671140944 0.4311321460271908
59.152 37.86610738255034 0.4262126258308655
59.152 37.867114093959735 0.4211919431681132
59.152 37.86812080536913 0.4160684084165384
59.152 37.869127516778526 0.41079201732000103
59.152 37.87013422818792 0.40514920411495586
59.152 37.87114093959732 0.3983973870970837
59.152 37.87214765100671 0.3882350952384863
59.152 37.87315436241611 0.3690130405467483
59.152 37.874161073825505 0.33445880037442166
59.152 37.8751677852349 0.2937320310114939
59.152 37.876174496644296 0.2658265273410146
59.152 37.87718120805369 0.25164710718998795
59.152 37.87818791946309 0.24395067077912388
59.152 37.87919463087248 0.23860366871230332
59.152 37.88020134228188 0.23411098439439443
59.152 37.881208053691275 0.23000286643236464
59.152 37.88221476510067 0.22613880698142855
59.152 37.883221476510066 0.2224771843082712
59.152 37.88422818791946 0.2190044924503596
59.152 37.88523489932886 0.21571421557458897
59.152 37.88624161073825 0.21260072531997354
59.152 37.88724832214765 0.2096576831680084
59.152 37.88825503355705 0.20687778195989734
59.152 37.88926174496645 0.20425287303872128
59.152 37.89026845637584 0.20177418956639734
59.152 37.89127516778524 0.19943257674864648
59.152 37.892281879194634 0.19721870100180652
59.152 37.89328859060403 0.19827372154605474
59.152 37.894295302013425 0.1963399858485103
59.152 37.89530201342282 0.19450738648257843
59.152 37.89630872483222 0.1927673902073855
59.152 37.89731543624161 0.19111189159707037
59.152 37.89832214765101 0.1895332586536671
59.152 37.899328859060404 0.1880243604568343
59.152 37.9003355704698 0.18657857997770183
59.152 37.901342281879195 0.1775130050305449
59.152 37.90234899328859 0.17610136651849403
59.152 37.90335570469799 0.17473556755175224
59.152 37.90436241610738 0.16618087862232336
59.152 37.90536912751678 0.1648010210103971
59.152 37.906375838926174 0.16345355095397152
59.152 37.90738255033557 0.16213508333560314
59.152 37.908389261744965 0.16084259417506647
59.152 37.90939597315436 0.15957339152274386
59.152 37.910402684563756 0.15832508642227117
59.152 37.91140939597315 0.15709556394290325
59.152 37.91241610738255 0.1558829540153725
59.152 37.91342281879195 0.154685601489555
59.152 37.914429530201346 0.1535020344325463
59.152 37.91543624161074 0.15233092915095203
59.152 37.91644295302014 0.1511710696818452
59.152 37.91744966442953 0.15002129845124873
59.152 37.91845637583893 0.14888045330396874
59.152 37.919463087248324 0.14774728395992182
59.152 37.92046979865772 0.14662033786150575
59.152 37.921476510067116 0.145497800940722
59.152 37.92248322147651 0.14437727250364868
59.152 37.92348993288591 0.14325544447627672
59.152 37.9244966442953 0.14212764277620527
59.152 37.9255033557047 0.14098717158011273
59.152 37.926510067114094 0.1398243789782952
59.152 37.92751677852349 0.13862533519635872
59.152 37.928523489932886 0.127124345864155
59.152 37.92953020134228 0.12564280437135683
59.152 37.93053691275168 0.12403416682069734
59.152 37.93154362416107 0.12224141928711738
59.152 37.93255033557047 0.12018635714401328
59.152 37.933557046979864 0.11776485734964481
59.152 37.93456375838926 0.11484437450331535
59.152 37.935570469798655 0.11126755399168448
59.152 37.93657718120805 0.10686794903858421
59.152 37.937583892617454 0.10150435533196632
59.152 37.93859060402685 0.09511550986603233
59.152 37.939597315436245 0.08778318738970631
59.152 37.94060402684564 0.0797725530888258
59.152 37.94161073825504 0.07151160679297958
59.152 37.94261744966443 0.06349746548530148
59.152 37.94362416107383 0.05616734984029398
59.152 37.94463087248322 0.04980033071063937
59.152 37.94563758389262 0.044491197535369315
59.152 37.946644295302015 0.04018749417384174
59.152 37.94765100671141 0.034118277902615554
59.152 37.948657718120806 0.0313898880581743
59.152 37.9496644295302 0.02920620201737044
59.152 37.9506711409396 0.02743305000082387
59.152 37.95167785234899 0.015969857538702018
59.152 37.95268456375839 0.01484999025990659
59.152 37.953691275167785 0.013896568102770762
59.152 37.95469798657718 0.013068428111280103
59.152 37.955704697986576 0.012336755954529252
59.152 37.95671140939597 0.008828998479852829
59.152 37.95771812080537 0.008251620424290201
59.152 37.95872483221476 0.007728138373623128
59.152 37.95973154362416 0.007252449644107928
59.152 37.960738255033554 0.0068205395229603005
59.152 37.96174496644295 0.006429796991561917
59.152 37.96275167785235 0.006078531240908333
59.152 37.96375838926175 0.005765628316046545
59.152 37.964765100671144 0.005490304656565956
59.152 37.96577181208054 0.005251927169955414
59.152 37.966778523489936 0.005049878772009034
59.152 37.96778523489933 0.004883454969295611
59.152 37.96879194630873 0.004751781784686784
59.152 37.96979865771812 0.004653748704019758
59.152 37.97080536912752 0.0045879527710736695
59.152 37.971812080536914 0.004552651801626312
59.152 37.97281879194631 0.004545726209737491
59.152 37.973825503355705 0.004564650487668256
59.152 37.9748322147651 0.006340517182869279
59.152 37.9758389261745 0.006355838093323241
59.152 37.97684563758389 0.006387608080493684
59.152 37.97785234899329 0.006431830698693283
59.152 37.978859060402684 0.006484544765464242
59.152 37.97986577181208 0.006542520962064424
59.152 37.980872483221475 0.0066049330554040775
59.152 37.98187919463087 0.00667739570161642
59.152 37.982885906040266 0.006781744049673591
59.152 37.98389261744966 0.006979559708725672
59.152 37.98489932885906 0.004045728216172079
59.152 37.98590604026845 0.005117072112178822
59.152 37.98691275167785 0.0076145708023964
59.152 37.98791946308725 0.012940000220538803
59.152 37.98892617449665 0.02241087442486081
59.152 37.98993288590604 0.03490268671689189
59.152 37.99093959731544 0.046186140023533065
59.152 37.991946308724835 0.05330945333956088
59.152 37.99295302013423 0.05671955259032983
59.152 37.993959731543626 0.05797244596070007
59.152 37.99496644295302 0.05817696913787908
59.152 37.99597315436242 0.05789431562421154
59.152 37.99697986577181 0.05737437678502744
59.152 37.99798657718121 0.05672355485302774
59.152 37.998993288590604 0.055987180435705844
59.153 37.85 0.4971306706913138
59.153 37.8510067114094 0.49427452788301723
59.153 37.85201342281879 0.4912470426443895
59.153 37.85302013422819 0.4880418285594218
59.153 37.854026845637584 0.4846533978327009
59.153 37.85503355704698 0.48107741969977497
59.153 37.856040268456375 0.4773109980651777
59.153 37.85704697986577 0.473352960794306
59.153 37.85805369127517 0.4541158673342356
59.153 37.85906040268456 0.4495963650347907
59.153 37.86006711409396 0.4448880183289682
59.153 37.861073825503354 0.43999915685493796
59.153 37.86208053691275 0.434940837478118
59.153 37.86308724832215 0.42972621607884404
59.153 37.86409395973155 0.4243681654977606
59.153 37.865100671140944 0.4188712682595545
59.153 37.86610738255034 0.4132053590198848
59.153 37.867114093959735 0.407218571500165
59.153 37.86812080536913 0.40035798388501986
59.153 37.869127516778526 0.3908468394556759
59.153 37.87013422818792 0.3739505411937966
59.153 37.87114093959732 0.34252959251826043
59.153 37.87214765100671 0.3007103420878781
59.153 37.87315436241611 0.26830555523633465
59.153 37.874161073825505 0.25094538058194776
59.153 37.8751677852349 0.241639694816195
59.153 37.876174496644296 0.23536328324591527
59.153 37.87718120805369 0.23017969794159351
59.153 37.87818791946309 0.22546998114066072
59.153 37.87919463087248 0.22104968163584127
59.153 37.88020134228188 0.21686604272929563
59.153 37.881208053691275 0.21290391274822512
59.153 37.88221476510067 0.209157495122681
59.153 37.883221476510066 0.2056221261789432
59.153 37.88422818791946 0.20229208382885555
59.153 37.88523489932886 0.19916020271710477
59.153 37.88624161073825 0.19621801948923728
59.153 37.88724832214765 0.19345605817772416
59.153 37.88825503355705 0.19086413350012774
59.153 37.88926174496645 0.1884316322847302
59.153 37.89026845637584 0.18614775985971999
59.153 37.89127516778524 0.18400174777067388
59.153 37.892281879194634 0.18198302320042614
59.153 37.89328859060403 0.18008134245084445
59.153 37.894295302013425 0.1815143630052931
59.153 37.89530201342282 0.17987116730699224
59.153 37.89630872483222 0.17831794775294488
59.153 37.89731543624161 0.17684654135276448
59.153 37.89832214765101 0.17544937503133914
59.153 37.899328859060404 0.17411946323266075
59.153 37.9003355704698 0.17285039528893742
59.153 37.901342281879195 0.17163631515949485
59.153 37.90234899328859 0.17047189573576882
59.153 37.90335570469799 0.16166084512547504
59.153 37.90436241610738 0.16050821670233623
59.153 37.90536912751678 0.15212941566600427
59.153 37.906375838926174 0.15095339431544177
59.153 37.90738255033557 0.1498059584994013
59.153 37.908389261744965 0.1486842972447067
59.153 37.90939597315436 0.14758591028158813
59.153 37.910402684563756 0.14650857996270897
59.153 37.91140939597315 0.1454503447536772
59.153 37.91241610738255 0.14440947423255807
59.153 37.91342281879195 0.14338444542408474
59.153 37.914429530201346 0.14237392017192801
59.153 37.91543624161074 0.14137672310438565
59.153 37.91644295302014 0.14039181955707797
59.153 37.91744966442953 0.13941829255332594
59.153 37.91845637583893 0.1384553175719253
59.153 37.919463087248324 0.13750213329999478
59.153 37.92046979865772 0.13655800579985053
59.153 37.921476510067116 0.1356221824041086
59.153 37.92248322147651 0.1346938300372042
59.153 37.92348993288591 0.1337719503222548
59.153 37.9244966442953 0.1328552604558209
59.153 37.9255033557047 0.13194202398401453
59.153 37.926510067114094 0.13102980869921022
59.153 37.92751677852349 0.1301151391239287
59.153 37.928523489932886 0.12919299752015637
59.153 37.92953020134228 0.12825610908444124
59.153 37.93053691275168 0.1272939233498905
59.153 37.93154362416107 0.11577280378795449
59.153 37.93255033557047 0.11456451281180771
59.153 37.933557046979864 0.11326091859828263
59.153 37.93456375838926 0.11181755178335737
59.153 37.935570469798655 0.11017245032334202
59.153 37.93657718120805 0.10824118048498485
59.153 37.937583892617454 0.1059124255511697
59.153 37.93859060402685 0.10304658937127438
59.153 37.939597315436245 0.09948185643003817
59.153 37.94060402684564 0.09505408846975999
59.153 37.94161073825504 0.08963653474665009
59.153 37.94261744966443 0.0831985187540408
59.153 37.94362416107383 0.07586634327494032
59.153 37.94463087248322 0.06533132498533173
59.153 37.94563758389262 0.05729375949818523
59.153 37.946644295302015 0.049624034870975844
59.153 37.94765100671141 0.04272570682145371
59.153 37.948657718120806 0.03683032273752462
59.153 37.9496644295302 0.02181203267358621
59.153 37.9506711409396 0.01807109703053232
59.153 37.95167785234899 0.015155118397155179
59.153 37.95268456375839 0.012897735131678717
59.153 37.953691275167785 0.011145716514137635
59.153 37.95469798657718 0.00691231485291792
59.153 37.955704697986576 0.0058328152663727345
59.153 37.95671140939597 0.004959351614450283
59.153 37.95771812080537 0.00423866971808011
59.153 37.95872483221476 0.0036330002890654772
59.153 37.95973154362416 0.0031159582592871077
59.153 37.960738255033554 0.002669330247871901
59.153 37.96174496644295 0.0022806699639659894
59.153 37.96275167785235 0.0019415471391312222
59.153 37.96375838926175 0.0016462932194831609
59.153 37.964765100671144 0.0013911109430506802
59.153 37.96577181208054 0.00117344426320673
59.153 37.966778523489936 0.0009915316286924214
59.153 37.96778523489933 0.0008440870025582803
59.153 37.96879194630873 0.0007300692202388825
59.153 37.96979865771812 0.0006485121983841879
59.153 37.97080536912752 0.0005983970992751719
59.153 37.971812080536914 0.0005785537085514492
59.153 37.97281879194631 0.0005875827026680519
59.153 37.973825503355705 0.0023829054054278903
59.153 37.9748322147651 0.0023983408289363906
59.153 37.9758389261745 0.0024371597872064055
59.153 37.97684563758389 0.0024965605149743914
59.153 37.97785234899329 0.00257335057561027
59.153 37.978859060402684 0.00266399551396021
59.153 37.97986577181208 0.002764760020633375
59.153 37.980872483221475 0.002872058778360942
59.153 37.98187919463087 0.0029833031826914935
59.153 37.982885906040266 0.003098944570142065
59.153 37.98389261744966 -0.00014083322610748683
59.153 37.98489932885906 3.0241930883370682e-05
59.153 37.98590604026845 0.0003200062967754491
59.153 37.98691275167785 0.0009210541679773032
59.153 37.98791946308725 0.0022831235810783918
59.153 37.98892617449665 0.005344852735059871
59.153 37.98993288590604 0.011608289465632207
59.153 37.99093959731544 0.022075936478408373
59.153 37.991946308724835 0.03476351312618708
59.153 37.99295302013423 0.04526488160325549
59.153 37.993959731543626 0.05146339338906949
59.153 37.99496644295302 0.05430174734876008
59.153 37.99597315436242 0.05529725425163764
59.153 37.99697986577181 0.05541512370127832
59.153 37.99798657718121 0.05512408554320911
59.153 37.998993288590604 0.03783320592920228
59.154 37.85 0.4921578506204244
59.154 37.8510067114094 0.4888130549372418
59.154 37.85201342281879 0.4852600596841952
59.154 37.85302013422819 0.4814927717518502
59.154 37.854026845637584 0.4775067215811327
59.154 37.85503355704698 0.4732994276988288
59.154 37.856040268456375 0.4688707651341163
59.154 37.85704697986577 0.4642233145398813
59.154 37.85805369127517 0.44407133452585573
59.154 37.85906040268456 0.438821421279434
59.154 37.86006711409396 0.4333730951567021
59.154 37.861073825503354 0.4277393421805801
59.154 37.86208053691275 0.4219284878454054
59.154 37.86308724832215 0.4159243444208721
59.154 37.86409395973155 0.4096206192432622
59.154 37.865100671140944 0.40260819898951905
59.154 37.86610738255034 0.39352838785705163
59.154 37.867114093959735 0.3785242483459462
59.154 37.86812080536913 0.3504350566916595
59.154 37.869127516778526 0.3089270500251092
59.154 37.87013422818792 0.2723458416095781
59.154 37.87114093959732 0.2513852765898845
59.154 37.87214765100671 0.24022861927838446
59.154 37.87315436241611 0.23295131787305237
59.154 37.874161073825505 0.22706915424376703
59.154 37.8751677852349 0.2217682599961274
59.154 37.876174496644296 0.21680549291119766
59.154 37.87718120805369 0.21211274087361343
59.154 37.87818791946309 0.20767220863259706
59.154 37.87919463087248 0.20347874975911742
59.154 37.88020134228188 0.199528830829065
59.154 37.881208053691275 0.1958175404647685
59.154 37.88221476510067 0.19233801992949157
59.154 37.883221476510066 0.1890816136033556
59.154 37.88422818791946 0.18603821776487517
59.154 37.88523489932886 0.1831966628561753
59.154 37.88624161073825 0.180545074358637
59.154 37.88724832214765 0.17807119308217892
59.154 37.88825503355705 0.17576264860584215
59.154 37.88926174496645 0.17360718519371918
59.154 37.89026845637584 0.17159284238300626
59.154 37.89127516778524 0.16970809405177903
59.154 37.892281879194634 0.16794195063798883
59.154 37.89328859060403 0.16628402953836774
59.154 37.894295302013425 0.1647245987143621
59.154 37.89530201342282 0.16325459828663016
59.154 37.89630872483222 0.16522489317907366
59.154 37.89731543624161 0.16396419813438423
59.154 37.89832214765101 0.1627705843651061
59.154 37.899328859060404 0.16163761845891478
59.154 37.9003355704698 0.160559454098927
59.154 37.901342281879195 0.15953079621186278
59.154 37.90234899328859 0.15854686345961203
59.154 37.90335570469799 0.1576033502701132
59.154 37.90436241610738 0.15669638931539806
59.154 37.90536912751678 0.148120840657314
59.154 37.906375838926174 0.14720423606815508
59.154 37.90738255033557 0.13893067262714634
59.154 37.908389261744965 0.13797252237759858
59.154 37.90939597315436 0.13703568114426679
59.154 37.910402684563756 0.13611820971799604
59.154 37.91140939597315 0.13521839287602422
59.154 37.91241610738255 0.13433471707553013
59.154 37.91342281879195 0.1334658500362238
59.154 37.914429530201346 0.13261062203343085
59.154 37.91543624161074 0.13176800868384808
59.154 37.91644295302014 0.1309371149598168
59.154 37.91744966442953 0.1301171601064346
59.154 37.91845637583893 0.12930746304945734
59.154 37.919463087248324 0.1285074277571072
59.154 37.92046979865772 0.127716527836521
59.154 37.921476510067116 0.12693428937772644
59.154 37.92248322147651 0.12616027066562194
59.154 37.92348993288591 0.12539403680640132
59.154 37.9244966442953 0.12463512647655245
59.154 37.9255033557047 0.12388300678183153
59.154 37.926510067114094 0.12313701044056384
59.154 37.92751677852349 0.12239624693780575
59.154 37.928523489932886 0.12165947559379925
59.154 37.92953020134228 0.12092492317959372
59.154 37.93053691275168 0.12019002115816467
59.154 37.93154362416107 0.11945102701304723
59.154 37.93255033557047 0.11870247948358224
59.154 37.933557046979864 0.10728967568220898
59.154 37.93456375838926 0.10635109935756153
59.154 37.935570469798655 0.10536537769411405
59.154 37.93657718120805 0.10430841326960417
59.154 37.937583892617454 0.10314582621232704
59.154 37.93859060402685 0.10182913876182795
59.154 37.939597315436245 0.10029113653253939
59.154 37.94060402684564 0.09844089318260146
59.154 37.94161073825504 0.09355405712997147
59.154 37.94261744966443 0.09069896430688239
59.154 37.94362416107383 0.08710209867437155
59.154 37.94463087248322 0.08260421938733592
59.154 37.94563758389262 0.07709688627617214
59.154 37.946644295302015 0.07058405859315235
59.154 37.94765100671141 0.06323846385189989
59.154 37.948657718120806 0.04517901705721069
59.154 37.9496644295302 0.03748040291738193
59.154 37.9506711409396 0.030262633597276087
59.154 37.95167785234899 0.023890361905538296
59.154 37.95268456375839 0.01568027989134195
59.154 37.953691275167785 0.011394220859755513
59.154 37.95469798657718 0.008047111007520785
59.154 37.955704697986576 0.005481594179947355
59.154 37.95671140939597 0.0035325424961221707
59.154 37.95771812080537 0.00205264668930083
59.154 37.95872483221476 0.0009220593567802055
59.154 37.95973154362416 4.875254815778218e-05
59.154 37.960738255033554 -0.0006352936725416147
59.154 37.96174496644295 -0.001179060286777861
59.154 37.96275167785235 -0.001617174959225906
59.154 37.96375838926175 -0.001973790298118658
59.154 37.964765100671144 -0.002265581452889756
59.154 37.96577181208054 -0.002503972513380137
59.154 37.966778523489936 -0.0026967509197939193
59.154 37.96778523489933 -0.0028492217877837817
59.154 37.96879194630873 -0.002965027484279334
59.154 37.96979865771812 -0.0030467286898333418
59.154 37.97080536912752 -0.00309621783076533
59.154 37.971812080536914 -0.0012860638322426954
59.154 37.97281879194631 -0.0013219453336705822
59.154 37.973825503355705 -0.0013292417580885174
59.154 37.9748322147651 -0.0013094451061844703
59.154 37.9758389261745 -0.0012642612198835723
59.154 37.97684563758389 -0.0011956783975076296
59.154 37.97785234899329 -0.0011060164587989585
59.154 37.978859060402684 -0.000997954633391876
59.154 37.97986577181208 -0.0008745295658191543
59.154 37.980872483221475 -0.000739079807202378
59.154 37.98187919463087 -0.003951164022027359
59.154 37.982885906040266 -0.0038009083996450727
59.154 37.98389261744966 -0.003646866947826455
59.154 37.98489932885906 -0.0034870780497947007
59.154 37.98590604026845 -0.003309688701168184
59.154 37.98691275167785 -0.003078259575566053
59.154 37.98791946308725 -0.0026967001942134333
59.154 37.98892617449665 -0.0019289753160539602
59.154 37.98993288590604 -0.0002347422247250125
59.154 37.99093959731544 0.0034675897626887803
59.154 37.991946308724835 0.010728780963245324
59.154 37.99295302013423 0.022080296979851075
59.154 37.993959731543626 0.03469374556439475
59.154 37.99496644295302 0.044294103108591734
59.154 37.99597315436242 0.04962753198830905
59.154 37.99697986577181 0.05197468536541471
59.154 37.99798657718121 0.03588743833042508
59.154 37.998993288590604 0.03605279180124381
59.155 37.85 0.4765509490328027
59.155 37.8510067114094 0.4827250852673087
59.155 37.85201342281879 0.4784599483106404
59.155 37.85302013422819 0.4739412646251542
59.155 37.854026845637584 0.4680038511012381
59.155 37.85503355704698 0.4630280606193947
59.155 37.856040268456375 0.45780985166421884
59.155 37.85704697986577 0.4523586639466075
59.155 37.85805369127517 0.4466862820338707
59.155 37.85906040268456 0.4251457265472959
59.155 37.86006711409396 0.4188593987277934
59.155 37.861073825503354 0.4122790392494413
59.155 37.86208053691275 0.4051044604577605
59.155 37.86308724832215 0.3963051260329151
59.155 37.86409395973155 0.38280467548908503
59.155 37.865100671140944 0.3579637510051505
59.155 37.86610738255034 0.3180396029254028
59.155 37.867114093959735 0.27796575327511447
59.155 37.86812080536913 0.2530293511584801
59.155 37.869127516778526 0.23972947150035673
59.155 37.87013422818792 0.23135980233675177
59.155 37.87114093959732 0.22477157527379507
59.155 37.87214765100671 0.2188974390171989
59.155 37.87315436241611 0.21341570789119974
59.155 37.874161073825505 0.20823694492080935
59.155 37.8751677852349 0.20333910275674041
59.155 37.876174496644296 0.19871738285292695
59.155 37.87718120805369 0.1943694491239866
59.155 37.87818791946309 0.19029135305900327
59.155 37.87919463087248 0.18647669446872497
59.155 37.88020134228188 0.18291675329879153
59.155 37.881208053691275 0.17960089753351288
59.155 37.88221476510067 0.17651704670024065
59.155 37.883221476510066 0.1736521166584308
59.155 37.88422818791946 0.17099241879863433
59.155 37.88523489932886 0.16852400403775022
59.155 37.88624161073825 0.1662329495328658
59.155 37.88724832214765 0.16410558998790137
59.155 37.88825503355705 0.16212869770580837
59.155 37.88926174496645 0.16028961681959158
59.155 37.89026845637584 0.15857635772834297
59.155 37.89127516778524 0.1569776578673155
59.155 37.892281879194634 0.15548301470399345
59.155 37.89328859060403 0.15408269639674052
59.155 37.894295302013425 0.15276773497142865
59.155 37.89530201342282 0.15152990623697438
59.155 37.89630872483222 0.15036170002346771
59.155 37.89731543624161 0.15269474141259373
59.155 37.89832214765101 0.15170161250716466
59.155 37.899328859060404 0.15076028489115545
59.155 37.9003355704698 0.14986572341042334
59.155 37.901342281879195 0.14901340015753956
59.155 37.90234899328859 0.14819925209259038
59.155 37.90335570469799 0.14741964042259625
59.155 37.90436241610738 0.1466713121444952
59.155 37.90536912751678 0.1459513640015483
59.155 37.906375838926174 0.14525720898240413
59.155 37.90738255033557 0.1368791698527728
59.155 37.908389261744965 0.12874750538742674
59.155 37.90939597315436 0.12795321360687562
59.155 37.910402684563756 0.12717570709147483
59.155 37.91140939597315 0.12641356545953808
59.155 37.91241610738255 0.12566553548071982
59.155 37.91342281879195 0.12493051372491229
59.155 37.914429530201346 0.12420753094454329
59.155 37.91543624161074 0.12349573801797992
59.155 37.91644295302014 0.12279439327728175
59.155 37.91744966442953 0.12210285103595267
59.155 37.91845637583893 0.121420551119029
59.155 37.919463087248324 0.12074700917517797
59.155 37.92046979865772 0.12008180751371442
59.155 37.921476510067116 0.1194245861512783
59.155 37.92248322147651 0.11877503366392339
59.155 37.92348993288591 0.11813287730637571
59.155 37.9244966442953 0.11749787166044527
59.155 37.9255033557047 0.11686978477970206
59.155 37.926510067114094 0.1162483803645312
59.155 37.92751677852349 0.1156333938679493
59.155 37.928523489932886 0.11502449950836144
59.155 37.92953020134228 0.11442126382105143
59.155 37.93053691275168 0.11382307942946353
59.155 37.93154362416107 0.1132290698964545
59.155 37.93255033557047 0.11263795245389838
59.155 37.933557046979864 0.11204783959553155
59.155 37.93456375838926 0.11145595227174894
59.155 37.935570469798655 0.10008639209411517
59.155 37.93657718120805 0.0993329908845907
59.155 37.937583892617454 0.09855770840561479
59.155 37.93859060402685 0.09515663969392618
59.155 37.939597315436245 0.09429631762349083
59.155 37.94060402684564 0.09335689107193047
59.155 37.94161073825504 0.0923007932888621
59.155 37.94261744966443 0.09107525152872256
59.155 37.94362416107383 0.08960749501897611
59.155 37.94463087248322 0.0877998681757052
59.155 37.94563758389262 0.08552654092806458
59.155 37.946644295302015 0.07221581274024208
59.155 37.94765100671141 0.0686660596866062
59.155 37.948657718120806 0.06417920972165916
59.155 37.9496644295302 0.058669173541764874
59.155 37.9506711409396 0.04930302500848388
59.155 37.95167785234899 0.042065950417908865
59.155 37.95268456375839 0.03446261262610408
59.155 37.953691275167785 0.026984285557154125
59.155 37.95469798657718 0.020092944022863005
59.155 37.955704697986576 0.014110352271239912
59.155 37.95671140939597 0.009173711234231843
59.155 37.95771812080537 0.005260441738208402
59.155 37.95872483221476 0.002248146295617731
59.155 37.95973154362416 -2.59841595900423e-05
59.155 37.960738255033554 -0.001724226907016388
59.155 37.96174496644295 -0.0029873935624893377
59.155 37.96275167785235 -0.003928128583781942
59.155 37.96375838926175 -0.004631997626379433
59.155 37.964765100671144 -0.005161799262565242
59.155 37.96577181208054 -0.005562551356430551
59.155 37.966778523489936 -0.005865998812866091
59.155 37.96778523489933 -0.006094267619161403
59.155 37.96879194630873 -0.0062626565133010875
59.155 37.96979865771812 -0.006381696733166006
59.155 37.97080536912752 -0.004608134591398486
59.155 37.971812080536914 -0.0046942441321437545
59.155 37.97281879194631 -0.004746252124170874
59.155 37.973825503355705 -0.0047668091394869605
59.155 37.9748322147651 -0.0047580233910416575
59.155 37.9758389261745 -0.004721735809929184
59.155 37.97684563758389 -0.004659719831592843
59.155 37.97785234899329 -0.004573827684231424
59.155 37.978859060402684 -0.004466097197470209
59.155 37.97986577181208 -0.004338826801167581
59.155 37.980872483221475 -0.007537802401026236
59.155 37.98187919463087 -0.007378867617703594
59.155 37.982885906040266 -0.007208782767399516
59.155 37.98389261744966 -0.007030759113400398
59.155 37.98489932885906 -0.00684754735187898
59.155 37.98590604026845 -0.006660180257787682
59.155 37.98691275167785 -0.006464910340742314
59.155 37.98791946308725 -0.006245764924519129
59.155 37.98892617449665 -0.005956550579319181
59.155 37.98993288590604 -0.005478172450795724
59.155 37.99093959731544 -0.004522534436771447
59.155 37.991946308724835 -0.0024449579839321695
59.155 37.99295302013423 0.0019805745657025253
59.155 37.993959731543626 0.01027611281587601
59.155 37.99496644295302 0.022346501497706575
59.155 37.99597315436242 0.03462661490822828
59.155 37.99697986577181 0.02631955218318542
59.155 37.99798657718121 0.030978915343565977
59.155 37.998993288590604 0.03302228483915293
59.156 37.85 0.43570965894418273
59.156 37.8510067114094 0.43034818754015847
59.156 37.85201342281879 0.45700535786944757
59.156 37.85302013422819 0.46226369479861584
59.156 37.854026845637584 0.456624871326211
59.156 37.85503355704698 0.45072513180234264
59.156 37.856040268456375 0.4445753346201701
59.156 37.85704697986577 0.4373846576533267
59.156 37.85805369127517 0.43074277105919206
59.156 37.85906040268456 0.40778461662808496
59.156 37.86006711409396 0.3991632553511497
59.156 37.861073825503354 0.38683481415421417
59.156 37.86208053691275 0.3649681015322918
59.156 37.86308724832215 0.32762625551431546
59.156 37.86409395973155 0.2850925839812481
59.156 37.865100671140944 0.2559240279712036
59.156 37.86610738255034 0.24013737473427543
59.156 37.867114093959735 0.23055409587940673
59.156 37.86812080536913 0.2232462358070762
59.156 37.869127516778526 0.2168198810232688
59.156 37.87013422818792 0.21084865922357085
59.156 37.87114093959732 0.20521369214727864
59.156 37.87214765100671 0.1998863528372313
59.156 37.87315436241611 0.19486163160628983
59.156 37.874161073825505 0.19013835625984485
59.156 37.8751677852349 0.18571365344801033
59.156 37.876174496644296 0.18158172306771228
59.156 37.87718120805369 0.17773391588706489
59.156 37.87818791946309 0.1741591906996205
59.156 37.87919463087248 0.17084465777464788
59.156 37.88020134228188 0.16777610930140066
59.156 37.881208053691275 0.16493850027847204
59.156 37.88221476510067 0.16231636604783167
59.156 37.883221476510066 0.15989417259629712
59.156 37.88422818791946 0.15765660098258832
59.156 37.88523489932886 0.15558877031118343
59.156 37.88624161073825 0.15367640544262945
59.156 37.88724832214765 0.1519059565030804
59.156 37.88825503355705 0.150264677485553
59.156 37.88926174496645 0.148740671016943
59.156 37.89026845637584 0.14732290585334265
59.156 37.89127516778524 0.146001212984548
59.156 37.892281879194634 0.1447662654693384
59.156 37.89328859060403 0.14360954635384238
59.156 37.894295302013425 0.14252330828924525
59.156 37.89530201342282 0.141500527790809
59.156 37.89630872483222 0.1405348564818253
59.156 37.89731543624161 0.13962057114826393
59.156 37.89832214765101 0.138752523991947
59.156 37.899328859060404 0.1415011116668764
59.156 37.9003355704698 0.14076941499892476
59.156 37.901342281879195 0.1400723025849206
59.156 37.90234899328859 0.13940648092270536
59.156 37.90335570469799 0.138769013796277
59.156 37.90436241610738 0.13815728683753553
59.156 37.90536912751678 0.137568975019499
59.156 37.906375838926174 0.13700201299215306
59.156 37.90738255033557 0.1364545681320407
59.156 37.908389261744965 0.13592501615081165
59.156 37.90939597315436 0.13541191909264777
59.156 37.910402684563756 0.11960768604308628
59.156 37.91140939597315 0.1189594709541986
59.156 37.91241610738255 0.11832310849154742
59.156 37.91342281879195 0.11769772769192782
59.156 37.914429530201346 0.11708256386641211
59.156 37.91543624161074 0.11647694731398807
59.156 37.91644295302014 0.11588029326588427
59.156 37.91744966442953 0.11529209292247916
59.156 37.91845637583893 0.11471190545006325
59.156 37.919463087248324 0.11413935080735556
59.156 37.92046979865772 0.1135741032704176
59.156 37.921476510067116 0.1130158855174313
59.156 37.92248322147651 0.11246446311960899
59.156 37.92348993288591 0.1119196392578152
59.156 37.9244966442953 0.11138124944103592
59.156 37.9255033557047 0.11084915593564645
59.156 37.926510067114094 0.11032324151295869
59.156 37.92751677852349 0.1098034019711027
59.156 37.928523489932886 0.10928953666386225
59.156 37.92953020134228 0.108781535941105
59.156 37.93053691275168 0.10827926392548834
59.156 37.93154362416107 0.10778253435010218
59.156 37.93255033557047 0.1072910761628392
59.156 37.933557046979864 0.10680448412323848
59.156 37.93456375838926 0.1041656474758822
59.156 37.935570469798655 0.10368887142209612
59.156 37.93657718120805 0.10321426414762667
59.156 37.937583892617454 0.10273977114433455
59.156 37.93859060402685 0.09080153332956689
59.156 37.939597315436245 0.09017104074893999
59.156 37.94060402684564 0.08952532343561274
59.156 37.94161073825504 0.08885476997137787
59.156 37.94261744966443 0.08814541909660586
59.156 37.94362416107383 0.08737711013137434
59.156 37.94463087248322 0.0759173011431741
59.156 37.94563758389262 0.07505981405492562
59.156 37.946644295302015 0.07401500530518167
59.156 37.94765100671141 0.07270426326975336
59.156 37.948657718120806 0.06813907011135337
59.156 37.9496644295302 0.06596897255191016
59.156 37.9506711409396 0.06313789847916447
59.156 37.95167785234899 0.05947958718201131
59.156 37.95268456375839 0.054853675373119776
59.156 37.953691275167785 0.04920052306522168
59.156 37.95469798657718 0.042604078009727445
59.156 37.955704697986576 0.03533149505909556
59.156 37.95671140939597 0.027811591654472313
59.156 37.95771812080537 0.020540537907339534
59.156 37.95872483221476 0.013953155623549152
59.156 37.95973154362416 0.008325669271745509
59.156 37.960738255033554 0.003750544155683627
59.156 37.96174496644295 0.00017394637382184275
59.156 37.96275167785235 -0.0025416732024879513
59.156 37.96375838926175 -0.004561950679684762
59.156 37.964765100671144 -0.006045021898410705
59.156 37.96577181208054 -0.007124827928223693
59.156 37.966778523489936 -0.007906925773161377
59.156 37.96778523489933 -0.008470729552154596
59.156 37.96879194630873 -0.006957262759383395
59.156 37.96979865771812 -0.007288316295688463
59.156 37.97080536912752 -0.0075288901589340845
59.156 37.971812080536914 -0.007699296879099121
59.156 37.97281879194631 -0.007813631617649766
59.156 37.973825503355705 -0.007881676492278153
59.156 37.9748322147651 -0.007910272617344186
59.156 37.9758389261745 -0.0079042988866804
59.156 37.97684563758389 -0.00786736738998492
59.156 37.97785234899329 -0.007802317659628105
59.156 37.978859060402684 -0.01104249004677288
59.156 37.97986577181208 -0.010928058084033548
59.156 37.980872483221475 -0.010792145413945228
59.156 37.98187919463087 -0.010637150483522123
59.156 37.982885906040266 -0.010465685748393252
59.156 37.98389261744966 -0.010280600391907022
59.156 37.98489932885906 -0.010084918070961693
59.156 37.98590604026845 -0.009881604506757569
59.156 37.98691275167785 -0.00967294987876395
59.156 37.98791946308725 -0.009459034930256044
59.156 37.98892617449665 -0.00923397944966492
59.156 37.98993288590604 -0.008976813760998556
59.156 37.99093959731544 -0.008629462995215137
59.156 37.991946308724835 -0.008044935817039425
59.156 37.99295302013423 -0.006873116572452501
59.156 37.993959731543626 -0.004351231679065121
59.156 37.99496644295302 -0.016239338971204484
59.156 37.99597315436242 -0.0067921825528897
59.156 37.99697986577181 0.0058987304765971
59.156 37.99798657718121 0.017728102089986993
59.156 37.998993288590604 0.025494454206566958
59.157000000000004 37.85 0.42315379296262057
59.157000000000004 37.8510067114094 0.4168323528048153
59.157000000000004 37.85201342281879 0.4101728941275501
59.157000000000004 37.85302013422819 0.4364717693375715
59.157000000000004 37.854026845637584 0.4409065545437132
59.157000000000004 37.85503355704698 0.43401362704603075
59.157000000000004 37.856040268456375 0.42665485967201955
59.157000000000004 37.85704697986577 0.4182501193869643
59.157000000000004 37.85805369127517 0.40692809788626
59.157000000000004 37.85906040268456 0.387764635277962
59.157000000000004 37.86006711409396 0.33612181820402104
59.157000000000004 37.861073825503354 0.2923666713145964
59.157000000000004 37.86208053691275 0.260104713355952
59.157000000000004 37.86308724832215 0.24144429051301225
59.157000000000004 37.86409395973155 0.230485595970946
59.157000000000004 37.865100671140944 0.22243125082672421
59.157000000000004 37.86610738255034 0.2154721116876681
59.157000000000004 37.867114093959735 0.2090431285643187
59.157000000000004 37.86812080536913 0.20298513507014823
59.157000000000004 37.869127516778526 0.1972597009063743
59.157000000000004 37.87013422818792 0.19186074132999298
59.157000000000004 37.87114093959732 0.1867881030674584
59.157000000000004 37.87214765100671 0.1820400588493915
59.157000000000004 37.87315436241611 0.17761153987485087
59.157000000000004 37.874161073825505 0.17349410997509151
59.157000000000004 37.8751677852349 0.16967645609281745
59.157000000000004 37.876174496644296 0.16614500680029867
59.157000000000004 37.87718120805369 0.16288454750829354
59.157000000000004 37.87818791946309 0.15987878371514064
59.157000000000004 37.87919463087248 0.15711083334687218
59.157000000000004 37.88020134228188 0.15456364206407958
59.157000000000004 37.881208053691275 0.15222032214888906
59.157000000000004 37.88221476510067 0.1500644195485937
59.157000000000004 37.883221476510066 0.14808011597888346
59.157000000000004 37.88422818791946 0.14625237418600776
59.157000000000004 37.88523489932886 0.1445670348493691
59.157000000000004 37.88624161073825 0.1430108734178222
59.157000000000004 37.88724832214765 0.1415716246094396
59.157000000000004 37.88825503355705 0.14023798151943084
59.157000000000004 37.88926174496645 0.13899957539240537
59.157000000000004 37.89026845637584 0.13784694120680208
59.157000000000004 37.89127516778524 0.13677147334764939
59.157000000000004 37.892281879194634 0.13576537484332238
59.157000000000004 37.89328859060403 0.13482160293189083
59.157000000000004 37.894295302013425 0.13393381310843136
59.157000000000004 37.89530201342282 0.1330963032859969
59.157000000000004 37.89630872483222 0.13230395927349367
59.157000000000004 37.89731543624161 0.1315522024241841
59.157000000000004 37.89832214765101 0.13083694002938356
59.157000000000004 37.899328859060404 0.13015451881283202
59.157000000000004 37.9003355704698 0.12950168171212342
59.157000000000004 37.901342281879195 0.1325904180529987
59.157000000000004 37.90234899328859 0.1320471895697308
59.157000000000004 37.90335570469799 0.1315265830229125
59.157000000000004 37.90436241610738 0.13102657509082205
59.157000000000004 37.90536912751678 0.13054537203440675
59.157000000000004 37.906375838926174 0.13008138505148303
59.157000000000004 37.90738255033557 0.12963320812314935
59.157000000000004 37.908389261744965 0.12919959815018695
59.157000000000004 37.90939597315436 0.1287794571815678
59.157000000000004 37.910402684563756 0.12837181654505897
59.157000000000004 37.91140939597315 0.12797582270006816
59.157000000000004 37.91241610738255 0.11909267432303955
59.157000000000004 37.91342281879195 0.11163635190564944
59.157000000000004 37.914429530201346 0.11110600509794535
59.157000000000004 37.91543624161074 0.11058361423146214
59.157000000000004 37.91644295302014 0.11006873913884666
59.157000000000004 37.91744966442953 0.10956099780138769
59.157000000000004 37.91845637583893 0.10906006022988078
59.157000000000004 37.919463087248324 0.108565643042085
59.157000000000004 37.92046979865772 0.10807750464995003
59.157000000000004 37.921476510067116 0.10759544097408433
59.157000000000004 37.92248322147651 0.10711928160476225
59.157000000000004 37.92348993288591 0.1066488863273077
59.157000000000004 37.9244966442953 0.10618414192353087
59.157000000000004 37.9255033557047 0.10572495914853294
59.157000000000004 37.926510067114094 0.10527126976110353
59.157000000000004 37.92751677852349 0.10482302345212549
59.157000000000004 37.928523489932886 0.10438018446369318
59.157000000000004 37.92953020134228 0.1039427276140632
59.157000000000004 37.93053691275168 0.10135117440120346
59.157000000000004 37.93154362416107 0.10092610836251256
59.157000000000004 37.93255033557047 0.10050656821426875
59.157000000000004 37.933557046979864 0.10009250023554941
59.157000000000004 37.93456375838926 0.09968381049336861
59.157000000000004 37.935570469798655 0.09928033933625603
59.157000000000004 37.93657718120805 0.09888182452246597
59.157000000000004 37.937583892617454 0.09848784775106546
59.157000000000004 37.93859060402685 0.0980977570095244
59.157000000000004 37.939597315436245 0.09771055375548232
59.157000000000004 37.94060402684564 0.09732472907622414
59.157000000000004 37.94161073825504 0.08522756345483959
59.157000000000004 37.94261744966443 0.07390089761772821
59.157000000000004 37.94362416107383 0.0734814801939713
59.157000000000004 37.94463087248322 0.07304332931234787
59.157000000000004 37.94563758389262 0.06966798887085085
59.157000000000004 37.946644295302015 0.06917052068177229
59.157000000000004 37.94765100671141 0.06860640913773747
59.157000000000004 37.948657718120806 0.0679440662117125
59.157000000000004 37.9496644295302 0.06713880846971507
59.157000000000004 37.9506711409396 0.06612840302246005
59.157000000000004 37.95167785234899 0.06482810231534329
59.157000000000004 37.95268456375839 0.06312627101307286
59.157000000000004 37.953691275167785 0.06088306951482392
59.157000000000004 37.95469798657718 0.057936651951154564
59.157000000000004 37.955704697986576 0.05412325392625065
59.157000000000004 37.95671140939597 0.0493170849242917
59.157000000000004 37.95771812080537 0.04348903858494072
59.157000000000004 37.95872483221476 0.0367672737444964
59.157000000000004 37.95973154362416 0.029464596449893292
59.157000000000004 37.960738255033554 0.022037873421147956
59.157000000000004 37.96174496644295 0.014979640953167811
59.157000000000004 37.96275167785235 0.008691299421175247
59.157000000000004 37.96375838926175 0.00340218138277662
59.157000000000004 37.964765100671144 -0.0008372370019556347
59.157000000000004 37.96577181208054 -0.0041078085723608715
59.157000000000004 37.966778523489936 -0.004577061659272352
59.157000000000004 37.96778523489933 -0.006421268257694096
59.157000000000004 37.96879194630873 -0.007764325923783635
59.157000000000004 37.96979865771812 -0.008733105165396132
59.157000000000004 37.97080536912752 -0.00942627461738997
59.157000000000004 37.971812080536914 -0.009917440440350367
59.157000000000004 37.97281879194631 -0.010260010608699507
59.157000000000004 37.973825503355705 -0.01049202492052316
59.157000000000004 37.9748322147651 -0.010640253710635622
59.157000000000004 37.9758389261745 -0.010723412242641187
59.157000000000004 37.97684563758389 -0.01407311712230655
59.157000000000004 37.97785234899329 -0.014061686004127397
59.157000000000004 37.978859060402684 -0.014013701497017444
59.157000000000004 37.97986577181208 -0.013934005576514863
59.157000000000004 37.980872483221475 -0.013826358885298373
59.157000000000004 37.98187919463087 -0.013693893307808638
59.157000000000004 37.982885906040266 -0.01353943108604616
59.157000000000004 37.98389261744966 -0.013365705426838112
59.157000000000004 37.98489932885906 -0.013175501288348157
59.157000000000004 37.98590604026845 -0.012971715845222123
59.157000000000004 37.98691275167785 -0.012757305959338341
59.157000000000004 37.98791946308725 -0.012535021503086982
59.157000000000004 37.98892617449665 -0.012306662497230988
59.157000000000004 37.98993288590604 -0.01207120859347928
59.157000000000004 37.99093959731544 -0.0118202234904176
59.157000000000004 37.991946308724835 -0.011526666737748367
59.157000000000004 37.99295302013423 -0.011117994550437355
59.157000000000004 37.993959731543626 -0.027591814967684744
59.157000000000004 37.99496644295302 -0.026055405259587397
59.157000000000004 37.99597315436242 -0.022907127971216745
59.157000000000004 37.99697986577181 -0.016673831442050896
59.157000000000004 37.99798657718121 -0.006233781528310389
59.157000000000004 37.998993288590604 0.006714557767791257
59.158 37.85 0.4097198787666757
59.158 37.8510067114094 0.4022035688009036
59.158 37.85201342281879 0.39232642803533313
59.158 37.85302013422819 0.38413280670308686
59.158 37.854026845637584 0.40933262148732374
59.158 37.85503355704698 0.3984700430849985
59.158 37.856040268456375 0.39317412810253405
59.158 37.85704697986577 0.3626606140864091
59.158 37.85805369127517 0.319263594377377
59.158 37.85906040268456 0.28186971865920696
59.158 37.86006711409396 0.24238521859158324
59.158 37.861073825503354 0.22980025679980826
59.158 37.86208053691275 0.22091646166328982
59.158 37.86308724832215 0.21340591954212643
59.158 37.86409395973155 0.20652121246282415
59.158 37.865100671140944 0.20004844397648341
59.158 37.86610738255034 0.19393484918855927
59.158 37.867114093959735 0.1896384187624654
59.158 37.86812080536913 0.18424340535847647
59.158 37.869127516778526 0.17919618858321298
59.158 37.87013422818792 0.17449255037531228
59.158 37.87114093959732 0.17012441472452436
59.158 37.87214765100671 0.16608034881894304
59.158 37.87315436241611 0.16234624817737525
59.158 37.874161073825505 0.15890603329739197
59.158 37.8751677852349 0.15574229406359646
59.158 37.876174496644296 0.1528368566882023
59.158 37.87718120805369 0.15017126430269565
59.158 37.87818791946309 0.14772717079940123
59.158 37.87919463087248 0.14548665250193943
59.158 37.88020134228188 0.14343244519675089
59.158 37.881208053691275 0.14154811562147276
59.158 37.88221476510067 0.1398181770683197
59.158 37.883221476510066 0.1382281586189342
59.158 37.88422818791946 0.13676463691960095
59.158 37.88523489932886 0.13541523852040704
59.158 37.88624161073825 0.13416861978329642
59.158 37.88724832214765 0.13301443031488916
59.158 37.88825503355705 0.13194326486913482
59.158 37.88926174496645 0.13094660773591182
59.158 37.89026845637584 0.13001677280683638
59.158 37.89127516778524 0.12914684179723057
59.158 37.892281879194634 0.12833060250198394
59.158 37.89328859060403 0.12756248846649582
59.158 37.894295302013425 0.12683752105052407
59.158 37.89530201342282 0.12615125454144704
59.158 37.89630872483222 0.12549972472196516
59.158 37.89731543624161 0.12487940110380027
59.158 37.89832214765101 0.1242871428935244
59.158 37.899328859060404 0.12372015865001718
59.158 37.9003355704698 0.12317596951705247
59.158 37.901342281879195 0.12265237586287737
59.158 37.90234899328859 0.12214742712583712
59.158 37.90335570469799 0.1255175228208719
59.158 37.90436241610738 0.12510527239538036
59.158 37.90536912751678 0.12470786727973542
59.158 37.906375838926174 0.12432412202458218
59.158 37.90738255033557 0.12395299051790397
59.158 37.908389261744965 0.12359355047449248
59.158 37.90939597315436 0.12324498963768331
59.158 37.910402684563756 0.1229065935195485
59.158 37.91140939597315 0.12257773451984336
59.158 37.91241610738255 0.12225786227774381
59.158 37.91342281879195 0.11343151672327915
59.158 37.914429530201346 0.1130156545275285
59.158 37.91543624161074 0.11260634557289112
59.158 37.91644295302014 0.10521404637918311
59.158 37.91744966442953 0.1047672005686452
59.158 37.91845637583893 0.104326071347776
59.158 37.919463087248324 0.10389045894507114
59.158 37.92046979865772 0.1034601949041488
59.158 37.921476510067116 0.10303513891896993
59.158 37.92248322147651 0.1026151760364519
59.158 37.92348993288591 0.10220021417766331
59.158 37.9244966442953 0.10179018193077201
59.158 37.9255033557047 0.09922251025206419
59.158 37.926510067114094 0.09882312733391639
59.158 37.92751677852349 0.09842878314461243
59.158 37.928523489932886 0.09803946973191999
59.158 37.92953020134228 0.09765519091566846
59.158 37.93053691275168 0.09727596051065317
59.158 37.93154362416107 0.09690180026642019
59.158 37.93255033557047 0.09653273731647885
59.158 37.933557046979864 0.09616880084177078
59.158 37.93456375838926 0.0958100175245228
59.158 37.935570469798655 0.09545640518033739
59.158 37.93657718120805 0.09510796368128649
59.158 37.937583892617454 0.09476466188191893
59.158 37.93859060402685 0.09442641867630867
59.158 37.939597315436245 0.09409307546543491
59.158 37.94060402684564 0.08283484590152462
59.158 37.94161073825504 0.08263861818306978
59.158 37.94261744966443 0.08006940929392051
59.158 37.94362416107383 0.07989062784588961
59.158 37.94463087248322 0.06716040725372102
59.158 37.94563758389262 0.0668328283121239
59.158 37.946644295302015 0.0664975382831987
59.158 37.94765100671141 0.06614910838819425
59.158 37.948657718120806 0.06577958171751805
59.158 37.9496644295302 0.06537736079588521
59.158 37.9506711409396 0.06492563987276459
59.158 37.95167785234899 0.06440023149439164
59.158 37.95268456375839 0.06376662824011008
59.158 37.953691275167785 0.06297618632218233
59.158 37.95469798657718 0.06196149154275234
59.158 37.955704697986576 0.06063140119151081
59.158 37.95671140939597 0.058867143218694046
59.158 37.95771812080537 0.05652238697879902
59.158 37.95872483221476 0.053432285666123354
59.158 37.95973154362416 0.049438104374868605
59.158 37.960738255033554 0.0444323880104296
59.158 37.96174496644295 0.03842048854024213
59.158 37.96275167785235 0.03157656104334474
59.158 37.96375838926175 0.024256359630680416
59.158 37.964765100671144 0.018981854264951317
59.158 37.96577181208054 0.01209786080108817
59.158 37.966778523489936 0.0060593216771739206
59.158 37.96778523489933 0.001048543354549722
59.158 37.96879194630873 -0.002922636289118513
59.158 37.96979865771812 -0.005957892170446913
59.158 37.97080536912752 -0.008214547875586509
59.158 37.971812080536914 -0.009857537368905697
59.158 37.97281879194631 -0.011033978074567866
59.158 37.973825503355705 -0.01186361255853724
59.158 37.9748322147651 -0.01574473864823256
59.158 37.9758389261745 -0.016133672650230454
59.158 37.97684563758389 -0.016384952216416046
59.158 37.97785234899329 -0.016533601943385864
59.158 37.978859060402684 -0.016604301218380024
59.158 37.97986577181208 -0.01661438769284536
59.158 37.980872483221475 -0.01657609195731273
59.158 37.98187919463087 -0.016498161252454777
59.158 37.982885906040266 -0.01638702245368122
59.158 37.98389261744966 -0.016247607577022885
59.158 37.98489932885906 -0.016083935025660176
59.158 37.98590604026845 -0.015899512237515928
59.158 37.98691275167785 -0.01569760000503853
59.158 37.98791946308725 -0.015481350605289379
59.158 37.98892617449665 -0.015253788768875592
59.158 37.98993288590604 -0.015017517610014242
59.158 37.99093959731544 -0.014773831776071278
59.158 37.991946308724835 -0.031859088037253236
59.158 37.99295302013423 -0.03147335772282689
59.158 37.993959731543626 -0.031032637189513088
59.158 37.99496644295302 -0.030446787478180373
59.158 37.99597315436242 -0.029494415648389616
59.158 37.99697986577181 -0.027666227877527177
59.158 37.99798657718121 -0.023928513689197684
59.158 37.998993288590604 -0.016740174346640907
59.159 37.85 0.3897458121488476
59.159 37.8510067114094 0.380585727097025
59.159 37.85201342281879 0.3695462442816757
59.159 37.85302013422819 0.35339124110995856
59.159 37.854026845637584 0.3245999117885954
59.159 37.85503355704698 0.31720114943391814
59.159 37.856040268456375 0.2761294995293446
59.159 37.85704697986577 0.2627561843998044
59.159 37.85805369127517 0.24844181786498626
59.159 37.85906040268456 0.23881237486258358
59.159 37.86006711409396 0.23090648221573534
59.159 37.861073825503354 0.20601098718868893
59.159 37.86208053691275 0.1991811338004174
59.159 37.86308724832215 0.19273313725327357
59.159 37.86409395973155 0.18665439476495432
59.159 37.865100671140944 0.1809455883516369
59.159 37.86610738255034 0.17560705651187736
59.159 37.867114093959735 0.1706352736303323
59.159 37.86812080536913 0.1660224096217498
59.159 37.869127516778526 0.1617568207831347
59.159 37.87013422818792 0.15782379652023648
59.159 37.87114093959732 0.15420633669034564
59.159 37.87214765100671 0.15088587716979318
59.159 37.87315436241611 0.14939944152374293
59.159 37.874161073825505 0.1466216887874328
59.159 37.8751677852349 0.1440815522815717
59.159 37.876174496644296 0.14175975711825223
59.159 37.87718120805369 0.13963770317508167
59.159 37.87818791946309 0.13769766320677748
59.159 37.87919463087248 0.13592291757632227
59.159 37.88020134228188 0.1342978363187254
59.159 37.881208053691275 0.1328079186121559
59.159 37.88221476510067 0.13143979875353698
59.159 37.883221476510066 0.1301812265912074
59.159 37.88422818791946 0.1290210291791611
59.159 37.88523489932886 0.12794905926828296
59.159 37.88624161073825 0.12695613519203003
59.159 37.88724832214765 0.12603397576443084
59.159 37.88825503355705 0.12517513299687014
59.159 37.88926174496645 0.12437292475653973
59.159 37.89026845637584 0.1236213689251519
59.159 37.89127516778524 0.12291512015949398
59.159 37.892281879194634 0.12224940999173013
59.159 37.89328859060403 0.12161999072366371
59.159 37.894295302013425 0.12102308335144482
59.159 37.89530201342282 0.12045532959404955
59.159 37.89630872483222 0.11991374797963349
59.159 37.89731543624161 0.11939569385926091
59.159 37.89832214765101 0.1188988231603232
59.159 37.899328859060404 0.11842105965587067
59.159 37.9003355704698 0.11796056550578309
59.159 37.901342281879195 0.11751571481738302
59.159 37.90234899328859 0.11708506997351471
59.159 37.90335570469799 0.11666736048247925
59.159 37.90436241610738 0.11626146411486188
59.159 37.90536912751678 0.11987113134391635
59.159 37.906375838926174 0.11954798673689372
59.159 37.90738255033557 0.11923483684028129
59.159 37.908389261744965 0.11893101524481955
59.159 37.90939597315436 0.11863593724236302
59.159 37.910402684563756 0.11834909062383703
59.159 37.91140939597315 0.11807002753702449
59.159 37.91241610738255 0.1177983572860971
59.159 37.91342281879195 0.11753373996688801
59.159 37.914429530201346 0.11727588084307478
59.159 37.91543624161074 0.10838409555335782
59.159 37.91644295302014 0.10802584085046874
59.159 37.91744966442953 0.10767252779831475
59.159 37.91845637583893 0.10732399290942748
59.159 37.919463087248324 0.09781313977620067
59.159 37.92046979865772 0.09742519772129729
59.159 37.921476510067116 0.09704193402665914
59.159 37.92248322147651 0.09666327907125324
59.159 37.92348993288591 0.09628918006121934
59.159 37.9244966442953 0.09591959945404831
59.159 37.9255033557047 0.09555451356866496
59.159 37.926510067114094 0.09519391135554828
59.159 37.92751677852349 0.09483779330146787
59.159 37.928523489932886 0.09448617044259136
59.159 37.92953020134228 0.09413906345716483
59.159 37.93053691275168 0.09379650180384369
59.159 37.93154362416107 0.093458522863251
59.159 37.93255033557047 0.09312517102700461
59.159 37.933557046979864 0.09279649665798531
59.159 37.93456375838926 0.09247255481506655
59.159 37.935570469798655 0.09215340359036611
59.159 37.93657718120805 0.09183910184047316
59.159 37.937583892617454 0.08028241755589817
59.159 37.93859060402685 0.08010890149879693
59.159 37.939597315436245 0.07752112359385882
59.159 37.94060402684564 0.07736945636106353
59.159 37.94161073825504 0.07722162984144645
59.159 37.94261744966443 0.07707757226799486
59.159 37.94362416107383 0.07693712313246653
59.159 37.94463087248322 0.07679998948570851
59.159 37.94563758389262 0.07666568211935665
59.159 37.946644295302015 0.07653342249761759
59.159 37.94765100671141 0.07640200723239636
59.159 37.948657718120806 0.06335742994571694
59.159 37.9496644295302 0.06307355109286693
59.159 37.9506711409396 0.06278077428313862
59.159 37.95167785234899 0.06247312461745794
59.159 37.95268456375839 0.062141855749365926
59.159 37.953691275167785 0.0617742362473263
59.159 37.95469798657718 0.06135184528734956
59.159 37.955704697986576 0.060848222500538215
59.159 37.95671140939597 0.0602257173237432
59.159 37.95771812080537 0.05943145236901633
59.159 37.95872483221476 0.05839253918802165
59.159 37.95973154362416 0.057011211476182405
59.159 37.960738255033554 0.055161575882861566
59.159 37.96174496644295 0.05269138177404767
59.159 37.96275167785235 0.05153917554134259
59.159 37.96375838926175 0.047296943793612184
59.159 37.964765100671144 0.04203239021197036
59.159 37.96577181208054 0.035788939746146844
59.159 37.966778523489936 0.02878756336156689
59.159 37.96778523489933 0.021423008762094818
59.159 37.96879194630873 0.014185970299262496
59.159 37.96979865771812 0.007537209043360846
59.159 37.97080536912752 0.0017971721532107077
59.159 37.971812080536914 -0.0028978982722780156
59.159 37.97281879194631 -0.009865308778692988
59.159 37.973825503355705 -0.012640013044985285
59.159 37.9748322147651 -0.014677585372887741
59.159 37.9758389261745 -0.016140137466670756
59.159 37.97684563758389 -0.017168392394233803
59.159 37.97785234899329 -0.017875027029270588
59.159 37.978859060402684 -0.01834577404997796
59.159 37.97986577181208 -0.0186437359265392
59.159 37.980872483221475 -0.018814372170929405
59.159 37.98187919463087 -0.01889001036121614
59.159 37.982885906040266 -0.018893506350668637
59.159 37.98389261744966 -0.018841043965172444
59.159 37.98489932885906 -0.018744202887514022
59.159 37.98590604026845 -0.018611452882710253
59.159 37.98691275167785 -0.018449216738563853
59.159 37.98791946308725 -0.01826261337237549
59.159 37.98892617449665 -0.01805595749589084
59.159 37.98993288590604 -0.017833052074878536
59.159 37.99093959731544 -0.0349748953918855
59.159 37.991946308724835 -0.03462029235318213
59.159 37.99295302013423 -0.03425665370575394
59.159 37.993959731543626 -0.03388025038835345
59.159 37.99496644295302 -0.03347591415115639
59.159 37.99597315436242 -0.03300027006825203
59.159 37.99697986577181 -0.03234179628665196
59.159 37.99798657718121 -0.06740951657427574
59.159 37.998993288590604 -0.06492224158394712
59.160000000000004 37.85 0.3553103035266243
59.160000000000004 37.8510067114094 0.3302288183043912
59.160000000000004 37.85201342281879 0.28959260253461017
59.160000000000004 37.85302013422819 0.24491164677942523
59.160000000000004 37.854026845637584 0.2196303243301054
59.160000000000004 37.85503355704698 0.20215671929010576
59.160000000000004 37.856040268456375 0.191006831778336
59.160000000000004 37.85704697986577 0.21842186398704808
59.160000000000004 37.85805369127517 0.21067638967106847
59.160000000000004 37.85906040268456 0.21631185196173797
59.160000000000004 37.86006711409396 0.20966743674495322
59.160000000000004 37.861073825503354 0.20340663073180695
59.160000000000004 37.86208053691275 0.1797517423944063
59.160000000000004 37.86308724832215 0.17415879333183507
59.160000000000004 37.86409395973155 0.16895117860632958
59.160000000000004 37.865100671140944 0.16412175286507472
59.160000000000004 37.86610738255034 0.1596591090099924
59.160000000000004 37.867114093959735 0.15554836353162518
59.160000000000004 37.86812080536913 0.15177200225896736
59.160000000000004 37.869127516778526 0.148310680358544
59.160000000000004 37.87013422818792 0.14514393469763104
59.160000000000004 37.87114093959732 0.14225079262340962
59.160000000000004 37.87214765100671 0.13961027404715468
59.160000000000004 37.87315436241611 0.1372017910860414
59.160000000000004 37.874161073825505 0.13500545388985113
59.160000000000004 37.8751677852349 0.13300229366884944
59.160000000000004 37.876174496644296 0.13117441491160536
59.160000000000004 37.87718120805369 0.12950508875738195
59.160000000000004 37.87818791946309 0.12797879880069418
59.160000000000004 37.87919463087248 0.1265812495192231
59.160000000000004 37.88020134228188 0.1252993462322812
59.160000000000004 37.881208053691275 0.12412115415944566
59.160000000000004 37.88221476510067 0.12468428450895484
59.160000000000004 37.883221476510066 0.1236904653330307
59.160000000000004 37.88422818791946 0.12277094523375445
59.160000000000004 37.88523489932886 0.1219177860786384
59.160000000000004 37.88624161073825 0.12112387135238797
59.160000000000004 37.88724832214765 0.12038283516746283
59.160000000000004 37.88825503355705 0.11968899405775879
59.160000000000004 37.88926174496645 0.11903728237606095
59.160000000000004 37.89026845637584 0.11842319179979287
59.160000000000004 37.89127516778524 0.11784271520787867
59.160000000000004 37.892281879194634 0.11729229501053234
59.160000000000004 37.89328859060403 0.11676877588208547
59.160000000000004 37.894295302013425 0.11626936175374439
59.160000000000004 37.89530201342282 0.11579157686041322
59.160000000000004 37.89630872483222 0.11533323059634032
59.160000000000004 37.89731543624161 0.11489238591241696
59.160000000000004 37.89832214765101 0.11446733097920822
59.160000000000004 37.899328859060404 0.11405655384060864
59.160000000000004 37.9003355704698 0.11365871979034839
59.160000000000004 37.901342281879195 0.11327265121552374
59.160000000000004 37.90234899328859 0.1128973096662006
59.160000000000004 37.90335570469799 0.1125317799265178
59.160000000000004 37.90436241610738 0.11217525587989594
59.160000000000004 37.90536912751678 0.11182702797826291
59.160000000000004 37.906375838926174 0.11148647214199284
59.160000000000004 37.90738255033557 0.11530773740185057
59.160000000000004 37.908389261744965 0.11504452324381054
59.160000000000004 37.90939597315436 0.11478834858523101
59.160000000000004 37.910402684563756 0.11310183634256821
59.160000000000004 37.91140939597315 0.11285722591859153
59.160000000000004 37.91241610738255 0.11261888738458489
59.160000000000004 37.91342281879195 0.11238658948730383
59.160000000000004 37.914429530201346 0.1121601337827093
59.160000000000004 37.91543624161074 0.11193935103563984
59.160000000000004 37.91644295302014 0.11172409804360789
59.160000000000004 37.91744966442953 0.10231401831646066
59.160000000000004 37.91845637583893 0.10199436360356054
59.160000000000004 37.919463087248324 0.10167882037843223
59.160000000000004 37.92046979865772 0.10136732000808053
59.160000000000004 37.921476510067116 0.10105980905024019
59.160000000000004 37.92248322147651 0.10075624777808395
59.160000000000004 37.92348993288591 0.09312626560718276
59.160000000000004 37.9244966442953 0.09278499883336536
59.160000000000004 37.9255033557047 0.09244770343120517
59.160000000000004 37.926510067114094 0.09211438861898069
59.160000000000004 37.92751677852349 0.09178507228300445
59.160000000000004 37.928523489932886 0.09145978029912441
59.160000000000004 37.92953020134228 0.09113854593462192
59.160000000000004 37.93053691275168 0.09082140931696456
59.160000000000004 37.93154362416107 0.090508416954721
59.160000000000004 37.93255033557047 0.09019962129356379
59.160000000000004 37.933557046979864 0.08989508028600972
59.160000000000004 37.93456375838926 0.08959485694678544
59.160000000000004 37.935570469798655 0.07786242849571248
59.160000000000004 37.93657718120805 0.07524107159884957
59.160000000000004 37.937583892617454 0.0750998669620439
59.160000000000004 37.93859060402685 0.07496211592635835
59.160000000000004 37.939597315436245 0.07482790700868089
59.160000000000004 37.94060402684564 0.07469732610619612
59.160000000000004 37.94161073825504 0.07457045333746949
59.160000000000004 37.94261744966443 0.07444735833095663
59.160000000000004 37.94362416107383 0.07432809323323217
59.160000000000004 37.94463087248322 0.07421268237568705
59.160000000000004 37.94563758389262 0.0741011070531217
59.160000000000004 37.946644295302015 0.07399328316212228
59.160000000000004 37.94765100671141 0.07388902842240916
59.160000000000004 37.948657718120806 0.07378801441815311
59.160000000000004 37.9496644295302 0.07368969654520897
59.160000000000004 37.9506711409396 0.07359321184612738
59.160000000000004 37.95167785234899 0.07349723025497547
59.160000000000004 37.95268456375839 0.06012492037759698
59.160000000000004 37.953691275167785 0.05987290025542462
59.160000000000004 37.95469798657718 0.05961077253468645
59.160000000000004 37.955704697986576 0.059331970040241204
59.160000000000004 37.95671140939597 0.0590268895838686
59.160000000000004 37.95771812080537 0.058681569195315565
59.160000000000004 37.95872483221476 0.05827583780663016
59.160000000000004 37.95973154362416 0.05778077896219909
59.160000000000004 37.960738255033554 0.05931849950505322
59.160000000000004 37.96174496644295 0.058457686791537775
59.160000000000004 37.96275167785235 0.05733103689088501
59.160000000000004 37.96375838926175 0.05583397094777462
59.160000000000004 37.964765100671144 0.053833592317224344
59.160000000000004 37.96577181208054 0.05117249920802489
59.160000000000004 37.966778523489936 0.04768478329776217
59.160000000000004 37.96778523489933 0.043230647426512864
59.160000000000004 37.96879194630873 0.03775117937000371
59.160000000000004 37.96979865771812 0.03133102385314385
59.160000000000004 37.97080536912752 0.020954838289300566
59.160000000000004 37.971812080536914 0.013615534190101475
59.160000000000004 37.97281879194631 0.006527394493646199
59.160000000000004 37.973825503355705 0.00012427750457273679
59.160000000000004 37.9748322147651 -0.005318369549827978
59.160000000000004 37.9758389261745 -0.009708467980144075
59.160000000000004 37.97684563758389 -0.013099890897604813
59.160000000000004 37.97785234899329 -0.015630048861530785
59.160000000000004 37.978859060402684 -0.017464425633907404
59.160000000000004 37.97986577181208 -0.018761093244719335
59.160000000000004 37.980872483221475 -0.019654062927908278
59.160000000000004 37.98187919463087 -0.02024910885875679
59.160000000000004 37.982885906040266 -0.020626010341644144
59.160000000000004 37.98389261744966 -0.020843205192933978
59.160000000000004 37.98489932885906 -0.02094272735459396
59.160000000000004 37.98590604026845 -0.020954522328686512
59.160000000000004 37.98691275167785 -0.020899886086158906
59.160000000000004 37.98791946308725 -0.02079406635445742
59.160000000000004 37.98892617449665 -0.03816376465903207
59.160000000000004 37.98993288590604 -0.037880018140010074
59.160000000000004 37.99093959731544 -0.037571248657679454
59.160000000000004 37.991946308724835 -0.037242678874616936
59.160000000000004 37.99295302013423 -0.03689851376159173
59.160000000000004 37.993959731543626 -0.03654168836708066
59.160000000000004 37.99496644295302 -0.036172667181572304
59.160000000000004 37.99597315436242 -0.03578610838328279
59.160000000000004 37.99697986577181 -0.07180242335760989
59.160000000000004 37.99798657718121 -0.06924776252551071
59.160000000000004 37.998993288590604 -0.068207343928978
59.161 37.85 0.2874138205156285
59.161 37.8510067114094 0.22080592835989116
59.161 37.85201342281879 0.20056121726072243
59.161 37.85302013422819 0.1876530901941555
59.161 37.854026845637584 0.1775328223719299
59.161 37.85503355704698 0.16852513027856952
59.161 37.856040268456375 0.160121056853792
59.161 37.85704697986577 0.15902866384919695
59.161 37.85805369127517 0.18997274446218407
59.161 37.85906040268456 0.18369360516009875
59.161 37.86006711409396 0.17782227636500814
59.161 37.861073825503354 0.18546260753845467
59.161 37.86208053691275 0.18053222283648762
59.161 37.86308724832215 0.15815401245376848
59.161 37.86409395973155 0.15387638988549518
59.161 37.865100671140944 0.14995014043552077
59.161 37.86610738255034 0.14635530794479876
59.161 37.867114093959735 0.14307061348891195
59.161 37.86812080536913 0.14007412618342857
59.161 37.869127516778526 0.1373438190513904
59.161 37.87013422818792 0.13485801347922222
59.161 37.87114093959732 0.13259572101640754
59.161 37.87214765100671 0.1305368941828675
59.161 37.87315436241611 0.12866259921128864
59.161 37.874161073825505 0.1269551237547654
59.161 37.8751677852349 0.12539803191648327
59.161 37.876174496644296 0.12397617781101126
59.161 37.87718120805369 0.12267568747887261
59.161 37.87818791946309 0.1214839175142759
59.161 37.87919463087248 0.1203893973423747
59.161 37.88020134228188 0.11938176076792376
59.161 37.881208053691275 0.118451671249024
59.161 37.88221476510067 0.11759074434190081
59.161 37.883221476510066 0.11679146991527253
59.161 37.88422818791946 0.11604713603549924
59.161 37.88523489932886 0.11535175586059249
59.161 37.88624161073825 0.11469999843525885
59.161 37.88724832214765 0.11408712393255094
59.161 37.88825503355705 0.11350892362324369
59.161 37.88926174496645 0.11296166465699911
59.161 37.89026845637584 0.11244203959621746
59.161 37.89127516778524 0.11194712054293618
59.161 37.892281879194634 0.11147431763163176
59.161 37.89328859060403 0.11102134161897304
59.161 37.894295302013425 0.11058617027864107
59.161 37.89530201342282 0.11016701830068101
59.161 37.89630872483222 0.1097623103964787
59.161 37.89731543624161 0.1093706573190748
59.161 37.89832214765101 0.10899083452203515
59.161 37.899328859060404 0.1086217631966659
59.161 37.9003355704698 0.10826249344547798
59.161 37.901342281879195 0.10791218936868788
59.161 37.90234899328859 0.10757011585948201
59.161 37.90335570469799 0.1072356269220936
59.161 37.90436241610738 0.10690815534430118
59.161 37.90536912751678 0.10658720357255651
59.161 37.906375838926174 0.10627233565326887
59.161 37.90738255033557 0.1059631701179804
59.161 37.908389261744965 0.10565937370320178
59.161 37.90939597315436 0.1053606558074587
59.161 37.910402684563756 0.10986176480564576
59.161 37.91140939597315 0.10964267562681021
59.161 37.91241610738255 0.10942884031282413
59.161 37.91342281879195 0.10922010679480929
59.161 37.914429530201346 0.10901634686020344
59.161 37.91543624161074 0.1088174535686903
59.161 37.91644295302014 0.10862333897373262
59.161 37.91744966442953 0.10843393211292107
59.161 37.91845637583893 0.10824917723469704
59.161 37.919463087248324 0.0987656406662594
59.161 37.92046979865772 0.09847464214304485
59.161 37.921476510067116 0.0981870948581764
59.161 37.92248322147651 0.09790298549103232
59.161 37.92348993288591 0.09762230997135421
59.161 37.9244966442953 0.09734507265981186
59.161 37.9255033557047 0.09707128562783976
59.161 37.926510067114094 0.0968009680255667
59.161 37.92751677852349 0.09653414552801584
59.161 37.928523489932886 0.0888704791536028
59.161 37.92953020134228 0.08856667521499817
59.161 37.93053691275168 0.0882665712204631
59.161 37.93154362416107 0.08797022019270971
59.161 37.93255033557047 0.07339297588393164
59.161 37.933557046979864 0.07325403870379416
59.161 37.93456375838926 0.07311785730290084
59.161 37.935570469798655 0.07298451470057968
59.161 37.93657718120805 0.07285409665208827
59.161 37.937583892617454 0.0727266915744766
59.161 37.93859060402685 0.07260239041264958
59.161 37.939597315436245 0.07248128640904501
59.161 37.94060402684564 0.07236347472243718
59.161 37.94161073825504 0.07224905181520944
59.161 37.94261744966443 0.07213811449017882
59.161 37.94362416107383 0.07203075840222398
59.161 37.94463087248322 0.07192707578856253
59.161 37.94563758389262 0.07182715204282286
59.161 37.946644295302015 0.0717310605851457
59.161 37.94765100671141 0.07163885522879485
59.161 37.948657718120806 0.07155055887727632
59.161 37.9496644295302 0.07146614685293982
59.161 37.9506711409396 0.07138552238333033
59.161 37.95167785234899 0.07130848064676726
59.161 37.95268456375839 0.07123465614818666
59.161 37.953691275167785 0.07116344583805806
59.161 37.95469798657718 0.0710938969875972
59.161 37.955704697986576 0.07102454395627161
59.161 37.95671140939597 0.07095317104093067
59.161 37.95771812080537 0.05935285958855655
59.161 37.95872483221476 0.05906429019454784
59.161 37.95973154362416 0.05875770730859395
59.161 37.960738255033554 0.05842257714235365
59.161 37.96174496644295 0.05804360201026902
59.161 37.96275167785235 0.05759871444123699
59.161 37.96375838926175 0.057056345887644744
59.161 37.964765100671144 0.056371837656738796
59.161 37.96577181208054 0.055482994041477385
59.161 37.966778523489936 0.0543051299291203
59.161 37.96778523489933 0.052726718180223085
59.161 37.96879194630873 0.047335866464843585
59.161 37.96979865771812 0.044513764762848754
59.161 37.97080536912752 0.040826846542263234
59.161 37.971812080536914 0.03614980900367432
59.161 37.97281879194631 0.03045399756678343
59.161 37.973825503355705 0.02386779160945717
59.161 37.9748322147651 0.016703811995248764
59.161 37.9758389261745 0.009418336412023054
59.161 37.97684563758389 0.0025031489498748283
59.161 37.97785234899329 -0.0036409315240916867
59.161 37.978859060402684 -0.008784790704210496
59.161 37.97986577181208 -0.012877848797091473
59.161 37.980872483221475 -0.016000712484446872
59.161 37.98187919463087 -0.01830224399697082
59.161 37.982885906040266 -0.01994859191566968
59.161 37.98389261744966 -0.021092855152985174
59.161 37.98489932885906 -0.021862183666243054
59.161 37.98590604026845 -0.022355693668560064
59.161 37.98691275167785 -0.04028973165301265
59.161 37.98791946308725 -0.04033072052318459
59.161 37.98892617449665 -0.04026340285351887
59.161 37.98993288590604 -0.04011565811604478
59.161 37.99093959731544 -0.03990747774591255
59.161 37.991946308724835 -0.03965335992553022
59.161 37.99295302013423 -0.03936402028562434
59.161 37.993959731543626 -0.03305815846544165
59.161 37.99496644295302 -0.07396828266743825
59.161 37.99597315436242 -0.07331866361816519
59.161 37.99697986577181 -0.07265431190430784
59.161 37.99798657718121 -0.07196828538853345
59.161 37.998993288590604 -0.07123596408886355
59.162 37.85 0.21997109352281752
59.162 37.8510067114094 0.20863470297702796
59.162 37.85201342281879 0.19867847912985134
59.162 37.85302013422819 0.18942790515525082
59.162 37.854026845637584 0.1544255649580409
59.162 37.85503355704698 0.14677897187562153
59.162 37.856040268456375 0.1395835859712217
59.162 37.85704697986577 0.13284297167459747
59.162 37.85805369127517 0.12655659181530898
59.162 37.85906040268456 0.12071792105303217
59.162 37.86006711409396 0.16809335304072473
59.162 37.861073825503354 0.15812474294172507
59.162 37.86208053691275 0.15333780949830347
59.162 37.86308724832215 0.14492210395212707
59.162 37.86409395973155 0.14152417381697638
59.162 37.865100671140944 0.13842786231892862
59.162 37.86610738255034 0.13561023149573226
59.162 37.867114093959735 0.1330486356107828
59.162 37.86812080536913 0.13072109919193534
59.162 37.869127516778526 0.12860659619263842
59.162 37.87013422818792 0.12668524401510634
59.162 37.87114093959732 0.12493842640118138
59.162 37.87214765100671 0.12334885856408952
59.162 37.87315436241611 0.12190060674748289
59.162 37.874161073825505 0.12057907292186093
59.162 37.8751677852349 0.11937095375348211
59.162 37.876174496644296 0.1182641814363407
59.162 37.87718120805369 0.11724785254551068
59.162 37.87818791946309 0.11631214979410365
59.162 37.87919463087248 0.11544826047366778
59.162 37.88020134228188 0.1146482944301253
59.162 37.881208053691275 0.11390520366363191
59.162 37.88221476510067 0.1132127050241217
59.162 37.883221476510066 0.11256520698616171
59.162 37.88422818791946 0.11195774110748753
59.162 37.88523489932886 0.11138589848642566
59.162 37.88624161073825 0.110845771318029
59.162 37.88724832214765 0.11033389949248482
59.162 37.88825503355705 0.10984722206947728
59.162 37.88926174496645 0.10938303338869462
59.162 37.89026845637584 0.1089389435308803
59.162 37.89127516778524 0.10851284281890035
59.162 37.892281879194634 0.10810287003864896
59.162 37.89328859060403 0.10770738406127424
59.162 37.894295302013425 0.10732493855739855
59.162 37.89530201342282 0.10695425950846656
59.162 37.89630872483222 0.10659422523812566
59.162 37.89731543624161 0.10624384870598227
59.162 37.89832214765101 0.1059022618263154
59.162 37.899328859060404 0.10556870159461937
59.162 37.9003355704698 0.1052424978244656
59.162 37.901342281879195 0.10492306231595933
59.162 37.90234899328859 0.10460987929479981
59.162 37.90335570469799 0.10430249697731801
59.162 37.90436241610738 0.10400052013202214
59.162 37.90536912751678 0.10370360352207401
59.162 37.906375838926174 0.10341144612566457
59.162 37.90738255033557 0.10312378604264637
59.162 37.908389261744965 0.10284039600609018
59.162 37.90939597315436 0.1025610794265905
59.162 37.910402684563756 0.10228566690538349
59.162 37.91140939597315 0.10201401315974297
59.162 37.91241610738255 0.10174599431061618
59.162 37.91342281879195 0.10651168807437797
59.162 37.914429530201346 0.10632320884863626
59.162 37.91543624161074 0.10613894664519763
59.162 37.91644295302014 0.10595885298350678
59.162 37.91744966442953 0.10578289182401093
59.162 37.91845637583893 0.10561103831221777
59.162 37.919463087248324 0.1054432776698065
59.162 37.92046979865772 0.10527960421472668
59.162 37.921476510067116 0.09571762812645143
59.162 37.92248322147651 0.09544645495523874
59.162 37.92348993288591 0.09517829679023178
59.162 37.9244966442953 0.09491317225594761
59.162 37.9255033557047 0.09465110586575541
59.162 37.926510067114094 0.09439212757177576
59.162 37.92751677852349 0.09291219790667107
59.162 37.928523489932886 0.09265172630093643
59.162 37.92953020134228 0.07898272608399287
59.162 37.93053691275168 0.07888632028308462
59.162 37.93154362416107 0.07879203426420267
59.162 37.93255033557047 0.0786999373412048
59.162 37.933557046979864 0.0711513854849702
59.162 37.93456375838926 0.0710241511837742
59.162 37.935570469798655 0.0708994279346923
59.162 37.93657718120805 0.0707772995788073
59.162 37.937583892617454 0.07065785245464398
59.162 37.93859060402685 0.07054117541660296
59.162 37.939597315436245 0.07042735984449361
59.162 37.94060402684564 0.0703164996328897
59.162 37.94161073825504 0.07020869114285606
59.162 37.94261744966443 0.07010403308945067
59.162 37.94362416107383 0.07000262632498296
59.162 37.94463087248322 0.06990457345835283
59.162 37.94563758389262 0.06980997822199837
59.162 37.946644295302015 0.06971894445593366
59.162 37.94765100671141 0.06963157451701332
59.162 37.948657718120806 0.06954796683205594
59.162 37.9496644295302 0.0694682121830478
59.162 37.9506711409396 0.06939238812268697
59.162 37.95167785234899 0.06932055064192597
59.162 37.95268456375839 0.06925272180870103
59.162 37.953691275167785 0.06918887151172962
59.162 37.95469798657718 0.06912889059284506
59.162 37.955704697986576 0.07259587132476718
59.162 37.95671140939597 0.07249446777800925
59.162 37.95771812080537 0.07239608015718388
59.162 37.95872483221476 0.07229965991976506
59.162 37.95973154362416 0.07220359579485969
59.162 37.960738255033554 0.0721054537312012
59.162 37.96174496644295 0.07200160027582705
59.162 37.96275167785235 0.07188665915773516
59.162 37.96375838926175 0.05608221705087165
59.162 37.964765100671144 0.055760737813593475
59.162 37.96577181208054 0.05213060024064478
59.162 37.966778523489936 0.05168609577171217
59.162 37.96778523489933 0.05113503208455532
59.162 37.96879194630873 0.05042886339667513
59.162 37.96979865771812 0.04950030187288505
59.162 37.97080536912752 0.048258373372033946
59.162 37.971812080536914 0.046584533282825566
59.162 37.97281879194631 0.044332751126313116
59.162 37.973825503355705 0.04133855029949671
59.162 37.9748322147651 0.03744359343328015
59.162 37.9758389261745 0.032540743626389944
59.162 37.97684563758389 0.026635426904906938
59.162 37.97785234899329 0.019901481891059107
59.162 37.978859060402684 0.012693966615084422
59.162 37.97986577181208 0.005489769928230914
59.162 37.980872483221475 -0.0012311198367123688
59.162 37.98187919463087 -0.007105947538310342
59.162 37.982885906040266 -0.011952088162730579
59.162 37.98389261744966 -0.01575721476462058
59.162 37.98489932885906 -0.03638338023749012
59.162 37.98590604026845 -0.03836922192288186
59.162 37.98691275167785 -0.03973955189601325
59.162 37.98791946308725 -0.040641825300486406
59.162 37.98892617449665 -0.0351766250437323
59.162 37.98993288590604 -0.03550323982822644
59.162 37.99093959731544 -0.03564427345092126
59.162 37.991946308724835 -0.035650041720949543
59.162 37.99295302013423 -0.035556796951816724
59.162 37.993959731543626 -0.07675819760099782
59.162 37.99496644295302 -0.07622232818667032
59.162 37.99597315436242 -0.07564723658872917
59.162 37.99697986577181 -0.075042704367042
59.162 37.99798657718121 -0.07441487348911606
59.162 37.998993288590604 -0.07376490116818825
59.163000000000004 37.85 0.1915483375046011
59.163000000000004 37.8510067114094 0.1827057703178826
59.163000000000004 37.85201342281879 0.17435430752920053
59.163000000000004 37.85302013422819 0.16648966493816414
59.163000000000004 37.854026845637584 0.15911569423574592
59.163000000000004 37.85503355704698 0.1522322157256691
59.163000000000004 37.856040268456375 0.12226398033763602
59.163000000000004 37.85704697986577 0.11673657134184809
59.163000000000004 37.85805369127517 0.11163608672299416
59.163000000000004 37.85906040268456 0.10694233354892596
59.163000000000004 37.86006711409396 0.10263231854459826
59.163000000000004 37.861073825503354 0.15132823968226397
59.163000000000004 37.86208053691275 0.1481013315618824
59.163000000000004 37.86308724832215 0.139971059331029
59.163000000000004 37.86409395973155 0.11586105966919652
59.163000000000004 37.865100671140944 0.12892162373814942
59.163000000000004 37.86610738255034 0.12675373616845748
59.163000000000004 37.867114093959735 0.1251506454699971
59.163000000000004 37.86812080536913 0.1233631724681078
59.163000000000004 37.869127516778526 0.12173944129133453
59.163000000000004 37.87013422818792 0.12026278589284965
59.163000000000004 37.87114093959732 0.11891793264765382
59.163000000000004 37.87214765100671 0.11769096049942976
59.163000000000004 37.87315436241611 0.11656924110812228
59.163000000000004 37.874161073825505 0.11554136569164603
59.163000000000004 37.8751677852349 0.11459706387490692
59.163000000000004 37.876174496644296 0.1137271186644168
59.163000000000004 37.87718120805369 0.11292328065983866
59.163000000000004 37.87818791946309 0.11217818378417792
59.163000000000004 37.87919463087248 0.11148526414419517
59.163000000000004 37.88020134228188 0.11083868310197846
59.163000000000004 37.881208053691275 0.11023325522628956
59.163000000000004 37.88221476510067 0.109664381477929
59.163000000000004 37.883221476510066 0.10912798774919721
59.163000000000004 37.88422818791946 0.10862046870757111
59.163000000000004 37.88523489932886 0.10813863677419429
59.163000000000004 37.88624161073825 0.10767967598768301
59.163000000000004 37.88724832214765 0.10724110045381426
59.163000000000004 37.88825503355705 0.10682071705414388
59.163000000000004 37.88926174496645 0.10641659207588844
59.163000000000004 37.89026845637584 0.1060270214266565
59.163000000000004 37.89127516778524 0.10565050410725749
59.163000000000004 37.892281879194634 0.10528571863094476
59.163000000000004 37.89328859060403 0.10493150209629577
59.163000000000004 37.894295302013425 0.10458683164150714
59.163000000000004 37.89530201342282 0.10425080802936156
59.163000000000004 37.89630872483222 0.1039226411336346
59.163000000000004 37.89731543624161 0.10360163711852838
59.163000000000004 37.89832214765101 0.10328718712264057
59.163000000000004 37.899328859060404 0.10297875727776895
59.163000000000004 37.9003355704698 0.10267587991020366
59.163000000000004 37.901342281879195 0.10237814578819836
59.163000000000004 37.90234899328859 0.10208519729402434
59.163000000000004 37.90335570469799 0.10179672241227737
59.163000000000004 37.90436241610738 0.10151244943815141
59.163000000000004 37.90536912751678 0.10123214232028126
59.163000000000004 37.906375838926174 0.10095559656242865
59.163000000000004 37.90738255033557 0.10068263561697902
59.163000000000004 37.908389261744965 0.1004131077110188
59.163000000000004 37.90939597315436 0.10014688305261095
59.163000000000004 37.910402684563756 0.09988385137101594
59.163000000000004 37.91140939597315 0.09962391975008106
59.163000000000004 37.91241610738255 0.09936701071878394
59.163000000000004 37.91342281879195 0.09911306056716665
59.163000000000004 37.914429530201346 0.09886201785970841
59.163000000000004 37.91543624161074 0.10381155856201356
59.163000000000004 37.91644295302014 0.10364031892012723
59.163000000000004 37.91744966442953 0.10347274125777889
59.163000000000004 37.91845637583893 0.10330882183320374
59.163000000000004 37.919463087248324 0.10314856441364366
59.163000000000004 37.92046979865772 0.10299197958975745
59.163000000000004 37.921476510067116 0.10283908416907873
59.163000000000004 37.92248322147651 0.1020983314061417
59.163000000000004 37.92348993288591 0.10194540347844251
59.163000000000004 37.9244966442953 0.09154862877872641
59.163000000000004 37.9255033557047 0.09128583081246022
59.163000000000004 37.926510067114094 0.07724068658270615
59.163000000000004 37.92751677852349 0.07714431936613404
59.163000000000004 37.928523489932886 0.07704958543000531
59.163000000000004 37.92953020134228 0.0769565465240013
59.163000000000004 37.93053691275168 0.0768652665797913
59.163000000000004 37.93154362416107 0.07677581169926193
59.163000000000004 37.93255033557047 0.07668825014719798
59.163000000000004 37.933557046979864 0.07660265234908276
59.163000000000004 37.93456375838926 0.07651909089473181
59.163000000000004 37.935570469798655 0.0764376405484867
59.163000000000004 37.93657718120805 0.07635837826662309
59.163000000000004 37.937583892617454 0.07628138322247952
59.163000000000004 37.93859060402685 0.07620673683950555
59.163000000000004 37.939597315436245 0.07613452283191777
59.163000000000004 37.94060402684564 0.06850954384556249
59.163000000000004 37.94161073825504 0.06840431244280819
59.163000000000004 37.94261744966443 0.06830190421121228
59.163000000000004 37.94362416107383 0.06820241735067147
59.163000000000004 37.94463087248322 0.06810595259418795
59.163000000000004 37.94563758389262 0.06801261319006632
59.163000000000004 37.946644295302015 0.0679225048156845
59.163000000000004 37.94765100671141 0.06783573537906257
59.163000000000004 37.948657718120806 0.06775241464280236
59.163000000000004 37.9496644295302 0.06767265357334512
59.163000000000004 37.9506711409396 0.06759656327229224
59.163000000000004 37.95167785234899 0.06752425327908457
59.163000000000004 37.95268456375839 0.07104208637363471
59.163000000000004 37.953691275167785 0.07092918997244486
59.163000000000004 37.95469798657718 0.07082079459779428
59.163000000000004 37.955704697986576 0.07071695915788004
59.163000000000004 37.95671140939597 0.07061770558570477
59.163000000000004 37.95771812080537 0.07052299885638072
59.163000000000004 37.95872483221476 0.07043271755020979
59.163000000000004 37.95973154362416 0.0703466106252964
59.163000000000004 37.960738255033554 0.0702642341032874
59.163000000000004 37.96174496644295 0.0701848585413301
59.163000000000004 37.96275167785235 0.07010733409237555
59.163000000000004 37.96375838926175 0.06788176572528865
59.163000000000004 37.964765100671144 0.06779828411947622
59.163000000000004 37.96577181208054 0.06770844678491686
59.163000000000004 37.966778523489936 0.06760636130562472
59.163000000000004 37.96778523489933 0.06748337392508227
59.163000000000004 37.96879194630873 0.067326858214242
59.163000000000004 37.96979865771812 0.067118514081112
59.163000000000004 37.97080536912752 0.06683202245332812
59.163000000000004 37.971812080536914 0.04868968817469249
59.163000000000004 37.97281879194631 0.04795760767114382
59.163000000000004 37.973825503355705 0.046983490239226836
59.163000000000004 37.9748322147651 0.04566984859574155
59.163000000000004 37.9758389261745 0.04389113477777057
59.163000000000004 37.97684563758389 0.0414955019834817
59.163000000000004 37.97785234899329 0.03831706677138068
59.163000000000004 37.978859060402684 0.03420530155664915
59.163000000000004 37.97986577181208 0.029075068980995114
59.163000000000004 37.980872483221475 0.02296939750393187
59.163000000000004 37.98187919463087 0.023391627214305037
59.163000000000004 37.982885906040266 -0.0029287529993742775
59.163000000000004 37.98389261744966 -0.009948380272769203
59.163000000000004 37.98489932885906 -0.01637986403598088
59.163000000000004 37.98590604026845 -0.02190233333919632
59.163000000000004 37.98691275167785 -0.026378753425268617
59.163000000000004 37.98791946308725 -0.029831401301858004
59.163000000000004 37.98892617449665 -0.032382289589922994
59.163000000000004 37.98993288590604 -0.03419404806289483
59.163000000000004 37.99093959731544 -0.035429115915770484
59.163000000000004 37.991946308724835 -0.07802854356328874
59.163000000000004 37.99295302013423 -0.0781990006883172
59.163000000000004 37.993959731543626 -0.07813535450766539
59.163000000000004 37.99496644295302 -0.077901964601135
59.163000000000004 37.99597315436242 -0.07754568536834922
59.163000000000004 37.99697986577181 -0.07710030482468665
59.163000000000004 37.99798657718121 -0.07659002469737389
59.163000000000004 37.998993288590604 -0.07603181486320178
59.164 37.85 0.16829042691313426
59.164 37.8510067114094 0.16080179773893216
59.164 37.85201342281879 0.15380419080634203
59.164 37.85302013422819 0.14729233747440698
59.164 37.854026845637584 0.14125433736885903
59.164 37.85503355704698 0.13567263779999827
59.164 37.856040268456375 0.13052530141611035
59.164 37.85704697986577 0.12578726594202544
59.164 37.85805369127517 0.12143148511572124
59.164 37.85906040268456 0.11742990867841974
59.164 37.86006711409396 0.09263844541533481
59.164 37.861073825503354 0.08954175899342492
59.164 37.86208053691275 0.08670888635993138
59.164 37.86308724832215 0.13694497967116287
59.164 37.86409395973155 0.13491946924692305
59.164 37.865100671140944 0.10694644191243581
59.164 37.86610738255034 0.10479553585633483
59.164 37.867114093959735 0.11862968086141354
59.164 37.86812080536913 0.11725316068106256
59.164 37.869127516778526 0.11599761979976395
59.164 37.87013422818792 0.1148499173766305
59.164 37.87114093959732 0.11379820520016438
59.164 37.87214765100671 0.1128318381283725
59.164 37.87315436241611 0.11194128116155509
59.164 37.874161073825505 0.11153922185478381
59.164 37.8751677852349 0.11078938351083382
59.164 37.876174496644296 0.11009328972902328
59.164 37.87718120805369 0.10944486493370005
59.164 37.87818791946309 0.10883871536875185
59.164 37.87919463087248 0.10827005923005739
59.164 37.88020134228188 0.10773466237580168
59.164 37.881208053691275 0.10722877957577917
59.164 37.88221476510067 0.10674910113101936
59.164 37.883221476510066 0.10629270460789486
59.164 37.88422818791946 0.10585701137624265
59.164 37.88523489932886 0.10543974761063918
59.164 37.88624161073825 0.10503890940168266
59.164 37.88724832214765 0.10465273162485472
59.164 37.88825503355705 0.10427966022415998
59.164 37.88926174496645 0.1039183275835117
59.164 37.89026845637584 0.1035675306784028
59.164 37.89127516778524 0.1032262117220737
59.164 37.892281879194634 0.10289344104286995
59.164 37.89328859060403 0.10256840195215355
59.164 37.894295302013425 0.10225037738402777
59.164 37.89530201342282 0.10193873810911101
59.164 37.89630872483222 0.10163293234437304
59.164 37.89731543624161 0.10133247659931302
59.164 37.89832214765101 0.10103694761564469
59.164 37.899328859060404 0.10074597527312229
59.164 37.9003355704698 0.10045923634810301
59.164 37.901342281879195 0.10017644902410136
59.164 37.90234899328859 0.09989736806503174
59.164 37.90335570469799 0.0996217805720006
59.164 37.90436241610738 0.09934950225363592
59.164 37.90536912751678 0.09908037414813335
59.164 37.906375838926174 0.0988142597423809
59.164 37.90738255033557 0.09855104243995436
59.164 37.908389261744965 0.09829062333551761
59.164 37.90939597315436 0.0980329192581465
59.164 37.910402684563756 0.09777786105055532
59.164 37.91140939597315 0.0975253920551761
59.164 37.91241610738255 0.09727546678146061
59.164 37.91342281879195 0.0970280497318306
59.164 37.914429530201346 0.09678311436644184
59.164 37.91543624161074 0.09543175021014337
59.164 37.91644295302014 0.09517852462497753
59.164 37.91744966442953 0.09492802086539649
59.164 37.91845637583893 0.09468023952527962
59.164 37.919463087248324 0.10047835105625137
59.164 37.92046979865772 0.10031782913712863
59.164 37.921476510067116 0.10016091434166086
59.164 37.92248322147651 0.08535804244731307
59.164 37.92348993288591 0.08537165077889408
59.164 37.9244966442953 0.08538745401719186
59.164 37.9255033557047 0.08540550912867628
59.164 37.926510067114094 0.08542587497583853
59.164 37.92751677852349 0.075344427553584
59.164 37.928523489932886 0.07525245774610882
59.164 37.92953020134228 0.07516191252589795
59.164 37.93053691275168 0.0750728541853106
59.164 37.93154362416107 0.0749853470332558
59.164 37.93255033557047 0.07489945739055474
59.164 37.933557046979864 0.0748152535883323
59.164 37.93456375838926 0.07473280597007452
59.164 37.935570469798655 0.07465218689810076
59.164 37.93657718120805 0.07457347076526194
59.164 37.937583892617454 0.07449673401277102
59.164 37.93859060402685 0.07442205515510095
59.164 37.939597315436245 0.07434951481291349
59.164 37.94060402684564 0.07427919575490012
59.164 37.94161073825504 0.07421118294926965
59.164 37.94261744966443 0.07414556362527736
59.164 37.94362416107383 0.0740824273446229
59.164 37.94463087248322 0.0740218660816331
59.164 37.94563758389262 0.07396397430967541
59.164 37.946644295302015 0.07390884908902065
59.164 37.94765100671141 0.07385659014800541
59.164 37.948657718120806 0.07380729994429694
59.164 37.9496644295302 0.07840413106945353
59.164 37.9506711409396 0.07830725680711306
59.164 37.95167785234899 0.06944595042049001
59.164 37.95268456375839 0.06932610626205031
59.164 37.953691275167785 0.06921037759310744
59.164 37.95469798657718 0.06909888313415032
59.164 37.955704697986576 0.06899174014209469
59.164 37.95671140939597 0.06888906158010755
59.164 37.95771812080537 0.06879095176402886
59.164 37.95872483221476 0.06869749975943507
59.164 37.95973154362416 0.06860876946983652
59.164 37.960738255033554 0.06635339474362244
59.164 37.96174496644295 0.06627064679767614
59.164 37.96275167785235 0.06619268438277323
59.164 37.96375838926175 0.06611923362979102
59.164 37.964765100671144 0.06604980977075244
59.164 37.96577181208054 0.06598361567157744
59.164 37.966778523489936 0.06591939386224795
59.164 37.96778523489933 0.06585521125695323
59.164 37.96879194630873 0.06578814678196608
59.164 37.96979865771812 0.0657138396366134
59.164 37.97080536912752 0.07556526804331512
59.164 37.971812080536914 0.07547417839693077
59.164 37.97281879194631 0.07534581763775326
59.164 37.973825503355705 0.07516018702897753
59.164 37.9748322147651 0.07488857393406047
59.164 37.9758389261745 0.07449019319757284
59.164 37.97684563758389 0.07390795080824597
59.164 37.97785234899329 0.07306356325978139
59.164 37.978859060402684 0.07185289598474531
59.164 37.97986577181208 0.07014357138971654
59.164 37.980872483221475 0.049015483009121744
59.164 37.98187919463087 0.045917399059763025
59.164 37.982885906040266 0.04185890070143916
59.164 37.98389261744966 0.03678086462587349
59.164 37.98489932885906 0.03076711107862306
59.164 37.98590604026845 0.02408385168739883
59.164 37.98691275167785 0.017158405630859665
59.164 37.98791946308725 0.010485569491978403
59.164 37.98892617449665 0.0044995890360975815
59.164 37.98993288590604 -0.04566715483059816
59.164 37.99093959731544 -0.04934546683851279
59.164 37.991946308724835 -0.05202010334715147
59.164 37.99295302013423 -0.053827831536123597
59.164 37.993959731543626 -0.05493399988938669
59.164 37.99496644295302 -0.05549694867620439
59.164 37.99597315436242 -0.05565124498784477
59.164 37.99697986577181 -0.05550341506199652
59.164 37.99798657718121 -0.05513410971354828
59.164 37.998993288590604 -0.054602653018115094
59.165 37.85 0.1487761320879964
59.165 37.8510067114094 0.14262893711117683
59.165 37.85201342281879 0.1369425196832037
59.165 37.85302013422819 0.1316958607392307
59.165 37.854026845637584 0.12686453004096218
59.165 37.85503355704698 0.12242185989285015
59.165 37.856040268456375 0.11833996430804052
59.165 37.85704697986577 0.11459058672850093
59.165 37.85805369127517 0.11114577797787213
59.165 37.85906040268456 0.10797841653931728
59.165 37.86006711409396 0.10506258891411935
59.165 37.861073825503354 0.10237385031584403
59.165 37.86208053691275 0.09988938629671924
59.165 37.86308724832215 0.07814359334577137
59.165 37.86409395973155 0.07625974874818182
59.165 37.865100671140944 0.12753026994722366
59.165 37.86610738255034 0.10013128175325138
59.165 37.867114093959735 0.09885087392212052
59.165 37.86812080536913 0.0976711392732136
59.165 37.869127516778526 0.11391209273386682
59.165 37.87013422818792 0.11083808702923582
59.165 37.87114093959732 0.11000402736498525
59.165 37.87214765100671 0.10923163457295633
59.165 37.87315436241611 0.10851388730752516
59.165 37.874161073825505 0.10784455178778206
59.165 37.8751677852349 0.1072181021637694
59.165 37.876174496644296 0.10662964683447697
59.165 37.87718120805369 0.10607486082214339
59.165 37.87818791946309 0.10554992411988068
59.165 37.87919463087248 0.10505146579932663
59.165 37.88020134228188 0.10457651357914553
59.165 37.881208053691275 0.10412244850302484
59.165 37.88221476510067 0.10368696434852763
59.165 37.883221476510066 0.10326803137928708
59.165 37.88422818791946 0.10286386405724685
59.165 37.88523489932886 0.10315419736851993
59.165 37.88624161073825 0.1027944478698005
59.165 37.88724832214765 0.10244524766814338
59.165 37.88825503355705 0.10210550456673373
59.165 37.88926174496645 0.1017742588801692
59.165 37.89026845637584 0.10145066769961467
59.165 37.89127516778524 0.1011339910055119
59.165 37.892281879194634 0.1008235794211994
59.165 37.89328859060403 0.10051886342153213
59.165 37.894295302013425 0.10021934382970513
59.165 37.89530201342282 0.09992458345315915
59.165 37.89630872483222 0.0996341997256413
59.165 37.89731543624161 0.09934785823710707
59.165 37.89832214765101 0.09906526704640015
59.165 37.899328859060404 0.0987861716836173
59.165 37.9003355704698 0.0985103507596973
59.165 37.901342281879195 0.09823761211032378
59.165 37.90234899328859 0.09796778940978591
59.165 37.90335570469799 0.09770073919796111
59.165 37.90436241610738 0.09743633827029607
59.165 37.90536912751678 0.09613639166394918
59.165 37.906375838926174 0.09586166725570744
59.165 37.90738255033557 0.09558960249234337
59.165 37.908389261744965 0.09532013630797517
59.165 37.90939597315436 0.09505321849184496
59.165 37.910402684563756 0.09478880853285084
59.165 37.91140939597315 0.09452687460569381
59.165 37.91241610738255 0.09426739268085696
59.165 37.91342281879195 0.09401034574277667
59.165 37.914429530201346 0.09375572310249854
59.165 37.91543624161074 0.09350351979268452
59.165 37.91644295302014 0.09325373603433955
59.165 37.91744966442953 0.09300637676587578
59.165 37.91845637583893 0.07553905262588662
59.165 37.919463087248324 0.07546524743816972
59.165 37.92046979865772 0.07539243499631101
59.165 37.921476510067116 0.07532065501874236
59.165 37.92248322147651 0.08357400284298831
59.165 37.92348993288591 0.08358757714843693
59.165 37.9244966442953 0.08360309408343786
59.165 37.9255033557047 0.0836206088954649
59.165 37.926510067114094 0.08364017860827566
59.165 37.92751677852349 0.08366186200361646
59.165 37.928523489932886 0.08368571960124871
59.165 37.92953020134228 0.07355237981403687
59.165 37.93053691275168 0.07346363060485239
59.165 37.93154362416107 0.07337617978223361
59.165 37.93255033557047 0.07329009082236601
59.165 37.933557046979864 0.07320542911156847
59.165 37.93456375838926 0.07312226195090099
59.165 37.935570469798655 0.0730406585632532
59.165 37.93657718120805 0.07296069010353855
59.165 37.937583892617454 0.07288242967273695
59.165 37.93859060402685 0.07280595233662356
59.165 37.939597315436245 0.07273133515013824
59.165 37.94060402684564 0.07265865718844311
59.165 37.94161073825504 0.07258799958580929
59.165 37.94261744966443 0.072519445583529
59.165 37.94362416107383 0.07245308058806074
59.165 37.94463087248322 0.07238899224056557
59.165 37.94563758389262 0.07705744341018675
59.165 37.946644295302015 0.07694427409431928
59.165 37.94765100671141 0.07683403703883648
59.165 37.948657718120806 0.07672683002893671
59.165 37.9496644295302 0.07662275356553544
59.165 37.9506711409396 0.07652191100102727
59.165 37.95167785234899 0.07642440867046309
59.165 37.95268456375839 0.07633035600386126
59.165 37.953691275167785 0.07623986559713623
59.165 37.95469798657718 0.07615305320701649
59.165 37.955704697986576 0.07607003761747924
59.165 37.95671140939597 0.07447418531461186
59.165 37.95771812080537 0.07439029648799549
59.165 37.95872483221476 0.07431076254005828
59.165 37.95973154362416 0.07423570688208594
59.165 37.960738255033554 0.0741652487966527
59.165 37.96174496644295 0.07409949873546809
59.165 37.96275167785235 0.07403855115122984
59.165 37.96375838926175 0.07398247370009253
59.165 37.964765100671144 0.07393129111882261
59.165 37.96577181208054 0.07388496130703187
59.165 37.966778523489936 0.0738433400218869
59.165 37.96778523489933 0.07380612896538412
59.165 37.96879194630873 0.07377279969131974
59.165 37.96979865771812 0.07374248236809988
59.165 37.97080536912752 0.07371380357011031
59.165 37.971812080536914 0.07368465034533679
59.165 37.97281879194631 0.07365182805322353
59.165 37.973825503355705 0.07361056595751134
59.165 37.9748322147651 0.07355380633910537
59.165 37.9758389261745 0.07347118938552141
59.165 37.97684563758389 0.07334761812028989
59.165 37.97785234899329 0.07316125959318455
59.165 37.978859060402684 0.054030626380843924
59.165 37.97986577181208 0.053697822997031755
59.165 37.980872483221475 0.05316488947743045
59.165 37.98187919463087 0.05234762508577906
59.165 37.982885906040266 0.05113507458846254
59.165 37.98389261744966 0.04938821750226492
59.165 37.98489932885906 0.046946119462597545
59.165 37.98590604026845 0.043645872030644545
59.165 37.98691275167785 0.03936219898307217
59.165 37.98791946308725 -0.011366625437646195
59.165 37.98892617449665 -0.017268960779143527
59.165 37.98993288590604 -0.023746736873482673
59.165 37.99093959731544 -0.030345125564686798
59.165 37.991946308724835 -0.03657268942141462
59.165 37.99295302013423 -0.04202768518306049
59.165 37.993959731543626 -0.04647930738600409
59.165 37.99496644295302 -0.04987527614692237
59.165 37.99597315436242 -0.05229502870636005
59.165 37.99697986577181 -0.053886957020265
59.165 37.99798657718121 -0.054817351055827815
59.165 37.998993288590604 -0.055239883145771246
59.166000000000004 37.85 -0.044622555531427535
59.166000000000004 37.8510067114094 -0.044622555531427535
59.166000000000004 37.85201342281879 0.12343055149829313
59.166000000000004 37.85302013422819 0.11926691805754343
59.166000000000004 37.854026845637584 0.11544236784866306
59.166000000000004 37.85503355704698 0.1119288347244988
59.166000000000004 37.856040268456375 0.10869897109544184
59.166000000000004 37.85704697986577 0.10572655918826662
59.166000000000004 37.85805369127517 0.102986797853317
59.166000000000004 37.85906040268456 0.1004564855748598
59.166000000000004 37.86006711409396 0.09811411954641273
59.166000000000004 37.861073825503354 0.0959399289234977
59.166000000000004 37.86208053691275 0.0939158581213134
59.166000000000004 37.86308724832215 0.0920255136115878
59.166000000000004 37.86409395973155 0.09025408530917667
59.166000000000004 37.865100671140944 0.0885882514588496
59.166000000000004 37.86610738255034 0.087016074006902
59.166000000000004 37.867114093959735 0.09507098509114696
59.166000000000004 37.86812080536913 0.09411293992694338
59.166000000000004 37.869127516778526 0.09321923657576346
59.166000000000004 37.87013422818792 0.09238252186215605
59.166000000000004 37.87114093959732 0.09159627272535444
59.166000000000004 37.87214765100671 0.10827686923025345
59.166000000000004 37.87315436241611 0.10569745512117285
59.166000000000004 37.874161073825505 0.10513840972541035
59.166000000000004 37.8751677852349 0.10461000588810301
59.166000000000004 37.876174496644296 0.10410874900232746
59.166000000000004 37.87718120805369 0.10363155832042194
59.166000000000004 37.87818791946309 0.10317571986240928
59.166000000000004 37.87919463087248 0.10273884422958947
59.166000000000004 37.88020134228188 0.10231882892587994
59.166000000000004 37.881208053691275 0.1019138247921109
59.166000000000004 37.88221476510067 0.10152220617076578
59.166000000000004 37.883221476510066 0.10114254443748576
59.166000000000004 37.88422818791946 0.10077358455852124
59.166000000000004 37.88523489932886 0.10041422435815252
59.166000000000004 37.88624161073825 0.10006349620580941
59.166000000000004 37.88724832214765 0.09972055085821703
59.166000000000004 37.88825503355705 0.09938464321655564
59.166000000000004 37.88926174496645 0.09905511978213981
59.166000000000004 37.89026845637584 0.09873140761613934
59.166000000000004 37.89127516778524 0.09841300462926304
59.166000000000004 37.892281879194634 0.0980994710459648
59.166000000000004 37.89328859060403 0.09779042190488017
59.166000000000004 37.894295302013425 0.09748552047258019
59.166000000000004 37.89530201342282 0.09718447246165786
59.166000000000004 37.89630872483222 0.09688702095671825
59.166000000000004 37.89731543624161 0.09659294196297591
59.166000000000004 37.89832214765101 0.09630204050214222
59.166000000000004 37.899328859060404 0.09601414718921646
59.166000000000004 37.9003355704698 0.09572911523162334
59.166000000000004 37.901342281879195 0.09544681779912989
59.166000000000004 37.90234899328859 0.09516714571920318
59.166000000000004 37.90335570469799 0.09489000545788298
59.166000000000004 37.90436241610738 0.09461531735106309
59.166000000000004 37.90536912751678 0.09434301405537263
59.166000000000004 37.906375838926174 0.09407303919153889
59.166000000000004 37.90738255033557 0.09380534615640895
59.166000000000004 37.908389261744965 0.09353989708274629
59.166000000000004 37.90939597315436 0.09327666192841537
59.166000000000004 37.910402684563756 0.09301561767879973
59.166000000000004 37.91140939597315 0.09275674764830501
59.166000000000004 37.91241610738255 0.09250004086846977
59.166000000000004 37.91342281879195 0.09224549155171372
59.166000000000004 37.914429530201346 0.07423628775029933
59.166000000000004 37.91543624161074 0.07415948931854993
59.166000000000004 37.91644295302014 0.07408334049271413
59.166000000000004 37.91744966442953 0.07400787402067932
59.166000000000004 37.91845637583893 0.07393312414905426
59.166000000000004 37.919463087248324 0.07385912660752841
59.166000000000004 37.92046979865772 0.07378591859181688
59.166000000000004 37.921476510067116 0.07371353874484005
59.166000000000004 37.92248322147651 0.0736420271357748
59.166000000000004 37.92348993288591 0.07357142523661862
59.166000000000004 37.9244966442953 0.07350177589588157
59.166000000000004 37.9255033557047 0.0734331233090138
59.166000000000004 37.926510067114094 0.08200536166402358
59.166000000000004 37.92751677852349 0.08202473125864987
59.166000000000004 37.928523489932886 0.08204603859174553
59.166000000000004 37.92953020134228 0.08206934290026698
59.166000000000004 37.93053691275168 0.08209470505707242
59.166000000000004 37.93154362416107 0.08212218755873138
59.166000000000004 37.93255033557047 0.08215185451112963
59.166000000000004 37.933557046979864 0.07173791017299808
59.166000000000004 37.93456375838926 0.07165291188866968
59.166000000000004 37.935570469798655 0.07156923133134012
59.166000000000004 37.93657718120805 0.07148693575542836
59.166000000000004 37.937583892617454 0.07140609429155645
59.166000000000004 37.93859060402685 0.07132677796401123
59.166000000000004 37.939597315436245 0.07124905971166233
59.166000000000004 37.94060402684564 0.07117301441315659
59.166000000000004 37.94161073825504 0.07109871891733471
59.166000000000004 37.94261744966443 0.07578479508647662
59.166000000000004 37.94362416107383 0.07566068782696175
59.166000000000004 37.94463087248322 0.07553894421435264
59.166000000000004 37.94563758389262 0.07541964962244202
59.166000000000004 37.946644295302015 0.07530289161990772
59.166000000000004 37.94765100671141 0.0751887600603201
59.166000000000004 37.948657718120806 0.07507734718666845
59.166000000000004 37.9496644295302 0.07496874775168039
59.166000000000004 37.9506711409396 0.07486305915484505
59.166000000000004 37.95167785234899 0.07476038159635488
59.166000000000004 37.95268456375839 0.07466081824709471
59.166000000000004 37.953691275167785 0.07300112231371361
59.166000000000004 37.95469798657718 0.07289968816790973
59.166000000000004 37.955704697986576 0.07280188172451584
59.166000000000004 37.95671140939597 0.07270781990893752
59.166000000000004 37.95771812080537 0.07261762345080366
59.166000000000004 37.95872483221476 0.07253141693800488
59.166000000000004 37.95973154362416 0.07244932873408519
59.166000000000004 37.960738255033554 0.07237149067282077
59.166000000000004 37.96174496644295 0.07229803740180306
59.166000000000004 37.96275167785235 0.07222910518551504
59.166000000000004 37.96375838926175 0.07216482988895902
59.166000000000004 37.964765100671144 0.07210534373249969
59.166000000000004 37.96577181208054 0.07205077021876814
59.166000000000004 37.966778523489936 0.07200121635609844
59.166000000000004 37.96778523489933 0.07195676090099085
59.166000000000004 37.96879194630873 0.07191743675769477
59.166000000000004 37.96979865771812 0.07188320482422791
59.166000000000004 37.97080536912752 0.07185391534276778
59.166000000000004 37.971812080536914 0.07182925102900106
59.166000000000004 37.97281879194631 0.07180864367855393
59.166000000000004 37.973825503355705 0.07179115223971275
59.166000000000004 37.9748322147651 0.07177528503092476
59.166000000000004 37.9758389261745 0.05274065969251489
59.166000000000004 37.97684563758389 0.052804468528230564
59.166000000000004 37.97785234899329 0.05285899244436573
59.166000000000004 37.978859060402684 0.05289651779811777
59.166000000000004 37.97986577181208 0.052905722970260666
59.166000000000004 37.980872483221475 0.05287011879367334
59.166000000000004 37.98187919463087 0.05276588662147906
59.166000000000004 37.982885906040266 0.05255895603703127
59.166000000000004 37.98389261744966 0.05220120965505852
59.166000000000004 37.98489932885906 0.05162587463796353
59.166000000000004 37.98590604026845 0.005045865703162324
59.166000000000004 37.98691275167785 0.004010063066528777
59.166000000000004 37.98791946308725 0.002402765413339617
59.166000000000004 37.98892617449665 6.014280643386674e-05
59.166000000000004 37.98993288590604 -0.003175816872358319
59.166000000000004 37.99093959731544 -0.007412566022343192
59.166000000000004 37.991946308724835 -0.012645894116971187
59.166000000000004 37.99295302013423 -0.018704002666355732
59.166000000000004 37.993959731543626 -0.02523407265794523
59.166000000000004 37.99496644295302 -0.031760659285685486
59.166000000000004 37.99597315436242 -0.03780369861907318
59.166000000000004 37.99697986577181 -0.04299893693159654
59.166000000000004 37.99798657718121 -0.047161633115518325
59.166000000000004 37.998993288590604 -0.05027762483056199
59.167 37.85 -0.044622555531427535
59.167 37.8510067114094 -0.044622555531427535
59.167 37.85201342281879 -0.044622555531427535
59.167 37.85302013422819 -0.044622555531427535
59.167 37.854026845637584 -0.044622555531427535
59.167 37.85503355704698 -0.044622555531427535
59.167 37.856040268456375 -0.044622555531427535
59.167 37.85704697986577 0.09864860364264702
59.167 37.85805369127517 0.09643677758135943
59.167 37.85906040268456 0.09437913554525242
59.167 37.86006711409396 0.09245890406882531
59.167 37.861073825503354 0.090660912823606
59.167 37.86208053691275 0.08897150273062902
59.167 37.86308724832215 0.08737842307947151
59.167 37.86409395973155 0.08587072334932638
59.167 37.865100671140944 0.08443864399382933
59.167 37.86610738255034 0.08307350928018202
59.167 37.867114093959735 0.08176762433507055
59.167 37.86812080536913 0.08051417781524035
59.167 37.869127516778526 0.10507635881869579
59.167 37.87013422818792 0.10422094532209887
59.167 37.87114093959732 0.089021304203887
59.167 37.87214765100671 0.08837684674468542
59.167 37.87315436241611 0.08776072243446399
59.167 37.874161073825505 0.08716973688585859
59.167 37.8751677852349 0.10415374906909149
59.167 37.876174496644296 0.10367706016689489
59.167 37.87718120805369 0.1015283477000163
59.167 37.87818791946309 0.10112128206368652
59.167 37.87919463087248 0.10072783263292102
59.167 37.88020134228188 0.10034652226281983
59.167 37.881208053691275 0.09997605347654322
59.167 37.88221476510067 0.09961528695640666
59.167 37.883221476510066 0.09926322254584712
59.167 37.88422818791946 0.09891898248794716
59.167 37.88523489932886 0.09858179665161758
59.167 37.88624161073825 0.09825098952080931
59.167 37.88724832214765 0.09792596874494457
59.167 37.88825503355705 0.09760621506981147
59.167 37.88926174496645 0.09729127348757592
59.167 37.89026845637584 0.09698074546226924
59.167 37.89127516778524 0.0966742821031598
59.167 37.892281879194634 0.09637157817280924
59.167 37.89328859060403 0.0960723668296956
59.167 37.894295302013425 0.09577641501684238
59.167 37.89530201342282 0.09548351941826526
59.167 37.89630872483222 0.09519350291432364
59.167 37.89731543624161 0.09490621147521187
59.167 37.89832214765101 0.0946215114390833
59.167 37.899328859060404 0.09433928712777853
59.167 37.9003355704698 0.09405943875875389
59.167 37.901342281879195 0.09378188061682033
59.167 37.90234899328859 0.09350653945376462
59.167 37.90335570469799 0.09323335308776762
59.167 37.90436241610738 0.0929622691779574
59.167 37.90536912751678 0.09269324415249088
59.167 37.906375838926174 0.09242624227115316
59.167 37.90738255033557 0.0921612348057907
59.167 37.908389261744965 0.0732316662458172
59.167 37.90939597315436 0.07315211390159909
59.167 37.910402684563756 0.07307288120629546
59.167 37.91140939597315 0.07299399123517718
59.167 37.91241610738255 0.07291546843053896
59.167 37.91342281879195 0.07283733859975854
59.167 37.914429530201346 0.07275962891389133
59.167 37.91543624161074 0.07268236790656678
59.167 37.91644295302014 0.07260558547296195
59.167 37.91744966442953 0.07252931286861639
59.167 37.91845637583893 0.07245358270784129
59.167 37.919463087248324 0.07237842896146407
59.167 37.92046979865772 0.0723038869536543
59.167 37.921476510067116 0.07222999335754965
59.167 37.92248322147651 0.07215678618939633
59.167 37.92348993288591 0.07208430480091263
59.167 37.9244966442953 0.07201258986956495
59.167 37.9255033557047 0.07194168338642958
59.167 37.926510067114094 0.07187162864131873
59.167 37.92751677852349 0.07180247020481695
59.167 37.928523489932886 0.07173425390686904
59.167 37.92953020134228 0.07166702681155597
59.167 37.93053691275168 0.0716008371876722
59.167 37.93154362416107 0.08058830189085209
59.167 37.93255033557047 0.0806138032647303
59.167 37.933557046979864 0.08064132281514695
59.167 37.93456375838926 0.0806709239889619
59.167 37.935570469798655 0.0807026717802185
59.167 37.93657718120805 0.07012476929520546
59.167 37.937583892617454 0.07493054074288916
59.167 37.93859060402685 0.07479397725727392
59.167 37.939597315436245 0.07465913100530143
59.167 37.94060402684564 0.07452607240900805
59.167 37.94161073825504 0.07439487374971226
59.167 37.94261744966443 0.07426560920126897
59.167 37.94362416107383 0.07413835486809302
59.167 37.94463087248322 0.07401318882901778
59.167 37.94563758389262 0.07389019118817414
59.167 37.946644295302015 0.07376944413421935
59.167 37.94765100671141 0.07365103200941339
59.167 37.948657718120806 0.07353504139015855
59.167 37.9496644295302 0.07181667998856975
59.167 37.9506711409396 0.07169764493670339
59.167 37.95167785234899 0.07158148498926767
59.167 37.95268456375839 0.0714682967220577
59.167 37.953691275167785 0.0713581795500219
59.167 37.95469798657718 0.07125123591455393
59.167 37.955704697986576 0.07114757149283679
59.167 37.95671140939597 0.07104729542851551
59.167 37.95771812080537 0.07095052058108678
59.167 37.95872483221476 0.07085736378841155
59.167 37.95973154362416 0.07076794613229265
59.167 37.960738255033554 0.07068239319036933
59.167 37.96174496644295 0.07060083524762534
59.167 37.96275167785235 0.0705234074261322
59.167 37.96375838926175 0.07045024967011113
59.167 37.964765100671144 0.07038150649176989
59.167 37.96577181208054 0.07031732633723078
59.167 37.966778523489936 0.0702578603644138
59.167 37.96778523489933 0.0702032603264531
59.167 37.96879194630873 0.07015367511098763
59.167 37.96979865771812 0.07010924527714005
59.167 37.97080536912752 0.07007009462854928
59.167 37.971812080536914 0.07003631741954157
59.167 37.97281879194631 0.05082778158041981
59.167 37.973825503355705 0.05088793114274489
59.167 37.9748322147651 0.050953417510078085
59.167 37.9758389261745 0.05102390439399884
59.167 37.97684563758389 0.051098782158598
59.167 37.97785234899329 0.05117703419704049
59.167 37.978859060402684 0.05125704211587288
59.167 37.97986577181208 0.05133630256748219
59.167 37.980872483221475 0.05141101709416031
59.167 37.98187919463087 0.051475500637284136
59.167 37.982885906040266 0.05152133357897349
59.167 37.98389261744966 0.005593976006605279
59.167 37.98489932885906 0.005827240123521371
59.167 37.98590604026845 0.005985178206584469
59.167 37.98691275167785 0.006030723372813334
59.167 37.98791946308725 0.00591170058664342
59.167 37.98892617449665 0.005556069929588566
59.167 37.98993288590604 0.004867078265213723
59.167 37.99093959731544 0.0037199949814486627
59.167 37.991946308724835 0.0019637881974054784
59.167 37.99295302013423 -0.0005668019184366414
59.167 37.993959731543626 -0.004022184587856033
59.167 37.99496644295302 -0.00848825949926385
59.167 37.99597315436242 -0.05618554155357313
59.167 37.99697986577181 -0.06262529221311164
59.167 37.99798657718121 -0.06943162012157841
59.167 37.998993288590604 -0.07611585687148939
59.168 37.85 -0.04462255553142752
59.168 37.8510067114094 -0.044622555531427535
59.168 37.85201342281879 -0.044622555531427535
59.168 37.85302013422819 -0.044622555531427535
59.168 37.854026845637584 -0.044622555531427535
59.168 37.85503355704698 -0.044622555531427535
59.168 37.856040268456375 -0.044622555531427535
59.168 37.85704697986577 -0.044622555531427535
59.168 37.85805369127517 -0.044622555531427535
59.168 37.85906040268456 -0.044622555531427535
59.168 37.86006711409396 -0.044622555531427535
59.168 37.861073825503354 -0.044622555531427535
59.168 37.86208053691275 -0.044622555531427535
59.168 37.86308724832215 -0.044622555531427535
59.168 37.86409395973155 -0.044622555531427535
59.168 37.865100671140944 0.08080505281810617
59.168 37.86610738255034 0.07958770039920242
59.168 37.867114093959735 0.07841264194479529
59.168 37.86812080536913 0.07727505826363078
59.168 37.869127516778526 0.07617070765711466
59.168 37.87013422818792 0.0454316974913478
59.168 37.87114093959732 0.04444973962392028
59.168 37.87214765100671 0.09997093726357904
59.168 37.87315436241611 0.09927485373743421
59.168 37.874161073825505 0.09859747856703845
59.168 37.8751677852349 0.09793686315564283
59.168 37.876174496644296 0.08408185681504467
59.168 37.87718120805369 0.08358804742857763
59.168 37.87818791946309 0.10081735961212059
59.168 37.87919463087248 0.10041616593603166
59.168 37.88020134228188 0.10002384733367227
59.168 37.881208053691275 0.09963950477880261
59.168 37.88221476510067 0.09789785654037995
59.168 37.883221476510066 0.0975650634410229
59.168 37.88422818791946 0.09723806827046398
59.168 37.88523489932886 0.096916334652082
59.168 37.88624161073825 0.09659939242995015
59.168 37.88724832214765 0.09628682961888808
59.168 37.88825503355705 0.09597828533662818
59.168 37.88926174496645 0.09567344360080339
59.168 37.89026845637584 0.09537202788693543
59.168 37.89127516778524 0.09507379635566472
59.168 37.892281879194634 0.09477853766814996
59.168 37.89328859060403 0.09448606731822375
59.168 37.894295302013425 0.09419622441832738
59.168 37.89530201342282 0.09390886888378074
59.168 37.89630872483222 0.09362387896666231
59.168 37.89731543624161 0.09334114909641002
59.168 37.89832214765101 0.09306058798945731
59.168 37.899328859060404 0.09278211699484486
59.168 37.9003355704698 0.09250566864673997
59.168 37.901342281879195 0.07242164387335419
59.168 37.90234899328859 0.07234101962210683
59.168 37.90335570469799 0.07226045720516969
59.168 37.90436241610738 0.07217997043555571
59.168 37.90536912751678 0.07209957429708683
59.168 37.906375838926174 0.07201928494215103
59.168 37.90738255033557 0.07193911969108446
59.168 37.908389261744965 0.07185909703303987
59.168 37.90939597315436 0.07177923662819553
59.168 37.910402684563756 0.07169955931115074
59.168 37.91140939597315 0.07162008709537046
59.168 37.91241610738255 0.07154084317852502
59.168 37.91342281879195 0.07146185194856201
59.168 37.914429530201346 0.07138313899036797
59.168 37.91543624161074 0.07130473109284194
59.168 37.91644295302014 0.07122665625621709
59.168 37.91744966442953 0.07114894369945678
59.168 37.91845637583893 0.07107162386753901
59.168 37.919463087248324 0.07099472843843341
59.168 37.92046979865772 0.07091829032957914
59.168 37.921476510067116 0.07084234370364773
59.168 37.92248322147651 0.07076692397336827
59.168 37.92348993288591 0.07069206780519013
59.168 37.9244966442953 0.07061781312153767
59.168 37.9255033557047 0.07054419910139614
59.168 37.926510067114094 0.07047126617897033
59.168 37.92751677852349 0.0703990560401319
59.168 37.928523489932886 0.07032761161635559
59.168 37.92953020134228 0.07025697707584673
59.168 37.93053691275168 0.07018719781153437
59.168 37.93154362416107 0.07011832042559106
59.168 37.93255033557047 0.07629732497822574
59.168 37.933557046979864 0.07617837494273538
59.168 37.93456375838926 0.0760607738915796
59.168 37.935570469798655 0.07594457277864683
59.168 37.93657718120805 0.08563807371563063
59.168 37.937583892617454 0.08562047839031141
59.168 37.93859060402685 0.08560530259968772
59.168 37.939597315436245 0.08559261245861295
59.168 37.94060402684564 0.07310191360378875
59.168 37.94161073825504 0.07296798672060523
59.168 37.94261744966443 0.07283574632841662
59.168 37.94362416107383 0.07270526312255285
59.168 37.94463087248322 0.07093437583376927
59.168 37.94563758389262 0.07079960102021321
59.168 37.946644295302015 0.07066698156881109
59.168 37.94765100671141 0.07053659605428628
59.168 37.948657718120806 0.07040852514256021
59.168 37.9496644295302 0.07028285166449423
59.168 37.9506711409396 0.07015966070083535
59.168 37.95167785234899 0.07003903968017189
59.168 37.95268456375839 0.06992107849189791
59.168 37.953691275167785 0.06980586961631079
59.168 37.95469798657718 0.06969350827411065
59.168 37.955704697986576 0.0695840925975994
59.168 37.95671140939597 0.06947772382583468
59.168 37.95771812080537 0.0693745065258144
59.168 37.95872483221476 0.06927454884130534
59.168 37.95973154362416 0.06917796277014827
59.168 37.960738255033554 0.06908486446955785
59.168 37.96174496644295 0.06899537458682589
59.168 37.96275167785235 0.06890961860958436
59.168 37.96375838926175 0.06882772722488548
59.168 37.964765100671144 0.06874983666893728
59.168 37.96577181208054 0.0686760890384347
59.168 37.966778523489936 0.06860663251822538
59.168 37.96778523489933 0.06854162145634066
59.168 37.96879194630873 0.06848121618264078
59.168 37.96979865771812 0.0490885595246216
59.168 37.97080536912752 0.04911970547835104
59.168 37.971812080536914 0.049156077479424014
59.168 37.97281879194631 0.049197842948578686
59.168 37.973825503355705 0.04924516167205063
59.168 37.9748322147651 0.04929817667956736
59.168 37.9758389261745 0.049357000330981046
59.168 37.97684563758389 0.04942169336690979
59.168 37.97785234899329 0.049492233657652
59.168 37.978859060402684 0.04956846990492082
59.168 37.97986577181208 0.049650053410976155
59.168 37.980872483221475 0.04973633794394235
59.168 37.98187919463087 0.0036539481851209296
59.168 37.982885906040266 0.0040079487604479
59.168 37.98389261744966 0.004360776151920407
59.168 37.98489932885906 0.004708290036246829
59.168 37.98590604026845 0.005044287083824355
59.168 37.98691275167785 0.0053595756095055555
59.168 37.98791946308725 0.005640663433156903
59.168 37.98892617449665 0.005867922196016152
59.168 37.98993288590604 -0.03398247260677281
59.168 37.99093959731544 -0.03416011603622461
59.168 37.991946308724835 -0.03452094216270285
59.168 37.99295302013423 -0.03514271904013951
59.168 37.993959731543626 -0.03612894825764341
59.168 37.99496644295302 -0.03761129967549744
59.168 37.99597315436242 -0.039745962496967116
59.168 37.99697986577181 -0.04269800041033983
59.168 37.99798657718121 -0.04660730103566917
59.168 37.998993288590604 -0.051534431829312756
59.169000000000004 37.85 -0.044622555531427535
59.169000000000004 37.8510067114094 -0.044622555531427535
59.169000000000004 37.85201342281879 -0.044622555531427535
59.169000000000004 37.85302013422819 -0.044622555531427535
59.169000000000004 37.854026845637584 -0.044622555531427535
59.169000000000004 37.85503355704698 -0.044622555531427535
59.169000000000004 37.856040268456375 -0.044622555531427535
59.169000000000004 37.85704697986577 -0.044622555531427535
59.169000000000004 37.85805369127517 -0.044622555531427535
59.169000000000004 37.85906040268456 -0.044622555531427535
59.169000000000004 37.86006711409396 -0.044622555531427535
59.169000000000004 37.861073825503354 -0.044622555531427535
59.169000000000004 37.86208053691275 -0.044622555531427535
59.169000000000004 37.86308724832215 -0.044622555531427535
59.169000000000004 37.86409395973155 -0.044622555531427535
59.169000000000004 37.865100671140944 -0.044622555531427535
59.169000000000004 37.86610738255034 -0.044622555531427535
59.169000000000004 37.867114093959735 -0.044622555531427535
59.169000000000004 37.86812080536913 -0.044622555531427535
59.169000000000004 37.869127516778526 -0.044622555531427535
59.169000000000004 37.87013422818792 -0.044622555531427535
59.169000000000004 37.87114093959732 -0.04077705901460324
59.169000000000004 37.87214765100671 -0.040765050542603765
59.169000000000004 37.87315436241611 -0.040752900695263966
59.169000000000004 37.874161073825505 0.05139062430075368
59.169000000000004 37.8751677852349 0.05126110315422465
59.169000000000004 37.876174496644296 0.05113166290139283
59.169000000000004 37.87718120805369 0.05100231010382049
59.169000000000004 37.87818791946309 0.05087305158144091
59.169000000000004 37.87919463087248 0.05074389442257167
59.169000000000004 37.88020134228188 0.050614845994274835
59.169000000000004 37.881208053691275 0.08078061974454377
59.169000000000004 37.88221476510067 0.08061931764721937
59.169000000000004 37.883221476510066 0.07037381563619187
59.169000000000004 37.88422818791946 0.07029403015584344
59.169000000000004 37.88523489932886 0.07021399370165769
59.169000000000004 37.88624161073825 0.07013370634951054
59.169000000000004 37.88724832214765 0.07225155182141794
59.169000000000004 37.88825503355705 0.0721723368678422
59.169000000000004 37.88926174496645 0.07209299073163937
59.169000000000004 37.89026845637584 0.07201351275381977
59.169000000000004 37.89127516778524 0.07193390340033438
59.169000000000004 37.892281879194634 0.07185416423511355
59.169000000000004 37.89328859060403 0.07177429789618599
59.169000000000004 37.894295302013425 0.07169430807470785
59.169000000000004 37.89530201342282 0.07161419949674623
59.169000000000004 37.89630872483222 0.07153397790767645
59.169000000000004 37.89731543624161 0.07145365005905484
59.169000000000004 37.89832214765101 0.07137322369782657
59.169000000000004 37.899328859060404 0.0712927075577544
59.169000000000004 37.9003355704698 0.07121211135294354
59.169000000000004 37.901342281879195 0.07113144577334178
59.169000000000004 37.90234899328859 0.0710507224821136
59.169000000000004 37.90335570469799 0.07096995411477507
59.169000000000004 37.90436241610738 0.07088915427998065
59.169000000000004 37.90536912751678 0.07080833756186806
59.169000000000004 37.906375838926174 0.07072751952385414
59.169000000000004 37.90738255033557 0.07064671671377859
59.169000000000004 37.908389261744965 0.07056594667030247
59.169000000000004 37.90939597315436 0.07048522793045833
59.169000000000004 37.910402684563756 0.07040458003824353
59.169000000000004 37.91140939597315 0.0703240235541649
59.169000000000004 37.91241610738255 0.07024358006562254
59.169000000000004 37.91342281879195 0.07016327219802018
59.169000000000004 37.914429530201346 0.07008312362650228
59.169000000000004 37.91543624161074 0.07000315908818786
59.169000000000004 37.91644295302014 0.06992340439478678
59.169000000000004 37.91744966442953 0.06984388644547135
59.169000000000004 37.91845637583893 0.0697646332398697
59.169000000000004 37.919463087248324 0.06968567389103378
59.169000000000004 37.92046979865772 0.06960703863824408
59.169000000000004 37.921476510067116 0.06952875885948992
59.169000000000004 37.92248322147651 0.06945086708345419
59.169000000000004 37.92348993288591 0.06937339700083768
59.169000000000004 37.9244966442953 0.06929638347483107
59.169000000000004 37.9255033557047 0.07561217803075403
59.169000000000004 37.926510067114094 0.07548346278909804
59.169000000000004 37.92751677852349 0.07535561512857913
59.169000000000004 37.928523489932886 0.07522867453737578
59.169000000000004 37.92953020134228 0.07510268166452688
59.169000000000004 37.93053691275168 0.07497767832324087
59.169000000000004 37.93154362416107 0.07485370749154335
59.169000000000004 37.93255033557047 0.07473081331000199
59.169000000000004 37.933557046979864 0.0746090410762302
59.169000000000004 37.93456375838926 0.07448843723585458
59.169000000000004 37.935570469798655 0.07436904936964117
59.169000000000004 37.93657718120805 0.07425092617643153
59.169000000000004 37.937583892617454 0.0741341174515468
59.169000000000004 37.93859060402685 0.07401867406028907
59.169000000000004 37.939597315436245 0.07204561545563988
59.169000000000004 37.94060402684564 0.07192059818297351
59.169000000000004 37.94161073825504 0.07179723042093855
59.169000000000004 37.94261744966443 0.07167556689855024
59.169000000000004 37.94362416107383 0.08352952657535341
59.169000000000004 37.94463087248322 0.0694912588298964
59.169000000000004 37.94563758389262 0.06935395545211402
59.169000000000004 37.946644295302015 0.06921855033579286
59.169000000000004 37.94765100671141 0.06908511600415548
59.169000000000004 37.948657718120806 0.06895372698332183
59.169000000000004 37.9496644295302 0.0688244598627182
59.169000000000004 37.9506711409396 0.06869739336338379
59.169000000000004 37.95167785234899 0.06857260841560066
59.169000000000004 37.95268456375839 0.06845018824747884
59.169000000000004 37.953691275167785 0.06833021848630517
59.169000000000004 37.95469798657718 0.06821278727469338
59.169000000000004 37.955704697986576 0.06809798540375736
59.169000000000004 37.95671140939597 0.06798590646573203
59.169000000000004 37.95771812080537 0.06787664702866593
59.169000000000004 37.95872483221476 0.06777030683593055
59.169000000000004 37.95973154362416 0.06766698903337282
59.169000000000004 37.960738255033554 0.06756680042691432
59.169000000000004 37.96174496644295 0.06746985177318117
59.169000000000004 37.96275167785235 0.06737625810527931
59.169000000000004 37.96375838926175 0.06728613909498946
59.169000000000004 37.964765100671144 0.06719961945117028
59.169000000000004 37.96577181208054 0.06711682935189334
59.169000000000004 37.966778523489936 0.047549067187848196
59.169000000000004 37.96778523489933 0.0475547957995803
59.169000000000004 37.96879194630873 0.047564803627680445
59.169000000000004 37.96979865771812 0.04757924487478202
59.169000000000004 37.97080536912752 0.04759828137643017
59.169000000000004 37.971812080536914 0.04762208279371338
59.169000000000004 37.97281879194631 0.047650826531453264
59.169000000000004 37.973825503355705 0.047684697211980265
59.169000000000004 37.9748322147651 0.047723885452907386
59.169000000000004 37.9758389261745 0.04776858557840642
59.169000000000004 37.97684563758389 0.04781899172041042
59.169000000000004 37.97785234899329 0.04787529151437338
59.169000000000004 37.978859060402684 0.0012906966306766368
59.169000000000004 37.97986577181208 0.0016170810075936265
59.169000000000004 37.980872483221475 0.0019494783927874494
59.169000000000004 37.98187919463087 0.002287877337564234
59.169000000000004 37.982885906040266 -0.035342516205822114
59.169000000000004 37.98389261744966 -0.035158980909406834
59.169000000000004 37.98489932885906 -0.03497280143757415
59.169000000000004 37.98590604026845 -0.03478532025592848
59.169000000000004 37.98691275167785 -0.03459860528127265
59.169000000000004 37.98791946308725 -0.0344157888440281
59.169000000000004 37.98892617449665 -0.034241557920488816
59.169000000000004 37.98993288590604 -0.03408285947040895
59.169000000000004 37.99093959731544 -0.033949908082558516
59.169000000000004 37.991946308724835 -0.0338576109840732
59.169000000000004 37.99295302013423 -0.03382755345476614
59.169000000000004 37.993959731543626 -0.03389070329617824
59.169000000000004 37.99496644295302 -0.03409096686742404
59.169000000000004 37.99597315436242 -0.03448960032866151
59.169000000000004 37.99697986577181 -0.03517013537585309
59.169000000000004 37.99798657718121 -0.0362427410695154
59.169000000000004 37.998993288590604 -0.037845608203110837`;

let dataCO = [];
str.split("\n").forEach(substr => {
    let a = substr.split(' ');
    dataCO.push({
        coordinates: [parseFloat(a[1]), parseFloat(a[0])],
        val: parseFloat(a[2]),
    })
});

export default dataCO;