import axios from "axios";


export default {
    resource: 'none',
    baseUrl: '/api/v1/',

    async load(pageSize = 1000, pageIndex = 0){
        // try {
            let response =  await axios.get(this.baseUrl + this.resource+"/", {
                params:{
                    pageSize,
                    pageIndex,
                }
            })
            return response.data.data
        // } catch (e) {
        //     console.error(e)
        //     return false
        // }
    },

    async loadItem(id){
        try {
            let response = await axios.get(`${this.baseUrl}${this.resource}/${id}`)
            return response.data
        } catch (e) {
            console.error(e)
            return false
        }
    },

    async create(itemData, onError){
        try {
            return await axios.post(this.baseUrl + this.resource + "/", itemData)
        } catch (e) {
            console.error(e)
            if(onError) onError(e)
            return false
        }
    },

    async update(id, itemData, onError){
        try {
            itemData.id = id;
            return await axios.put(`${this.baseUrl}${this.resource}/`, itemData)
        } catch (e) {
            console.error(e)
            if(onError) onError(e)
            return false
        }
    },

    async delete(id, onError){
        try {
            return await axios.delete(`${this.baseUrl}${this.resource}/${id}`)
        } catch (e) {
            console.error(e.response)
            if(onError) onError(e)
            return false
        }
    },

}
