import axios from 'axios'
import base from './_base'

let api = Object.assign({}, base)

api.resource = 'MailGroups'

api.update = async (id, itemData, onError) => {
    try {
        itemData.id = id;
        return await axios.put(`${api.baseUrl}${api.resource}/${id}`, itemData)
    } catch (e) {
        console.error(e)
        if(onError) onError(e)
        return false
    }
}

api.load = async () => {
    let response =  await axios.get(api.baseUrl + api.resource+"/")
    return response.data
};

export default api