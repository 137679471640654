<template>
    <div style="display: none" class="in-dev-info__bg">
        <div class="in-dev-info__text">
            Раздел в процессе разработки
        </div>
    </div>
</template>

<script>
import dataCO from "../../../testData";
import {forEach} from "lodash";
import {gasLabelColor} from "../../../other";

let circles = [];
export default {
    components: {},

    data() {
        return {

        }
    },

    mounted() {
        circles.forEach(circle => {
            circle.destroy();
        })
        circles = [];

        dataCO.forEach(d => {
            circles.push(new mapgl.Circle(this.$map, {
                coordinates: d.coordinates,
                radius: 56,
                color: gasLabelColor('NO', d.val) + '55',
                strokeWidth: 0,
            }));
        })
    },

    computed: {},

    methods: {},
}
</script>