<template>
<div class="wind-dir" title="">
    <div class="wind-labal wind-label__N">С</div>
    <div class="wind-labal wind-label__S">Ю</div>
    <div class="wind-labal wind-label__E">В</div>
    <div class="wind-labal wind-label__W">З</div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"
         class="wind-arrow" :style="`transform: translateX(-50%) translateY(-50%) rotate(${dir}deg)`">
        <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z"/>
    </svg>
</div>
</template>

<style lang="scss">
$s: 50px;
.wind-dir{
    width: $s;
    height: $s;
    position: relative;
    margin: 10px;
}

.wind-arrow{
    position: absolute;
    left: $s/2;
    top: $s/2;
    width: 20px;
    path{
        fill: #004fb3;
    }
}

.wind-labal{
    position: absolute;
    color: #7f7f7f;
    font-size: 13px;
    font-weight: 500;
}
.wind-label__N {
    top: 0;
    left: $s/2;
    transform: translateX(-50%);
}
.wind-label__S {
    bottom: 0;
    left: $s/2;
    transform: translateX(-50%);
}
.wind-label__W {
    top: $s/2;
    left: 0;
    transform: translateY(-50%);
}
.wind-label__E {
    top: $s/2;
    right: 0;
    transform: translateY(-50%);
}

</style>

<script>
export default {
    props: {
        dir: Number,
    },
}
</script>