import Vue from 'vue'
import Vuex from 'vuex'
import projects from './modules/projects'
import roles from './modules/roles'
import mailGroups from './modules/mailGroups'
import devices from './modules/devices'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

import {
    USER_LOGGED,
    DATA_LOADED,
    USER_SET,
} from "./mutations";

import userApi from '../api/user'

export default new Vuex.Store({
    modules: {
        projects,
        roles,
        mailGroups,
        devices,
    },

    state: {
        user: { },
        loggedIn: false,
        dataLoaded: false,
        mapAddGroupMode: false,
        project: null,
    },

    mutations:{
        [USER_SET](state, payload){
            state.user = payload
        },
        [USER_LOGGED](state, payload){
            state.loggedIn = payload
        },
        [DATA_LOADED](state, payload){
            state.dataLoaded = payload
        },
    },

    getters:{

    },

    actions:{
        getAuthUser({commit, dispatch}){
            return userApi.getCurrentUser((userData)=>{
                commit(USER_LOGGED, true)
                commit(USER_SET, userData)
            }, ()=>{
                commit(USER_LOGGED, false)
            })
        },

        async logout({commit}){
            try{
                await userApi.logout()
                commit(USER_LOGGED, false)
                commit(USER_SET, {})
                window.location.reload()
            } catch (err) {
                console.error(err)
            }
        },

    },

    strict: debug,
})
