<template>
    <my-block
            :width="600"
            :height="400"
            :top="40"
            :left="250" title="Добавление устройств"
            @close="$emit('close', true)">

        <template v-slot:default>
            <div class="upload-row">
                <pe-input v-model="serials" style="flex-grow: 1; margin-right: 10px"
                          type="text" placeholder="Введите ID устройств" autocomplete="off"></pe-input>

                <button class="btn"
                        :class="{inactive: !serials.length}"
                        @click="addDevs"
                        style="margin-top: 15px"
                >Добавить</button>
            </div>

            <div style="position: relative">
                <table v-if="uploadedDevs.length" class="table newDevsTbl" style="position: sticky; top: 0;">
                    <thead>
                    <tr>
                        <td :style="`width: ${tblWidth.serial}px`">ID</td>
                        <td>Статус</td>
                        <td :style="`width: ${tblWidth.del}px`"></td>
                    </tr>
                    </thead>
                </table>
                <table v-if="uploadedDevs.length" class="table newDevsTbl">
                    <tbody>
                    <tr v-for="(d, k) in uploadedDevs">
                        <td :style="`width: ${tblWidth.serial}px`">{{d.serial}}</td>

                        <td>
                            <div v-if="d.added" class="add-status">
                                <img src="/images/icons/ok-r.svg">
                                Добавлено
                            </div>
                            <div v-else class="add-status">
                                <img src="/images/icons/error.svg">
                                Не добавлено: {{d.uploadError}}
                            </div>
                        </td>

                        <td :style="`width: ${tblWidth.del}px`">
                            <img class="ico-btn-color" src="/images/icons/minus-r-g.svg" @click="delDev(k)">
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </template>

        <template v-slot:bottom-buttons>
            <button  @click="closeEvent" class="btn green narrow" style="width: 140px">Закрыть</button>
        </template>

    </my-block>
</template>

<style lang="scss" scoped>
    .upload-row{
        display: flex;
        align-items: flex-start;
    }
</style>

<style lang="scss">
    .newDevsTbl{
        width: 100%;
        border-collapse: collapse;
        thead td{
            font-size: 12px;
            line-height: 16px;
            color: #3c3a3a;
            border: none;
        }
        td{
            color: #000000;
            font-size: 15px;
            border-spacing: 0;
            /*padding: 12px 15px;*/
            padding: 5px 7px;
            vertical-align: middle;
            border: 1px solid #adacac;
            border-left: none;
            border-right: none;

            &:last-of-type{
                border: none;
            }
        }

        .error-msg{
            font-size: 14px;
        }

        .no-r-b{
            border-right: none;
        }

        .add-status{
            img{
                padding-right: 10px;
            }
        }
    }
</style>

<script>
    import MyBlock from './../../../../components/responsiveBlock'
    import peInput from "../../../../components/peInput";
    import devicesApi from "../../../../api/devices";

    export default {

        props: {
            project: Object,
        },

        components:{
            peInput,
            MyBlock,
        },

        data(){
            return {
                serials: '',
                uploadedDevs: [
                    /*{
                        serial: '12345',
                        added: true,
                        uploadError: '',
                        status: 'offline',
                    }*/
                ],
                tblWidth:{
                    serial: 220,
                    del: 16,
                }
            }
        },

        methods:{
            closeEvent(){
                this.$emit('close', true)
            },

            async addDevs(){
                if(this.serials){
                    let devs = this.serials.replace(/ +/g, ' ').split(' ');
                    for (const serial of devs) {
                        try {
                            //55.777968, 37.496857
                            //55.793514, 37.529265
                            // let defaultLat = 55.753936;
                            // let defaultLng = 37.622152;
                            let {lng, lat} = this.$map.getCenter();
                            // console.log(this.$map.getCenter())
                            let lngD = .025, latD = .015;
                            let dev  = await devicesApi.addToProject(this.project.id, {
                                projectId: this.project.id,
                                serial: serial,
                                latitude: lat + ( -latD* 0.5 + Math.random() * latD),
                                longitude: lng + ( -lngD* 0.5 + Math.random() * lngD),
                            })
                            this.uploadedDevs.push({
                                id: dev.id,
                                serial: dev.serial,
                                added: true,
                                uploadError: '',
                            })
                            setTimeout(()=>{
                                this.$store.dispatch('devicesLoad', this.project.id)
                            }, 3000)
                        } catch (e) {
                            if(e.response.data && e.response.data.error){
                                this.uploadedDevs.push({
                                    serial: serial,
                                    added: false,
                                    uploadError: e.response.data.error,
                                })
                            }
                        }
                    }
                }
            },

            async delDev(index){
                let d = this.uploadedDevs[index];
                if(d.added){
                    await devicesApi.delete(d.id);
                }
                this.uploadedDevs.splice(index, 1);
            }
        }
    }
</script>