<template>
    <div>
        <div id="side-menu">
            <div class="side-menu__item"
                 @click="$to('projects-add')"
                 v-if="projectsCount">
                <div style="padding-left: 15px; color: #0077FF;">Добавить проект</div>
            </div>
        </div>

        <div class="center-content projects-list">
            <template v-if="projectsCount">
                <project-item v-for="p in projects" :project="p" :key="p.id"></project-item>
            </template>
            <template v-else>
                <div class="block" style="text-align: center; font-size: 18px; width: 300px">
                    <div style="font-size: 20px; margin-bottom: 15px">Объектов нет</div>
                    <router-link :to="`/projects/add`">Добавьте объект</router-link>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import projectItem from './list-item'
import demo from '../../demo'

export default {

    components: {
        projectItem,
        demo,
    },

    data() {
        return {
            /*projects: [
                {
                    id: 1,
                    name: 'Проект 1',
                    city: 'Москва',
                    online: true,
                    errorsCount: 4,
                    timeZoneId: 'Europe/Moscow',
                },
                {
                    id: 2,
                    name: 'Проект 2',
                    city: 'Могадиш',
                    online: false,
                    errorsCount: 24,
                    timeZoneId: 'Atlantic/Azores',
                },
            ],*/
        }
    },

    computed: {
        ...mapState({
            projects: state => state.projects.all,
            projectsCount: state => state.projects.count,
        })
    },

    methods: {},

    mounted() {
        this.$store.dispatch('projectsLoad')
    }
}
</script>