<template>
    <div class="block project-item">
        <div class="header">

            <router-link class="title" :to="`/projects/${project.id}`">
                {{ project.name }}
                <div style="color: #7f7f7f">{{ project.city }}</div>
            </router-link>

            <div class="clocks">
                <div class="clocks-text">
                    {{ clocks.time }}<br>
                    {{ clocks.date }}
                </div>
            </div>

            <router-link :to="`/projects/${project.id}/edit`" class="edit-link">
                <img v-if="showEdit" class="ico-btn" src="/images/icons/edit.svg">
            </router-link>
        </div>

        <div class="project-list-bottom">
            <div class="dev-status" v-if="devCount > 0">
                <div class="s-ico">
                    <div class="online"></div>
                </div>
                <div class="s-title">{{devCount}} {{$plural(devCount, 'устройство', 'устройства', 'устройств')}}</div>
            </div>
            <div class="dev-status" v-else>
                <div class="s-ico">
                    <div class="offline"></div>
                </div>
                <div class="s-title">Нет устройств</div>
            </div>
        </div>

    </div>
</template>

<style>
.dev-status-errors {
    display: flex;
    align-items: center;
}
</style>

<script>
import moment from 'moment-timezone'
import apiDevs from '../../../api/devices'
import {AccessRoles} from "../../../config";

export default {

    props: {
        project: Object,
    },

    data() {
        return {
            clocks: {
                time: '10:00',
                date: '15.05.2020',
            },
            timer: false,
            devCount: 0,
        }
    },

    methods: {
        refreshTime() {
            let m = moment();
            this.clocks.time = m.tz(this.project.timeZoneId).format('HH:mm')
            this.clocks.date = m.tz(this.project.timeZoneId).format('DD.MM.YYYY')
        }
    },

    computed:{
        showEdit(){
            if(this.$store.state.user?.role === 'Administrator'){
                return true;
            }

            let user = this.$store.state.user;
            if(!user || !this.project){
                return false;
            }

            if(user.email === this.project.owner){
                return true;
            }

            if(user.projects) for(let el of user.projects){
                if(el.projectId == this.project.id && el.access === AccessRoles.edit){
                    return true;
                }
            }

            return false;
        },
    },

    async mounted() {
        this.refreshTime()
        this.timer = setInterval(() => {
            this.refreshTime()
        }, 10000);

        let devs = await apiDevs.loadByProject(this.project.id);
        this.devCount = devs.length;
    },

    beforeDestroy() {
        clearInterval(this.timer)
    }
}

</script>