import axios from 'axios'

export default {
    async load(serial, param = null, pageSize = 2000, pageIndex = 0){
        let params = {
            serial,
            pageSize,
            pageIndex,
        };
        let response =  await axios.get('/api/history', { params })
        return response.data.data
    },

    async loadByParam(serial, param, pageSize = 2000, pageIndex = 0){
        let response =  await axios.get('/api/history/' + param, {
            params:{
                serial,
                pageSize,
                pageIndex,
            }
        })
        return response.data.data
    },
}