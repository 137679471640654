import Vue from 'vue'
import {
    ROLES_SET,
    ROLE_UPDATE_PROP,
} from '../mutations'

import {forEach} from 'lodash'
import api from '../../api/roles'

const state = {
    all: {},
}

// getters
const getters = {

}

// actions
const actions = {
    async rolesLoad({commit, dispatch}){
        let data = await api.load();
        let roles = [];
        data.forEach((r)=>{
            let rights = []; //"Project", "Rights", "MailGroups", "Notify", "Monitor"
            r.claims.forEach((c)=>{
                rights.push(c.type)
            });
            roles.push({
                id: r.role.id,
                name: r.role.name,
                rights,
            })
        })
        commit(ROLES_SET, roles);
    },
}

// mutations
const mutations = {
    [ROLES_SET] (state, items) {
        state.all = {}
        forEach(items, d => {
            Vue.set(state.all, d.id, d)
        })
    },

    [ROLE_UPDATE_PROP] (state, {id, prop, val}) {
        Vue.set(state.all[id], prop, val)
    },

}


export default {
    // namespaced: true,
    state,
    getters,
    actions,
    mutations
}
