import axios from 'axios'

import base from './_base'

let api = Object.assign({}, base)

api.resource = 'infra/projects'

api.loadTimezones = async (pageSize = 1000, pageIndex = 0) => {
    let response = await axios.get(`${api.baseUrl}/infra/timezones`,{
        params:{
            pageSize,
            pageIndex,
        }
    })
    return response.data.data;
}


export default api