export const settings = {
    mapboxApiKey: 'pk.eyJ1IjoiYXNzeHh4IiwiYSI6ImNrZjJwZzBwazAwcGkydXFxY3NvNzlvaTUifQ.-EBvRg1m_6kcjuIzTu3opw',
    dadataApiKey: 'd77838b33c884e896a714982a24f81bbcbcc8c4f',
    defaultTimezone: 'Europe/Moscow',
}

export const devStates = {
    offline: 'Offline',
    online: 'Online',
    upgrading: 'Upgrading',
    unknown: 'Unknown'
}

export const mqttSettings = {
    host: 'mqtt.pelena.cloud',
    port: 8083
}


export const AccessRoles = {
    view: 'View',
    edit: 'Edit'
}