<template>
    <div class="block" id="restore-pwd-form">

        <h3>Восстановление пароля</h3>

        <div v-if="send" id="info-send">На указанный email отправлено письмо со ссылкой для восстановления пароля.</div>

        <form v-else @submit.prevent="sendData">
            <pe-input v-model="email" type="text" :error="errors.email"
                      placeholder="Введите email, который вы указывали при регистрации "></pe-input>
            <button type="submit" class="btn">Далее</button>
        </form>

    </div>
</template>

<style lang="scss" scoped>
    #restore-pwd-form {
        margin: 50px auto;
        width: 600px;
        box-sizing: border-box;

        .btn {
            margin-top: 40px;
            width: 100%;
        }
    }

    h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        margin: 10px 0 40px 0;
    }
</style>

<script>

    import peInput from "../../components/peInput";
    import axios from 'axios'
    // import {setErrors} from "../../other";

    export default {

        components: {
            peInput,
        },

        data() {
            return {
                send: false,
                errors: {
                    email: null,
                },
                email: null,
            }
        },

        methods: {
            sendData() {
                axios.post('/api/account/reset/' + encodeURI(this.email), {email: this.email}).then((response) => {
                    this.send = true
                }).catch((err) => {
                    // setErrors(this.errors, err.response.data)
                    this.errors.email = 'Пользователь с уазаным email не найден'
                })
            }
        }
    }
</script>