import Vue from 'vue'
import {
    DEVICE_SET,
    DEVICES_SET,
    DEVICE_UPDATE_PROP,
    DEVICE_UPDATE_STATUS, DEVICE_SHOW_INFO_POPUP,
} from '../mutations'

import {each} from 'lodash'
import api from '../../api/devices'

const state = {
    all: {},
    showInfoDevId: null,
}

// getters
const getters = {

}


// actions
const actions = {
    async devicesLoad({commit, dispatch}, projectId){
        try {
            let data = await api.loadByProject(projectId);
            commit(DEVICES_SET, data);
        } catch (e) {
            console.error(e)
            if(e.response.status === 401){
                dispatch('logout')
            }
        }
    },

    async deviceReload({commit}, devId) {
        let dev = await api.loadItem(devId);
        commit(DEVICE_SET, dev);
    }
}

// mutations
const mutations = {
    [DEVICES_SET] (state, items) {
        state.all = {}
        each(items, d => {
            if(d.descriptions === undefined){
                d.descriptions = {};
            }
            Vue.set(state.all, d.id, d)
        })
    },

    [DEVICE_SET] (state, dev) {
        if(dev.descriptions === undefined){
            dev.descriptions = {};
        }
        Vue.set(state.all, dev.id, dev)
    },

    [DEVICE_SHOW_INFO_POPUP] (state, devId) {
        state.showInfoDevId = devId;
    },

    [DEVICE_UPDATE_PROP] (state, {id, prop, val}) {
        Vue.set(state.all[id], prop, val)
    },

    [DEVICE_UPDATE_STATUS] (state, {serial, status}) {
        each(state.all, d => {
            if(d.serial === serial || d.nodeSerial === serial){
                Vue.set(d, 'state', status)
            }
        })
    },
}


export default {
    // namespaced: true,
    state,
    getters,
    actions,
    mutations
}
