<template>
    <div class="pe-input" :class="{'error': error, 'success': success}">
        <input :type="fType"
               :value="value"
               :tabindex="tabIndex"
               :min="min"
               :max="max"
               :disabled="disabled"
               @input="change($event)"
               @keyup="$emit('keyup', $event)"
               @keydown="$emit('keydown', $event)"
               @focus="$emit('focus', $event); focused = true"
               @blur="$emit('blur', $event); focused = false"
               >
        <div v-if="!hideLabel" class="pe-input-label" :class="{active: focused || value !== ''}">{{placeholder}}</div>
        <div class="show-pwd" v-if="type === 'password'">
            <img v-if="showPwd" @click="showPwd = !showPwd" src="/images/icons/eye-cross.svg">
            <img v-else @click="showPwd = !showPwd" src="/images/icons/eye.svg">
        </div>
<!--        <div class="ico-success"><img src="/images/icons/ok.svg"></div>-->
<!--        <div class="ico-error"><img src="/images/icons/cross.svg"></div>-->
        <div class="err-hint" v-if="!autoHideError || error"><span v-if="error">{{error}}</span></div>
    </div>
</template>


<script>
    export default {
        props: [
            'value',
            'type',
            'placeholder',
            'error',
            'success',
            'autoHideError',
            'tabIndex',
            'min',
            'max',
            'hideLabel',
            'disabled'
        ],

        data() {
            return {
                showPwd: false,
                focused: false,
            }
        },

        computed: {
            fType(newVal, oldVal) {
                if (this.type === 'password') {
                    return this.showPwd ? 'text' : 'password'
                } else {
                    return this.type
                }
            }
        },

        /*mounted() {
            setTimeout(()=>{
                this.$forceUpdate();
            }, 100)
        },*/

        methods: {
            change(e) {
                this.$emit('input', e.target.value)
                this.$emit('change', e.target.value)
            },
        }
    }
</script>
