<template>
    <div id="login-form" class="block">

        <div class="tabs-h">
            <div class="tab-t h3" :class="{active: tab === 'auth'}" @click="tab = 'auth'">Авторизация</div>
            <div class="tab-t h3" :class="{active: tab === 'reg'}" @click="tab = 'reg'">Регистрация</div>
        </div>

        <div class="tab-content" v-if="tab === 'auth'">
            <form autocomplete="off" @submit.prevent="sendDataAuth">
                <pe-input v-model="form.auth.data.email" :error="form.auth.errors.email"
                          type="email" placeholder="Email"></pe-input>

                <pe-input v-model="form.auth.data.password" :error="form.auth.errors.password"
                          type="password" placeholder="Пароль"></pe-input>

                <div class="forgot">
                    <router-link to="/login/restore">Не помню пароль</router-link>
                </div>

                <button type="submit" class="btn">Авторизироваться</button>
            </form>
        </div>

        <div class="tab-content" v-if="tab === 'reg'">
            <form autocomplete="off" @submit.prevent="sendDataReg">

                <pe-input v-model="form.reg.data.email" :error="form.reg.errors.email"
                          type="email" placeholder="Email" autocomplete="off"></pe-input>

                <pe-input v-model="form.reg.data.password" :error="form.reg.errors.password"
                          type="password" placeholder="Пароль"
                          autocomplete="off"></pe-input>

                <button type="submit" class="btn">Зарегистрироваться</button>
            </form>
            <div class="agree-text">
                Регистрируясь, вы соглашаетесь с
                <a href="#">пользовательским соглашением</a> и
                <a href="#">политикой конфиденциальности</a>
            </div>
        </div>

    </div>
</template>

<style lang="scss" scoped>
    #login-form {
        margin: 50px auto;
        width: 480px;
        box-sizing: border-box;
        overflow: hidden;

        .btn {
            margin-top: 25px;
            width: 100%;
        }

        .agree-text {
            margin-top: 18px;
            color: #787C84;
            font-size: 14px;
            line-height: 19px;
        }

        .forgot {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            margin-top: 18px;
        }
    }

    .tabs-h {
        display: flex;
        margin: -40px -40px 40px -40px;
    }

    .tab-t {
        color: #B7BAC0;
        text-align: center;
        flex-grow: 1;
        flex-shrink: 0;
        width: 50%;
        height: 68px;
        line-height: 68px;
        box-sizing: border-box;
        cursor: pointer;
        transition: color 300ms;
        box-shadow: inset 0 0 17px rgba(0, 0, 0, 0.05);

        &.active {
            color: #464B56;
            box-shadow: none;
        }
    }
</style>

<script>

    import peInput from "../../components/peInput";
    import axios from "axios";
    import { USER_LOGGED } from "../../store/mutations";
    import {setErrors} from "../../other";

    export default {

        components: {
            peInput,
        },

        data() {
            return {
                tab: 'auth',
                form: {
                    reg: {
                        data: {
                            email: '',
                            password: '',
                            userName: '',
                        },
                        errors: {
                            email: '',
                            password: '',
                            userName: '',
                        },
                    },
                    auth: {
                        data: {
                            email: '',
                            password: '',
                        },
                        errors:{
                            email: '',
                            password: '',
                        }
                    },
                }
            }
        },

        methods: {

            async sendDataAuth() {
                try{
                    let data = {
                        email: this.form.auth.data.email,
                        password: this.form.auth.data.password,
                        rememberMe: true,
                    }
                    await axios.post('/api/account/login', data)
                    this.$store.commit(USER_LOGGED, true)
                    await this.$store.dispatch('getAuthUser')
                    /*if(response.headers.authorization !== undefined){
                        window.localStorage.setItem('authorization', response.headers.authorization)
                        axios.defaults.headers.common['Authorization'] = response.headers.authorization
                    }*/
                    // this.$to('begin')
                    this.$to('projects')
                } catch(err) {
                    if(err.response.status === 401){
                        this.form.auth.errors.password = 'Неверный логин/пароль'
                    } else {
                        setErrors(this.form.auth.errors, err.response.data)
                    }
                }
            },

            async sendDataReg() {
                try{
                    let data = {
                        email: this.form.reg.data.email,
                        password: this.form.reg.data.password,
                        confirmPassword: this.form.reg.data.password,
                        userName: this.form.reg.data.email,
                    }
                    let response = await axios.post('/api/account', data)
                    if(response.status === 200){ // если вдруг таки будет не 200 на успешный ответ
                        this.$to('reg-done')
                    }
                }
                catch(err){
                    setErrors(this.form.reg.errors, err.response.data)
                }
            },
        }
    }
</script>