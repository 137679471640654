import axios from 'axios'

export default {

    logout(){
        return axios.post('/api/account/logout')
    },

    getCurrentUser(onDone, onError){
        return axios.get('/api/account/profile').then((req)=>{
            let data = {
                email: req.data.email,
                projects: req.data.projects,
                role: req.data.role,
                // rights: [],
            };
            // if(req.data.projects) req.data.projects.forEach((c)=>{
            //     data.rights.push(c.type)
            // })
            onDone(data)
        }).catch((req)=>{
            onError(req);
        })
    }
}