<template>
    <div style="position: relative; width: 100%; height: 100%; pointer-events: none;">

        <div class="moreInfo" v-if="devToShow">
            <div class="moreInfo__close" @click="closeDevPopup()">
                <img src="/images/icons/close.svg">
            </div>

            <div class="moreInfo__title">
                {{devToShow.title ?? ''}} <br>
                <span style="color: #7f7f7f">{{devToShow.serial}}</span>
            </div>

            <template  v-if="devToShow?.data?.gas?.timeStamp">
                <div class="moreInfo-row__title">
                    <div class="moreInfo-row__title-title">Газ</div>
                    <div class="moreInfo-row__title-date">{{toDate(devToShow.data.gas.timeStamp)}}</div>
                </div>

                <div class="moreInfo-row" v-for="s in devToShow.data.gas.sensors">
                    <div class="moreInfo-row__substance" @click="chart.show = true; chart.param = 'gas' + s.substanceCode">{{s.substanceCode}}</div>
                    <div class="moreInfo-row__concentration"
                         :style="'color: ' + gasLabelColor(s.substanceCode, s.value)">
                        {{gasFormatValue(s.substanceCode, s.value)}} PPM
                    </div>
                    <div class="moreInfo-row__chart" @click="showChart(s.substanceCode, 'PPM', 'gas')">
                        <i class="fa-solid fa-chart-line"></i>
                    </div>
                </div>
            </template>

            <template v-if="devToShow?.data?.dust?.timeStamp">
                <div class="moreInfo-row__title">
                    <div class="moreInfo-row__title-title">Пыль</div>
                    <div class="moreInfo-row__title-date">{{toDate(devToShow.data.dust.timeStamp)}}</div>
                </div>

                <div class="moreInfo-row">
                    <div class="moreInfo-row__substance">PM2.5</div>
                    <div class="moreInfo-row__concentration">
                        {{$round(devToShow.data.dust.pm2Concentration, 3)}}
                        мг/м<sup>3</sup>
                    </div>
                    <div class="moreInfo-row__chart" @click="showChart('Pm2', 'мг/м3', 'dust')">
                        <i class="fa-solid fa-chart-line"></i>
                    </div>
                </div>

                <div class="moreInfo-row">
                    <div class="moreInfo-row__substance">PM10</div>
                    <div class="moreInfo-row__concentration">{{$round(devToShow.data.dust.pm10Concentration, 3)}}
                        мг/м<sup>3</sup>
                    </div>
                    <div class="moreInfo-row__chart" @click="showChart('Pm10', 'мг/м3', 'dust')">
                        <i class="fa-solid fa-chart-line"></i>
                    </div>
                </div>

                <div class="moreInfo-row">
                    <div class="moreInfo-row__substance">TSP</div>
                    <div class="moreInfo-row__concentration">
                        {{$round(devToShow.data.dust.tspConcentration, 3)}}
                        мг/м<sup>3</sup>
                    </div>
                    <div class="moreInfo-row__chart" @click="showChart('Tsp', 'мг/м3', 'dust')">
                        <i class="fa-solid fa-chart-line"></i>
                    </div>
                </div>
            </template>


            <template v-if="devToShow?.data?.meteo?.timeStamp">
                <div class="moreInfo-row__title">
                    <div class="moreInfo-row__title-title">Метео</div>
                    <div class="moreInfo-row__title-date">{{toDate(devToShow.data.meteo.timeStamp)}}</div>
                </div>

                <div class="moreInfo-row">
                    <div class="moreInfo-row__substance">
                        <img class="title-data__icon" src="/images/icons/meteo/temp.svg" style="height: 32px">
                    </div>
                    <div class="moreInfo-row__concentration" style="color: #3c3a3a">
                        {{$round(devToShow.data.meteo.airTemp, 1)}}℃
                    </div>
                    <div class="moreInfo-row__chart" @click="showChart('temperature', '℃')">
                        <i class="fa-solid fa-chart-line"></i>
                    </div>
                </div>

                <div class="moreInfo-row">
                    <div class="moreInfo-row__substance">
                        <img class="title-data__icon" src="/images/icons/meteo/hum.svg">
                    </div>
                    <div class="moreInfo-row__concentration" style="color: #3c3a3a">
                        {{$round(devToShow.data.meteo.airHum, 1)}}%
                    </div>
                    <div class="moreInfo-row__chart" @click="showChart('humidity', '%')">
                        <i class="fa-solid fa-chart-line"></i>
                    </div>
                </div>

                <div class="moreInfo-row">
                    <div class="moreInfo-row__substance">
                        <img class="title-data__icon" src="/images/icons/meteo/press.svg">
                    </div>
                    <div class="moreInfo-row__concentration" style="color: #3c3a3a">
                        {{$round(devToShow.data.meteo.atmPress, 0)}}мм. рт. ст.
                    </div>
                    <div class="moreInfo-row__chart" @click="showChart('pressure', 'мм. рт. ст.')">
                        <i class="fa-solid fa-chart-line"></i>
                    </div>
                </div>

                <div class="moreInfo-row">
                    <div class="moreInfo-row__substance">
                        <img class="title-data__icon" src="/images/icons/meteo/wind.svg">
                    </div>
                    <div class="moreInfo-row__concentration" style="color: #3c3a3a">
                        {{$round(devToShow.data.meteo.horWinSpd, 1)}}м/с,
                        {{degreesToDirection(devToShow.data.meteo.horWinDir)}}
                    </div>
                    <div class="moreInfo-row__chart" @click="showChart('horizontalWindSpeed', 'м/с')">
                        <i class="fa-solid fa-chart-line"></i>
                    </div>
                </div>

            </template>

        </div>

        <div id="dev-serial-tt"></div>

        <subs-chart v-if="chart.show"
                    :param="chart.param"
                    :dev-serial="devToShow.serial"
                    :title="`Показания для ${devToShow.title} по параметру ${chart.paramTitle}`"
                    :y-units="chart.yUnits"
                    @close="chart.show = 0"
        ></subs-chart>
    </div>
</template>

<style lang="scss">
@import "src/scss/vars";

$pV: 10px;
$pH: 15px;

.moreInfo{
    background: #FFFFFF;
    border-radius: 5px;
    position: absolute;
    left: 228px;
    top: 10px;
    width: 240px;
    font-size: 16px;
}

.moreInfo__close{
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
    img{
        width: 16px;
    }
}

.moreInfo__title{
    color: #4c5970;
    border-bottom: 2px solid #7C94B0;
    padding: $pV $pH + 15px $pV $pH;
    font-size: 15px;
}

.moreInfo-row__title{
    padding: 15px $pH 0 $pH;
    display: flex;
    align-items: center;
}

.moreInfo-row__title-title{
    flex-grow: 1;
}
.moreInfo-row__title-date{
    font-size: 13px;
    color: #808080;
}

.moreInfo-row{
    border-bottom: 1px solid rgba(146, 171, 199, 0.67);
    display: flex;
    align-items: center;
    padding: 7px $pH;
}

.moreInfo-row__substance{
    color: $colorBlack;
    font-weight: 600;
    width: 60px;
}
.moreInfo-row__concentration{
    color: rgb(37, 155, 84);
    flex-grow: 1;
}
.moreInfo-row__chart{
    height: 16px;
    color: #004bb6;
    cursor: pointer;
    transition: opacity 200ms;
    &:hover{
        opacity: 0.6;
    }
}

</style>

<script>
import {
    drawDevicesNoGroup,
    gasFormatValue,
    gasLabelColor,
} from "../../../other";

import subsChart from "../components/subs-chart.vue";
import {DEVICE_SHOW_INFO_POPUP} from "../../../store/mutations";
import {th} from "date-fns/locale";

let refresher;

export default {
    components: {
        subsChart,
    },

    data() {
        return {
            // devToShow: null,
            chart: {
                show: false,
                param: null,
                paramTitle: null,
                yUnits: '',
            }
        }
    },

    mounted() {
        drawDevicesNoGroup(this.$map, this.$store.state.devices.all, this.$store, this.$hasAccess(this.project)); //без этого не хочет отрисовывать.
        // this.redrawDevices();
        if(this.devToShow){
            document.getElementById('map-marker-' + this.devToShow.id).classList.add('selected');
        }
    },

    watch: {
        devices(){
            this.redrawDevices(false);
        },
        devToShow(newVal, old){
            if(newVal === null){
                document.getElementById('map-marker-' + old.id).classList.remove('selected');
            }
        }
    },

    destroyed() {
        clearInterval(refresher);
        document.querySelectorAll('.map-marker').forEach((el) => el.classList.remove('selected'));
    },

    methods: {

        toDate(ts){
            let d = new Date(ts);
            return d.toLocaleString('ru-RU');
        },

        gasFormatValue,
        gasLabelColor,

        degreesToDirection(degrees) {
            const directions =  ['С', 'ССВ', 'СВ', 'ВСВ', 'В', 'ВЮВ', 'ЮВ', 'ЮЮВ', 'Ю', 'ЮЮЗ', 'ЮЗ', 'ЗЮЗ', 'З', 'ЗСЗ', 'СЗ', 'ССЗ'];
            const index = Math.round(degrees / 22.5);
            return directions[index % 16];
        },

        redrawDevices(fitMap = false){
            drawDevicesNoGroup(this.$map, this.$store.state.devices.all, this.$store, fitMap);
        },

        showChart(param, yUnits, prefix = ''){
            //Temperature,Humidity,Pressure,HorizontalWindSpeed,HorizontalWindDirection,DustPm1,DustPm2,DustPm10,DustTsp,GasNO2,GasO3,GasNO,GasSO2,GasCO,GasSampleTemperature,GasSampleHumidity,GasBoardTemperature
            let titleMap = {
                'temperature': 'Температура',
                'humidity': 'Влажность',
                'pressure': 'Давление',
                'horizontalWindSpeed': 'Скорость ветра',
                'horizontalWindDirection': 'Направление ветра',
            }
            this.chart.paramTitle = param;
            this.chart.param = prefix + param;
            this.chart.show = true;
            this.chart.yUnits = yUnits;
        },

        closeDevPopup(){
            this.$store.commit(DEVICE_SHOW_INFO_POPUP, null);
        }
    },

    computed: {
        project() {
            return this.$store.state.projects.all[this.$route.params.projectId];
        },

        devices(){
            return this.$store.state.devices.all;
        },

        devToShow() {
            return  this.$store.state.devices.all[this.$store.state.devices.showInfoDevId] ?? null;
        }
    },

}
</script>