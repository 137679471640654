<template>
    <div class="pe-input pe-select" :class="{'error': error, 'success': success}">
        <select
                :value="value" @input="change($event)"
                :tabindex="tabIndex"
                @focus="$emit('focus', $event); focused = true"
                @blur="$emit('blur', $event); focused = false"
                :disabled="disabled"
        >
            <template v-if="Array.isArray(options)">
                <option v-for="v in options" :value="v">{{v}}</option>
            </template>
            <template v-else>
                <option v-for="(v, k) in options" :value="k">{{v}}</option>
            </template>
        </select>
        <div class="pe-input-label" :class="{active: focused || value}">{{placeholder}}</div>
        <div class="ico-success"><img src="/images/icons/ok.svg"></div>
        <div class="ico-error"><img src="/images/icons/cross.svg"></div>
        <div class="err-hint" v-if="error">{{error}}</div>
    </div>
</template>


<script>
    export default {
        props: ['value', 'options', 'placeholder', 'error', 'success', 'tabIndex', 'disabled'],

        data() {
            return {
                showPwd: false,
                focused: false,
            }
        },

        computed:{
            optionList(){
                let obj = {}
                if(Array.isArray(this.options)){
                    this.options.forEach((el) => {
                        obj[el] = el
                    })
                } else {
                    let obj = {}
                    for(let i in this.options){
                        if(this.options.hasOwnProperty(i)){
                            obj[i] = this.options[i]
                        }
                    }
                }
                return obj
            }
        },

        methods: {
            change(e) {
                this.$emit('input', e.target.value)
            }
        }
    }
</script>
