<template>
    <my-block
            :width="900"
            :height="440"
            :left="480"
            :top="10"
            :title="title"
            @close="$emit('close', true)">

        <template v-slot:default>
            <canvas id="meter-chart"></canvas>
        </template>

        <template v-slot:bottom-buttons>

        </template>
    </my-block>
</template>

<style>
#meter-chart{
    height: 100%;
    width: 100%;
}
</style>

<script>
    import myBlock from '../../../components/responsiveBlock';
    import historyApi from "../../../api/history";
    import Chart from 'chart.js/auto';
    import 'chartjs-adapter-date-fns';

    let meterChart;

    export default {
        components:{
            myBlock,
        },

        props:{
            title: String,
            param: String,
            devSerial: String,
            yUnits: String,
        },

        data(){
            return {
            }
        },

        watch:{
            param(){
                this.redraw();
            },
            devSerial(){
                this.redraw();
            },
        },

        methods:{
            async redraw(){
                let ctx = document.getElementById('meter-chart').getContext('2d');
                if(meterChart) meterChart.destroy();

                let data = await historyApi.loadByParam(this.devSerial, this.param);
                let vals = [];
                data.forEach(row => {
                    vals.push({ x: new Date(row.time), y: row[this.param] })
                })

                let datasets = [
                    {
                        label: '',
                        backgroundColor: '#014c52',
                        borderColor: '#014c52',
                        data: vals,
                        fill: false,
                        lineTension: 0,
                    },
                ];

                meterChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        datasets,
                    },
                    options: {
                        elements: {
                            point:{
                                radius: 0 // hide markers
                            }
                        },
                        legend: {
                            labels: {
                                fontColor: "#363636",
                                fontSize: 14
                            }
                        },
                        scales: {
                            x: {
                                type: 'time',
                                ticks: {
                                    fontColor: "#363636",
                                },
                                scaleLabel: {
                                    display: true,
                                },
                                time: {
                                    // unit: 'day',
                                    displayFormats: {
                                        quarter: 'MMM yyyy',
                                        second: 'HH:mm:ss',
                                        minute: 'HH:mm',
                                        hour: 'dd.MM HH:mm',
                                        day: 'dd.MM.yyyy',
                                    }
                                }
                            },
                            y: {
                                ticks: {
                                    fontColor: "#363636",
                                },
                                title:{
                                    display: true,
                                    text: this.yUnits,
                                },
                                scaleLabel: {
                                    display: true,
                                }
                            }
                        },
                        maintainAspectRatio: 0,
                    }
                });
            }
        },

        async mounted() {
            this.redraw();
        }
    }
</script>