import {each} from "lodash";
import devApi from './api/devices';
import {DEVICE_SHOW_INFO_POPUP} from './store/mutations'

export const gasLimits = {
    NO: {min: 0.064, max: 0.96},
    NO2: {min: 0.02, max: 0.3},
    CO: {min: 0.86, max: 12.9},
    O3: {min: 0.016, max: 0.24},
    SO2: {min: 0.038, max: 0.57},
    PM25: {min: 0.5, max: 6},
    PM10: {min: 0.5, max: 6},
    TSP: {min: 0.0, max: 6},
}

export const gasLimitsColors = [
    '#259b54',
    '#d2ca00',
    '#f0832a',
    '#ff0800',
    '#ca0900',
];

export const gasLimitsValues = {
    NO: [0.16, 0.256, 0.32, 0.96],
    NO2: [0.05, 0.08, 0.1, 0.3],
    CO: [2.15, 3.44, 4.3, 12.9],
    O3: [0.04, 0.064, 0.08, 0.24],
    SO2: [0.095, 0.152, 0.19, 0.57],
    PM25: [1, 1.6, 2, 6],
    PM10: [1, 1.6, 2, 6],
    TSP: [1, 1.6, 2, 6],
}

export function setErrors(vueErrObj, errResponseData) {
    each(errResponseData, (v, k) => {
        each(vueErrObj, (v, k)=>{
            vueErrObj[k] = ''
        })
        each(errResponseData, (v, k) => {
            if(vueErrObj[k] !== undefined){
                if(Array.isArray(v)){
                    v.forEach((el)=>{
                        vueErrObj[k] += (el.description !== undefined ? el.description : el.toString()) + '\n'
                    })
                } else {
                    vueErrObj[k] = v.toString()
                }
            }
        })
    })
}

export const notificationTypes = {
    2: 'Критический',
    1: 'Предупредительный',
    0: 'Информационный',
}

export const accessRights = {
    //"Project", "Rights", "MailGroups", "Notify", "Monitor"
    Project: 'Создание/редактирование проектов',
    Rights: 'Настройка прав доступа',
    MailGroups: 'Настройка групп рассылки',
    Notify: 'Настройка базы аварийных сообщений',
    Monitor: 'Режим диспетчера',
}


export function plural(n, one, two, five) {
    n = Math.abs(n);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
}

function cloudIconColor(device){
    let i = 0;
    if(device?.data?.gas?.sensors) each(device.data.gas.sensors, (sens) =>{
        i = Math.max(i, gasColorIndex(sens.substanceCode, sens.value));
        if(i >= gasLimitsColors.length) return gasLimitsColors[0];
    })
    return i;
}

export function drawDevicesNoGroup($map, devs, $vueStore, fitMap = true){
    if(devs){
        let mapBounds;
        each(devs, (dev)=>{
            if(dev.longitude === undefined || dev.latitude === undefined) return;
            if(!mapBounds){
                mapBounds = new mapgl.LngLatBoundsClass({
                    southWest: [dev.longitude, dev.latitude],
                    northEast: [dev.longitude, dev.latitude],
                })
            }
            mapBounds.extend([dev.longitude, dev.latitude]);
        })

        if(fitMap){
            $map.fitBounds(mapBounds, {
                animation: {animate: true, duration: 1000},
                padding:{
                    top: 110,
                    right: 20,
                    bottom: 20,
                    left: 300,
                }
            })
        }

        let newMarkers = [];
        each(devs, (dev) => {
            let i = cloudIconColor(dev);
            let markerEl = document.getElementById('map-marker-' + dev.id);
            if(markerEl){
                markerEl.querySelector('img').src = `/images/icons/map/marker-${i}.svg`;
            } else {
                let marker = new mapgl.HtmlMarker($map, {
                    coordinates: [dev.longitude, dev.latitude],
                    html: `<div class="map-marker" id="map-marker-${dev.id}" data-id="${dev.id}">
<img src="/images/icons/map/marker-${i}.svg">
</div>`,
                    userData: {
                        devId: dev.id,
                    }
                })
                newMarkers.push(dev.id);
            }
        })

        let onClick = function (e){
            e.preventDefault();
            $vueStore.commit(DEVICE_SHOW_INFO_POPUP, e.target.dataset.id);
            document.querySelectorAll('.map-marker').forEach((el) => {
                el.classList.remove('selected');
            })
            e.target.classList.add('selected');
        };

        //т.к. непонятно в какой момент маркер отрисуется и будет в DOM
        setTimeout(()=>{
            newMarkers.forEach((devId) => {
                document.getElementById('map-marker-' + devId).onclick = function (e) {
                    onClick(e)
                }
            })
        }, 1000)
    }
}

export function gasColorIndex(gas, value){
    let gC = gasLimitsValues[gas];
    if(!gC) return 0;

    if(value < gC[0]) return 0;
    if(value > gC[gC.length - 1]) return gasLimitsColors.length - 1;

    for(let i = 0; i < gC.length - 1; i++){
        if(value > gC[i] && value < gC[i+1]){
            return i + 1;
        }
    }
    return 0;
}

export function roundTo(v, p){
    return Math.round(v * 10**p) / 10**p;
}

export function gasFormatValue(gas, v){
    let min = gasLimits[gas]?.min ?? 0;
    if(v < min) return 0;
    return roundTo(v, 3);
}

export function gasLabelColor(gas, value){
    return gasLimitsColors[gasColorIndex(gas, value)];
}