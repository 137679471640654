import axios from 'axios'

import base from './_base'

let api = Object.assign({}, base)

api.resource = 'roles'

api.load = async () => {
    let response =  await axios.get(api.baseUrl + api.resource)
    return response.data
};

api.update = async (id, itemData, onError) => {
    try {
        itemData.id = id;
        return await axios.put(`${api.baseUrl}${api.resource}/id:${id}`, itemData)
    } catch (e) {
        console.error(e)
        if(onError) onError(e)
        return false
    }
};

api.updateRole = async (id, itemData, onError) => {
    try {
        itemData.id = id;
        return await axios.put(`${api.baseUrl}${api.resource}/id:${id}/claims`, itemData)
    } catch (e) {
        console.error(e)
        if(onError) onError(e)
        return false
    }
};

api.delete = async (id, onError) => {
    try {
        return await axios.delete(`${api.baseUrl}${api.resource}/id:${id}`)
    } catch (e) {
        console.error(e)
        if(onError) onError(e)
        return false
    }
};

export default api