export const USER_SET = 'USER_SET'
export const USER_LOGGED = 'USER_LOGGED'
export const DATA_LOADED = 'DATA_LOADED'

export const PROJECTS_SET = 'PROJECTS_SET'
export const TIMEZONES_SET = 'TIMEZONES_SET'
export const PROJECT_UPDATE_PROP = 'PROJECT_UPDATE_PROP'

export const ROLES_SET = 'ROLES_SET'
export const ROLE_UPDATE_PROP = 'ROLE_UPDATE_PROP'

export const MAILGROUPS_SET = 'MAILGROUPS_SET'
export const MAILGROUPS_PROP = 'MAILGROUPS_PROP'

export const DEVICES_SET = 'DEVICES_SET'
export const DEVICE_SET = 'DEVICE_SET'
export const DEVICE_UPDATE_PROP = 'DEVICE_UPDATE_PROP'
export const DEVICE_UPDATE_STATUS = 'DEVICE_UPDATE_STATUS'
export const DEVICE_SHOW_INFO_POPUP = 'DEVICE_SHOW_INFO_POPUP'