<template>
    <div>
        <div id="side-menu-right">
            <div class="side-menu-right__item" @click="showAdd = true" v-if="hasAccess">
                <img src="/images/icons/add.svg" class="special">
            </div>
        </div>

        <add-devices
                v-if="showAdd"
                @close="showAdd = false"
                :project="project"
        ></add-devices>

        <div class="dev-list">
            <list-item
                    v-for="dev in devices"
                    :device="dev"
                    :key="dev.id"
                    :project="project"
                    :show-edit="hasAccess"
            ></list-item>
        </div>

    </div>
</template>

<style>
.dev-list{
    width: calc(100% - 340px);
    margin-left: 220px;
    padding: 20px 10px;
    max-height: calc(100vh - 130px);
    overflow: auto;
}
</style>

<script>
import addDevices from "./add.vue";
import listItem from "./list-item.vue";
import {AccessRoles} from "../../../../config";

export default {
    components: {
        addDevices,
        listItem,
    },

    data() {
        return {
            showAdd: false,
        }
    },

    computed: {
        project() {
            return this.$store.state.projects.all[this.$route.params.projectId];
        },

        devices() {
            return this.$store.state.devices.all;
        },

        hasAccess(){
            return this.$hasAccess(this.project);
        }
    },

    methods: {},
}
</script>