<template>
    <div id="demo-block">
        <div class="title">
            Станция измерения газопылевого состава <br> воздуха № 3
        </div>

        <div class="row">Газ</div>

        <div class="row info">
            <div class="param-name">М 10</div>
            <div class="param-value">{{ params.gas.pm10 / 100 }} мкг/м3</div>
        </div>

        <div class="row info">
            <div class="param-name">PМ 2.5</div>
            <div class="param-value">{{ params.gas.pm25 / 100 }} мкг/м3</div>
        </div>

        <div class="divider"></div>

        <div class="row">Климат</div>

        <div class="row info">
            <div class="param-name">Температура</div>
            <div class="param-value">{{ params.climate.temp }} ℃</div>
        </div>

        <div class="row info">
            <div class="param-name">Влажность</div>
            <div class="param-value">{{ params.climate.hum }}%</div>
        </div>
    </div>
</template>

<style lang="scss">
#demo-block {
  position: absolute;
  top: 30px;
  left: 300px;
  border-radius: 5px;
  width: 350px;
  padding: 15px;
  background: #ffffff;

  .title {
    color: #B4B4B4;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
  }

  .row {
    color: #474747;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    height: 40px;
    display: flex;
    align-items: center;

    .param-name {
      width: 130px;
    }

    .param-value {
      color: #2AD055;
    }

    &.info {
      border-bottom: 1px solid #EAEEF2;
    }
  }

  .divider {
    margin: 0 -15px 5px -15px;
    height: 1px;
    background: #7C94B0;
  }
}
</style>

<script>
export default {
    components: {},

    data() {
        return {
            params: {
                gas: {
                    pm10: 0,
                    pm25: 0,
                },
                climate: {
                    temp: 0,
                    hum: 0,
                }
            }
        }
    },

    created() {
        this.$mqtt.addListener('all', 'palantir/plc1/DDATA/10000000246c74be/DA/(.+)', (params, msg) => {
            let tType = params[1];
            let data = JSON.parse(msg);
            switch (tType) {
                case 'PM10':
                    this.params.gas.pm10 = data[0];
                    break;
                case 'PM2.5':
                    this.params.gas.pm25 = data[0];
                    break;
                case 'Temp':
                    this.params.climate.temp = data[0];
                    break;
                case 'Hum':
                    this.params.climate.hum = data[0];
                    break;
            }
        })
    },

    mounted() {
        let topic = "palantir/plc1/DDATA/10000000246c74be/DA/#";
        this.$mqtt.subscribe(topic);
    },


}
</script>