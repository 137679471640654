<template>
    <div class="center-content">

        <div class="block">
            <h3>Регистрация</h3>
            <div class="help-text">
                После подтверждения администратором системы на указанный email
                будет выслано письмо активации учетной записи.
            </div>
        </div>

    </div>
</template>

<style lang="scss" scoped>

    .block{
        width: 450px;
    }

    h3{
        margin-bottom: 40px;
    }

    .help-text{
        font-size: 16px;
        line-height: 22px;
    }

</style>

<script>
    export default {

    }
</script>