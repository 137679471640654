<template>
    <vue-resizable
            :width="width"
            :height="height"
            :max-height="1000"
            :min-height="200"
            :left="left"
            :top="top"
            dragSelector=".header">
        <div class="block movable">
            <div class="header">
                <div class="block-title">{{title}}</div>
                <slot name="header-icons"></slot>
                <img @click="$emit('close', true)"
                     v-if="!noClose"
                     src="/images/icons/close-a.svg" class="ico-btn-color close">
            </div>

            <div style="overflow: auto; flex-grow: 1; margin: 15px 0;">
                <slot name="default"></slot>
            </div>

            <slot name="bottom-buttons"></slot>

            <slot name="dialogs"></slot>
        </div>
    </vue-resizable>

</template>

<style lang="scss" scoped>
    .block{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        height: 100%;
    }
    .resizable-component {
        position: absolute;
    }
</style>


<script>
    import VueResizable from 'vue-resizable'

    export default {

        components:{
            VueResizable,
        },

        props: {
            title: String,
            width: Number,
            height: Number,
            left: Number,
            top: Number,
            noClose: Boolean,
        },

        data(){
            return {

            }
        },

    }
</script>