import Vue from 'vue'
import {
    TIMEZONES_SET,
    PROJECTS_SET,
    PROJECT_UPDATE_PROP,
} from '../mutations'

import {forEach} from 'lodash'
import api from '../../api/projects'


const state = {
    all: {},
    timezones: {},
    count: 0,
}

// getters
const getters = {

}

// actions
const actions = {
    async projectsLoad({commit, dispatch}){
        let data = await api.load();
        commit(PROJECTS_SET, data);
    },

    async timezonesLoad({commit}){
        let data = await api.loadTimezones();
        commit(TIMEZONES_SET, data);
    },

}

// mutations
const mutations = {
    [PROJECTS_SET] (state, items) {
        state.all = {};
        state.count = 0;
        forEach(items, d => {
            Vue.set(state.all, d.id, d);
            ++state.count;
        })
    },

    [TIMEZONES_SET] (state, items) {
        state.timezones = {}
        forEach(items, d => {
            // Vue.set(state.timezones, d.id, d.id + ' ' + d.name)
            state.timezones[d.id] = d.id + ' ' + d.name;
        })
    },

    [PROJECT_UPDATE_PROP] (state, {id, prop, val}) {
        Vue.set(state.all[id], prop, val)
    },

}


export default {
    // namespaced: true,
    state,
    getters,
    actions,
    mutations
}
