import axios from 'axios'

import base from './_base'

let api = Object.assign({}, base)

api.resource = 'devices'

api.loadByProject = async (projectId, pageSize = 1000, pageIndex = 0) => {
    let response = await axios.get(`${api.baseUrl}devices/project/` + projectId,{
        params:{
            pageSize,
            pageIndex,
        }
    })
    return response.data.data;
}

api.addToProject = async (projectId, data) => {
    let response = await axios.post(`${api.baseUrl}devices/` + projectId, data)
    return response.data;
}


api.update = async (id, itemData, onError) => {
    try {
        itemData.id = id;
        return await axios.put(`${api.baseUrl}${api.resource}/${id}`, itemData)
    } catch (e) {
        console.error(e)
        if(onError) onError(e)
        return false
    }
}

api.updateCoords = async (id, lat, lng, onError) => {
    try {
        let data = {
            id,
            latitude: parseFloat(lat),
            longitude: parseFloat(lng),
        }
        return await api.update(id, data, onError)
    } catch (e) {
        console.error(e)
        if(onError) onError(e)
        return false
    }
}

export default api