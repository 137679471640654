<template>
    <div id="project-body">
        <div id="side-menu">
            <div class="side-menu__item"
                 :class="{active: tab === TabEnum.DEVICES}"
                 @click="tab = TabEnum.DEVICES">
                <img src="/images/icons/side-menu/projects.svg">
                <div>Устройства</div>
            </div>

            <div class="side-menu__item"
                 :class="{active: tab === TabEnum.MANAGEMENT}"
                 @click="tab = TabEnum.MANAGEMENT">
                <img src="/images/icons/side-menu/dispetcher.svg">
                <div>Диспетчерский режим</div>
            </div>

            <div class="side-menu__item"
                 :class="{active: tab === TabEnum.MONITOR}"
                 @click="tab = TabEnum.MONITOR">
                <img src="/images/icons/side-menu/monitoring.svg">
                <div>Мониторинг среды</div>
            </div>

            <div class="side-menu__item"
                 :class="{active: tab === TabEnum.DISASTER}"
                 @click="tab = TabEnum.DISASTER">
                <img src="/images/icons/side-menu/alerts.svg">
                <div>Режим ЧП</div>
            </div>
        </div>

        <div style="pointer-events: none; width: 100%; height: 100%;">
            <devices v-if="tab === TabEnum.DEVICES"></devices>
            <management v-if="tab === TabEnum.MANAGEMENT"></management>
            <monitoring v-if="tab === TabEnum.MONITOR"></monitoring>
            <disaster v-if="tab === TabEnum.DISASTER"></disaster>
        </div>

    </div>
</template>

<script>
import Devices from "./tabs/devices/list.vue";
import Management from "./tabs/management.vue";
import Monitoring from "./tabs/monitoring.vue";
import Disaster from "./tabs/disaster.vue";

const TabEnum = {
    DEVICES: 'DEVICES',
    MANAGEMENT: 'MANAGEMENT',
    MONITOR: 'MONITOR',
    DISASTER: 'DISASTER',
}

let refresher;

export default {
    components: {
        Devices,
        Management,
        Monitoring,
        Disaster,
    },

    data() {
        return {
            TabEnum,
            tab: TabEnum.DEVICES,
        }
    },

    computed: {
        project() {
            return this.$store.state.projects.all[this.$route.params.projectId];
        },
    },

    methods: {},

    destroyed() {
        clearInterval(refresher)
    },

    mounted(){
        this.$store.dispatch('devicesLoad', this.$route.params.projectId);
        refresher = setInterval(()=>{
            this.$store.dispatch('devicesLoad', this.$route.params.projectId);
        }, 30000)

        if(this.project){
            this.$map.setCenter([this.project.longitude, this.project.latitude]);
            this.$map.setZoom(14);
        }
    }
}
</script>