<template>
    <div class="in-dev-info__bg">
        <div class="in-dev-info__text">
            Раздел в процессе разработки
        </div>
    </div>
</template>

<script>

export default {
    components: {},

    data() {
        return {

        }
    },

    computed: {},

    methods: {},
}
</script>