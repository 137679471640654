import VueRouter from "vue-router";
import Vue from 'vue'
Vue.use(VueRouter)

import pageLogin from './pages/auth/login.vue'
import pageRestore from './pages/auth/pwd-restore.vue'
import pageReset from './pages/auth/pwd-reset.vue'
import pageRegDone from './pages/auth/reg-done.vue'
import pageRegMailConfirmed from './pages/auth/mail-confirmed.vue'

import projects from './pages/project/main/list.vue'
import projectsAdd from './pages/project/main/add.vue'
import projectPage from './pages/project/project.vue'
import pageAccessList from './pages/access/page.vue'


export const router = new VueRouter({
    routes:[
        { path: '/', component: pageLogin, meta:{ requireGuest: true }},
        { path: '/projects', component: projects, name:'projects', meta:{ requireAuth: true }},
        { path: '/projects/add', component: projectsAdd, name:'projects-add', meta:{ requireAuth: true }},
        { path: '/projects/:projectId', component: projectPage, name:'project', meta:{ requireAuth: true }},
        { path: '/projects/:projectId/edit', component: projectsAdd, name:'projects-edit', meta:{ requireAuth: true }},

        { path: '/access', name: 'access', component: pageAccessList, meta: { requireAuth: true }},
        { path: '/login', name: 'login', component: pageLogin, meta: { requireGuest: true } },
        { path: '/login/restore', name: 'pwd-restore', component: pageRestore, meta: { requireGuest: true } },
        { path: '/login/restore/:token(.+)', name: 'pwd-reset', component: pageReset, meta: { requireGuest: true } },
        { path: '/login/registered', name: 'reg-done', component: pageRegDone, meta: { requireGuest: true } },
        { path: '/login/mail-confirmed', name: 'reg-main-confirmed', component: pageRegMailConfirmed, meta: { requireGuest: true } },
    ]
})


export function redirect(route, loggedIn){
    if(route.meta.requireGuest && loggedIn){
        return { name: 'projects' }
    }
    if(route.meta.requireAuth && !loggedIn){
        return { name: 'login' }
    }
    return false
}
