<template>
    <div class="dev-list-item">
        <div class="dev-list-item__title" @click="collapsed = !collapsed">
            <img src="/images/icons/form/arrow-down.svg" class="collapse-arrow" :class="{collapsed: collapsed}">
            <div style="font-size: 18px; min-width: 250px">
                {{device.title ?? ''}}
                <span style="color: #7f7f7f">{{device.serial}}</span>
            </div>
            <div v-if="device.data?.meteo?.timeStamp" class="title-data">
                <img class="title-data__icon" src="/images/icons/meteo/temp.svg" style="height: 32px">
                <div class="title-data__prop">{{$round(device.data.meteo.airTemp, 1)}}℃</div>

                <img class="title-data__icon" src="/images/icons/meteo/hum.svg">
                <div class="title-data__prop">{{$round(device.data.meteo.airHum, 1)}}%</div>

                <img class="title-data__icon" src="/images/icons/meteo/wind.svg">
                <div class="title-data__prop">
                    {{$round(device.data.meteo.horWinSpd, 1)}}м/с,
                    {{degreesToDirection(device.data.meteo.horWinDir)}}
                </div>

                <img class="title-data__icon" src="/images/icons/meteo/press.svg">
                <div class="title-data__prop">{{$round(device.data.meteo.atmPress, 0)}}мм. рт. ст.</div>
            </div>
            <div style="margin-left: 50px">
                <cloud-icon class="title-data__icon"
                            style="opacity: 0.6"
                            src="/images/icons/meteo/press.svg"
                            :color="cloudIconColor()"
                ></cloud-icon>
            </div>
            <div style="flex-grow: 1"></div>
            <template v-if="showEdit">
                <img class="ico-btn-color" src="/images/icons/edit.svg" @click.stop="editPopup = true">
                <img class="ico-btn-color" src="/images/icons/minus-r-g.svg" @click.stop="delPopup = true" style="margin-left: 15px">
            </template>
        </div>

        <div class="dev-list-item__body" v-if="device.data" :class="{collapsed: collapsed}">

            <div style="display: flex">

                <div class="data" style="flex: 1; margin-right: 15px;" v-if="device.data?.gas?.timeStamp">
                    <div class="data-title">
                        Газы
                        <span class="sub-title">{{device.data.gas.deviceName}}</span>
                        <div class="s-ico">
                            <div :class="{offline: !device.data.gas.status, online: device.data.gas.status}"></div>
                        </div>
                        <span class="ts-date">{{toDate(device.data.gas.timeStamp)}}</span>
                    </div>

                    <div class="data-body">
                        <div class="data-row" style="margin-bottom: 10px">
                            <template class="data" v-if="device.data.ivtm">
                                <div class="data-item" :title="`Обновлено ${toDate(device.data.ivtm.timeStamp)}`">
                                    <div class="data-item-label">Температура пробы:</div>
                                    <div class="data-item-val">{{$round(device.data.ivtm.sensorIVTMtemp, 1)}}℃</div>
                                </div>

                                <div class="data-item" :title="`Обновлено ${toDate(device.data.ivtm.timeStamp)}`">
                                    <div class="data-item-label">Влажность пробы:</div>
                                    <div class="data-item-val">{{$round(device.data.ivtm.sensorIVTMhum)}}%</div>
                                </div>
                            </template>

                            <div class="data-item">
                                <div class="data-item-label">Температура платы:</div>
                                <div class="data-item-val">{{$round(device.data.gas.boardTemperature)}}℃</div>
                            </div>
                        </div>

                        <div class="gas-row" v-for="s in device.data.gas.sensors">
                            <div class="s-ico">
                                <div :class="{offline: s.status !== 'ready', online: s.status === 'ready'}"></div>
                            </div>
                            <div class="label">
                                {{s.substanceCode}}
                            </div>
                            <div class="progress">
                                <div class="inner" :style="'width: ' + gasProgress(s.substanceCode, s.value) + '%'"></div>
                            </div>
                            <div class="label-after">сред</div>
                            <div class="label-value"
                                 :style="'color: ' + gasLabelColor(s.substanceCode, s.value)">
                                {{gasFormatValue(s.substanceCode, s.value)}} PPM
                            </div>
                        </div>
                    </div>
                </div>

                <div class="data" style="flex: 1; margin-left: 15px;" v-if="device.data?.dust?.timeStamp">
                    <div class="data-title">
                        Пыль
                        <span class="sub-title">{{device.data.dust.deviceName}}</span>
                        <div class="s-ico">
                            <div :class="{offline: !device.data.dust.status, online: device.data.dust.status}"></div>
                        </div>
                        <span class="ts-date">{{toDate(device.data.dust.timeStamp)}}</span>
                    </div>

                    <div class="data-body">
                        <div class="gas-row">
                            <div class="label">
                                PM2.5
                            </div>
                            <div class="progress">
                                <div class="inner" :style="'width: ' + gasProgress('PM25', device.data.dust.pm2Concentration) + '%'"></div>
                            </div>
                            <div class="label-after">сред</div>
                            <div class="label-value">
                                {{$round(device.data.dust.pm2Concentration, 3)}}
                                мг/м<sup>3</sup>
                            </div>
                        </div>

                        <div class="gas-row">
                            <div class="label">
                                PM10
                            </div>
                            <div class="progress">
                                <div class="inner" :style="'width: ' + gasProgress('PM10', device.data.dust.pm10Concentration) + '%'"></div>
                            </div>
                            <div class="label-after">сред</div>
                            <div class="label-value">
                                {{$round(device.data.dust.pm10Concentration, 3)}}
                                мг/м<sup>3</sup>
                            </div>
                        </div>

                        <div class="gas-row">
                            <div class="label">
                                TSP
                            </div>
                            <div class="progress">
                                <div class="inner" :style="'width: ' + gasProgress('TSP', device.data.dust.tspConcentration) + '%'"></div>
                            </div>
                            <div class="label-after">сред</div>
                            <div class="label-value">
                                {{$round(device.data.dust.tspConcentration, 3)}}
                                мг/м<sup>3</sup>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="data" v-if="device.data.meteo?.timeStamp">
                <div class="data-title">
                    Метео <span class="sub-title">{{device.data.meteo.deviceName}}</span>
                    <div class="s-ico">
                        <div :class="{offline: !device.data.meteo.status, online: device.data.meteo.status}"></div>
                    </div>
                    <span class="ts-date">{{toDate(device.data.meteo.timeStamp)}}</span>
                </div>

                <div class="data-body">
                    <div class="data-row">
                        <div class="data-item">
                            <div class="data-item-label">Температура:</div>
                            <div class="data-item-val">{{$round(device.data.meteo.airTemp, 2)}}℃</div>
                        </div>

                        <div class="data-item">
                            <div class="data-item-label">Влажность:</div>
                            <div class="data-item-val">{{$round(device.data.meteo.airHum, 2)}}%</div>
                        </div>

                        <div class="data-item">
                            <div class="data-item-label">Давление:</div>
                            <div class="data-item-val">{{$round(device.data.meteo.atmPress, 0)}}мм. рт. ст.</div>
                        </div>

                        <div class="data-item">
                            <div class="data-item-label">Ветер:</div>
                            <div class="data-item-val">
                                {{$round(device.data.meteo.horWinSpd, 1)}}м/с
                            </div>
                            <div class="data-item-val">
                                <wind-direction :dir="device.data.meteo.horWinDir"></wind-direction>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="data">
                <div class="data-title">
                        {{device.data.deviceName}}
                    <span class="ts-date">{{toDate(device.data.timeStamp)}}</span>
                </div>

                <div class="data-body">
                    <div class="data-row">
                        <div class="data-item">
                            <div class="data-item-label">CPU:</div>
                            <div class="data-item-val">{{$round(device.data.cpuTemp)}}℃</div>
                        </div>

                        <div class="data-item">
                            <div class="data-item-label">RAM (Мб):</div>
                            <div class="data-item-val">{{$round(device.data.memFree / 1024 / 1024)}} / {{$round(device.data.memTotal / 1024 / 1024)}}</div>
                        </div>

                        <div class="data-item" :title="`Обновлено ${toDate(device.data.modbusStatusTime)}`">
                            <div class="data-item-label">Modbus:</div>
                            <div class="data-item-val">{{device.data.modbusStatus}}</div>
                        </div>

                        <div class="data-item" :title="`Обновлено ${toDate(device.data.rs485StatusTime)}`">
                            <div class="data-item-label">RS-485:</div>
                            <div class="data-item-val">{{device.data.rs485Status}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="popup-bg" v-if="delPopup">
            <div class="block" style="width: 345px">
                <div class="title">Вы точно хотите удалить это устройство ?</div>
                <div class="btns">
                    <button class="btn red" type="button" @click="delDev()">Удалить</button>
                    <button class="btn green" @click="delPopup = false">Отмена</button>
                </div>
            </div>
        </div>

        <div class="popup-bg" v-if="editPopup">
            <div class="block" style="width: 300px">
                <pe-input v-model="title"
                          type="text"
                          placeholder="Название"
                ></pe-input>

                <pe-input v-model="coords.lat"
                          type="text"
                          placeholder="Широта"
                ></pe-input>

                <pe-input v-model="coords.lng"
                          type="text"
                          placeholder="Долгота"
                ></pe-input>

                <pe-input v-model="coords.alt"
                          type="text"
                          placeholder="Высота"
                ></pe-input>

                <div class="btns" style="margin-top: 10px">
                    <button class="btn blue" type="button" @click="saveCoords()">Сохранить</button>
                    <button class="btn gray" @click="editPopup = false">Отмена</button>
                </div>
            </div>
        </div>

    </div>
</template>

<style scoped lang="scss">
@import "src/scss/vars";

.collapse-arrow{
    margin-right: 10px;
    transition: 200ms;
    &.collapsed{
        transform: rotate(-90deg);
    }
}

.dev-list-item{
    margin-bottom: 25px;
    box-shadow: #989898 0 0 7px 0;
    background: #FFFFFF;
}
.dev-list-item__title{
    font-size: 16px;
    padding: 15px 20px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.dev-list-item__body{
    background: #FFFFFF;
    padding: 15px 20px 15px 20px;
    transition: 0.5s ease;
    max-height: 800px;
    border-top: 1px solid #c1c1c1;
    &.collapsed{
        max-height: 0;
        padding-bottom: 0;
        padding-top: 0;
        overflow: hidden;
    }
}

.data{
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    .s-ico{
        display: inline-block;
        padding-bottom: 2px;
    }
}

.sub-title{
    color: #98a6b5;
    padding-left: 10px;
}

.data-title{
    font-weight: 700;
    background: #f1f1f1;
    padding: 5px 10px;
}

.data-body{
    padding: 5px 10px;
    background: #fafafa;
    flex-grow: 1;
}

.data-row{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.data-item{
    margin-right: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.data-item-label{
    color: #004972;
    margin-right: 10px;
}
.data-item-val{
    color: #636363;
}

.ts-date{
    color: #a3a3a3;
    padding-left: 25px;
    font-size: 14px;
    font-weight: 400;
}

.gas-row{
    display: flex;
    max-width: 600px;
    align-items: center;
    font-weight: 600;
    margin: 7px 0;

    .s-ico{
        display: block;
        padding-bottom: 0;
        margin-right: 5px;
    }

    .label{
        width: 60px;
        font-size: 18px;
    }

    .progress {
        height: 8px;
        border-radius: 8px;
        margin: 0 15px;
        flex-grow: 1;
        overflow: hidden;
        position: relative;
        //background: linear-gradient(90deg, #2AD055 0%, #20A041 0%, #FFE600 60%, #FF0000 132%);
        background: linear-gradient(90deg, rgba(37,155,84,1) 6%, rgba(254,241,31,1) 16%, rgba(240,131,42,1) 32%, rgba(255,8,0,1) 85%, rgba(202,9,0,1) 100%);

        .inner{
            background: #EDF1F5;
            height: 100%;
            position: absolute;
            right: 0;
        }
    }

    .label-after{
        font-size: 12px;
        color: #B4B4B4;
        margin-right: 5px;
    }

    .label-value{
        font-size: 14px;
        color: $colorGreen;
        width: 90px;
        .overflow{
            color: $colorRed;
        }
    }
}

.title-data__props{

}

.title-data{
    display: flex;
    align-items: center;
    color: #474747;
}
.title-data__name{
    width: 250px;
}
.title-data__icon{
    margin: 0 10px;
}
.title-data__prop{
    margin-right: 20px;
}

</style>
<script>

import devicesApi from "../../../../api/devices";
import peInput from "../../../../components/peInput.vue";
import windDirection from "../../../../components/windDirection.vue";
import {DEVICE_UPDATE_PROP} from "../../../../store/mutations";
import devApi from "../../../../api/devices";
import historyApi from "../../../../api/history";
import cloudIcon from "../../../../components/cloudIcon.vue";
import {each} from "lodash";
import {
    gasFormatValue,
    gasLimits,
    gasLimitsColors,
    gasLabelColor,
    gasColorIndex
} from "../../../../other";


export default {
    components: {
        peInput,
        windDirection,
        cloudIcon,
    },

    props:{
        device: Object,
        project: Object,
        showEdit: Boolean,
    },

    data() {
        return {
            expanded: false,
            delPopup: false,
            editPopup: false,
            collapsed: true,
            coords: {
                lat: 0,
                lng: 0,
                alt: 0,
            },
            title: '',
        }
    },

    computed: {
        errorState(){
            return 'good';
        },
    },

    async mounted() {
        this.coords.lat = this.device.latitude;
        this.coords.lng = this.device.longitude;
        this.coords.alt = this.device.altitude;
        this.title = this.device.title ?? '';

        // let d = await historyApi.load(this.device.serial);
        // let d = await historyApi.loadByParam(this.device.serial, 'temperature');
    },

    methods: {
        gasFormatValue,
        gasLabelColor,

        async delDev(){
            await devicesApi.delete(this.device.id);
            this.$store.dispatch('devicesLoad', this.project.id);
        },

        toDate(ts){
            let d = new Date(ts);
            return d.toLocaleString('ru-RU');
        },

        gasProgress(gas, v){
            let min = gasLimits[gas]?.min ?? 0;
            let max = gasLimits[gas]?.max ?? 1;
            if(v < min) return 100;
            if(v >= max) return 0;
            let w = (v - min) / (max - min);
            return (1 - w) * 100;
        },

        cloudIconColor(){
            let i = 0;
            if(this.device?.data?.gas?.sensors) each(this.device.data.gas.sensors, (sens) =>{
                i = Math.max(i, gasColorIndex(sens.substanceCode, sens.value));
                if(i >= gasLimitsColors.length) return false;
            })
            return gasLimitsColors[i];
        },

        degreesToDirection(degrees) {
            const directions =  ['С', 'ССВ', 'СВ', 'ВСВ', 'В', 'ВЮВ', 'ЮВ', 'ЮЮВ', 'Ю', 'ЮЮЗ', 'ЮЗ', 'ЗЮЗ', 'З', 'ЗСЗ', 'СЗ', 'ССЗ'];
            const index = Math.round(degrees / 22.5);
            return directions[index % 16];
        },

        async saveCoords(){
            this.$store.commit(DEVICE_UPDATE_PROP, {
                id: this.device.id,
                prop: 'latitude',
                val: this.coords.lat,
            })
            this.$store.commit(DEVICE_UPDATE_PROP, {
                id: this.device.id,
                prop: 'longitude',
                val: this.coords.lng,
            })
            this.$store.commit(DEVICE_UPDATE_PROP, {
                id: this.device.id,
                prop: 'altitude',
                val: this.coords.alt,
            })
            this.$store.commit(DEVICE_UPDATE_PROP, {
                id: this.device.id,
                prop: 'title',
                val: this.title,
            })
            // await devApi.updateCoords(this.device.id, this.coords.lat, this.coords.lng);
            await devApi.update(this.device.id, {
                title: this.title,
                latitude: parseFloat( this.coords.lat),
                longitude: parseFloat( this.coords.lng),
                altitude: parseFloat( this.coords.alt),
            });
            this.editPopup = false;
        }
    },
}
</script>