<template>
    <div @click.stop="showMenu = false">
        <div id="map"></div>

        <header>
            <div class="head-left-block">
                <router-link to="/">Палантир</router-link>
            </div>

            <div class="head-title">
                <template v-if="loggedIn">
                    <router-link to="/projects">Проекты</router-link>
                    <template v-if="project">
                        <span style="padding: 0 10px">/</span>
                        <span style="color: #000000">{{project.name}}</span>
                    </template>
                </template>
            </div>

            <div class="head-right-block">
                <img class="ico-btn" style="width: 32px; margin-right: 25px"
                     v-if="$store.state.user && $store.state.user.role === 'Administrator'"
                     @click="$router.push({name: 'access'})"
                     src="/images/icons/b-ico-rights.svg">

                <div class="dropdown-menu" v-if="loggedIn" @click.stop="showMenu = !showMenu">
                    <img class="ico-btn" style="height: 45px" src="/images/icons/profile.svg">
                    <div class="items" v-if="showMenu">
                        <div class="item" @click="logout()">Выйти из системы</div>
                    </div>
                </div>
            </div>
        </header>

        <div id="cbody">

            <router-view></router-view>

            <notifications group="all"
                           position="top left"
                           classes="my vue-notification"
                           :duration="4000"
                           width="350"/>
        </div>

    </div>
</template>

<style lang="scss" scoped>
@import "./src/scss/vars";

header{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
}

.head-left-block a{
    text-decoration: none;
}

.dropdown-menu{
    position: relative;
    .items{
        position: absolute;
        z-index: 9999;
        bottom: -45px;
        right: 10px;
        width: 170px;
        text-align: left;
        .item{
            padding: 10px 15px;
            background: #ffffff;
            font-size: 14px;
            border: 1px solid #b2cfde;
            cursor: pointer;
            color: $colorBlack;
            &:hover{
                color: #0062f8;
            }
        }
    }
}

</style>

<script>
    import {mapState} from 'vuex'
    import project from "./project/project.vue";

    export default {
        components:{

        },

        data(){
            return {
                showMenu: false,
            }
        },

        methods:{
            logout(){
                this.$store.dispatch('logout')
            }
        },

        computed:{
            project() {
                return this.$store.state.projects.all[this.$route.params.projectId];
            },

            ...mapState([
                'loggedIn',
            ])
        }

    }
</script>